// Vessel Specification component for View VMD and Update VMD

import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChange,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { filter, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { isValidDate } from 'src/app/utils/valid-date';
import { PaintsService } from 'src/app/core/services/paints/paints.service';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MY_FORMATS,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';
import { convertToLocalIsoString, setZeroHours } from '../custom-moment-datepicker/custom-moment-datepicker.component';

const moment = customMoment;

@Component({
  selector: 'champs-vessel-specification',
  templateUrl: './vessel-specification.component.html',
  styleUrls: ['./vessel-specification.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VesselSpecificationComponent implements OnInit, OnDestroy, OnChanges {
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  private dimensionFormObject: any;
  private tonnageFormObject: any;
  private hullInfoFormObject: any;
  private tankCapFormObject: any;
  private fuelSpecFormObject: any;

  private dimensionFormData: any;
  private tonnageFormData: any;
  private hullInfoFormData: any;
  private tankCapFormData: any;
  private fuelSpecFormData: any;

  @Input() public values: any;
  @Output() public update: EventEmitter<any> = new EventEmitter();
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  public dimensionViewMode: boolean = true;
  public dimensionFrmGrp: UntypedFormGroup;
  public tonnageViewMode: boolean = true;
  public tonnageFrmGrp: UntypedFormGroup;
  public hullInfoViewMode: boolean = true;
  public hullInfoFrmGrp: UntypedFormGroup;
  public tankCapViewMode: boolean = true;
  public tankCapFrmGrp: UntypedFormGroup;
  public fuelSpecViewMode: boolean = true;
  public fuelSpecFrmGrp: UntypedFormGroup;
  public paintProducers: any = [];
  public paintProducts: ReplaySubject<any> = new ReplaySubject(1);
  public dimFieldError: boolean;
  public tonFieldError: boolean;
  public distillateFuel: any;
  public hullFieldError: boolean;
  public tankFieldError: boolean;
  public fuelFieldError: boolean;

  public mainFuelTypes$: Observable<any>;
  public auxFuelTypes$: Observable<any>;
  public distillateFuelTypes$: Observable<any>;
  public halfFuelTypes$: Observable<any>;
  public ulsFuelTypes$: Observable<any>;
  public fuelISOYears$: Observable<any>;
  public isVsSpUpdateAuth: boolean;
  public showFormErrors: boolean;
  public showGTLessError: boolean;
  public dryDockDateError: boolean;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private paintsService: PaintsService,
    private vesselDropdowns: VesselDropdownsService,
    public dialog: MatDialog,
    private auth: AuthService
  ) {
    this.mainFuelTypes$ = this.vesselDropdowns.getMainFuelTypes();
    this.auxFuelTypes$ = this.vesselDropdowns.getAuxFuelTypes();
    this.distillateFuelTypes$ = this.vesselDropdowns.getDistillateFuelTypes();
    this.ulsFuelTypes$ = this.vesselDropdowns.getULSFuelTypes();
    this.fuelISOYears$ = this.vesselDropdowns.getFuelISOYears();
    this.halfFuelTypes$ = this.vesselDropdowns.getHalfFuelTypes();
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsSpUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.paintsService
      .getPaintsMaker()
      .pipe(filter((x) => x !== undefined))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((paintProducers) => {
        this.paintProducers = paintProducers;
      });

    this.createDimForm();
    this.createTonnageForm();
    this.createHullInfoForm();
    this.createTankCapForm();
    this.createFuelSpecForm();

    this.paintMakerChanged();
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (changes.values) {
      this.values = changes.values.currentValue;
    }
  }

  public paintMakerChanged(e: any = null) {
    const val = e ? e.pmPKID : this.values.vslPaintMaker;
    if (val && val > 0) {
      this.paintsService
        .getPaintsTypeByMaker(val)
        .pipe(
          filter((x) => x !== undefined),
          distinctUntilChanged()
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.paintProducts.next(res);
          if (this.hullInfoFrmGrp.get('vslPaintType').value) {
            const match = res.filter((ptMaker) => ptMaker.ptPKID === this.hullInfoFrmGrp.get('vslPaintType').value);
            if (match.length === 0) {
              this.hullInfoFrmGrp.get('vslPaintType').setValue(null);
            }
          }
        });

      return;
    }

    this.hullInfoFrmGrp.get('vslPaintType').setValue(null);
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // dimension
  public dimensionCancel() {
    this.dimensionViewMode = !this.dimensionViewMode;
    this.createDimForm();
  }

  public dimensionSave() {
    if (this.dimensionFrmGrp.invalid) {
      this.dimFieldError = true;
      let requiredError = false;
      Object.keys(this.dimensionFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.dimensionFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    this.dimensionViewMode = true;
    if (this.dimensionFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateSpec',
        value: this.dimensionFrmGrp.getRawValue(),
      });
    }
  }

  // tonnage
  public tonnageCancel() {
    this.tonnageViewMode = true;
    this.createTonnageForm();
  }
  public tonnageSave() {
    if (this.tonnageFrmGrp.invalid) {
      this.tonFieldError = true;
      let requiredError = false;
      Object.keys(this.tonnageFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.tonnageFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    if (
      this.tonnageFrmGrp.get('vslGT').value &&
      this.tonnageFrmGrp.get('vslNT').value &&
      Number(this.tonnageFrmGrp.get('vslGT').value) < Number(this.tonnageFrmGrp.get('vslNT').value)
    ) {
      this.showFormErrors = true;
      this.showGTLessError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.tonnageViewMode = true;
    if (this.tonnageFrmGrp.dirty) {
      this.showGTLessError = false;
      this.update.emit({
        type: 'UpdateTon',
        value: this.tonnageFrmGrp.getRawValue(),
      });
    }
  }
  // hullInfo

  public hullInfoCancel() {
    this.hullInfoViewMode = true;
    this.dryDockDateError = false;
    this.createHullInfoForm();
    this.paintMakerChanged();
  }
  public hullInfoSave() {
    this.dryDockDateError = false;
    if (this.hullInfoFrmGrp.invalid) {
      this.hullFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    if (
      this.hullInfoFrmGrp.get('vslNextDryDockDate').value &&
      this.hullInfoFrmGrp.get('vslLastDryDockDate').value &&
      this.hullInfoFrmGrp.get('vslLastDryDockDate').value > this.hullInfoFrmGrp.get('vslNextDryDockDate').value
    ) {
      this.dryDockDateError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.hullInfoViewMode = true;
    this.update.emit({
      type: 'UpdateHull',
      value: this.hullInfoFrmGrp.getRawValue(),
    });
  }
  // tankCap

  public tankCapCancel() {
    this.tankCapViewMode = true;
    this.createTankCapForm();
  }
  public tankCapSave() {
    if (this.tankCapFrmGrp.invalid) {
      this.tankFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.tankCapViewMode = true;
    if (this.tankCapFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateTank',
        value: this.tankCapFrmGrp.getRawValue(),
      });
    }
  }
  // fuelSpec
  public fuelSpecCancel() {
    this.fuelSpecViewMode = true;
    this.createFuelSpecForm();
  }
  public fuelSpecSave() {
    if (this.fuelSpecFrmGrp.invalid) {
      this.fuelFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.fuelSpecViewMode = true;
    if (this.fuelSpecFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateFuel',
        value: this.fuelSpecFrmGrp.getRawValue(),
      });
    }
  }
  public updateVslisUniFielChecked(option, event) {
    if (!event.checked) {
      this.fuelSpecFrmGrp.get('vslAuxFuel').setValue(null);
    }
  }

  private createTonnageForm() {
    this.tonnageFrmGrp = this.formBuilder.group(
      (this.tonnageFormObject = {
        vslGT: this.values.vslGT ? this.values.vslGT : '',
        vslNT: this.values.vslNT ? this.values.vslNT : '',
        vslSuezT: this.values.vslSuezT ? this.values.vslSuezT : '',
        vslSuezTNet: this.values.vslSuezTNet ? this.values.vslSuezTNet : '',
      })
    );
  }

  private createHullInfoForm() {
    this.hullInfoFrmGrp = this.formBuilder.group(
      (this.hullInfoFormObject = {
        vslLastDryDockDate: this.formBuilder.control(
          isValidDate(this.values.vslLastDryDockDate) ? moment(this.values.vslLastDryDockDate) : null
        ),

        vslNextDryDockDate: this.formBuilder.control(
          this.values.vslNextDryDockDate ? moment(this.values.vslNextDryDockDate) : null
        ),

        vslPaintMaker: this.values.vslPaintMaker ? [this.values.vslPaintMaker] : [],
        vslPaintType: this.values.vslPaintType ? [this.values.vslPaintType] : [],
      })
    );
  }

  private createDimForm() {
    this.dimensionFrmGrp = this.formBuilder.group(
      (this.dimensionFormObject = {
        vslLOA: this.values.vslLOA ? this.values.vslLOA : '',
        vslDesignDraft: this.values.vslDesignDraft ? this.values.vslDesignDraft : '',
        vslBeam: this.formBuilder.control(this.values.vslBeam ? this.values.vslBeam : '', [Validators.max(99)]),
        vslDraft: this.values.vslDraft ? this.values.vslDraft : '',
        vslDWT: this.formBuilder.control(this.values.vslDWT, [Validators.pattern(new RegExp('^[0-9]+$'))]),
        vslMaxAirDraft: this.values.vslMaxAirDraft ? this.values.vslMaxAirDraft : '',
      })
    );
  }

  private createTankCapForm() {
    this.tankCapFrmGrp = this.formBuilder.group(
      (this.tankCapFormObject = {
        vslHFOCapacity: this.formBuilder.control(this.values.vslHFOCapacity ? this.values.vslHFOCapacity : null, [
          Validators.min(0),
        ]),

        vslMGOCapacity: this.formBuilder.control(this.values.vslMGOCapacity ? this.values.vslMGOCapacity : null, [
          Validators.min(0),
        ]),

        vslUltraLowSulphurTankCap: this.formBuilder.control(
          this.values.vslUltraLowSulphurTankCap ? this.values.vslUltraLowSulphurTankCap : null,
          [Validators.min(0)]
        ),

        vslHFOReceivingCap: this.formBuilder.control(
          this.values.vslHFOReceivingCap ? this.values.vslHFOReceivingCap : null,
          [Validators.min(0)]
        ),

        vslMGOReceivingCap: this.formBuilder.control(
          this.values.vslMGOReceivingCap ? this.values.vslMGOReceivingCap : null,
          [Validators.min(0)]
        ),

        vslULSFOReceivingCap: this.formBuilder.control(
          this.values.vslULSFOReceivingCap ? this.values.vslULSFOReceivingCap : null,
          [Validators.min(0)]
        ),
      })
    );
  }

  private createFuelSpecForm() {
    this.fuelSpecFrmGrp = this.formBuilder.group(
      (this.fuelSpecFormObject = {
        vslEngineFuel: this.values.vslEngineFuel ? [this.values.vslEngineFuel] : [],
        vslisUniFiel: this.values.vslisUniFiel ? this.values.vslisUniFiel : false,
        vslAuxFuel: this.values.vslAuxFuel ? [this.values.vslAuxFuel] : [],
        vslfsyID: this.values.vslfsyID ? [this.values.vslfsyID] : [],
        vslFullySeparateFSFlag: this.values.vslFullySeparateFSFlag ? this.values.vslFullySeparateFSFlag : false,
        vslULSFuel: this.values.vslULSFuel ? [this.values.vslULSFuel] : [],
        vslDistillateFuel: this.values.vslDistillateFuel ? [this.values.vslDistillateFuel] : [],
        vslHalffo: this.values.vslHalffo ? [this.values.vslHalffo] : [],
      })
    );
  }
}
