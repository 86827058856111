//
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'champs-schedule-header',
  templateUrl: './schedule-header.component.html',
  styleUrls: ['./schedule-header.component.scss'],
})
export class ScheduleHeaderComponent implements OnInit {
  @Input() public schDetails: any;
  @Input() public id: any;
  @Input() public activeLink: any;
  @Output() public approvedEmit: EventEmitter<any> = new EventEmitter();
  public isServiceUpdateAuth: boolean = false;
  public approvedGrp: UntypedFormGroup;
  public serviceRepo: boolean;
  public schedulePlannerLink: any;
  public isReportingRole: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private auth: AuthService,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.serviceRepo = this.route.snapshot.paramMap.get('serviceRepo') === 'false' ? false : true;
  }

  public ngOnInit() {
    this.isServiceUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.approvedGrp = this.formBuilder.group({
      approved: this.schDetails ? (this.schDetails.scheduleStatus === 'New' ? true : this.schDetails.isVisible) : null,
    });
    this.setSchedulePlannerLink();

    if (this.auth.BYPASS_AUTHENTICATION) {
      this.isReportingRole = false;
    } else {
      this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
    }
    if (this.schDetails) {
      // this.titleService.setTitle(
      //   this.titleService.getTitle() +
      //   (' ' + '(S)' + ' ' + this.schDetails.serviceCode + '- ' + 'ID' + this.schDetails.proformaID)
      // );
      // const title = this.titleService.getTitle();
      // if (title.includes('(') && title.lastIndexOf('(') === 7) {
      //   this.titleService.setTitle(
      //     // this.titleService.getTitle() +
      //     'CHAMPS' + ' ' + '(S)' + ' ' + this.schDetails.serviceCode + '- ' + 'ID' + this.schDetails.proformaID
      //   );
      //   return;
      // } else {
      //   this.titleService.setTitle(
      //     this.titleService.getTitle() +
      //       (' ' + '(S)' + ' ' + this.schDetails.serviceCode + '- ' + 'ID' + this.schDetails.proformaID)
      //   );
      // }

      localStorage.setItem('serviceCodeFromHeader', this.schDetails.serviceCode);
      localStorage.setItem('serviceProformaIDFromHeader', this.schDetails.proformaID);
    }
    // REFACTOR - same as above if
    // if (this.schDetails) {
    //   let title = this.titleService.getTitle();
    //   if (title.includes('(') && title.lastIndexOf('(') === 7) {
    //     this.titleService.setTitle(
    //       // this.titleService.getTitle() +
    //       'CHAMPS' + ' ' + '(S)' + ' ' + this.schDetails.serviceCode + '- ' + 'ID' + this.schDetails.proformaID
    //     );
    //     return;
    //   } else {
    //     this.titleService.setTitle(
    //       this.titleService.getTitle() +
    //       (' ' + '(S)' + ' ' + this.schDetails.serviceCode + '- ' + 'ID' + this.schDetails.proformaID)
    //     );
    //   }

    //   localStorage.setItem('serviceCodeFromHeader', this.schDetails.serviceCode);
    //   localStorage.setItem('serviceProformaIDFromHeader', this.schDetails.proformaID);
    // }
  }

  public onApproved(value: any) {
    this.approvedEmit.emit({
      postData: this.approvedGrp.value.approved,
    });

    this.router
      .navigateByUrl('/services', { skipLocationChange: true })
      .then(() =>
        this.router.navigate([
          '/services/' + this.id + '/schedule/' + this.schDetails.proformaID + '/true' + '/deployments/true',
        ])
      );
  }

  public showDropdown() {
    return this.isServiceUpdateAuth && this.serviceRepo && this.schDetails && this.schDetails.isDeploymentUser;
  }

  public setSchedulePlannerLink() {
    const test = [
      // local
      'http://localhost:4200',
      // dev1
      'https://wapazewdmlit001champsweb.crb.apmoller.net',
      'https://champsdev1.apmoller.net',
      // dev2
      'https://wapazewdmlit002champsweb.crb.apmoller.net',
      'https://champsdev1.apmoller.net',
      // test
      'https://wapazewtmlit001champsweb.crb.apmoller.net',
      'https://champsst.apmoller.net',
      // sit
      'https://wapazewtmlit002champsweb.crb.apmoller.net',
      'https://champssit.apmoller.net',
    ];
    const pp = [
      // edu
      'https://wapazewumlit001champsweb.crb.apmoller.net',
      'https://champsedu.apmoller.net',
      // pp
      'https://wapazewrmlit001champsweb.crb.apmoller.net',
      'https://champspp.apmoller.net',
      // pp
      'https://wapazearmlit001champsweb.crb.apmoller.net',
      'https://champsppea.apmoller.net',
    ];
    const prod = [
      // prod
      'https://wapazewpmlit002champsweb.crb.apmoller.net',
      'https://champs.apmoller.net',
      // prod
      'https://wapazeapmlit002champsweb.crb.apmoller.net/',
      'https://champsea.apmoller.net',
    ];
    const testUrl = 'https://comst.apmoller.net/ddnd/schedulePlanner';
    const ppUrl = 'https://comsd.apmoller.net/ddnd/schedulePlannerProformaDesigner';
    const prodUrl = 'https://coms.apmoller.net/ddnd/schedulePlannerProformaDesigner';
    const url = document.location.origin;

    if (test.includes(url)) {
      this.schedulePlannerLink = testUrl + '/' + this.schDetails.proformaID;
    }
    if (pp.includes(url)) {
      this.schedulePlannerLink = ppUrl + '/' + this.schDetails.proformaID;
    }
    if (prod.includes(url)) {
      this.schedulePlannerLink = prodUrl + '/' + this.schDetails.proformaID;
    }
  }
}
