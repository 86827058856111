// pipe to filter out selected values in dropdown (used mainly in fixture bunker card)

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'dropdownSelectedFilter'
})
export class DropdownSelectedFilterPipe implements PipeTransform {

    public transform(value: any, args?: any): any {
        const tmpArray = args[1].filter((e, index) => index !== args[0]);
        // For offhire bunker type dropdown
        if (args[2]) {
          return value?.filter(e => !tmpArray.includes(e.btPKID));
        }
        return value.filter(e => !tmpArray.includes(e));
    }

}
