// add TCO component
import { Component, Input, OnInit, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'champs-tco-create',
  templateUrl: './create-tco.component.html',
  styleUrls: ['./create-tco.component.scss']
})
export class CreateTcoComponent implements OnInit, OnChanges {

  private tcoObj: any;
  @Input() public serviceObj: any;
  @Input() public index: any;
  @Input() public service: any;
  @Input() public roundtripDays: any;
  @Input() public totalTeuCap: any;
  @Input() public update: boolean;
  @Input() public schId: any;
  @Input() public serviceRepo: any;
  @Input() public proformaHeaderId: any;
  @Input() public isCharter: any;
  @Input() public charterReqObj: UntypedFormGroup;
  @Input() public uploadedProfoId: any;
  @Input() public profoIdFromRequest: any;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  public bunkerPrice: any;
  public serviceFormGrp: any;
  public vesselClass: any;
  public fieldError: boolean = false;
  public showCustomError: boolean;
  public bunkerPriceAvailable: boolean = false;
  public customErrorMsg: any;
  public customRoundTripError: any;
  public clickedMarketPriceBtn: boolean = false;
  public roundtripByProfoId: any;
  public proformaIdFromURL: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private scheduleService: ServiceRepositoryService,
    private route: ActivatedRoute
  ) {
    this.createTCOBunkerForm();
    this.proformaIdFromURL = this.route.snapshot.paramMap.get('schId');
  }

  public ngOnInit() {
    this.createTCOBunkerForm();
    this.getRoundTripByProfoID();
    if (this.serviceObj && !this.isCharter) {
      if (!this.serviceObj.scheduleBunkerPrice) {
      this.getBunkerPrices();
      }
    } else if (this.isCharter) {
      this.serviceFormGrp.patchValue({
        bpBunkerType1Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType1Price : null : null,
        bpBunkerType2Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType2Price : null : null,
        bpBunkerType3Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType3Price : null : null,
      });
    }
    }
    public getRoundTripByProfoID() {
    const profoIdFinal = this.uploadedProfoId ? this.uploadedProfoId : this.proformaIdFromURL ? this.proformaIdFromURL : this.profoIdFromRequest.proformaId;
    this.scheduleService.getRoundtripDaysByProfoId(profoIdFinal).subscribe((response: any) => {
      this.roundtripByProfoId = response;
    }, (errorResponse) => {
      if (errorResponse.error.error === 'Details not received from Schedule Planner') {
        this.showCustomError = true;
        this.customRoundTripError = errorResponse.error.error;
      }
    }
  );
  }
  public getBunkerPrices() {
    this.scheduleService.getBunkerForCharter().subscribe((res1: any) => {
      if (res1) {
        for (const bunker of res1.monthlyAvgBunkerPrice) {
          if (bunker.bunkerType === 'HFO') {
            this.serviceFormGrp.patchValue({
              bpBunkerHFOPrice: bunker.mapbunkerPrice,
            });
          }
          if (bunker.bunkerType === 'MDO') {
            this.serviceFormGrp.patchValue({
              bpBunkerMDOPrice: bunker.mapbunkerPrice,
            });
          }
        }
      }
    });
  }

   public ngOnChanges(changes: { service: SimpleChange }) {
    this.scheduleService.getVesselDesign().subscribe((vesselClass: any) => {
      this.vesselClass = vesselClass.vesselDesignMapping;
    });
    if (changes.service) {
      this.tcoObj = changes.service.currentValue;
    }
  }

  public getMarketPrice() {
    this.clickedMarketPriceBtn = true;
    this.scheduleService.getMarketRate(this.serviceFormGrp.get('stVesselDesignMappingId').value).subscribe((res: any) => {
      if (res) {
        this.showCustomError = false;
        this.serviceFormGrp.patchValue({
          stTCEperDayMarket: res
        });

      }
    },
    (errorResponse) => {
      if (errorResponse.error.error === 'Market Rate not release for the segment. Please enter manually') {
        this.showCustomError = true;
        this.customErrorMsg = errorResponse.error.error;
        this.serviceFormGrp.patchValue({
          stTCEperDayMarket: ''
        });
      }
    });
  }
  public claculateServiceCostRT() {
    // Bunker price validation for addTCO
    if (this.serviceObj && this.serviceObj.scheduleBunkerPrice) {
    if (this.serviceObj.scheduleBunkerPrice.bpBunkerType1Price != null || this.serviceObj.scheduleBunkerPrice.bpBunkerType2Price != null || this.serviceObj.scheduleBunkerPrice.bpBunkerType3Price != null) {
   this.bunkerPriceAvailable = true;
    }
  }
    // changing stRoundTripDays to roundtripByProfoId from api
    if (this.serviceFormGrp.get('bpBunkerType1Price').value) {
      let value =  (this.serviceFormGrp.get('stTCEperDayMarket').value * this.roundtripByProfoId / 1000000) +
      (((this.serviceFormGrp.get('stBunkerType1TotalMT').value * this.serviceFormGrp.get('bpBunkerType1Price').value) + (this.serviceFormGrp.get('stBunkerType2TotalMT').value * this.serviceFormGrp.get('bpBunkerType2Price').value) + (this.serviceFormGrp.get('stBunkerType3TotalMT').value * this.serviceFormGrp.get('bpBunkerType3Price').value)) / 1000000) +
      (+this.serviceFormGrp.get('stTotalPortCost').value) +
      (+this.serviceFormGrp.get('stMiscellaneousCost').value);
      value = +value.toFixed(2);
      if (value && !isNaN(value)) {
        this.serviceFormGrp.patchValue({ stTotalServiceCostPerRT: value });
        return value;
      }
    }
    return null;
  }

  public claculateServiceCostYear() {
    let value = this.serviceFormGrp.get('frequency').value ? (this.serviceFormGrp.get('stTotalServiceCostPerRT').value * 365) / this.serviceFormGrp.get('frequency').value : 0;
    value = +value.toFixed(2);
    if (value && !isNaN(value)) {
      this.serviceFormGrp.patchValue({ stTotalServiceCostPerYear: value });
      return value;
    }
    return null;
  }
  public calculateSlotCostMarketTC() {
    const capacity = this.serviceFormGrp.get('stTotalTEUCapacity').value;
    let value = capacity ? (this.serviceFormGrp.get('stTotalServiceCostPerRT').value * 1000000) / capacity : 0;
    value = +value.toFixed(2);
    if (value && !isNaN(value)) {
      this.serviceFormGrp.patchValue({ stSlotCostMarketTC: value });
      return value;
    }
    return null;
  }

  public saveTCO() {
    if (this.serviceFormGrp.invalid) {
      this.fieldError = true;
      return;
    }

    if (this.serviceFormGrp.valid) {
      const postData = {
        scheduleTCOs: [{
          'stPKID': this.serviceFormGrp.get('stPKID').value,
          'stProformaHeaderId': this.serviceFormGrp.get('stProformaHeaderId').value,
          'stVesselDesignMappingId': this.serviceFormGrp.get('stVesselDesignMappingId').value,
          'cdName': this.serviceFormGrp.get('cdName').value,
          'stTCEperDayMarket': this.serviceFormGrp.get('stTCEperDayMarket').value,
          'stTotalTEUCapacity': this.serviceFormGrp.get('stTotalTEUCapacity').value,
          'stTotalPortCost': this.serviceFormGrp.get('stTotalPortCost').value,
          'stMiscellaneousCost': this.serviceFormGrp.get('stMiscellaneousCost').value,
          'stBunkerType1TotalMT' : this.serviceFormGrp.get('stBunkerType1TotalMT').value,
          'stBunkerType2TotalMT' : this.serviceFormGrp.get('stBunkerType2TotalMT').value,
          'stBunkerType3TotalMT' : this.serviceFormGrp.get('stBunkerType3TotalMT').value,
          'stBunkerMDONonSteamingRT': +this.serviceFormGrp.get('stBunkerMDONonSteamingRT').value,
          'stTotalServiceCostPerRT': this.serviceFormGrp.get('stTotalServiceCostPerRT').value,
          'stTotalServiceCostPerYear': this.serviceFormGrp.get('stTotalServiceCostPerYear').value,
          'stSlotCostMarketTC': this.serviceFormGrp.get('stSlotCostMarketTC').value,
          'stRoundTripDays': this.roundtripByProfoId
        }
        ],
        scheduleBunkerPrice: {
          bpPKID: this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpPKID : 0 : 0,
          bpProformaHeaderId: this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpProformaHeaderId : this.proformaHeaderId : this.proformaHeaderId,
          bpBunkerType1Price : this.serviceFormGrp.get('bpBunkerType1Price').value,
          bpBunkerType2Price : this.serviceFormGrp.get('bpBunkerType2Price').value,
          bpBunkerType3Price : this.serviceFormGrp.get('bpBunkerType3Price').value
        },
        frequency: this.serviceFormGrp.get('frequency').value
      };
      this.close.emit({
        value: postData
      });
    }
  }
  private createTCOBunkerForm() {
    if (this.service) {
    this.serviceFormGrp = this.formBuilder.group({
      'stPKID': this.service.stPKID,

      'stProformaHeaderId': this.service.stProformaHeaderId,

      'stVesselDesignMappingId': this.service.stVesselDesignMappingId,

      'cdName': this.service.cdName,

      'stTCEperDayMarket': this.service.stTCEperDayMarket,

      'stTotalTEUCapacity': this.service.stTotalTEUCapacity,

      'stTotalPortCost': this.service.stTotalPortCost,

      'stMiscellaneousCost': this.service.stMiscellaneousCost,

      'stBunkerType1TotalMT' : this.service.stBunkerType1TotalMT,

      'stBunkerType2TotalMT' :  this.service.stBunkerType2TotalMT,

      'stBunkerType3TotalMT' : this.service.stBunkerType3TotalMT,

      'stBunkerMDONonSteamingRT': this.service.stBunkerMDONonSteamingRT,

      'stTotalServiceCostPerRT': this.service.stTotalServiceCostPerRT,

      'stTotalServiceCostPerYear': this.service.stTotalServiceCostPerYear,

      'stSlotCostMarketTC': this.service.stSlotCostMarketTC,
      bpBunkerType1Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType1Price : null : null,
      bpBunkerType2Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType2Price : null : null,
      bpBunkerType3Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType3Price : null : null,
      frequency: this.serviceObj ? this.serviceObj.frequency : 0,

      'stRoundTripDays': this.service.stRoundTripDays,
    });
  } else {
    this.serviceFormGrp = this.formBuilder.group({
      'stPKID': 0,

      'stProformaHeaderId': this.proformaHeaderId,

      'stVesselDesignMappingId': null,

      'cdName': null,

      'stTCEperDayMarket': null,

      'stTotalTEUCapacity': null,

      'stTotalPortCost': null,

      'stMiscellaneousCost': null,

      'stBunkerType1TotalMT' : null,

      'stBunkerType2TotalMT': null,

      'stBunkerType3TotalMT' : null,

      'stBunkerMDONonSteamingRT': null,

      'stTotalServiceCostPerRT': null,

      'stTotalServiceCostPerYear': null,

      'stSlotCostMarketTC': null,
      bpBunkerType1Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType1Price : null : null,
      bpBunkerType2Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType2Price : null : null,
      bpBunkerType3Price :  this.serviceObj ? this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpBunkerType3Price : null : null,
      bpBunkerLFHFOPrice: 0,
      bpBunkerLSMDDOPrice: 0,
      frequency: this.serviceObj ? this.serviceObj.frequency : 0,
      'stRoundTripDays': 0
    });

  }
  }
}
