//

import { Component, OnInit} from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'champs-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent  implements OnInit {

  public cards: { title: string, description: string, routeName: string, routeURL: string }[];
  public dashboards: { title: string, routeURL: string }[];
  public isReportingRole: any;
  public isNoRole: any;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    this.cards = [
      {
        title: 'Vessels',
        description: 'Vessel details & specifications, restrictions, evaluations and inspections',
        routeName: 'View all vessels',
        routeURL: 'vessels'
      },
      {
        title: 'Fixtures',
        description: 'Deliver and redeliver, rates and options, bunker, offhire, notices, expences etc.',
        routeName: 'View all fixtures',
        routeURL: 'fixtures'
      },
      {
        title: 'Owners',
        description: 'Vessel owner contact information, vessels, fixtures and outstandings.',
        routeName: 'View all owners',
        routeURL: 'owners'
      },
      {
        title: 'Services',
        description: 'Vessels, schedules, ports, rotation and deployments',
        routeName: 'View all services',
        routeURL: 'services'
      }
    ];

    this.dashboards = [
      { title: 'POST FIXTURE', routeURL: 'postfixture-dashboard' },
      { title: 'CHARTERING', routeURL: 'chartering-dashboard' },
      { title: 'DEPLOYMENT', routeURL: 'deployment-dashboard' },
      { title: 'FINANCE', routeURL: 'finance-dashboard' }
    ];
  }

  public ngOnInit() {

    if (this.auth.BYPASS_AUTHENTICATION) {
      return true;
    } else {
      this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');

    }
    this.isNoRole = !this.auth.roles ? true : false;
    if (this.isNoRole || this.isReportingRole) {
      this.router.navigate(['/reports']);
    }
  }

  public navigateTo(URL: string) {
    this.router.navigate([URL]);
  }
}
