//

import { Directive, forwardRef } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[inputDate]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputDateDirective), multi: true }
  ]
})
export class InputDateDirective implements Validator {

  private validator: ValidatorFn;
  private dateRegex: RegExp = /^(([0-9])|([0-2][0-9])|([3][0-1]))\-(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\-\d{2}$/;

  private _onChange: () => void;

  constructor() {
    this.validator = this.numberValidator();
  }

  public validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private numberValidator(): ValidatorFn {
    return (c: UntypedFormControl) => {
      if (c.value && !this.dateRegex.test(c.value.toString().toLowerCase())) {
        return {
          invalidDate: true
        };
      } else {
        return null;
      }
    };
  }
}
