//

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, empty } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';

@Injectable()
export class VesselDetailsResolver  {

  constructor(
    private router: Router,
    private vesselsService: VesselsService
  ) { }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const id = route.paramMap.get('id');
    return this.vesselsService
      .getSingleVesselData(parseFloat(id))
      .pipe(
        filter(x => x !== undefined),
        map(res => res),
        catchError(() => {
          this.router.navigate(['']); // could redirect to error page
          return empty();
        })
      );
  }
}
