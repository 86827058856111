/* */
import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { Subject } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UIHelperService } from 'src/app/core/services/ui-helper/ui-helper.service';
import '@maersk-global/mds-components-community';
import "@maersk-global/mds-components-core/mc-icon";
import { A3FiltersComponent } from './a3-filters/a3-filters.component';
import * as moment from 'moment';
import { ExcelService } from '@cham-services/download-excel/excel.service';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'champs-a3-view',
  templateUrl: './a3-view.component.html',
  styleUrls: ['./a3-view.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class A3ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  public showMoreIndex: number = 0;
  public _onDestroy = new Subject<void>();
  public noticesBuffer = [];
  public bufferSize = 50;
  public a3List: any[] = [];
  public a3Months: string[] = [];
  public initialList: any[] = [];
  public filteredArray: any[] = [];
  @ViewChild(A3FiltersComponent, { static: false }) public filterComponent!: A3FiltersComponent;
  @ViewChild(A3FiltersComponent) public filterComp: A3FiltersComponent;
  public showMinMaxError: boolean = false;
  public rows: any[] = [];
  public mobile: boolean = false;
  public showElementsCount: number = 20;
  public removable: boolean = true;
  public showFilters: boolean = true;
  public math = Math;
  public sidemenuCollapsed: boolean;
  public selectedVessel: any = null;
  public currentPage = 1;
  public pageSize = 20;
  public page = {
    pageNumber: 0,
    size: 20,
    totalCount: 0,
  };
  public selected = [];
  public reorderable = true;
  public swapColumns = false;
  public routeSelected: boolean = true;
  public serviceSelected: boolean = true;
  public vslNameSelected: boolean = true;
  public vslTypeSelected: boolean = true;
  public startDateSelected: boolean = false;
  public endDateSelected: boolean = false;
  public positionSelected: boolean = true;
  public operatorSelected: boolean = false;
  public nominalTEUSelected: boolean = false;
  public plugsSelected: boolean = false;
  public nextDryDockDateSelected: boolean = false;
  public vslFilters: any;
  public vslTypeFilters: any;
  public serviceFilters: any;
  public ampFilters: any;
  public ciiFilters: any;
  public beamFilters: any;
  public cranesFilters: any;
  public deadWeightFilters: any;
  public endPortFilters: any;
  public endRegionFilters: any;
  public flagFilters: any;
  public fleetRiskScoreFilters: any;
  public keelMastFilters: any;
  public loaFilters: any;
  public teuFilters: any;
  public operatorFilters: any;
  public panamaFilters: any;
  public plugsFilters: any;
  public positionFilters: any;
  public routeFilters: any;
  public selectedRouteFilters: any;
  public obj1 = [
    { key: 0, value: '99', kvIsChecked: true }
  ];
  public scantlingDraftFilters: any;
  public scrubberFilters: any;
  public segmentFilters: any;
  public startPortFilters: any;
  public startRegionFilters: any;
  public nominalTEUFilters: any;
  public reeferFilters: any;
  public filtersCtrls = {
    startDateMultiCtrl: {
      fromDateCtrl: new UntypedFormControl(),
      toDateCtrl: new UntypedFormControl(),
    },
    endDateMultiCtrl: {
      fromDateCtrl: new UntypedFormControl(),
      toDateCtrl: new UntypedFormControl(),
    },
    nextDryDockDateMultiCtrl: {
      fromDateCtrl: new UntypedFormControl(),
      toDateCtrl: new UntypedFormControl(),
    },
    vslNameCtrl: new UntypedFormControl(),
    vslTypeCtrl: new UntypedFormControl(),
    serviceCtrl: new UntypedFormControl(),
    ampCtrl: new UntypedFormControl(),
    ciiCtrl: new UntypedFormControl(),
    beamCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    cranesCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    deadWeightCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    endPortCtrl: new UntypedFormControl(),
    endRegionCtrl: new UntypedFormControl(),
    flagCtrl: new UntypedFormControl(),
    fleetRiskScoreCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    keelMastCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    loaCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    teuCtrl: new UntypedFormControl(),
    operatorCtrl: new UntypedFormControl(),
    panamaCtrl: new UntypedFormControl(),
    plugsCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    positionCtrl: new UntypedFormControl(),
    routeCtrl: new UntypedFormControl(),
    scantlingDraftCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    scrubberCtrl: new UntypedFormControl(),
    segmentCtrl: new UntypedFormControl(),
    startPortCtrl: new UntypedFormControl(),
    startRegionCtrl: new UntypedFormControl(),
    reeferCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    nominalTeuCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
    nominal2MTeuCtrl: {
      fromValCtrl: new UntypedFormControl(),
      toValCtrl: new UntypedFormControl(),
    },
  };
  // added
  public ciiSelected: boolean = false;
  public fleetRiskScoreSelected: boolean = false;
  public scrubberSelected: boolean = false;
  public flagSelected: boolean = false;

  public loaSelected: boolean = false;

  public beamSelected: boolean = false;

  public deadWeightSelected: boolean = false;

  public scantlingDraftSelected: boolean = false;

  public keelMastSelected: boolean = false;
  public noOfReeferPlugsSelected: boolean = false;

  public cranesSelected: boolean = false;

  public ampComplianceSelected: boolean = false;

  public panamaFittedSelected: boolean = false;

  public segmentSelected: boolean = false;

  public nominalTEU2MSelected: boolean = false;

  public commentSelected: boolean = false;

  public startPortSelected: boolean = false;

  public endPortSelected: boolean = false;

  public startRegionSelected: boolean = false;

  public endRegionSelected: boolean = false;

  public showDrydock: boolean = true;
  /** Label of the search placeholder */
  public placeholderLabel = 'Search';

  /** Label to be shown when no entries are found. Set to null if no message should be shown. */
  public noEntriesFoundLabel = 'Nothing found';

  public userRole: any;

  public userId: any;

  public saveFilters = {
    DistColumns: null,
    DistVesselName: null,
    DistVesselType: null,
    DistServiceName: null,
    DistRoute: null,
    DistPosition: null,
    DistOperator: null,
    DistCii: null,
    DistScrubber: null,
    DistFlag: null,
    DistAMP: null,
    DistPanamaFitted: null,
    DistSegment: null,
    DistStartPort: null,
    DistEndPort: null,
    DistStartRegion: null,
    DistEndRegion: null,
    Min_Beam: null,
    Min_Deadweight: null,
    Min_Fleetriskcore: null,
    Min_keelmast: null,
    Min_loa: null,
    Min_plugs: null,
    Min_cranes: null,
    Min_scantlingDraft: null,
    Min_Totalreeferplugs: null,
    Min_NominalTEU: null,
    Min_Nominal2MTEU: null,
    Max_Beam: null,
    Max_Deadweight: null,
    Max_Fleetriskcore: null,
    Max_keelmast: null,
    Max_loa: null,
    Max_plugs: null,
    Max_cranes: null,
    Max_scantlingDraft: null,
    Max_Totalreeferplugs: null,
    Max_NominalTEU: null,
    Max_Nominal2MTEU: null,
    min_StartDate: null,
    max_StartDate: null,
    min_EndDate: null,
    max_EndDate: null,
    min_NextDryDockDate: null,
    max_NextDryDockDate: null,
    userId: null
  };

  @ViewChild('DatatableComponent') public table: DatatableComponent;
  public selectedVslFilters: any;
  public selectedVslTypeFilters: any;
  public selectedServiceFilters: any;
  public selectedAmpFilters: any;
  public selectedCiiFilters: any;
  public selectedBeamFilters: any;
  public selectedCranesFiltersFrom: any;
  public selectedCranesFiltersTo: any;
  public selectedDeadWeightFilters: any;
  public selectedEndPortFilters: any;
  public selectedEndRegionFilters: any;
  public selectedFlagFilters: any;
  public selectedFleetRiskScoreFilters: any;
  public selectedKeelMastFilters: any;
  public selectedLoaFilters: any;
  public selectedOperatorFilters: any;
  public selectedPositionFilters: any;
  public selectedScrubberFilters: any;
  public selectedSegmentFilters: any;
  public selectedTeuFiltersFrom: any;
  public selectedTeuFiltersTo: any;
  public selectedPanamaFilters: any;
  public selectedPlugsFilters: any;
  public selectedScantlingDraftFilters: any;
  public selectedStartPortFilters: any;
  public selectedStartRegionFilters: any;
  public selectedReeferFilters: any;
  public selectedNominalTEU2MFiltersFrom: any;
  public selectedNominalTEU2MFiltersTo: any;
  public selectedBeamFiltersFrom: any;
  public selectedBeamFiltersTo: any;
  public selectedDeadWeightFiltersFrom: any;
  public selectedDeadWeightFiltersTo: any;
  public selectedFleetRiskScoreFiltersFrom: any;
  public selectedFleetRiskScoreFiltersTo: any;
  public selectedKeelMastFiltersFrom: any;
  public selectedKeelMastFiltersTo: any;
  public selectedLoaFiltersFrom: any;
  public selectedLoaFiltersTo: any;
  public selectedReeferFiltersFrom: any;
  public selectedReeferFiltersTo: any;
  public selectedScantlingDraftFiltersFrom: any;
  public selectedScantlingDraftFiltersTo: any;
  public selectedPlugsFiltersFrom: any;
  public selectedPlugsFiltersTo: any;
  public selectedStartDateFiltersFrom: any;
  public selectedStartDateFiltersTo: any;
  public selectedEndDateFiltersFrom: any;
  public selectedEndDateFiltersTo: any;
  public selectedNextDryDockDateFiltersFrom: any;
  public selectedNextDryDockDateFiltersTo: any;
  constructor(
    private vslService: VesselsService,
    public dialog: MatDialog,
    private uiHelperService: UIHelperService,
    private excelService: ExcelService,
    private auth: AuthService
  ) {
    if (window.screen.width < 600) {
      this.mobile = true;
    }

    if (window.screen.width < 600) {
      this.mobile = true;
    }
  }
  public ngAfterViewInit(): void {
    this.setPage({ offset: 0 });
    this.getA3Report();
  }
  /** */
  public ngOnInit() {
    this.sidemenuCollapsed = true;
    if (this.auth.userProfile) {
      this.userRole = this.auth.userProfile.role ? this.auth.userProfile.roles[0] : 'L3';
      //updated to return UID for CDA Accounts
      this.userId = this.auth.userProfile.UID ? this.auth.userProfile.UID : 'CDA001';
    }
  }

  public recalculateTable() {
    setTimeout(() => {
      this.table.resize.emit();
    }, 200);
  }
  public toggleFilter(event: any = null) {
    if (event) {
      this.showFilters = true;
      return;
    }
    this.showFilters = !this.showFilters;
  }

  public getRowBorderClass(row) {
    return {
      'row-border': row['serviceBorderClass'] === true,
      'row-pos-border': row['positionBorderClass'] === true,
    };
  }

  public getCellClass({ row, column, value }): any {
    return {
      'vsa-color': row[column.prop] !== null && row['vesselColor'] === 'brightyellow' ? true : false,
      'own-color': row[column.prop] !== null && row['vesselColor'] === 'lightpink' ? true : false,
      'dummy-color': row[column.prop] !== null && row['vesselColor'] === 'red' ? true : false,
      'phasein-color': row['phaseInMonth'] === column.name ? true : false,
      'drydock-color': row.dryDockMonths && row.dryDockMonths[column.name] ? true : false,
      'own-drydock-color':
        row[column.prop] !== null &&
          row['vesselColor'] === 'lightpink' &&
          row.dryDockMonths &&
          row.dryDockMonths[column.name]
          ? true
          : false,
    };
  }

  public clearDateFilter(ctrl) {
    ctrl?.setValue(null);
    this.allFiltered();
  }

  public toggleDrydock() {
    this.showDrydock = !this.showDrydock;
    this.filterComp.dryDockMappings = this.showDrydock;
    this.getA3Report();
  }

  public getA3Report() {
    const obj = {
      monthYearFilter: moment().format('MMM-YYYY'),
      vesselPlanningGroupMappings: [],
      lines: [],
      segments: [],
      pageNum: this.page.pageNumber,
      pageSize: this.page.size,
      userId: this.userId
    };
    this.filterComp.applyFilters(obj);
    this.vslService.a3Data.subscribe((vessels: any) => {
      this.a3Months = vessels.monthsYears;
      this.a3List = vessels;
      this.initialList = vessels.a3ViewServiceTable;
      this.rows = this.initialList;
      this.vslFilters = vessels.distVesselName;
      this.vslTypeFilters = vessels.distVesselType;
      this.serviceFilters = vessels.distServiceName;
      this.ampFilters = vessels.distAMP;
      this.ciiFilters = vessels.distCii;
      this.beamFilters = vessels.distBeam;
      this.cranesFilters = vessels.distCranes;
      this.deadWeightFilters = vessels.distDeadWeight;
      this.endPortFilters = vessels.distEndPort;
      this.endRegionFilters = vessels.distEndRegion;
      this.flagFilters = vessels.distFlag;
      this.fleetRiskScoreFilters = vessels.distFleetRiskScore;
      this.keelMastFilters = vessels.distKeelmastDistance;
      this.loaFilters = vessels.distLOA;
      this.teuFilters = vessels.distNominalTue;
      this.operatorFilters = vessels.distOperator;
      this.panamaFilters = vessels.distPanamaFitted;
      this.plugsFilters = vessels.distPlugs;
      this.positionFilters = vessels.distPosition;
      this.routeFilters = vessels.distRoute;
      this.scantlingDraftFilters = vessels.distScantlingDraft;
      this.scrubberFilters = vessels.distScrubber;
      this.segmentFilters = vessels.distSegment;
      this.startPortFilters = vessels.distStartPort;
      this.startRegionFilters = vessels.distStartRegion;
      this.reeferFilters = vessels.distTotalNoReefer;
      this.nominalTEUFilters = vessels.distNominalTue;

      // Preset selected filters

      // Route
      this.selectedRouteFilters = this.setSelectedFilters(vessels?.distRoute);
      this.filtersCtrls.routeCtrl.setValue(this.selectedRouteFilters);

      // Vessel name
      this.selectedVslFilters = this.setSelectedFilters(vessels?.distVesselName);
      this.filtersCtrls.vslNameCtrl.setValue(this.selectedVslFilters);

      // Vessel type
      this.selectedVslTypeFilters = this.setSelectedFilters(vessels?.distVesselType);
      this.filtersCtrls.vslTypeCtrl.setValue(this.selectedVslTypeFilters);

      // Service name
      this.selectedServiceFilters = this.setSelectedFilters(vessels?.distServiceName);
      this.filtersCtrls.serviceCtrl.setValue(this.selectedServiceFilters);

      // AMP
      this.selectedAmpFilters = this.setSelectedFilters(vessels?.distAMP);
      this.filtersCtrls.ampCtrl.setValue(this.selectedAmpFilters);

      // CII
      this.selectedCiiFilters = this.setSelectedFilters(vessels.distCii);
      this.filtersCtrls.ciiCtrl.setValue(this.selectedCiiFilters);

      // Cranes
      this.selectedCranesFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Cranes : null;
      this.selectedCranesFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Cranes : null;

      this.filtersCtrls.cranesCtrl.fromValCtrl.setValue(this.selectedCranesFiltersFrom);
      this.filtersCtrls.cranesCtrl.toValCtrl.setValue(this.selectedCranesFiltersTo);

      // End Port
      this.selectedEndPortFilters = this.setSelectedFilters(vessels.distEndPort);
      this.filtersCtrls.endPortCtrl.setValue(this.selectedEndPortFilters);

      // End Region
      this.selectedEndRegionFilters = this.setSelectedFilters(vessels.distEndRegion);
      this.filtersCtrls.endRegionCtrl.setValue(this.selectedEndRegionFilters);

      // Flag
      this.selectedFlagFilters = this.setSelectedFilters(vessels.distFlag);
      this.filtersCtrls.flagCtrl.setValue(this.selectedFlagFilters);

      // Beam
      this.selectedBeamFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Beam : null;
      this.selectedBeamFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Beam : null;
      this.filtersCtrls.beamCtrl.fromValCtrl.setValue(this.selectedBeamFiltersFrom);
      this.filtersCtrls.beamCtrl.toValCtrl.setValue(this.selectedBeamFiltersTo);

      // Deadweight
      this.selectedDeadWeightFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Deadweight : null;
      this.selectedDeadWeightFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Deadweight : null;
      this.filtersCtrls.deadWeightCtrl.fromValCtrl.setValue(this.selectedDeadWeightFiltersFrom);
      this.filtersCtrls.deadWeightCtrl.toValCtrl.setValue(this.selectedDeadWeightFiltersTo);

      // FleetRiskScore
      this.selectedFleetRiskScoreFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Fleetriskcore : null;
      this.selectedFleetRiskScoreFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Fleetriskcore : null;
      this.filtersCtrls.fleetRiskScoreCtrl.fromValCtrl.setValue(this.selectedFleetRiskScoreFiltersFrom);
      this.filtersCtrls.fleetRiskScoreCtrl.toValCtrl.setValue(this.selectedFleetRiskScoreFiltersTo);

      // Keel-Mast
      this.selectedKeelMastFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_keelmast : null;
      this.selectedKeelMastFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_keelmast : null;
      this.filtersCtrls.keelMastCtrl.fromValCtrl.setValue(this.selectedKeelMastFiltersFrom);
      this.filtersCtrls.keelMastCtrl.toValCtrl.setValue(this.selectedKeelMastFiltersTo);

      // LOA
      this.selectedLoaFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_loa : null;
      this.selectedLoaFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_loa : null;
      this.filtersCtrls.loaCtrl.fromValCtrl.setValue(this.selectedLoaFiltersFrom);
      this.filtersCtrls.loaCtrl.toValCtrl.setValue(this.selectedLoaFiltersTo);

      // Nominal TEU
      this.selectedTeuFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_NominalTEU : null;
      this.selectedTeuFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_NominalTEU : null;
      this.filtersCtrls.nominalTeuCtrl.fromValCtrl.setValue(this.selectedTeuFiltersFrom);
      this.filtersCtrls.nominalTeuCtrl.toValCtrl.setValue(this.selectedTeuFiltersTo);

      // Nominal 2M TEU
      this.selectedNominalTEU2MFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Nominal2MTEU : null;
      this.selectedNominalTEU2MFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Nominal2MTEU : null;
      this.filtersCtrls.nominal2MTeuCtrl.fromValCtrl.setValue(this.selectedNominalTEU2MFiltersFrom);
      this.filtersCtrls.nominal2MTeuCtrl.toValCtrl.setValue(this.selectedNominalTEU2MFiltersTo);

      // Total no of reefer plugs
      this.selectedReeferFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_Totalreeferplugs : null;
      this.selectedReeferFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_Totalreeferplugs : null;
      this.filtersCtrls.reeferCtrl.fromValCtrl.setValue(this.selectedReeferFiltersFrom);
      this.filtersCtrls.reeferCtrl.toValCtrl.setValue(this.selectedReeferFiltersTo);

      // Scantling Draft
      this.selectedScantlingDraftFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_scantlingDraft : null;
      this.selectedScantlingDraftFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_scantlingDraft : null;
      this.filtersCtrls.scantlingDraftCtrl.fromValCtrl.setValue(this.selectedScantlingDraftFiltersFrom);
      this.filtersCtrls.scantlingDraftCtrl.toValCtrl.setValue(this.selectedScantlingDraftFiltersTo);

      // Plugs
      this.selectedPlugsFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_plugs : null;
      this.selectedPlugsFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_plugs : null;
      this.filtersCtrls.plugsCtrl.fromValCtrl.setValue(this.selectedPlugsFiltersFrom);
      this.filtersCtrls.plugsCtrl.toValCtrl.setValue(this.selectedPlugsFiltersTo);

      // Operators
      this.selectedOperatorFilters = this.setSelectedFilters(vessels.distOperator);
      this.filtersCtrls.operatorCtrl.setValue(this.selectedOperatorFilters);

      // Panama canal
      this.selectedPanamaFilters = this.setSelectedFilters(vessels.distPanamaFitted);
      this.filtersCtrls.panamaCtrl.setValue(this.selectedPanamaFilters);

      // Position
      vessels.distPosition = vessels.distPosition.sort(function (a, b) {
        return a.value.localeCompare(b.value, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      this.selectedPositionFilters = this.setSelectedFilters(vessels.distPosition);
      this.filtersCtrls.positionCtrl.setValue(this.selectedPositionFilters);

      // Scrubber
      this.selectedScrubberFilters = this.setSelectedFilters(vessels.distScrubber);
      this.filtersCtrls.scrubberCtrl.setValue(this.selectedScrubberFilters);

      // Segment
      this.selectedSegmentFilters = this.setSelectedFilters(vessels.distSegment);
      this.filtersCtrls.segmentCtrl.setValue(this.selectedSegmentFilters);

      // Start Port
      this.selectedStartPortFilters = this.setSelectedFilters(vessels.distStartPort);
      this.filtersCtrls.startPortCtrl.setValue(this.selectedStartPortFilters);

      // Start Region
      this.selectedStartRegionFilters = this.setSelectedFilters(vessels.distStartRegion);
      this.filtersCtrls.startRegionCtrl.setValue(this.selectedStartRegionFilters);

      // Start Date
      this.selectedStartDateFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_StartDate : null;
      this.selectedStartDateFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_StartDate : null;
      this.filtersCtrls.startDateMultiCtrl.fromDateCtrl.setValue(this.selectedStartDateFiltersFrom);
      this.filtersCtrls.startDateMultiCtrl.toDateCtrl.setValue(this.selectedStartDateFiltersTo);

      // End date
      this.selectedEndDateFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_EndDate : null;
      this.selectedEndDateFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_EndDate : null;
      this.filtersCtrls.endDateMultiCtrl.fromDateCtrl.setValue(this.selectedEndDateFiltersFrom);
      this.filtersCtrls.endDateMultiCtrl.toDateCtrl.setValue(this.selectedEndDateFiltersTo);

      // Next Dry Dock
      this.selectedNextDryDockDateFiltersFrom = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].min_NextDryDockDate : null;
      this.selectedNextDryDockDateFiltersTo = vessels.minMaxDateRangeFilters[0] ? vessels.minMaxDateRangeFilters[0].max_NextDryDockDate : null;
      this.filtersCtrls.nextDryDockDateMultiCtrl.fromDateCtrl.setValue(this.selectedNextDryDockDateFiltersFrom);
      this.filtersCtrls.nextDryDockDateMultiCtrl.toDateCtrl.setValue(this.selectedNextDryDockDateFiltersTo);

      this.page.totalCount = vessels.totalCount;
      this.page.pageNumber = vessels.pageNum - 1;
      this.allFiltered();
    });
    // this.allFiltered();
  }

  public setSelectedFilters(val) {
    const arr1: any = [];

    val?.forEach((n: any) => {
      if (n.kvIsChecked === true) {
        arr1.push(n.value);
      }
    });
    return arr1;

  }

  public async exportA3(includeFilters: boolean) {
    let a3Data = this.rows;
    let a3Months = this.a3Months;

    if (!includeFilters) {
      const res: any = await this.vslService.getA3ServiceViewData(
        {
          monthYearFilter: moment().format('MMM-YYYY'),
          pageNum: 1,
          pageSize: 20,
          vesselPlanningGroupMappings: '',
          lines: '',
          segments: '',
          showDryDock: false,
        },
        false
      );
      a3Data = res.a3ViewServiceTable;
      a3Months = res.monthsYears;
    }

    let headers = [
      { name: 'Route', filterButton: true, width: 10 },
      { name: 'Service', filterButton: true },
      { name: 'Vessel Name', filterButton: true },
      { name: 'Vessel Type', filterButton: true },
      { name: 'Operator', filterButton: true },
      { name: 'Nominal TEU.', filterButton: true },
      { name: '2M Nominal TEU', filterButton: true },
      { name: 'Beam', filterButton: true },
      { name: 'Plugs', filterButton: true },
      { name: 'Next Dry Dock Date', filterButton: true },
      { name: 'Start Date', filterButton: true, width: 15 },
      { name: 'End Date', filterButton: true, width: 15 },
      { name: 'Pos', filterButton: true, width: 8 },
      { name: 'CII', filterButton: true },
      { name: 'Fleet Risk Score', filterButton: true },
      { name: 'Scrubber', filterButton: true },
      { name: 'Flag', filterButton: true },
      { name: 'LOA', filterButton: true },
      { name: 'Deadweight', filterButton: true },
      { name: 'Scantling Draft', filterButton: true },
      { name: 'Keel-Mast Distance', filterButton: true },
      // { name: 'Total no. of reefer plugs', filterButton: true },
      { name: 'Cranes', filterButton: true },
      { name: 'AMP Compliance', filterButton: true },
      { name: 'Panama fitted', filterButton: true },
      { name: 'Segment', filterButton: true },
      { name: 'Comment', filterButton: true, width: 20 },
      { name: 'Start port', filterButton: true },
      { name: 'End port', filterButton: true },
      { name: 'Start region', filterButton: true },
      { name: 'End region', filterButton: true },
    ];

    if (includeFilters) {
      headers = headers.filter(item =>
        this.filterComp.columns.some(filterItem => (filterItem.name === item.name || item.name === 'Vessel Type'))
      );
    }

    const monthHeaders = a3Months.map(mon => ({ name: mon, filterButton: false, width: 7 }));
    headers = headers.concat(monthHeaders);

    const modifiedRows = a3Data.map((r) => {
      const monthData = {};
      this.a3Months.forEach((val, idx) => {
        monthData[val] = r['month' + (idx + 1)];
      });

      return {
        Route: r.routeCode,
        Service: r.serviceName,
        'Vessel Name': r.vessleName,
        'Start Date': r.depbegindate ? moment(new Date(r.depbegindate)).format('DD-MMM-YYYY') : null,
        'End Date': r.dependdate ? moment(new Date(r.dependdate)).format('DD-MMM-YYYY') : null,
        Pos: r.position,
        Operator: r.operator,
        'Nominal TEU.': r.nomianlTeu,
        Plugs: r.plugs,
        'Next Dry Dock Date': r.nextDryDock ? moment(new Date(r.nextDryDock)).format('DD-MMM-YYYY') : null,
        CII: r.cii,
        'Fleet Risk Score': r.fleetRisk,
        Scrubber: r.scrubber,
        Flag: r.vslFlag,
        LOA: r.loa,
        Beam: r.beam,
        Deadweight: r.deadWeight,
        'Scantling Draft': r.scantlingDraft,
        'Keel-Mast Distance': r.airDraft,
        Cranes: r.cranes,
        'AMP Compliance': r.amp,
        'Panama fitted': r.panamaFitted,
        Segment: r.segment,
        '2M Nominal TEU': r.nominalTEU2M,
        Comment: r.depComments,
        'Start port': r.startPort,
        'End port': r.endPort,
        'Start region': r.startRegion,
        'End region': r.endRegion,
        'Vessel Type': r.vesselType,
        'vesselColor': r.vesselColor,
        'serviceBorderClass': r.serviceBorderClass,
        'positionBorderClass': r.positionBorderClass,
        'phaseInMonth': r.phaseInMonth,
        'dryDockMonths': r.dryDockMonths,
        ...monthData
      };
    });
    const drydockFlag = includeFilters ? this.filterComp.dryDockToggle : false;
    this.excelService.generateA3Excel(headers, modifiedRows, drydockFlag);
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  /** */
  public fetchMore(e) {
    const len = this.noticesBuffer.length;
  }
  /** */
  /** */
  public showMore() {
    this.showElementsCount = this.showElementsCount + 10;
    this.showMoreIndex = ++this.showMoreIndex;
  }

  public sideMenuToggle() {
    setTimeout(() => {
      let event: Event;
      event = new Event('resize');
      window.dispatchEvent(event);
    }, 0);
    if (this.sidemenuCollapsed) {
      this.sidemenuCollapsed = false;
      this.uiHelperService.changeSidebarState('open');
    } else {
      this.sidemenuCollapsed = true;
      this.uiHelperService.changeSidebarState('close');
    }
  }

  public onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected);
  }

  public toggleVesselDetail(event) {
    if (event.type === 'close') {
      this.selectedVessel = null;
    } else if (this.selectedVessel?.vessleName === event.vessleName) {
      this.selectedVessel = null;
    } else {
      this.selectedVessel = event;
      this.selectedVessel.panamaFitted =
        event.panamaFitted === 0
          ? 'Yes Fitted for new locks'
          : event.panamaFitted === 1
            ? 'Yes Fitted for Old locks'
            : event.panamaFitted === 2
              ? 'Yes fitted for both old and new locks'
              : 'No';
    }
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
  }

  public filterColumn(selCol) {

    this.routeSelected = selCol.some(col => col.name === 'Route');

    this.serviceSelected = selCol.some(col => col.name === 'Service');

    this.vslNameSelected = selCol.some(col => col.name === 'Vessel Name');

    this.vslTypeSelected = selCol.some(col => col.name === 'Vessel Type');

    this.startDateSelected = selCol.some(col => col.name === 'Start Date');

    this.endDateSelected = selCol.some(col => col.name === 'End Date');

    this.positionSelected = selCol.some(col => col.name === 'Pos');

    this.operatorSelected = selCol.some(col => col.name === 'Operator');

    this.nominalTEUSelected = selCol.some(col => col.name === 'Nominal TEU.');

    this.plugsSelected = selCol.some(col => col.name === 'Plugs');

    this.nextDryDockDateSelected = selCol.some(col => col.name === 'Next Dry Dock Date');
    // added

    this.ciiSelected = selCol.some(col => col.name === 'CII');

    this.fleetRiskScoreSelected = selCol.some(col => col.name === 'Fleet Risk Score');

    this.scrubberSelected = selCol.some(col => col.name === 'Scrubber');

    this.flagSelected = selCol.some(col => col.name === 'Flag');

    this.loaSelected = selCol.some(col => col.name === 'LOA');

    this.beamSelected = selCol.some(col => col.name === 'Beam');

    this.deadWeightSelected = selCol.some(col => col.name === 'Deadweight');

    this.scantlingDraftSelected = selCol.some(col => col.name === 'Scantling Draft');

    this.keelMastSelected = selCol.some(col => col.name === 'Keel-Mast Distance');

    this.noOfReeferPlugsSelected = selCol.some(col => col.name === 'Total no. of reefer plugs');

    this.cranesSelected = selCol.some(col => col.name === 'Cranes');

    this.ampComplianceSelected = selCol.some(col => col.name === 'AMP Compliance');

    this.panamaFittedSelected = selCol.some(col => col.name === 'Panama fitted');

    this.segmentSelected = selCol.some(col => col.name === 'Segment');

    this.nominalTEU2MSelected = selCol.some(col => col.name === '2M Nominal TEU');

    this.commentSelected = selCol.some(col => col.name === 'Comment');

    this.startPortSelected = selCol.some(col => col.name === 'Start port');

    this.endPortSelected = selCol.some(col => col.name === 'End port');

    this.startRegionSelected = selCol.some(col => col.name === 'Start region');

    this.endRegionSelected = selCol.some(col => col.name === 'End region');
  }

  // public dryDockToggled(e) {
  //   this.hideDryDock = e.flag;
  // }

  public saveFilter() {
    const selectedColumns = this.filterComp.columns.map(col => col.name);
    this.saveFilters.DistColumns = selectedColumns.join(',');

    // Commented saving column filters for now
    this.saveFilters.DistServiceName = null; // this.filtersCtrls.serviceCtrl.value.length > 0 ? this.filtersCtrls.serviceCtrl.value.toString() : null;

    this.saveFilters.DistVesselName = null; // this.filtersCtrls.vslNameCtrl.value.length > 0 ? this.filtersCtrls.vslNameCtrl.value.toString() : null;

    this.saveFilters.DistVesselType = null;  // this.filtersCtrls.vslTypeCtrl.value.length > 0 ? this.filtersCtrls.vslTypeCtrl.value.toString() : null;

    this.saveFilters.DistRoute = null; // this.filtersCtrls.routeCtrl.value.length > 0 ? this.filtersCtrls.routeCtrl.value.toString() : null;

    this.saveFilters.DistPosition = null; // this.filtersCtrls.positionCtrl.value.length > 0 ? this.filtersCtrls.positionCtrl.value.toString() : null;

    this.saveFilters.DistOperator = null; // this.filtersCtrls.operatorCtrl.value.length > 0 ? this.filtersCtrls.operatorCtrl.value.toString() : null;

    this.saveFilters.DistCii = null; // this.filtersCtrls.ciiCtrl.value.length > 0 ? this.filtersCtrls.ciiCtrl.value.toString() : null;

    this.saveFilters.DistScrubber = null; // this.filtersCtrls.scrubberCtrl.value.length > 0 ? this.filtersCtrls.scrubberCtrl.value.toString() : null;

    this.saveFilters.DistFlag = null; // this.filtersCtrls.flagCtrl.value.length > 0 ? this.filtersCtrls.flagCtrl.value.toString() : null;

    this.saveFilters.DistAMP = null; // this.filtersCtrls.ampCtrl.value.length > 0 ? this.filtersCtrls.ampCtrl.value.toString() : null;

    this.saveFilters.DistPanamaFitted = null; // this.filtersCtrls.panamaCtrl.value.length > 0 ? this.filtersCtrls.panamaCtrl.value.toString() : null;

    this.saveFilters.DistSegment = null; // this.filtersCtrls.segmentCtrl.value.length > 0 ? this.filtersCtrls.segmentCtrl.value.toString() : null;

    this.saveFilters.DistStartPort = null; // this.filtersCtrls.startPortCtrl.value.length > 0 ? this.filtersCtrls.startPortCtrl.value.toString() : null;

    this.saveFilters.DistEndPort = null; // this.filtersCtrls.endPortCtrl.value.length > 0 ? this.filtersCtrls.endPortCtrl.value.toString() : null;

    this.saveFilters.DistStartRegion = null; // this.filtersCtrls.startRegionCtrl.value.length > 0 ? this.filtersCtrls.startRegionCtrl.value.toString() : null;

    this.saveFilters.DistEndRegion = null; // this.filtersCtrls.endRegionCtrl.value.length > 0 ? this.filtersCtrls.endRegionCtrl.value.toString() : null;

    // range filters: min values

    this.saveFilters.Min_Beam = null; // this.filtersCtrls.beamCtrl.fromValCtrl.value;

    this.saveFilters.Min_Deadweight = null; // this.filtersCtrls.deadWeightCtrl.fromValCtrl.value;

    this.saveFilters.Min_Fleetriskcore = null; // this.filtersCtrls.fleetRiskScoreCtrl.fromValCtrl.value;

    this.saveFilters.Min_keelmast = null; // this.filtersCtrls.keelMastCtrl.fromValCtrl.value;

    this.saveFilters.Min_loa = null; // this.filtersCtrls.loaCtrl.fromValCtrl.value;

    this.saveFilters.Min_plugs = null; // this.filtersCtrls.plugsCtrl.fromValCtrl.value;

    this.saveFilters.Min_cranes = null; // this.filtersCtrls.cranesCtrl.fromValCtrl.value;

    this.saveFilters.Min_scantlingDraft = null; // this.filtersCtrls.scantlingDraftCtrl.fromValCtrl.value;

    this.saveFilters.Min_Totalreeferplugs = null; // this.filtersCtrls.reeferCtrl.fromValCtrl.value;

    this.saveFilters.Min_NominalTEU = null; // this.filtersCtrls.nominalTeuCtrl.fromValCtrl.value;

    this.saveFilters.Min_Nominal2MTEU = null; // this.filtersCtrls.nominal2MTeuCtrl.fromValCtrl.value;

    // range filters: max values

    this.saveFilters.Max_Beam = null; // this.filtersCtrls.beamCtrl.toValCtrl.value;

    this.saveFilters.Max_Deadweight = null; // this.filtersCtrls.deadWeightCtrl.toValCtrl.value;

    this.saveFilters.Max_Fleetriskcore = null; // this.filtersCtrls.fleetRiskScoreCtrl.toValCtrl.value;

    this.saveFilters.Max_keelmast = null; // this.filtersCtrls.keelMastCtrl.toValCtrl.value;

    this.saveFilters.Max_loa = null; // this.filtersCtrls.loaCtrl.toValCtrl.value;

    this.saveFilters.Max_plugs = null; // this.filtersCtrls.plugsCtrl.toValCtrl.value;

    this.saveFilters.Max_cranes = null; // this.filtersCtrls.cranesCtrl.toValCtrl.value;

    this.saveFilters.Max_scantlingDraft = null; // this.filtersCtrls.scantlingDraftCtrl.toValCtrl.value;

    this.saveFilters.Max_Totalreeferplugs = null; // this.filtersCtrls.reeferCtrl.toValCtrl.value;

    this.saveFilters.Max_NominalTEU = null; // this.filtersCtrls.nominalTeuCtrl.toValCtrl.value;

    this.saveFilters.Max_Nominal2MTEU = null; // this.filtersCtrls.nominal2MTeuCtrl.toValCtrl.value;

    // dates filter

    this.saveFilters.min_StartDate = null; // this.filtersCtrls.startDateMultiCtrl.fromDateCtrl.value;

    this.saveFilters.max_StartDate = null; // this.filtersCtrls.startDateMultiCtrl.toDateCtrl.value;

    this.saveFilters.min_EndDate = null; // this.filtersCtrls.endDateMultiCtrl.fromDateCtrl.value;

    this.saveFilters.max_EndDate = null; // this.filtersCtrls.endDateMultiCtrl.toDateCtrl.value;

    this.saveFilters.min_NextDryDockDate = null; // this.filtersCtrls.nextDryDockDateMultiCtrl.fromDateCtrl.value;

    this.saveFilters.max_NextDryDockDate = null; // this.filtersCtrls.nextDryDockDateMultiCtrl.toDateCtrl.value;

    this.saveFilters.userId = this.userId;
    // Call new API here

    this.vslService.getA3SavedFilters(this.saveFilters).subscribe((res) => {
      if (res === 1) {
        // Call API here
        this.getA3Report();
        // this.allFiltered();
      }
    });
  }

  /** */
  public allFiltered() {
    /* chaining the filters */
    this.filteredArray = this.setFilter(this.initialList, 'vslNameCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'vslTypeCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'serviceCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'ampCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'ciiCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'beamCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'cranesCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'deadWeightCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'endPortCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'endRegionCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'flagCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'fleetRiskScoreCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'keelMastCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'loaCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'operatorCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'panamaCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'plugsCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'positionCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'routeCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'scantlingDraftCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'scrubberCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'segmentCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'startPortCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'startRegionCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'reeferCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'nominalTeuCtrl');
    this.filteredArray = this.numberFilter(this.filteredArray, 'nominal2MTeuCtrl');
    this.filteredArray = this.datesFilter(this.filteredArray, 'startDateMultiCtrl');
    this.filteredArray = this.datesFilter(this.filteredArray, 'endDateMultiCtrl');
    this.filteredArray = this.datesFilter(this.filteredArray, 'nextDryDockDateMultiCtrl');
    this.rows = this.filteredArray ? [...this.filteredArray] : null;
  }
  public getFilteredLines(e) {
    this.allFiltered();
  }

  public getfilterIcon(ctrl: any, ctrl2: any = null, type: string = null) {
    // date filter
    if (type === 'date') {
      if (ctrl || ctrl2) {
        return '/assets/icons/filter-active.svg';
      }
      return '/assets/icons/filter.svg';
    }
    // number filter
    if (ctrl2) {
      if (ctrl?.value || ctrl2?.value) {
        return '/assets/icons/filter-active.svg';
      }
      return '/assets/icons/filter.svg';
    }
    // For multiselect dropdown
    if (ctrl?.value?.length > 0 || (ctrl2 && ctrl2?.value?.length > 0)) {
      return '/assets/icons/filter-active.svg';
    }
    return '/assets/icons/filter.svg';
  }

  public checkFilterActive(filter: any) {
    return true;

  }

  // public openSelect(selectType: string) {
  //   console.log(selectType);
  //   this[selectType].open();
  // }
  /**
   *
   * @param vesselToBeFiltered
   * @param ctrl
   */
  private setFilter(vesselToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'vslNameCtrl':
        key = 'vessleName';
        break;
      case 'vslTypeCtrl':
        key = 'vesselType';
        break;
      case 'serviceCtrl':
        key = 'serviceName';
        break;
      case 'ampCtrl':
        key = 'amp';
        break;
      case 'ciiCtrl':
        key = 'cii';
        break;
      case 'cranesCtrl':
        key = 'craine';
        break;
      case 'endPortCtrl':
        key = 'endPort';
        break;
      case 'endRegionCtrl':
        key = 'endRegion';
        break;
      case 'flagCtrl':
        key = 'vslFlag';
        break;
      case 'operatorCtrl':
        key = 'operator';
        break;
      case 'panamaCtrl':
        key = 'panamaFitted';
        break;
      case 'positionCtrl':
        key = 'position';
        break;
      case 'routeCtrl':
        key = 'routeCode';
        break;
      case 'scrubberCtrl':
        key = 'scrubber';
        break;
      case 'segmentCtrl':
        key = 'segment';
        break;
      case 'startPortCtrl':
        key = 'startPort';
        break;
      case 'startRegionCtrl':
        key = 'startRegion';
        break;
      default:
        key = '';
        break;
    }

    if (this.filtersCtrls[ctrl].value && this.filtersCtrls[ctrl].value.length > 0) {
      return vesselToBeFiltered.filter((dummy) => this.filtersCtrls[ctrl].value.indexOf(dummy[key]) > -1);
    }
    return vesselToBeFiltered;
  }

  private datesFilter(vesselsToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'startDateMultiCtrl':
        key = 'depbegindate';
        break;
      case 'endDateMultiCtrl':
        key = 'dependdate';
        break;
      case 'nextDryDockDateMultiCtrl':
        key = 'nextDryDock';
        break;
      default:
        key = '';
        break;
    }
    const fromDateValue =
      this.filtersCtrls[ctrl].fromDateCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].fromDateCtrl.value, 'dd-MMM-yyyy'))
        : null;

    const toDateValue =
      this.filtersCtrls[ctrl].toDateCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].toDateCtrl.value, 'dd-MMM-yyyy'))
        : null;

    if (fromDateValue !== null && toDateValue !== null) {

      return vesselsToBeFiltered.filter(
        (notice) =>
          new Date(new DatePipe('en-US').transform(notice[key], 'dd-MMM-yyyy')) >= fromDateValue &&
          new Date(new DatePipe('en-US').transform(notice[key], 'dd-MMM-yyyy')) <= toDateValue
      );
    } else if (fromDateValue === null && toDateValue !== null) {
      return vesselsToBeFiltered.filter(
        (notice) => new Date(new DatePipe('en-US').transform(notice[key], 'dd-MMM-yyyy')) <= toDateValue
      );
    } else if (toDateValue === null && fromDateValue !== null) {
      return vesselsToBeFiltered.filter(
        (notice) => new Date(new DatePipe('en-US').transform(notice[key], 'dd-MMM-yyyy')) >= fromDateValue
      );
    } else {
      return vesselsToBeFiltered;
    }
  }

  /**
   *
   * @param vesselToBeFiltered
   * @param ctrl
   */
  private numberFilter(vesselsToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'deadWeightCtrl':
        key = 'deadWeight';
        break;
      case 'fleetRiskScoreCtrl':
        key = 'fleetRisk';
        break;
      case 'beamCtrl':
        key = 'beam';
        break;
      case 'keelMastCtrl':
        key = 'airDraft';
        break;
      case 'reeferCtrl':
        key = 'totalnumberofreeferplugs';
        break;
      case 'nominalTeuCtrl':
        key = 'nomianlTeu';
        break;
      case 'nominal2MTeuCtrl':
        key = 'nomianlTeu';
        break;
      case 'scantlingDraftCtrl':
        key = 'scantlingDraft';
        break;
      case 'loaCtrl':
        key = 'loa';
        break;
      case 'plugsCtrl':
        key = 'plugs';
        break;
      case 'cranesCtrl':
        key = 'cranes';
        break;
      default:
        key = '';
        break;
    }

    if (this.filtersCtrls[ctrl].fromValCtrl.value && this.filtersCtrls[ctrl].toValCtrl.value) {
      return vesselsToBeFiltered.filter(
        (vessel) =>
          vessel[key] >= this.filtersCtrls[ctrl].fromValCtrl.value &&
          vessel[key] <= this.filtersCtrls[ctrl].toValCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toValCtrl.value) {
      return vesselsToBeFiltered.filter(
        (vessel) => vessel[key] <= this.filtersCtrls[ctrl].toValCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromValCtrl.value) {
      return vesselsToBeFiltered.filter(
        (vessel) => vessel[key] >= this.filtersCtrls[ctrl].fromValCtrl.value
      );
    } else {
      return vesselsToBeFiltered;
    }
  }
}
