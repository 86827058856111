//

import { Component, OnInit, ViewChildren, QueryList, HostListener, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromVesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';
import * as fromVesselQuestionnaire from 'src/app/state/vessels/reducers/vessel-questionnaire.reducer';
import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { CanDeactivateGuard } from 'src/app/modules/can-deactivate/services/can-deactivate-guard/can-deactivate.guard';
import { CanDeactivateDialogService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog/can-deactivate-dialog.service';
import { CanDeactivateDialogToggleService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog-toggle/can-deactivate-dialog-toggle.service';
import { Subject } from 'rxjs';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Component({
  selector: 'champs-vessel-new',
  templateUrl: './vessel-new.component.html',
  styleUrls: ['./vessel-new.component.scss']
})
export class VesselNewComponent implements CanDeactivateGuard, OnInit, OnDestroy {

  private totalStep = 8;
  @ViewChildren('accPanel', { read: ElementRef }) private accPanels: QueryList<ElementRef>;

  private formObjArr: any = [
    'generalInformation',
    'vesselSpecification',
    'machineryPerticulars',
    'speedAndConsumption',
    'communication',
    'cargoCapLayout',
    'plansDocCert',
    'additionalInfo'
  ];

  public step: any = 1;
  public formObj: any = {};
  public duplicateIssue: any;
  public RKSTIssue: any;
  public rkstObj: any;

  public vesselDetails: any;
  public vesselOriginalDetails: any;
  public route: string;
  public id: any;
  public requiredCtrl: UntypedFormControl = new UntypedFormControl();
  public requiredFltrValue: string = null;

  public showCtrl: UntypedFormControl = new UntypedFormControl();
  public showFltrValue: Subject<string> = new Subject();

  public submitSubject: Subject<any> = new Subject();
  public cancelSubject: Subject<any> = new Subject();

  public hideSaveButton: boolean = false;

  constructor(
    private router: Router,
    private vesselsService: VesselsService,
    private dialogService: CanDeactivateDialogService,
    private dialogToggleService: CanDeactivateDialogToggleService,
    private store: Store<fromVesselCreate.State>,
    private alertService: AlertService
  ) {

    this.route = this.router.url;
    this.requiredCtrl.setValue('all');
    this.showCtrl.setValue('all');

    if (this.route.includes('/vessels/import')) {
      this.store.pipe(select(fromVesselQuestionnaire.getQuestionnaireFormData))
        .pipe(distinctUntilChanged())
        .subscribe((data) => {
          this.vesselDetails = data.vesselDetails;
          this.vesselOriginalDetails = data.vesselOriginalDetails;
        });
    } else if (this.route.includes('/vessels/new')) {
      this.store.pipe(select(fromVesselCreate.getVesselFormData))
        .subscribe((data) => {
          this.vesselDetails = data;
          if (this.vesselDetails.vmVesselName && this.vesselDetails.vmMONumber) {
            this.alertService.clear();
            this.router.navigate(['/vessels/' + this.vesselDetails.vslPKID + '/details']);
            const msg = 'Vessel identified: IMO ' + this.vesselDetails.vmMONumber + ' – ' + this.vesselDetails.vmVesselName + ' of ' + this.vesselDetails.vslCountryforReg;
            this.alertService.success(msg, true);
          }
        });
    }
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.store.pipe(
      select(fromVesselCreate.getVesselFormGrp),
      distinctUntilChanged()
    ).subscribe((val) => {
      this.formObj = val;
    });

    this.showFltrValue.subscribe((val) => {
      this.showCtrl.setValue(val);
    });
  }

  /**
   * dummy comment
   */
  @HostListener('window:beforeunload', ['$event'])
  public canDeactivate(e: any = null): any {
    const chkResult = this.checkFormDirty();
    if (chkResult) {
      return true;
    } else {
      if (e) {
        return e.returnValue = 'This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)';
      } else {
        this.dialogService.openDialog('Hello',
          `You are about to redirect to another page. The changes will be lost.
          Are you sure you want to leave the page?`
        );
        return this.dialogToggleService.canDeactivateToggle;
      }
    }
  }

  public setStep(index: number = null) {

    const step = index ? index : (this.step < this.totalStep ? ++this.step : this.step);

    if (!this.formObjArr[step - 2] && step > 1) {
      return;
    }

    this.step = step;

    if (this.accPanels) {
      const el = this.accPanels.toArray()[this.step - 1].nativeElement;
      setTimeout(() => {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
      }, 350);
    }
  }

  public showDuplicateError(e) {
    this.duplicateIssue = null;
    if (e.value) {
      this.duplicateIssue = e.value;
    }

  }
  public showRKSTMessage(e) {
    this.RKSTIssue = e.value;
    this.id = e.id;
    this.hideSaveButton = true;
    this.step++;
  }

  public disabledSubmit() {
    const unsetCount = this.formObjArr.reduce((res, value) => {
      if (!this.formObj[value] && value !== 'additionalInfo') {
        ++res;
      }
      return res;
    }, 0);
    return unsetCount > 0 ? true : false;
  }

  public rqdFilterChanged(e) {
    this.requiredFltrValue = e.value;
  }

  public showFilterChanged(e) {
    this.showFltrValue.next(e.value);
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this.store.dispatch(new VesselCreateActions.ResetCreateType());
    this.store.dispatch(new VesselCreateActions.ResetCreateVessel());
    this.store.dispatch(new VesselCreateActions.ResetCreateVesselObj());
  }

  private checkFormDirty(): any {
    const changed = Object.keys(this.formObj).reduce((res, val) => {
      if (this.formObj[val]) {
        return ++res;
      }
      return res;
    }, 0);

    if (changed > 0) {
      return false;
    } else {
      return true;
    }
  }

}
