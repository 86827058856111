// Angular
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// Service
import { FeatureToggleService } from '../../core/services/feature-toggle/feature-toggle.service';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
// Interface
import { FeatureToggle } from './featureToggle.interface';
@Component({
  selector: 'champs-feature-toggle',
  templateUrl: './feature-toggle.component.html',
  styleUrls: ['./feature-toggle.component.scss']
})
export class FeatureToggleComponent implements OnInit {
  public displayedColumns = ['ftName', 'ftValue'];
  public isAuthorized = false;
  public noRecordsToDisplay = false;
  public dataSource: MatTableDataSource<FeatureToggle>;
  constructor(private featureToggleService: FeatureToggleService, private auth: AuthService) {
  }

  public ngOnInit() {
    this.isAuthorized = this.auth.isAuthorised(this.auth.roles, 'Feature Toggle', 'update');
    this.getFeatureToggleList();
  }

  public getFeatureToggleList() {
    const data = JSON.parse(localStorage['cachedFeatureToggles'] || '[]');
    this.dataSource = new MatTableDataSource(data);
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.noRecordsToDisplay = (this.dataSource.filteredData.length <= 0) ? true : false;
  }

  public updateFeatureToggle(selectedItem: FeatureToggle) {
    this.featureToggleService.updateFeatureToggle(selectedItem).subscribe();
    this.featureToggleService.getFeatureToggleList();
  }
}
