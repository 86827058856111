//

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CanDeactivateDialogComponent } from '../../components/can-deactivate-dialog/can-deactivate-dialog.component';

interface IButtonslabel {
  yes: string;
  no: string;
}
const BUTTONS_LABEL: IButtonslabel = {
  yes: 'LEAVE',
  no: 'Cancel',
};

@Injectable()
export class CanDeactivateDialogService {
  private dialogRef: MatDialogRef<any>;
  constructor(private dialog: MatDialog) {
    this.dialogRef = null;
  }

  public openDialog(title, message, buttonsLabels: IButtonslabel = null) {
    this.dialogRef = this.dialog.open(CanDeactivateDialogComponent, {
      width: '500px',
      panelClass: 'dialog-container',
      data: {
        title,
        message,
        buttons: {
          ...BUTTONS_LABEL,
          ...buttonsLabels,
        },
      },
      disableClose: true,
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
