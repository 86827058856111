import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'globalDateFormatPipe',
})
export class ChampsDateFormatPipe implements PipeTransform {
    public transform(value: string, args?: string) {
        let datePipe = new DatePipe('en-US');
        if (args === 'dateTime') {
            value = datePipe.transform(value, 'dd-MMM-yyyy HH:mm');
        } else if (args === 'dateTimeSec') {
            value = datePipe.transform(value, 'dd-MMM-yyyy HH:mm:ss');
        } else if (args === 'monthYear') {
            value = datePipe.transform(value, 'MMM-yyyy');
        } else if (args === 'hourMins') {
            value = datePipe.transform(value, 'HH-mm');
        } else if (args === 'hourMinSec') {
            value = datePipe.transform(value, 'HH-mm:ss');
        } else {
            value = datePipe.transform(value, 'dd-MMM-yyyy');
        }
        return value;
    }
}
