import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NpsFeedbackService {
  private apiUrl = 'https://insightshub-api.maersk-digital.net/api/feedbacks';
  private localStorageKey = 'feedbackSubmissionDate';
  private additionalDateKey = 'notificationDate';
  public reminderFlag = false;
  public flag: boolean = false;
  constructor(private http: HttpClient) { }

  public postFeedback(rating: number, comment: string, userName, userEmail, feedbackDisplayId, feedbackId, QuestionIds:{qsn1, qsn2}): Observable<any> {
    const qsn1 = QuestionIds.qsn1;
    const qsn2 = QuestionIds.qsn2;
    const requestBody = {
      form_data: {
        [qsn1] : rating.toString(),
        [qsn2] : comment
      },
      meta_data: {
        host: 'insightshub.dev.maersk-digital.net',
        pathname: `/form/feedback/${feedbackId}`, 
        pagetitle: ''
      },
      hidden: { email: userEmail, name: userName }
    };
    return this.http.post<any>(this.apiUrl+'/'+feedbackId+'/submit?author='+userEmail, requestBody);
  }

  public validateSubmissionDate(): Observable<any> {
    console.log("Entered validateSubmissionDate")
    const lastSubmissionDate = localStorage.getItem(this.localStorageKey);
    // let lastSubmissionDate ='2024-03-09T10:06:45.263Z';
    let todayDate = new Date();
    // let todayDate = new Date('2024-06-17T10:06:45.263Z');
    if (!lastSubmissionDate) {
      const dayOfYear = this.getDayOfYear(todayDate);
      if (dayOfYear >= 75) {
        return of(this.reminderFlag = true);
      }
    } else {
      const submissionDate = new Date(lastSubmissionDate);
      const currentQuarter = this.getQuarter(todayDate);
      const submissionQuarter = this.getQuarter(submissionDate);
      if (currentQuarter !== submissionQuarter) {
        const dayOfYear = this.getDayOfYear(todayDate);
        if (dayOfYear >= 75) {
          return of(this.reminderFlag = true);
        }
      }
    }
    return of(this.reminderFlag = false);
  }
  private getQuarter(date: Date): number {
    return Math.floor((date.getMonth() / 3) + 1);
  }

  private getDayOfYear(date: Date): number {
    const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    const quarterStartDate = new Date(date.getFullYear(), quarterStartMonth, 1);
    const diff = date.getTime() - quarterStartDate.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay) + 1;
  }

  public isAdditionalDateNotToday(): boolean {
    const additionalDate = localStorage.getItem(this.additionalDateKey);
    if(!additionalDate){
      return true;
    }
    const todayDate = new Date();
    const storedDate = new Date(additionalDate);
    return storedDate.getFullYear() !== todayDate.getFullYear() ||
           storedDate.getMonth() !== todayDate.getMonth() ||
           storedDate.getDate() !== todayDate.getDate();
  }
}
