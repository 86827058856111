//

import { Component, OnInit, ViewChild, HostListener, OnDestroy, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { takeUntil, filter, take, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { Validators } from '@angular/forms';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { BuildYardService } from 'src/app/core/services/build-yard/build-yard.service';
import { AppInsightBaseComponent } from 'src/app/components/app-insights/app-insight-base/app-insight-base.component';
import { AuthService } from '../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'app-vessel-list',
  templateUrl: './vessel-list.component.html',
  styleUrls: ['./vessel-list.component.scss'],
})
export class VesselListComponent extends AppInsightBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  private firstCalled: boolean = false;
  private params: any = null;
  private createFormGrp: UntypedFormGroup;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  private vesselsBuffer = [];
  private imoBuffer = [];
  private bufferSize = 50;

  private vesselList: any[] = [];
  private showMoreIndex: number = 0;
  private filteredArray: any[] = [];
  private dialogRef: MatDialogRef<any>;

  @ViewChild(DatatableComponent, { static: false }) public table: DatatableComponent;

  @ViewChild('createForm', { static: false }) public createForm: any;
  public showCreateFrmErrors: boolean = false;

  public rows: any[] = [];
  public mobile: boolean = false;
  public removable: boolean = true;
  public showMoreVesselsFlag: boolean = true;
  public teuRangeChipString: string;
  public vesselCodeChipString: string;
  public filtersList: FilterList = null;
  public vesselNames: any[] = [];
  public imoNumbers: any[] = [];
  public reorderable: boolean = true;
  public isVsLstUpdateAuth: boolean;

  public showElementsCount: number = 50;

  public showIMOFrm = true;
  public showMinMaxError: boolean = false;

  public filtersCtrls = {
    vesselMultiCtrl: new UntypedFormControl(),
    vesselCodeMultiCtrl: new UntypedFormControl(),
    imoMultiCtrl: new UntypedFormControl(),
    operatorMultiCtrl: new UntypedFormControl(),
    typeMultiCtrl: new UntypedFormControl(),
    teuMultiCtrl: {
      fromTeuMultiCtrl: new UntypedFormControl(),
      toTeuMultiCtrl: new UntypedFormControl(),
    },
    serviceMultiCtrl: new UntypedFormControl(),
    vslGrpMultiCtrl: new UntypedFormControl(),
    ownerMultiCtrl: new UntypedFormControl(),
    statusMultiCtrl: new UntypedFormControl(),
  };

  public paramList: ParamsList = {
    vessels: [],
    vesselCodes: '',
    imo: [],
    operators: [],
    types: [],
    from: '',
    to: '',
    services: [],
    groups: [],
    owners: [],
    statuses: [],
    sortBy: '',
    sortDir: '',
    more: 0,
  };

  public filterSearchControls = {
    operatorFilterSearchCtrl: new UntypedFormControl(),
    serviceFilterSearchCtrl: new UntypedFormControl(),
    ownerFilterSearchCtrl: new UntypedFormControl(),
    contactFilterSearchCtrl: new UntypedFormControl(),
  };

  /** list of elements filtered by search keyword for multi-selection */
  public filteredServicesMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredOwnersMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredContactMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  /** Label of the search placeholder */
  public placeholderLabel = 'Search';

  /** Label to be shown when no entries are found. Set to null if no message should be shown. */
  public noEntriesFoundLabel = 'Nothing found';

  public buildYards$: Observable<any>;
  public isDummyAuth: boolean;

  // tslint:disable-next-line:member-ordering
  constructor(
    private vesselsService: VesselsService,
    private location: Location,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private buildYardService: BuildYardService,
    private store: Store<any>,
    private auth: AuthService,
    private titleService: Title
  ) {
    super();
    this.buildYards$ = this.buildYardService.getBuildYards();

    if (window.screen.width < 600) {
      this.mobile = true;
    }
    this.activatedRoute.queryParams
      .pipe(filter((x) => x !== undefined))
      .pipe(take(1))
      .subscribe((params) => {
        this.params = params;
        Object.keys(params).forEach((key) => {
          if (key === 'from' || key === 'to') {
            this.paramList[key] = params[key];
          }
          if (key === 'sortBy' || key === 'sortDir') {
            this.paramList[key] = params[key];
          }
          if (key === 'statuses') {
            this.paramList[key] = params[key].split(',').map((val) => parseInt(val, 10));
          }
          if (key === 'more') {
            this.showMoreIndex = this.showMoreIndex + parseInt(params[key] || '0', 10);
            this.paramList[key] = this.showMoreIndex;
            this.showElementsCount = 50 * (this.showMoreIndex + 1);
          } else {
            this.paramList[key] = params[key].split(',');
          }
        });
      });
  }

  // listening for screen width change
  @HostListener('window: resize', ['$event'])
  public onWindowResize(event) {
    if (event.target.screen.width < 600) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsLstUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    sessionStorage.removeItem('champsVesselImoNumber');
      this.isDummyAuth = this.auth.userProfile
        ? this.auth.userProfile.roles.includes('L3') || this.auth.userProfile.roles.includes('Deployment')
        : false;
    this.getVesselLists();

    this.createFormGrp = this.fb.group({
      imoNumber: this.fb.control('', [
        Validators.minLength(7),
        Validators.maxLength(7),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      hullnumber: this.fb.control('', [Validators.maxLength(50)]),
      buildyard: '',
    });
    this.titleService.setTitle('CHAMPS ' + ':' + ' ' + 'Vessels');
  }

  /**
   * after view checked life-cycle hook
   */
  public ngAfterViewChecked() {
    this.filterSearchControls.serviceFilterSearchCtrl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.sortFilterBySearch('serviceFilterSearchCtrl');
      });
    this.filterSearchControls.ownerFilterSearchCtrl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.sortFilterBySearch('ownerFilterSearchCtrl');
      });
    this.filterSearchControls.contactFilterSearchCtrl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.sortFilterBySearch('contactFilterSearchCtrl');
      });
    if (this.filtersCtrls.vesselCodeMultiCtrl.value) {
      this.filtersCtrls.vesselCodeMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.vesselCodeMultiCtrl);
        this.allFiltered();
      });
    }
    if (this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value && this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value) {
      this.showMinMaxError = false;
      if (this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value > this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value) {
        this.showMinMaxError = true;
        return;
      } else {
        this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
        this.allFiltered();
      });
    }
  }

  /**
   *
   * @param filterControl
   */
  public filterDropdownIconUrlSetter(filterControl): string {
    if (!(filterControl instanceof UntypedFormControl)) {
      if (filterControl.fromTeuMultiCtrl.value || filterControl.toTeuMultiCtrl.value) {
        return 'assets/icons/tick.svg';
      } else {
        return 'assets/icons/arrow-dropdown.svg';
      }
    }

    if (filterControl.value && filterControl.value.length > 0) {
      return 'assets/icons/tick.svg';
    } else {
      return 'assets/icons/arrow-dropdown.svg';
    }
  }

  /**
   *
   * @param row
   */
  public openRowDetails(row) {
    row.expand = !row.expand;
    this.table.rowDetail.toggleExpandRow(row);
  }

  /** */
  public showMore() {
    this.showElementsCount = this.showElementsCount + 50;
    this.showMoreIndex = ++this.showMoreIndex;
    this.paramList.more = this.showMoreIndex;
    this.setRequestFilter();
  }

  /** */
  public allFiltered() {
    /* chaining the filters */
    // this.filteredArray = this.setFilterred(this.vesselList, 'vesselMultiCtrl');
    this.filteredArray = this.vesselNameFilter(this.vesselList, 'vesselMultiCtrl');
    this.filteredArray = this.vesselCodeFilter(this.filteredArray, 'vesselCodeMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'imoMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'operatorMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'typeMultiCtrl');
    this.filteredArray = this.teuFilter(this.filteredArray, 'teuMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'serviceMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'vslGrpMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'statusMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'ownerMultiCtrl');
    this.rows = this.filteredArray ? [...this.filteredArray] : null;
    this.setRequestFilter();
  }

  /**
   *
   * @param fltr
   * @param ctrl
   */
  public remove(fltr: any, ctrl: string = null): void {
    /* remove all filters chip */
    if (fltr === 'all') {
      this.filtersCtrls.vesselMultiCtrl.setValue([]);
      this.filtersCtrls.vesselCodeMultiCtrl.setValue(null);
      this.filtersCtrls.imoMultiCtrl.setValue([]);
      this.filtersCtrls.operatorMultiCtrl.setValue([]);
      this.filtersCtrls.typeMultiCtrl.setValue([]);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);
      this.filtersCtrls.serviceMultiCtrl.setValue([]);
      this.filtersCtrls.vslGrpMultiCtrl.setValue([]);
      this.filtersCtrls.statusMultiCtrl.setValue([]);
      this.filtersCtrls.ownerMultiCtrl.setValue([]);
      this.filteredArray = [];

      this.allFiltered();
      return;
    }
    if (fltr === 'code') {
      this.filtersCtrls.vesselCodeMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }

    if (fltr === 'teu') {
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }

    const removeFilterIndex = this.filtersCtrls[ctrl].value ? this.filtersCtrls[ctrl].value.indexOf(fltr) : -1;

    if (removeFilterIndex >= 0) {
      this.filtersCtrls[ctrl].value.splice(removeFilterIndex, 1);
      this.filtersCtrls[ctrl].setValue(this.filtersCtrls[ctrl].value);
    }

    this.allFiltered();
  }

  public fetchMore(e) {
    const len = this.vesselsBuffer.length;
    const more = this.vesselNames.slice(len, this.bufferSize + len);
    this.vesselsBuffer = this.vesselsBuffer.concat(more);
  }
  public fetchMoreImo(e) {
    const len = this.imoBuffer.length;
    const more = this.imoNumbers.slice(len, this.bufferSize + len);
    this.imoBuffer = this.imoBuffer.concat(more);
  }

  /**
   *
   * @param e
   */
  public onDataTableSort(e) {
    setTimeout(() => {
      const sort = e.sorts[0];
      this.paramList.sortBy = sort.prop;
      this.paramList.sortDir = sort.dir;

      this.setRequestFilter();
    }, 0);
  }

  public showCreateForm() {
    this.dialogRef = this.dialog.open(this.createForm, {
      width: '500px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }

  /**
   * teu filter
   * @param vesselsToBeFiltered
   */
  public vesselNameFilter(vesselsToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'vesselMultiCtrl':
        key = 'status';
        break;
      default:
        key = '';
        break;
    }
    if (this.filtersCtrls['vesselMultiCtrl'].value && this.filtersCtrls['vesselMultiCtrl'].value.length > 0) {

      let vesselfilter =  vesselsToBeFiltered.filter(
        (vessel) =>
          this.filtersCtrls['vesselMultiCtrl'].value.indexOf(vessel['vslVesselName']) > -1 ||
          this.filtersCtrls['vesselMultiCtrl'].value.indexOf(vessel['vslExName']) > -1
      );
      return vesselfilter.filter(flt => flt.vslIsActive === 1);
    }
    return vesselsToBeFiltered;
  }

  public toggleCreateForm() {
    this.showCreateFrmErrors = false;
    this.showIMOFrm = !this.showIMOFrm;
    this.createFormGrp.get('imoNumber').reset();
    this.createFormGrp.get('hullnumber').reset();
    this.createFormGrp.get('buildyard').reset();
    this.createFormGrp.setValue({
      imoNumber: null,
      hullnumber: null,
      buildyard: null,
    });
  }

  public submitCreateForm() {
    sessionStorage.removeItem('champsVesselImoNumber')
    if (!this.validateCreateForm()) {
      return;
    }

    const frmData = this.createFormGrp.value;

    let postData = null;

    if (this.showIMOFrm) {
      postData = {
        imoNumber: frmData.imoNumber,
      };
    } else {
      postData = {
        hullnumber: frmData.hullnumber,
        buildYardId: frmData.buildyard,
      };
    }

    this.vesselsService
      .getVesselDetailForCreate(postData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(() => this.router.navigate(['/vessels/new']));
        let data = res
          ? res
          : this.showIMOFrm
            ? {
              vmMONumber: frmData.imoNumber,
            }
            : {
              vslBuildYardID: frmData.buildyard,
              vslhullno: frmData.hullnumber,
            };
        if (!this.showIMOFrm) {
          data = {
            ...data,
            vslBuildYardID: frmData.buildyard,
            vslhullno: frmData.hullnumber,
            vslBuildYear: null,
          };
        }
        sessionStorage.setItem('champsVesselImoNumber', frmData.imoNumber);
        this.store.dispatch(new VesselCreateActions.PutCreateVessel(data));
        this.store.dispatch(new VesselCreateActions.PutCreateType(this.showIMOFrm ? 'imo-number' : 'hull-number'));
      });
  }

  public closeCreatePopup() {
    this.showIMOFrm = true;
    this.createFormGrp.reset();
    this.dialogRef.close();
  }

  /**
   *
   * @param ctrl
   */
  public valueChanges(ctrl) {
    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);
    if (this.firstCalled) {
      this.allFiltered();
    }
  }
  public selectionChange(value) {
    if (value) {
      return value;
    }
    return false;
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public getVesselLists() {
    this.vesselsService.getVessels(this.params).subscribe((vessels: any) => {
      this.vesselList = vessels.vesselsList;

      if (vessels) {
        this.getFilters(vessels);
      }

      this.rows = this.vesselList;

      this.vesselNames = vessels.vesselName;
      this.imoNumbers = vessels.imoNumbers;

      if (vessels && vessels.length > 0) {
        this.vesselsBuffer = this.vesselNames.slice(0, this.bufferSize);
      }
      // this.vesselsBuffer = this.vesselNames.slice(0, this.bufferSize);
      if (vessels && vessels.length > 0) {
        this.imoBuffer = this.imoNumbers.slice(0, this.bufferSize);
      }
      // this.imoBuffer = this.imoNumbers.slice(0, this.bufferSize);
      this.filtersCtrls.vesselMultiCtrl.valueChanges.subscribe(() => {
        if (this.firstCalled) {
          this.allFiltered();
        }
      });
      this.filtersCtrls.imoMultiCtrl.valueChanges.subscribe(() => {
        if (this.firstCalled) {
          this.allFiltered();
        }
      });
      /* Fetching data from the json to respective arrays */
      if (this.filtersList) {
        this.filteredServicesMulti.next(this.filtersList.services);
        this.filteredOwnersMulti.next(this.filtersList.owners);
        this.filteredContactMulti.next(this.filtersList.groups);
        this.setFiltersFromParam(this.paramList);
        this.allFiltered();
      }
      // this.setFiltersFromParam(this.paramList);
      // this.allFiltered();
    });
  }

  /**
   * teu filter
   * @param vesselsToBeFiltered
   */
  public vesselCodeFilter(vesselsToBeFiltered, ctrl) {

    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);

    if (this.filtersCtrls.vesselCodeMultiCtrl.value && this.filtersCtrls.vesselCodeMultiCtrl.value.length > 0) {

      this.vesselCodeChipString = '= ' + this.filtersCtrls.vesselCodeMultiCtrl.value;

      return vesselsToBeFiltered.filter((vessel) => {

        if (vessel.vslMLCode !== null) {

          return vessel.vslMLCode.toLowerCase() === this.filtersCtrls.vesselCodeMultiCtrl.value.toLowerCase() && vessel.vslIsActive === 1;

        }

      });
      // return vesselsToBeFiltered.filter(vessel1 =>

      //   vessel1.vslVesselName === exactCode[0].vslVesselName && vessel1.vslIsActive === 1

      // );

    } else {

      this.vesselCodeChipString = null;

      return vesselsToBeFiltered;

    }

  }
  // Commenting as navigation for new dummy creation done in template itself
  // public showCreateDummyPage() {
  //   this.router.navigate(['/vessels/createdummy']);
  // }

  private validateCreateForm() {
    if (this.showIMOFrm) {
      this.createFormGrp.get('imoNumber').markAsTouched();
      if (!this.createFormGrp.get('imoNumber').value || !this.createFormGrp.get('imoNumber').valid) {
        if (!this.createFormGrp.get('imoNumber').value) {
          this.createFormGrp.get('imoNumber').setErrors({
            required: true,
          });
        }
        this.showCreateFrmErrors = true;
        return false;
      }
      this.createFormGrp.get('imoNumber').setErrors(null);
      return true;
    } else {
      this.createFormGrp.get('hullnumber').markAsTouched();
      this.createFormGrp.get('buildyard').markAsTouched();
      if (!this.createFormGrp.get('hullnumber').value || !this.createFormGrp.get('buildyard').value) {
        if (!this.createFormGrp.get('hullnumber').value) {
          this.createFormGrp.get('hullnumber').setErrors({
            required: true,
          });
        }

        if (!this.createFormGrp.get('buildyard').value) {
          this.createFormGrp.get('buildyard').setErrors({
            required: true,
          });
        }

        this.showCreateFrmErrors = true;
        return false;
      } else {
        this.createFormGrp.get('hullnumber').setErrors(null);
        this.createFormGrp.get('buildyard').setErrors(null);
        return true;
      }
    }
  }

  /**
   *
   * @param paramList
   */
  private setFiltersFromParam(paramList: ParamsList) {
    if (!this.firstCalled) {
      const statusList = paramList.statuses.map((i) => +i);
      this.filtersCtrls.vesselMultiCtrl.setValue(paramList.vessels || null);
      this.filtersCtrls.vesselCodeMultiCtrl.setValue(paramList.vesselCodes || null);
      this.filtersCtrls.operatorMultiCtrl.setValue(paramList.operators || null);
      this.filtersCtrls.imoMultiCtrl.setValue(paramList.imo || null);
      this.filtersCtrls.typeMultiCtrl.setValue(paramList.types || null);
      this.filtersCtrls.serviceMultiCtrl.setValue(paramList.services || null);
      this.filtersCtrls.vslGrpMultiCtrl.setValue(paramList.groups || null);
      this.filtersCtrls.ownerMultiCtrl.setValue(paramList.owners || null);
      this.filtersCtrls.statusMultiCtrl.setValue(statusList || null);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(paramList.from);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(paramList.to);
    }
    this.firstCalled = true;
  }

  /**
   *
   * @param vessels
   */
  private getFilters(vessels) {
    vessels = vessels || [];
    if (!this.filtersList) {
      this.filtersList = {
        vessels: vessels.vesselName ? vessels.vesselName.sort() : null,
        imo: vessels.imoNumbers ? vessels.imoNumbers.sort() : null,
        operators: vessels.operatorCode ? vessels.operatorCode.sort() : null,
        types: vessels.type ? vessels.type.sort() : null,
        services: vessels.services ? vessels.services.sort() : null,
        groups: vessels.vesselGroup ? vessels.vesselGroup.sort() : null,
        owners: vessels.owner ? vessels.owner.sort() : null,
        statuses: vessels.depStatus ? vessels.depStatus.sort((a, b) => b - a) : null,
      };
    }
  }

  /**
   *
   * @param ctrl
   */
  private sortFilterBySearch(ctrl) {
    let fltr = '';
    switch (ctrl) {
      case 'serviceFilterSearchCtrl':
        fltr = 'services';
        break;
      case 'ownerFilterSearchCtrl':
        fltr = 'owners';
        break;
      case 'contactFilterSearchCtrl':
        fltr = 'groups';
        break;
      default:
        break;
    }
    if (!this.filtersList[fltr]) {
      return;
    }
    let search = this.filterSearchControls[ctrl].value;
    if (!search) {
      if (ctrl === 'serviceFilterSearchCtrl') {
        this.filteredServicesMulti.next(this.filtersList[fltr]);
      }
      if (ctrl === 'contactFilterSearchCtrl') {
        this.filteredContactMulti.next(this.filtersList[fltr]);
      } else {
        this.filteredOwnersMulti.next(this.filtersList[fltr]);
      }
      return;
    } else {
      search = search.toLowerCase();
    }

    if (ctrl === 'serviceFilterSearchCtrl') {
      this.filteredServicesMulti.next(this.filtersList[fltr].filter((item) => item.toLowerCase().indexOf(search) > -1));
    }
    if (ctrl === 'contactFilterSearchCtrl') {
      this.filteredContactMulti.next(this.filtersList[fltr].filter((item) => item.toLowerCase().indexOf(search) > -1));
    } else {
      this.filteredOwnersMulti.next(this.filtersList[fltr].filter((item) => item.toLowerCase().indexOf(search) > -1));
    }
  }

  private customIndexOf(ctrl, val) {
      if (ctrl.indexOf(val) > -1) {
        return true;
      } else if (val && val.indexOf(',') == -1 && val.indexOf(ctrl) > -1) {
        return true;
      } else if (val && val.indexOf(',') > -1 && val.indexOf(ctrl) > -1) {
        return true;
      } else {
        return false;
      }
  }

  private teuFilter(vesselsToBeFiltered, ctrl) {
    if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value && this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString =
        this.filtersCtrls[ctrl].fromTeuMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          vessel.vslTeuNominal >= this.filtersCtrls[ctrl].fromTeuMultiCtrl.value &&
          vessel.vslTeuNominal <= this.filtersCtrls[ctrl].toTeuMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString = '<= ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vslTeuNominal <= this.filtersCtrls[ctrl].toTeuMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value) {
      this.teuRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vslTeuNominal >= this.filtersCtrls[ctrl].fromTeuMultiCtrl.value
      );
    } else {
      this.teuRangeChipString = null;
      return vesselsToBeFiltered;
    }
  }

  /** */
  private setRequestFilter() {
    this.paramList = Object.assign(this.paramList, {
      vessels: this.filtersCtrls.vesselMultiCtrl.value,
      imo: this.filtersCtrls.imoMultiCtrl.value,
      operators: this.filtersCtrls.operatorMultiCtrl.value,
      types: this.filtersCtrls.typeMultiCtrl.value,
      from: this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value,
      to: this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value,
      services: this.filtersCtrls.serviceMultiCtrl.value,
      groups: this.filtersCtrls.vslGrpMultiCtrl.value,
      owners: this.filtersCtrls.ownerMultiCtrl.value,
      statuses: this.filtersCtrls.statusMultiCtrl.value,
    });

    const params = Object.keys(this.paramList)
      .filter((key) => {
        if (this.paramList[key] && (this.paramList[key].length > 0 || typeof this.paramList[key] === 'number')) {
          if (key === 'more') {
            return this.paramList[key] > 0;
          }
          return true;
        }
        return false;
      })
      .reduce((res, key) => Object.assign(res, { [key]: this.paramList[key] }), {});

    this.params = Object.keys(params)
      .map((key) => key + '=' + encodeURI(params[key]))
      .join('&');
    const searchParams = this.params ? '?' + this.params : '';
    if (searchParams !== location.search) {
      this.location.replaceState(location.pathname + searchParams);
    }
  }

  /**
   *
   * @param vesselToBeFiltered
   * @param ctrl
   */
  private setFilterred(vesselToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'vesselMultiCtrl':
        key = 'vslVesselName';
        break;
      case 'imoMultiCtrl':
        key = 'vslIMONumber';
        break;
      case 'operatorMultiCtrl':
        key = 'vslOperatorCode';
        break;
      case 'typeMultiCtrl':
        key = 'vslVesselType';
        break;
      case 'serviceMultiCtrl':
        key = 'vslService';
        break;
      case 'vslGrpMultiCtrl':
        key = 'vslGroup';
        break;
      case 'statusMultiCtrl':
        key = 'vslDepStatus';
        break;
      case 'ownerMultiCtrl':
        key = 'vslOwner';
        break;
      default:
        key = '';
        break;
    }

    if (this.filtersCtrls[ctrl].value && this.filtersCtrls[ctrl].value.length > 0) {
      if (key === 'vslService') {
        return vesselToBeFiltered.filter((vessel) => (this.filtersCtrls[ctrl].value.indexOf(vessel[key]) > -1
          || this.filtersCtrls[ctrl].value.indexOf(vessel['vslServiceCode']) > -1)
          && vessel.vslIsActive === 1);
      }
      return vesselToBeFiltered.filter((vessel) => this.filtersCtrls[ctrl].value.indexOf(vessel[key]) > -1 && vessel.vslIsActive === 1

      );
    }
    return vesselToBeFiltered;
  }
}

interface Vessel {
  vslIMONumber: any;
  vslVesselName: any;
  vslVesselType: any;
  vslTeuNominal: any;
  vslIsActive: any;
  vslOperatorCode: any;
  vslDepStatus: any;
}

interface FilterList {
  vessels: Array<string>;
  imo: Array<string>;
  operators: Array<string>;
  types: Array<string>;
  teu?: Array<string>;
  services: Array<string>;
  groups: Array<string>;
  owners: Array<string>;
  statuses: Array<string>;
}

interface ParamsList {
  vessels?: Array<string>;
  vesselCodes: string;
  imo?: Array<string>;
  operators?: Array<string>;
  types?: Array<string>;
  from?: string;
  to?: string;
  services?: Array<string>;
  groups?: Array<string>;
  owners?: Array<string>;
  statuses?: Array<string>;
  sortBy?: string;
  sortDir?: string;
  more?: any;
}
