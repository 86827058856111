// submit request
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DeploymentComponent } from '../deployment/deployment.component';

@Component({
  selector: 'champs-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.scss']
})
export class SubmitRequestComponent implements OnInit, OnChanges {
  public requestFormGrp: UntypedFormGroup;
  public fieldError: boolean = false;
  public saveRequestSuccessful: boolean = false;
  public newCharterRequestId: any;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public charetrRequest: any;
  @Input() public serviceRepo: boolean;
  @Input() public position: any;
  @Input() public serviceid: any;
  @Input() public serviceCode: any;
  @Input() public serviceName: any;
  @Input() public scenarioId: any;
  @Input() public isExtraloaderPosition: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private serviceRepoService: ServiceRepositoryService,
    private scenarioService: ScenariosService,
    private deploymentComponent: DeploymentComponent
  ) {

  }

  public ngOnInit() {
    this.createFormGroup();
  }

  public ngOnChanges(changes: { service: SimpleChange }) {
    this.serviceRepoService.getBunkerForCharter()
      .subscribe((res: any) => {
        this.setBunkerValues(res.monthlyAvgBunkerPrice);
      });
  }
  public saveService() {
    if (this.requestFormGrp.invalid) {
      this.fieldError = true;
      return;
    } else {
      const postData = {
        'crPKID': this.charetrRequest.charterRequestId,
        'crPhaseInPortID': this.charetrRequest.phaseInPortID,
        'crPhaseInPortName': this.charetrRequest.phaseInPortName,
        'crStatusID': 2,
        'crPhaseInStartDate': this.charetrRequest.fromDate,
        'crPhaseOutPortID': this.charetrRequest.phaseOutPortID,
        'crPhaseOutPortName': this.charetrRequest.phaseOutPortName,
        'crPhaseOutStartDate': this.charetrRequest.toDate,
        'crClassID': this.charetrRequest.classId,
        'crTradeID': this.charetrRequest.tradeID,
        'crTEU': this.charetrRequest.teu,
        'crMTCapacity': this.charetrRequest.mtCapacity,
        'crPlugs': this.charetrRequest.plugs,
        'crMinSpeed': this.charetrRequest.minSpeed,
        'crMaxSpeed': this.charetrRequest.maxSpeed,
        'crDraft': this.charetrRequest.draftLimitations,
        'crAirDraft': this.charetrRequest.airDraft,
        'crIsGear': this.charetrRequest.gear,
        'crIsIceClass': this.charetrRequest.iceClass,
        'crComments': this.charetrRequest.requestComments,
        'crVesselDepID': this.charetrRequest.vesselDepID,
        'crReason': this.requestFormGrp.get('crReason').value,
        'crForecastTEU': this.requestFormGrp.get('crForecastTEU').value,
        'crForecastTEURate': this.requestFormGrp.get('crForecastTEURate').value,
        'crHFOPrice': this.requestFormGrp.get('crHFOPrice').value,
        'crLSHFOPrice': this.charetrRequest.lshfoPrice,
        'crMDOPrice': this.requestFormGrp.get('crMDOPrice').value,
        'crLSMDOPrice': this.charetrRequest.lsmdoPrice,
        'crMLTeuCapacity': this.charetrRequest.mlTeuCapacity,
        'crMLMTCapacity': this.charetrRequest.mlmtCapacity,
        'crProformaHeaderId': this.charetrRequest.charterProformaHeaderId,
        'crVesselTeuId': this.charetrRequest.vesselTeuId,
        'crNomTeuCapacity': this.charetrRequest.nomTeuCapacity,
        'crTonCenCap': this.charetrRequest.tonCenCap,
        'crECAZone': this.charetrRequest.ecaZone,
        'crPosition': this.position,
        'crServiceId': this.serviceid,
        'crServiceCode': this.serviceCode,
        'crServiceName': this.serviceName,
        'crIsExtraPosition' : this.isExtraloaderPosition
      };
      const serv1 = this.serviceRepo ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(this.serviceid, this.position, postData.crPhaseInStartDate) :
        this.scenarioService.getLastDeploymentOnServiceByPosition(this.serviceid, this.position, postData.crPhaseInStartDate, this.scenarioId);
      serv1.subscribe((res1: any) => {
        postData.crVesselDepID = res1 === -1 ? null : res1;
        const servpostCreateUpdateDummy = this.serviceRepo ? this.serviceRepoService.postCreateUpdateDummy(postData) : this.scenarioService.postCreateUpdateDummy(postData);
        servpostCreateUpdateDummy.subscribe((res: any) => {
          if (res) {
            this.newCharterRequestId = res.charter_id;
            this.saveRequestSuccessful = !this.saveRequestSuccessful;
            this.deploymentComponent.getServicePlanningLst();
          }

        });

      });

    }

  }
  public closeDialogue(close?: any) {
    if (!close) {
      this.close.emit({
        'closeDialog': true,
        'link': '/charter-request/CandidateVessels/' + this.newCharterRequestId
      });
    }
  }
  private setBunkerValues(mapPrice) {

    mapPrice.forEach(rate => {

      if (rate.bunkerType === 'HFO') {

        this.requestFormGrp.patchValue({ crHFOPrice: rate.mapbunkerPrice ? rate.mapbunkerPrice : null });
      }
      if (rate.bunkerType === 'MDO') {
        this.requestFormGrp.patchValue({ crMDOPrice: rate.mapbunkerPrice ? rate.mapbunkerPrice : null });
      }

    });
  }
  private createFormGroup() {

    this.requestFormGrp = this.formBuilder.group({
      crPKID: 0,
      crReason: null,
      crForecastTEU: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
      crForecastTEURate: this.formBuilder.control(14, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
      crHFOPrice: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
      crMDOPrice: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
      crDeploymentUser: 0
    });
  }

}
