//

import { Component, AfterContentInit } from '@angular/core';
import { CustomDatetimepickerComponent } from '../champs-custom-datetimepicker/custom-datetimepicker.component';
import { isValidDate } from '../../../utils/valid-date';
import { DatePipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'champs-custom-datetimepicker-content',
  templateUrl: './custom-datetimepicker-content.component.html',
  styleUrls: ['./custom-datetimepicker-content.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'mat-datepickertimepicker-content'
  }
})
export class CustomDatetimepickerContentComponent<D> implements AfterContentInit {

  /** Reference to the datepicker that created the overlay. */
  public datetimePicker: CustomDatetimepickerComponent<D>;

  public dateModel: UntypedFormControl;

  public hourValue: any = '00';
  public mintValue: any = '00';

  public hourValues = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  public mintValues = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59'
  ];

  constructor() {
    this.dateModel = new UntypedFormControl(null);
  }

  public ngAfterContentInit() {
    if (this.datetimePicker._datetimepickerInput.value) {
      const dt = new Date(this.datetimePicker._datetimepickerInput.value as any);
      this.hourValue = this.setHourValue(dt.getHours());
      this.mintValue = this.setMinValue(dt.getMinutes());
      this.dateModel.setValue(new DatePipe('en-US').transform(dt, 'dd-MMM-yy'));
    }
    this.datetimePicker._datetimepickerInput._valueChange.subscribe((res:Date) => {
      if (res && isValidDate(res)) {
        const dt = new Date(res);
        this.hourValue = this.hourValue !== '00' ? this.setHourValue(dt.getHours()) : this.hourValue;
        this.mintValue = this.mintValue !== '00' ? this.setMinValue(dt.getMinutes()) : this.mintValue;
        dt.setHours(parseInt(this.hourValue, 10));
        dt.setMinutes(parseInt(this.mintValue, 10));
        this.datetimePicker._datetimepickerInput.value = new DatePipe('en-US').transform(dt, 'dd-MMM-yy HH:mm') as any;
      }
    });

    this.dateModel.valueChanges
    .subscribe(val => {
      this.datetimePicker.dateValueChange(val);
      this.datetimePicker.close();
    });
  }

  public hourValueChange(e) {
    this.datetimePicker.hourValueChange(e);
  }

  public minuteValueChange(e) {
    this.datetimePicker.minuteValueChange(e);
  }

  private setMinValue(mint) {
    if (mint.toString().length === 1) {
      return '0' + mint.toString();
    }

    return mint;
  }

  private setHourValue(hour) {
    if (hour.toString().length === 1) {
      return '0' + hour.toString();
    }

    return hour;
  }

}
