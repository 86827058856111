//

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanDeactivateDialogToggleService } from '../../services/can-deactivate-dialog-toggle/can-deactivate-dialog-toggle.service';

@Component({
  selector: 'champs-can-deactivate-dialog',
  templateUrl: './can-deactivate-dialog.component.html',
  styleUrls: ['./can-deactivate-dialog.component.scss'],
})
export class CanDeactivateDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogToggle: CanDeactivateDialogToggleService) {}

  /**
   * dummy comment
   */
  public cancel() {
    this.dialogToggle.canDeactivateToggle.next(false);
  }

  /**
   * dummy comment
   */
  public leave() {
    this.dialogToggle.canDeactivateToggle.next(true);
  }
}
