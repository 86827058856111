// main module of the application

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';

import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from 'src/app/core/core.module';
import { StateModule } from 'src/app/state/state.module';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TimezoneInterceptor } from './core/interceptors/timezone.interceptor';
import { environment } from '../environments/environment';
import { MsalLoginRedirectService } from 'src/app/core/services/msal/msal-login-redirect.service';
// MDS Components
import '@maersk-global/mds-components-core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [AppComponent],
  imports: [
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.clientId,
        authority: 'https://login.microsoftonline.com/' + environment.tenant,
        redirectUri: document.location.origin + '/auth-callback',
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        [environment.baseUrl, [environment.MSALScope]],
        ['http://localhost:50735', [environment.MSALScope]],
      ])
    }),
    MatSelectModule,
    BrowserModule,
    CoreModule.forRoot(),
    StateModule.forRoot(),
    NoopAnimationsModule,
    LayoutModule,
    PagesModule,
    ComponentsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    MsalModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    // AdalService,
    MsalService,
    // { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    /** comment this out for bypassing authentication */
    ...environment.byPassAuthentication === 'false' ? [{
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }] : [],
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { scrollStrategy: new NoopScrollStrategy(), hasBackdrop: true } },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalLoginRedirectService

  ],
})
export class AppModule { }
