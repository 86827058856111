/* */
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../../core/services/alert/alert.service';
import { Location, DatePipe } from '@angular/common';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { Subject, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  convertToLocalIsoString,
  MY_FORMATS,
  extractDateOnly
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DownloadReportService } from '../../../core/services/download-report/download-report.service';
import { ExcelService } from '../../../core/services/download-excel/excel.service';

@Component({
  selector: 'champs-standalone-report-dashboards',
  templateUrl: './standalone-report-dashboards.html',
  styleUrls: ['./standalone-report-dashboards.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class StandaloneReportDashboardsComponent implements OnInit, OnDestroy {
  private showMoreIndex: number = 0;
  private params: any = null;
  private filteredArray: any[] = [];
  private firstCalled: boolean = false;
  private _onDestroy = new Subject<void>();
  private noticesBuffer = [];
  private bufferSize = 50;
  private oppList: any[] = [];
  public showMinMaxError: boolean = false;
  public rows: any[] = [];
  public mobile: boolean = false;
  public showElementsCount: number = 10;
  public reorderable: boolean = true;
  public removable: boolean = true;
  public filtersList: FilterList = null;
  public startDateByChip: string;
  public endDateByChip: string;
  public teuRangeChipString: string;
  public services: any[] = [];
  public regions: any[] = [];
  public endRegions: any[] = [];
  public category: any[] = [];
  public vesselNames: any[] = [];
  public math = Math;
  /** Label of the search placeholder */
  public placeholderLabel = 'Search';

  /** Label to be shown when no entries are found. Set to null if no message should be shown. */
  public noEntriesFoundLabel = 'Nothing found';
  /** list of elements filtered by search keyword for multi-selection */
  public filteredcategoryMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredrangeMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredendrangeMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  public paramList: ParamsList = {
    regions: [],
    categories: [],
    endRegions: [],
    sortBy: '',
    sortDir: '',
    more: 0,
    teuFrom: '',
    teuTo: '',
  };
  public filtersCtrls = {
    categoryMultiCtrl: new UntypedFormControl(),
    regionMultiCtrl: new UntypedFormControl(),
    endRegionMultiCtrl: new UntypedFormControl(),
    datesMultiCtrl: {
      startDateMultiCtrl: new UntypedFormControl(),
      endDateMultiCtrl: new UntypedFormControl(),
    },
    teuMultiCtrl: {
      fromTeuMultiCtrl: new UntypedFormControl(),
      toTeuMultiCtrl: new UntypedFormControl()
    }
  };

  @ViewChild(DatatableComponent, { static: false }) public table: DatatableComponent;

  constructor(
    private vslService: VesselsService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private alertService: AlertService,
    private downloadReport: DownloadReportService,
    private excelService: ExcelService
  ) {
    if (window.screen.width < 600) {
      this.mobile = true;
    }

    if (window.screen.width < 600) {
      this.mobile = true;
    }
    this.activatedRoute.queryParams
      .pipe(filter((x) => x !== undefined))
      .pipe(take(1))
      .subscribe((params) => {
        this.params = params;
        Object.keys(params).forEach((key) => {
          if (key === 'teuFrom' || key === 'teuTo') {
            this.paramList[key] = params[key];
          }
          if (key === 'sortBy' || key === 'sortDir') {
            this.paramList[key] = params[key];
          }
          if (key === 'statuses') {
            this.paramList[key] = params[key].split(',').map((val) => parseInt(val, 10));
          }
          if (key === 'more') {
            this.showMoreIndex = this.showMoreIndex + parseInt(params[key] || '0', 10);
            this.paramList[key] = this.showMoreIndex;
            this.showElementsCount = 10 * (this.showMoreIndex + 1);
          } else {
            this.paramList[key] = params[key].split(',');
          }
        });
      });
  }
  /** */
  public ngOnInit() {
    this.getOpportunityListForReport();
  }

  public getOpportunityListForReport() {
    this.vslService.getOpportunityListReportPage().subscribe((vessels: any) => {
      this.oppList = vessels.opprtunityListDetails;
      this.oppList = this.oppList.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      this.getFilters(vessels);
      this.regions = vessels.range;
      this.endRegions = vessels.endRange;
      this.category = vessels.category;
      this.filteredcategoryMulti.next(this.filtersList.categories);
      this.filteredrangeMulti.next(this.filtersList.regions);
      this.filteredendrangeMulti.next(this.filtersList.endRegions);
      this.setFiltersFromParam(this.paramList);
      this.allFiltered();
    });
  }

  /*
   * @param filterControl
   */
  public filterDropdownIconUrlSetter(filterControl): string {
    if (filterControl === this.filtersCtrls.teuMultiCtrl) {
      if (filterControl.fromTeuMultiCtrl.value || filterControl.toTeuMultiCtrl.value) {
        return 'assets/icons/tick.svg';
      } else {
        return 'assets/icons/arrow-dropdown.svg';
      }
    }

    if (filterControl.value && filterControl.value.length > 0) {
      return 'assets/icons/tick.svg';
    } else {
      return 'assets/icons/arrow-dropdown.svg';
    }
  }

  /** */
  public allFiltered() {
    /* chaining the filters */
    this.filteredArray = this.setRegionByFilter(this.oppList, 'regionMultiCtrl');
    this.filteredArray = this.setEndregionByFilter(this.filteredArray, 'endRegionMultiCtrl');
    this.filteredArray = this.setCategoryFilter(this.filteredArray, 'categoryMultiCtrl');
    this.filteredArray = this.setDateByFilter(this.filteredArray, 'datesMultiCtrl');
    this.filteredArray = this.teuFilter(this.filteredArray, 'teuMultiCtrl');
    this.rows = [...this.filteredArray];
    this.setRequestFilter();
  }

  /**
   *
   * @param ctrl
   */
  public valueChanges(ctrl) {
    this.alertService.clear();
    this.allFiltered();
  }

  /**
   *
   * @param fltr
   * @param ctrl
   */
  public remove(fltr: any, ctrl: string = null): void {
    this.alertService.clear();
    /* remove all filters chip */
    if (fltr === 'all') {
      this.filtersCtrls.regionMultiCtrl.setValue([]);
      this.filtersCtrls.endRegionMultiCtrl.setValue([]);
      this.filtersCtrls.categoryMultiCtrl.setValue([]);
      this.filtersCtrls.datesMultiCtrl.startDateMultiCtrl.setValue([]);
      this.filtersCtrls.datesMultiCtrl.endDateMultiCtrl.setValue([]);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);
      this.filteredArray = [];
      this.allFiltered();
      return;
    }
    if (fltr === 'startdateBy') {
      this.filtersCtrls.datesMultiCtrl.startDateMultiCtrl.setValue(null);
      this.filtersCtrls.datesMultiCtrl.endDateMultiCtrl.setValue(null);
      this.allFiltered();
      return;
    }
    if (fltr === 'enddateBy') {
      this.filtersCtrls.datesMultiCtrl.endDateMultiCtrl.setValue(null);
      this.allFiltered();
      return;
    }
    if (fltr === 'teu') {
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);
      this.allFiltered();
      return;
    }
    const removeFilterIndex = this.filtersCtrls[ctrl].value ? this.filtersCtrls[ctrl].value.indexOf(fltr) : -1;

    if (removeFilterIndex >= 0) {
      this.filtersCtrls[ctrl].value.splice(removeFilterIndex, 1);
      this.filtersCtrls[ctrl].setValue(this.filtersCtrls[ctrl].value);
    }
    this.allFiltered();
  }
  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  /** */
  public fetchMore(e) {
    const len = this.noticesBuffer.length;
    const more = this.services.slice(len, this.bufferSize + len);
    this.noticesBuffer = this.noticesBuffer.concat(more);
  }
  /** */
  /** */
  public showMore() {
    this.showElementsCount = this.showElementsCount + 10;
    this.showMoreIndex = ++this.showMoreIndex;
    this.paramList.more = this.showMoreIndex;
    this.setRequestFilter();
  }

  public setRegionFilter(val) {
    this.filtersCtrls.regionMultiCtrl.setValue(val);
    this.filtersCtrls.endRegionMultiCtrl.setValue(val);
  }

  /* Method to grey out dates in calender of end date */
  public autoSelectedEndDate() {
    return new Date();
  }

  public getTCRate(tcRate) {
    if (tcRate === null || tcRate === 'CONFIDENTIAL') {
      return tcRate;
    } else {
      return Math.trunc(tcRate);
    }
  }

  public downloadOppListForReport() {
    const modified_rows = this.rows.map((e) => {
      if (e.category === 'available+days') {
        return {
          'Category': e.category,
          'Start Date': extractDateOnly(e.startDate),
          'End Date': extractDateOnly(e.endDate),
          'Days': e.days,
          'Start Region': e.range,
          'Start Port': e.startPort,
          'End Region': e.endRange,
          'End Port': e.endPort,
          'Operator': e.operator,
          'Vessel Code': e.vesselCode,
          'Vessel name': e.vesselname,
          'Nom TEU': e.teuNominal,
          'Service': e.service,
          'TC rate': this.getTCRate(e.tcRate)
        };
      } else {
        return {
          'Category': e.category,
          'Start Date': extractDateOnly(e.startDate),
          'End Date': extractDateOnly(e.endDate),
          'Days': e.days,
          'Start Region': e.range,
          'Start Port': e.startPort,
          'End Region': e.endRange,
          'End Port': e.endPort,
          'Operator': e.operator,
          'Vessel Code': e.vesselCode,
          'Vessel name': e.vesselname,
          'Nom TEU': e.teuNominal,
          'Service': e.service,
          'TC rate': null
        };
      }
    });

    // set headers of excel columns
    const header = ['Category', 'Start Date', 'End Date', 'Days', 'Start Region', 'Start Port', 'End Region', 'End Port', 'Operator', 'Vessel Code', 'Vessel name', 'Nom TEU', 'Service', 'TC rate'];

    // set width of columns
    const width = [{ width: 18 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 15 }, { width: 20 }, { width: 15 }, { width: 20 }, { width: 10 }, { width: 13 }, { width: 23 }, { width: 10 }, { width: 30 }, { width: 20 }];

    this.excelService.generateExcel('Opportunity_List', modified_rows, header, width);
  }

  private setRequestFilter() {
    this.paramList = Object.assign(this.paramList, {
      regions: this.filtersCtrls.regionMultiCtrl.value,
      endRegions: this.filtersCtrls.endRegionMultiCtrl.value,
      categories: this.filtersCtrls.categoryMultiCtrl.value,
      teuFrom: this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value,
      teuTo: this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value,
    });

    const params = Object.keys(this.paramList)
      .filter((key) => {
        if (this.paramList[key] && (this.paramList[key].length > 0 || typeof this.paramList[key] === 'number')) {
          if (key === 'more') {
            return this.paramList[key] > 0;
          }
          return true;
        }
        return false;
      })
      .reduce((res, key) => Object.assign(res, { [key]: this.paramList[key] }), {});

    this.params = Object.keys(params)
      .map((key) => key + '=' + encodeURI(params[key]))
      .join('&');
    const searchParams = this.params ? '?' + this.params : '';
    if (searchParams !== location.search) {
      this.location.replaceState(location.pathname + searchParams);
    }
  }

  /**
   *
   * @param oppToBeFiltered
   * @param ctrl
   */

  private setRegionByFilter(oppToBeFiltered, ctrl) {
    if (this.filtersCtrls['regionMultiCtrl'].value && this.filtersCtrls['regionMultiCtrl'].value.length > 0) {
      const TBARegion = oppToBeFiltered.filter((vessel) => vessel['range'] == null);
      const filteredRegion = oppToBeFiltered.filter(
        (vessel) => this.filtersCtrls['regionMultiCtrl'].value.indexOf(vessel['range']) > -1
      );
      return [...filteredRegion, ...TBARegion];
    }
    return oppToBeFiltered;
  }

  private setEndregionByFilter(oppToBeFiltered, ctrl) {
    if (this.filtersCtrls['endRegionMultiCtrl'].value && this.filtersCtrls['endRegionMultiCtrl'].value.length > 0) {
      const TBARegion = oppToBeFiltered.filter((vessel) => vessel['endRange'] == null);
      const filteredRegion = oppToBeFiltered.filter(
        (vessel) => this.filtersCtrls['endRegionMultiCtrl'].value.indexOf(vessel['endRange']) > -1
      );
      return [...filteredRegion, ...TBARegion];
    }
    return oppToBeFiltered;
  }

  private setCategoryFilter(oppToBeFiltered, ctrl) {
    if (this.filtersCtrls['categoryMultiCtrl'].value && this.filtersCtrls['categoryMultiCtrl'].value.length > 0) {
      const filteredRegion = oppToBeFiltered.filter(
        (vessel) => this.filtersCtrls['categoryMultiCtrl'].value.indexOf(vessel['category']) > -1
      );
      return filteredRegion;
    }
    return oppToBeFiltered;
  }

  private setDateByFilter(oppListToBeFiltered, ctrl) {
    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);
    let fromDateValue = null;
    let toDateValue = null;

    fromDateValue =
      this.filtersCtrls[ctrl].startDateMultiCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].startDateMultiCtrl.value, 'dd-MMM-yyyy'))
        : null;

    toDateValue =
      this.filtersCtrls[ctrl].endDateMultiCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].endDateMultiCtrl.value, 'dd-MMM-yyyy'))
        : null;

    if (fromDateValue !== null && toDateValue !== null) {
      this.startDateByChip =
        new DatePipe('en-US').transform(fromDateValue, 'dd-MMM-yyyy') +
        '   ' +
        ' - ' +
        '   ' +
        new DatePipe('en-US').transform(toDateValue, 'dd-MMM-yyyy');
      return oppListToBeFiltered.filter(
        (vessels) =>
          convertToLocalIsoString(new Date(new DatePipe('en-US').transform(vessels.endDate, 'dd-MMM-yyyy'))) >=
          convertToLocalIsoString(fromDateValue) &&
          convertToLocalIsoString(new Date(new DatePipe('en-US').transform(vessels.startDate, 'dd-MMM-yyyy'))) <=
          convertToLocalIsoString(toDateValue)
      );
    } else if (fromDateValue === null && toDateValue !== null) {
      this.startDateByChip = '' + new DatePipe('en-US').transform(toDateValue, 'dd-MMM-yyyy');
      return oppListToBeFiltered.filter(
        (vessels) =>
          convertToLocalIsoString(new Date(new DatePipe('en-US').transform(vessels.startDate, 'dd-MMM-yyyy'))) <=
          convertToLocalIsoString(toDateValue)
      );
    } else if (toDateValue === null && fromDateValue !== null) {
      this.startDateByChip = ' ' + new DatePipe('en-US').transform(fromDateValue, 'dd-MMM-yyyy');
      return oppListToBeFiltered.filter(
        (vessels) =>
          convertToLocalIsoString(new Date(new DatePipe('en-US').transform(vessels.endDate, 'dd-MMM-yyyy'))) >=
          convertToLocalIsoString(fromDateValue)
      );
    } else {
      this.startDateByChip = '';
      return oppListToBeFiltered;
    }
  }

  private teuFilter(candidateToBeFiltered, ctrl) {
    this.showMinMaxError = false;

    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);

    if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value && this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString =
        this.filtersCtrls[ctrl].fromTeuMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
        if (this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value > this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value) {
          this.showMinMaxError = true;
          return [];
        }
      return candidateToBeFiltered.filter(
        (cand) =>
          cand.teuNominal >= this.filtersCtrls[ctrl].fromTeuMultiCtrl.value &&
          cand.teuNominal <= this.filtersCtrls[ctrl].toTeuMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString = '<= ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
      return candidateToBeFiltered.filter((cand) => cand.teuNominal <= this.filtersCtrls[ctrl].toTeuMultiCtrl.value);
    } else if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value) {
      this.teuRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromTeuMultiCtrl.value;
      return candidateToBeFiltered.filter((cand) => cand.teuNominal >= this.filtersCtrls[ctrl].fromTeuMultiCtrl.value);
    } else {
      this.teuRangeChipString = null;
      return candidateToBeFiltered;
    }
  }

  /**
   *
   * @param vessels
   */
  private getFilters(vessels) {
    if (!this.filtersList) {
      this.filtersList = {
        regions: vessels.range,
        endRegions: vessels.endRange,
        categories: vessels.category
      };
    }
  }
  /**
   *
   * @param paramList
   */
  private setFiltersFromParam(paramList: ParamsList) {
    if (!this.firstCalled) {
      this.filtersCtrls.regionMultiCtrl.setValue(paramList.regions || null);
      this.filtersCtrls.endRegionMultiCtrl.setValue(paramList.endRegions || null);
      this.filtersCtrls.categoryMultiCtrl.setValue(paramList.categories || null);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(paramList.teuFrom);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(paramList.teuTo);
    }
    this.firstCalled = true;
  }
}

interface ParamsList {
  regions: Array<string>;
  endRegions?: Array<string>;
  categories?: Array<string>;
  sortBy?: string;
  sortDir?: string;
  more?: any;
  teuFrom?: string;
  teuTo?: string;
}
interface FilterList {
  startdateBy?: Array<string>;
  regions?: Array<string>;
  endRegions?: Array<string>;
  endDateBy?: Array<string>;
  categories?: Array<string>;
}
