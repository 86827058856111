// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep .message {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  background: #ffffff;
  border-radius: 3px;
  border: solid 1px #d3d3d3;
  line-height: 24px;
  padding: 13px;
  position: relative;
  margin: 15px -15px;
}
:host::ng-deep .message + .message {
  margin-top: 10px;
}
:host::ng-deep .m-error {
  background-color: #fff6f6;
  border-color: #de574f;
}
:host::ng-deep .m-warn {
  background-color: #fffaf3;
  border-color: #ffa73b;
}
:host::ng-deep .m-0 {
  margin-left: 0;
}
:host::ng-deep .vessel .ng-select.custom .ng-select-container {
  border: 1px solid #888888;
  background-color: #ffffff;
  min-height: inherit !important;
  border-radius: 0px;
}
:host::ng-deep .vessel .header .address {
  padding: 12px 0;
  color: #888888 !important;
}
:host::ng-deep .vessel .header .address a {
  color: #282828;
}
:host::ng-deep .vessel .header .title {
  color: #282828;
  font-size: 24px;
  padding: 12px 0;
}
:host::ng-deep .vessel .padding-top7 {
  padding-top: 7px;
}
:host::ng-deep .vessel .vesl-name {
  max-width: 400px;
  font-size: 26px;
  color: #282828;
}
:host::ng-deep .vessel .edit-vesl-name {
  max-width: 400px;
  font-size: 20px;
  color: #282828;
  vertical-align: super;
}
:host::ng-deep .vessel .input-field {
  padding: 10px;
  text-align: center;
  height: 43px;
  width: 50px;
  margin-left: -6%;
  opacity: 0;
  border: 0;
}
:host::ng-deep .vessel .padding-top30 {
  padding-top: 30px;
}
:host::ng-deep .vessel .buttons {
  display: inline-block;
  margin-top: -9px;
  position: absolute;
  left: 5px;
}
:host::ng-deep .vessel .buttons .mat-mdc-button {
  line-height: 28px;
  border-radius: 5px;
}
:host::ng-deep .vessel .buttons .save-button {
  background: #64b2d4;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 5px;
}
:host::ng-deep .vessel .buttons .cancel-button {
  color: #64b2d4;
}
:host::ng-deep .vessel .vsl-new-dummy .mat-mdc-card {
  width: 100%;
}
:host::ng-deep .vessel .vsl-new-dummy-basicdtls {
  padding: 0;
  display: inline-flex;
}
:host::ng-deep .vessel .vsl-new-dummy-basicdtls-name {
  padding: 0;
}
:host::ng-deep .vessel .vsl-new-dummy-basicdtls-name .dummy-name {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #888888;
  background-color: rgb(242, 242, 242);
  color: #313131;
  display: inline-flex;
  width: 80%;
  margin-left: 10px;
}
:host::ng-deep .vessel .vsl-new-dummy-basicdtls-operator {
  padding: 0;
  background-color: #ffffff;
}
:host::ng-deep .vessel .vsl-new-dummy-basicdtls .padding-left5 {
  padding-left: 5px;
}
:host::ng-deep .vessel .vessel-requirements-block {
  padding-bottom: 18px;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-card {
  display: block;
  position: relative;
  margin-top: 0;
  padding: 16px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-card-header .mat-mdc-card-title {
  font-weight: bold;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-card-header-text {
  margin: 0 !important;
  padding: 8px 0;
  width: 98%;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-card-title {
  margin-bottom: 0 !important;
}
:host::ng-deep .vessel .vessel-requirements-block .pen {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
:host::ng-deep .vessel .vessel-requirements-block .edit-icon {
  margin: 8px 0;
}
:host::ng-deep .vessel .vessel-requirements-block .gap {
  width: 1%;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .speed-val-gap {
  position: relative;
  width: 8%;
  display: inline-block;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .width-division {
  position: relative;
  width: 12%;
  display: inline-block;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .width-division-per {
  position: relative;
  width: 12%;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .value {
  display: inline-block;
  width: 25%;
  color: #282828;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .seg-value,
:host::ng-deep .vessel .vessel-requirements-block .remark-val {
  display: inline-block;
  width: 50%;
  color: #282828;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .remark-value {
  display: inline-block;
  width: 60%;
  color: #282828;
  text-overflow: ellipsis;
  vertical-align: top;
  max-height: 67px;
  overflow-y: auto;
}
:host::ng-deep .vessel .vessel-requirements-block .value-draft {
  display: inline-block;
  width: 10%;
  color: #282828;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .heading {
  position: relative;
  width: 38%;
  display: inline-block;
  color: #888888;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .extra-header {
  position: relative;
  width: 10%;
  display: inline-block;
  color: #888888;
  vertical-align: top;
}
:host::ng-deep .vessel .vessel-requirements-block .left {
  width: 100%;
  padding: 6px 0;
}
:host::ng-deep .vessel .vessel-requirements-block .bottom-border {
  border-bottom: 1px solid #f0f0f0;
}
:host::ng-deep .vessel .vessel-requirements-block .input-width {
  width: 100%;
}
:host::ng-deep .vessel .vessel-requirements-block .icon-align {
  height: 16px;
  text-align: right;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-error {
  white-space: normal;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field {
  display: block;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field .mat-form-field-wrapper {
  padding-bottom: 0.01em;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field .mat-select-arrow {
  color: #282828;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-flex {
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #888888;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-infix {
  border: 0;
  width: 100%;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-should-float {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .vessel .vessel-requirements-block .mat-mdc-form-field-should-float .mat-form-field-label {
  display: none;
}
:host::ng-deep .vessel .vessel-requirements-block .dropdown {
  width: 100%;
  display: inline-block;
}
:host::ng-deep .vessel .vessel-requirements-block .dropdown mat-form-field {
  width: 100%;
}
:host::ng-deep .vessel .vessel-requirements-block .main-head {
  font-weight: bold;
  padding-left: 4%;
  padding-bottom: 1%;
}
:host::ng-deep .vessel .vessel-requirements-block .sub-head {
  font-weight: bold;
  padding-bottom: 2%;
  padding-top: 2%;
}
:host::ng-deep .vessel .delete-span {
  cursor: pointer;
}
:host::ng-deep .vessel .delete-span-txt {
  display: inline-block;
  bottom: 4%;
  position: relative;
  color: #d62d23;
  margin-left: 1%;
}
:host::ng-deep .show-loader {
  background: #ffffff;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 102%;
  z-index: 9999;
  margin-left: -25px;
}
:host::ng-deep .show-loader-img {
  margin: 275px 0;
  float: none;
  height: 30px;
}
:host::ng-deep champs-vessel-redesign-deployment .invalid-feedback {
  border-top: 1px solid red;
  margin-top: 1px;
  color: red;
}
:host::ng-deep champs-vessel-redesign-deployment .invalid-feedback.dep-position-top {
  margin-top: -14.5px !important;
}
:host .disabled {
  opacity: 1;
  pointer-events: none;
}
:host .disabled > * {
  opacity: 1;
  pointer-events: none;
}
:host .service-create {
  padding: 16px;
}
:host .service-create .service-title {
  color: #282828;
  font-size: 1.25rem;
  margin-bottom: 20px;
}
:host .service-create .service-reason {
  margin-top: 20px;
}
:host .service-create .service-reason textarea {
  margin: 8px 0;
}
:host .service-create .service-reason textarea.text-area {
  width: 100% !important;
  font-size: 0.75rem !important;
  overflow: auto !important;
  font-family: inherit !important;
}
:host .service-create .service-teu .input-teu {
  width: 40%;
  display: inline-block;
}
:host .service-create .service-teu .symbol-teu {
  width: 10%;
  display: inline-block;
}
:host .service-create .line {
  padding: 4px 0;
  line-height: 32px;
}
:host .service-create .line .headings {
  width: 40%;
  display: inline-block;
}
:host .service-create .line .headings-teu {
  width: 33%;
  display: inline-block;
}
:host .service-create .line .values {
  width: 60%;
  display: inline-block;
}
:host .errors-teu {
  margin-left: 33%;
}
:host .errors-bunkers {
  margin-left: 40%;
}
:host .start-margin {
  margin-left: 1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
