//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberDirective } from './input-number/input-number.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDateDirective } from './input-date/input-date.directive';
import { NgxtableFixedHeaderDirective } from './ngxtable-fixed-header/ngxtable-fixed-header.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';
import { FixedDecimalDirective } from './fixed-decimal/fixed-decimal.directive';
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatTooltipModule],
  declarations: [
    NgxtableFixedHeaderDirective,
    InputNumberDirective,
    InputDateDirective,
    CustomTooltipDirective,
    FixedDecimalDirective,
  ],
  exports: [
    NgxtableFixedHeaderDirective,
    InputNumberDirective,
    InputDateDirective,
    CustomTooltipDirective,
    FixedDecimalDirective,
  ],
})
export class DirectivesModule {}
