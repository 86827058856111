import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { AuthService } from '../../../core/services/authentication/auth/auth.service';
import { dateTimeCompile, customMoment, setZeroHours, convertToLocalIsoString, convertDateTimeToDate } from '../../../components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

const moment = customMoment;

@Component({
  selector: 'champs-vessel-detail-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class VesselDetailDetailsComponent implements OnInit {

  private id: any;
  public changeLog: any = 'Changelog';
  public vesselDetails: any;
  public step = 0;
  public error: boolean = false;
  public message: any;
  public rkstError: boolean = false;
  public isVsDtlsUpdateAuth: boolean;
  constructor(
    private vesselsService: VesselsService,
    private route: ActivatedRoute,
    private router: Router,
    private formbuilder: UntypedFormBuilder,
    private auth: AuthService
  ) {
    this.id = this.route.parent ? this.route.parent.snapshot.paramMap.get('id') : 0;

    if (this.id) {
      this.vesselsService.getVMD(this.id)
        .pipe(distinctUntilChanged())
        .subscribe((vesselDetails: Response) => {
          this.vesselDetails = vesselDetails;
        });
    }
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    window.onpopstate = function (e) {
      window.location.assign('/vessels/');

    };
    this.isVsDtlsUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
  }

  public saveVesselDetails(e) {
    if (e.type === 'cancel') {
      this.error = false;
      this.message = null;
    } else {
      if (e.type === 'UpdateGeneral') {
        this.error = false;
        e.value.vesselCode.forEach(element => {
          element.vcInFleetDate = dateTimeCompile(element.vcInFleetDate, element.vcInFleetTime);
          // element.vcOutFleetDate = dateTimeCompile(element.vcOutFleetDate, element.vcOutFleetTime ? element.vcOutFleetTime : new DatePipe('en-US').transform(moment(new Date()).hours(0).minutes(0).seconds(0).milliseconds(0), 'HH:mm'));
          element.vcOutFleetDate = dateTimeCompile(element.vcOutFleetDate, element.vcOutFleetTime ? element.vcOutFleetTime : '+00:00:00:00Z');
        });
      }
      if (e.type === 'UpdateHull') {
        this.error = false;
        e.value.vslLastDryDockDate = e.value.vslLastDryDockDate !== null ? setZeroHours(convertToLocalIsoString(e.value.vslLastDryDockDate)) : null;
        e.value.vslNextDryDockDate = e.value.vslNextDryDockDate !== null ? setZeroHours(convertToLocalIsoString(e.value.vslNextDryDockDate)) : null;
      }

      e.value = {
        vslPKID: parseInt(this.id, 10),
        ...e.value
      };
      this.vesselsService.postVMD(e.type, e.value)
        .subscribe((res) => {
          this.vesselDetails = res;
          this.message = null;
          this.error = false;
        }, err => {
          if (err.error.error === 'Vessel Code already exists with the given details') {
            this.message = err.error.error;
            this.error = true;
          }
          if (err.error.error === 'Vessel Infleet Date Or OutFleet Date Beyond Fixture Actual Delivery Or ReDelivery Date') {
            this.message = err.error.error;
            this.error = true;
          }
        });
    }
  }

  public setStep(index: number) {
    this.step = index;
  }
  public changeLogPopup() {
    window.open('1/changelog/' + this.id, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=920,height=550');
  }
}
