//

import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromDropdownsContent from 'src/app/state/dropdowns/reducers/dropdown-content.reducer';
import { HttpClient } from '@angular/common/http';
import { PutDropdownContent } from 'src/app/state/dropdowns/actions/dropdown-content.actions';

@Injectable({
  providedIn: 'root'
})
export class DropdownContentService {
  private hasOnGoingRequest = {};
  private contentSubject: ReplaySubject<any> = new ReplaySubject();
  constructor(
    private http: HttpClient,
    private store: Store<any>
  ) { }

  /**
   * Method that returns a subscription to the requested content data
   * @param {string} dropdownPath -URLe that data is requested for
   * @param {any} [opts] - Additional params to be attached to the call
   */
  public retrieveContent(dropdownPath: string, opts?: any): Observable<any> {
    const options = opts || { fresh: false };

    const path = dropdownPath;

    const urlWithQueryString = this.getCacheKey(path, options.params);

    return this.store.pipe(
      select(fromDropdownsContent.getDropdownContent(urlWithQueryString)),
      map((content) => {
        if ((!content && !this.hasOnGoingRequest[urlWithQueryString]) || options.fresh) {
          this.hasOnGoingRequest[urlWithQueryString] = true;
          this.getContent(path, options.params).pipe(take(1)).subscribe();
        } else {
          return content;
        }
      })
    );
  }

  /**
   * Method to make a call to the backend to get content data and store it in the store
   * @param {String} path - The path that content is requested for
   * @param {Object} [params] - Params to be attached to the MS call
   */
  private getContent(path, params?: {}): Observable<any> {
    const urlWithQueryString = this.getCacheKey(path, params);
    return this.http.get(path, params).pipe(
      map((res) => {
        this.store.dispatch(new PutDropdownContent(res, urlWithQueryString));
        this.hasOnGoingRequest[urlWithQueryString] = false;
      })
    );

  }

  /* tslint:disable-next-line */
  private getCacheKey(page: string, params: any): string {
    if (!params) {
      return page;
    }
    return page + '?' + Object.keys(params).map(o => o + '=' + params[o]).join('&');
  }
}
