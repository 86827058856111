/** */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';

@Injectable({
  providedIn: 'root',
})
export class DeploymentService {
  constructor(private http: HttpClient) { }

  /** get deployment redelivery notices */
  public getDeploymentRedeliveryNotices() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/GetNotices');
  }
  /* get deployemnt charter requests */
  public getDeploymentCharterRequests() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/GetCharterRequests');
  }
  /*get deployment  unemployed Veseels*/
  public getUnemployedVessels() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/GetIdleVessels');
  }
  /* get deployment New Schedules*/
  public getDeploymentNewSchedules() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/GetNewSchedules');
  }
  /** get deployment dockings */
  public getVesselDockings() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/GetDockingVessels');
  }
  /** get dummy overview */
  public getdummyDashboardReport() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/PastDummy/GetPastDummyDetailsAsync');
  }
  /** get over/under deployment */
  public getOverUnderDeploymentReport() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/OverUnderDeployment/GetOverUnderDeploymentDetailsAsync');
  }
  /** get over/under deployment */
  public updateFixtureDeliveryOrRedeliveryDate(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/UpdateFixtureDeliveryOrRedeliveryDate', data);
  }
  /** get over/under deployment */
  public updateVesselDeploymentDate(data) {
    return this.http.post(
      environment.baseUrl + '/api/VesselManagement/VesselDeployment/UpdateVesselDeploymentDate',
      data
    );
  }

  /** snooze line */
  public snoozeDeploymentLine(fixtureId, category) {
    return this.http.post(
      environment.baseUrl +
      '/api/FixtureManagement/UpdateOverUnderIsSnooze?fixtureId=' +
      fixtureId +
      '&category=' +
      category, null
    );
  }

  /** get opportunity list */
  public getOpportunityListReport() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Opprtunity/GetOpportunityDetailsListAsync');
  }

  /** get deployment communication list */
  public getDeploymentCommunicationList() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Deployment/getDeploymentCommList');
  }

  /** get dry dock planning report */
  public getDryDockPlanningReport() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/DryDocking/GetDeploymentDryDockDetailsListAsync');
  }
}
