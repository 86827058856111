//
import {
  Component,
  QueryList,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  time,
  customMoment,
  MAERSK_DATE_FORMATS,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { VesselsService } from '../../core/services/vessels/vessels.service';
import { PortService } from '../../core/services/port/port.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isValidDate } from 'src/app/utils/valid-date';
import { DatePipe } from '@angular/common';
import { distinctUntilChanged, take, takeUntil, debounceTime, delay } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { Subject } from 'rxjs';
import { convertToLocalIsoString, setZeroHours } from '../custom-moment-datepicker/custom-moment-datepicker.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../core/services/alert/alert.service';
import { SessionTimeoutService } from 'src/app/core/services/common/session-timeout.service';
import { CustomDateValidators } from '@cham-utils/customDateValidator';

const moment = customMoment || _moment;

@Component({
  selector: 'champs-vessel-redesign-deployment',
  templateUrl: './vessel-redesign-deployments.component.html',
  styleUrls: ['./vessel-redesign-deployments.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAERSK_DATE_FORMATS },
  ],
})
export class VesselRedesignDeploymentsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private dialogRef: MatDialogRef<any>;
  public fixtureContractNo: string;
  public fixtureIdForRange: any;
  public fixtureDurationsForDepl: any;
  @ViewChildren('listitem') public listItems: QueryList<MatListItem>;
  @ViewChild('editPrevious', { static: false }) public editPrevious: any;
  @ViewChild('editBlackout', { static: false }) public editBlackout: any;
  @ViewChild('confirmationDialog', { static: false }) public deleteDeploymentDialogue: any;
  @ViewChild('warningDialog', { static: false }) public warningMessagetDialogue: any;
  @ViewChild('selectProformaDialog', { static: false }) public selectProformaDialog: any;
  errorInPrevDepLines: boolean;
  errorInPrevDepLinesMessage: string;

  @HostListener('window:mousemove') public refreshUserState() {
    clearTimeout(this.sessionTimeoutService.userActivity);
    if (!(this.isCancelShowPreviousClick || this.vesselDeploymentFrmGrp.invalid)) {
      this.sessionTimeoutService.setTimeout();
    }
  }

  public matchedProformaLst = [];
  public posPrfLst: any = [];
  public posPrfStartEndDtLst: any = [];
  public selectedServiceDtl: any;
  public checkedProformaDtl: any;
  public errorText: string;
  public errorTextExist: boolean = false;
  public matchingPrfrmSelectedDtl: any[];
  public eventDtl: any;
  public frmGrpControlDtl: any;
  public indexDtl: any;
  public servicePositionsDetailsLst: any = [];
  public ifPosnEnteredIsSpcl: boolean = false;
  public showMoreVisible: boolean = false;
  public showMore: boolean = false;
  public deploymentDropdown?: any = {};
  public depEndDateShowAlert: boolean;
  public depEndPortShowAlert: boolean;
  public popupDepEndDate: boolean;
  public popupDepStartDate: boolean;
  public vesselDeploymentFrmGrp: UntypedFormGroup;

  public vesselDeploymentServices: any = [];
  public vesselDeploymentSpclServices: any = [];
  public vesselDepSpclServiceTypes: any = [];
  public vesselDepUnemployedTypes: any = [];
  public vesselDepLayupTypes: any = [];
  public vesselDeploymentPorts: any = [];
  public vesselDeploymentReasons: any = [];
  public vesselDeploymentStartPort: any;
  public vesselDeploymentEndPort: any;
  public roundTrip: any;
  public trade: any;
  public hideTrade: boolean = false;
  public hideRoundTrip: boolean = false;
  public showLocationHidePort: boolean = false;
  public showvesselDepSpclServiceTypes: boolean = false;
  public showvesselDepUnemployedTypes: boolean = false;
  public dontShowServiceType: boolean = false;
  public showvesselDepLayupTypes: boolean = false;
  public time = time;
  public showDetailsDiv: boolean = false;
  public showSpecialServicesFlag: boolean = false;
  public showServicePosition: boolean = false;
  public servicePositions: any = [];
  public preServiceDetails: any = [];

  public vslId: any;
  public veselID: any;
  public veselType: any;
  public showError: boolean = false;
  public locationErrorMsg: any;
  public showBeginTimeError: boolean = false;
  public showBeginDateError: boolean = false;
  public showEndTimeError: boolean = false;
  public showEndDateError: boolean = false;
  public trade_restrictions: any;
  public warnings: string[] = [];
  public deploymentErrorLines: number[] = [];
  public showDeploymentFormError = false;
  public requiredMessage = '';
  public showAlertForVesselId = '';
  public preventSave = false;

  /** error keys */
  public errorKeys: string[] = [];
  /** error values */
  public errorValues: any[] = [];
  public incorrectDeploymentDetails: any;
  public incorrectDeploymentDetailsNew: any;
  public showIncorrectDetailError: boolean = false;
  public blackoutViolationMsg: any;
  public showDatesInvalid: boolean = false;
  public stopSavingData: boolean = false;
  public previousDeploymentId: any;
  public fixturePortsForRange: any[] = [];
  public showRangeInvalid: boolean = false;
  public showEditPreviousWarning: boolean = false;
  public isVsDepUpdateAuth: boolean = false;
  public isVsDepDeleteAuth: boolean = false;
  public vesselDetail: any;
  public deploymentStartDate: null;
  public showScheduleDateError: boolean;
  public scheduleDateErrorMsg: any;
  public endDateNotPresent: boolean = false;
  public teuPercent: any;
  public mlPercent: any;
  public vsaFlagUp: any;
  public vsaFlagCur: string;
  public depPositionExists: boolean = true;
  public extraLoaderPosition: any;
  public extraPosEntered: boolean = false;
  public validExtraPos: boolean = false;
  public depPositionValid: boolean = true;
  public isVesslDeplyPositionSelected: boolean;
  @ViewChildren('noticeDatePicker') public noticeDatePicker;
  @ViewChildren('effectiveDatePicker') public effectiveDatePicker;
  public deploymentLastYearFrmGrp: UntypedFormGroup;
  public vesselDeploymentDropdownsOnInit?: any;
  public showHidePreviousDeploymentLink: any = 'Show previous deployments';
  public editDeployments: boolean = false;
  public veslDeployments: any;
  public showPreviousDeployments: boolean = false;
  public isPreviousDeployments: boolean = false;
  public islastYearOptionChecked: boolean = true;
  public addExtraDeploymtLine: boolean = false;
  @Input() public showControl?: boolean;
  @Input() public depItems: any;
  @Input() public isOwnDeploymentType: boolean;

  public loading: boolean;
  public isAnyControlModified: boolean = false;
  public pastDeploymentControl = {
    configuredNoOfPreviousYear: 1,
    islastYearOptionChecked: new UntypedFormControl(true),
  };
  public numberOfvslDeploymentLine: number;
  public vesselCurrentDeployment: any;
  public vesselUpcomingDeployment: any;
  public vesselPreviousDeployments: any[];
  public vesselDeploymentDropdowns: any;
  public lastYearVesselPreviousDeployments: any[];
  public deletedDeploymentLines: any = [];
  public combineFixArrFromOverview: any[];
  public estDeliveryDateArray: any[];
  public oldestDeliveryDate: any;
  public estReDeliveryDateArray: any[];
  public newestReDeliveryDate: any;
  public schedulePrfStartPortPKId: any;
  public schedulePrfEndPortPKId: any;
  public isCancelShowPreviousClick: boolean = false;
  public isMultipleProformasflag: boolean = false;
  public allPortsByService: any;
  public serviceNameWithCode: any;
  public isFinanceUser: boolean = false;
  /** Objects for create/update form */
  public veslDeploymentsObj: any;
  public vesselPreviousDeploymentsObj: any;
  public lastYearVesselPreviousDeploymentsObj: any;
  public dplDeltedMsg = 'Are you sure to you want to delete this deployment?';
  public emptyMessage = 'Sorry, no records found for vessel deployment';
  public depErrorLineMinDate = moment('2021-07-01').format('YYYY-MM-DD');
  public depErrorLineMinDateString = '2021-07-01';

  public columnDef = [
    { name: 'Start date', class: 'common' },
    { name: 'End date', class: 'common' },
    { name: 'Service', class: 'service' },
    { name: 'Position', class: 'position' },
    { name: 'Reason', class: 'reason' },
    { name: 'Comment', class: 'comment' },
    { name: 'Start Port', class: 'common' },
    { name: 'End port', class: 'common' },
  ];
  public edieModeColumnDef = [
    ...this.columnDef.slice(0, 6),
    ...[
      { name: 'Start Port', class: 'startport' },
      { name: 'End port', class: 'endport' },
    ],
  ];
  // create dummy
  @Input() public fromVesselListVslId: boolean;
  @Input() public fromVesselListPage: boolean;
  @Input() public fromVesselDetailPage: boolean; // ExtendTo Dummy implementation
  @Input() public fromCreateDummy: boolean;
  @Input() public editModeForDummy: boolean;
  @Input() public dummyDetails: any;
  @Input() public dummyOverlapDetails: any;

  @Input() public isUserNotAuthToEdit: boolean;
  public finalPayloadFromRedesign: any;
  @Output() public getServiceChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getPositionChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getStartPortChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public getUpdatedNameForDummy: EventEmitter<any> = new EventEmitter();

  @Output() public depControlValueChangeEventsEmiter: EventEmitter<any> = new EventEmitter();

  @Output() public emptyDummyName: EventEmitter<any> = new EventEmitter();
  @Output() public getDatesChangedforDummy: EventEmitter<any> = new EventEmitter();
  public sticky: boolean = false;
  public elementPosition: any;
  @ViewChild('stickyHeader', { read: ElementRef, static: false }) public stickyHeader: ElementRef;

  public allPorts: any;
  public lstofServicesPositions: any[];
  public depStartPortListLineWise: any = [];
  public depEndPortListLineWise: any = [];
  public methodVisitCount = 0;
  public showWarningOverlapping: boolean = false;
  public isExtendDummyCondition: boolean = false;
  public overlappingMsg: any;
  public overlappingErrorMsg: any = [];
  public showErrorOverlapping: boolean = false;
  public showPortRangeError: boolean = false;
  public portRangeErrorMsg: any;
  public overlappingExist: boolean = false;
  public vesselData: any;
  public saveAfterDelete: boolean = true;
  public saveAfterDeleteStatus: any;
  public isDepLinesValid = [];

  constructor(
    private fb: UntypedFormBuilder,
    private vesselsService: VesselsService,
    private portsService: PortService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private sessionTimeoutService: SessionTimeoutService
  ) {
    this.vslId = this.route.parent.snapshot.paramMap.get('id');
    route.parent.data.subscribe(({ details }) => {
      this.vesselDetail = details;
    });
    this.vesselDeploymentFrmGrp = this.fb.group({
      vesselDeploymentLines: this.fb.array([]),
    });
  }

  public ngOnInit() {
    if (!this.isOwnDeploymentType) {
      this.vesselDetail = this.depItems;
      this.veselType = this.depItems.vcType;
    }
    this.vesselsService.data.subscribe(async (data) => {
      this.combineFixArrFromOverview = await data;
      // To retrieve oldest estimated delivery rate for charter type
      if (data !== '') {
        this.estDeliveryDateArray = data.map((e) => e.fixDelivDate || e.fixEstimatedDate);
        const estDeliveryDateArray = this.estDeliveryDateArray;
        this.oldestDeliveryDate = estDeliveryDateArray.reduce((date2, date1) =>
          Date.parse(date1) < Date.parse(date2) ? date1 : date2
        );
        // To retrieve newest estimated redelivery rate for charter type
        this.estReDeliveryDateArray = data.map((e) => e.fixActRedelivDate || e.fixEstRedelivDate);
        const estReDeliveryDateArray = this.estReDeliveryDateArray;
        this.newestReDeliveryDate = estReDeliveryDateArray.reduce((date1, date2) =>
          Date.parse(date1) > Date.parse(date2) ? date1 : date2
        );
      }
    });

    this.isVsDepUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.isVsDepDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'delete');
    this.isFinanceUser = this.isOwnDeploymentType
      ? this.auth.userProfile.roles.includes('Finance') ||
        this.auth.userProfile.roles.includes('PostFixture') ||
        this.auth.userProfile.roles.includes('FinanceL3') ||
        this.auth.userProfile.roles.includes('Chartering')
      : this.auth.userProfile.roles.includes('Finance');
    setTimeout(() => {
      this.loadServiceAndDplyReasonList();

      this.isCancelShowPreviousClick = true;
      // this.createDeploymentForm();

      if (this.vslId) {
        this.fromVesselListVslId = this.fromVesselListVslId ? this.fromVesselListVslId : this.vslId;
        if (this.isOwnDeploymentType) {
          this.loadCurrUpcomPrevDeploymentsOwn(this.fromVesselListVslId);
        } else {
          this.loadCurrUpcomPrevDeploymentsRelet(this.fromVesselListVslId);
        }
      }

      // Extend To Dummy Condition
      if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselDetailPage) {
        this.extendDummyDetails(this.fromVesselListVslId);
      }
      if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselListPage) {
        this.loaddummyDeployments(this.fromVesselListVslId);
      } else {
        if (this.fromCreateDummy) {
          this.loadDummyMode();
        }
      }
    }, 1); //reduced time for performance fix

    if (this.router.url.includes('extenddummy')) {
      this.isExtendDummyCondition = true;
    } else {
      this.isExtendDummyCondition = false;
    }

    this.alertService.alertVesselId.subscribe((res) => {
      this.showAlertForVesselId = res;
    });
  }

  public ngOnChanges() {
    if (this.dummyOverlapDetails) {
      this.displayDetails(this.dummyOverlapDetails);
    }
  }

  @HostListener('window:scroll', ['$event'])
  public handleScroll() {
    if (this.editDeployments && this.vesselDeploymentLines.controls.length > 10) {
      this.sticky = window.pageYOffset >= this.elementPosition ? true : false;
      this.changeDetectorRef.markForCheck();
    } else {
      if (this.isPreviousDeployments) {
        this.sticky = window.pageYOffset >= this.elementPosition ? true : false;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  get isPostFixutreUserAuthorized() {
    return this.auth.roles
      ? this.auth.isRolePresent(this.auth.roles, 'PostFixture') ||
          this.auth.isRolePresent(this.auth.roles, 'Chartering')
      : false;
  }

  public loadCurrUpcomPrevDeploymentsOwn(vslId?) {
    this.vesselsService
      .getVesselDeployments(vslId)
      .pipe(take(1), distinctUntilChanged(), takeUntil(this.unsubscribe$))

      .subscribe(async (vesselDeployments: Response) => {
        if (vesselDeployments) {
          this.portsService.sendCount.next(vesselDeployments);
          this.veslDeployments = await [
            ...vesselDeployments['allDeployments'][0]['deployments']['currentDeployment'],
            ...vesselDeployments['allDeployments'][0]['deployments']['upcomingDeployment'],
          ].filter((depLines) => !depLines.depIsDeleted);

          this.veselID = vesselDeployments['allDeployments'][0].vcPKID;
          this.veselType = vesselDeployments['allDeployments'][0].vcType;
          this.numberOfvslDeploymentLine = this.veslDeployments.length;
          this.vesselCurrentDeployment =
            vesselDeployments['allDeployments'][0]['deployments']['currentDeployment'] &&
            vesselDeployments['allDeployments'][0]['deployments']['currentDeployment'].length
              ? vesselDeployments['allDeployments'][0]['deployments']['currentDeployment']
              : null;
          if (this.vesselCurrentDeployment != null) {
            const valCurrent = this.vesselCurrentDeployment.maerskAllocationOnServiceDto;
            if (valCurrent != null) {
              if (
                valCurrent.mlmtCap === 0 ||
                valCurrent.mlTeuCap === 0 ||
                valCurrent.totalMTCap === 0 ||
                valCurrent.totalTeuCap === 0
              ) {
                this.vsaFlagCur = ' ';
              } else {
                const teuPercent = Number((valCurrent.mlTeuCap / valCurrent.totalTeuCap) * 100).toFixed(0);
                const mlPercent = Number((valCurrent.mlmtCap / valCurrent.totalMTCap) * 100).toFixed(0);
                if (+teuPercent < 100 || +mlPercent < 100) {
                  this.vsaFlagCur = '(VSA) ';
                } else {
                  this.vsaFlagCur = ' ';
                }
              }
            }
          }

          this.vesselUpcomingDeployment =
            vesselDeployments['allDeployments'][0]['deployments']['upcomingDeployment'] &&
            vesselDeployments['allDeployments'][0]['deployments']['upcomingDeployment'].length
              ? vesselDeployments['allDeployments'][0]['deployments']['upcomingDeployment']
              : null;
          if (this.vesselUpcomingDeployment != null) {
            this.vesselUpcomingDeployment.map((e) => {
              const valUpcomming = e.maerskAllocationOnServiceDto;
              if (valUpcomming != null) {
                if (
                  valUpcomming.mlmtCap === 0 ||
                  valUpcomming.mlTeuCap === 0 ||
                  valUpcomming.totalMTCap === 0 ||
                  valUpcomming.totalTeuCap === 0
                ) {
                  e.vsaFlagUp = ' ';
                } else {
                  const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
                  const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
                  if (+teuPercent < 100 || +mlPercent < 100) {
                    e.vsaFlagUp = '(VSA) ';
                  } else {
                    e.vsaFlagUp = ' ';
                  }
                }
              }
            });
          }
          this.vesselPreviousDeployments =
            vesselDeployments['allDeployments'][0]['deployments']['previousDeployment'] &&
            vesselDeployments['allDeployments'][0]['deployments']['previousDeployment'].length
              ? vesselDeployments['allDeployments'][0]['deployments']['previousDeployment']
              : null;
        }
        if (this.veslDeployments && this.isVsDepUpdateAuth) {
          if (this.isPostFixutreUserAuthorized) {
            this.editDeployments = true;
            this.toogleEditMode();
          } else {
            this.loadDefaultEditMode();
          }
        } else {
          this.editDeployments = true;
          this.toogleEditMode();
        }
      });

    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe(async (controlValue) => {
      if (controlValue) {
        this.lastYearVesselPreviousDeployments = await this.lastYearPreviousDeployments(controlValue);
        this.lastYearVesselPreviousDeploymentsObj = this.lastYearVesselPreviousDeployments
          ? JSON.parse(JSON.stringify(this.lastYearVesselPreviousDeployments))
          : null;
      }
    });
    if (typeof this.veslDeployments !== 'undefined') {
      this.veslDeploymentsObj = JSON.parse(JSON.stringify(this.veslDeployments));
    }
  }

  public loadCurrUpcomPrevDeploymentsRelet(vslId?) {
    this.veslDeployments = [
      ...this.depItems.deployments.currentDeployment,
      ...this.depItems.deployments.upcomingDeployment,
    ].filter((depLines) => !depLines.depIsDeleted);
    this.veselID = this.depItems.vcPKID;
    // this.veselID= vesselDeployments['allDeployments'][0].vcPKID;
    this.veselType = this.depItems.vcType;
    this.numberOfvslDeploymentLine = this.veslDeployments.length;
    this.vesselCurrentDeployment =
      this.depItems.deployments.currentDeployment && this.depItems.deployments.currentDeployment.length
        ? this.depItems.deployments.currentDeployment
        : null;
    if (this.vesselCurrentDeployment != null) {
      const valCurrent = this.vesselCurrentDeployment.maerskAllocationOnServiceDto;
      if (valCurrent != null) {
        if (
          valCurrent.mlmtCap === 0 ||
          valCurrent.mlTeuCap === 0 ||
          valCurrent.totalMTCap === 0 ||
          valCurrent.totalTeuCap === 0
        ) {
          this.vsaFlagCur = ' ';
        } else {
          const teuPercent = Number((valCurrent.mlTeuCap / valCurrent.totalTeuCap) * 100).toFixed(0);
          const mlPercent = Number((valCurrent.mlmtCap / valCurrent.totalMTCap) * 100).toFixed(0);
          if (+teuPercent < 100 || +mlPercent < 100) {
            this.vsaFlagCur = '(VSA) ';
          } else {
            this.vsaFlagCur = ' ';
          }
        }
      }
    }

    this.vesselUpcomingDeployment =
      this.depItems.deployments.upcomingDeployment && this.depItems.deployments.upcomingDeployment.length
        ? this.depItems.deployments.upcomingDeployment
        : null;
    if (this.vesselUpcomingDeployment != null) {
      this.vesselUpcomingDeployment.map((e) => {
        const valUpcomming = e.maerskAllocationOnServiceDto;
        if (valUpcomming != null) {
          if (
            valUpcomming.mlmtCap === 0 ||
            valUpcomming.mlTeuCap === 0 ||
            valUpcomming.totalMTCap === 0 ||
            valUpcomming.totalTeuCap === 0
          ) {
            e.vsaFlagUp = ' ';
          } else {
            const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
            const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
            if (+teuPercent < 100 || +mlPercent < 100) {
              e.vsaFlagUp = '(VSA) ';
            } else {
              e.vsaFlagUp = ' ';
            }
          }
        }
      });
    }
    this.vesselPreviousDeployments =
      this.depItems.deployments.previousDeployment && this.depItems.deployments.previousDeployment.length
        ? this.depItems.deployments.previousDeployment
        : null;
    // }
    if (this.veslDeployments && this.isVsDepUpdateAuth) {
      if (this.isPostFixutreUserAuthorized) {
        this.editDeployments = true;
        this.toogleEditMode();
      } else {
        this.loadDefaultEditMode();
      }
    } else {
      this.editDeployments = true;
      this.toogleEditMode();
    }
    // });

    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe(async (controlValue) => {
      if (controlValue) {
        this.lastYearVesselPreviousDeployments = await this.lastYearPreviousDeployments(controlValue);
        this.lastYearVesselPreviousDeploymentsObj = this.lastYearVesselPreviousDeployments
          ? JSON.parse(JSON.stringify(this.lastYearVesselPreviousDeployments))
          : null;
      }
    });
    if (typeof this.veslDeployments !== 'undefined') {
      this.veslDeploymentsObj = JSON.parse(JSON.stringify(this.veslDeployments));
    }
  }

  // Extend To Dummy calling DetaIL API
  public extendDummyDetails(vslId) {
    const fixEstRedelivDate = localStorage.getItem('this.fixEstRedelivDate');
    const depPosition = Number(localStorage.getItem('this.depPosition'));
    const depStringID = Number(localStorage.getItem('this.depStringID'));

    // this.vesselsService.getVMD(vslId)
    // .pipe(distinctUntilChanged())
    // .subscribe((vesselDetail: Response) => {
    //   this.vesselDetail = vesselDetail;

    const extendedDummy = this.fb.group({
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: depStringID || null,
      depPosition: depPosition || null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: moment(fixEstRedelivDate),
      depEndDate: null,
      depReasonCodeID: null,
      depComments: '',
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,

      durationFixtureEndDate: null,
      vesselOwnType: '',
      depVesselCodeId: 0,
      setDepEndDateErrorCSS: false,
      isMultipleProformas: false,
    });
    this.vesselDeploymentLines.push(extendedDummy);
    const obj = { depStringID: depStringID, depBeginDate: fixEstRedelivDate };
    this.loadPortsByService(obj, extendedDummy, 0);

    this.controlsValueChanges();
    // });
  }

  public loaddummyDeployments(vslId?) {
    this.vesselsService
      .getDummyDetails(vslId)
      .pipe(take(1), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(async (response: any) => {
        const vesselDeployments = response.dummyVesselDetails.deployments;
        if (vesselDeployments) {
          this.veslDeployments = await [
            ...vesselDeployments['currentDeployment'],
            ...vesselDeployments['upcomingDeployment'],
          ].filter((depLines) => !depLines.depIsDeleted);

          this.numberOfvslDeploymentLine = this.veslDeployments.length;
          this.vesselCurrentDeployment =
            vesselDeployments['currentDeployment'] && vesselDeployments['currentDeployment'].length
              ? vesselDeployments['currentDeployment'][0]
              : null;
          if (this.vesselCurrentDeployment != null) {
            const valCurrent = this.vesselCurrentDeployment.maerskAllocationOnServiceDto;
            if (valCurrent != null) {
              if (
                valCurrent.mlmtCap === 0 ||
                valCurrent.mlTeuCap === 0 ||
                valCurrent.totalMTCap === 0 ||
                valCurrent.totalTeuCap === 0
              ) {
                this.vsaFlagCur = ' ';
              } else {
                const teuPercent = Number((valCurrent.mlTeuCap / valCurrent.totalTeuCap) * 100).toFixed(0);
                const mlPercent = Number((valCurrent.mlmtCap / valCurrent.totalMTCap) * 100).toFixed(0);
                if (+teuPercent < 100 || +mlPercent < 100) {
                  this.vsaFlagCur = '(VSA) ';
                } else {
                  this.vsaFlagCur = ' ';
                }
              }
            }
          }

          this.vesselUpcomingDeployment =
            vesselDeployments['upcomingDeployment'] && vesselDeployments['upcomingDeployment'].length
              ? vesselDeployments['upcomingDeployment']
              : null;
          if (this.vesselUpcomingDeployment != null) {
            this.vesselUpcomingDeployment.map((e) => {
              const valUpcomming = e.maerskAllocationOnServiceDto;
              if (valUpcomming != null) {
                if (
                  valUpcomming.mlmtCap === 0 ||
                  valUpcomming.mlTeuCap === 0 ||
                  valUpcomming.totalMTCap === 0 ||
                  valUpcomming.totalTeuCap === 0
                ) {
                  e.vsaFlagUp = ' ';
                } else {
                  const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
                  const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
                  if (+teuPercent < 100 || +mlPercent < 100) {
                    e.vsaFlagUp = '(VSA) ';
                  } else {
                    e.vsaFlagUp = ' ';
                  }
                }
              }
            });
          }
          this.vesselPreviousDeployments =
            vesselDeployments['previousDeployment'] && vesselDeployments['previousDeployment'].length
              ? vesselDeployments['previousDeployment']
              : null;
        }
        if (this.veslDeployments && this.isVsDepUpdateAuth) {
          if (this.isPostFixutreUserAuthorized) {
            this.editDeployments = true;
            this.toogleEditMode();
          } else {
            this.loadDefaultEditMode();
          }
        }
        // else {
        //   if (this.auth.isRolePresent(this.auth.roles, 'ReadOnly')) {
        //     // this.editDeployments = false;
        //     // this.toogleEditMode();
        //   } else {
        //     // this.editDeployments = true;
        //     // this.toogleEditMode();
        //   }
        // }
      });

    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe(async (controlValue) => {
      if (controlValue) {
        this.lastYearVesselPreviousDeployments = await this.lastYearPreviousDeployments(controlValue);
        this.lastYearVesselPreviousDeploymentsObj = this.lastYearVesselPreviousDeployments
          ? JSON.parse(JSON.stringify(this.lastYearVesselPreviousDeployments))
          : [{}];
      }
    });
    if (typeof this.veslDeployments !== 'undefined') {
      this.veslDeploymentsObj = JSON.parse(JSON.stringify(this.veslDeployments));
    }
  }

  public islastYearOptionControlChanage() {}

  public loadServiceAndDplyReasonList() {
    this.vesselsService
      .getVesselDeploymentDropdownsServicesList(this.vslId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vesselDeploymentDropdowns: Response) => {
        if (vesselDeploymentDropdowns) {
          this.vesselDeploymentDropdownsOnInit = vesselDeploymentDropdowns;
          this.deploymentDropdown = this.vesselDeploymentDropdownsOnInit;
          this.vesselDeploymentServices = this.deploymentDropdown.servicesList.map((o) => {
            return {
              routeCode: o.routeCode,
              serviceCode: o.serviceCode,
              strPKID: o.serviceId,
              strNameCode: o.serviceName,
              svcName: o.svcName,
              isSpecial: o.isSpecial,
              lstProformas: o.lstProformas,
            };
          });
          this.vesselDeploymentReasons = this.deploymentDropdown.deploymentReasons.map((o) => {
            return {
              drDescription: o.drDescription,
              drIsActive: o.drDescription,
              drReason: o.drReason,
              drpkID: o.drpkID,
            };
          });
        }
      });
  }

  /* get service code for read-only mode*/
  public getServiceCode(serviceName) {
    let serviceNameAndCode = null;
    if (serviceName) {
      serviceNameAndCode = this.vesselDeploymentServices.filter((e) => {
        if (e.svcName === serviceName) {
          return e.strNameCode;
        }
      });
      if (this.isOwnDeploymentType) {
        for (let i = 0; i < serviceNameAndCode.length; i++) {
          if (serviceNameAndCode[i].svcName === serviceName) {
            return serviceNameAndCode[i].strNameCode;
          }
        }
      } else {
        if (serviceNameAndCode[0].svcName === serviceName) {
          return serviceNameAndCode[0].strNameCode;
        } else {
          return serviceName;
        }
      }
    }
  }

  get vesselDeploymentLines(): UntypedFormArray {
    return <UntypedFormArray>this.vesselDeploymentFrmGrp.get('vesselDeploymentLines');
  }

  public getControls() {
    return (<UntypedFormArray>this.vesselDeploymentFrmGrp.get('vesselDeploymentLines')).controls;
  }

  public getValue(i?) {
    return this.getControls()[i];
  }

  public getDeploymentLineValidity(i) {
    return (<UntypedFormArray>this.vesselDeploymentFrmGrp.get('vesselDeploymentLines')).controls[i].invalid;
  }

  public lastYearPreviousDeployments(v?) {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - this.pastDeploymentControl.configuredNoOfPreviousYear);
    const currentUpcommngDeployments = this.veslDeployments;
    const islastYearData =
      this.vesselPreviousDeployments != null
        ? [
            ...this.vesselPreviousDeployments.filter(
              (o) => new Date(o.depEndDate).getFullYear() >= currentDate.getFullYear()
            ),
            ...currentUpcommngDeployments,
          ]
        : currentUpcommngDeployments != null
        ? [...currentUpcommngDeployments]
        : null;
    return islastYearData != null ? (islastYearData.length !== 0 ? islastYearData : null) : null;
  }

  public getLastDeploymentLineFromCurrentOrUpcominglines() {
    if (this.vesselUpcomingDeployment != null || this.vesselCurrentDeployment != null) {
      return this.vesselUpcomingDeployment != null
        ? Array.isArray(this.vesselUpcomingDeployment)
          ? this.vesselUpcomingDeployment[this.vesselUpcomingDeployment.length - 1]
          : this.vesselUpcomingDeployment
        : this.vesselCurrentDeployment != null
        ? Array.isArray(this.vesselCurrentDeployment)
          ? this.vesselCurrentDeployment[this.vesselCurrentDeployment.length - 1]
          : this.vesselCurrentDeployment
        : null;
    } else {
      return this.vesselPreviousDeployments != null
        ? Array.isArray(this.vesselPreviousDeployments)
          ? this.vesselPreviousDeployments[this.vesselPreviousDeployments.length - 1]
          : this.vesselPreviousDeployments
        : null;
    }
  }

  public loadDummyMode() {
    this.editDeployments = !this.editDeployments;
    this.addNewDeploymentLine(null);
  }

  public showLastYearDeploymentData() {
    const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
    this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe((controlValue) => {
      if (this.isPreviousDeployments && controlValue) {
        if (this.lastYearVesselPreviousDeploymentsObj != null) {
          if (lastDeploymentLine != null) {
            this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj, lastDeploymentLine);
          } else {
            this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
          }
          this.CheckDuplicateDepLineWithPrevDepLines();
        } else {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(lastDeploymentLine);
        }
      }
    });
  }

  public resetFromGrupControls() {
    this.resetVesselDeploymentFrmGrp(this.vesselDeploymentLines);
  }

  public loadDefaultEditMode() {
    this.editDeployments = !this.editDeployments;
    if (this.fromCreateDummy) {
      this.editDeployments = true;
    }
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.elementPosition = this.getCurrentElementOffsetTop(this.stickyHeader);
    this.veslDeploymentsObj =
      this.veslDeployments != null && this.veslDeployments.length > 0
        ? JSON.parse(JSON.stringify(this.veslDeployments))
        : null;
    const addCurrentUpcommng =
      this.vesselPreviousDeployments != null ? [...this.vesselPreviousDeployments, ...this.veslDeployments] : null;
    this.vesselPreviousDeploymentsObj =
      this.vesselPreviousDeployments != null
        ? JSON.parse(JSON.stringify(addCurrentUpcommng))
        : this.veslDeploymentsObj
        ? this.veslDeploymentsObj
        : null;
    if (this.pastDeploymentControl.islastYearOptionChecked) {
      this.lastYearVesselPreviousDeploymentsObj = this.lastYearPreviousDeployments();
      this.showLastYearDeploymentData();
    }
    if (this.editDeployments && !this.isPreviousDeployments) {
      const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
      if (this.veslDeploymentsObj != null) {
        if (lastDeploymentLine != null) {
          this.createDeploymentForm(this.veslDeploymentsObj, lastDeploymentLine);
        } else {
          this.createDeploymentForm(this.veslDeploymentsObj);
          this.addNewDeploymentLine(null);
        }
      } else {
        if (
          this.vesselUpcomingDeployment == null &&
          this.vesselCurrentDeployment == null &&
          this.vesselPreviousDeployments != null
        ) {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(lastDeploymentLine);
          if (this.fromCreateDummy) {
            this.getServiceChangeValforDummy.emit(null);
          }
        } else {
          this.resetFromGrupControls();
          this.addNewDeploymentLine(null);
          this.getServiceChangeValforDummy.emit(null);
        }
      }
    } else {
      this.deletedDeploymentLines = [];
    }
  }

  public clearAlertService() {
    this.alertService.clear();
    this.alertService.alertVesselId.next('');
    this.showAlertForVesselId = '';
    this.preventSave = false;
    this.warnings = [];
    this.overlappingErrorMsg = [];
    this.incorrectDeploymentDetails = '';
    this.incorrectDeploymentDetailsNew = '';
    this.showPortRangeError = false;
    this.overlappingMsg = [];
    this.trade_restrictions = [];
    this.showErrorOverlapping = false;
    this.showWarningOverlapping = false;
    this.changeButtonState();
  }

  public changeButtonState() {
    (this.showWarningOverlapping || this.showPortRangeError || this.warnings.length) && !this.showErrorOverlapping
      ? this.vesselsService.saveButtonState.next(true)
      : this.vesselsService.saveButtonState.next(false);
  }

  public controlsValueChanges() {
    const lineInGap = false;
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    this.vesselDeploymentLines.controls.forEach((control, index) => {
      control.valueChanges
        //debounceTime reduced
        .pipe(debounceTime(10), distinctUntilChanged(), takeUntil(this.unsubscribe$))
        .subscribe(() => {
          // Validation position pattern
          control.get('depPosition').valueChanges.subscribe((value) => {
            const patternMatched = this.validatePositionPattern(value);
            if (!patternMatched) {
              this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });
            }
          });
          // Deployment line Validation
          let isdeplineEmpty = false;
          let isSpecialPosition = false;
          if (!this.isOwnDeploymentType) {
            if (this.vesselDeploymentLines.value.length >= 1) {
              if (
                this.vesselDeploymentLines.value[index] &&
                !this.vesselDeploymentLines.value[index].isSpecialPosition
              ) {
                // save after delete button
                this.isDepLinesValid = [];
                this.vesselDeploymentLines.value.forEach((value, i) => {
                  if (this.vesselDeploymentLines.value.length - 2 > i) {
                    isdeplineEmpty = this.setSaveAfterDeleteState(i);
                  }
                });
              } else {
                isSpecialPosition = true;
                // if Any Special Service
                if (
                  this.vesselDeploymentLines.value[index] &&
                  (this.vesselDeploymentLines.value[index].depSpecialServiceStartPortID === null ||
                    this.vesselDeploymentLines.value[index].depSpecialServiceEndPortID === null)
                ) {
                  isdeplineEmpty = true;
                  this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
                  this.vesselDeploymentFrmGrp.updateValueAndValidity();
                }
              }
            }
          } else {
            if (this.vesselDeploymentLines.value.length >= 1 && this.vesselDeploymentLines.value[index]) {
              if (!this.vesselDeploymentLines.value[index].isSpecialPosition) {
                if (
                  this.vesselDeploymentLines.value[index].depBeginDate === null ||
                  this.vesselDeploymentLines.value[index].depEndDate === null ||
                  this.vesselDeploymentLines.value[index].depStringID === null ||
                  this.vesselDeploymentLines.value[index].depPosition === null ||
                  this.vesselDeploymentLines.value[index].depReasonCodeID === null ||
                  this.vesselDeploymentLines.value[index].depStartPortID === null ||
                  this.vesselDeploymentLines.value[index].depEndPortID === null
                ) {
                  isdeplineEmpty = true;
                  this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
                  this.vesselDeploymentFrmGrp.updateValueAndValidity();
                } else {
                  if (
                    this.vesselDeploymentLines.value[index].depBeginDate !== null &&
                    this.vesselDeploymentLines.value[index].depEndDate !== null &&
                    this.vesselDeploymentLines.value[index].depStringID !== null &&
                    this.vesselDeploymentLines.value[index].depPosition !== null &&
                    this.vesselDeploymentLines.value[index].depReasonCodeID !== null &&
                    this.vesselDeploymentLines.value[index].depStartPortID !== null &&
                    this.vesselDeploymentLines.value[index].depEndPortID !== null
                  ) {
                    isdeplineEmpty = false;
                    this.vesselDeploymentFrmGrp.setErrors(null);
                    this.vesselDeploymentFrmGrp.updateValueAndValidity();
                  }
                }
              } else {
                isSpecialPosition = true;
                // if Any Special Service
                if (
                  this.vesselDeploymentLines.value[index].depSpecialServiceStartPortID === null ||
                  this.vesselDeploymentLines.value[index].depSpecialServiceEndPortID === null
                ) {
                  isdeplineEmpty = true;
                  this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
                }
              }
            }
          }
          if (!isdeplineEmpty) {
            this.vesselDeploymentFrmGrp.setErrors(null);
            this.vesselDeploymentFrmGrp.updateValueAndValidity();
          }

          this.isAnyControlModified = true;
          // this.showWarningOverlapping = false;
          // this.showErrorOverlapping = false;
          // this.warnings = [];
          // this.showPortRangeError = false;
          // this.showIncorrectDetailError = false;
          if ((this.fromVesselListPage && this.fromVesselListVslId) || this.fromCreateDummy) {
            const emitObj = {
              isInvalid: this.vesselDeploymentLines.invalid,
              isModified: this.isAnyControlModified,
              isPristine: this.vesselDeploymentLines.pristine,
              isDirty: this.vesselDeploymentLines.dirty,
              controlIndex: index,
              isdeplineEmpty: isdeplineEmpty,
              isSpecialPosition: isSpecialPosition,
            };
            this.depControlValueChangeEventsEmiter.emit(emitObj);
          }
        });

      if (this.vesselDeploymentLines.controls[index].dirty) {
        this.vesselDeploymentLines.controls[index]['controls']['depIsUpdated'].setValue(true);
        this.isCancelShowPreviousClick = false;
      }
    });
  }

  public setSaveAfterDeleteState(index) {
    if (
      this.vesselDeploymentLines.value[index].depBeginDate === null ||
      this.vesselDeploymentLines.value[index].depEndDate === null ||
      this.vesselDeploymentLines.value[index].depStringID === null ||
      this.vesselDeploymentLines.value[index].depPosition === null ||
      this.vesselDeploymentLines.value[index].depReasonCodeID === null ||
      this.vesselDeploymentLines.value[index].depStartPortID === null ||
      this.vesselDeploymentLines.value[index].depEndPortID === null
    ) {
      this.isDepLinesValid.push('true');
      this.vesselDeploymentFrmGrp.setErrors({ invalid: true });
      this.vesselDeploymentFrmGrp.updateValueAndValidity();
    } else {
      if (
        this.vesselDeploymentLines.value[index].depBeginDate !== null &&
        this.vesselDeploymentLines.value[index].depEndDate !== null &&
        this.vesselDeploymentLines.value[index].depStringID !== null &&
        this.vesselDeploymentLines.value[index].depPosition !== null &&
        this.vesselDeploymentLines.value[index].depReasonCodeID !== null &&
        this.vesselDeploymentLines.value[index].depStartPortID !== null &&
        this.vesselDeploymentLines.value[index].depEndPortID !== null
      ) {
        this.isDepLinesValid.push('false');
        this.vesselDeploymentFrmGrp.setErrors(null);
        this.vesselDeploymentFrmGrp.updateValueAndValidity();
      }
    }
    const isdeplineEmpty = this.isDepLinesValid.some((val) => val === 'true');
    isdeplineEmpty
      ? this.vesselDeploymentFrmGrp.setErrors({ invalid: true })
      : this.vesselDeploymentFrmGrp.setErrors(null);
    return isdeplineEmpty;
  }

  // Error Handling to Validate Deployment Gap Function

  public validateGapDeploymentLine(control?, index?) {
    // control null check//

    if (index > 0) {
      const controlBeginDateNextDeployment = new Date(
        new DatePipe('en-US').transform(control.value.depBeginDate, 'dd-MMM-yy')
      );
      const lastDeployEndDate = new Date(
        new DatePipe('en-US').transform(this.vesselDeploymentLines.value[index - 1].depEndDate, 'dd-MMM-yy')
      );

      if (controlBeginDateNextDeployment.getTime() < lastDeployEndDate.getTime()) {
        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });

        return true;
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);

        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
      }
    } else {
      if (!this.fromCreateDummy || this.fromCreateDummy === undefined) {
        const controlBeginDateSameDeployment =
          control.value.depBeginDate !== null ? moment(control.value.depBeginDate).format('YYYY-MM-DD') : null;
        const controlEndDateSameDeployment =
          control.value.depEndDate !== null ? moment(control.value.depEndDate).format('YYYY-MM-DD') : null;
        if (controlBeginDateSameDeployment > controlEndDateSameDeployment) {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });

          return true;
        } else {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        }
      }
    }
  }

  public validateGapDeploymentLineEndDate(control?, index?, isLastDeploymentLine?) {
    if (
      index !== this.vesselDeploymentLines.value.length - 1 &&
      isValidDate(this.vesselDeploymentLines.value[index + 1].depBeginDate)
    ) {
      const controlEndDateDeployment = new Date(new DatePipe('en-US').transform(control.value.depEndDate, 'dd-MMM-yy'));
      const nextDeployStartDate = new Date(
        new DatePipe('en-US').transform(this.vesselDeploymentLines.value[index + 1].depBeginDate, 'dd-MMM-yy')
      );

      if (controlEndDateDeployment.getTime() > nextDeployStartDate.getTime()) {
        // this.addLastDeploymentValidators(this.vesselDeploymentLines.controls[index]['controls']['depBeginDate']);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
    }

    this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
  }

  public isValidDateUpdateControlValue(control?, val?) {
    setTimeout(() => {
      control.setValue(val);

      control.updateValueAndValidity();

      this.vesselDeploymentLines.updateValueAndValidity();
    }, 100);
  }

  public addLastDeploymentValidators(control?) {
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
    this.vesselDeploymentLines.updateValueAndValidity();
    this.controlsValueChanges();
  }

  public toogleEditMode() {
    this.editDeployments = !this.editDeployments;
    if (this.fromCreateDummy) {
      this.editDeployments = true;
    }
    this.veslDeploymentsObj =
      this.veslDeployments != null && this.veslDeployments.length > 0
        ? JSON.parse(JSON.stringify(this.veslDeployments))
        : null;
    const addCurrentUpcommng =
      this.vesselPreviousDeployments != null ? [...this.vesselPreviousDeployments, ...this.veslDeployments] : null;
    this.vesselPreviousDeploymentsObj =
      this.vesselPreviousDeployments != null
        ? JSON.parse(JSON.stringify(addCurrentUpcommng))
        : this.veslDeploymentsObj
        ? this.veslDeploymentsObj
        : null;
    if (this.editDeployments) {
      this.createDeploymentForm();
    }
    if (this.editDeployments && !this.isPreviousDeployments) {
      this.createDeploymentForm(this.veslDeploymentsObj);
    }
    if (this.editDeployments && this.isPreviousDeployments && !this.islastYearOptionChecked) {
      this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
    }
    if (this.editDeployments && this.isPreviousDeployments && this.islastYearOptionChecked) {
      this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
    } else {
      this.deletedDeploymentLines = [];
    }
  }

  public togglePreviousDeployment() {
    this.isPreviousDeployments = !this.isPreviousDeployments;
    this.isCancelShowPreviousClick = true;
    this.changeDetectorRef.detectChanges();
    if (!this.fromCreateDummy) {
      this.elementPosition = this.getCurrentElementOffsetTop(this.stickyHeader);
    }

    this.isPreviousDeployments
      ? (this.showHidePreviousDeploymentLink = 'Hide previous deployments')
      : (this.showHidePreviousDeploymentLink = 'Show previous deployments');

    if (this.editDeployments && this.isPreviousDeployments) {
      const lastDeploymentLine = this.getLastDeploymentLineFromCurrentOrUpcominglines();
      this.pastDeploymentControl.islastYearOptionChecked.valueChanges.subscribe((controlValue) => {
        if (this.isPreviousDeployments && controlValue) {
          this.resetFromGrupControls();
          if (this.lastYearVesselPreviousDeploymentsObj != null) {
            if (lastDeploymentLine != null) {
              this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj, lastDeploymentLine);
            } else {
              this.createDeploymentForm(this.lastYearVesselPreviousDeploymentsObj);
            }
          }
        }
        if (this.isPreviousDeployments && !controlValue) {
          this.resetFromGrupControls();
          // this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
          if (this.vesselPreviousDeploymentsObj != null) {
            if (lastDeploymentLine != null) {
              this.createDeploymentForm(this.vesselPreviousDeploymentsObj, lastDeploymentLine);
            } else {
              this.createDeploymentForm(this.vesselPreviousDeploymentsObj);
            }
            this.CheckDuplicateDepLineWithPrevDepLines();
          } else {
            if (this.vesselUpcomingDeployment != null || this.vesselCurrentDeployment != null) {
              this.resetFromGrupControls();
              this.addNewDeploymentLine(lastDeploymentLine);
            } else {
              this.resetFromGrupControls();
              this.addNewDeploymentLine(null);
            }
          }
        }
      });
    } else {
      if (this.editDeployments && !this.isPreviousDeployments) {
        this.editDeployments = false;
        this.loadDefaultEditMode();
      }
    }
    const element = <HTMLInputElement>document.getElementById('saveDefault');
    if (element) {
      element.disabled = true;
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.showAlertForVesselId) {
        const header = document.getElementById('alertBox');
        if (header) {
          header.scrollIntoView();
        }
      }
    }, 5000);

    if ((this.editDeployments && this.vesselDeploymentLines.controls.length > 10) || this.isPreviousDeployments) {
      this.elementPosition = this.getCurrentElementOffsetTop(this.stickyHeader);
      this.changeDetectorRef.markForCheck();
    }
  }

  public getCurrentElementOffsetTop(element: ElementRef) {
    const rect = element.nativeElement.getBoundingClientRect();
    return rect.top + window.pageYOffset - document.documentElement.clientTop;
  }

  public toggleMore = (click: boolean) => {
    if (click) {
      this.showMore = !this.showMore;
    }
    this.listItems.map((item, key) => {
      if (key > 1 && !this.showMore) {
        item._hostElement.classList.add('hide');
      } else {
        item._hostElement.classList.remove('hide');
      }
      return true;
    });
    // tslint:disable-next-line:semicolon
  };

  // private _getHostElement() {
  //   return this._elementRef.nativeElement;
  // }
  
  public deleteVesselDeployment() {
    this.clearAlertService();
    const postData = {
      vesselDeploymentId: this.vesselDeploymentFrmGrp.get('depPKID').value,
      lstScheduleIds: null,
    };
    this.vesselsService
      .deleteVesselDeployment(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.router
            .navigateByUrl('/vessels', { skipLocationChange: true })
            .then(() => this.router.navigate(['/vessels/' + this.vslId]));
        }
      });
  }

  public deleteDeployment(index?, control?) {
    const dialogRef = this.dialog.open(this.deleteDeploymentDialogue, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (control.value.depPKID && (control.value.depVesselID || this.fromCreateDummy)) {
            control.value.depIsDeleted = true;
            control.value.depIsUpdated = false;
            this.deletedDeploymentLines.push(control.value);
            control.patchValue(control.value.depIsDeleted, { emitEvent: false });
            this.vesselDeploymentLines.updateValueAndValidity();
            this.isAnyControlModified = true;
          }
          this.removeDeploymentLines(index);
          this.CheckDuplicateDepLineWithPrevDepLines();
          this.displayErrorLines(this.vesselDeploymentFrmGrp);
          this.isCancelShowPreviousClick = false;
          if (!this.isOwnDeploymentType) {
            this.isDepLinesValid = [];
            this.vesselDeploymentLines.value.forEach((value, i) => {
              if (this.vesselDeploymentLines.value.length - 2 > i) {
                this.setSaveAfterDeleteState(i);
              }
            });
          }
        }
      });
  }

  public insertDeployment(index) {
    this.clearAlertService();
    const newDeploymentLine = {
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: null,
      depPosition: null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: this.vesselDeploymentLines.value[index].depEndDate,
      depEndDate: null,
      depReasonCodeID: null,
      depComments: '',
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,
      durationFixtureEndDate: null,
      vesselOwnType: this.fromCreateDummy ? 'Dummy' : this.vesselDetail.vslVesselType,
      depVesselCodeId: this.fromCreateDummy ? 0 : this.veselID,
      setDepEndDateErrorCSS: false,
      isMultipleProformas: false,
    };
    this.depStartPortListLineWise.splice(index + 1, 0, { ports: [] });
    this.depEndPortListLineWise.splice(index + 1, 0, { ports: [] });
    this.vesselDeploymentLines.insert(index + 1, this.fb.group(newDeploymentLine));
    setTimeout(() => {
      this.displayErrorLines(this.vesselDeploymentFrmGrp);
    }, 800);
  }

  public removeDeploymentLines(index): void {
    this.clearAlertService();
    if (index > 0 && index < this.vesselDeploymentLines.length - 1) {
      const prevIndexDepLine = this.getValue(index - 1);
      const nextIndexDepLine = this.getValue(index + 1);
      if (nextIndexDepLine && prevIndexDepLine) {
        // Bug 331371 Save button is not getting enabled after deleting deployment line
        const BeginDate = nextIndexDepLine.value.depBeginDate !== null && nextIndexDepLine.value.depBeginDate;
        const EndDate = nextIndexDepLine.value.depEndDate !== null && nextIndexDepLine.value.depEndDate;
        if (BeginDate && !EndDate) {
          nextIndexDepLine['controls'].depBeginDate.setValue(prevIndexDepLine.value.depEndDate);
        }
      }
    }

    if (index !== 0) {
      this.vesselDeploymentLines.removeAt(index);
      // this.vesselDeploymentFrmGrp.markAsDirty();
      this.vesselDeploymentFrmGrp.setErrors(null);
    } else if (!this.fromCreateDummy && index === 0) {
      this.vesselDeploymentLines.removeAt(index);
      this.vesselDeploymentFrmGrp.markAsDirty();
    }
    // this.depStartPortListLineWise.splice(index, 1);
    this.depStartPortListLineWise.splice(index, 1);
    this.depEndPortListLineWise.splice(index, 1);
    const isPrevDepAvailable =
      this.lastYearVesselPreviousDeploymentsObj != null && this.lastYearVesselPreviousDeploymentsObj.length > 1;
    if (this.fromCreateDummy && index === 0) {
      const emittedObj = this.emitDummyObjectOnlyForFirstDeploymentLine();
      if (emittedObj) {
        this.getServiceChangeValforDummy.emit(emittedObj);
      }
      if (
        this.deletedDeploymentLines[0].depIsDeleted &&
        this.vesselDeploymentLines.value[1].depEndDate == null &&
        this.vesselDeploymentLines.value[1].depPosition == null &&
        !isPrevDepAvailable
      ) {
        this.vesselDeploymentLines.removeAt(index);
        const currentIndexDepLine = this.getValue(index);
        currentIndexDepLine['controls'].depBeginDate.setValue(null);
        // this.vesselDeploymentFrmGrp.markAsDirty();
        // Delete dummy
        const emitObjDelete = {
          isModified: this.isAnyControlModified,
          isDummyDeleted: true,
        };
        if (this.fromCreateDummy) {
          this.depControlValueChangeEventsEmiter.emit(emitObjDelete);
        }
      } else {
        this.vesselDeploymentLines.removeAt(index);
        // this.vesselDeploymentFrmGrp.markAsDirty();
      }

      // •	In case of 2 deployment lines, deleting the first deployment should change vessel name
      if (this.vesselDeploymentLines.value.length > 1 && index === 0) {
        const emitObj1 = this.emitUpdatedNameForDummy();
        if (emitObj1) {
          this.getUpdatedNameForDummy.emit(emitObj1);
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    const emitObj = {
      isInvalid: this.vesselDeploymentLines.invalid,
      isModified: this.isAnyControlModified,
      isPristine: false,
      isDirty: this.vesselDeploymentLines.dirty,
      controlIndex: index,
    };
    if (this.fromCreateDummy) {
      this.depControlValueChangeEventsEmiter.emit(emitObj);
    }
  }

  public closeDialogue() {
    this.dialogRef.close();
  }

  public addNewDeploymentLine(lastDeploymentLine?, frmGrpControl?) {
    // Eror handling for first deployment line start date
    const fixEstDelivDate =
      this.combineFixArrFromOverview && this.combineFixArrFromOverview.length > 0 ? this.oldestDeliveryDate : null;
    let lastDeploymentlineEndDate;
    // Error handling check on extend and dummy overview page for vessel type

    lastDeploymentlineEndDate =
      lastDeploymentLine != null
        ? isValidDate(lastDeploymentLine.depEndDate)
          ? convertToLocalIsoString(lastDeploymentLine.depEndDate, this.fromCreateDummy)
          : null
        : null;

    const extraLine = this.fb.group({
      depPKID: 0,
      depVesselID: this.vslId,
      depStringID: null,
      depPosition: null,
      depStartPortID: null,
      depEndPortID: null,
      depBeginDate: lastDeploymentlineEndDate,
      depEndDate: null,
      depReasonCodeID: null,
      depComments: null,
      specialSerivceType: null,
      unemployedeType: null,
      layupType: null,
      previousDeploymentId: 0,
      depSpecialServiceStartPortID: null,
      depSpecialServiceEndPortID: null,
      extraPosition: false,
      depIsUpdated: true,
      depIsDeleted: false,
      isSpecialService: false,
      isSpecialPosition: false,
      durationFixture: null,

      durationFixtureEndDate: null,

      vesselOwnType: this.fromCreateDummy ? 'Dummy' : this.veselType,
      depVesselCodeId: this.fromCreateDummy ? 0 : this.veselID,
      setDepEndDateErrorCSS: false,

      //  setDepEndDateErrorCSS: false,
      depToValidate: false,
      isMultipleProformas: false,
    });

    if (!this.fromVesselDetailPage) {
      this.vesselDeploymentLines.push(extraLine);
    }
    return;
  }

  public createDeploymentForm(depValues: any = null, lastDeploymentLine?) {
    const fixEstDelivDate = this.combineFixArrFromOverview.length > 0 ? this.oldestDeliveryDate : null;
    const fixEstRedelivDate = localStorage.getItem('this.fixEstRedelivDate');
    let fromFixture = localStorage.getItem('isFromFixture');

    if (depValues) {
      this.deploymentStartDate = depValues.depBeginDate;
      this.preServiceDetails = this.vesselDeploymentServices.filter((o) => o.serviceId === depValues.depStringID);
      let vesselDeploymentFrmGrpArr = this.vesselDeploymentLines;
      vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(vesselDeploymentFrmGrpArr);
      vesselDeploymentFrmGrpArr.reset();
      if (depValues && depValues.length) {
        [...depValues].forEach((depValues, index) => {
          const item = this.fb.group({
            depPKID: depValues.depPKID,
            depVesselID: this.vslId,
            depStringID: depValues.depStringID,
            depPosition: [
              depValues.extraPosition
                ? depValues.depPosition + 'X'
                : !depValues.extraPosition
                ? depValues.depPosition
                : null,
              [Validators.required],
            ],

            depStartPortID: !depValues.isSpecial ? depValues.depStartPortID : null,
            depEndPortID: !depValues.isSpecial ? depValues.depEndPortID : null,
            depBeginDate: [
              this.isOwnDeploymentType
                ? fromFixture === 'true'
                  ? moment(fixEstRedelivDate)
                  : convertToLocalIsoString(depValues.depBeginDate, this.fromCreateDummy)
                : convertToLocalIsoString(depValues.depBeginDate, this.fromCreateDummy),
              [Validators.required],
            ],

            depEndDate: isValidDate(depValues.depEndDate)
              ? convertToLocalIsoString(depValues.depEndDate, this.fromCreateDummy)
              : null,
            depReasonCodeID: [depValues.depReasonCodeID ? depValues.depReasonCodeID : null, [Validators.required]],
            depComments: depValues.depComments,
            specialSerivceType: depValues.specialSerivceType ? depValues.specialSerivceType : null,
            unemployedeType: depValues.unemployedeType ? depValues.unemployedeType : null,
            layupType: depValues.layupType ? depValues.layupType : null,
            previousDeploymentId: depValues.previousDeploymentId ? depValues.previousDeploymentId : null,
            depSpecialServiceStartPortID: depValues.depSpecialServiceStartPortID
              ? depValues.depSpecialServiceStartPortID
              : null,
            depSpecialServiceEndPortID: depValues.depSpecialServiceEndPortID
              ? depValues.depSpecialServiceEndPortID
              : null,
            extraPosition: depValues.extraPosition,
            depIsDeleted: depValues.depIsDeleted,
            depIsUpdated: depValues.depIsUpdated,
            isSpecialService: depValues.isSpecial || false,
            isSpecialPosition:
              depValues.isSpecialPosition ||
              depValues.isSpecial ||
              (depValues.depSpecialServiceStartPortID && !depValues.depStartPortID)
                ? true
                : false,
            durationFixture: null,

            durationFixtureEndDate: null,

            depVesselCodeId: depValues.vesselCodeID ? depValues.vesselCodeID : 0,
            vesselOwnType: this.isOwnDeploymentType
              ? this.veselType
                ? this.veselType
                : ''
              : this.depItems.vcType
              ? this.depItems.vcType
              : '',
            // setDepEndDateErrorCSS: false

            setDepEndDateErrorCSS: false,
            depToValidate: depValues.depToValidate,
            isMultipleProformas: false,
          });
          fromFixture = 'false';
          vesselDeploymentFrmGrpArr.push(item);
        });
        localStorage.setItem('isFromFixture', 'false');
        if (lastDeploymentLine) {
          this.addNewDeploymentLine(lastDeploymentLine);
        }
        this.populateServiceDetails(depValues, this.vesselDeploymentLines.controls);
        this.controlsValueChanges();
        return;
      }
    } else {
      let vesselDeploymentFrmGrpArr = this.vesselDeploymentLines;
      vesselDeploymentFrmGrpArr = this.resetVesselDeploymentFrmGrp(vesselDeploymentFrmGrpArr);
      vesselDeploymentFrmGrpArr.reset();
      this.addNewDeploymentLine();
    }
  }

  public resetVesselDeploymentFrmGrp(formArray: UntypedFormArray): UntypedFormArray {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    return formArray;
  }

  public resetDeploymentLine() {
    this.vesselDeploymentLines.controls.forEach((frmGrpControls, i) => {
      frmGrpControls.markAsPristine();
      frmGrpControls.markAsUntouched();
    });
  }

  public validateRegExPosPattern(frmGrpControl?) {
    return /^(?:(^([1-9][\d]*){1,2})+([xX])$)/g.test(frmGrpControl.controls.depPosition.value);
  }

  public validatePositionPattern(posvalue?) {
    return /^0*([1-9]){1,2}\d?[xX]?$/g.test(posvalue);
  }

  public validateDepPosition(frmGrpControl?) {
    this.depPositionExists = true;
    this.extraPosEntered = false;
    this.showError = false;
    const patternMatched = this.validateRegExPosPattern(frmGrpControl);
    if (patternMatched) {
      this.extraPosEntered = true;
      this.extraLoaderPosition = frmGrpControl.controls.depPosition.value;
      // For extra slice x from the input and check min max value validation
      this.extraLoaderPosition = this.extraLoaderPosition.toLowerCase();
      const xPos = this.extraLoaderPosition.indexOf('x');
      xPos === 1
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
        : xPos === 2
        ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
        : (this.extraLoaderPosition = null);

      if (+this.extraLoaderPosition >= 0 && +this.extraLoaderPosition <= 99) {
        this.depPositionValid = true;

        this.validatePosExists(this.extraLoaderPosition, true, frmGrpControl);
      } else {
        this.depPositionValid = false;
        this.showError = true;
      }
    } else if (patternMatched === false) {
      if (+frmGrpControl.controls.depPosition.value >= 0 && +frmGrpControl.controls.depPosition.value <= 99) {
        this.depPositionValid = true;
        this.validatePosExists(frmGrpControl.controls.depPosition.value, false, frmGrpControl);
      } else {
        this.depPositionValid = false;
        this.showError = true;
      }
    }
  }

  public validatePosExists(positionEntered, extra?, frmGrpControl?) {
    this.validExtraPos = false;
    const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
    const serviceId = frmGrpControl.controls.depStringID.value || 0;

    if (extra === false) {
      if (+positionEntered === 0 || +positionEntered === 31 || +positionEntered === 99) {
        this.depPositionExists = true;
      }
      this.depPositionExists = true;
    } else if (extra === true) {
      const specialPos = ['0', '31', '99'];
      if (specialPos.indexOf('' + positionEntered) === -1) {
        this.validExtraPos = true;
        frmGrpControl.controls.depPosition.setValue(positionEntered + 'X');
        frmGrpControl.controls.extraPosition.setValue(this.validExtraPos ? true : false);
      } else {
        this.depPositionExists = false;
        frmGrpControl.controls.depPosition.setValue(positionEntered);
        frmGrpControl.controls.extraPosition.setValue(false);
        this.showError = true;
      }
    } else {
      this.depPositionExists = false;
      this.showError = true;
    }
    // });
  }

  /** */
  public populateServiceDetails(e?, frmGrpControls?, index?) {
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    if (index !== undefined && (!frmGrpControls.pristine || frmGrpControls.dirty)) {
      frmGrpControls.controls.depStartPortID.setValue(null);
      frmGrpControls.controls.depEndPortID.setValue(null);
      frmGrpControls.controls.depSpecialServiceStartPortID.setValue(null);
      frmGrpControls.controls.depSpecialServiceEndPortID.setValue(null);
      frmGrpControls.controls.depPosition.setValue(null);
      frmGrpControls.controls.isSpecialPosition.setValue(false);
      frmGrpControls.controls.isSpecialService.setValue(false);
    }
    // if (this.fromCreateDummy && index === 0) {
    // this.emptyDummyName.emit();
    // }
    this.showSpecialServicesFlag = false;
    this.showServicePosition = false;
    this.showLocationHidePort = false;
    this.showvesselDepSpclServiceTypes = false;
    this.showvesselDepUnemployedTypes = false;
    this.showvesselDepLayupTypes = false;
    this.dontShowServiceType = false;
    this.hideTrade = false;
    this.hideRoundTrip = false;
    this.showScheduleDateError = false;

    if (e.isSpecial) {
      frmGrpControls.controls.isSpecialService.setValue(true);
      this.portsService
        .getPorts()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (ports: any) => {
            this.allPorts = ports;
            this.allPorts = ports.map((o) => {
              return {
                schedulePortId: o.prtPKID,
                schedulePortName: o.prtPortName,
                scheduleDate: null,
                scheduleTime: null,
                scheduleType: null,
                position: null,
                portId: o.prtPKID,
              };
            });
          },
          (errorResponse) => {
            // Error handling for end port and end date when schedule doesn;t exist.
            this.showScheduleDateError = true;
            const msg = errorResponse.error.error;
            this.scheduleDateErrorMsg = msg;

            frmGrpControls.controls['depEndPortID'].setErrors({ incorrect: true });
            frmGrpControls.controls['depEndDate'].setErrors({ incorrect: true });
          }
        );
      frmGrpControls.controls['depEndPortID'].setErrors(null);
      frmGrpControls.controls['depEndDate'].setErrors(null);
    } else {
      const dpValues = e;
      this.servicePositions = e.positions;

      if (Array.isArray(dpValues)) {
        const obj = { depStringID: null, depBeginDate: null };
        frmGrpControls.map((frmGrpControl, i) => {
          obj.depStringID = frmGrpControl.controls.depStringID.value;
          this.loadPortsByService(obj, frmGrpControl, i);
          this.loading = false;

          if (i === 0) {
            this.populateDummyData(dpValues);
          }
        });
      } else {
        if (dpValues.serviceCode) {
          dpValues.depStringID = dpValues.strPKID;
          this.loadDataOnChangeOfServiceControl(dpValues, frmGrpControls, index);
        }
      }
    }
    if (this.fromCreateDummy && index === 0) {
      this.getServiceChangeValforDummy.emit(e);
    }
    this.displayErrorLines(frmGrpControls);
  }

  public populateDummyData(dpValues) {
    setTimeout(() => {
      const serviceObj = this.vesselDeploymentServices.filter((serv) => serv.strPKID === +dpValues.depStringID);
      dpValues.lstProformas = serviceObj.lstProfomas;
      this.getServiceChangeValforDummy.emit(dpValues);
    }, 1000);

    // this.getPositionChangeValforDummy.emit(dpValues.);
  }

  public emitDummyObjectOnlyForFirstDeploymentLine() {
    const stringObj = this.vesselDeploymentServices.filter(
      (s) => s.strPKID === this.vesselDeploymentLines.value[0].depStringID
    );
    const position = this.vesselDeploymentLines.value[0].depPosition;
    const startPort = this.depStartPortListLineWise[0]
      ? this.depStartPortListLineWise[0].ports.filter(
          (p) => p.schedulePortId === this.vesselDeploymentLines.value[0].depStartPortID
        )
      : null;
    if (stringObj.length > 0) {
      const dataTobeEmitted = {
        stringObj: stringObj[0].serviceCode,
        position: position,
        startPort: startPort && startPort.length ? startPort[0].schedulePortName : null,
      };
    }
    // return (this.vesselDeploymentLines.value)[0].isSpecialPosition ? null : dataTobeEmitted;
    return stringObj;
  }

  public emitUpdatedNameForDummy() {
    const stringObj = this.vesselDeploymentServices.filter(
      (s) => s.strPKID === this.vesselDeploymentLines.value[0].depStringID
    );
    const position = this.vesselDeploymentLines.value[0].depPosition;
    const startPort = this.depStartPortListLineWise[0]
      ? this.depStartPortListLineWise[0].ports.filter(
          (p) => p.schedulePortId === this.vesselDeploymentLines.value[0].depStartPortID
        )
      : null;
    if (stringObj.length > 0) {
      const dataTobeEmitted = {
        stringObj: stringObj[0].serviceCode,
        position: position,
        startPort: startPort && startPort.length ? startPort[0].schedulePortName : null,
      };
      return this.vesselDeploymentLines.value[0].isSpecialPosition ? null : dataTobeEmitted;
    }
    return stringObj;
  }

  public loadPortsByService(e?, frmGrpControl?, index?) {
    this.showServicePosition = true;

    if (!frmGrpControl.pristine || frmGrpControl.dirty) {
      // this.vesselDeploymentStartPort = [];
      // this.vesselDeploymentEndPort = []; //strPKID

      frmGrpControl.controls.depStartPortID.setValue();
      frmGrpControl.controls.depEndPortID.setValue();
    }

    if (e.depStringID) {
      const serviceId = e.depStringID;
      const beginDate = frmGrpControl.controls.depBeginDate.value
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;
      const endDate = frmGrpControl.controls.depEndDate.value
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

      const selectedService = frmGrpControl.controls.depStringID.value;
      const selectedDateTime = beginDate != null ? beginDate + 'T' + '00:00' : null;
      const selectedEndDateTime = endDate != null ? endDate + 'T' + '00:00' : null;
      const isSpecialPosition = frmGrpControl.controls.isSpecialPosition.value;
      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId;
      if (serviceId && isSpecialPosition) {
        this.loadPorts();
      } else if (serviceId && selectedDateTime) {
        this.vesselsService
          .getPortsByService(
            serviceId,
            e.depBeginDate ? e.depBeginDate : selectedDateTime,
            e.depEndDate ? e.depEndDate : selectedEndDateTime
          )
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(
            async (response: any) => {
              this.allPortsByService = await response; //different API
              // this.portsDeploymentLineWise[index] = response.portDateTime;
              const patternMatched = this.validateRegExPosPattern(frmGrpControl);
              let positionSelected;
              if (patternMatched) {
                const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
                const xPos = extraPosValue.indexOf('x');
                xPos === 1
                  ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
                  : xPos === 2
                  ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
                  : (this.extraLoaderPosition = null);
                positionSelected = this.extraLoaderPosition;
              } else {
                positionSelected = +frmGrpControl.controls.depPosition.value;
              }
              const startportSet = new Set();
              const endportSet = new Set();
              if (positionSelected && positionSelected > 0) {
                this.isVesslDeplyPositionSelected = true;
                this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.position === positionSelected);
                this.depStartPortListLineWise[index] = {};
                if (frmGrpControl.controls.depStartPortID.value != null) {
                  // console.log(JSON.stringify(this.vesselDeploymentStartPort));
                  // console.log(JSON.stringify(frmGrpControl.controls.depStartPortID.value));
                  const objstartPortProformaPKId = this.vesselDeploymentStartPort.find(
                    (i) => i.schedulePortId === +frmGrpControl.controls.depStartPortID.value
                  );
                  // console.log(JSON.stringify(objstartPortProformaPKId));
                  // Remove duplicate portid from array by comparing sub properties
                  //condition added to check the null record
                  if (objstartPortProformaPKId) {
                    this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                      (o) =>
                        !startportSet.has(o.schedulePortId) &&
                        startportSet.add(o.schedulePortId) &&
                        o.scheduleProformaPKId === objstartPortProformaPKId.scheduleProformaPKId
                    );
                  }
                  // console.log(JSON.stringify(this.depStartPortListLineWise[index].ports));
                } else {
                  // Remove duplicate portid from array by comparing sub properties
                  this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                    (o) => !startportSet.has(o.schedulePortId) && startportSet.add(o.schedulePortId)
                  );
                }
                this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.position === positionSelected);
                this.depEndPortListLineWise[index] = {};
                if (frmGrpControl.controls.depEndPortID.value != null) {
                  const objendPortProformaPKId = this.vesselDeploymentStartPort.find(
                    (i) => i.schedulePortId === +frmGrpControl.controls.depEndPortID.value
                  );
                  // console.log(JSON.stringify(this.vesselDeploymentStartPort));
                  // console.log(JSON.stringify(frmGrpControl.controls.depEndPortID.value));
                  // console.log(JSON.stringify(objendPortProformaPKId));
                  if (objendPortProformaPKId) {
                    // Remove duplicate portid from array by comparing sub properties
                    this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                      (o1) =>
                        !endportSet.has(o1.schedulePortId) &&
                        endportSet.add(o1.schedulePortId) &&
                        o1.scheduleProformaPKId === objendPortProformaPKId.scheduleProformaPKId
                    );
                  }
                  // console.log(JSON.stringify(this.depEndPortListLineWise[index].ports));
                } else {
                  // Remove duplicate portid from array by comparing sub properties
                  this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                    (o1) => !endportSet.has(o1.schedulePortId) && endportSet.add(o1.schedulePortId)
                  );
                }
              } else {
                this.isVesslDeplyPositionSelected = false;
                this.vesselDeploymentStartPort = response.portDateTime;
                this.depStartPortListLineWise[index] = {};
                this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                  (o) => !startportSet.has(o.schedulePortId) && startportSet.add(o.schedulePortId)
                );
                this.vesselDeploymentEndPort = response.portDateTime;
                this.depEndPortListLineWise[index] = {};
                this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                  (o1) => !endportSet.has(o1.schedulePortId) && endportSet.add(o1.schedulePortId)
                );
              }
              // this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(
              //   this.vesselDeploymentStartPort[0].schedulePortId
              // );
              let startPortToPopulate: any;
              const startDateBeforePort = new Date(frmGrpControl.controls.depBeginDate.value);
              startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
              if (startPortToPopulate.length && (!frmGrpControl.pristine || frmGrpControl.dirty)) {
                frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
                this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;
                frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                if (this.fromCreateDummy && index === 0) {
                  this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
                }
              }
              let endPortToPopulate: any;
              const endDateBeforePort = new Date(
                new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
              );
              if (endDateBeforePort) {
                endPortToPopulate = response.portDateTime.filter(
                  (k) =>
                    positionSelected === k.position &&
                    endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                );
                if (endPortToPopulate.length && (!frmGrpControl.pristine || frmGrpControl.dirty)) {
                  frmGrpControl.controls.depEndPortID.setValue(
                    endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
                  );
                  this.schedulePrfEndPortPKId = endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;
                  frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                  frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                } else if (!endPortToPopulate.length && positionSelected && positionSelected > 0) {
                  // Error handling for end port and end date when schedule doesn;t exist.
                  frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
                  frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
                }
              }
              // this.checkAdjacentLinesForSpcl(frmGrpControl, index);
              this.validateEndDateErrHandlng(e, frmGrpControl, index);
              this.validateStartDateErrHandlng(e, frmGrpControl, index);
              this.displayErrorLines(this.vesselDeploymentFrmGrp);
            },
            (errorResponse) => {
              // Error handling for end port and end date when schedule doesn;t exist.
              this.showScheduleDateError = true;
              const msg = errorResponse.error ? errorResponse.error.error : errorResponse.error;
              this.scheduleDateErrorMsg = msg;
              // this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({'incorrect': true});
              this.depEndPortListLineWise[index] = {};
              this.depEndPortListLineWise[index].ports = [];
              this.depStartPortListLineWise[index] = {};
              this.depStartPortListLineWise[index].ports = [];
              frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
              frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
              this.displayErrorLines(this.vesselDeploymentFrmGrp);
              this.spinner.hide();
            }
          );
        frmGrpControl.controls['depEndPortID'].setErrors(null);
        frmGrpControl.controls['depEndDate'].setErrors(null);
        this.validateEndDateErrHandlng(e, frmGrpControl, index);
        this.validateStartDateErrHandlng(e, frmGrpControl, index);
      }
      //Commented below line for performance fix
      // this.loadVesselsDeploymentServicseAndResons(e.depStringID, e, frmGrpControl, index);
    }
  }

  public loadVesselsDeploymentServicseAndResons(serviceId?, e?, frmGrpControl?, index?) {
    const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId;
    if (serviceId) {
      this.vesselsService
        .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((vesselDeploymentDropdowns: Response) => {
          this.vesselDeploymentDropdowns = vesselDeploymentDropdowns;
          const vesselDeploymentServices = this.vesselDeploymentDropdowns.services.map((o) => {
            return {
              strPKID: o.strPKID,
              strCode: o.strCode,
              strName: o.strName,
              strRoundTrip: o.strRoundTrip,
              strTrade: o.strTrade,
              isSpecial: o.isSpecial,
              strNameCode: o.strCode + '  ' + o.strName,
              positions: o.positions,
              positionMaxDateByProforma: o.positionMaxDateByProforma,
            };
          });
          this.servicePositionsDetailsLst = vesselDeploymentServices;
          this.vesselDeploymentSpclServices = vesselDeploymentServices.filter((o) => o.isSpecial === true);
          this.vesselDepSpclServiceTypes = this.vesselDeploymentDropdowns.specialSerivceTypes;
          this.vesselDepUnemployedTypes = this.vesselDeploymentDropdowns.unemployedeTypes;
          this.vesselDepLayupTypes = this.vesselDeploymentDropdowns.layupTypes;
          this.vesselDeploymentPorts = this.vesselDeploymentDropdowns.ports;
          this.vesselDeploymentReasons = this.vesselDeploymentDropdowns.deploymentReasons;
          this.previousDeploymentId = this.vesselDeploymentDropdowns.previousDeploymentId;
          this.fixturePortsForRange = this.vesselDeploymentDropdowns.fixturePorts;
        });
    }
  }

  public loadDataOnChangeOfServiceControl(serviceObject?, frmGrpControl?, index?) {
    const serviceId = serviceObject.depStringID ? serviceObject.depStringID : serviceObject;
    //Commented below for performance fix
    // this.loadPortsByService(serviceObject, frmGrpControl, index);
    return;
  }

  public setPortsData(ports) {
    this.vesselDeploymentStartPort = ports;
    this.vesselDeploymentEndPort = ports;
  }

  public loadPorts(frmGrpControl?, index?) {
    this.portsService
      .getPorts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (ports: any) => {
          // this.vesselDeploymentStartPort = await ports;
          // this.vesselDeploymentEndPort = ports;
          this.allPorts = await ports;
          this.allPorts = ports.map((o) => {
            return {
              schedulePortId: o.prtPKID,
              schedulePortName: o.prtPortName,
              scheduleDate: null,
              scheduleTime: null,
              scheduleType: null,
              position: null,
              portId: o.prtPKID,
            };
          });

          this.populateDatePortForSpclPosition(frmGrpControl, index);
          this.checkMinStartDate();
          this.checkDateEquality();
          this.vesselDeploymentFrmGrp.updateValueAndValidity();
          this.validateEndDateErrHandlng('', frmGrpControl, index);
          this.validateStartDateErrHandlng('', frmGrpControl, index);
        },
        (errorResponse) => {
          // Error handling for end port and end date when schedule doesn;t exist.
          this.showScheduleDateError = true;
          const msg = errorResponse.error.error;
          this.scheduleDateErrorMsg = msg;

          frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
          frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
          this.spinner.hide();
        }
      );
    if (frmGrpControl) {
      frmGrpControl.controls['depEndPortID'].setErrors(null);
      frmGrpControl.controls['depEndDate'].setErrors(null);
    }
  }

  public showSpecialServices() {
    this.showSpecialServicesFlag = true;
  }

  public populateServiceControl(e) {
    this.vesselDeploymentFrmGrp.patchValue({
      depStringID: e.strPKID,
    });
    // this.showSpecialServicesFlag = false;
    this.populateServiceDetails(e);
  }

  public displayErrorLines(deploymentFormGroup) {
    let deploymentLines =
      deploymentFormGroup.controls && deploymentFormGroup.controls.vesselDeploymentLines
        ? deploymentFormGroup.controls.vesselDeploymentLines.controls
        : [];
    this.showDeploymentFormError = deploymentFormGroup.status === 'VALID' ? false : true;
    this.deploymentErrorLines = [];
    if (deploymentLines) {
      for (const line in deploymentLines) {
        if (deploymentLines[line].status === 'INVALID' && !this.deploymentErrorLines.includes(parseInt(line) + 1)) {
          this.alertService.clear();
          if (parseInt(line) + 1 === deploymentLines.length) {
            // clear validators for last line
            deploymentLines[line].controls.depPosition.setErrors(null);
            this.vesselDeploymentFrmGrp.updateValueAndValidity();
          } else {
            this.deploymentErrorLines.push(parseInt(line) + 1);
          }
        }
      }
    }
  }

  public saveAllDeploymentLines(type) {
    this.checkDateEquality();
    this.checkPrevEndDateMin();
    this.CheckDuplicateDepLineWithPrevDepLines();
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    //return false;
    if (
      (!this.vesselDeploymentFrmGrp.valid && !this.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.valid) ||
      this.preventSave ||
      this.errorInPrevDepLines
    ) {
      this.showError = true;
      this.spinner.hide();
      return;
    }
    const deletedDeploymentLines = this.deletedDeploymentLines.length > 0 ? this.deletedDeploymentLines : [];
    const payloadData = this.vesselDeploymentFrmGrp.get('vesselDeploymentLines').value;
    const payloadObject = {
      // vesselID: this.vslId != null ? this.vslId : 0,
      vesselID: this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0,
      lstVesselDeplymentDto: [
        ...payloadData.filter(
          (depLines) => depLines.depEndDate != null && depLines.depPosition != null && depLines.depStringID != null
        ),
        ...deletedDeploymentLines,
      ],
    };
    payloadObject.lstVesselDeplymentDto.forEach((element) => {
      if (element.extraPosition) {
        const extraPosValue = element.depPosition.toLowerCase();
        const xPos = extraPosValue.indexOf('x');
        element.depPosition =
          xPos === 1
            ? element.depPosition.slice(0, 1)
            : xPos === 2
            ? (element.depPosition = element.depPosition.slice(0, 2))
            : element.depPosition;
      }
    });
    let deleteCount = 0;
    let updateCount = 0;
    let existingDepCount = 0;
    let newDepCount = 0;
    payloadObject.lstVesselDeplymentDto.forEach((element) => {
      if (element.depPKID > 0) {
        existingDepCount++;
        if (element.depIsDeleted) {
          deleteCount++;
        }
        if (element.depIsUpdated) {
          updateCount++;
        }
      }
      element.depVesselID = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      if (element.depPKID === 0) {
        newDepCount++;
      }
    });
    let checkOverlap = true;
    if (newDepCount === 0 && existingDepCount === deleteCount) {
      checkOverlap = false;
    }
    if (updateCount === 0 && deleteCount > 0) {
      this.vesselDeploymentFrmGrp.updateValueAndValidity();

      if (this.vesselDeploymentFrmGrp.invalid) {
        checkOverlap = true;
      } else {
        checkOverlap = false;
      }
    }
    // this.deletedDeploymentLines = [];
    if (
      !this.showErrorOverlapping &&
      !this.showWarningOverlapping &&
      !this.showPortRangeError &&
      checkOverlap &&
      !this.fromCreateDummy &&
      this.fromCreateDummy === undefined
    ) {
      this.incorrectDeploymentDetailsNew = '';
      this.vesselsService.saveVesselDeploymentNewVessel(payloadObject).subscribe(
        (res) => {
          if (res && res[0] && res[0].isOverlapDeployment) {
            this.overlappingExist = true;
            this.showErrorOverlapping = false;
            this.showWarningOverlapping = true;
            this.showIncorrectDetailError = true;
            const extraPos = res[0].extraPosition ? true : false;

            const warnMessage = {
              vessel_status_id: null,
              vslPKID: res[0].deployedVessels[0].deployedVesselID,
              vessel_name: res[0].deployedVessels[0].deployedVesselName,
              position: res[0].depPosition,
              overlapping_start_date: new DatePipe('en-US').transform(
                res[0].deployedVessels[0].deployedVslBeginDate,
                'dd-MMM-yyyy'
              ),
              overlapping_end_date: new DatePipe('en-US').transform(
                res[0].deployedVessels[0].deployedVslEndDate,
                'dd-MMM-yyyy'
              ),
              extra_position: extraPos,
            };
            const warnMessageArray = [];
            warnMessageArray.push(warnMessage);

            this.overlappingMsg = warnMessageArray;
          } else {
            this.sendVesselDeoplymentPostObject(payloadObject);
          }
        },
        // Warning overlapping implementation
        (errorResponse) => {
          this.displayDetails(errorResponse);
          this.spinner.hide();
        }
      );
    } else {
      this.sendVesselDeoplymentPostObject(payloadObject);
    }
  }

  public displayDetails(errorResponse) {
    if (errorResponse.error.code === 'VesselDeploymentOverlapWarning') {
      this.overlappingExist = true;
      this.showIncorrectDetailError = true;
      if (errorResponse.error.data.inRange_warnings) {
        this.showPortRangeError = true;
        const msgRange = errorResponse.error.data.inRange_warnings;
        const msg = 'Redelivery NOT in range - ' + msgRange.CityName + ' does not match redelivery range under fixture';
        this.fixtureIdForRange = msgRange.fixPKID;
        this.fixtureContractNo = msgRange.fixContractNo;
        this.portRangeErrorMsg = msg;
      }
      if (errorResponse.error.data.overlap_warnings && errorResponse.error.data.overlap_warnings.length) {
        this.showWarningOverlapping = true;
        const msg = errorResponse.error.data.overlap_warnings
          ? errorResponse.error.data.overlap_warnings
          : errorResponse.error;
        this.overlappingMsg = msg;
      }
      if (errorResponse.error.data.warnings) {
        this.showWarningOverlapping = true;
        this.warnings = errorResponse.error.data.warnings;
      }
      if (errorResponse.error.data.trade_restrictions) {
        this.showWarningOverlapping = true;
        this.trade_restrictions = errorResponse.error.data.trade_restrictions;
      }
      if (errorResponse.error.data.error_data && errorResponse.error.data.error_data.length) {
        this.showErrorOverlapping = true;
        this.preventSave = true;
        const extraPos = errorResponse.error.data.error_data[0].extra_position ? true : false;

        const warnMessageArray = [];
        for (let i = 0; i < errorResponse.error.data.error_data.length; i++) {
          const warnMessage = {
            vessel_status_id_p: errorResponse.error.data.error_data[i].p_vessel_status_id,
            vslPKID_p: errorResponse.error.data.error_data[i].p_vslPKID,
            vessel_status_id_s: errorResponse.error.data.error_data[i].s_vessel_status_id,
            vslPKID_s: errorResponse.error.data.error_data[i].s_vslPKID,
            vessel_name_primary: errorResponse.error.data.error_data[i].p_vessel_name,
            vessel_name_secondary: errorResponse.error.data.error_data[i].s_vessel_name,
            position: errorResponse.error.data.error_data[i].position,
            route_code: errorResponse.error.data.error_data[i].route_code,
            service_code: errorResponse.error.data.error_data[i].service_code,
            service_name: errorResponse.error.data.error_data[i].service_name,
            // overlapping_start_date:  new DatePipe('en-US').transform(errorResponse.error.data.error_data[i].overlapping_start_date, 'dd-MMM-yyyy'),
            // overlapping_end_date:  new DatePipe('en-US').transform(errorResponse.error.data.error_data[i].overlapping_end_date, 'dd-MMM-yyyy'),
            p_overlapping_start_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].p_overlapping_start_date,
              'dd-MMM-yyyy'
            ),
            p_overlapping_end_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].p_overlapping_end_date,
              'dd-MMM-yyyy'
            ),
            s_overlapping_start_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].s_overlapping_start_date,
              'dd-MMM-yyyy'
            ),
            s_overlapping_end_date: new DatePipe('en-US').transform(
              errorResponse.error.data.error_data[i].s_overlapping_end_date,
              'dd-MMM-yyyy'
            ),
            extra_position: extraPos,
          };
          warnMessageArray.push(warnMessage);
        }
        if (errorResponse.error.data.error_data.length >= 1) {
          this.incorrectDeploymentDetailsNew =
            'Deployment CANNOT be saved. There are multiple vessels overlappingly deployed on same dates.';
        }
        this.overlappingErrorMsg = warnMessageArray;
        if (!this.isOwnDeploymentType) {
          return;
        }
      }
    } else {
      this.showErrorOverlapping = true;
      this.showWarningOverlapping = false;
      this.showIncorrectDetailError = true;
      const msg = errorResponse.error.error;
      this.overlappingErrorMsg = msg;
    }
    this.changeButtonState();
  }
  public redirectToVslOverview(vessel_status_id, vslPKID) {
    if (vessel_status_id === null || vessel_status_id === '') {
      // vessel

      window.open('/vessels/' + vslPKID, '_blank');
    } else if (vessel_status_id === 3 || vessel_status_id === 2) {
      // Request OR Dummy

      window.open('/vessels/dummy/overview/' + vslPKID, '_blank');
    }
  }

  public navigateToFixOverview(fixtureIdForRange) {
    // fixture

    window.open('/fixtures/' + fixtureIdForRange, '_blank');
  }

  public sendVesselDeoplymentPostObject(finalPayloadObject?) {
    finalPayloadObject.lstVesselDeplymentDto.forEach((element) => {
      element.isMultipleProformas = this.isMultipleProformasflag;

      element.depBeginDate = setZeroHours(convertToLocalIsoString(new Date(element.depBeginDate)));
      element.depEndDate = setZeroHours(convertToLocalIsoString(new Date(element.depEndDate)));
    });
    if (!this.fromCreateDummy) {
      this.clearAlertService();
      this.vesselsService
        .saveVesselDeploymentNew(finalPayloadObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            this.deletedDeploymentLines = [];
            this.showError = false;
            this.showIncorrectDetailError = false;
            this.showBeginDateError = false;
            this.showBeginTimeError = false;
            this.showEndDateError = false;
            this.showEndTimeError = false;
            this.showRangeInvalid = false;
            this.showDatesInvalid = false;
            this.showLocationHidePort = false;
            //Commented below reload for performance fix
            // this.router
            //   .navigateByUrl('/vessels', { skipLocationChange: true })
            //   .then(() => this.router.navigate(['/vessels/' + this.vslId]));
            if (!this.vslId) {
              this.alertService.alertVesselId.next(this.veselID);
              this.showAlertForVesselId = this.veselID;
              this.alertService.success('Created successfully', true);
            } else {
              this.alertService.alertVesselId.next(this.veselID);
              this.showAlertForVesselId = this.veselID;
              this.alertService.success('Updated successfully', true);
            }

            // this.ngOnInit();
          },

          (errorResponse) => {
            // this.dialogRef.close();
            if (errorResponse && errorResponse instanceof HttpErrorResponse) {
              this.errorKeys = Object.keys(this.vesselDeploymentLines).filter((key) =>
                Object.keys(errorResponse.error).includes(key)
              );
              this.errorValues = this.errorKeys.map((errorKey) => errorResponse.error[errorKey]);
              const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
              const data = errorResponse.error && errorResponse.error.data ? errorResponse.error.data : null;
              if (error) {
                this.showIncorrectDetailError = true;
                this.incorrectDeploymentDetails = error;
                this.overlappingMsg = typeof error !== 'string' ? error : [];
              }
              this.spinner.hide();
            }
          }
        );
    } else {
      this.finalPayloadFromRedesign = finalPayloadObject;
      this.saveAfterDeleteStatus = this.vesselDeploymentFrmGrp.invalid;
      return;
    }
  }

  /** */
  public cancelEdit(controls?) {
    this.showError = false;
    this.preventSave = false;
    this.warnings = [];
    this.deploymentErrorLines = [];
    this.endDateNotPresent = false;
    this.showIncorrectDetailError = false;
    this.showBeginDateError = false;
    this.showBeginTimeError = false;
    this.showEndDateError = false;
    this.showEndTimeError = false;
    this.showRangeInvalid = false;
    this.showDatesInvalid = false;
    this.showLocationHidePort = false;
    this.showScheduleDateError = false;
    this.showWarningOverlapping = false;
    this.showErrorOverlapping = false;
    this.showPortRangeError = false;
    this.deletedDeploymentLines = [];
    this.isCancelShowPreviousClick = true;
    this.saveAfterDelete = true;
    this.overlappingExist = false;
    if (this.editDeployments) {
      if (!this.fromCreateDummy) {
        this.editDeployments = false;
      }

      this.isPreviousDeployments = false;
      this.showHidePreviousDeploymentLink = 'Show previous deployments';
      this.loadDefaultEditMode();
    }
    if (this.vslId == null && this.fromVesselListVslId != null && this.fromVesselDetailPage) {
      this.extendDummyDetails(this.fromVesselListVslId);
    }
  }
  public proformaChanged(e) {
    if (e && e.value) {
      this.errorTextExist = false;
      this.checkedProformaDtl = e.value;
    }
  }
  public selectedProformaDtl() {
    this.matchingPrfrmSelectedDtl = [];
    this.errorTextExist = false;
    if (this.checkedProformaDtl.length !== 0) {
      this.populateReasonDetails(this.eventDtl, this.frmGrpControlDtl, this.indexDtl, this.checkedProformaDtl);
      this.dialogRef.close();
    } else {
      this.errorTextExist = true;
      this.errorText = 'Please select one record.';
    }
  }
  public validateEndDateErrHandlng(e?, frmGrpControls?, index?) {
    if (
      frmGrpControls &&
      frmGrpControls.controls.depStringID.value &&
      frmGrpControls.controls.depPosition.value != null &&
      !this.isCancelShowPreviousClick
    ) {
      let sortedPrfLst;
      let seldateInvalid = false;

      const selectedProformaID = this.checkedProformaDtl ? this.checkedProformaDtl.proformaID : null;

      if (this.matchedProformaLst.length > 1) {
        this.posPrfLst.map((k) => {
          if (k.proformaID === selectedProformaID) {
            sortedPrfLst = new Date(k.maxDate);
            seldateInvalid = false;
            this.isMultipleProformasflag = false;
          }
        });
      } else if (this.matchedProformaLst.length === 1) {
        this.matchedProformaLst.forEach((o) => {
          this.posPrfLst.map((k) => {
            if (k.proformaID === o.proformaID) {
              sortedPrfLst = new Date(k.maxDate);
              seldateInvalid = false;
              this.isMultipleProformasflag = false;
            }
          });
        });
      } else {
        if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
          sortedPrfLst = new Date(Math.max(...this.posPrfStartEndDtLst.map((m) => new Date(m.maxDate))));
          seldateInvalid = true;
          this.isMultipleProformasflag = true;
        } else {
          this.posPrfLst.map((k) => {
            sortedPrfLst = new Date(k.maxDate);
          });
          seldateInvalid = false;
        }
      }

      const selEndDate = new Date(
        new DatePipe('en-US').transform(frmGrpControls.controls.depEndDate.value, 'dd-MMM-yyyy')
      );

      if (!frmGrpControls.controls.isSpecialService.value) {
        const prfEndDate = new Date(new DatePipe('en-US').transform(sortedPrfLst, 'dd-MMM-yyyy'));
        if (this.posPrfLst.length > 0 || seldateInvalid) {
          if (selEndDate.getTime() > prfEndDate.getTime()) {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setValue(null);
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setErrors({ incorrect: true });
            this.spinner.hide();
            return;
          } else {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
            this.vesselDeploymentLines.controls[index]['controls']['depEndPortID'].setErrors(null);
          }
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
  }

  public validateStartDateErrHandlng(e?, frmGrpControls?, index?) {
    // Error Handling - If start date not match in any proforma-Validation
    if (
      frmGrpControls &&
      frmGrpControls.controls.depStringID.value &&
      frmGrpControls.controls.depPosition.value != null
    ) {
      let sortedPrfLst;
      let seldateInvalid = false;

      const selectedProformaID = this.checkedProformaDtl ? this.checkedProformaDtl.proformaID : null;

      if (this.matchedProformaLst.length > 1) {
        this.posPrfLst.map((k) => {
          if (k.proformaID === selectedProformaID) {
            sortedPrfLst = new Date(k.minDate);
            seldateInvalid = false;
            this.isMultipleProformasflag = false;
          }
        });
      } else if (this.matchedProformaLst.length === 1) {
        this.matchedProformaLst.forEach((o) => {
          this.posPrfLst.map((k) => {
            if (k.proformaID === o.proformaID) {
              sortedPrfLst = new Date(k.minDate);
              seldateInvalid = false;
              this.isMultipleProformasflag = false;
            }
          });
        });
      } else {
        if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
          sortedPrfLst = new Date(Math.min(...this.posPrfStartEndDtLst.map((m) => new Date(m.minDate))));
          seldateInvalid = true;
          this.isMultipleProformasflag = true;
        } else {
          seldateInvalid = false;
        }
      }

      const selStartDate = new Date(
        new DatePipe('en-US').transform(frmGrpControls.controls.depBeginDate.value, 'dd-MMM-yyyy')
      );

      if (!frmGrpControls.controls.isSpecialService.value) {
        if (this.posPrfLst.length > 0 || seldateInvalid) {
          const prfStartDate = new Date(new DatePipe('en-US').transform(sortedPrfLst, 'dd-MMM-yyyy'));

          if (selStartDate.getTime() < prfStartDate.getTime()) {
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
            this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setValue(null);
            this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setErrors({ incorrect: true });
            this.spinner.hide();
            return;
          } else {
            if (this.vesselDeploymentLines.controls[index]) {
              this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
              this.vesselDeploymentLines.controls[index]['controls']['depStartPortID'].setErrors(null);
            }
          }
        }
      }
    }
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
  }
  public verifyMultiplePrfExists(e?, frmGrpControl?, index?) {
    this.portRangeErrorMsg = false;
    this.showWarningOverlapping = false;
    this.showErrorOverlapping = false;
    this.clearAlertService();
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    frmGrpControl.controls.isSpecialPosition.setValue(false);

    frmGrpControl.controls.depStartPortID.setValue(null);
    frmGrpControl.controls.depEndPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null);
    frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
    frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
    if (
      frmGrpControl.controls.depStringID.value === null ||
      frmGrpControl.controls.depBeginDate.value === null ||
      frmGrpControl.controls.depEndDate.value === null ||
      frmGrpControl.controls.depPosition.value === null ||
      frmGrpControl.controls.depPosition.value === ''
    ) {
      return;
    }
    // position validation
    const pos = frmGrpControl.controls.depPosition.value;
    const pospatternMatched = this.validatePositionPattern(pos);
    if (!pospatternMatched) {
      this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });
      return;
    }
    /** spinner starts */
    this.spinner.show();
    //Commented for performance fix
    // this.controlsValueChanges();
    this.isCancelShowPreviousClick = false;
    this.showIncorrectDetailError = false;
    //Commented setTimeout for performance fix
    // setTimeout(() => {
    this.depPositionValid = true;
    this.ifPosnEnteredIsSpcl = false;
    this.errorTextExist = false;
    this.matchedProformaLst = [];
    this.selectedServiceDtl = [];
    this.checkedProformaDtl = [];
    this.posPrfLst = [];
    this.posPrfStartEndDtLst = [];
    this.extraLoaderPosition = null;
    frmGrpControl.controls.extraPosition.setValue(false);
    if (!frmGrpControl.controls.isSpecialService.value) {
      this.showScheduleDateError = false;
      this.showEditPreviousWarning = false;
      // if (this.fromCreateDummy && index === 0) {
      //   this.getPositionChangeValforDummy.emit(e);
      //   this.getStartPortChangeValforDummy.emit(null);
      // }
      frmGrpControl.controls.depStartPortID.setValue(null);
      frmGrpControl.controls.depEndPortID.setValue(null);
      frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null);
      frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null);
      this.validateDepPosition(frmGrpControl);
      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      const serviceId = frmGrpControl.controls.depStringID.value || 0;
      const positionEntered = this.validateRegExPosPattern(frmGrpControl)
        ? this.extraLoaderPosition
        : frmGrpControl.controls.depPosition.value;
      this.vesselsService
        .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
        .pipe()
        .subscribe(async (response: any) => {
          this.servicePositionsDetailsLst = response.services;
          const lstOfSpecialPositions = await response.specialPositionsList;
          this.lstofServicesPositions =
            response.services[0].positions !== null ? await response.services[0].positions : null;
          if (lstOfSpecialPositions.length) {
            this.ifPosnEnteredIsSpcl =
              lstOfSpecialPositions.findIndex((element) => element.spPosition === +positionEntered) > -1 ? true : false;
            if (!this.ifPosnEnteredIsSpcl) {
              frmGrpControl.controls.isSpecialPosition.setValue(false);
            } else {
              frmGrpControl.controls.isSpecialPosition.setValue(true);
            }
          }
          let ifPosEnteredIsValid;
          if (
            this.lstofServicesPositions &&
            this.lstofServicesPositions.length > 0 &&
            !frmGrpControl.controls.isSpecialPosition.value
          ) {
            if (this.lstofServicesPositions.length) {
              ifPosEnteredIsValid =
                this.lstofServicesPositions.findIndex((element) => element.position === +positionEntered) > -1
                  ? true
                  : false;
              if (!ifPosEnteredIsValid) {
                frmGrpControl.controls['depPosition'].setErrors({ incorrect: true });
                this.spinner.hide();
                return;
              } else {
                frmGrpControl.controls['depPosition'].setErrors(null);
              }
            }
          }
          const patternMatched = this.validateRegExPosPattern(frmGrpControl);
          let positionSelected;
          if (patternMatched) {
            const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
            const xPos = extraPosValue.indexOf('x');
            xPos === 1
              ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
              : xPos === 2
              ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
            positionSelected = this.extraLoaderPosition;
          } else {
            positionSelected = +frmGrpControl.controls.depPosition.value;
          }
          if (
            this.servicePositionsDetailsLst[0] &&
            this.servicePositionsDetailsLst[0]?.positionMaxDateByProforma != null
          ) {
            this.servicePositionsDetailsLst[0].positionMaxDateByProforma.map((item) => {
              const minDate = new Date(new DatePipe('en-US').transform(item.minDate, 'dd-MMM-yyyy'));
              const maxDate = new Date(new DatePipe('en-US').transform(item.maxDate, 'dd-MMM-yyyy'));
              const depBegin = new Date(
                new DatePipe('en-US').transform(frmGrpControl.controls.depBeginDate.value, 'dd-MMM-yyyy')
              );
              const depEnd = new Date(
                new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
              );
              if (
                +item.position === +positionSelected &&
                minDate.getTime() <= depBegin.getTime() &&
                maxDate.getTime() >= depEnd.getTime()
              ) {
                this.posPrfLst.push(item);
              }
              if (+item.position === +positionSelected) {
                this.posPrfStartEndDtLst.push(item);
              }
            });
            this.vesselDeploymentServices.map((item) => {
              if (item.strPKID === frmGrpControl.controls.depStringID.value) {
                item.lstProformas.map((o) => {
                  this.posPrfLst.map((k) => {
                    if (k.proformaID === o.proformaID) {
                      this.matchedProformaLst.push(o);
                    }
                  });
                });
                this.selectedServiceDtl = item;
              }
            });
          }
          if (this.matchedProformaLst.length > 1) {
            this.eventDtl = e;
            this.frmGrpControlDtl = frmGrpControl;
            this.indexDtl = index;
            this.dialogRef = this.dialog.open(this.selectProformaDialog, {
              width: '580px',
              height: 'auto',
              panelClass: 'dialog-container',
              disableClose: true,
            });
          } else if (this.matchedProformaLst.length === 1) {
            this.populateReasonDetails(e, frmGrpControl, index, this.matchedProformaLst[0]);
          } else {
            this.populateReasonDetails(e, frmGrpControl, index, '');
          }
          // Spinner close
          this.spinner.hide();
        });
    } else {
      frmGrpControl.controls.depStartPortID.setErrors(false);
      frmGrpControl.controls.depEndPortID.setErrors(false);
      this.populateReasonDetails(e, frmGrpControl, index, '');
      // Spinner close
      this.spinner.hide();
    }
    // }, 1);
  }
  /** */
  public populateReasonDetails(e?, frmGrpControl?, index?, checkedProformaDtl?) {
    if (!frmGrpControl.controls.isSpecialService.value) {
      this.showScheduleDateError = false;
      const strDate = frmGrpControl.controls.depBeginDate.value
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;

      const endDate = frmGrpControl.controls.depEndDate.value
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

      const selectedService = frmGrpControl.controls.depStringID.value;
      const selectedEndDateTime = endDate != null ? endDate + 'T' + '00:00' : null;
      const selectedDateTime = strDate + 'T' + '00:00';
      // Error handling Removing ifPosEnteredIsValid for a while
      if (!frmGrpControl.controls.isSpecialPosition.value) {
        if (selectedService && selectedDateTime) {
          this.vesselsService
            .getPortsByService(selectedService, selectedDateTime, selectedEndDateTime)
            .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe(
              (response: any) => {
                const patternMatched = this.validateRegExPosPattern(frmGrpControl);
                let positionSelected;
                if (patternMatched) {
                  const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
                  const xPos = extraPosValue.indexOf('x');
                  xPos === 1
                    ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
                    : xPos === 2
                    ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
                    : (this.extraLoaderPosition = null);
                  positionSelected = this.extraLoaderPosition;
                } else {
                  positionSelected = +frmGrpControl.controls.depPosition.value;
                }
                // End of code
                const startportSet1 = new Set();
                const endportSet1 = new Set();

                if (positionSelected && positionSelected > 0 && !frmGrpControl.controls.isSpecialPosition.value) {
                  this.isVesslDeplyPositionSelected = true;
                  checkedProformaDtl
                    ? (this.vesselDeploymentStartPort = response.portDateTime.filter(
                        (o) =>
                          o.position === positionSelected && o.scheduleProformaPKId === checkedProformaDtl.proformaPKID
                      ))
                    : (this.vesselDeploymentStartPort = response.portDateTime.filter(
                        (o) => o.position === positionSelected
                      ));
                  // for multiple proforma and duplicate port
                  let multipleProformaMinPKId;
                  this.depStartPortListLineWise[index] = {};

                  if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
                    const mindateprfID = this.posPrfStartEndDtLst.reduce(function (r, a) {
                      return r.minDate < a.minDate ? r : a;
                    });

                    multipleProformaMinPKId = mindateprfID.proformaPKID;

                    this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                      (o) =>
                        !startportSet1.has(o.schedulePortId) &&
                        startportSet1.add(o.schedulePortId) &&
                        o.scheduleProformaPKId === multipleProformaMinPKId
                    );

                    this.isMultipleProformasflag = true;
                  } else {
                    this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                      (o) => !startportSet1.has(o.schedulePortId) && startportSet1.add(o.schedulePortId)
                    );
                  }

                  checkedProformaDtl
                    ? (this.vesselDeploymentEndPort = response.portDateTime.filter(
                        (o) =>
                          o.position === positionSelected && o.scheduleProformaPKId === checkedProformaDtl.proformaPKID
                      ))
                    : (this.vesselDeploymentEndPort = response.portDateTime.filter(
                        (o) => o.position === positionSelected
                      ));

                  let multipleProformaMaxPKId;
                  if (this.posPrfLst.length === 0 && this.posPrfStartEndDtLst.length > 0) {
                    const maxdateprfID = this.posPrfStartEndDtLst.reduce(function (r, a) {
                      return r.maxDate > a.maxDate ? r : a;
                    });

                    multipleProformaMaxPKId = maxdateprfID.proformaPKID;

                    this.depEndPortListLineWise[index] = {};
                    this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                      (o1) =>
                        !endportSet1.has(o1.schedulePortId) &&
                        endportSet1.add(o1.schedulePortId) &&
                        o1.scheduleProformaPKId === multipleProformaMaxPKId
                    );

                    this.isMultipleProformasflag = true;
                  } else {
                    this.depEndPortListLineWise[index] = {};
                    this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                      (o1) => !endportSet1.has(o1.schedulePortId) && endportSet1.add(o1.schedulePortId)
                    );
                  }

                  let startPortToPopulate: any;
                  const startDateBeforePort = new Date(
                    new DatePipe('en-US').transform(frmGrpControl.controls.depBeginDate.value, 'dd-MMM-yyyy')
                  );

                  if (this.isMultipleProformasflag) {
                    checkedProformaDtl
                      ? (startPortToPopulate = response.portDateTime.filter(
                          (k) =>
                            k.position === positionSelected &&
                            k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                            startDateBeforePort <=
                              new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                        ))
                      : (startPortToPopulate = response.portDateTime.filter(
                          (k) =>
                            k.position === positionSelected &&
                            k.scheduleProformaPKId === multipleProformaMinPKId &&
                            startDateBeforePort <=
                              new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                        ));
                  } else {
                    checkedProformaDtl
                      ? (startPortToPopulate = response.portDateTime.filter(
                          (k) =>
                            k.position === positionSelected &&
                            k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                            startDateBeforePort <=
                              new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                        ))
                      : (startPortToPopulate = response.portDateTime.filter(
                          (k) =>
                            k.position === positionSelected &&
                            startDateBeforePort <=
                              new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                        ));
                  }

                  if (startPortToPopulate.length) {
                    frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
                    this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;
                    frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                    frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                    if (this.fromCreateDummy && index === 0) {
                      this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
                    }
                  }
                  let endPortToPopulate: any;
                  const endDateBeforePort = new Date(
                    new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
                  );
                  if (endDateBeforePort) {
                    if (this.isMultipleProformasflag) {
                      checkedProformaDtl
                        ? (endPortToPopulate = response.portDateTime.filter(
                            (k) =>
                              positionSelected === k.position &&
                              k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                              endDateBeforePort >=
                                new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                          ))
                        : (endPortToPopulate = response.portDateTime.filter(
                            (k) =>
                              positionSelected === k.position &&
                              endDateBeforePort >=
                                new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
                              k.scheduleProformaPKId === multipleProformaMaxPKId
                          ));
                    } else {
                      checkedProformaDtl
                        ? (endPortToPopulate = response.portDateTime.filter(
                            (k) =>
                              positionSelected === k.position &&
                              k.scheduleProformaPKId === checkedProformaDtl.proformaPKID &&
                              endDateBeforePort >=
                                new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                          ))
                        : (endPortToPopulate = response.portDateTime.filter(
                            (k) =>
                              positionSelected === k.position &&
                              endDateBeforePort >=
                                new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
                          ));
                    }

                    if (endPortToPopulate.length) {
                      frmGrpControl.controls.depEndPortID.setValue(
                        endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
                      );
                      this.schedulePrfEndPortPKId =
                        endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;

                      frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                      frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
                    }
                  }

                  this.validateEndDateErrHandlng(e, frmGrpControl, index);
                  this.checkAdjacentLinesForSpcl(frmGrpControl, index);
                  this.validateStartDateErrHandlng(e, frmGrpControl, index);
                } else {
                  this.isVesslDeplyPositionSelected = false;
                  this.vesselDeploymentStartPort = response.portDateTime;
                  this.depStartPortListLineWise[index] = {};
                  this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort.filter(
                    (o) => !startportSet1.has(o.schedulePortId) && startportSet1.add(o.schedulePortId)
                  );
                  this.vesselDeploymentEndPort = response.portDateTime;
                  this.depEndPortListLineWise[index] = {};
                  this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort.filter(
                    (o1) => !endportSet1.has(o1.schedulePortId) && endportSet1.add(o1.schedulePortId)
                  );

                  this.validateEndDateErrHandlng(e, frmGrpControl, index);
                  this.validateStartDateErrHandlng(e, frmGrpControl, index);
                }
                this.checkMinStartDate();
                this.checkDateEquality();
                this.vesselDeploymentFrmGrp.updateValueAndValidity();
              },
              (errorResponse) => {
                this.showScheduleDateError = true;
                const msg = errorResponse.error.error;
                this.scheduleDateErrorMsg = msg;

                this.depEndPortListLineWise[index] = {};

                this.depEndPortListLineWise[index].ports = [];
                this.depStartPortListLineWise[index] = {};

                this.depStartPortListLineWise[index].ports = [];

                // Error handling for end port and end date when schedule doesn;t exist.
                frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
                frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
                this.checkMinStartDate();
                this.checkDateEquality();
                this.vesselDeploymentFrmGrp.updateValueAndValidity();
                this.spinner.hide();
              }
            );
          frmGrpControl.controls['depEndPortID'].setErrors(null);
          frmGrpControl.controls['depEndDate'].setErrors(null);
          this.validateEndDateErrHandlng(e, frmGrpControl, index);
          this.validateStartDateErrHandlng(e, frmGrpControl, index);
        }
      } else if (this.ifPosnEnteredIsSpcl && frmGrpControl.controls.isSpecialPosition.value) {
        frmGrpControl.controls.depStartPortID.clearValidators();
        frmGrpControl.controls.depEndPortID.clearValidators();
        frmGrpControl.updateValueAndValidity();
        // frmGrpControl.controls.depEndPortID.updateValueAndValidity();

        frmGrpControl.controls.depStartPortID.setErrors({ required: false });
        frmGrpControl.controls.depEndPortID.setErrors({ required: false });
        this.loadPorts(frmGrpControl, index);
        // this.populateDatePortForSpclPosition(frmGrpControl, index);
      }
    } else if (frmGrpControl.controls.isSpecialService.value) {
      frmGrpControl.controls.depStartPortID.setValue(null);
      frmGrpControl.controls.depEndPortID.setValue(null);
      frmGrpControl.controls.depStartPortID.setErrors(false);
      frmGrpControl.controls.depEndPortID.setErrors(false);
      frmGrpControl.controls.depSpecialServiceStartPortID.setValue(null);
      frmGrpControl.controls.depSpecialServiceEndPortID.setValue(null);
      frmGrpControl.controls.depStartPortID.clearValidators();
      frmGrpControl.controls.depEndPortID.clearValidators();
      frmGrpControl.updateValueAndValidity();

      const vesselId = this.fromVesselListPage ? this.fromVesselListVslId : this.vslId ? this.vslId : 0;
      const serviceId = frmGrpControl.controls.depStringID.value || 0;
      const positionEntered = frmGrpControl.controls.depPosition.value;
      frmGrpControl.controls.isSpecialPosition.setValue(true);
      // if (vesselId && serviceId) {
      //Check here
      // commented below code for performance fix
      // this.vesselsService
      //   .getVesselDeploymentDropdownsByServiceId(vesselId, serviceId)
      //   .subscribe(async (response: any) => {
      //     const lstOfSpecialPositions = await response.specialPositionsList;

      //     if (lstOfSpecialPositions.length) {
      //       const ifPosEnteredIsSpcl =
      //         lstOfSpecialPositions.findIndex((element) => element.spPosition === +positionEntered) > -1 ? true : false;
      //       if (!ifPosEnteredIsSpcl) {
      //         this.depPositionValid = false;
      //         this.showError = true;

      //         frmGrpControl.controls['depPosition'].setErrors({ incorrect: true });
      //       } else {
      //         this.depPositionValid = true;
      //         frmGrpControl.controls['depPosition'].setErrors(null);
      //         this.loadPorts(frmGrpControl, index);
      //       }
      //     }
      //     this.validateEndDateErrHandlng(e, frmGrpControl, index);
      //     this.validateStartDateErrHandlng(e, frmGrpControl, index);
      //     // this.populateDatePortForSpclPosition(frmGrpControl, index);
      //   });
    }
    // }

    if (this.fromCreateDummy && index === 0) {
      if (e !== '') {
        this.getPositionChangeValforDummy.emit(e);
      } else {
        this.getPositionChangeValforDummy.emit({ event: e, depPosition: frmGrpControl.controls.depPosition.value });
      }
    }

    // Error Handling to Validate Position
    if (
      this.depPositionExists === false ||
      !this.depPositionValid ||
      this.vesselDeploymentLines.controls[index]['controls']['depPosition'].value === ''
    ) {
      this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors({ incorrect: true });
    } else {
      this.vesselDeploymentLines.controls[index]['controls']['depPosition'].setErrors(null);
    }
    this.validateEndDateErrHandlng(e, frmGrpControl, index);
    this.validateStartDateErrHandlng(e, frmGrpControl, index);

    // To enable or disable Save button on start date click
    if (frmGrpControl.controls.depStartPortID.value == null) {
      frmGrpControl.setErrors({ invalid: true });
    }
    if (frmGrpControl.controls.depEndPortID.value == null) {
      frmGrpControl.setErrors({ invalid: true });
    }
    this.spinner.hide();
  }
  public checkAdjacentLinesForSpcl(frmGrpControl?, index?) {
    if (index > 0) {
      const prevLine = this.getValue(index - 1);
      if (prevLine.value.isSpecialPosition) {
        const startPortInCurrentLine = this.depStartPortListLineWise[index].ports.filter(
          (p) => p.schedulePortId === +frmGrpControl.controls.depStartPortID.value
        );
        const portForPrevSpclLine = startPortInCurrentLine.length
          ? this.allPorts.filter(
              (ap) => ap.schedulePortName.toLowerCase() === startPortInCurrentLine[0].schedulePortName.toLowerCase()
            )
          : null;
        if (portForPrevSpclLine && portForPrevSpclLine.length) {
          prevLine['controls'].depSpecialServiceEndPortID.setValue(portForPrevSpclLine[0].schedulePortId);
        }
      }
    }
    const nextLine = this.getValue(index + 1);
    if (nextLine && nextLine.value.isSpecialPosition) {
      const endPortInCurrentLine = this.depEndPortListLineWise[index].ports.filter(
        (p) => p.schedulePortId === +frmGrpControl.controls.depEndPortID.value
      );
      const portForNextSpclLine = endPortInCurrentLine.length
        ? this.allPorts.filter(
            (ap) => ap.schedulePortName.toLowerCase() === endPortInCurrentLine[0].schedulePortName.toLowerCase()
          )
        : null;
      if (endPortInCurrentLine && endPortInCurrentLine.length) {
        nextLine['controls'].depSpecialServiceStartPortID.setValue(portForNextSpclLine[0].schedulePortId);
      }
    }
  }
  public populateDatePortForSpclPosition(frmGrpControl?, index?) {
    if (frmGrpControl) {
      const positionAtCurrentIndex = frmGrpControl.controls.depPosition.value;
    }
    // if (+positionAtCurrentIndex === 91 || +positionAtCurrentIndex === 92) {
    if (frmGrpControl && frmGrpControl.controls.isSpecialPosition) {
      if (index > 0) {
        const previousIndexDepLine = this.getValue(index - 1);
        // frmGrpControl.controls.depBeginDate.setValue(previousIndexDepLine.value.depEndDate);
        this.validateGapDeploymentLine(frmGrpControl, index);
        const portsFromPrevDepLine = previousIndexDepLine.value.isSpecialPosition
          ? this.allPorts
          : this.depEndPortListLineWise[index - 1].ports;

        const portIdInPrevLine =
          previousIndexDepLine.value.depEndPortID || previousIndexDepLine.value.depSpecialServiceEndPortID;

        const portUsedInPrevLine = portIdInPrevLine
          ? portsFromPrevDepLine.filter((p) => p.schedulePortId === +portIdInPrevLine)
          : null;

        const filterPortForCurrentIndex = portUsedInPrevLine
          ? this.allPorts.filter((ap) =>
              ap.schedulePortName.toLowerCase() === portUsedInPrevLine[0].schedulePortName.toLowerCase()
                ? portUsedInPrevLine[0].schedulePortName.toLowerCase()
                : ''
            )
          : null;
        if (filterPortForCurrentIndex && filterPortForCurrentIndex[0]) {
          frmGrpControl.controls.depSpecialServiceStartPortID.setValue(filterPortForCurrentIndex[0].schedulePortId);
        }
      }

      const nextIndexDepLine = this.getValue(index + 1);

      if (nextIndexDepLine && nextIndexDepLine.value.depPosition) {
        const portsFromNextDepLine = nextIndexDepLine.value.isSpecialPosition
          ? this.allPorts
          : this.depEndPortListLineWise[index + 1].ports;

        const portIdInNextLine =
          nextIndexDepLine.value.depStartPortID || nextIndexDepLine.value.depSpecialServiceStartPortID;

        const portUsedInNextLine = portIdInNextLine
          ? portsFromNextDepLine.filter((p) => p.schedulePortId === +portIdInNextLine)
          : null;

        const filterPortForCurrentIndex =
          portUsedInNextLine && portUsedInNextLine[0]
            ? this.allPorts.filter(
                (ap) => ap.schedulePortName.toLowerCase() === portUsedInNextLine[0].schedulePortName.toLowerCase()
              )
            : null;
        if (filterPortForCurrentIndex && filterPortForCurrentIndex[0]) {
          frmGrpControl.controls.depSpecialServiceEndPortID.setValue(filterPortForCurrentIndex[0].schedulePortId);
        }
      }
    }
    if (frmGrpControl) {
      frmGrpControl.controls.depStartPortID.setValue(null);
      frmGrpControl.controls.depEndPortID.setValue(null);
    }
  }

  /** */
  public editPort() {
    this.showRangeInvalid = false;
  }
  /** */

  public checkMinStartDate() {
    this.vesselDeploymentLines.value.forEach((item, index) => {
      if (
        (typeof item.depBeginDate === 'object' && item.depBeginDate.format('YYYY-MM-DD') < this.depErrorLineMinDate) ||
        (typeof item.depBeginDate === 'object' && item.depBeginDate.format('YYYY-MM-DD')) >
          (item.depEndDate && moment(item.depEndDate).format('YYYY-MM-DD'))
      ) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      } else {
        if (this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].valid) {
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        }
      }

      if (
        (item.depEndDate && typeof item.depEndDate === 'object' && item.depEndDate.format('YYYY-MM-DD')) <
        (item.depBeginDate && moment(item.depBeginDate).format('YYYY-MM-DD'))
      ) {
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
    });
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
  }
  public CheckDuplicateDepLineWithPrevDepLines() {
    if (!this.vesselPreviousDeployments || this.vesselPreviousDeployments.length === 0) {
      return;
    }
    let prevDeplymentLines = [...this.vesselPreviousDeployments];
    if (this.deletedDeploymentLines.length > 0) {
      //Removed the Dep line if it deleted
      const deletedidList: number[] = this.deletedDeploymentLines.map((obj) => obj.depPKID);
      prevDeplymentLines = this.vesselPreviousDeployments.filter((item) => !deletedidList.includes(item.depPKID));
    }
    this.errorInPrevDepLinesMessage = null;
    this.errorInPrevDepLines = false;
    const previdList: number[] = prevDeplymentLines.map((obj) => obj.depPKID);
    const currentidList: number[] = this.vesselDeploymentLines.value.map((obj) => obj.depPKID);
    // Step 1  Validate Duplicate Deployment line with current and previous
    this.vesselDeploymentLines.value.forEach((item, index) => {
      // Validate Dep line with prev avaible dep lines
      const startDate =
        typeof item.depBeginDate === 'string' ? moment(item.depBeginDate, 'YYYY-MM-DD') : item.depBeginDate;
      const endDate = typeof item.depEndDate === 'string' ? moment(item.depEndDate, 'YYYY-MM-DD') : item.depEndDate;

      const foundObject = prevDeplymentLines.find(
        (obj) =>
          moment(obj.depBeginDate, 'YYYY-MM-DD').isSame(startDate, 'day') &&
          moment(obj.depEndDate, 'YYYY-MM-DD').isSame(endDate, 'day') &&
          obj.depStringID === item.depStringID &&
          obj.depPKID !== item.depPKID
      );
      if (foundObject) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depStringID'].setErrors({ incorrect: true });
        this.errorInPrevDepLines = true;
        if (!this.errorInPrevDepLinesMessage) {
          this.errorInPrevDepLinesMessage = 'Duplicate deployment line found';
        }
      }
    });
    // Step 2 Check All Deployment line are in order
    const prevDepLine = prevDeplymentLines.filter((item) => !currentidList.includes(item.depPKID));
    const mergedDepLineList = [...prevDepLine, ...this.vesselDeploymentLines.value];
    let isInValid = false;
    for (let i = 0; i < mergedDepLineList.length; i++) {
      if (!mergedDepLineList[i].depStringID) {
        continue;
      }

      const currentEndDate = mergedDepLineList[i] ? moment(mergedDepLineList[i].depEndDate, 'YYYY-MM-DD') : null;
      const nextStartDate = mergedDepLineList[i + 1]
        ? moment(mergedDepLineList[i + 1].depBeginDate, 'YYYY-MM-DD')
        : null;
      if (nextStartDate && currentEndDate && nextStartDate < currentEndDate) {
        isInValid = true;
        this.vesselDeploymentLines.value.forEach((item, index) => {
          if (item.depPKID === mergedDepLineList[i].depPKID) {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
          }
          if (item.depPKID === mergedDepLineList[i + 1].depPKID) {
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
          }
        });
      }
    }
    if (isInValid) {
      this.errorInPrevDepLines = true;
      const message = 'End date and next start dates sequence error';
      this.errorInPrevDepLinesMessage =
        this.errorInPrevDepLinesMessage === null ? message : `${this.errorInPrevDepLinesMessage},${message}`;
    }
  }
  //  Checks if start & end date in deployment is same & sets error
  public checkDateEquality() {
    this.vesselDeploymentLines.value.forEach((item, index) => {
      const startDate =
        typeof item.depBeginDate === 'string' ? moment(item.depBeginDate, 'YYYY-MM-DD') : item.depBeginDate;
      const endDate = typeof item.depEndDate === 'string' ? moment(item.depEndDate, 'YYYY-MM-DD') : item.depEndDate;
      if (item.depBeginDate && item.depEndDate && endDate.format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      }
    });
    this.vesselDeploymentFrmGrp.updateValueAndValidity();
  }

  public checkPrevEndDateMin() {
    const depLines = this.vesselDeploymentLines.value;
    depLines.forEach((item, index) => {
      if (item.depBeginDate && typeof item.depBeginDate === 'object') {
        const newBeginDate = item.depBeginDate.format('YYYY-MM-DD');
        item['depBeginDate'] = Object.assign(newBeginDate).toString();
      }
      if (item.depEndDate && typeof item.depEndDate === 'object') {
        const newEndDate = item.depEndDate.format('YYYY-MM-DD');
        item['depEndDate'] = Object.assign(newEndDate).toString();
      }

      if (
        index > 0 &&
        new Date(depLines[index - 1].depEndDate).valueOf() > new Date(depLines[index].depBeginDate).valueOf()
      ) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.updateValueAndValidity();
      }
    });
  }

  public startDateChng(frmGrpControl?, index?) {
    this.isCancelShowPreviousClick = false;
    this.clearAlertService();
    // this.controlsValueChanges();
    // To enable or disable Save button on start date click
    this.displayErrorLines(this.vesselDeploymentFrmGrp);
    this.checkMinStartDate();
    this.checkDateEquality();
    this.checkPrevEndDateMin();
    this.CheckDuplicateDepLineWithPrevDepLines();
    this.vesselDeploymentFrmGrp.updateValueAndValidity();

    if (frmGrpControl.controls.depEndDate.value == null) {
      frmGrpControl.setErrors({ invalid: true });
    }
    if (frmGrpControl.controls.depStringID.value == null) {
      frmGrpControl.setErrors({ invalid: true });
    }
    // let checkGapForChrter = false;
    this.getDatesChangedforDummy.emit(frmGrpControl.controls.depBeginDate.value);
    if (index > 0) {
      this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
    }

    if (frmGrpControl.controls.depBeginDate.invalid) {
      this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
      return;
    } else {
      // begin and enddate comparison
      let invalid = false;
      const BeginDate = frmGrpControl.controls.depBeginDate.value !== null && frmGrpControl.controls.depBeginDate.value;
      const EndDate = frmGrpControl.controls.depEndDate.value !== null && frmGrpControl.controls.depEndDate.value;
      if (BeginDate && EndDate) {
        invalid = new Date(BeginDate).valueOf() > new Date(EndDate).valueOf();
      }
      if (invalid) {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
        return;
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
      }
    }

    if (this.validateGapDeploymentLine(frmGrpControl, index)) {
      return;
    }

    this.validateStartDateErrHandlng('', frmGrpControl, index);

    if (!isValidDate(frmGrpControl.controls.depBeginDate.value)) {
      this.isValidDateUpdateControlValue(frmGrpControl.controls.depBeginDate, null);
      return false;
    }
    // Begin- code for Charter Vsl type Scenario erro handling

    // End
    this.showScheduleDateError = false;
    this.showMoreVisible = false;
    const strDate = moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD');
    const endDate =
      frmGrpControl.controls.depEndDate.value !== null
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;

    const selectedService = frmGrpControl.controls.depStringID.value;

    const selectedDateTime = strDate ? strDate + 'T' + '00:00' : null;
    const selectedEndDateTime = endDate ? endDate + 'T' + '00:00' : null;
    if (selectedService && selectedDateTime != null && !frmGrpControl.controls.isSpecialPosition.value) {
      this.vesselsService
        .getPortsByService(selectedService, selectedDateTime, selectedEndDateTime)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            // Logic for Extra position containing x or X
            const patternMatched = this.validateRegExPosPattern(frmGrpControl);
            let positionSelected;

            if (patternMatched) {
              const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
              const xPos = extraPosValue.indexOf('x');
              xPos === 1
                ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
                : xPos === 2
                ? (this.extraLoaderPosition = +frmGrpControl.depPosition.value.slice(0, 2))
                : (this.extraLoaderPosition = null);
              positionSelected = this.extraLoaderPosition;
            } else {
              positionSelected = +frmGrpControl.controls.depPosition.value;
            }
            // this.vesselDeploymentStartPort = [];
            if (positionSelected && positionSelected > 0) {
              this.isVesslDeplyPositionSelected = true;
              this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.position === positionSelected);
              this.depStartPortListLineWise[index] = {};
              this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort;
            } else {
              this.isVesslDeplyPositionSelected = false;
              this.vesselDeploymentStartPort = response.portDateTime;
              this.depStartPortListLineWise[index] = {};
              this.depStartPortListLineWise[index].ports = this.vesselDeploymentStartPort;
            }
            if (positionSelected > 0) {
              // this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(this.vesselDeploymentStartPort[0].schedulePortId);
              let startPortToPopulate: any;
              const startDateBeforePort = new Date(frmGrpControl.controls.depBeginDate.value);
              // startPortToPopulate = response.portDateTime.filter(k => positionSelected === k.position)

              startPortToPopulate = response.portDateTime.filter(
                (k) =>
                  positionSelected === k.position &&
                  startDateBeforePort <= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
              );

              if (startPortToPopulate.length) {
                frmGrpControl.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
                this.schedulePrfStartPortPKId = startPortToPopulate[0].scheduleProformaPKId;

                frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
                frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
              }

              if (this.fromCreateDummy && index === 0) {
                this.getStartPortChangeValforDummy.emit(startPortToPopulate[0]);
              }
            }
            this.checkAdjacentLinesForSpcl(frmGrpControl, index);
          },
          (errorResponse) => {
            this.showScheduleDateError = true;
            const msg = errorResponse.error.error;
            this.scheduleDateErrorMsg = msg;
            this.depEndPortListLineWise[index] = {};
            this.depEndPortListLineWise[index].ports = [];
            this.depStartPortListLineWise[index] = {};

            this.depStartPortListLineWise[index].ports = [];
            this.spinner.hide();
          }
        );
    } else if (frmGrpControl.controls.isSpecialPosition.value) {
      this.loadPorts(frmGrpControl, index);
    }

    if (frmGrpControl.controls.depPosition.value && frmGrpControl.controls.depStringID.value) {
      this.verifyMultiplePrfExists('', frmGrpControl, index);
    }

    if (frmGrpControl.controls.depEndDate.value == null || frmGrpControl.controls.depStringID.value == null) {
      const emitObj = {
        isInvalid: true,
        isModified: this.isAnyControlModified,
        isPristine: this.vesselDeploymentLines.pristine,
        isDirty: this.vesselDeploymentLines.dirty,
        controlIndex: index,
        isdeplineEmpty: true,
      };
      this.depControlValueChangeEventsEmiter.emit(emitObj);
    }
  }

  public endDateChng(frmGrpControl?, index?, isLastDeploymentLine?) {
    this.clearAlertService();
    this.checkMinStartDate();
    this.checkDateEquality();
    this.displayErrorLines(this.vesselDeploymentFrmGrp);

    this.vesselDeploymentFrmGrp.updateValueAndValidity();

    if (frmGrpControl) {
      frmGrpControl.controls.setDepEndDateErrorCSS.setValue(false);
    }
    this.controlsValueChanges();

    this.getDatesChangedforDummy.emit(frmGrpControl.controls.depEndDate.value);

    const checkGapForChrter = false;

    this.isCancelShowPreviousClick = false;

    if (frmGrpControl.controls.depEndDate.invalid) {
      this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
      return;
    } else if (this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].valid) {
      // this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
      // begin and enddate comparison
      let invalid = false;
      const BeginDate = frmGrpControl.controls.depBeginDate.value !== null && frmGrpControl.controls.depBeginDate.value;
      const EndDate = frmGrpControl.controls.depEndDate.value !== null && frmGrpControl.controls.depEndDate.value;
      if (BeginDate && EndDate) {
        invalid = new Date(BeginDate).valueOf() > new Date(EndDate).valueOf();
      }
      if (invalid) {
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
        this.isCancelShowPreviousClick = true;
        return;
      } else {
        let invalidprev = false;
        // Validate previous enddate and current line
        const previousIndexDepLine = this.getValue(index - 1);
        if (previousIndexDepLine) {
          const CBeginDate =
            frmGrpControl.controls.depBeginDate.value !== null && frmGrpControl.controls.depBeginDate.value;
          const PEndDate =
            previousIndexDepLine['controls'].depEndDate.value !== null &&
            previousIndexDepLine['controls'].depEndDate.value;
          if (CBeginDate && PEndDate) {
            invalidprev = new Date(CBeginDate).valueOf() < new Date(PEndDate).valueOf();
          }
          if (invalidprev) {
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors({ incorrect: true });
            this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors({ incorrect: true });
            return;
          } else {
            this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
            this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
            this.vesselDeploymentLines.controls[index - 1]['controls']['depEndDate'].setErrors(null);
          }
        } else {
          this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
          this.vesselDeploymentLines.controls[index]['controls']['depBeginDate'].setErrors(null);
        }
      }
    }
    this.validateEndDateErrHandlng('', frmGrpControl, index);

    if (!isValidDate(frmGrpControl.controls.depEndDate.value)) {
      this.isValidDateUpdateControlValue(frmGrpControl.controls.depEndDate, null);
      return false;
    }
    let vslVesselType;
    // Error handling check on extend and dummy overview page for vessel type
    if (this.fromCreateDummy === true) {
      vslVesselType = localStorage.getItem('this.vesselDetail.vslVesselType');
    }
    const nextIndexDepLine = this.getValue(index + 1);
    if (nextIndexDepLine) {
      nextIndexDepLine['controls'].depBeginDate.setValue(frmGrpControl.controls.depEndDate.value);
      /*bug 345942 fix*/
      nextIndexDepLine['controls']['depIsUpdated'].setValue(true);
      let invalid = false;
      const BeginDate = frmGrpControl.controls.depEndDate.value !== null && frmGrpControl.controls.depEndDate.value;
      const EndDate =
        nextIndexDepLine['controls'].depEndDate.value !== null && nextIndexDepLine['controls'].depEndDate.value;
      if (BeginDate && EndDate) {
        invalid = new Date(BeginDate).valueOf() > new Date(EndDate).valueOf();
      }
      if (invalid) {
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors({ incorrect: true });
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors({ incorrect: true });
        return;
      } else {
        this.vesselDeploymentLines.controls[index]['controls']['depEndDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depBeginDate'].setErrors(null);
        this.vesselDeploymentLines.controls[index + 1]['controls']['depEndDate'].setErrors(null);
      }

      this.controlsValueChanges();
    }
    // Moved function to call after depBeginDate is set
    this.CheckDuplicateDepLineWithPrevDepLines();
    // Begin - code for Charter Type vessel scenarion- Erro Handling

    // End
    this.showScheduleDateError = false;
    this.showWarningOverlapping = false;
    this.warnings = [];
    this.showErrorOverlapping = false;
    this.showPortRangeError = false;

    const endDate =
      frmGrpControl.controls.depEndDate.value !== null
        ? moment(frmGrpControl.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD')
        : null;
    const beginDate =
      frmGrpControl.controls.depBeginDate.value !== null
        ? moment(frmGrpControl.controls.depBeginDate.value).subtract(1, 'M').format('YYYY-MM-DD')
        : null;

    const selectedService = frmGrpControl.controls.depStringID.value;
    const selectedDateTime = endDate ? endDate + 'T' + '00:00' : null;
    this.showScheduleDateError = false;

    this.showWarningOverlapping = false;
    this.showErrorOverlapping = false;
    this.warnings = [];
    this.showPortRangeError = false;
    // Error handling for vessel type charter end date should not exceed estimated delivery date and for own should not be grater than outfleet date

    if (isLastDeploymentLine) {
      const lastDepl = {
        depEndDate: frmGrpControl.controls.depEndDate.value,
      };
      this.addNewDeploymentLine(lastDepl);

      this.addLastDeploymentValidators(frmGrpControl.controls['depPosition']);
      this.addLastDeploymentValidators(frmGrpControl.controls['depReasonCodeID']);
    }

    if (selectedService && selectedDateTime != null && !frmGrpControl.controls.isSpecialPosition.value) {
      //Commented below code is part of performance fix
      // this.vesselsService
      //   .getPortsByService(selectedService, beginDate, endDate)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(
      //     (response: any) => {
      //       // Logic for Extra position containing x or X
      //       const patternMatched = this.validateRegExPosPattern(frmGrpControl);
      //       let positionSelected;
      //       // if (this.validExtraPos === true) {
      //       //   positionSelected = this.extraLoaderPosition;
      //       // }
      //       // this.portsDeploymentLineWise[index] = response.portDateTime;
      //       if (patternMatched) {
      //         const extraPosValue = frmGrpControl.controls.depPosition.value.toLowerCase();
      //         const xPos = extraPosValue.indexOf('x');
      //         xPos === 1
      //           ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 1))
      //           : xPos === 2
      //           ? (this.extraLoaderPosition = +frmGrpControl.controls.depPosition.value.slice(0, 2))
      //           : (this.extraLoaderPosition = null);
      //         positionSelected = this.extraLoaderPosition;
      //       } else {
      //         positionSelected = +frmGrpControl.controls.depPosition.value;
      //       }
      //       // this.vesselDeploymentEndPort = [];
      //       if (positionSelected && positionSelected > 0) {
      //         this.isVesslDeplyPositionSelected = true;
      //         this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.position === positionSelected);
      //         this.depEndPortListLineWise[index] = {};
      //         this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort;
      //         if (this.vesselDeploymentEndPort.length === 0) {
      //           frmGrpControl.controls.depEndPortID.setValue('');
      //           this.showScheduleDateError = true;
      //           const msg = 'Schedules do not exist for the selected end date ';
      //           this.scheduleDateErrorMsg = msg;
      //         }
      //       } else {
      //         this.isVesslDeplyPositionSelected = false;
      //         this.vesselDeploymentEndPort = response.portDateTime;
      //         this.depEndPortListLineWise[index] = {};
      //         this.depEndPortListLineWise[index].ports = this.vesselDeploymentEndPort;
      //         if (positionSelected > 0) {
      //           let endPortToPopulate: any;
      //           const endDateBeforePort = new Date(
      //             new DatePipe('en-US').transform(frmGrpControl.controls.depEndDate.value, 'dd-MMM-yyyy')
      //           );
      //           endPortToPopulate = response.portDateTime.filter(
      //             (k) =>
      //               positionSelected === k.position &&
      //               endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy'))
      //           );
      //           if (endPortToPopulate.length) {
      //             frmGrpControl.controls.depEndPortID.setValue(
      //               endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
      //             );
      //             this.schedulePrfEndPortPKId = endPortToPopulate[endPortToPopulate.length - 1].scheduleProformaPKId;
      //             frmGrpControl.controls.depSpecialServiceStartPortID.setErrors(null);
      //             frmGrpControl.controls.depSpecialServiceEndPortID.setErrors(null);
      //           }
      //           this.checkAdjacentLinesForSpcl(frmGrpControl, index);
      //         }
      //       }
      //     },
      //     (errorResponse) => {
      //       // Error handling for end port and end date when schedule doesn;t exist.
      //       this.showScheduleDateError = true;
      //       const msg = errorResponse.error.error;
      //       this.scheduleDateErrorMsg = msg;
      //       this.depEndPortListLineWise[index] = {};
      //       this.depEndPortListLineWise[index].ports = [];
      //       this.depStartPortListLineWise[index] = {};
      //       this.depStartPortListLineWise[index].ports = [];
      //       frmGrpControl.controls['depEndPortID'].setErrors({ incorrect: true });
      //       frmGrpControl.controls['depEndDate'].setErrors({ incorrect: true });
      //       this.spinner.hide();
      //     }
      //   );
      // frmGrpControl.controls['depEndPortID'].setErrors(null);
      // frmGrpControl.controls['depEndDate'].setErrors(null);
    } else if (frmGrpControl.controls.isSpecialPosition.value) {
      this.loadPorts(frmGrpControl, index);
    }
    // Error handling to validate end date

    this.validateGapDeploymentLineEndDate(frmGrpControl, index, isLastDeploymentLine);

    if (frmGrpControl.controls.depPosition.value && frmGrpControl.controls.depStringID.value) {
      this.verifyMultiplePrfExists('', frmGrpControl, index);
    }
  }

  public populateStartDate(e, frmGrp, index) {
    this.controlsValueChanges();
    if (this.fromCreateDummy && index === 0) {
      this.getStartPortChangeValforDummy.emit(e);
    }
    if (frmGrp.controls.isSpecialPosition.value) {
      frmGrp.controls.depSpecialServiceStartPortID.setValue(e.schedulePortId);
      frmGrp.controls.depStartPortID.setValue(null);
    }
    if (index > 0) {
      const previousIndexDepLine = this.getValue(index - 1);
      if (+previousIndexDepLine.value.isSpecialPosition) {
        const portsFromThisDepLine = frmGrp.controls.isSpecialPosition.value
          ? this.allPorts
          : this.depStartPortListLineWise[index].ports;

        const portIdInThisLine =
          frmGrp.controls.depStartPortID.value || frmGrp.controls.depSpecialServiceStartPortID.value;

        const portUsedInThisLine = portsFromThisDepLine.filter((p) => p.schedulePortId === +portIdInThisLine);

        const filterPortForPrevIndex = this.allPorts.filter(
          (ap) => ap.schedulePortName.toLowerCase() === portUsedInThisLine[0].schedulePortName.toLowerCase()
        );

        previousIndexDepLine['controls'].depSpecialServiceEndPortID.setValue(filterPortForPrevIndex[0].schedulePortId);
      }
    }
    this.displayErrorLines(frmGrp);
  }
  /** */
  public populateEndDate(e, frmGrp, index) {
    this.controlsValueChanges();
    if (frmGrp.controls.isSpecialPosition.value) {
      frmGrp.controls.depSpecialServiceEndPortID.setValue(e.schedulePortId);
      frmGrp.controls.depEndPortID.setValue(null);
    }
    if (index >= 0) {
      const nextIndexDepLine = this.getValue(index + 1);
      if (nextIndexDepLine && nextIndexDepLine.value.isSpecialPosition) {
        const portsFromThisDepLine = frmGrp.controls.isSpecialPosition.value
          ? this.allPorts
          : this.depEndPortListLineWise[index].ports;

        const portIdInThisLine = frmGrp.controls.depEndPortID.value || frmGrp.controls.depSpecialServiceEndPortID.value;

        const portUsedInThisLine = portsFromThisDepLine.filter((p) => p.schedulePortId === +portIdInThisLine);

        const filterPortForPrevIndex = this.allPorts.filter(
          (ap) => ap.schedulePortName.toLowerCase() === portUsedInThisLine[0].schedulePortName.toLowerCase()
        );

        nextIndexDepLine['controls'].depSpecialServiceStartPortID.setValue(filterPortForPrevIndex[0].schedulePortId);
      }
    }
    this.displayErrorLines(frmGrp);
  }

  public clearSpinner() {
    this.spinner.hide();
  }

  public isVsDepDeleteAuthCheck() {
    if (this.auth.BYPASS_AUTHENTICATION) {
      return true;
    } else {
      return (
        this.auth.isRolePresent(this.auth.roles, 'Deployment') ||
        this.auth.isRolePresent(this.auth.roles, 'L3') ||
        this.auth.isRolePresent(this.auth.roles, 'FinanceL3')
      );
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private showEditConfirmation() {
    const dialogRef = this.dialog.open(this.editPrevious);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          return;
        }
      });
  }
}
