//

import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { createSidetab } from '@maersk-global/insightshub-embed-common';
import '@maersk-global/insightshub-embed-common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { Router } from '@angular/router';
import { NpsIntegrationComponent } from '@cham-components/nps-integration/nps-integration.component';
import { MatDialog } from '@angular/material/dialog';
import { feedbackId } from '@cham-utils/constants';
import { NpsFeedbackService } from '@cham-services/nps-feedback/nps-feedback.service';

@Component({
  selector: 'champs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() private susNpsValidation?: any;
  @Input() private userInfo?: any;
  @Output() private isSigningOut = new EventEmitter<boolean>();

  private _showDrawer: boolean = false;

  public isHandset$: Observable<boolean>;
  public showDropdown: boolean = false;
  public fullName: any;
  public firstName: any;
  public isReportingRole: any;
  public isNoRole: any;
  public dialogRef: any;
  public showWarningText: any;
  public showNotification = false;
  private notificationKey = 'notificationDate';

  @Output() public showDrawer: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public npsFeedbackService: NpsFeedbackService
  ) {

    this.isHandset$ = this.breakpointObserver.observe(['(max-width:988px)'])
      .pipe(
        map(result => result.matches)
      );

  }

  public ngOnInit() {
    this.showWarningText = this.susNpsValidation;
    if (this.auth.BYPASS_AUTHENTICATION) {
      this.fullName = '';
      this.isReportingRole = false;
    } else {
      this.fullName = this.reverseName(this.userInfo.name);
      this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
      createSidetab(this.getFeedbackId(), {
        buttonText: 'Champs Feedback',
        email: this.userInfo.preferred_username,
      });
    }
    this.isNoRole = !this.auth.roles ? true : false;
    if(this.susNpsValidation && this.npsFeedbackService.isAdditionalDateNotToday()){
      this.npsFeedBack();
    }
  }
  public npsFeedBack() {
    const { feedbackId, feedbackDisplayId, QuestionIds } = this.getNPSFeedbackId();
    this.dialogRef = this.dialog.open(NpsIntegrationComponent, {
      width: 'auto', 
      height: 'auto',
      data: {
        username : this.userInfo.name,
        userEmail : this.userInfo.preferred_username,
        feedbackDisplayId: feedbackDisplayId,
        feedbackId: feedbackId,
        QuestionIds: QuestionIds
      },
    });

    this.dialogRef.afterClosed().subscribe(result => {
      localStorage.setItem(this.notificationKey, new Date().toISOString());
      this.npsFeedbackService.validateSubmissionDate().subscribe(reminderFlag => {
        this.showWarningText = reminderFlag;
      });
    });
  }
  private getNPSFeedbackId() {
    const hostname = window.location.hostname;
    // const hostname = 'http://localhost:4200/';
    // const hostname = 'https://champs.dev.maersk.com/';
    // const hostname = 'https://champs.sit.maersk.com/';
    // const hostname = 'https://champs.maersk.com/';
    if (hostname.includes('.dev.') || hostname.includes('localhost')) {
      return {
        feedbackId: feedbackId.devNPSFeedBackId,
        feedbackDisplayId: feedbackId.devNPSFeedBackDisplayId,
        QuestionIds: feedbackId.devNPSQsn
      }
    } else if (hostname.includes('.sit.')) {
      return {
        feedbackId: feedbackId.sitNPSFeedBackId,
        feedbackDisplayId: feedbackId.sitNPSFeedBackDisplayId,
        QuestionIds: feedbackId.sitNPSQsn
      }
    } else {
      return {
        feedbackId: feedbackId.prodNPSFeedBackId,
        feedbackDisplayId: feedbackId.prodNPSFeedBackDisplayId,
        QuestionIds: feedbackId.prodNPSQsn
      }
    }
  }
  private getFeedbackId() {
    const hostname = window.location.hostname;
    // const hostname = 'http://localhost:4200/';
    // const hostname = 'https://champs.dev.maersk.com/';
    // const hostname = 'https://champs.sit.maersk.com/';
    // const hostname = 'https://champs.maersk.com/';
    if (hostname.includes('.dev.') || hostname.includes('localhost')) {
      return feedbackId.devSUSFeedBackId;
    } else if (hostname.includes('.sit.')) {
      return feedbackId.sitSUSFeedBackId;
    } else {
      return feedbackId.prodSUSFeedBackId;
    }
  }

  public reverseName(name) {
    return name.split(',').reverse().join(' ');
  }

  /**
   * function to toggle drawer
   */
  public toggleDrawer = () => {
    this._showDrawer = !this._showDrawer;
    this.showDrawer.emit(this._showDrawer);
  }

  public toggle() {
    this.showDropdown = !this.showDropdown;
  }

  public signOut() {
    this.isSigningOut.emit(true);
  }

  public navigateTo(URL: string) {
    if (this.isReportingRole) {
      this.router.navigate(['/reports']);
    }
    this.router.navigate([URL]);
  }
}
