//

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CHECK_IN_URLS } from './http-spinner.interceptor';

const URLS_LISTS: Array<string | RegExp> = [
  '/api/MarketRateManagement/MarketRates',
  /\/api\/FixtureManagement\/FixtureRecapCommentAdditionalView\/[0-9].*/,
  /\/api\/VesselManagement\/VesselDetail\/[0-9].*/,
];

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {

  /**
   * intercept method of HttpInterceptor
   * @param {HttpRequest} req request object
   * @param {HttpHandler} next on response handler
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (req.method.toLowerCase() === 'get' && isIEOrEdge && CHECK_IN_URLS(URLS_LISTS, req.url)) {
      const formHeader = req.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      request = formHeader;
    }

    return next.handle(request);
  }
}
