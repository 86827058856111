//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MaterialModule } from '../material/material.module';
import { PipeModule } from '../pipe/pipe.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { HeaderComponent } from './header/header.component';
import { VesselFixtureComponent } from './vessel-fixture/vessel-fixture.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { VesselDeploymentsComponent } from './vessel-deployments/vessel-deployments.component';
import { VesselInformationComponent } from './vessel-information/vessel-information.component';
import { VesselSpecificationComponent } from './vessel-specification/vessel-specification.component';
import { MachineryParticularsComponent } from './machinery-particulars/machinery-particulars.component';
import { SpeedAndConsumptionComponent } from './speed-and-consumption/speed-and-consumption.component';
import { VesselCommComponent } from './vessel-comm/vessel-comm.component';
import { CargoCapacityAndLayoutComponent } from './cargo-capacity-and-layout/cargo-capacity-and-layout.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { VesselNewVesselSpecificationsComponent } from './vessel-new-vessel-specifications/vessel-new-vessel-specifications.component';
import { VesselNewPlansDocsAndCertsComponent } from './vessel-new-plans-docs-and-certs/vessel-new-plans-docs-and-certs.component';
import { VesselNewCargoCapLayoutComponent } from './vessel-new-cargo-cap-layout/vessel-new-cargo-cap-layout.component';
import { VesselNewMachineryParticularsComponent } from './vessel-new-machinery-particulars/vessel-new-machinery-particulars.component';
import { DirectivesModule } from '../directives/directives.module';
import { CanDeactivateModule } from '../modules/can-deactivate/can-deactivate.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NumberSplitterComponent } from './number-splitter/number-splitter.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { AppInsightBaseComponent } from './app-insights/app-insight-base/app-insight-base.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { CustomMomentDatepickerComponent } from './custom-moment-datepicker/custom-moment-datepicker.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CustomMomentUtcAdapter } from './custom-moment-datepicker/custom-moment-utc-adapter';
import { VesselNewAdditionalInformationComponent } from './vessel-new-additional-information/vessel-new-additional-information.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { VesselLocationComponent } from './vessel-location/vessel-location.component';
import { AlertNotificationModule } from '../modules/alert-notification/alert-notification.module';
import { AlertService } from '../core/services/alert/alert.service';
import { VesselRedesignDeploymentsComponent } from './vessel-redesign-deployments/vessel-redesign-deployments.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReloadMessageModalComponent } from './reload-message-modal/reload-message-modal.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { VesselDeploymentsLinesComponent } from './vessel-deployments-lines/vessel-deployments-lines.component';
import { DryDockPlanningDashboardComponent } from './dry-dock-planning-dashboard/dry-dock-planning-dashboard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule } from '@angular/material/menu';
import { DeploymentCommunicationIconComponent } from './deployment-communication-icon/deployment-communication-icon.component';
import { NpsIntegrationComponent } from './nps-integration/nps-integration.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    PipeModule,
    AngularSvgIconModule.forRoot(),
    ClickOutsideModule,
    MaterialModule,
    MatDialogModule,
    DirectivesModule,
    CanDeactivateModule,
    DirectivesModule,
    MatDatepickerModule,
    MatMomentDateModule,
    AlertNotificationModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    MatMenuModule,
    MatSnackBarModule
  ],
  declarations: [
    HeaderComponent,
    SideNavComponent,
    VesselFixtureComponent,
    VesselDeploymentsComponent,
    VesselRedesignDeploymentsComponent,
    VesselInformationComponent,
    VesselSpecificationComponent,
    MachineryParticularsComponent,
    SpeedAndConsumptionComponent,
    VesselCommComponent,
    CargoCapacityAndLayoutComponent,
    FileUploadComponent,
    VesselNewVesselSpecificationsComponent,
    VesselNewPlansDocsAndCertsComponent,
    VesselNewCargoCapLayoutComponent,
    VesselNewMachineryParticularsComponent,
    DatePickerComponent,
    NumberSplitterComponent,
    CustomDatePickerComponent,
    AppInsightBaseComponent,
    AuthCallbackComponent,
    CustomMomentDatepickerComponent,
    VesselNewAdditionalInformationComponent,
    UnauthorisedComponent,
    VesselLocationComponent,
    ReloadMessageModalComponent,
    ReleaseNotesComponent,
    ImageModalComponent,
    VesselDeploymentsLinesComponent,
    DryDockPlanningDashboardComponent,
    DeploymentCommunicationIconComponent,
    NpsIntegrationComponent
    /* UploadTcoComponent, */
  ],
  exports: [
    HeaderComponent,
    SideNavComponent,
    VesselFixtureComponent,
    VesselDeploymentsComponent,
    VesselRedesignDeploymentsComponent,
    VesselInformationComponent,
    VesselCommComponent,
    SpeedAndConsumptionComponent,
    MachineryParticularsComponent,
    VesselSpecificationComponent,
    CargoCapacityAndLayoutComponent,
    FileUploadComponent,
    VesselNewVesselSpecificationsComponent,
    VesselNewPlansDocsAndCertsComponent,
    VesselNewCargoCapLayoutComponent,
    VesselNewMachineryParticularsComponent,
    DatePickerComponent,
    NumberSplitterComponent,
    CustomDatePickerComponent,
    AuthCallbackComponent,
    VesselNewAdditionalInformationComponent,
    VesselLocationComponent,
    PipeModule,
    NgxSpinnerModule,
    ReleaseNotesComponent,
    ImageModalComponent,
    VesselDeploymentsLinesComponent,
    DryDockPlanningDashboardComponent,
    DeploymentCommunicationIconComponent
    /*  UploadTcoComponent */
  ],
  providers: [AlertService, { provide: DateAdapter, useClass: CustomMomentUtcAdapter }],
})
export class ComponentsModule {}
