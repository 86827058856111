//
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MY_FORMATS,
  time,
  customMoment,
  convertToLocalIsoString,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { Router } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DataServiceService } from '../../../service-repository/services/data-service.service';
import { CharterDataService } from '../../../charter-request/services/charter-data.service';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
import { CharterRequestService } from '../../../charter-request/services/charter-request.service';

const moment = customMoment;
@Component({
  selector: 'champs-create-dummy',
  templateUrl: './create-dummy.component.html',
  styleUrls: ['./create-dummy.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, CharterRequestService],
})
export class CreateDummyComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  private charterRequestId: any;
  public time = time;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Output() public createUpdateDummy: EventEmitter<any> = new EventEmitter();
  @Output() public show: EventEmitter<any> = new EventEmitter();
  @Input() public charterRequst: any;
  @Input() public position: any;
  @Input() public isCreateDummy: any;
  @Input() public proformaId: any;
  @Input() public datedScheduleId: any;
  @Input() public createDummyFromDate: any;
  @Input() public proformaPortName: any;
  @Input() public proformaHeaderId: any;
  @Input() public proformaPortId: any;
  @Input() public posPortLst: any;
  @Input() public isExtraloaderPosition: any;
  @Input() public cityId: any;
  @Input() public cityName: any;
  @Input() public serviceid: any;
  @Input() public selectedTime: any;
  @Input() public serviceRepo: boolean;
  @Input() public scenarioId: any;
  @Input() public serviceCode: any;
  @Input() public serviceName: any;
  public tradeId: any;
  public startDate: any;
  public endDate: any;
  public charter: any;
  public routerLink: any;
  public requirmentHeader: any;
  public vesselList: any;
  public vessels: any;
  @ViewChild('replaceVessel', { static: false }) public replaceVessel: any;
  public showDetails = false;
  public showMoreDetails = false;
  public editForm: boolean = false;
  public vesselRequirementObj: any;
  public createDummyFrmGrp: UntypedFormGroup;
  public showSubmitRequest: boolean = false;
  public showError: boolean = false;
  public linkText: any;
  public classList: any;
  public isDeploymentUpdateAuth: boolean = false;
  public isDeploymentDeleteAuth: boolean = false;
  public loading: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceRepoService: ServiceRepositoryService,
    public dialog: MatDialog,
    private router: Router,
    private scenarioService: ScenariosService,
    private dataService: DataServiceService,
    private charterDataService: CharterDataService,
    private auth: AuthService,
    private charterRequest: CharterRequestService
  ) {}

  public ngOnInit() {
    this.isDeploymentUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.isDeploymentDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'delete');
    this.serviceRepoService.getClassList().subscribe((res) => {
      this.classList = res;
    });
    this.createDummyRequestFormGroup();
    if (this.isCreateDummy) {
      const serv = this.serviceRepo
        ? this.serviceRepoService.getVesselRequirements(this.proformaId)
        : this.scenarioService.getVesselRequirements(this.proformaId, this.scenarioId);
      serv.subscribe((res: any) => {
        this.vesselRequirementObj = res;
        this.createDummyRequestFormGroup();
      });
    }
    this.dataService.serviceOverviewData.subscribe((data) => {
      this.tradeId = data.tradeId;
    });
  }
  public cancelCreateForm() {
    this.close.emit({
      type: 'close',
    });
  }
  public showEditForm() {
    this.editForm = !this.editForm;
  }
  public replaceWithDummy() {
    this.createDummyFrmGrp.patchValue({
      crStatusID: 1,
    });
    const serv1 = this.serviceRepo
      ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value
        )
      : this.scenarioService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value,
          this.scenarioId
        );
    serv1.subscribe((res: any) => {
      this.createDummyFrmGrp.patchValue({
        crVesselDepID: res === -1 ? null : res,
      });
      const servi = this.serviceRepo
        ? this.serviceRepoService.getVesselRequirements(this.proformaId)
        : this.scenarioService.getVesselRequirements(this.proformaId, this.scenarioId);
      servi.subscribe((resp: any) => {
        this.vesselRequirementObj = resp;
        this.createDummyFrmGrp.patchValue({
          crTEU: this.vesselRequirementObj.totalTeuCap,
          crMTCapacity: this.vesselRequirementObj.totalMTCap,
          crPlugs: this.vesselRequirementObj.plugs,
          crMinSpeed: this.vesselRequirementObj.minSpeed,
          crMaxSpeed: this.vesselRequirementObj.maxSpeed,
          crDraft: this.vesselRequirementObj.draftLimitations,
          crAirDraft: this.vesselRequirementObj.airDraft,
          crIsGear: this.vesselRequirementObj.gears,
          crIsIceClass: this.vesselRequirementObj.iceClass,
          crECAZone: this.vesselRequirementObj.ecaZone,
          crMLTeuCapacity: this.vesselRequirementObj.mlTeuCap,
          crMLMTCapacity: this.vesselRequirementObj.mlmtCap,
          crVesselTeuId: this.vesselRequirementObj.vesselTeuId,
          crNomTeuCapacity: this.vesselRequirementObj.nomTeuCapacity,
          crTonCenCap: this.vesselRequirementObj.tonCenCap,
          crIsECA: this.vesselRequirementObj.isECAZone,
        });
        const serv = this.serviceRepo
          ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
          : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
        serv.subscribe((res1: any) => {
          if (res1) {
            this.close.emit({
              type: 'save',
            });
          }
        });
      });
    });
  }
  public getSegmentNameFromId(id) {
    if (id) {
      const name = this.classList && this.classList.find((item) => item.vesselTeuID === id).vesselTeuName;
      return name;
    }
  }
  public replaceBlanking() {
    this.createDummyFrmGrp.patchValue({
      crStatusID: 5,
    });
    const serv1 = this.serviceRepo
      ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value
        )
      : this.scenarioService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value,
          this.scenarioId
        );
    serv1.subscribe((res: any) => {
      this.createDummyFrmGrp.patchValue({
        crVesselDepID: res === -1 ? null : res,
      });
      const serv = this.serviceRepo
        ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
        : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
      serv.subscribe((res1: any) => {
        if (res1) {
          this.close.emit({
            type: 'save',
          });
        }
      });
    });
  }
  public getInitailData() {
    this.startDate = this.charterRequst.fromDate;
    this.endDate = this.charterRequst.toDate;
    this.charter = this.charterRequst;
    // planner changes
    const servGetRequirmentHeader = this.serviceRepo
      ? this.serviceRepoService.getRequirementHeader(this.charterRequst.charterRequestId)
      : this.scenarioService.getRequirementHeader(this.charterRequst.charterRequestId);
    servGetRequirmentHeader.subscribe((response) => {
      this.requirmentHeader = response;
    });

    const servgetVesselList = this.serviceRepo
      ? this.serviceRepoService.getVesselList(this.serviceid, this.startDate, this.endDate)
      : this.scenarioService.getVesselList(this.serviceid, this.startDate, this.endDate);
    servgetVesselList.subscribe((response: any) => {
      this.vessels = response;
      this.vesselList = response.vesselList;
      this.dialogRef = this.dialog.open(this.replaceVessel, {
        width: '1200px',
        panelClass: 'replace-dialog-container',
        disableClose: true,
      });
    });
    // this.startDate = '2018-10-13T00:00:00';
    // this.endDate = '2019-05-12T00:00:00';
  }
  public replaceVesselForm() {
    this.getInitailData();
  }
  public createDummy() {
    if (!this.createDummyFrmGrp.valid) {
      this.showError = true;
    }
    if (this.createDummyFrmGrp.valid) {
      this.showError = false;
      let schLst = [];
      let portInfoEnd: any;
      let portInfoStart: any;
      let firstFlag: boolean = false;
      let startDate;
      let endDate;

      const fromDate = new Date(
        moment(this.createDummyFrmGrp.value.crPhaseInStartDate).format('YYYY-MM-DD[T]HH:mm:ss').slice(0, 11) +
          this.createDummyFrmGrp.value.frFromTime +
          ':00'
      );
      const toDate = new Date(
        moment(this.createDummyFrmGrp.value.crPhaseOutStartDate).format('YYYY-MM-DD[T]HH:mm:ss').slice(0, 11) +
          this.createDummyFrmGrp.value.frToTime +
          ':00'
      );
      /* const fromDate = new Date(this.createDummyFrmGrp.value.crPhaseInStartDate).toISOString().split('.')[0];
      const toDate = new Date(this.createDummyFrmGrp.value.crPhaseOutStartDate).toISOString().split('.')[0]; */
      for (const lst of this.posPortLst) {
        const schFromDate = lst.lstPositionDto
          ? lst.lstPositionDto.length === 2
            ? lst.lstPositionDto[0].scheduleType === 0
              ? lst.lstPositionDto[0]
              : lst.lstPositionDto[1].scheduleType === 0
              ? lst.lstPositionDto[1]
              : null
            : lst.lstPositionDto[0].scheduleType === 0
            ? lst.lstPositionDto[0]
            : null
          : null;

        const schToDate = lst.lstPositionDto
          ? lst.lstPositionDto.length === 2
            ? lst.lstPositionDto[0].scheduleType === -1
              ? lst.lstPositionDto[0]
              : lst.lstPositionDto[1].scheduleType === -1
              ? lst.lstPositionDto[1]
              : null
            : lst.lstPositionDto[0].scheduleType === -1
            ? lst.lstPositionDto[0]
            : null
          : null;

        if (
          schFromDate
            ? new Date(schFromDate.scheduleDate) >= fromDate && new Date(schFromDate.scheduleDate) <= toDate
            : false
        ) {
          if (lst.isExtraloaderPosition == this.isExtraloaderPosition) {
            schLst.push(schFromDate.scheduleId);
          }
          if (!firstFlag) {
            portInfoStart = lst;
            startDate = schFromDate.scheduleDate;
          }
          firstFlag = true;
          portInfoEnd = lst;
          endDate = schFromDate.scheduleDate;
          if (new Date(schFromDate.scheduleDate).getTime() === toDate.getTime()) {
            break;
          }
        }
        if (
          schToDate ? new Date(schToDate.scheduleDate) >= fromDate && new Date(schToDate.scheduleDate) <= toDate : false
        ) {
          if (lst.isExtraloaderPosition == this.isExtraloaderPosition) {
            schLst.push(schToDate.scheduleId);
          }
          if (!firstFlag) {
            portInfoStart = lst;
            startDate = schToDate.scheduleDate;
          }
          firstFlag = true;
          portInfoEnd = lst;
          endDate = schToDate.scheduleDate;
          if (new Date(schToDate.scheduleDate).getTime() === toDate.getTime()) {
            break;
          }
        }
      }
      /* if (!this.charterRequst) { */
      this.createDummyFrmGrp.patchValue({
        crPhaseInPortID: portInfoStart.cityId,
        crPhaseInPortName: portInfoStart.cityName,
        // crPhaseInStartDate: startDate,
        crPhaseInStartDate: convertToLocalIsoString(fromDate.toISOString()),
        crPhaseOutPortID: portInfoEnd.cityId,
        crPhaseOutPortName: portInfoEnd.cityName,
        crPhaseOutStartDate: convertToLocalIsoString(toDate.toISOString()),
        // crPhaseOutStartDate: endDate
      });
      /* } */
      schLst = Array.from(new Set(schLst));
      const serv1 = this.serviceRepo
        ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
            this.serviceid,
            this.position,
            this.createDummyFrmGrp.get('crPhaseInStartDate').value
          )
        : this.scenarioService.getLastDeploymentOnServiceByPosition(
            this.serviceid,
            this.position,
            this.createDummyFrmGrp.get('crPhaseInStartDate').value,
            this.scenarioId
          );
      serv1.subscribe((res1: any) => {
        this.createDummyFrmGrp.patchValue({
          crVesselDepID: res1 === -1 ? null : res1,
        });
        const servpostCreateUpdateDummy = this.serviceRepo
          ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
          : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
        servpostCreateUpdateDummy.subscribe((res: any) => {
          if (res) {
            this.charterRequestId = res;
            const postData = {
              charterRequestId: this.charterRequestId.charter_id,
              lstSchedules: schLst,
            };
            const servUpdateCharterRequestOnSchedule = this.serviceRepo
              ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(postData)
              : this.scenarioService.postUpdateCharterRequestOnSchedule(postData);
            servUpdateCharterRequestOnSchedule.subscribe((res2: any) => {
              if (res2) {
                this.close.emit({
                  type: 'save',
                });
              }
            });
          }
        });
      });

      /* if (this.charterRequestId) {
        const postData = {
          charterRequestId: this.charterRequestId,
          lstSchedules: schLst
        };
        this.serviceRepoService.postUpdateCharterRequestOnSchedule(postData).subscribe((res1: any) => {
        });
        this.close.emit({
          type: 'save'
        });
      } */
    }
  }
  public closeReplaceVesselForm(event) {
    if (event.type === 'close') {
      this.dialogRef.close();
    }
    if (event.type === 'use') {
      const servuseVessel = this.serviceRepo
        ? this.serviceRepoService.useVessel(event.value)
        : this.scenarioService.useVessel(event.value);
      servuseVessel.subscribe((res) => {
        // delete rest of the services
        // const servdeleteVesselService = this.serviceRepo ? this.serviceRepoService.deleteVesselService(event.delete) : this.scenarioService.deleteVesselService(event.delete);
        // delete service not required to be called for scenario
        if (this.serviceRepo) {
          const servdeleteVesselService = this.serviceRepoService.deleteVesselService(event.delete);
          servdeleteVesselService.subscribe((resp) => {
            // call convert to dummy here
            this.createDummyRequestForReplaceVessel(res, event.value, null, true, false);
            const serv1 = this.serviceRepo
              ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
                  this.serviceid,
                  this.position,
                  this.createDummyFrmGrp.get('crPhaseInStartDate').value
                )
              : this.scenarioService.getLastDeploymentOnServiceByPosition(
                  this.serviceid,
                  this.position,
                  this.createDummyFrmGrp.get('crPhaseInStartDate').value,
                  this.scenarioId
                );
            serv1.subscribe((res1: any) => {
              this.createDummyFrmGrp.patchValue({
                crVesselDepID: res1 === -1 ? null : res1,
              });
              let servpostCreateUpdateDummy = this.serviceRepo
                ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
                : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
              servpostCreateUpdateDummy.subscribe((dummy: any) => {
                if (dummy) {
                  this.charterRequestId = dummy;
                  const postData = {
                    charterRequestId: dummy.charter_id,
                    lstSchedules: event.scheduleIds,
                  };
                  event.directDummy.forEach((element) => {
                    const servgetVesselRequirements = this.serviceRepo
                      ? this.serviceRepoService.getVesselRequirements(element.lstPortDetailsListDto[0].proformaId)
                      : this.scenarioService.getVesselRequirements(
                          element.lstPortDetailsListDto[0].proformaId,
                          this.scenarioId
                        );
                    servgetVesselRequirements.subscribe((response) => {
                      this.createDummyRequestForReplaceVessel(response, element, event.proformaHeaderId, false, true);
                      servpostCreateUpdateDummy = this.serviceRepo
                        ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
                        : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
                      servpostCreateUpdateDummy.subscribe((resp1: any) => {
                        if (resp1) {
                          const data = {
                            charterRequestId: resp1.charter_id,
                            lstSchedules: this.getScheduleIdList(element),
                          };
                          const servpostUpdateCharterRequestOnSchedule = this.serviceRepo
                            ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(data)
                            : this.scenarioService.postUpdateCharterRequestOnSchedule(data);
                          servpostUpdateCharterRequestOnSchedule.subscribe((res2: any) => {});
                        }
                      });
                    });
                  });
                  const servpostUpdateCharterRequestOnSchedule1 = this.serviceRepo
                    ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(postData)
                    : this.scenarioService.postUpdateCharterRequestOnSchedule(postData);
                  servpostUpdateCharterRequestOnSchedule1.subscribe((res3: any) => {
                    this.dialogRef.close();
                    if (res3) {
                      this.close.emit({
                        type: 'save',
                      });
                    }
                  });
                }
              });
            });
          });
        } else {
          this.dialogRef.close();
          this.close.emit({
            type: 'save',
          });
        }
      });
    }
    if (event.type === 'usedirect') {
      this.replaceVesselData(event, false);
    }
    if (event.type === 'useIdle') {
      this.replaceVesselData(event, true);
    }
  }
  public replaceVesselData(event, isIdle) {
    // this.loading = true;
    // this.createDummyRequestForReplaceVessel(event)
    if (isIdle) {
      this.deleteDeployments(event);
    } else {
      const servuseForWithoutDeployment = this.serviceRepo
        ? this.serviceRepoService.useForWithoutDeployment(event.createVesselObj)
        : this.scenarioService.useForWithoutDeployment(event.createVesselObj);
      servuseForWithoutDeployment.subscribe(
        (response) => {
          // this.loading = false;
          if (response) {
            this.dialogRef.close();
            this.close.emit({
              type: 'save',
            });
          }
        },
        (error) => {
          // this.loading = false;
          this.close.emit({
            type: 'popupErrorVessel',
            error: error.error.error,
          });
        }
      );
    }
  }
  public deleteDeployments(event) {
    // Scenario no delete deployment service to be called.
    // const servdeleteVesselService = this.serviceRepo ? this.serviceRepoService.deleteVesselService(event.delete) : this.scenarioService.deleteVesselService(event.delete);
    if (this.serviceRepo) {
      const servdeleteVesselService = this.serviceRepoService.deleteVesselService(event.delete);
      servdeleteVesselService.subscribe((resp) => {
        // below if condition removed ,as code should run for both case-api response true/false
        // if (resp) {
        event.directDummy.forEach((element) => {
          const servgetVesselRequirements = this.serviceRepo
            ? this.serviceRepoService.getVesselRequirements(event.proformaId)
            : this.scenarioService.getVesselRequirements(event.proformaId, this.scenarioId);
          servgetVesselRequirements.subscribe((response) => {
            this.createDummyRequestForReplaceVessel(response, event.charter, event.proformaHeaderId, false, false);
            const serv1 = this.serviceRepo
              ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
                  this.serviceid,
                  this.position,
                  this.createDummyFrmGrp.get('crPhaseInStartDate').value
                )
              : this.scenarioService.getLastDeploymentOnServiceByPosition(
                  this.serviceid,
                  this.position,
                  this.createDummyFrmGrp.get('crPhaseInStartDate').value,
                  this.scenarioId
                );
            serv1.subscribe((res: any) => {
              this.createDummyFrmGrp.patchValue({
                crVesselDepID: res === -1 ? null : res,
              });
              const servpostCreateUpdateDummy = this.serviceRepo
                ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
                : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
              servpostCreateUpdateDummy.subscribe((resp1: any) => {
                if (resp1) {
                  this.charterRequestId = resp1;
                  const data = {
                    charterRequestId: resp1.charter_id,
                    lstSchedules: this.getScheduleIdList(element),
                  };
                  const servpostUpdateCharterRequestOnSchedule = this.serviceRepo
                    ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(data)
                    : this.scenarioService.postUpdateCharterRequestOnSchedule(data);
                  servpostUpdateCharterRequestOnSchedule.subscribe((res1: any) => {});
                }
              });
            });
          });
        });
        // }
      });
    } else {
      event.directDummy.forEach((element) => {
        const servgetVesselRequirements = this.serviceRepo
          ? this.serviceRepoService.getVesselRequirements(event.proformaId)
          : this.scenarioService.getVesselRequirements(event.proformaId, this.scenarioId);
        servgetVesselRequirements.subscribe((response) => {
          this.createDummyRequestForReplaceVessel(response, event.charter, event.proformaHeaderId, false, false);
          const serv1 = this.serviceRepo
            ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
                this.serviceid,
                this.position,
                this.createDummyFrmGrp.get('crPhaseInStartDate').value
              )
            : this.scenarioService.getLastDeploymentOnServiceByPosition(
                this.serviceid,
                this.position,
                this.createDummyFrmGrp.get('crPhaseInStartDate').value,
                this.scenarioId
              );
          serv1.subscribe((res: any) => {
            this.createDummyFrmGrp.patchValue({
              crVesselDepID: res === -1 ? null : res,
            });
            const servpostCreateUpdateDummy = this.serviceRepo
              ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
              : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
            servpostCreateUpdateDummy.subscribe((resp1: any) => {
              if (resp1) {
                this.charterRequestId = resp1;
                const data = {
                  charterRequestId: resp1.charter_id,
                  lstSchedules: this.getScheduleIdList(element),
                };
                const servpostUpdateCharterRequestOnSchedule = this.serviceRepo
                  ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(data)
                  : this.scenarioService.postUpdateCharterRequestOnSchedule(data);
                servpostUpdateCharterRequestOnSchedule.subscribe((res1: any) => {});
              }
            });
          });
        });
      });
    }
  }
  public createRequestPopUp() {
    this.show.emit({
      showCreateRequestPopUp: true,
      charterRequest: this.charterRequst,
    });
  }

  public deleteRequestPopUp() {
    this.show.emit({
      showDeletePopUp: true,
      charterId: this.charterRequst,
    });
  }
  public deleteDummyPopUp() {
    this.show.emit({
      showDeleteDummyPopUp: true,
      charterId: this.charterRequst,
    });
  }
  public revertToDummyPopUp() {
    this.show.emit({
      showRevertToDummy: true,
      charterRequest: this.charterRequst,
    });
  }
  public navigateToRequestPage() {
    this.linkText =
      this.charterRequst.charterRequestStatusId === 1
        ? 'View dummy'
        : this.charterRequst.charterRequestStatusId !== 1 &&
          this.charterRequst.charterRequestStatusId !== 5 &&
          !this.isCreateDummy
        ? 'View request'
        : '';
    if (this.linkText === 'View dummy') {
      /*  this.router.navigate(['/charter-request/' + this.charterRequst.charterRequestId]); */
      this.cancelCreateForm();
      window.open('/charter-request/' + this.charterRequst.charterRequestId, '_blank', 'noreferrer');
    } else if (this.linkText === 'View request') {
      /* this.router.navigate(['/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId]); */
      this.cancelCreateForm();

      // if (this.charterRequst.charterRequestStatusId === 2) {
      //   window.open('/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId, '_blank').focus();
      // } else if (this.charterRequst.charterRequestStatusId === 3 || this.charterRequst.charterRequestStatusId === 4) {
      //   if (this.charterRequst.charterRequestVesselCandidateId) {
      //     window.open('/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId + '/Approval/' + this.charterRequst.charterRequestVesselCandidateId, '_blank').focus();
      //   } else {
      //     window.open('/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId, '_blank').focus();
      //   }
      // } else {
      //   window.open('/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId, '_blank').focus();
      // }

      this.charterRequest.getNegotiatingRequestStage(this.charterRequst.charterRequestId).subscribe((response: any) => {
        if (
          response.charterRequestStage === 3.1 ||
          response.charterRequestStage === 3 ||
          response.charterRequestStage === 2.0
        ) {
          window.open(
            '/charter-request/CandidateVessels/' + this.charterRequst.charterRequestId,
            '_blank',
            'noreferrer'
          );
        }
        if (response.charterRequestStage === 3.2) {
          this.charterRequest.getApprovalData(this.charterRequst.charterRequestId).subscribe((candResponse: any) => {
            const selectedVesselForNegotitation = candResponse.charterRequestCandidateList.candidates.filter(
              (cand) => cand.crvcIsFinalCandidate === true
            );
            const selectedVslIdForNegotitation = selectedVesselForNegotitation[0].vcPKID;
            window.open(
              '/charter-request/CandidateVessels/' +
                this.charterRequst.charterRequestId +
                '/Approval/' +
                selectedVslIdForNegotitation,
              '_blank',
              'noreferrer'
            );
          });
        }
      });
    }
  }

  public vesselLink() {
    window.open('/vessels/' + this.charterRequst.extendsVesselId + '/', '_blank', 'noreferrer');
  }
  private createDummyRequestFormGroup() {
    if (this.charterRequst) {
      this.createDummyFrmGrp = this.formBuilder.group({
        crPKID: this.charterRequst.charterRequestId,

        crPhaseInPortID: this.charterRequst.phaseInPortID,

        crPhaseInPortName: this.charterRequst.phaseInPortName,

        crStatusID: this.charterRequst.charterRequestStatusId,

        crPhaseInStartDate: this.charterRequst.fromDate,

        crPhaseOutPortID: this.charterRequst.phaseOutPortID,

        crPhaseOutPortName: this.charterRequst.phaseOutPortName,

        crPhaseOutStartDate: this.charterRequst.toDate,

        crClassID: this.charterRequst.classId,

        crTradeID: this.charterRequst.tradeID,
        /*  'nomTeuCapacity': this.formBuilder.control(this.charterRequst.nomTeuCapacity, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]), */
        /*         'tonCenCap': this.formBuilder.control(this.charterRequst.tonCenCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]), */

        crTEU: this.formBuilder.control(this.charterRequst.teu, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crMTCapacity: this.formBuilder.control(this.charterRequst.mtCapacity, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crPlugs: this.formBuilder.control(this.charterRequst.plugs, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crMinSpeed: this.formBuilder.control(this.charterRequst.minSpeed, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crMaxSpeed: this.formBuilder.control(this.charterRequst.maxSpeed, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crDraft: this.formBuilder.control(this.charterRequst.draftLimitations, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crAirDraft: this.formBuilder.control(this.charterRequst.airDraft, [
          Validators.min(0),
          Validators.max(999),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crIsGear: this.charterRequst.gear,

        crIsIceClass: this.charterRequst.iceClass,
        crECAZone: this.formBuilder.control(this.charterRequst.ecaZone, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,2})?$')),
        ]),

        crComments: this.charterRequst.requestComments,

        crVesselDepID: this.charterRequst.vesselDepID,

        crReason: this.charterRequst.reason,

        crForecastTEU: this.charterRequst.forecastTEU,

        crForecastTEURate: this.charterRequst.forecastTEURate,

        crHFOPrice: this.charterRequst.hfoPrice,

        crLSHFOPrice: this.charterRequst.lshfoPrice,

        crMDOPrice: this.charterRequst.mdoPrice,

        crLSMDOPrice: this.charterRequst.lsmdoPrice,

        crMLTeuCapacity: this.charterRequst.mlTeuCapacity,

        crMLMTCapacity: this.charterRequst.mlmtCapacity,

        crProformaHeaderId: this.charterRequst.charterProformaHeaderId,

        crVesselTeuId: this.charterRequst.vesselTeuId,

        crNomTeuCapacity: this.formBuilder.control(this.charterRequst.nomTeuCapacity, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crTonCenCap: this.formBuilder.control(this.charterRequst.tonCenCap, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crIsECA: true,
        crExtendsVesselId: this.charterRequst && this.charterRequst.extendsVesselId,
        crPosition: this.position,
        crServiceId: this.serviceid,
        crServiceCode: this.serviceCode,
        crServiceName: this.serviceName,
        frFromTime: this.selectedTime ? this.selectedTime.slice(0, 5) : null,
        frToTime: this.charterRequst && this.charterRequst.toDate && this.charterRequst.toDate.slice(11, 16),
        crIsExtraPosition: this.isExtraloaderPosition,
      });
    } else {
      this.createDummyFrmGrp = this.formBuilder.group({
        crPKID: 0,

        crPhaseInPortID: this.cityId,

        crPhaseInPortName: this.cityName,

        crStatusID: 1,

        crPhaseInStartDate: this.createDummyFromDate,

        crPhaseOutPortID: this.cityId,

        crPhaseOutPortName: this.cityName,

        crPhaseOutStartDate: null,

        crClassID: null,

        // 'crTradeID': 7,
        crTradeID: this.tradeId,
        /* 'nomTeuCapacity': this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]), */
        /*         'tonCenCap': this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]), */
        crTEU: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.totalTeuCap : null, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crMTCapacity: this.formBuilder.control(
          this.vesselRequirementObj ? this.vesselRequirementObj.totalMTCap : null,
          [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]
        ),

        crPlugs: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.plugs : null, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crMinSpeed: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.minSpeed : null, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crMaxSpeed: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.maxSpeed : null, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),

        crDraft: this.formBuilder.control(
          this.vesselRequirementObj ? this.vesselRequirementObj.draftLimitations : null,
          [Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$'))]
        ),

        crAirDraft: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.airDraft : null, [
          Validators.min(0),
          Validators.max(999),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,2})?$')),
        ]),

        crIsGear: this.vesselRequirementObj ? this.vesselRequirementObj.gears : null,

        crIsIceClass: this.vesselRequirementObj ? this.vesselRequirementObj.iceClass : null,

        crComments: '',

        crVesselDepID: null,

        crReason: '',

        crForecastTEU: null,

        crForecastTEURate: null,

        crHFOPrice: null,

        crLSHFOPrice: null,

        crMDOPrice: null,

        crLSMDOPrice: null,

        crMLTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlTeuCap : null,

        crMLMTCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlmtCap : null,

        crProformaHeaderId: this.proformaHeaderId,

        crVesselTeuId: this.vesselRequirementObj ? this.vesselRequirementObj.vesselTeuId : '',

        crNomTeuCapacity: this.formBuilder.control(
          this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null,
          [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]
        ),

        crTonCenCap: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null, [
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),

        crECAZone: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.ecaZone : null, [
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,2})?$')),
        ]),

        crIsECA: this.vesselRequirementObj ? this.vesselRequirementObj.isECAZone : null,
        crExtendsVesselId: null,
        crPosition: this.position,
        crServiceId: this.serviceid,
        crServiceCode: this.serviceCode,
        crServiceName: this.serviceName,
        frFromTime: this.selectedTime ? this.selectedTime.slice(0, 5) : null,
        frToTime: '00:00',
        crIsExtraPosition: this.isExtraloaderPosition,
      });
    }
  }

  private createDummyRequestForReplaceVessel(dummyDetails, eventValue, headerid, isvesseldto, isPrevDep) {
    const length = this.serviceRepo
      ? !isvesseldto
        ? eventValue.lstPortDetailsListDto.length
        : 0
      : !isvesseldto
      ? eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto.length
      : 0;
    this.createDummyFrmGrp = this.formBuilder.group({
      crPKID: 0,

      crPhaseInPortID: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortStartId
          : isPrevDep
          ? eventValue.lstPortDetailsListDto[0].deploymentPortId
          : eventValue.phaseInPortID
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortStartId
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[0].deploymentPortId
        : eventValue.cutoffAndConvertToDummyDto.phaseInPortID, // ??

      crPhaseInPortName: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortStartName
          : isPrevDep
          ? eventValue.lstPortDetailsListDto[0].deploymentPortName
          : eventValue.phaseInPortName
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortStartName
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[0].deploymentPortName
        : eventValue.cutoffAndConvertToDummyDto.phaseInPortName, // ??

      crStatusID: 1,

      crPhaseInStartDate: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortBeginDate
          : isPrevDep
          ? this.calculateArrDep(
              eventValue.lstPortDetailsListDto[0].lstArrivalDepartureDto[0],
              eventValue.lstPortDetailsListDto[0].lstArrivalDepartureDto[1],
              -1
            )
          : eventValue.fromDate
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortBeginDate
        : isPrevDep
        ? this.calculateArrDep(
            eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[0].lstArrivalDepartureDto[0],
            eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[0].lstArrivalDepartureDto[1],
            -1
          )
        : eventValue.cutoffAndConvertToDummyDto.fromDate, // ??

      crPhaseOutPortID: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortEndID
          : isPrevDep
          ? eventValue.lstPortDetailsListDto[length - 1].deploymentPortId
          : eventValue.phaseOutPortID
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortEndID
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[length - 1].deploymentPortId
        : eventValue.cutoffAndConvertToDummyDto.phaseOutPortID, // ??

      crPhaseOutPortName: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortEndName
          : isPrevDep
          ? eventValue.lstPortDetailsListDto[length - 1].deploymentPortName
          : eventValue.phaseOutPortName
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortEndName
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[length - 1].deploymentPortName
        : eventValue.cutoffAndConvertToDummyDto.phaseOutPortName, // ??

      crPhaseOutStartDate: this.serviceRepo
        ? isvesseldto
          ? eventValue.vesselDeploymentDto.cutPortEndDate
          : isPrevDep
          ? this.calculateArrDep(
              eventValue.lstPortDetailsListDto[length - 1].lstArrivalDepartureDto[0],
              eventValue.lstPortDetailsListDto[length - 1].lstArrivalDepartureDto[1],
              0
            )
          : eventValue.toDate
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.vesselDeploymentDto.cutPortEndDate
        : isPrevDep
        ? this.calculateArrDep(
            eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[length - 1].lstArrivalDepartureDto[0],
            eventValue.cutoffAndConvertToDummyDto.lstPortDetailsListDto[length - 1].lstArrivalDepartureDto[1],
            0
          )
        : eventValue.cutoffAndConvertToDummyDto.toDate, // ??

      crClassID: null,

      // 'crTradeID': this.tradeId,

      crTradeID: this.serviceRepo ? eventValue.tradeId : eventValue.cutoffAndConvertToDummyDto.tradeId, // ??
      /* 'nomTeuCapacity': dummyDetails.nomTeuCapacity,
      'tonCenCap': null, /// ?? */
      crTEU: dummyDetails ? dummyDetails.mlTeuCap : '', // ??

      crMTCapacity: dummyDetails ? dummyDetails.mlmtCap : '',

      crPlugs: dummyDetails ? dummyDetails.plugs : '',

      crMinSpeed: dummyDetails ? dummyDetails.minSpeed : '',

      crMaxSpeed: dummyDetails ? dummyDetails.maxSpeed : '',

      crDraft: dummyDetails ? dummyDetails.draftLimitations : '', // ??

      crAirDraft: dummyDetails ? dummyDetails.airDraft : '',

      crIsGear: dummyDetails ? dummyDetails.gears : '',

      crIsIceClass: dummyDetails ? dummyDetails.iceClass : '',

      crComments: '',

      crVesselDepID: null,

      crReason: '',

      crForecastTEU: null,

      crForecastTEURate: null,

      crHFOPrice: null,

      crLSHFOPrice: null,

      crMDOPrice: null,

      crLSMDOPrice: null,

      crMLTeuCapacity: dummyDetails ? dummyDetails.mlTeuCap : '',

      crMLMTCapacity: dummyDetails ? dummyDetails.mlmtCap : '',

      crProformaHeaderId: this.serviceRepo
        ? isvesseldto
          ? eventValue.proformaHeaderId
          : headerid
        : isvesseldto
        ? eventValue.cutoffAndConvertToDummyDto.proformaHeaderId
        : headerid,

      crVesselTeuId: dummyDetails ? dummyDetails.vesselTeuId : '', // ??,,

      crNomTeuCapacity: dummyDetails ? dummyDetails.nomTeuCapacity : '',

      crTonCenCap: dummyDetails ? dummyDetails.tonCenCap : '',

      crECAZone: dummyDetails ? dummyDetails.ecaZone : '',

      crIsECA: dummyDetails ? dummyDetails.isECAZone : null,
      crExtendsVesselId: null,

      crPosition: this.serviceRepo
        ? isPrevDep
          ? eventValue.servicePosition
          : this.position
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.servicePosition
        : this.position,
      crServiceId: this.serviceRepo
        ? isPrevDep
          ? eventValue.serviceId
          : this.serviceid
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.serviceId
        : this.serviceid,
      crServiceCode: this.serviceRepo
        ? isPrevDep
          ? eventValue.serviceCode
          : this.serviceCode
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.serviceCode
        : this.serviceCode,
      crServiceName: this.serviceRepo
        ? isPrevDep
          ? eventValue.serviceName
          : this.serviceName
        : isPrevDep
        ? eventValue.cutoffAndConvertToDummyDto.serviceName
        : this.serviceName,
    });
  }

  private getScheduleIdList(service) {
    const scheduleIds = [];
    const ports = service.lstPortDetailsListDto;
    for (let i = 0; i < ports.length; i++) {
      if (i === ports.length - 1) {
        scheduleIds.push(
          ports[i].lstArrivalDepartureDto[0].movementType === 0
            ? ports[i].lstArrivalDepartureDto[0].scheduleId
            : ports[i].lstArrivalDepartureDto[1].scheduleId
        );
      } else if (i === 0) {
        scheduleIds.push(
          ports[i].lstArrivalDepartureDto[0].movementType === -1
            ? ports[i].lstArrivalDepartureDto[0].scheduleId
            : ports[i].lstArrivalDepartureDto[1].scheduleId
        );
      } else {
        scheduleIds.push(ports[i].lstArrivalDepartureDto[0].scheduleId);
        scheduleIds.push(ports[i].lstArrivalDepartureDto[1].scheduleId);
      }
    }
    return scheduleIds;
  }
  private calculateArrDep(portObj1, portObj2, type) {
    if (portObj1 && type === portObj1.movementType) {
      return portObj1.portArrDepDate;
    }
    if (portObj2 && type === portObj2.movementType) {
      return portObj2.portArrDepDate;
    }
  }
}
