//

import { Action } from '@ngrx/store';

export const PUT_VESSEL_QUESTIONNAIRE_DATA = 'PUT_VESSEL_QUESTIONNAIRE_DATA';

export class PutVesselQuestionnaires implements Action {
  public readonly type = PUT_VESSEL_QUESTIONNAIRE_DATA;
  constructor(
    public payload: any
  ) {}
}

export type VesselQuestionnaireActions = PutVesselQuestionnaires;
