// edit and view carg, capacity and layout
import {
  Component,
  OnInit,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert/alert.service';
@Component({
  selector: 'champs-cargo-capacity-and-layout',
  templateUrl: './cargo-capacity-and-layout.component.html',
  styleUrls: ['./cargo-capacity-and-layout.component.scss'],
})
export class CargoCapacityAndLayoutComponent implements OnInit, AfterViewChecked, OnChanges {
  private generalFormObject: any;
  private cranesFormObject: any;
  private lashingFormObject: any;
  private reeferDataFormObject: any;
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  @Input() public values: any;
  public vesselTeuTypes$: Observable<any>;

  @Output() public update: EventEmitter<any> = new EventEmitter();

  public generalFieldError: boolean;
  public reeferFieldError: boolean;
  public cranesFieldError: boolean;
  public generalViewMode: boolean = true;
  public generalFrmGrp: UntypedFormGroup;
  public cranesViewMode: boolean = true;
  public cranesFrmGrp: UntypedFormGroup;

  public lashingViewMode: boolean = true;
  public lashingFrmGrp: UntypedFormGroup;

  public reeferDataViewMode: boolean = true;
  public reeferDataFrmGrp: UntypedFormGroup;

  public generalFormData: any;
  public cranesFormData: any;
  public lashingFormData: any;
  public reeferDataFormData: any;
  public isVsCarCapUpdateAuth: boolean;
  public vesselType: boolean = true;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public vesselDropdowns: VesselDropdownsService,
    private auth: AuthService,
    private alertService: AlertService
  ) {
    this.vesselTeuTypes$ = this.vesselDropdowns.getVesselTypes();
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsCarCapUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.generalFormObject = {
      vslTeuNominal: this.formBuilder.control(this.values.vslTeuNominal ? this.values.vslTeuNominal : 0, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslTonCenCap: this.formBuilder.control(this.values.vslTonCenCap ? this.values.vslTonCenCap : 0, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslTeuActual: this.formBuilder.control(this.values.vslTeuActual ? this.values.vslTeuActual : 0, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslDeckRussianStowage: this.values.vslDeckRussianStowage ? this.values.vslDeckRussianStowage : false,
    };
    this.generalFrmGrp = this.formBuilder.group(this.generalFormObject);

    this.cranesFormObject = {
      vslCargoCraneInstalled: this.values.vslCargoCraneInstalled ? this.values.vslCargoCraneInstalled : false,
      vslNoOfCranes: [
        this.values.vslNoOfCranes ? this.values.vslNoOfCranes : null,
        [Validators.pattern(new RegExp('^[0-9]+$')), Validators.min(1), Validators.max(5)],
      ],
      vslCapacityCrane: [
        this.values.vslCapacityCrane ? this.values.vslCapacityCrane : null,
        [Validators.min(1), Validators.max(50)],
      ],
    };
    this.cranesFrmGrp = this.formBuilder.group(this.cranesFormObject);

    this.lashingFormObject = {
      vslFullyFittedLashing: this.values.vslFullyFittedLashing ? this.values.vslFullyFittedLashing : false,
      vslAutomaticTwistLock: this.values.vslAutomaticTwistLock ? this.values.vslAutomaticTwistLock : false,
    };
    this.lashingFrmGrp = this.formBuilder.group(this.lashingFormObject);

    this.reeferDataFormObject = {
      vslPlugsUnderDeck: [this.values.vslPlugsUnderDeck, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslPlugsInHold: [this.values.vslPlugsInHold, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslReeferPlugsIAtSea: [this.values.vslReeferPlugsIAtSea, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslReeferPlugsDuringPort: [this.values.vslReeferPlugsDuringPort, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslReeferPlugsDuringManoeuvring: [
        this.values.vslReeferPlugsDuringManoeuvring,
        [Validators.pattern(new RegExp('^[0-9]+$'))],
      ],
      vslVentPerReefer: this.values.vslVentPerReefer,
      vslVentPerCargo: this.values.vslVentPerCargo,
      vslCapacity40Reefer: [this.values.vslCapacity40Reefer, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslUnderDesk40Reefer: [this.values.vslUnderDesk40Reefer, [Validators.pattern(new RegExp('^[0-9]+$'))]],
      vslCapacity44Reefer: [this.values.vslCapacity44Reefer, [Validators.pattern(new RegExp('^[0-9]+$'))]],
    };
    this.reeferDataFrmGrp = this.formBuilder.group(this.reeferDataFormObject);

    this.alertService.sharedVesselType.subscribe((res) => {
      if (res) {
        this.vesselType = res === 'VSA' ? false : true;
      }
    });
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (changes.values) {
      this.values = changes.values.currentValue;
    }
  }

  /**
   * after view checked life-cycle hook
   */
  public ngAfterViewChecked() {
    this.generalFrmGrp.valueChanges.subscribe((values) => {
      this.generalFormData = values;
    });

    this.cranesFrmGrp.valueChanges.subscribe((values) => {
      this.cranesFormData = {
        vslCargoCraneInstalled: values.vslCargoCraneInstalled,
        vslCapacityCrane: values.vslCargoCraneInstalled ? values.vslCapacityCrane : null,
        vslNoOfCranes: values.vslCargoCraneInstalled ? values.vslNoOfCranes : null,
      };
    });

    this.lashingFrmGrp.valueChanges.subscribe((values) => {
      this.lashingFormData = values;
    });

    this.reeferDataFrmGrp.valueChanges.subscribe((values) => {
      this.reeferDataFormData = values;
    });
  }
  public getVesselTEUId() {
    this.vesselTeuTypes$.subscribe((data) => {
      this.vesselDropdowns.vesselTeuID = '';
      const x = { ...this.generalFormData };

      this.vesselDropdowns.vesselTeuID =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID !==
          undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID
          : null;
    });
  }
  public generalCancel() {
    this.generalViewMode = !this.generalViewMode;
    this.generalFrmGrp = this.formBuilder.group(this.generalFormObject);
  }
  public generalSave() {
    if (this.generalFrmGrp.invalid) {
      this.generalFieldError = true;
      let requiredError = false;
      Object.keys(this.generalFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    this.generalViewMode = true;
    if (this.generalFrmGrp.dirty) {
      this.generalFormObject = {
        vslTeuNominal: this.generalFrmGrp.value.vslTeuNominal ? this.generalFrmGrp.value.vslTeuNominal : 0,
        vslTonCenCap: this.generalFrmGrp.value.vslTonCenCap ? this.generalFrmGrp.value.vslTonCenCap : 0,
        vslTeuActual: this.generalFrmGrp.value.vslTeuActual ? this.generalFrmGrp.value.vslTeuActual : 0,
        vslDeckRussianStowage: this.generalFrmGrp.value.vslDeckRussianStowage
          ? this.generalFrmGrp.value.vslDeckRussianStowage
          : false,
      };
      this.generalFrmGrp = this.formBuilder.group(this.generalFormObject);
      this.getVesselTEUId();

      this.update.emit({
        type: 'UpdateCargoGen',
        value: { ...this.generalFormData, vslVesselTeu: this.vesselDropdowns.vesselTeuID },
      });
    }
  }
  public cranesCancel() {
    this.cranesViewMode = true;
  }
  public cranesSave() {
    if (this.cranesFrmGrp.invalid && this.cranesFrmGrp.get('vslCargoCraneInstalled').value) {
      this.cranesFieldError = true;
      return;
    }
    this.cranesViewMode = true;
    if (this.cranesFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateCargoCrane',
        value: this.cranesFormData,
      });
    }
  }

  public lashingCancel() {
    this.lashingViewMode = true;
  }
  public lashingSave() {
    this.lashingViewMode = true;
    if (this.lashingFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateCargoLashing',
        value: this.lashingFormData,
      });
    }
  }

  public reeferDataCancel() {
    this.reeferDataViewMode = true;
  }
  public reeferDataSave() {
    if (this.reeferDataFrmGrp.invalid) {
      this.reeferFieldError = true;
      let requiredError = false;
      Object.keys(this.generalFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    this.reeferDataViewMode = true;
    if (this.reeferDataFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateCargoReefer',
        value: this.reeferDataFormData,
      });
    }
  }
  public updateCraneInstalledChecked(option, event) {
    if (!event.checked) {
      this.cranesFrmGrp.get('vslNoOfCranes').setValue(null);
      this.cranesFrmGrp.get('vslCapacityCrane').setValue(null);
    }
  }
}
