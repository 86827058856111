//

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PUT_DROPDOWN_CONTENT, DropdownContentActions } from '../actions/dropdown-content.actions';

/** @docsNotRequired */
export interface State {
  [key: string]: any;
}

export const initialState: State = {
};

/**
 * Reducer for form dropdowns content actions
 * @param {State} state - The current state
 * @param {VesselQuestionnaireActions} action - The header content action
 */
export function reducer(state = initialState, action: DropdownContentActions): State {
  switch (action.type) {

    case PUT_DROPDOWN_CONTENT: {
      return {
        ...state,
        [action.url]: action.payload,
      };
    }
    default:
      return state;
  }
}

const getDropdownsState = createFeatureSelector<any>('dropdowns');

export const getDropdownContent = (url: string) => createSelector(
  getDropdownsState,
  (state: any) => state ? state[url] : undefined
);
