// Angular
import { Injectable } from '@angular/core';

// Rxjs
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageModalService {

  private image: Subject<string> = new BehaviorSubject<string>(null);

  public getImage() {
    return this.image.asObservable();
  }

  public showModal(img: string) {
    this.image.next(img);
  }
}
