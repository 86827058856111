// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Services
import { FeatureToggleService } from '../../core/services/feature-toggle/feature-toggle.service';
@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  constructor(private ft: FeatureToggleService) { }

  public transform(value = '') {
    const featureToggles = JSON.parse(localStorage['cachedFeatureToggles'] || '[]');
    let isFtEnabled = false;
    if (featureToggles) {
      featureToggles.forEach((item) => {
        if (value === item.ftName) {
          isFtEnabled = item.ftValue;
        }
      });
      return isFtEnabled;
    }
  }
}
