// color config service
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ColorConfigService {

  /** for score */
  public scoreColorConfig: any = {};
  public warRiskAlertColorConfig: any = {};
  public charterRequestDatesColorConfig: any = {};
  public charterRequestStatusColorConfig: any = {};
  public charterRequestRatesColorConfig: any = {};
  public noticeDueDateColorConfig: any = {};

  constructor(private http: HttpClient) { }

  /** api for key value table */
  public getColorConfigurations() {
    return this.http.get(environment.baseUrl + '/api/Administration/ColorConfiguration');
  }

  public getColorConfigByGroupName(name) {
    return this.http.get(environment.baseUrl + '/api/Administration/GetColorByGroup/' + name);
  }

  /** for color coding vessel score all over the application */
  public getColorConfigForScore() {
    return this.getColorConfigByGroupName('Scores - Vessel')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '0'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );

          // highest value is 5
          this.scoreColorConfig.highValueScore = values[2];
          this.scoreColorConfig.mediumValueScore = values[1];
          this.scoreColorConfig.lowValueScore = values[0];

          this.scoreColorConfig.highValueColorScore = names[2].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.scoreColorConfig.mediumValueColorScore = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          this.scoreColorConfig.lowValueColorScore = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of score and corresponding colors
          return this.scoreColorConfig;
        }
      ));
  }

  /** color coding for charter request table */
  public getColorConfigForCharterRequestDates() {
    return this.getColorConfigByGroupName('Charter Request - Date')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '0'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );

          // highest value is 5
          this.charterRequestDatesColorConfig.higherValue = values[3];
          this.charterRequestDatesColorConfig.highValue = values[2];
          this.charterRequestDatesColorConfig.mediumValue = values[1];
          this.charterRequestDatesColorConfig.lowValue = values[0];

          this.charterRequestDatesColorConfig.higherValueColor = names[3].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.charterRequestDatesColorConfig.highValueColor = names[2].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.charterRequestDatesColorConfig.mediumValueColor = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          this.charterRequestDatesColorConfig.lowValueColor = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of date and corresponding colors
          return this.charterRequestDatesColorConfig;
        }
      ));
  }

  public getColorConfigForCharterRequestStatus() {
    return this.getColorConfigByGroupName('Charter Request - Status')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '0'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );

          // highest value is 5
          this.charterRequestStatusColorConfig.higherValue = values[3];
          this.charterRequestStatusColorConfig.highValue = values[2];
          this.charterRequestStatusColorConfig.mediumValue = values[1];
          this.charterRequestStatusColorConfig.lowValue = values[0];

          this.charterRequestStatusColorConfig.higherValueColor = names[3].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.charterRequestStatusColorConfig.highValueColor = names[2].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.charterRequestStatusColorConfig.mediumValueColor = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          this.charterRequestStatusColorConfig.lowValueColor = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of date and corresponding colors
          return this.charterRequestStatusColorConfig;
        }
      ));
  }

  public getColorConfigForCharterRequestRates() {
    return this.getColorConfigByGroupName('Charter Request - Rate')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '-1'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );


          this.charterRequestRatesColorConfig.mediumValue = values[1];
          this.charterRequestRatesColorConfig.lowValue = values[0];
          this.charterRequestRatesColorConfig.mediumValueColor = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          this.charterRequestRatesColorConfig.lowValueColor = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of date and corresponding colors
          return this.charterRequestRatesColorConfig;
        }
      ));
  }

  /** color coding for notices */
  public getColorConfigForNoticesDueIn() {
    return this.getColorConfigByGroupName('Notice - Due Date')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '0'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );

          const colorConfig: any = {};
          // highest value is 5
          colorConfig.highValueScore = values[2];
          colorConfig.mediumValueScore = values[1];
          colorConfig.lowValueScore = values[0];

          colorConfig.highValueColorScore = names[2].substring(names[2].indexOf('.') + 1).toLowerCase();
          colorConfig.mediumValueColorScore = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          colorConfig.lowValueColorScore = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of score and corresponding colors
          return colorConfig;
        }
      ));
  }

  /** for color coding war risk alerts dashboard */
  public getColorConfigForWarRiskAlert() {
    return this.getColorConfigByGroupName('Alert - War Risk')
      .pipe(map(
        colorConfigList => {
          const names = [];
          const values = [];
          const keyValues = colorConfigList[0].keyValues;
          const namesValuesInStringForm = keyValues.map(i => {
            if (i.kvValue === 'default') {
              return {
                'kvName': i.kvName,
                'kvValue': '0'
              };
            } else {
              return {
                'kvName': i.kvName,
                'kvValue': i.kvValue
              };
            }
          });

          const namesValues = namesValuesInStringForm.map(i => {
            return {
              'kvName': i.kvName,
              'kvValue': +i.kvValue
            };
          });

          namesValues.sort((a, b) => a.kvValue - b.kvValue);
          namesValues.forEach(i => {
            names.push(i.kvName);
            values.push(i.kvValue);
          }
          );

          // highest value is 5
          this.warRiskAlertColorConfig.highValueScore = values[2];
          this.warRiskAlertColorConfig.mediumValueScore = values[1];
          this.warRiskAlertColorConfig.lowValueScore = values[0];

          this.warRiskAlertColorConfig.highValueColorScore = names[2].substring(names[2].indexOf('.') + 1).toLowerCase();
          this.warRiskAlertColorConfig.mediumValueColorScore = names[1].substring(names[1].indexOf('.') + 1).toLowerCase();
          this.warRiskAlertColorConfig.lowValueColorScore = names[0].substring(names[0].indexOf('.') + 1).toLowerCase();

          // this object has all the six values of score and corresponding colors
          return this.warRiskAlertColorConfig;
        }
      ));
  }

}
