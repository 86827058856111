//

import { Component, OnInit, HostBinding } from '@angular/core';
import { AlertService, Alert, AlertType } from 'src/app/core/services/alert/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'champs-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss']
})
export class AlertNotificationComponent implements OnInit {

  private interval: any;
  public alerts: Alert[] = [];
  @HostBinding('class.show') public showMessage: boolean = false;

  constructor(
    private alertService: AlertService,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.alertService.getAlert()
      .subscribe((alerts: Array<Alert>) => {

        this.alerts = alerts;
        this.showMessage = this.alerts && this.alerts.length > 0 ? true : false;
      });
  }

  public removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
    this.showMessage = this.alerts.length > 0 ? true : false;
  }
  public showFullMessage(alert: Alert) {
    alert.detailButton = 'Hide';
  }
  public hideFullMessage(alert: Alert) {
    alert.detailButton = 'Show';
  }

  public cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'm-success';
      case AlertType.Error:
        return 'm-error';
      case AlertType.Info:
        return 'm-info';
      case AlertType.Warning:
        return 'm-warn';
    }
  }

}
