//
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDummyComponent } from './component/create-dummy/create-dummy.component';
import { ReplaceVesselComponent } from './component/replace-vessel/replace-vessel.component';
import { MaterialModule } from '../../material/material.module';
import { DirectivesModule } from '../../directives/directives.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AlertNotificationModule } from '../alert-notification/alert-notification.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { VesselDeploymentComponent } from './component/vessel-deployment/vessel-deployment.component';
import { RouterModule } from '@angular/router';
import { VisibleDateRangeComponent } from './component/visible-date-range/visible-date-range.component';
import { ExtendToDummyComponent } from './component/extend-to-dummy/extend-to-dummy.component';
import { OmitPortComponent } from './component/omit-port/omit-port.component';
import { SubmitRequestComponent } from './component/submit-request/submit-request.component';
import { ScheduleDatingComponent } from './component/schedule-dating/schedule-dating.component';
import { VesselRequirementsComponent } from './component/vessel-requirements/vessel-requirements.component';
import { ScheduleOverviewPlannerDetailsComponent } from './component/schedule-overview-planner-details/schedule-overview-planner-details.component';
import { ScheduleOverviewServiceComponent } from './component/schedule-overview-service/schedule-overview-service.component';
import { ViewTcoComponent } from './component/view-tco/view-tco.component';
import { CreateTcoComponent } from './component/create-tco/create-tco.component';
import { OverviewCurrentRotationComponent } from './component/overview-current-rotation/overview-current-rotation.component';
import { OverviewScheduleDetailComponent } from './component/overview-schedule-detail/overview-schedule-detail.component';
import { ComponentsModule } from '../../components/components.module';
import { UploadTcoComponent } from './component/upload-tco/upload-tco.component';
import { DeploymentComponent } from './component/deployment/deployment.component';
import { RedeliverVesselComponent } from './component/redeliver-vessel/redeliver-vessel.component';
import { ScheduleHeaderComponent } from './component/schedule-header/schedule-header.component';
import { ScenarioOverviewHeaderComponent } from './component/scenario-overview-header/scenario-overview-header.component';
import { ExtraLoaderComponent } from './component/extra-loader/extra-loader.component';
import { FloatingDeploymentBlockComponent } from './component/floating-deployment-block/floating-deployment-block.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DirectivesModule,
    AngularSvgIconModule.forRoot(),
    NgxMatSelectSearchModule,
    NgxDatatableModule,
    AlertNotificationModule,
    RouterModule,
    ClickOutsideModule,
    ComponentsModule
  ],
  declarations: [CreateDummyComponent, ReplaceVesselComponent,
    VesselDeploymentComponent, VisibleDateRangeComponent, ExtendToDummyComponent, OmitPortComponent,
     SubmitRequestComponent, ScheduleDatingComponent, VesselRequirementsComponent,
      ScheduleOverviewPlannerDetailsComponent, ScheduleOverviewServiceComponent, ViewTcoComponent,
       CreateTcoComponent, OverviewCurrentRotationComponent, OverviewScheduleDetailComponent,
       UploadTcoComponent, DeploymentComponent, RedeliverVesselComponent, ScheduleHeaderComponent, ScenarioOverviewHeaderComponent, ExtraLoaderComponent, FloatingDeploymentBlockComponent],
  exports: [
    CreateDummyComponent,
    ReplaceVesselComponent,
    VesselDeploymentComponent,
    VisibleDateRangeComponent,
    OmitPortComponent,
    SubmitRequestComponent,
    ExtendToDummyComponent,
    ScheduleDatingComponent,
    VesselRequirementsComponent,
    ScheduleOverviewPlannerDetailsComponent,
    ScheduleOverviewServiceComponent,
    ViewTcoComponent,
    CreateTcoComponent,
    OverviewCurrentRotationComponent,
    OverviewScheduleDetailComponent,
    UploadTcoComponent,
    DeploymentComponent,
    RedeliverVesselComponent,
    ScheduleHeaderComponent,
    ScenarioOverviewHeaderComponent
  ],
})
export class CommonPlannerModule { }
