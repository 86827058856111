import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { VesselsService } from '../../core/services/vessels/vessels.service';
import 'here-js-api/scripts/mapsjs-core';
import 'here-js-api/scripts/mapsjs-service';
import 'here-js-api/scripts/mapsjs-ui';
import 'here-js-api/scripts/mapsjs-mapevents';
import 'here-js-api/scripts/mapsjs-clustering';
import { AlertService } from '../../core/services/alert/alert.service';

declare var H: any;

@Component({
  selector: 'champs-vessel-location',
  templateUrl: './vessel-location.component.html',
  styleUrls: ['./vessel-location.component.scss'],
})
export class VesselLocationComponent implements OnInit {
  private platform: any;
  @Input() public vesselDtl?: any = {};
  @ViewChild('map', { static: false })
  public mapElement: ElementRef;
  public vesselMapDtls: any;
  public imoNo: any;
  public noLocationError: boolean = false;
  public errorMsg: any;
  public getLocation: boolean = false;
  public showExpandLocation: boolean = false;
  constructor(private vesselService: VesselsService, private alertService: AlertService) {
    this.platform = new H.service.Platform({
      app_id: '2itQehoXHthlcPYfacOf',
      app_code: '0wwyLqFgp1V9GtKmNq7qOg',
    });
  }

  public ngOnInit() {}
  public showLocationDiv() {
    this.getLocation = true;
    this.showExpandLocation = true;
    this.alertService.clear();
    this.vesselService.getVesselMapPosition(this.vesselDtl.vslIMONumber, this.vesselDtl.vslMLCodeActual).subscribe(
      (response) => {
        this.vesselMapDtls = response;
        if (this.vesselMapDtls.message == null) {
          this.createMap(this.vesselMapDtls);
          this.noLocationError = false;
        } else {
          this.noLocationError = true;
          this.errorMsg = this.vesselMapDtls.errorMsg;
        }
      },
      (err) => {
        this.alertService.clear();
        this.alertService.error(
          'An error has occurred, Kindly raise an incident in Service Now.' + '\n Error:' + err.error.error
        );
      }
    );
  }
  public createMap(vesselMapDtl) {
    const defaultLayers = this.platform.createDefaultLayers();
    const map = new H.Map(this.mapElement.nativeElement, defaultLayers.normal.map, {
      zoom: 7,
      center: { lat: vesselMapDtl.vessel_latitude, lng: vesselMapDtl.vessel_longtiude },
    });
    const ui = H.ui.UI.createDefault(map, defaultLayers, 'en-US');
    ui.getControl('zoom');
    ui.getControl('mapsettings').setVisibility(false);
    ui.getControl('scalebar').setVisibility(false);

    const icon = new H.map.Icon('assets/icons/East_icon.svg');

    const marker = new H.map.Marker(
      { lat: vesselMapDtl.vessel_latitude, lng: vesselMapDtl.vessel_longtiude },
      { icon: icon }
    );

    // Add the marker to the map:
    map.addObject(marker);

    // Enable the event system on the map instance:
    const mapEvents = new H.mapevents.MapEvents(map);

    // Add event listeners:
    map.addEventListener('tap', function (evt) {
      // Log 'tap' and 'mouse' events:
      const bubble1 = new H.ui.InfoBubble(
        { lat: evt.target.b.lat, lng: evt.target.b.lng },
        {
          content: 'Nearest Port:' + vesselMapDtl.nearest_port,
        }
      );

      // Add info bubble to the UI:
      ui.addBubble(bubble1);
    });
  }
  public showMap() {
    setTimeout(() => {
      if (this.showExpandLocation && this.getLocation) {
        this.createMap(this.vesselMapDtls);
      }
    }, 100);
  }
}
