// ice class service
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class IceClassService {

  constructor(
    private http: HttpClient,
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get list of class societies
   */
  public getClassSocieties(): Observable<any> {
    const url = environment.baseUrl + '/api/DictionaryManagement/ClassSocieties';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
   * get paints by maker
   * @param {number} csID class society id
   */
  public getIceClassByCS(csID): any {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/IceClassBySociety/' + csID);
  }
}
