//

import { Injectable } from '@angular/core';
import { DropdownContentService } from '../dropdown-content/dropdown-content.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SAPMaterialsService {

  constructor(
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get list of class societies
   */
  public getSAPMaterials(): Observable<any> {
    const url = environment.baseUrl + '/api/DictionaryManagement/SAPMaterials';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }
}
