//

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class CountryService {

  constructor(
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get list of countries
   */
  public getCountry(): Observable<any> {
    const url = environment.baseUrl + '/api/CountryManagement/Countries';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
   * get list of countries with ports
   */
  public getCountriesWithPorts(): Observable<any> {
    const url = environment.baseUrl + '/api/CountryManagement/CountriesWithPorts';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  // get list of ranges with ports

  public getRangesWithPorts(): Observable<any> {
    const url = environment.baseUrl + '/api/CountryManagement/RangeWithPorts';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }
}
