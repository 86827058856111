// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .col-chart {
  padding-left: 3.5%;
  padding-top: 2%;
}
:host::ng-deep .col-fleetrisk {
  display: inline-block;
  width: 20%;
}
:host::ng-deep .col-offhire {
  display: inline-block;
  width: 20%;
}
:host::ng-deep .col-bmt-vessel {
  display: inline-block;
  width: 30%;
}
:host::ng-deep .col-bmt-crane {
  display: inline-block;
  width: 30%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
