// Angular
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs/dist/exceljs.min.js';

import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  /**
   * this function downloads a dashboard
   * @param url
   * @param fileName
   */
  public generateExcel(name, modified_rows, header, width) {

    const title = name;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);
    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9897A9' },
        bgColor: { argb: '9897A9' }
      };
      cell.font = {
        name: 'Calibri',
        size: 10,
        bold: true
      },
        cell.alignment = {
          vertical: 'right',
          horizontal: 'bottom'
        },
        cell.border = { top: { style: 'medium' }, bottom: { style: 'thick' }, right: { style: 'thick' } };
      cell.width = width;
    });

    const data = modified_rows.forEach(d => {
      const row = worksheet.addRow(Object.values(d));
      row.font = {
        name: 'sans-serif', size: 8
      };
    });
    for (let i = 1; i <= header.length; i++) {
      worksheet.getColumn(i).width = 26;
    }
    worksheet.addRow([data]);
    workbook.xlsx.writeBuffer().then((excelData) => {
      const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  public generateA3Excel(headers, data, showDrydock) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('A3');
    const rows = [];
    const ciiColors = {
      'A': 'ff00b050',
      'B': 'ff92d050',
      'C': 'ffffff00',
      'D': 'ffffc000',
      'E': 'ffff0000'
    };

    // Convert data to rows
    data.forEach(rowData => {
      const row = headers.map(header => rowData[header.name]);
      rows.push(row);
    });

    // Create table in excel
    worksheet.addTable({
      name: 'MyTable',
      ref: 'A1',
      headerRow: true,
      columns: headers,
      rows: rows
    });

    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {


      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {

        if (rowNumber === 1) {
          cell.font = {
            name: 'Arial',
            size: 10,
            bold: true
          };
        } else {
          cell.font = {
            name: 'Arial',
            size: 10,
            bold: false
          };
        }

        if (rowNumber >= 2) {
          const d = data[rowNumber - 2];
          const monthEndIdx = worksheet.columns.length;
          const monthStartIdx = monthEndIdx - 23;

          // Vessel type coloring
          if (cell.value != null) {
            if (colNumber >= monthStartIdx) {
              const color = d?.vesselColor === 'brightyellow' ? 'ffffff33' : d?.vesselColor === 'lightpink' ? 'fffec5e5' : 'ffe3242b';
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color }
              };
            }
          }

          // Demarcation
          if (d?.serviceBorderClass || d?.positionBorderClass) {
            cell.border = { bottom: { style: d.serviceBorderClass ? 'thick' : 'thin' } };
          }

          // Phase In border
          const curCol = worksheet.getColumn(colNumber);
          const phaseInStyle = { style: 'thick', color: { argb: '0000FF' } };
          if (d?.phaseInMonth && d?.phaseInMonth === curCol.values[1]) {
            cell.border = { top: phaseInStyle, bottom: phaseInStyle, right: phaseInStyle, left: phaseInStyle };
          }

          // CII Coloring
          if (curCol.values[1] === 'CII') {
            if (cell.value != null) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: ciiColors[cell.value] }
              };
            }
          }

          // Drydock coloring
          if (showDrydock) {
            const ddMon = Object.keys(d?.dryDockMonths);
            if (d?.dryDockMonths && ddMon.indexOf(curCol.values[1]) !== -1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: cell.value !== null ? 'ffc59ab2' : 'ffc2c2d1' }
              };
              cell.value = cell.value !== null ? cell.value : d?.dryDockMonths[curCol.values[1]];
            }
          }
        }
      });
    });

    // Column size calculation
    worksheet.columns.forEach(column => {
      const header = headers.find(obj => obj.name === column.values[1]);

      if (header && header.width) {
        column.width = header.width;
      } else {
        let maxLen = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          const colLen = cell.value !== null ? String(cell.value).length : 10;
          if (colLen > maxLen) {
            maxLen = colLen;
          }
        });
        column.width = maxLen;
      }
    });

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'A3.xlsx');
    });
  }

  public generateServiceViewExcel(name, mergedrows, modified_rows, header, width, serviceViewData, rows) {
    const workbook = new Workbook();

    //start of each trade sheets
    const trades = [];
    modified_rows.forEach(row => {
      // Rename Trade
      switch (row.Trade) {
        case "SEA": row.Trade = "IAM"; break;
        case "SGL": row.Trade = "IEU"; break;
        case "WCA": row.Trade = "IME"; break;
        default: break;
      }

      if (!trades[row.Trade]) {
        trades[row.Trade] = [];
      }
      trades[row.Trade].push(row);
    })

    let rowPointer = 12; 
    let colPointer = 2;

    // Get current week number
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (currentDate.getTime() - startOfYear.getTime()) / 86400000;
    const currentWeek = Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);

    Object.keys(trades).forEach(trade => {
      const tradeSheet = workbook.addWorksheet(trade); 

      //service specific information heading (1st column)
      Object.keys(mergedrows[0]).forEach((header, index) => {
        tradeSheet.getCell(`A${index + 1}`).value = header;
        tradeSheet.getColumn(1).width = 22;
      });

      //fixed 1-10 rows & 1st column
      tradeSheet.views = [{ state: 'frozen', ySplit: 11, xSplit: 1 } ];

      let trade_rows = trades[trade]
      const NewServiceIdx = Object.keys(trade_rows[0]).indexOf(" ");
      const vslNameIdx = Object.keys(trade_rows[0]).indexOf("Vsl name");
      const yearWeekIdx = Object.keys(trade_rows[0]).indexOf("Year week");

      for (let i = 0; i < trade_rows.length-1; i++) {
        trade_rows[i][" "] = trade_rows[i]["Service name"] !== trade_rows[i + 1]["Service name"];
      }      

      const seenServiceCodes = new Set();
      const mergedtrade_rows = trade_rows.reduce((acc, e) => {
      let serviceCode = rows.find(service => service.serviceName === e["Service name"]).serviceCode
        if (!seenServiceCodes.has(serviceCode)) {
          seenServiceCodes.add(serviceCode);
          acc.push({
            "": rows.find(service => service.serviceCode === serviceCode).route + '/'  + serviceCode,
            "Service name": serviceViewData.serviceName[serviceCode],
            "% ML": serviceViewData.perML13wk[serviceCode] + '%',
            "% fixed": serviceViewData.perFixed13wk[serviceCode] + '%',
            'No. Cancellations (13wks)': serviceViewData.blankRowsCount[serviceCode],
            'Avg CII (13wks)': serviceViewData.avgCII13wk[serviceCode],
            'Avg FleetRisk (13wks)': serviceViewData.avgFleetRisk[serviceCode] + '%',
            'YT Service Consumption': '-',
            '% Scrubber': serviceViewData.perScrubber[serviceCode] + '%',
            'Port Code': serviceViewData.portCodes[serviceCode]
          });
        }
        return acc;
      }, []);

      //service specific information data (1-9)
      rowPointer = 1; 
      colPointer = 2; 
      mergedtrade_rows.forEach((item, index) => {
        Object.values(item).forEach((value, idx) => {
          var cell = tradeSheet.getCell(rowPointer, colPointer);
          cell.value = value;
          // console.log(rowPointer, colPointer, rowPointer, colPointer+Object.keys(modified_rows[0]).length-1) //colPointer to be decreased +1 remove change -1 to -2
          tradeSheet.mergeCells(rowPointer, colPointer, rowPointer, colPointer+Object.keys(modified_rows[0]).length-1); //this can be changed to 2 afterwards
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          tradeSheet.getRow(rowPointer).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber > 1 && rowPointer > 1) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: idx % 2 === 0 ? 'd5f0ff' : 'bfe0ee' }, // Blue color for every alternate row
              };
            }
          });
          if(idx == Object.keys(item).length-1){ 
            rowPointer = 1
            colPointer = colPointer + Object.keys(modified_rows[0]).length; 
          }        
          else rowPointer++;
          cell.border = {right: { style: 'thin' } };
        });
      });

      //Heading for each services
      const repeatedHeaders = Array(mergedtrade_rows.length).fill(header).flat();
      var headerRow = tradeSheet.addRow( [''].concat(repeatedHeaders));
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'bfe0ee' },
          bgColor: { argb: 'bfe0ee' }
        };
        cell.font = {
          name: 'Calibri',
          size: 10,
          bold: true
        },
          cell.alignment = {
            vertical: 'right',
            horizontal: 'bottom'
          },
          cell.border = { top: { style: 'medium' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.width = width;
      });

      repeatedHeaders.forEach((header, index) => {
        const columnWidth = width[header] || 10;
        tradeSheet.getColumn(index + 2).width = columnWidth;
      });

      //data for each services
      rowPointer = 12; 
      colPointer = 2;
      trade_rows.forEach((item, index) => {
        var cell;
        Object.values(item).forEach((value, idx) => {
          cell = tradeSheet.getCell(rowPointer, colPointer + idx);
          // if(idx-1 != NewServiceIdx) 
            cell.value = value;

          if (idx === vslNameIdx && value == null) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'd62d23' } // Red color
            };
          }

          if (idx === yearWeekIdx) {
            const yearNumber = parseInt(value.toString().split('-')[0]);  // Extract the year number
            const weekNumber = parseInt(value.toString().split('-')[1]);  // Extract the week number
            
            if (yearNumber < currentDate.getFullYear() || weekNumber < (currentWeek + 9)) {
              // First 9 weeks: Red color
              cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'f0a8a4' }
              };
            } else if (weekNumber < (currentWeek + 12)) {
              // Next 3 weeks: Yellow color
              cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ffd6a4' }
              };
            } else {
              // Remaining weeks: Green color
              cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'c8eace' }
              };
            }
          }
          cell.border = { left: { style: 'thin' }, bottom: { style: 'thin' } };
          if(idx == NewServiceIdx){
            if (value == false) {
              rowPointer++;
            } else {
              rowPointer = 12; 
              colPointer = colPointer + idx+ 1; //remove 1
            }
          }       
        });
      });
    });
    //end - each trade sheets
    workbook.xlsx.writeBuffer().then((excelData) => {
      const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }
  public generateEndorcementExcel(deliveryHeader,reDeliveryHeader,  deliveryData,reDeliveryData) {
    console.log(deliveryHeader);
    console.log(deliveryData);
    console.log("--------------");
    console.log(reDeliveryHeader);
    console.log(reDeliveryData);
    const workbook = new Workbook();
    this.GenerateEndorcementData('Delivery',workbook, deliveryHeader, deliveryData);
    this.GenerateEndorcementData('ReDelivery',workbook, reDeliveryHeader, reDeliveryData);
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Postfixture_Endorcement.xlsx');
    });
  }

  private GenerateEndorcementData(worksheetName :string,workbook: any, deliveryHeader: any, modified_rows: any) {
    const worksheet = workbook.addWorksheet(worksheetName);
    const rows = [];
    const DueInColors = {
      'red-txt': 'ED1C24',
      'yellow-txt': 'ffffc000'
    };
    // Add Header Row
    const headerRow = worksheet.addRow(deliveryHeader);
    const data = modified_rows.forEach(d => {
      const row = worksheet.addRow(Object.values(d));
      row.font = {
        name: 'sans-serif', size: 8
      };
    });
    for (let i = 1; i <= deliveryHeader.length; i++) {
      worksheet.getColumn(i).width = 26;
    }
    worksheet.addRow([data]);


    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      //const monthEndIdx = row.columns.filter(col => col.values[1] === 'Notice DueIn');

      let rowCol = row.values[9];
      const rowColor = rowCol < 0 ? 'red-txt' : (rowCol >= 0 && rowCol <= 7 ? 'yellow-txt' : '');


      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {

        if (rowNumber === 1) {
          cell.font = {
            name: 'Arial',
            size: 10,
            bold: true
          };
        } else {
          cell.font = {
            name: 'Arial',
            size: 10,
            bold: false
          };
        }

        if (rowNumber >= 2) {
          // Phase In border
          const curCol = worksheet.getColumn(colNumber);
          // CII Coloring
          console.log(curCol);



          if (rowColor !== '') {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: DueInColors[rowColor] }
            };
          }


        }
      });
    });
  }
}
