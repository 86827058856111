// service-repository services
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScenariosService {
  private url = document.URL;
  private baseUrl = (this.url.search(/localhost/gi) !== -1 || this.url.search(/wapazewdmlit001champsweb.crb.apmoller.net/gi) !== -1) ? environment.baseUrl + '' : environment.baseUrl;
  public scheduleDetail: any;
  public openFolderId: number; // used in tree view component
  constructor(
    private http: HttpClient
  ) { }

  public postCreateUpdate(updateData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/CreateOrUpdateScenario/', updateData);
  }
  public getScenarioListAsync() {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetScenarioListAsync/');
  }

  public getScenarioServices(scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetServicesListByScenarioIdAsync/' + scId);
  }
  public getScenarios() {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/SearchScenarioDropDownList');
  }

  public deleteSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/DeleteProformaFromService/', postData);
  }
  public getServices() {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceList');
  }
  public postEndDeployment(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/EndDeployment', postData);
  }
  public postAddCommentService(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateCommentsForDeploymentPortDate', postData);
  }
  public getAdujstingVisibleDate(schId, fromDate, toDate, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetSchedulePlanningViewByDates/' + schId + '/' + fromDate + '/' + toDate
      + '/' + scId);
  }
  // Post Vessel Requirements data
  public postVesselReqUpdateService(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/EditScheduleOverviewVesselRequirement/Update/', postData);
  }
  public postOmitPortUpdateService(postData) {

    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateIsOmitted', postData);
  }
  public postCreateUpdateDummy(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/CharterRequest/CreateOrUpdate', postData);
  }

  public deleteCharterRequest(charterId) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/CharterRequest/Remove/', charterId);
  }
  public getServicePlanningLst(schId, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetServicePlanningViewByServiceId/' + schId + '/' + scId);
  }
  public getVesselRequirements(schId, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetScheduleOverviewVesselRequirement/' + schId + '/' + scId);
  }
  public postUpdateCharterRequestOnSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateCharterRequestOnSchedule', postData);
  }
  public useVessel(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UseVesselCutOffDeployment/', postData);
  }
  public deleteVesselService(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UseVesselDeleteDeployment/', postData);
  }
  public deleteServiceFromScenario(scenarioId, serviceId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/RemoveServiceFromScenario/' + scenarioId + '/' + serviceId);
  }
  public useForWithoutDeployment(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForScheduleAsync/', postData);
  }
  public postUpdateDeploymentCommentsAsync(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateDeploymentCommentsAsync', postData);
  }
  public getRequirementHeader(id) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/GetVesselFinderVesselRequirementsAsync/' + id, '');
  }
  public getVesselList(id, startdate, enddate) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/VesselFinderVesselsList/' + id + '/' + startdate + '/' + enddate);
  }
  public getManualScheduleLst(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/ManualScheduleImport', postData);
  }
  public addServiceToScenario(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/AddServiceToScenario/', postData);
  }
  public postManualScheduleLst(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/AddProformaHeaderToSchedules', postData);
  }

  public getServiceOverview(serviceId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceOverviewHeader/' + serviceId);
  }
  public getClassList() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/VesselTEU');
  }
  public getScheduleRotation(schId, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetRotationsBlockByProformaId/' + schId + '/' + scId);
  }
  // Get Schedule Data
  public getScheduleDetail(schId, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/ScheduleOverviewHeader/' + schId + '/' + scId);
  }
  // Get schedule dating
  public getScheduleDating(schId, scId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetScheduleDatingByProformaId/' + schId + '/' + scId);
  }
  public getLastDeploymentOnServiceByPosition(serviceId, position, crPhaseInStartDate, scId) {
    return this.http.get(environment.baseUrl + '/api/ScenarioManagement/GetLastDeploymentOnServiceByPosition/' + serviceId + '/' + position + '/' + crPhaseInStartDate + '/' + scId);
  }

  // added new API's as per service repository
  public postSchedulePlannerScheduleCutHere(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/GetSchedulePlanningViewForScheduleDating', postData);
  }

  public postSchedulePlannerForNewScheduleCutOff(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/GetSchedulePlannerViewForNewScheduleCutOff', postData);
  }
  public deleteVesselDeployment(postData) {
    return this.http.post(environment.baseUrl + '/api/ScenarioManagement/VesselDeployement/DeleteVesselDeploymentOnSchedulePlannerAsync', postData);
  }

  public postUpdateSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateScheduleHeaderAsync', postData);
  }

  public postUpdateScheduleVisibility(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateScheduleVisibility/', postData);
  }
  public postCreateUpdateScheduleDating(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/CreateUpdateScheduleDating', postData);
  }

  public postSchedulePlannerViewForNewScheduleDatesChanged(postData, fromDate, toDate) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/GetSchedulePlannerViewForNewScheduleDatesChanged?validFrom=' + fromDate + '&validTo=' + toDate, postData);
  }

  public postImportSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/ImportScheduleAsync', postData);
  }

  public postSchedulePlannerViewForNewSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/GetSchedulePlannerViewForNewSchedule', postData);
  }

  // Delete Bunker Ports-Schedule dating
  public deleteBunkerPortsService(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateIsBunkerPort/', postData);
  }
  // Post Schedule dating in and out ports
  public postInOutPortsService(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/UpdateDefaultPhaseInPhaseOutPorts/', postData);
  }
  public getVesselRequirementsExtendToDummy(vesselId, proformaId, scenarioId) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetExtendToDummyVesselRequirementsAsync/' + vesselId + '/' + proformaId + '/' + scenarioId);
  }

  public getScenarioOverviewHeader(scenarioId: number) {
    return this.http.get(this.baseUrl + '/api/ScenarioManagement/GetScenarioOverviewHeader/' + scenarioId);
  }
  public createVesselDeployment(postData) {
    return this.http.post(this.baseUrl + '/api/ScenarioManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForSchedulePlannerAsync', postData);
  }

  public addExtraloader(datedScheduleId: number, position: number) {
    const body = {
      datedScheduleId,
      position
    };
    return this.http.post(environment.baseUrl + '/api/ScenarioManagement/CreateExtraloaderPosition', body);
  }
}
