//

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, filter } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class TradeListService {

  constructor(
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get list of build yards
   */
  public getTradeList(): Observable<any> {
    return this.dropdownContent.retrieveContent(environment.baseUrl + '/api/ServiceManagement/TradeList')
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }
}
