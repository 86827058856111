import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import *as XLSX from 'sheetjs-style';

@Injectable({
  providedIn: 'root'
})
export class DownloadReportService {

  constructor(private http: HttpClient) { }

  /**
   * this function downloads a dashboard
   * @param url
   * @param fileName
   */
  public downloadReportToExcel(name, modified_rows, header, column_width) {

    // const modified_header = header;
    // const worksheet = XLSX.utils.json_to_sheet(
    //   modified_rows,
    //   { header: modified_header },
    // );

    // worksheet['!cols'] = column_width;

    // for (let i in worksheet) {
    //   if (typeof (worksheet[i]) != "object") continue;

    //   let cell = XLSX.utils.decode_cell(i);

    //   worksheet[i].s = { // styling for all cells
    //     font: {
    //       name: "sans-serif",
    //       sz: 9
    //     },
    //     alignment: {
    //       vertical: "right",
    //       horizontal: "bottom",
    //       // wrapText: '1', // any truthy value here
    //     },
    //     border: {
    //       right: {
    //         style: "thin",
    //         color: "000000"
    //       },
    //       left: {
    //         style: "thin",
    //         color: "000000"
    //       }
    //     }
    //   };

    //   // if (cell.c == 1 || cell.c == 2) { // first column

    //   if (cell.r == 0) { // first row
    //     worksheet[i].s.border.bottom = {
    //       style: "thick",
    //       color: "000000"
    //     };
    //     worksheet[i].s.border.right = {
    //       style: "thick",
    //       color: "000000"
    //     };
    //     worksheet[i].s.border.top = {
    //       style: "thick",
    //       color: "000000"
    //     };
    //     worksheet[i].s.fill = { // background color
    //       patternType: "solid",
    //       fgColor: { rgb: "9897A9" },
    //       bgColor: { rgb: "9897A9" },
    //       bold: true,
    //     };
    //     worksheet[i].s.font = {
    //       name: 'arial',
    //       sz: 12,
    //       bold: true,
    //       color: "#000000"
    //     }
    //   }

    //   if (cell.r == modified_rows.length) {    //last row bottom border
    //     worksheet[i].s.border.bottom = {
    //       style: "thin",
    //       color: "000000"
    //     };
    //   }

    //   if (worksheet[i].v == null) {
    //     worksheet[i].v = '---';
    //     worksheet[i].t = { // styling for empty cells - converts to null or ---
    //       border: {
    //         right: {
    //           style: "thin",
    //           color: "000000"
    //         },
    //         left: {
    //           style: "thin",
    //           color: "000000"
    //         }
    //       }
    //     };
    //   }
    // }
    // const workbook1 = XLSX.utils.book_new();
    // workbook1.SheetNames.push(name);
    // workbook1.Sheets[name] = worksheet;

    // const excelBuffer: any = XLSX.write(workbook1, { bookType: 'xlsx', type: 'array', cellStyles: true });
    // const data: Blob = new Blob([excelBuffer], { type: 'xlsx' });
    // XLSX.writeFile(workbook1, name + '.xlsx');

  }
}
