// this is for showing the DONUT charts in evaluation vessel score
import { Component, OnInit, Input } from '@angular/core';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { ActivatedRoute } from '@angular/router';
import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'champs-evaluation-score-charts',
  templateUrl: './evaluation-score-charts.component.html',
  styleUrls: ['./evaluation-score-charts.component.scss']
})
export class EvaluationScoreChartsComponent implements OnInit {

  @Input() public imoNumber: number;
  public id: any;
  /** chartData are for fleetrisk/offhire scores etc, chartData2 are for owner scores */

  public donutChartDetails: any;
  public chartDataFleetrisk: number;
  public chartDataFleetrisk2: number;

  public chartDataOffhire: number;
  public chartDataOffhire2: number;

  public chartDataBmtVessel: number;
  public chartDataBmtVessel2: number;

  public chartDataBmtCrane: number;
  public chartDataBmtCrane2: any;

  public notAvailable: boolean = false;

  public colorConfigList: any;

  constructor(private route: ActivatedRoute, private vesselsService: VesselsService, private colorConfigService: ColorConfigService) {
    this.id = this.route.parent ? this.route.parent.snapshot.paramMap.get('id') : 0;
  }

  public ngOnInit() {

    /** integrated mode (comment this for hardcoded mode) */
    const donutChartDetails$ = this.vesselsService.getDonutChartDetails(this.id);
    const colorConfigList$ = this.colorConfigService.getColorConfigByGroupName('Scores - Others');
    forkJoin(donutChartDetails$, colorConfigList$).subscribe(([donutChartDetails, colorConfigList]) => {
      this.donutChartDetails = donutChartDetails;

      this.colorConfigList = colorConfigList;
      if (this.donutChartDetails) {
        /** fleetrisk */
        this.chartDataFleetrisk = this.donutChartDetails.frAvgDefect;
        /** offhire */
        this.chartDataOffhire = this.donutChartDetails.offhireVesselRating;
        this.chartDataOffhire2 = this.donutChartDetails.offhireOwnerRating;
        /** bmt vessel */
        this.chartDataBmtVessel = this.donutChartDetails.bmtVesselRating;
        this.chartDataBmtVessel2 = this.donutChartDetails.bmtVesselOwnerRating;
        /** bmt crane */
        this.chartDataBmtCrane = this.donutChartDetails.bmtCraneRating;
        this.chartDataBmtCrane2 = this.donutChartDetails.bmtCraneOwnerRating;
      } else {
        this.notAvailable = true;
        /** fleetrisk */
        this.chartDataFleetrisk = 0;
        /** offhire */
        this.chartDataOffhire = 0;
        this.chartDataOffhire2 = 0;
        /** bmt vessel */
        this.chartDataBmtVessel = 0;
        this.chartDataBmtVessel2 = 0;
        /** bmt crane */
        this.chartDataBmtCrane = 0;
        this.chartDataBmtCrane2 = 'N/A';
      }
    }
    );
  }
}
