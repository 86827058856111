// Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

// PowerBi
import { IReportEmbedConfiguration, models } from 'powerbi-client';

@Component({
  selector: 'champs-powerbi-report',
  templateUrl: './powerbi-report.component.html',
  styleUrls: ['./powerbi-report.component.scss']
})
export class PowerbiReportComponent implements OnInit {

  public report: any;
  public showReport: boolean = false;
  public reportConfig: IReportEmbedConfiguration;

  constructor(private route: ActivatedRoute, private msal: MsalService) { }

  public ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.report = params;
    });
    this.msal.instance.acquireTokenSilent({
      scopes: ['https://analysis.windows.net/powerbi/api/.default']
    }).then((result) => {
      this.reportConfig = {
        type: 'report',
        id: this.report.id,
        embedUrl: this.report.url,
        tokenType: models.TokenType.Aad,
        accessToken: result.accessToken,
        settings: {
          panes: {
            pageNavigation: {
              visible: false
            },
            filters: {
              expanded: false
            }
          }
        },
      };
      this.showReport = true;
    });
  }

}
