import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors } from '@angular/forms';

import { Store, select } from '@ngrx/store';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import * as VesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { IVesselCargoCapCreate } from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { map, take } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { scrollToError } from '../../utils/scroll-utils';
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Component({
  selector: 'champs-vessel-new-cargo-cap-layout',
  templateUrl: './vessel-new-cargo-cap-layout.component.html',
  styleUrls: ['./vessel-new-cargo-cap-layout.component.scss'],
})
export class VesselNewCargoCapLayoutComponent implements OnInit {
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  private _showFltrValue;

  @Output() public changeStep: EventEmitter<any> = new EventEmitter();
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  @Input()
  public values: IVesselCargoCapCreate;
  public vesselTeuTypes$: Observable<any>;

  public originalValues: any = null;

  public vesselTeuName: any;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;
  public vesselType: boolean = true;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  public checked: boolean;
  public generalFrmGrp: UntypedFormGroup;
  public cranesFrmGrp: UntypedFormGroup;
  public lashingFrmGrp: UntypedFormGroup;
  public reeferDataFrmGrp: UntypedFormGroup;

  public showFormErrors: boolean = false;
  public isCreateTypeHull: boolean;

  constructor(
    private formbuilder: UntypedFormBuilder,
    public vesselDropdowns: VesselDropdownsService,
    public vesselsService: VesselsService,
    private store: Store<any>,
    private storeVessel: Store<VesselCreate.State>,
    public dialog: MatDialog,
    private alertService: AlertService
  ) {
    this.vesselTeuTypes$ = this.vesselDropdowns.getVesselTypes();
  }

  public ngOnInit() {
    this.storeVessel.pipe(select(VesselCreate.getVesselCreateType), take(1)).subscribe((type) => {
      this.isCreateTypeHull = type && type === 'hull-number' ? true : false;
    });

    this.createGeneralForm();
    this.createCranesForm();
    this.createLashingForm();
    this.createReeferDataForm();

    this.alertService.sharedVesselType.subscribe((res) => {
      if (res) {
        this.vesselType = res === 'VSA' ? false : true;
      }
    });
  }

  public nextStep() {
    this.setVesselTeuName();
    if (
      !this.generalFrmGrp.valid ||
      (!this.cranesFrmGrp.valid && this.cranesFrmGrp.get('vslCargoCraneInstalled').value) ||
      !this.lashingFrmGrp.valid ||
      !this.reeferDataFrmGrp.valid &&
      this.vesselType
    ) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.generalFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (!requiredError) {
        Object.keys(this.reeferDataFrmGrp.controls).forEach((key) => {
          const controlErrors: ValidationErrors = this.reeferDataFrmGrp.get(key).errors;
          if (controlErrors != null) {
            for (const error of Object.keys(controlErrors)) {
              if (error === 'required') {
                requiredError = true;
                break;
              }
            }
          }
        });
      }
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    const valueObj = this.createPostObj();
    this.store.dispatch(new VesselCreateActions.PutCargoCap(valueObj));
    this.changeStep.emit();
  }
  public updateCraneInstalledChecked(option, event) {
    if (!event.checked) {
      this.cranesFrmGrp.get('vslNoOfCranes').setValue(null);
      this.cranesFrmGrp.get('vslCapacityCrane').setValue(null);
    }
  }
  public setVesselTeuName() {
    this.vesselTeuTypes$.subscribe((data) => {
      this.vesselDropdowns.vesselTeuName = '';
      this.vesselDropdowns.vesselTeuID = '';

      const x = { ...this.generalFrmGrp.value };
      this.vesselDropdowns.vesselTeuName =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName !==
          undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName
          : null;

      this.vesselDropdowns.vesselTeuID =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID !==
          undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID
          : null;
    });
  }

  private createPostObj() {
    return {
      ...this.generalFrmGrp.value,

      ...this.cranesFrmGrp.value,
      ...this.lashingFrmGrp.value,
      ...this.reeferDataFrmGrp.value,
    };
  }

  private createGeneralForm() {
    this.generalFrmGrp = this.formbuilder.group({
      vslTeuNominal: this.formbuilder.control(this.values.vslTeuNominal, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      vslTonCenCap: this.formbuilder.control(
        this.originalValues ? this.values.vslTonCenCap : this.values.vslTeuNominal,
        [Validators.pattern(new RegExp('^[0-9]+$'))]
      ),
      vslTeuActual: this.formbuilder.control(this.values.vslTeuActual, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      vslDeckRussianStowage: this.values.vslDeckRussianStowage,
      vslSLP: this.values.vslSLP,
    });
    if (!this.originalValues) {
      this.generalFrmGrp.get('vslTeuNominal').valueChanges.subscribe((res) => {
        this.generalFrmGrp.get('vslTonCenCap').setValue(this.generalFrmGrp.get('vslTeuNominal').value);
      });
    }
  }

  private createCranesForm() {
    this.cranesFrmGrp = this.formbuilder.group({
      vslCargoCraneInstalled: this.values.vslCargoCraneInstalled,
      vslNoOfCranes: [
        this.values.vslCargoCraneInstalled ? this.values.vslNoOfCranes : null,
        [Validators.pattern(new RegExp('^[0-9]+$')), Validators.min(1), Validators.max(5)],
      ],
      vslOutReach: this.values.vslCargoCraneInstalled ? this.values.vslOutReach : null,
      vslCapacityCrane: [
        this.values.vslCargoCraneInstalled ? this.values.vslCapacityCrane : null,
        [Validators.min(1), Validators.max(50)],
      ],
    });
    this.cranesFrmGrp.patchValue({ vslCargoCraneInstalled: false });
  }

  private createLashingForm() {
    this.lashingFrmGrp = this.formbuilder.group({
      vslFullyFittedLashing: this.values.vslFullyFittedLashing,
      vslAutomaticTwistLock: this.values.vslAutomaticTwistLock,
    });
  }

  private createReeferDataForm() {
    this.reeferDataFrmGrp = this.formbuilder.group({
      vslPlugsInHold: this.formbuilder.control(this.values.vslPlugsInHold, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslPlugsUnderDeck: this.formbuilder.control(this.values.vslPlugsUnderDeck, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslPlugTypeInHold: this.values.vslPlugTypeInHold,
      vslPlugTypeOnDeck: this.values.vslPlugTypeOnDeck,
      vslEarthPin: this.values.vslEarthPin,
      vslVolt: this.values.vslVolt,
      vslReeferSocketCompliant: this.values.vslReeferSocketCompliant,
      vslReeferSocketProtected: this.values.vslReeferSocketProtected,
      vslReeferRepairable: this.values.vslReeferRepairable,
      vslReeferRepairableComment: this.values.vslReeferRepairableComment,
      vslReeferPlugsIAtSea: this.formbuilder.control(this.values.vslReeferPlugsIAtSea, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslReeferPlugsDuringPort: this.formbuilder.control(this.values.vslReeferPlugsDuringPort, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslReeferPlugsDuringManoeuvring: this.formbuilder.control(this.values.vslReeferPlugsDuringManoeuvring, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslVentPerReefer: this.values.vslVentPerReefer,
      vslVentPerCargo: this.values.vslVentPerCargo,
      vslCapacity40Reefer: this.values.vslCapacity40Reefer,
      vslUnderDesk40Reefer: this.formbuilder.control(this.values.vslUnderDesk40Reefer, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslCapacity44Reefer: this.formbuilder.control(this.values.vslCapacity44Reefer, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
    });
  }
}
