import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { IVesselDocsCertsCreate } from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { CanDeactivateDialogService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog/can-deactivate-dialog.service';
import { CanDeactivateDialogToggleService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog-toggle/can-deactivate-dialog-toggle.service';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { scrollToError } from '../../utils/scroll-utils';

@Component({
  selector: 'champs-vessel-new-plans-docs-and-certs',
  templateUrl: './vessel-new-plans-docs-and-certs.component.html',
  styleUrls: ['./vessel-new-plans-docs-and-certs.component.scss'],
})
export class VesselNewPlansDocsAndCertsComponent implements OnInit {
  /** Subject that emits when the component has been destroyed. */
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  @Output() public changeStep: EventEmitter<any> = new EventEmitter();

  @Input() public values: IVesselDocsCertsCreate;
  public originalValues: any = null;
  public navigate: boolean = true;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }

  public generalSectnFrmGrp: UntypedFormGroup;
  public showFormErrors: boolean = false;

  constructor(
    public dialog: MatDialog,
    private vesselsService: VesselsService,
    private formbuilder: UntypedFormBuilder,
    private store: Store<any>,
    private router: Router,
    private dialogService: CanDeactivateDialogService,
    private dialogToggleService: CanDeactivateDialogToggleService
  ) {}

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.crerateGeneralSectionForm();
  }

  public nextStep() {
    if (!this.generalSectnFrmGrp.valid) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.generalSectnFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalSectnFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    const valueObj = this.createPostObj();
    this.store.dispatch(new VesselCreateActions.PutPlansDoc(valueObj));
    this.changeStep.emit();
  }

  public createPostObj() {
    return {
      ...this.generalSectnFrmGrp.value,
    };
  }

  public cancelCreateVessel() {
    this.dialogService.openDialog('CANCEL', `Are you sure you want to leave the page?`, {
      yes: 'YES',
      no: 'NO',
    });

    this.dialogToggleService.canDeactivateToggle.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.store.dispatch(new VesselCreateActions.ResetCreateVesselObj());
        this.router.navigate(['/vessels'], {
          replaceUrl: true,
        });
      }
    });
  }

  private crerateGeneralSectionForm() {
    this.generalSectnFrmGrp = this.formbuilder.group({
      vcSeaTrial: this.values.vcSeaTrial ? this.values.vcSeaTrial : false,
      vcShopTrialME: this.values.vcShopTrialME ? this.values.vcShopTrialME : false,
      vcShopTrialAE: this.values.vcShopTrialAE ? this.values.vcShopTrialAE : false,
      vcDisplacementTable: this.values.vcDisplacementTable ? this.values.vcDisplacementTable : false,
      vcPowerSpeedTable: this.values.vcPowerSpeedTable ? this.values.vcPowerSpeedTable : false,
      vcShopTrialTCCOOperation: this.values.vcShopTrialTCCOOperation ? this.values.vcShopTrialTCCOOperation : false,
      vcModelTest: this.values.vcModelTest ? this.values.vcModelTest : false,
      vcLastDryDockingPaintReport: this.values.vcLastDryDockingPaintReport
        ? this.values.vcLastDryDockingPaintReport
        : false,
      vcLastUnderwaterInspectionReport: this.values.vcLastUnderwaterInspectionReport
        ? this.values.vcLastUnderwaterInspectionReport
        : false,
      vcLastPropellerPolishingReport: this.values.vcLastPropellerPolishingReport
        ? this.values.vcLastPropellerPolishingReport
        : false,
      vcLastHullCleaningReport: this.values.vcLastHullCleaningReport ? this.values.vcLastHullCleaningReport : false,
      vcShopTrailForTCCO: this.values.vcShopTrailForTCCO ? this.values.vcShopTrailForTCCO : false,
    });
  }
}
