//
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MY_FORMATS,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { Router } from '@angular/router';
import { ScenariosService } from 'src/app/modules/scenarios/services/scenarios.service';

const moment = customMoment;

@Component({
  selector: 'champs-omit-port',
  templateUrl: './omit-port.component.html',
  styleUrls: ['./omit-port.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class OmitPortComponent implements OnInit {
  @ViewChild('commentPort', { static: false }) public commentPort: ElementRef;
  @Input() public serviceId: any;
  @Input() public isOmitted: any;
  @Input() public versionPorts: any;
  @Input() public omitFromToDate: any;
  @Input() public vesselDto: any;
  @Input() public position: any;
  @Input() public proformaPortId: any;
  @Input() public datedScheduleId: any;
  @Input() public scheduleId: any;
  @Input() public deploymentPortComments: any;
  @Input() public serviceRepo: any;
  @Input() public extraLoaderPopupShow: boolean = false;
  @Input() public portInfoInput: any;
  @Input() public portInfoDummy: any;
  @Input() public portInfoVesselDep: any;
  @Output() public endDep: EventEmitter<any> = new EventEmitter();
  @Output() public addCommmentEmit: EventEmitter<any> = new EventEmitter();
  public showOmitFormData: boolean = false;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Output() public setOmit: EventEmitter<any> = new EventEmitter();
  @Output() public show: EventEmitter<any> = new EventEmitter();

  @Input() public isExtraloaderHidden: boolean;

  public showRemoveOmitFormData: boolean = false;

  public omitPortformGrp: UntypedFormGroup;
  public isBtnVisible: boolean = false;
  public isVesselDeployed: boolean = false;
  public isCreateDummy: boolean = false;
  @Output() public showCreateDummy: EventEmitter<any> = new EventEmitter();
  public saveBtnDisabled: boolean = false;
  public loading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceRepoService: ServiceRepositoryService,
    private scenarioService: ScenariosService,
    private router: Router
  ) {}

  public ngOnInit() {
    if (this.portInfoVesselDep.vesselDto != null) {
      this.isVesselDeployed = true;
    } else if (this.portInfoDummy === true) {
      this.isCreateDummy = true;
    }
    this.saveOmitPortForm();
    this.omitPortformGrp.get('addComment').valueChanges.subscribe(() => {
      if (this.omitPortformGrp.get('addComment').value == null) {
        this.isBtnVisible = false;
        return;
      }
    });
  }
  public createDummy() {
    this.showCreateDummy.emit({
      showCreateDummy: true,
    });
  }
  public showVisibleOmitForm() {
    this.showOmitFormData = !this.showOmitFormData;
  }

  public showRemoveOmitForm() {
    this.showRemoveOmitFormData = !this.showRemoveOmitFormData;
  }
  public cancelCreateForm() {
    this.close.emit({
      type: 'close',
    });
  }

  public updateOmit() {
    const fromDate = new Date(this.omitPortformGrp.get('omitPortStartDate').value).toISOString().split('.')[0];
    const toDate = new Date(this.omitPortformGrp.get('omitPortEndDate').value).toISOString().split('.')[0];
    const postData = [];
    if (this.omitPortformGrp.get('omitPosition').value === 'this') {
      postData.push({
        scheduleId: this.omitFromToDate.fromDate.scheduleId,
        isOmitted: true,
      });
      postData.push({
        scheduleId: this.omitFromToDate.toDate.scheduleId,
        isOmitted: true,
      });
    } else {
      this.versionPorts.lstScheduleListDto.map((schLst: any) => {
        const schFromDate = schLst
          ? schLst.lstPositionDto[0].scheduleType === 0
            ? schLst.lstPositionDto[0]
            : schLst.lstPositionDto[1].scheduleType === 0
            ? schLst.lstPositionDto[1]
            : null
          : null;

        const schToDate = schLst
          ? schLst.lstPositionDto[0].scheduleType === -1
            ? schLst.lstPositionDto[0]
            : schLst.lstPositionDto[1].scheduleType === -1
            ? schLst.lstPositionDto[1]
            : null
          : null;
        if (schFromDate ? schFromDate.scheduleDate >= fromDate && schFromDate.scheduleDate <= toDate : false) {
          if (schFromDate.vesselDto || schToDate.vesselDto) {
            postData.push({
              scheduleId: schFromDate.scheduleId,
              isOmitted: true,
            });
            if (schToDate) {
              postData.push({
                scheduleId: schToDate.scheduleId,
                isOmitted: true,
              });
            }
          }
        }
        /*         if ((schToDate) ? (schToDate.scheduleDate >= fromDate && schToDate.scheduleDate <= toDate) : false) {
                  postData.push({
                    'scheduleId': schToDate.scheduleId,
                    'isOmitted': true
                  });
                } */
      });
    }
    this.setOmit.emit({
      postData: postData,
    });
  }
  public removeOmit() {
    const postData = [];
    if (this.omitPortformGrp.get('omitPosition').value === '0') {
      postData.push({
        scheduleId: this.omitFromToDate.fromDate.scheduleId,
        isOmitted: false,
      });
      postData.push({
        scheduleId: this.omitFromToDate.toDate.scheduleId,
        isOmitted: false,
      });
    } else {
      this.versionPorts.lstScheduleListDto.map((schLst: any) => {
        const schFromDate = schLst
          ? schLst.lstPositionDto[0].scheduleType === 0
            ? schLst.lstPositionDto[0]
            : schLst.lstPositionDto[1].scheduleType === 0
            ? schLst.lstPositionDto[1]
            : null
          : null;

        const schToDate = schLst
          ? schLst.lstPositionDto[0].scheduleType === -1
            ? schLst.lstPositionDto[0]
            : schLst.lstPositionDto[1].scheduleType === -1
            ? schLst.lstPositionDto[1]
            : null
          : null;
        if (schFromDate) {
          postData.push({
            scheduleId: schFromDate.scheduleId,
            isOmitted: false,
          });
        }
        if (schToDate) {
          postData.push({
            scheduleId: schToDate.scheduleId,
            isOmitted: false,
          });
        }
      });
    }
    this.setOmit.emit({
      postData: postData,
    });
  }
  public emptyCommentField() {
    this.isBtnVisible = true;
    this.omitPortformGrp.value.addComment = null;
    this.commentPort.nativeElement.focus();
  }
  public addComment() {
    this.saveBtnDisabled = true;
    const postData = {
      scheduleId: this.scheduleId,
      comments: this.omitPortformGrp.value.addComment,
    };
    this.addCommmentEmit.emit({
      postData: postData,
    });
    /* this.serviceRepoService.postAddCommentService(postData).subscribe((res: any) => {

     }); */
  }
  public showButtons() {
    this.isBtnVisible = true;
  }
  public deleteMsg() {
    this.omitPortformGrp.patchValue({
      addComment: null,
    });
  }
  public endDeployment() {
    const postData = {
      serviceId: this.serviceId,

      scheduleId: this.scheduleId,

      scheduleDate: this.omitFromToDate.fromDate.scheduleDate,

      vesselDeploymentId: this.vesselDto.vesselDeploymentId,

      vesselId: this.vesselDto.vesselId,

      position: this.position,

      deploymentStartDate: this.vesselDto.deploymentStartDate,

      deploymentEndDate: this.vesselDto.deploymentEndDate,

      proformaPortId: this.proformaPortId,
    };
    this.endDep.emit({
      postData: postData,
    });
  }
  private saveOmitPortForm() {
    this.omitPortformGrp = this.formBuilder.group({
      omitPortStartDate: this.omitFromToDate ? this.omitFromToDate.fromDate.scheduleDate : null,
      omitPortEndDate: this.omitFromToDate ? this.omitFromToDate.toDate.scheduleDate : null,
      omitPosition: this.isOmitted ? '0' : 'this',
      addComment: this.deploymentPortComments ? this.deploymentPortComments : '',
    });
  }
  /** extraloader functionality */
  private addExtraloader() {
    // if extraloader is present but hidden, show extra position in UI
    if (this.isExtraloaderHidden) {
      this.close.emit({
        type: 'showExtraLoader',
        data: {
          position: this.position,
        },
      });
      return;
    }

    // if extraloader is not present, hit api
    const requestService = this.serviceRepo ? this.serviceRepoService : this.scenarioService;
    requestService.addExtraloader(this.datedScheduleId, this.position).subscribe(() => {
      this.close.emit({
        type: 'addExtraLoader',
        data: {
          position: this.position,
        },
      });
    });
  }
}
