import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, ValidationErrors } from '@angular/forms';
import { filter, takeUntil, distinctUntilChanged, take, map } from 'rxjs/operators';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { DatePipe } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import * as VesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';

import { isValidDate } from 'src/app/utils/valid-date';

import { CountryService } from 'src/app/core/services/country/country.service';
import { PortService } from 'src/app/core/services/port/port.service';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { BuildYardService } from 'src/app/core/services/build-yard/build-yard.service';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { IceClassService } from 'src/app/core/services/ice-class/ice-class.service';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  MY_FORMATS,
  dateTimeCompile,
  time,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { scrollToError } from '../../utils/scroll-utils';
import { AlertService } from 'src/app/core/services/alert/alert.service';

const moment = customMoment || _moment;
@Component({
  selector: 'champs-vessel-information',
  templateUrl: './vessel-information.component.html',
  styleUrls: ['./vessel-information.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class VesselInformationComponent implements OnInit, OnDestroy, OnChanges {
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
      this.vslInfoViewMode = false;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
      this.vslInfoViewMode = false;
    }
  }
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  private generalFormData: any;

  private _showFltrValue;

  public time = time;
  public countRow: any = 0;
  public fieldError: boolean;
  public fieldVSAError: boolean;
  public isVsInfoUpdateAuth: boolean;
  public fleetDateTimeError: boolean;
  @Input() public values: any;
  @Input() public message: any;
  @Input() public error: any;
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  public originalValues: any = null;

  public showRequiredOnly: boolean = false;

  public showFltrType: string = 'all';

  public vesselNewPage: boolean = false;
  public vesselQsImport: boolean = false;
  public vslInfoViewMode: boolean = false;
  public showCodeError: boolean = false;
  public codeErrorMessage: any;

  @Output() public update: EventEmitter<any> = new EventEmitter();
  @Output() public changeStep?: EventEmitter<any> = new EventEmitter();

  public vslInfoCreateMode: boolean;
  public vslInfoFrmGrp: UntypedFormGroup;
  public vslInfoFormObject: any;

  public countries$: Observable<any>;
  public operators$: Observable<any>;
  public classSocieties$: Observable<any>;
  public panamas$: Observable<any>;
  public itfs$: Observable<any>;
  public buildYards$: Observable<any>;
  public ports$: Observable<any>;
  public iceClasses: ReplaySubject<any> = new ReplaySubject(1);
  public noIce: boolean;

  public isCreateTypeHull: boolean;
  public disableIMO: boolean = false;
  public timeHourMinutePattern = '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$';
  public vesselType: boolean = true;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(
    private countryService: CountryService,
    private formBuilder: UntypedFormBuilder,
    private portService: PortService,
    private vesselsService: VesselsService,
    private buildYardService: BuildYardService,
    private vesselDropdowns: VesselDropdownsService,
    private classSocietyService: IceClassService,
    private store: Store<VesselCreate.State>,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService
  ) { }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsInfoUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    if (this.error || this.vesselNewPage || this.vesselQsImport) {
      this.vslInfoViewMode = false;
    } else {
      this.vslInfoViewMode = true;
    }
    this.operators$ = this.vesselDropdowns.getVesselOperators();
    this.panamas$ = this.vesselDropdowns.getPanamasFtd();
    this.itfs$ = this.vesselDropdowns.getITFFtd();
    this.buildYards$ = this.buildYardService.getBuildYards();
    this.ports$ = this.portService.getPorts();
    this.countries$ = this.countryService.getCountry();
    this.classSocieties$ = this.classSocietyService.getClassSocieties();
    this.classSocieties$ = this.classSocietyService.getClassSocieties();

    this.store.pipe(select(VesselCreate.getVesselCreateType), take(1)).subscribe((type) => {
      this.isCreateTypeHull = type && type === 'hull-number' ? true : false;
    });

    this.createGeneralInfoForm();

    this.classSocietyChanged();
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (this.error || this.vesselNewPage || this.vesselQsImport) {
      this.vslInfoViewMode = false;
    } else {
      this.vslInfoViewMode = true;
    }
    if (changes.values) {
      this.values = changes.values.currentValue;
    }
  }

  public validDate(d) {
    const error = isValidDate(d) ? true : false;
    if (!error) {
      return 'Invalid date';
    }
  }

  public vslInfoCancel() {
    this.vslInfoViewMode = !this.vslInfoViewMode;
    this.update.emit({
      type: 'cancel',
      value: this.vslInfoFrmGrp.getRawValue(),
    });
    this.createGeneralInfoForm();
  }
  public vslInfoSave() {
    if (this.vslInfoFrmGrp.invalid) {
      this.fieldError = true;
      this.fieldVSAError = true;
      let requiredError = false;
      Object.keys(this.vslInfoFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.vslInfoFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    const vesselCodeFrmArr1 = this.vslInfoFrmGrp.get('vesselCode') as UntypedFormArray;
    this.fleetDateTimeError = false;
    vesselCodeFrmArr1.controls.forEach((element) => {
      const inFleetDate = dateTimeCompile(element.get('vcInFleetDate').value, element.get('vcInFleetTime').value);
      const outFleetDate = dateTimeCompile(element.get('vcOutFleetDate').value, element.get('vcOutFleetTime').value);
      if (inFleetDate > outFleetDate) {
        this.fleetDateTimeError = true;
      }
    });
    if (this.fleetDateTimeError) {
      scrollToError(this.matErrorRefs);
      return;
    }

    if (this.vslInfoFrmGrp.dirty) {
      if (this.vslInfoFrmGrp.value.lmdPanama === null) {
        this.vslInfoFrmGrp.patchValue({
          lmdPanama: 'No',
        });
      }
      if (this.vslInfoFrmGrp.value.lmdITF === null) {
        this.vslInfoFrmGrp.patchValue({
          lmdITF: 'No',
        });
      }
    }

    // handling null for build country on edit
    if (this.vslInfoFrmGrp.value.vslBuildCountry === null) {
      this.vslInfoFrmGrp.patchValue({
        vslBuildCountry: null,
      });
    }
    // handling null for build year on edit
    if (this.vslInfoFrmGrp.value.vslBuildYear === null) {
      this.vslInfoFrmGrp.patchValue({
        vslBuildYear: null,
      });
    }

    this.update.emit({
      type: 'UpdateGeneral',
      value: this.vslInfoFrmGrp.getRawValue(),
    });
  }

  public classSocietyChanged(e: any = null) {
    const val = e ? e.csPKID : this.values.vslClassID;
    if (val && val > 0) {
      this.noIce = false;
      this.classSocietyService
        .getIceClassByCS(val)
        .pipe(
          filter((x) => x !== undefined),
          distinctUntilChanged()
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          if (res.length === 0) {
            this.noIce = true;
          }
          this.iceClasses.next(res);
          if (this.vslInfoFrmGrp.get('vslIceClassID').value) {
            const match = res.filter((iceClass) => iceClass.iclPKID === this.vslInfoFrmGrp.get('vslIceClassID').value);
            if (match.length === 0) {
              this.vslInfoFrmGrp.get('vslIceClassID').setValue(null);
            }
          }
        });
      return;
    }

    this.vslInfoFrmGrp.get('vslIceClassID').setValue(null);
  }

  public addVesselCode() {
    const vesselCodeFrmArr = this.vslInfoFrmGrp.get('vesselCode') as UntypedFormArray;
    vesselCodeFrmArr.push(
      this.formBuilder.group({
        vbID: null,
        vcPKID: 0,
        vslMLCode: this.formBuilder.control(null, [Validators.maxLength(3), Validators.minLength(3)]),
        vcType: null,
        vcInFleetDate: null,
        vcInFleetTime: null,
        vcOutFleetDate: null,
        vcOutFleetTime: null,
      })
    );
  }

  public deleteVesselCode(i) {
    const vesselCodeFrmArr = this.vslInfoFrmGrp.get('vesselCode') as UntypedFormArray;
    vesselCodeFrmArr.removeAt(i);
  }

  public nextStep() {
    this.showCodeError = false;
    if (this.vslInfoFrmGrp.invalid) {
      this.fieldError = true;

      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.vslInfoFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.vslInfoFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    if (this.vslInfoFrmGrp.value.lmdPanama === null) {
      this.vslInfoFrmGrp.patchValue({
        lmdPanama: 'No',
      });
    }
    if (this.vslInfoFrmGrp.value.lmdITF === null) {
      this.vslInfoFrmGrp.patchValue({
        lmdITF: null,
      });
    }
    // adding code to get vessel code checked on accpet instead of save
    const vesselCodeFrmArr1 = this.vslInfoFrmGrp.get('vesselCode') as UntypedFormArray;
    let vesselCodeList = [];
    const originalCode = [];
    vesselCodeFrmArr1.controls.forEach((a) => vesselCodeList.push(a.get('vslMLCode').value));
    if (new Set(vesselCodeList).size !== vesselCodeList.length) {
      this.showCodeError = true;
      this.codeErrorMessage = 'Vessel code already exists';
      scrollToError(this.matErrorRefs);
      return;
    }
    this.fleetDateTimeError = false;
    vesselCodeFrmArr1.controls.forEach((element) => {
      const inFleetDate = dateTimeCompile(element.get('vcInFleetDate').value, element.get('vcInFleetTime').value);
      const outFleetDate = dateTimeCompile(element.get('vcOutFleetDate').value, element.get('vcOutFleetTime').value);
      if (!inFleetDate && !this.vesselType) {
        this.fieldVSAError = true;
      } else {
        this.fieldVSAError = false;
      }
      if (inFleetDate > outFleetDate) {
        this.fleetDateTimeError = true;
      }
    });
    if (this.fieldVSAError) {
      scrollToError(this.matErrorRefs);
      return;
    }
    if (this.fleetDateTimeError) {
      scrollToError(this.matErrorRefs);
      return;
    }
    if (this.originalValues && this.originalValues.vesselCode) {
      this.originalValues.vesselCode.forEach((code) => {
        originalCode.push(code.vslMLCode);
      });
    }
    vesselCodeList = vesselCodeList.filter((item) => originalCode.indexOf(item) < 0);
    if (vesselCodeList.length) {
      this.vesselsService.checkDuplicateVesselCode(vesselCodeList.toString()).subscribe(
        (response) => {
          const postObj = {
            ...this.vslInfoFrmGrp.getRawValue(),
            ...{
              vslBuildYear: new DatePipe('en-US').transform(
                this.vslInfoFrmGrp.get('vslBuildYear').value,
                'dd-MMM-yyyy'
              ),
              vslFlag: this.vslInfoFrmGrp.get('vslFlag').value ? this.vslInfoFrmGrp.get('vslFlag').value : null,
              vslCountryReg: this.vslInfoFrmGrp.get('vslCountryReg').value
                ? this.vslInfoFrmGrp.get('vslCountryReg').value
                : null,
              vslHomePort: this.vslInfoFrmGrp.get('vslHomePort').value
                ? this.vslInfoFrmGrp.get('vslHomePort').value
                : null,
            },
          };
          postObj.vesselCode.forEach((element) => {
            element.vcInFleetDate = dateTimeCompile(element.vcInFleetDate, element.vcInFleetTime);
            element.vcOutFleetDate = dateTimeCompile(
              element.vcOutFleetDate,
              element.vcOutFleetTime
                ? element.vcOutFleetTime
                : new DatePipe('en-US').transform(
                  moment(new Date()).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(),
                  'HH:mm'
                )
            );
          });
          this.store.dispatch(new VesselCreateActions.PutGeneralInfo(postObj));

          this.changeStep.emit();
        },
        (errorResponse) => {
          if (errorResponse instanceof HttpErrorResponse) {
            const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
            if (error) {
              this.showCodeError = true;
              this.codeErrorMessage = error;
              scrollToError(this.matErrorRefs);
            }
          }
        }
      );
    } else {
      const postObj = {
        ...this.vslInfoFrmGrp.getRawValue(),
        ...{
          vslBuildYear: new DatePipe('en-US').transform(this.vslInfoFrmGrp.get('vslBuildYear').value, 'dd-MMM-yy'),
          vslFlag: this.vslInfoFrmGrp.get('vslFlag').value ? this.vslInfoFrmGrp.get('vslFlag').value : null,
          vslCountryReg: this.vslInfoFrmGrp.get('vslCountryReg').value
            ? this.vslInfoFrmGrp.get('vslCountryReg').value
            : null,
          vslHomePort: this.vslInfoFrmGrp.get('vslHomePort').value ? this.vslInfoFrmGrp.get('vslHomePort').value : null,
        },
      };

      this.store.dispatch(new VesselCreateActions.PutGeneralInfo(postObj));

      this.changeStep.emit();
    }
  }
  public enableEdit() {
    this.vslInfoViewMode = !this.vslInfoViewMode;
    this.createGeneralInfoForm();
  }
  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public vesselTypeChange(e) {
    this.vesselType = e.value === 'VSA' ? false : true;
    this.alertService.sharedVesselType.next(e.value);
    this.cdr.detectChanges();
  }

  private createGeneralInfoForm() {
    this.vslInfoFormObject = {
      vesselCode: this.formBuilder.array(this.createVesselCodesGrp(this.values.vesselCode)),
      vmVesselName: this.values.vmVesselName,
      vslVesselCategory: this.values.vslVesselCategory ? this.values.vslVesselCategory : 'Container',
      vmMONumber: this.formBuilder.control(this.values.vmMONumber, [
        Validators.minLength(7),
        Validators.maxLength(7),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslOfficialNo: [
        this.values.vslOfficialNo,
        [Validators.minLength(1), Validators.maxLength(9), Validators.pattern(new RegExp('^[a-zA-Z0-9-^ ]+$'))],
      ],
      vslMaidenName: this.values.vslMaidenName === '' ? null : this.values.vslMaidenName,
      vslExName: this.values.vslExName,
      vslFlag: this.formBuilder.control(this.values.vslFlag && this.values.vslFlag !== -1 ? this.values.vslFlag : null),
      vslCountryReg: this.formBuilder.control(
        this.values.vslCountryReg && this.values.vslCountryReg !== -1 ? this.values.vslCountryReg : null
      ),
      vslHomePort: this.formBuilder.control(
        this.values.vslHomePort && this.values.vslHomePort !== -1 ? this.values.vslHomePort : null
      ),
      vslSisterName: this.values.vslSisterName === '' ? null : this.values.vslSisterName,

      // handling -1 value for build country for upload questionnare scenario
      vslBuildCountry: this.formBuilder.control(
        this.values.vslBuildCountry === 0 || this.values.vslBuildCountry < 0 ? null : this.values.vslBuildCountry
      ),

      // vslBuildCountry: this.formBuilder.control(this.values.vslBuildCountry, Validators.min(1)),
      vslBuildYardID: this.formBuilder.control(
        this.values.vslBuildYardID > 0 ? this.values.vslBuildYardID : null,
        Validators.min(1)
      ),
      vslhullno: this.formBuilder.control(this.values.vslhullno, Validators.pattern(new RegExp('^[0-9]+$'))),

      vslBuildYear: this.formBuilder.control(this.values.vslBuildYear ? moment(this.values.vslBuildYear) : null), // date
      vslClassID: this.formBuilder.control(this.values.vslClassID > 0 ? this.values.vslClassID : null),
      vslIceClassID: this.formBuilder.control(this.values.vslIceClassID > 0 ? this.values.vslIceClassID : null), // new added
      vslPIClub: this.values.vslPIClub,
      vslHMValue: this.values.vslHMValue,
      vslTotalLoss: this.values.vslTotalLoss,
      vslOSHAFitted: this.values.vslOSHAFitted, // boolean
      lmdPanama: this.values.lmdPanama ? [this.values.lmdPanama] : null, // boolean
      vslSludgeTime: this.values.vslSludgeTime ? this.values.vslSludgeTime : false,
      vslSludgeCost: this.values.vslSludgeCost ? this.values.vslSludgeCost : false,
      lmdITF: this.values.lmdITF ? this.values.lmdITF : null,
    };

    this.vslInfoFrmGrp = this.formBuilder.group(this.vslInfoFormObject);

    if (this.vesselNewPage || this.vesselQsImport) {
      this.vslInfoFrmGrp.addControl('vslPKID', this.formBuilder.control(this.values.vslPKID));
    }

    if (this.isCreateTypeHull) {
      this.vslInfoFrmGrp.get('vslhullno').disable();
      this.vslInfoFrmGrp.get('vslBuildYardID').disable();
    }
    // else {
    //   this.disableIMO = true;
    // }
  }

  private createVesselCodesGrp(items): Array<UntypedFormGroup> {
    const grpArray = [];
    if (this.vesselQsImport) {
      if (this.originalValues && this.originalValues.vesselCode) {
        this.originalValues.vesselCode.forEach((code) => {
          items = items.filter((o) => o.vslMLCode.toLowerCase() !== code.vslMLCode.toLowerCase());
        });
        items = this.originalValues.vesselCode.concat(items);
      }
    }
    if (items) {
      items.forEach((code) => {
        const item = this.formBuilder.group({
          vbID: [code.vbID],
          vcPKID: [code.vcPKID],
          vslMLCode: this.formBuilder.control(code.vslMLCode, [Validators.maxLength(3), Validators.minLength(3)]),
          vcType: [code.vcType] ? [code.vcType] : null,
          vcInFleetDate: code.vcInFleetDate ? [moment(code.vcInFleetDate)] : null,
          vcInFleetTime: isValidDate(code.vcInFleetDate)
            ? new DatePipe('en-US').transform(code.vcInFleetDate, 'HH:mm')
            : null,
          vcOutFleetDate: code.vcOutFleetDate ? [moment(code.vcOutFleetDate)] : null,
          vcOutFleetTime: isValidDate(code.vcOutFleetDate)
            ? new DatePipe('en-US').transform(code.vcOutFleetDate, 'HH:mm')
            : null,
        });
        this.vesselType = code.vcType === 'VSA' ? false : true;
        if (!this.vesselType) {
          this.alertService.sharedVesselType.next('VSA');
        }
        grpArray.push(item);
      });
    }

    if (grpArray.length === 0) {
      grpArray.push(
        this.formBuilder.group({
          vbID: [],
          vcPKID: 0,
          vslMLCode: this.formBuilder.control(null, [Validators.maxLength(3), Validators.minLength(3)]),
          vcType: null,
          vcInFleetDate: null,
          vcInFleetTime: null,
          vcOutFleetDate: null,
          vcOutFleetTime: null,
        })
      );
    }

    return grpArray;
  }
}
