import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import * as VesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { PaintsService } from 'src/app/core/services/paints/paints.service';

import { reduceArray2Obj } from 'src/app/utils/reduce-array2Obj';
import { Subject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { scrollToError } from '../../utils/scroll-utils';

@Component({
  selector: 'champs-vessel-new-machinery-particulars',
  templateUrl: './vessel-new-machinery-particulars.component.html',
  styleUrls: ['./vessel-new-machinery-particulars.component.scss'],
})
export class VesselNewMachineryParticularsComponent implements OnInit {
  /** Subject that emits when the component has been destroyed. */
  private _showFltrValue;

  @Output() public changeStep: EventEmitter<any> = new EventEmitter();

  @Input() public values: any;

  public originalValues: any = null;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  public mainEngineFrmGrp: UntypedFormGroup;
  public shopTrialFrmGrp: UntypedFormGroup;
  public mainEngineSuplyInfoFrmGrp: UntypedFormGroup;
  public turboChargerCutOutFrmGrp: UntypedFormGroup;
  public propellerFrmGrp: UntypedFormGroup;
  public auxEngineFrmGrp: UntypedFormGroup;
  public reeferKWHFrmGrp: UntypedFormGroup;
  public turboGeneratorFrmGrp: UntypedFormGroup;
  public shaftGeneratorFrmGrp: UntypedFormGroup;
  public shaftMotorFrmGrp: UntypedFormGroup;
  public thrusterFrmGrp: UntypedFormGroup;
  public stabilisersFrmGrp: UntypedFormGroup;

  public showFormErrors: boolean = false;
  public isCreateTypeHull: boolean;
  public engineMaker$: Observable<any>;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<any>,
    private storeVessel: Store<VesselCreate.State>,
    public dialog: MatDialog,
    private paintsService: PaintsService
  ) {
    this.engineMaker$ = this.paintsService.getEngineMaker();
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.createMainEngineForm();
    this.shopTrialFrmGrp = this.formBuilder.group(this.createShopTrialForm(this.values.shopTrial));

    this.createMainEngineSuplyInfoForm();

    this.turboChargerCutOutFrmGrp = this.formBuilder.group({
      vslTCCOMode: this.values.vslTCCOMode,
      turboChargerCutOut: this.formBuilder.group(this.createTurboChargerCutOutForm(this.values.turboChargerCutOut)),
    });

    this.createPropellerForm();

    this.auxEngineFrmGrp = this.formBuilder.group({
      auxEngine: this.formBuilder.group(this.createAuxEngineForm(this.values.auxEngine)),
      vslAuxEstLoadAtSea: this.values.vslAuxEstLoadAtSea,
      vslAuxEstLoadAtPort: this.values.vslAuxEstLoadAtPort,
      vslAuxEstLoadDurManouvering: this.values.vslAuxEstLoadDurManouvering,
    });

    this.storeVessel.pipe(select(VesselCreate.getVesselCreateType), take(1)).subscribe((type) => {
      this.isCreateTypeHull = type && type === 'hull-number' ? true : false;
    });

    this.createReeferKWHForm();
    this.createTurboGeneratorForm();
    this.createShaftGeneratorForm();
    this.createShaftMotorForm();
    this.createThrusterForm();
    this.createStabilisersForm();
  }

  public nextStep() {
    if (
      !this.mainEngineFrmGrp.valid ||
      !this.shopTrialFrmGrp.valid ||
      !this.mainEngineSuplyInfoFrmGrp.valid ||
      !this.turboChargerCutOutFrmGrp.valid ||
      !this.propellerFrmGrp.valid ||
      !this.auxEngineFrmGrp.valid ||
      !this.reeferKWHFrmGrp.valid ||
      !this.turboGeneratorFrmGrp.valid ||
      !this.shaftGeneratorFrmGrp.valid ||
      !this.shaftMotorFrmGrp.valid ||
      !this.thrusterFrmGrp.valid ||
      !this.stabilisersFrmGrp.valid
    ) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.mainEngineFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.mainEngineFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (!requiredError) {
        Object.keys(this.propellerFrmGrp.controls).forEach((key) => {
          const controlErrors: ValidationErrors = this.propellerFrmGrp.get(key).errors;
          if (controlErrors != null) {
            for (const error of Object.keys(controlErrors)) {
              if (error === 'required') {
                requiredError = true;
                break;
              }
            }
          }
        });
      }
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }

    const valueObj = this.createPostObj();

    this.store.dispatch(new VesselCreateActions.PutMachineryParticulars(valueObj));

    this.changeStep.emit();
  }

  public updateBowThrusterChecked(option, event) {
    if (!event.checked) {
      this.thrusterFrmGrp.get('vslBowThruster').setValue(null);
    }
  }
  public updateSternThrusterChecked(option, event) {
    if (!event.checked) {
      this.thrusterFrmGrp.get('vslSternThruster').setValue(null);
    }
  }
  private createPostObj() {
    return {
      ...this.mainEngineFrmGrp.value,
      ...{
        shopTrial: reduceArray2Obj(this.shopTrialFrmGrp.value),
      },
      ...this.mainEngineSuplyInfoFrmGrp.value,
      ...{
        vslTCCOMode: this.turboChargerCutOutFrmGrp.get('vslTCCOMode').value,
        turboChargerCutOut: reduceArray2Obj(this.turboChargerCutOutFrmGrp.get('turboChargerCutOut').value),
      },
      ...this.propellerFrmGrp.value,
      ...{
        vslAuxEstLoadAtSea: this.auxEngineFrmGrp.get('vslAuxEstLoadAtSea').value,
        vslAuxEstLoadAtPort: this.auxEngineFrmGrp.get('vslAuxEstLoadAtPort').value,
        vslAuxEstLoadDurManouvering: this.auxEngineFrmGrp.get('vslAuxEstLoadDurManouvering').value,
        auxEngine: reduceArray2Obj(this.auxEngineFrmGrp.get('auxEngine').value),
      },
      ...this.reeferKWHFrmGrp.value,
      ...this.turboGeneratorFrmGrp.value,
      ...this.shaftGeneratorFrmGrp.value,
      ...this.shaftMotorFrmGrp.value,
      ...this.shaftMotorFrmGrp.value,
      ...this.thrusterFrmGrp.value,
      ...this.stabilisersFrmGrp.value,
    };
  }

  private createMainEngineForm() {
    this.mainEngineFrmGrp = this.formBuilder.group({
      vslMainEngCount: this.formBuilder.control(this.values.vslMainEngCount, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslMainEngineMaker: this.values.vslMainEngineMaker,
      vslMainEngineMakerID: this.values.vslMainEngineMakerID,
      vslMainEngineType: this.values.vslMainEngineType,
      vslMainEngStroke: this.formBuilder.control(this.values.vslMainEngStroke, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslMainEngOutput: this.formBuilder.control(this.values.vslMainEngOutput, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslMainEngRPM: this.formBuilder.control(this.values.vslMainEngRPM, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      vslMainEngCSR: this.formBuilder.control(this.values.vslMainEngCSR, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      vslMCR: this.formBuilder.control(this.values.vslMCR),
      vslMaxSpeed: this.values.vslMaxSpeed,
      vslMinSpeed: this.values.vslMinSpeed,
      vslMainEngExMCR: this.values.vslMainEngExMCR,
    });
  }

  private createShopTrialForm(items) {
    return {
      vstpkID: this.formBuilder.array(this.createColArr(items, 'vstpkID')),
      vstPower: this.formBuilder.array(this.createColArr(items, 'vstPower')),
      vstRPM: this.formBuilder.array(this.createColArr(items, 'vstRPM')),
      vstPMax: this.formBuilder.array(this.createColArr(items, 'vstPMax')),
      vstPComp: this.formBuilder.array(this.createColArr(items, 'vstPComp')),
      vstSFOC: this.formBuilder.array(this.createColArr(items, 'vstSFOC')),
      vstTCRPM: this.formBuilder.array(this.createColArr(items, 'vstTCRPM')),
      vstAirFilter: this.formBuilder.array(this.createColArr(items, 'vstAirFilter')),
      vstAirCooler: this.formBuilder.array(this.createColArr(items, 'vstAirCooler')),
      vstEPATC: this.formBuilder.array(this.createColArr(items, 'vstEPATC')),
      vstSATD: this.formBuilder.array(this.createColArr(items, 'vstSATD')),
      vstSAP: this.formBuilder.array(this.createColArr(items, 'vstSAP')),
      vstBIT: this.formBuilder.array(this.createColArr(items, 'vstBIT')),
      vstExhT: this.formBuilder.array(this.createColArr(items, 'vstExhT')),
      vstBP: this.formBuilder.array(this.createColArr(items, 'vstBP')),
      vstTIP: this.formBuilder.array(this.createColArr(items, 'vstTIP')),
      vstTIT: this.formBuilder.array(this.createColArr(items, 'vstTIT')),
      vstTOT: this.formBuilder.array(this.createColArr(items, 'vstTOT')),
      vstLCV: this.formBuilder.array(this.createColArr(items, 'vstLCV')),
      vstLoadDetails: this.formBuilder.array(this.createColArr(items, 'vstLoadDetails')),
      vstOrderNo: this.formBuilder.array(this.createColArr(items, 'vstOrderNo')),
    };
  }

  private createColArr(items, key) {
    const grpArr = [];
    items.forEach((item) => {
      grpArr.push(this.formBuilder.control(item[key]));
    });
    return grpArr;
  }

  private createMainEngineSuplyInfoForm() {
    this.mainEngineSuplyInfoFrmGrp = this.formBuilder.group({
      vslTCsuction: this.values.vslTCsuction, // to see for radio button
      vslTorMeterInstalled: this.values.vslTorMeterInstalled,
      vslAvgPowerAbility: this.values.vslTorMeterInstalled ? this.values.vslAvgPowerAbility : null,
      vslTorMeterBwMotorPropeller: this.values.vslTorMeterInstalled ? this.values.vslTorMeterBwMotorPropeller : null,
      vslTorMeterBwMotorMainEng: this.values.vslTorMeterInstalled ? this.values.vslTorMeterBwMotorMainEng : null,
      vslAuxBlowerRating: this.values.vslAuxBlowerRating,
      vslAuxBlowerCount: this.values.vslAuxBlowerCount,
      vslAuxBlowerMCR: this.values.vslAuxBlowerMCR,
      vslMLP: this.values.vslMLP,
      vslMEPMax: this.values.vslMEPMax,
      vslPmaxLimit: this.values.vslPmaxLimit,
      vslESP: this.values.vslESP,
      vslNominalMaxRPM: this.values.vslNominalMaxRPM,
      vslTurboChargerType: this.values.vslTurboChargerType,
      vslTurboChargerCount: this.formBuilder.control(this.values.vslTurboChargerCount, [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslTCMaxRPM: this.formBuilder.control(this.values.vslTCMaxRPM, [Validators.pattern(new RegExp('^[0-9]+$'))]),
    });
  }

  private createTurboChargerCutOutForm(items) {
    return {
      vccopkID: this.formBuilder.array(this.createColArr(items, 'vccopkID')),
      vccoengineRPM: this.formBuilder.array(this.createColArr(items, 'vccoengineRPM')),
      vccoPMax: this.formBuilder.array(this.createColArr(items, 'vccoPMax')),
      vccoPComp: this.formBuilder.array(this.createColArr(items, 'vccoPComp')),
      vccoSFOC: this.formBuilder.array(this.createColArr(items, 'vccoSFOC')),
      vccoTCRPM: this.formBuilder.array(this.createColArr(items, 'vccoTCRPM')),
      vccoAirFilter: this.formBuilder.array(this.createColArr(items, 'vccoAirFilter')),
      vccoAirCooler: this.formBuilder.array(this.createColArr(items, 'vccoAirCooler')),
      vccoEPATC: this.formBuilder.array(this.createColArr(items, 'vccoEPATC')),
      vccoSATD: this.formBuilder.array(this.createColArr(items, 'vccoSATD')),
      vccoSAP: this.formBuilder.array(this.createColArr(items, 'vccoSAP')),
      vccoBIT: this.formBuilder.array(this.createColArr(items, 'vccoBIT')),
      vccoExhT: this.formBuilder.array(this.createColArr(items, 'vccoExhT')),
      vccoBP: this.formBuilder.array(this.createColArr(items, 'vccoBP')),
      vccoLCV: this.formBuilder.array(this.createColArr(items, 'vccoLCV')),
      vccoLoadDetails: this.formBuilder.array(this.createColArr(items, 'vccoLoadDetails')),
      vccoOrderNo: this.formBuilder.array(this.createColArr(items, 'vccoOrderNo')),
    };
  }

  private createPropellerForm() {
    this.propellerFrmGrp = this.formBuilder.group({
      vslPropellerType: this.values.vslPropellerType, // to see for radio button
      vslPropellerDiameter: this.values.vslPropellerDiameter,
      vslPropellerPitch: this.values.vslPropellerPitch,
    });
  }

  private createAuxEngineForm(items) {
    return {
      vaePKID: this.formBuilder.array(this.createColArr(items, 'vaePKID')),
      vaeAuxEngineMaker: this.formBuilder.array(this.createColArr(items, 'vaeAuxEngineMaker')),
      vaeAuxEngineType: this.formBuilder.array(this.createColArr(items, 'vaeAuxEngineType')),
      vaeSerialNo: this.formBuilder.array(this.createColArr(items, 'vaeSerialNo')),
      vaeTurboChargerNo: this.formBuilder.array(this.createColArr(items, 'vaeTurboChargerNo')),
      vaeRPM: this.formBuilder.array(this.createColArr(items, 'vaeRPM')),
      vaeAuxMaxGenPower: this.formBuilder.array(this.createColArr(items, 'vaeAuxMaxGenPower')),
      vaeMinOperLoad: this.formBuilder.array(this.createColArr(items, 'vaeMinOperLoad')),
      vaeVoltage: this.formBuilder.array(this.createColArr(items, 'vaeVoltage')),
      vaeFrequency: this.formBuilder.array(this.createColArr(items, 'vaeFrequency')),
      vaePowerFactor: this.formBuilder.array(this.createColArr(items, 'vaePowerFactor')),
      vaeKWHCounterInstalled: this.formBuilder.array(this.createColArr(items, 'vaeKWHCounterInstalled')),
      vaeEngineNo: this.formBuilder.array(this.createColArr(items, 'vaeEngineNo')),
      vaeOrderNo: this.formBuilder.array(this.createColArr(items, 'vaeOrderNo')),
    };
  }

  private createReeferKWHForm() {
    this.reeferKWHFrmGrp = this.formBuilder.group({
      vslReeferKWHInstalled: this.values.vslReeferKWHInstalled,
    });
  }

  private createTurboGeneratorForm() {
    this.turboGeneratorFrmGrp = this.formBuilder.group({
      vslTurboGenInstalled: this.values.vslTurboGenInstalled,
      vslTurboGenMake: this.values.vslTurboGenInstalled ? this.values.vslTurboGenMake : null,
      vslTurboGenType: this.values.vslTurboGenInstalled ? this.values.vslTurboGenType : null,
      vslTurboGenMaxPower: this.values.vslTurboGenInstalled ? this.values.vslTurboGenMaxPower : null,
      vslTurboGenMCR: this.values.vslTurboGenInstalled ? this.values.vslTurboGenMCR : null,
      vslTurboGenKWHCounterInstalled: this.values.vslTurboGenInstalled
        ? this.values.vslTurboGenKWHCounterInstalled
        : null,
    });
  }

  private createShaftGeneratorForm() {
    this.shaftGeneratorFrmGrp = this.formBuilder.group({
      vslShaftGenInstalled: this.values.vslShaftGenInstalled,
      vslShaftGenMake: this.values.vslShaftGenInstalled ? this.values.vslShaftGenMake : null,
      vslShaftGenType: this.values.vslShaftGenInstalled ? this.values.vslShaftGenType : null,
      vslShaftGenMaxPower: this.values.vslShaftGenInstalled ? this.values.vslShaftGenMaxPower : null,
      vslShaftGenMCR: this.values.vslShaftGenInstalled ? this.values.vslShaftGenMCR : null,
      vslShaftGenKWHCounterInstalled: this.values.vslShaftGenInstalled
        ? this.values.vslShaftGenKWHCounterInstalled
        : null,
    });
  }

  private createShaftMotorForm() {
    this.shaftMotorFrmGrp = this.formBuilder.group({
      vslShaftMotorInstalled: this.values.vslShaftMotorInstalled,
      vslShaftMotorMake: this.values.vslShaftMotorInstalled ? this.values.vslShaftMotorMake : null,
      vslShaftMotorType: this.values.vslShaftMotorInstalled ? this.values.vslShaftMotorType : null,
      vslShaftMotorRating: this.values.vslShaftMotorInstalled ? this.values.vslShaftMotorRating : null,
    });
  }

  private createThrusterForm() {
    this.thrusterFrmGrp = this.formBuilder.group({
      vslBowThrusterInstalled: this.values.vslBowThrusterInstalled,
      vslBowThruster: this.formBuilder.control(
        this.values.vslBowThrusterInstalled ? this.values.vslBowThruster : null,
        [Validators.pattern(new RegExp('^[0-9]+$'))]
      ),
      vslSternThrusterInstalled: this.values.vslSternThrusterInstalled,
      vslSternThruster: this.formBuilder.control(
        this.values.vslSternThrusterInstalled ? this.values.vslSternThruster : null,
        [Validators.pattern(new RegExp('^[0-9]+$'))]
      ),
    });
  }

  private createStabilisersForm() {
    this.stabilisersFrmGrp = this.formBuilder.group({
      vslStabiliserInstalled: this.values.vslStabiliserInstalled,
    });
  }
}
