//

import { Component, OnInit, Attribute, ChangeDetectorRef, Input, ViewEncapsulation } from '@angular/core';
import { MatDatepickerToggle, MatDatepickerIntl } from '@angular/material/datepicker';
import { CustomDatetimepickerComponent } from '../champs-custom-datetimepicker/custom-datetimepicker.component';

@Component({
  selector: 'champs-champs-custom-datetimepicker-toggle',
  templateUrl: './champs-custom-datetimepicker-toggle.component.html',
  styleUrls: ['./champs-custom-datetimepicker-toggle.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'mat-datepicker-toggle',
    // Clear out the native tabindex here since we forward it to the underlying button
    '[attr.tabindex]': 'null',
    '[class.mat-datepicker-toggle-active]': 'datepicker && datepicker.opened',
    '[class.mat-accent]': 'datepicker && datepicker.color === "accent"',
    '[class.mat-warn]': 'datepicker && datepicker.color === "warn"',
  },
  encapsulation: ViewEncapsulation.None,
})
export class ChampsCustomDatetimepickerToggleComponent<D> extends MatDatepickerToggle<D> implements OnInit {
  /** Datepicker instance that the button will toggle. */
  // tslint:disable-next-line:no-input-rename
  @Input('for') public datepicker: CustomDatetimepickerComponent<D> | any;

  constructor(
    _intl: MatDatepickerIntl,
    _changeDetectorRef: ChangeDetectorRef,
    @Attribute('tabindex') defaultTabIndex: string
  ) {
    super(_intl, _changeDetectorRef, defaultTabIndex);
  }

  public ngOnInit() {}
}
