//

import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[inputNumber]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputNumberDirective), multi: true }
  ]
})
export class InputNumberDirective implements Validator {

  private validator: ValidatorFn;

  private _onChange: () => void;

  private value;

  @Input() public minStrict?: any = null;
  @Input() public noDecimal?: boolean = false;

  constructor() {
    this.validator = this.numberValidator();
  }

  public validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private numberValidator(): ValidatorFn {
    return (c: UntypedFormControl) => {
      if (isNaN(c.value) || /\s/.test(c.value)) {
        return {
          isNaN: true
        };
      } else if (this.noDecimal && c.value && String(c.value).includes('.')) {
        return {
          hasDecimal: true
        };
      } else {
        if (c.value && this.minStrict && !isNaN(this.minStrict)) {
          return c.value > parseFloat(this.minStrict) ? null : {
            required: true
          };
        }
        return null;
      }
    };
  }
}
