// service-repository services
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceRepositoryService {
  private url = document.URL;
  private baseUrl =
    this.url.search(/localhost/gi) !== -1 || this.url.search(/wapazewdmlit001champsweb.crb.apmoller.net/gi) !== -1
      ? environment.baseUrl + ''
      : environment.baseUrl;
  public scheduleDetail: any;
  /** use button in replace vessel */
  public disableUse: Subject<boolean> = new Subject();
  constructor(private http: HttpClient) {}

  public getServices() {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceList');
  }
  // API to get Filters on Planner Screen
  public getFiltersForPlanner() {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/SwitchPlannerViewList');
  }
  // for post favourite api
  public postMarkServiceAsFavouriteOrUnfavourite(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/MarkServiceAsFavouriteOrUnfavourite/',
      postData
    );
  }
  // for post proforma port type
  public postServiceProformaPortType(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateProformaPortType ', postData);
  }
  // for update schedule comments
  public updateScheduleComment(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateScheduleComments ', postData);
  }
  public getServiceOverview(serviceId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceOverviewHeader/' + serviceId);
  }
  public getServiceOverviewDropDown() {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceOverviewHeaderDropDownList');
  }
  public postEditServiceOverview(updateData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/EditServiceOverviewHeader/Update',
      updateData
    );
  }
  public postCreateUpdateService(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/CreateOrUpdateService', postData);
  }
  public getCurrentRotation(serviceId) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetCurrentRotationsBlockByServiceId/' + serviceId
    );
  }
  public getCurrentVessel(serviceId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ServiceOverviewCurrentVessels/' + serviceId);
  }
  public getScheduleLst(serviceId) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetServiceOverviewSchedulesByService/' + serviceId
    );
  }
  // Get Schedule Data
  public getScheduleDetail(schId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/ScheduleOverviewHeader/' + schId);
  }
  // Get schedule dating
  public getScheduleDating(schId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetScheduleDatingByProformaId/' + schId);
  }
  // Post Schedule dating in and out ports
  public postInOutPortsService(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/UpdateDefaultPhaseInPhaseOutPorts/',
      postData
    );
  }
  // Delete Bunker Ports-Schedule dating
  public deleteBunkerPortsService(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateIsBunkerPort/', postData);
  }
  public getScheduleRotation(schId) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetRotationsBlockByProformaId/' + schId);
  }

  // get TCO value
  public getScheduleTCO(schId) {
    // prevent when schId is null
    if (schId) {
      return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetScheduleOverviewTCOBlock/' + schId);
    }
    return of();
  }

  public postUpdateScheduleVisibility(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateScheduleVisibility/', postData);
  }
  // Get Vessel Requirements
  public getVesselRequirements(schId) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetScheduleOverviewVesselRequirement/' + schId
    );
  }
  public getVesselRequirementsExtendToDummy(vesselId, proformaId) {
    return this.http.get(
      this.baseUrl +
        '/api/ServiceRepositoryManagement/GetExtendToDummyVesselRequirementsAsync/' +
        vesselId +
        '/' +
        proformaId
    );
  }
  // Post Vessel Requirements data
  public postVesselReqUpdateService(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/EditScheduleOverviewVesselRequirement/Update/',
      postData
    );
  }
  // Get Segment List
  public getSegmentList() {
    return this.http.get(this.baseUrl + '/api/DictionaryManagement/VesselTEU');
  }
  // Get service planning view list now signature has changed Proforma id will be passed instead of service id
  public getServicePlanningLst(proformaId) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetServicePlanningViewByProformaId/' + proformaId
    );
  }
  public postOmitPortUpdateService(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateIsOmitted', postData);
  }
  public getAdujstingVisibleDate(schId, fromDate, toDate) {
    return this.http.get(
      this.baseUrl +
        '/api/ServiceRepositoryManagement/GetSchedulePlanningViewByDates/' +
        schId +
        '/' +
        fromDate +
        '/' +
        toDate
    );
  }
  public postAddCommentService(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/UpdateCommentsForDeploymentPortDate',
      postData
    );
  }
  public postEndDeployment(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/EndDeployment', postData);
  }

  // post TCO data
  public saveTCO(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/AddOrUpdateScheduleOverviewTCO', postData);
  }
  // get vessel class for TCO
  public getVesselClass() {
    return this.http.get(this.baseUrl + '/api/DictionaryManagement/VesselTEU');
  }
  public getVesselDesign() {
    return this.http.get(this.baseUrl + '/api/DictionaryManagement/VesselDesignMapping');
  }
  public getManualScheduleLst(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/ManualScheduleImport', postData);
  }
  public postManualScheduleLst(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/AddProformaHeaderToSchedules', postData);
  }
  public postCreateUpdateDummy(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceManagement/CharterRequest/CreateOrUpdate', postData);
  }
  public postUpdateCharterRequestOnSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateCharterRequestOnSchedule', postData);
  }
  public getRequirementHeader(id) {
    return this.http.get(this.baseUrl + '/api/ServiceRepositoryManagement/GetVesselFinderVesselRequirements/' + id);
  }
  public getVesselList(id, startdate, enddate) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/VesselFinderVesselsList/' + id + '/' + startdate + '/' + enddate
    );
  }
  public postUpdateDeploymentCommentsAsync(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateDeploymentCommentsAsync', postData);
  }

  // delete vessel finder service
  public deleteVesselService(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UseVesselDeleteDeployment/', postData);
  }

  public useVessel(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UseVesselCutOffDeployment/', postData);
  }

  public useForWithoutDeployment(postData) {
    return this.http.post(
      this.baseUrl + '/api/VesselManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForScheduleAsync/',
      postData
    );
  }
  public postImportSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/ImportScheduleAsync', postData);
  }
  public postUpdateSchedule(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateScheduleHeaderAsync', postData);
  }
  public postSchedulePlannerViewForNewSchedule(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForNewSchedule',
      postData
    );
  }
  public postSchedulePlannerViewForNewScheduleDatesChanged(postData, fromDate, toDate) {
    return this.http.post(
      this.baseUrl +
        '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForNewScheduleDatesChanged?validFrom=' +
        fromDate +
        '&validTo=' +
        toDate,
      postData
    );
  }
  public deleteCharterRequest(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceManagement/CharterRequest/Remove/', postData);
  }
  public postSchedulePlannerScheduleCutHere(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetSchedulePlanningViewForScheduleDating',
      postData
    );
  }
  public postSchedulePlannerForNewScheduleCutOff(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForNewScheduleCutOff',
      postData
    );
  }

  public postCreateUpdateScheduleDating(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/CreateUpdateScheduleDating', postData);
  }

  public getRedeliverPortsAndDateService(vesselId, position, proformaId, fixtureId) {
    return this.http.get(
      this.baseUrl +
        '/api/ServiceRepositoryManagement/GetRedeliverPorts/' +
        vesselId +
        '/' +
        position +
        '/' +
        proformaId +
        '/' +
        fixtureId
    );
  }
  public updateRedeliverPort(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/UpdateRedeliverPort', postData);
  }
  /** Service to get fixture overview */
  public getFixtureOverview(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureOverview/' + fixtureId);
  }
  public getBunkerForCharter() {
    return this.http.get(environment.baseUrl + '/api/AverageBunker/BunkerPriceForCharterRequest');
  }

  public createVesselDeployment(postData) {
    return this.http.post(
      this.baseUrl + '/api/VesselManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForSchedulePlannerAsync',
      postData
    );
  }
  public deleteVesselDeployment(postData) {
    return this.http.post(
      environment.baseUrl + '/api/VesselManagement/VesselDeployement/DeleteVesselDeploymentOnSchedulePlannerAsync',
      postData
    );
  }
  public getClassList() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/VesselTEU');
  }
  public getMarketRate(stVesselDesignMappingId: any) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceRepositoryManagement/GetMarketRates/' + stVesselDesignMappingId
    );
  }
  public getLastDeploymentOnServiceByPosition(serviceId, position, crPhaseInStartDate) {
    return this.http.get(
      environment.baseUrl +
        '/api/ServiceRepositoryManagement/GetLastDeploymentOnServiceByPosition/' +
        serviceId +
        '/' +
        position +
        '/' +
        crPhaseInStartDate
    );
  }
  // Charter Request Get Vessel candidate id
  public getApprovalData(id) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceManagement/CharterRequest/ManagementApproval/Summary?charterRequestId=' + id
    );
  }
  public getGetVesselByDeploymentAsync(vesselDepId: any) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceRepositoryManagement/GetVesselByDeploymentAsync/' + vesselDepId
    );
  }
  public postUploadTcoFromExcel(rateExlObj, proformaId) {
    const url = environment.baseUrl + '/api/ServiceRepositoryManagement/UploadTCO?proformaId=' + proformaId;

    return this.http.post(url, rateExlObj, {
      reportProgress: true,
    });
  }
  /**  get roundtrip days from proforma id */
  public getRoundtripDaysByProfoId(profoId) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceRepositoryManagement/GetRoundTripDaysByProformaId/' + profoId
    );
  }

  public getPostfixtureRedeliverNoticesAsync(fixtureId) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceRepositoryManagement/GetPostfixtureRedeliverNoticesAsync/' + fixtureId
    );
  }

  public addExtraloader(datedScheduleId: number, position: number) {
    const body = {
      datedScheduleId,
      position,
    };
    return this.http.post(environment.baseUrl + '/api/ServiceRepositoryManagement/CreateExtraloaderPosition', body);
  }
  public getCurrentDeployment(serviceId, proformaId) {
    return this.http.get(
      this.baseUrl + '/api/ServiceRepositoryManagement/ScheduleOverviewVesselDeployed/' + serviceId + '/' + proformaId
    );
  }
  // api for delete manual imported proforma if import not succeeded
  public DeleteProformaHeader(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/DeleteProformaHeader/', postData);
  }
  // api for prolong schedule
  public prolongSchedule(postData) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForProlongSchedule/',
      postData
    );
  }
  // api to get date when shortened or prolonged
  public getNewScheduleEndDateOnProlong(postData) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/GetNewScheduleEndDateOnProlong/', postData);
  }
  // api to auto shorten schedule
  public autoShortenSchedule(proformaId, confirmAutoShorten) {
    return this.http.post(
      this.baseUrl + '/api/ServiceRepositoryManagement/AutoShortenSchedule/' + proformaId + '/' + confirmAutoShorten,
      ''
    );
  }
  // api to cut schedule
  public cutDeploymentAndSchedule(postObj) {
    return this.http.post(this.baseUrl + '/api/ServiceRepositoryManagement/CutDeploymentAndSchedule/', postObj);
  }
}
