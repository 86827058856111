//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomDatepickerComponent } from './champs-custom-datepicker/custom-datepicker.component';
import { CustomDatePickerAdapter } from 'src/app/components/custom-date-picker/custom-date-picker-adapter';
import { CustomDatetimepickerComponent } from './champs-custom-datetimepicker/custom-datetimepicker.component';
import { CustomDatetimepickerContentComponent } from './champs-custom-datetimepicker-content/custom-datetimepicker-content.component';
import { CustomDatetimepickerInput } from './custom-datetimepicker-input.directive';
import { ChampsCustomDatetimepickerToggleComponent } from './champs-custom-datetimepicker-toggle/champs-custom-datetimepicker-toggle.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
    ],
    declarations: [
        CustomDatepickerComponent,
        CustomDatetimepickerComponent,
        CustomDatetimepickerInput,
        CustomDatetimepickerContentComponent,
        ChampsCustomDatetimepickerToggleComponent,
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDatePickerAdapter },
        MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    ],
    exports: [
        CustomDatepickerComponent,
        CustomDatetimepickerComponent,
        CustomDatetimepickerInput,
        ChampsCustomDatetimepickerToggleComponent,
    ]
})
export class CustomDatepickerModule {}
