//

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewChecked,
  OnChanges,
  SimpleChange,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { reduceArray2Obj } from 'src/app/utils/reduce-array2Obj';
import { Store } from '@ngrx/store';
import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { deepmerge } from 'src/app/utils/deep-merge';
import { emptyCreateVessel } from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';
@Component({
  selector: 'champs-speed-and-consumption',
  templateUrl: './speed-and-consumption.component.html',
  styleUrls: ['./speed-and-consumption.component.scss'],
})
export class SpeedAndConsumptionComponent implements OnInit, AfterViewChecked, OnChanges {
  private EPCFormData: any;
  private B4FormData: any;
  private B2FormData: any;
  private _showFltrValue;

  @Output() public update: EventEmitter<any> = new EventEmitter();

  public originalValues: any = null;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  @Output() public changeStep: EventEmitter<any> = new EventEmitter();

  @Input() public values: any;

  public EPCViewMode: boolean = true;
  public B2ViewMode: boolean = true;
  public B4ViewMode: boolean = true;

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
      this.EPCViewMode = false;
      this.B2ViewMode = false;
      this.B4ViewMode = false;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
      this.EPCViewMode = false;
      this.B2ViewMode = false;
      this.B4ViewMode = false;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  public EPCFrmGrp: UntypedFormGroup;
  public B2FrmGrp: UntypedFormGroup;
  public B4FrmGrp: UntypedFormGroup;
  public auxSeaFrmGrp: UntypedFormGroup;
  public auxPortFrmGrp: UntypedFormGroup;
  public boilerFrmGrp: UntypedFormGroup;

  public showFormErrors: boolean = false;
  public isVsSpCpUpdateAuth: boolean;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store<any>, private auth: AuthService) {}

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsSpCpUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.values = deepmerge(
      {
        enginePowerConsumption: emptyCreateVessel.form.enginePowerConsumption,
        beaufort2: emptyCreateVessel.form.beaufort2,
        beaufort4: emptyCreateVessel.form.beaufort4,
      },
      this.values
    );

    this.EPCFrmGrp = this.formBuilder.group(this.createEPCFrmGrp(this.values.enginePowerConsumption));
    this.B2FrmGrp = this.formBuilder.group(this.createB2FrmGrp(this.values.beaufort2));
    this.B4FrmGrp = this.formBuilder.group({
      beaufort4: this.formBuilder.group(this.createB4FrmGrp(this.values.beaufort4)),
    });
    if (this.vesselNewPage || this.vesselQsImport) {
      this.B4FrmGrp.addControl('vslLCVUsedForConsump', this.formBuilder.control(this.values.vslLCVUsedForConsump));
    }
    this.createAuxSeaFrmGrp();
    this.createAuxPortFrmGrp();
    this.createBoilerFrmGrp();
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (changes.values) {
      this.values = changes.values.currentValue;
    }
  }

  /**
   * after view checked life-cycle hook
   */
  public ngAfterViewChecked() {
    this.EPCFrmGrp.valueChanges.subscribe((values) => {
      this.EPCFormData = Object.keys(values).reduce(
        (res, val) => {
          res[0][val] = values[val][0];
          res[1][val] = values[val][1];
          return res;
        },
        [{}, {}]
      );
    });
    this.B2FrmGrp.valueChanges.subscribe((values) => {
      this.B2FormData = Object.keys(values).reduce(
        (res, val) => {
          res[0][val] = values[val][0];
          res[1][val] = values[val][1];
          return res;
        },
        [{}, {}]
      );
    });
    this.B4FrmGrp.valueChanges.subscribe((values) => {
      this.B4FormData = Object.keys(values.beaufort4).reduce(
        (res, val) => {
          res[0][val] = values.beaufort4[val][0];
          res[1][val] = values.beaufort4[val][1];
          return res;
        },
        [{}, {}]
      );
    });
  }

  public EPCSave(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.EPCFrmGrp.valid) {
      this.showFormErrors = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.EPCViewMode = true;
    this.update.next({
      type: 'UpdateEnginePower',
      value: {
        enginePowerConsumption: this.EPCFormData,
      },
    });
  }

  public B2Save(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.B2FrmGrp.valid) {
      this.showFormErrors = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.B2ViewMode = true;
    this.update.next({
      type: 'UpdateBeaufort2',
      value: {
        beaufort2: this.B2FormData,
      },
    });
  }

  public B4Save(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.B4FrmGrp.valid) {
      this.showFormErrors = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.B4ViewMode = true;
    this.update.next({
      type: 'UpdateBeaufort4',
      value: {
        beaufort4: this.B4FormData,
      },
    });
  }

  public editEPCClicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.EPCViewMode = !this.EPCViewMode;
  }
  public cancelEPCClicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.EPCViewMode = !this.EPCViewMode;
    this.EPCFrmGrp = this.formBuilder.group(this.createEPCFrmGrp(this.values.enginePowerConsumption));
  }

  public editB2Clicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.B2ViewMode = !this.B2ViewMode;
  }
  public cancelB2Clicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.B2ViewMode = !this.B2ViewMode;
    this.B2FrmGrp = this.formBuilder.group(this.createB2FrmGrp(this.values.beaufort2));
  }
  public editB4Clicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.B4ViewMode = !this.B4ViewMode;
  }
  public cancelB4Clicked(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.B4ViewMode = !this.B4ViewMode;
    this.B4FrmGrp = this.formBuilder.group({
      beaufort4: this.formBuilder.group(this.createB4FrmGrp(this.values.beaufort4)),
    });
    if (this.vesselNewPage || this.vesselQsImport) {
      this.B4FrmGrp.addControl('vslLCVUsedForConsump', this.formBuilder.control(this.values.vslLCVUsedForConsump));
    }
  }

  public nextStep(e: any = null) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (
      !this.EPCFrmGrp.valid ||
      !this.B2FrmGrp.valid ||
      !this.B4FrmGrp.valid ||
      !this.auxSeaFrmGrp.valid ||
      !this.auxPortFrmGrp.valid ||
      !this.boilerFrmGrp.valid
    ) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
        // return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }

    const valueObj = this.createPostObj();

    this.store.dispatch(new VesselCreateActions.PutSpeedConsumption(valueObj));

    this.changeStep.emit();
  }

  private createPostObj() {
    return {
      ...{
        enginePowerConsumption: reduceArray2Obj(this.EPCFrmGrp.value),
      },
      ...{
        beaufort2: reduceArray2Obj(this.B2FrmGrp.value),
      },
      ...{
        beaufort4: reduceArray2Obj(this.B4FrmGrp.get('beaufort4').value),
        vslLCVUsedForConsump: this.B4FrmGrp.get('vslLCVUsedForConsump').value,
      },
      ...this.auxSeaFrmGrp.value,
      ...this.auxPortFrmGrp.value,
      ...this.boilerFrmGrp.value,
    };
  }

  private createEPCFrmGrp(items): any {
    return {
      vepPKID: this.formBuilder.array(this.createColArr(items, 'vepPKID')),
      vepMCR90: this.formBuilder.array(this.createColArr(items, 'vepMCR90')),
      vepMCR80: this.formBuilder.array(this.createColArr(items, 'vepMCR80')),
      vepMCR70: this.formBuilder.array(this.createColArr(items, 'vepMCR70')),
      vepMCR60: this.formBuilder.array(this.createColArr(items, 'vepMCR60')),
      vepMCR50: this.formBuilder.array(this.createColArr(items, 'vepMCR50')),
      vepMCR40: this.formBuilder.array(this.createColArr(items, 'vepMCR40')),
      vepMCR30: this.formBuilder.array(this.createColArr(items, 'vepMCR30')),
      vepMCR20: this.formBuilder.array(this.createColArr(items, 'vepMCR20')),
      vepMCR10: this.formBuilder.array(this.createColArr(items, 'vepMCR10')),
      vepMCRLoadDetails: this.formBuilder.array(this.createColArr(items, 'vepMCRLoadDetails')),
      vepOrderNo: this.formBuilder.array(this.createColArr(items, 'vepOrderNo')),
    };
  }

  private createB2FrmGrp(items): any {
    return {
      vbPKID: this.formBuilder.array(this.createColArr(items, 'vbPKID')),
      vbB2Speed10: this.formBuilder.array(this.createColArr(items, 'vbB2Speed10')),
      vbB2Speed11: this.formBuilder.array(this.createColArr(items, 'vbB2Speed11')),
      vbB2Speed12: this.formBuilder.array(this.createColArr(items, 'vbB2Speed12')),
      vbB2Speed13: this.formBuilder.array(this.createColArr(items, 'vbB2Speed13')),
      vbB2Speed14: this.formBuilder.array(this.createColArr(items, 'vbB2Speed14')),
      vbB2Speed15: this.formBuilder.array(this.createColArr(items, 'vbB2Speed15')),
      vbB2Speed16: this.formBuilder.array(this.createColArr(items, 'vbB2Speed16')),
      vbB2Speed17: this.formBuilder.array(this.createColArr(items, 'vbB2Speed17')),
      vbB2Speed18: this.formBuilder.array(this.createColArr(items, 'vbB2Speed18')),
      vbB2Speed19: this.formBuilder.array(this.createColArr(items, 'vbB2Speed19')),
      vbB2Speed20: this.formBuilder.array(this.createColArr(items, 'vbB2Speed20')),
      vbB2Speed21: this.formBuilder.array(this.createColArr(items, 'vbB2Speed21')),
      vbB2Speed22: this.formBuilder.array(this.createColArr(items, 'vbB2Speed22')),
      vbB2Speed23: this.formBuilder.array(this.createColArr(items, 'vbB2Speed23')),
      vbB2Speed24: this.formBuilder.array(this.createColArr(items, 'vbB2Speed24')),
      vbSpeedType: this.formBuilder.array(this.createColArr(items, 'vbSpeedType')),
      vbOrderNo: this.formBuilder.array(this.createColArr(items, 'vbOrderNo')),
    };
  }

  private createB4FrmGrp(items): any {
    return {
      vbPKID: this.formBuilder.array(this.createColArr(items, 'vbPKID')),
      vbB4Speed10: this.formBuilder.array(this.createColArr(items, 'vbB4Speed10')),
      vbB4Speed11: this.formBuilder.array(this.createColArr(items, 'vbB4Speed11')),
      vbB4Speed12: this.formBuilder.array(this.createColArr(items, 'vbB4Speed12')),
      vbB4Speed13: this.formBuilder.array(this.createColArr(items, 'vbB4Speed13')),
      vbB4Speed14: this.formBuilder.array(this.createColArr(items, 'vbB4Speed14')),
      vbB4Speed15: this.formBuilder.array(this.createColArr(items, 'vbB4Speed15')),
      vbB4Speed16: this.formBuilder.array(this.createColArr(items, 'vbB4Speed16')),
      vbB4Speed17: this.formBuilder.array(this.createColArr(items, 'vbB4Speed17')),
      vbB4Speed18: this.formBuilder.array(this.createColArr(items, 'vbB4Speed18')),
      vbB4Speed19: this.formBuilder.array(this.createColArr(items, 'vbB4Speed19')),
      vbB4Speed20: this.formBuilder.array(this.createColArr(items, 'vbB4Speed20')),
      vbB4Speed21: this.formBuilder.array(this.createColArr(items, 'vbB4Speed21')),
      vbB4Speed22: this.formBuilder.array(this.createColArr(items, 'vbB4Speed22')),
      vbB4Speed23: this.formBuilder.array(this.createColArr(items, 'vbB4Speed23')),
      vbB4Speed24: this.formBuilder.array(this.createColArr(items, 'vbB4Speed24')),
      vbSpeedType: this.formBuilder.array(this.createColArr(items, 'vbSpeedType')),
      vbOrderNo: this.formBuilder.array(this.createColArr(items, 'vbOrderNo')),
    };
  }

  private createColArr(items, key) {
    const grpArr = [];
    items.forEach((item) => {
      grpArr.push(this.formBuilder.control(item[key]));
    });
    return grpArr;
  }

  private createAuxSeaFrmGrp(): any {
    this.auxSeaFrmGrp = this.formBuilder.group({
      vslAuxConsumpAtSea: this.values.vslAuxConsumpAtSea,
      vslAuxLCVUsed: this.values.vslAuxLCVUsed,
    });
  }

  private createAuxPortFrmGrp(): any {
    this.auxPortFrmGrp = this.formBuilder.group({
      vslAuxConsumpAtPort: this.values.vslAuxConsumpAtPort,
      vslAuxPortLCVUsed: this.values.vslAuxPortLCVUsed,
    });
  }

  private createBoilerFrmGrp(): any {
    this.boilerFrmGrp = this.formBuilder.group({
      vslBolierConsumpAtSea: this.values.vslBolierConsumpAtSea,
      vslBoilerSeaLCVUsed: this.values.vslBoilerSeaLCVUsed,
      vslBolierConsumpAtPort: this.values.vslBolierConsumpAtPort,
      vslBoilerPortLCVUsed: this.values.vslBoilerPortLCVUsed,
    });
  }
}
