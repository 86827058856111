//

import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { PUT_VESSEL_QUESTIONNAIRE_DATA, VesselQuestionnaireActions } from '../actions/vessel-questionnaire.actions';
import { deepmerge } from 'src/app/utils/deep-merge';
import { emptyCreateVessel } from './vessel-create.reducer';

/** @docsNotRequired */
export interface State {
  form: any;
}

export const initialState: State = {
  form: null
};

/**
 * Reducer for Vessel Questionanaire actions
 * @param {State} state - The current state
 * @param {VesselQuestionnaireActions} action - The header content action
 */
export function reducer(state = initialState, action: VesselQuestionnaireActions): State {
  switch (action.type) {

    case PUT_VESSEL_QUESTIONNAIRE_DATA: {
      return {
        ...state,
        ...{
          form: deepmerge(emptyCreateVessel.form, action.payload)
        }
      };
    }

    default:
      return state;
  }
}

const getVesselState = createFeatureSelector<any>('vessel');

export const getQuestionnaireFormData = createSelector(
  getVesselState,
  state => {
    return {
      vesselDetails: state.questionnaire.form,
      vesselOriginalDetails: state.create.form,
    };
  }
);
