//
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataScenarioService {
  private dataSource = new BehaviorSubject<any>('');
  private dataSource1 = new BehaviorSubject<any>('');
  private dataSourceService = new BehaviorSubject<any>('');
  private scenarioOverviewSource = new BehaviorSubject<any>('');
  private schIDSource = new BehaviorSubject<any>('');
  private schIDScSource = new BehaviorSubject<any>('');
  private schDtoSource = new BehaviorSubject<any>('');
  private schCurrentSource = new BehaviorSubject<any>('');
  private scheduleHeaderOverviewSource = new BehaviorSubject<any>('');
  private isImportedSuccessSource = new BehaviorSubject<any>(false);
  private hasDatedScheduleFalseSource = new BehaviorSubject<any>(false);
  private switchSchOverview1 = new BehaviorSubject<any>(false);
  private serviceOverviewSource = new BehaviorSubject<any>('');
  public data = this.dataSource.asObservable();
  public dataService = this.dataSourceService.asObservable();
  public isUseDatingSource = new BehaviorSubject<any>(false);
  public scenarioOverviewData = this.scenarioOverviewSource.asObservable();
  public schCurrentData = this.schCurrentSource.asObservable();
  public schIDData = this.schIDSource.asObservable();
  public schIDScData = this.schIDScSource.asObservable();
  public schDtoData = this.schDtoSource.asObservable();
  public isImportedSuccess = this.isImportedSuccessSource.asObservable();
  public hasDatedScheduleFalse = this.hasDatedScheduleFalseSource.asObservable();
  public scheduleHeaderOverviewData = this.hasDatedScheduleFalseSource.asObservable();
  public createDatedData = this.dataSource1.asObservable(); /* For New Draft Data */
  public switchSchOverview = this.switchSchOverview1.asObservable(); /* When Exixting Schedule */
  public isUseDating = this.isUseDatingSource.asObservable();
  public serviceOverviewData = this.serviceOverviewSource.asObservable();

  constructor() { }
  public fetchedScenarioDtl(data: any) {
    this.dataSource.next(data);
  }
  public updateScenarioOverviewSource(data: any) {
    this.scenarioOverviewSource.next(data);
  }
  public updateSchID(data: any) {
    this.schIDSource.next(data);
  }
  public updateSchIDSc(data: any) {
    this.schIDScSource.next(data);
  }
  public updateImportedSucces(data: any) {
    this.isImportedSuccessSource.next(data);
  }
  public updatehasDatedScheduleFalse(data: any) {
    this.hasDatedScheduleFalseSource.next(data);
  }
  public updateScheduleHeaderOverviewData(data: any) {
    this.scheduleHeaderOverviewSource.next(data);
  }
  public updateStatusSwitchSchOverview(data: any) {
    this.switchSchOverview1.next(data);
  }
  public updateSchCurrentData(data: any) {
    this.schCurrentSource.next(data);
  }
  public updateIsUseDating(data: any) {
    this.isUseDatingSource.next(data);
  }
  public updatedCreatedDateSelection(data: any) {
    this.dataSource1.next(data);
  }
  public updateschDto(data: any) {
    this.schDtoSource.next(data);
  }
  public updateServiceOverViewHeader(data: any) {
    this.serviceOverviewSource.next(data);
  }

  public resetFlags() {
    this.updateStatusSwitchSchOverview(false);
    this.updatedCreatedDateSelection('');
    this.updateIsUseDating(false);
    this.updateImportedSucces(false);
    this.updatehasDatedScheduleFalse(false);
  }
}
