//

/**
 * check if value is valid date or not
 * @param d date value
 */
export function isValidDate(d): boolean {
  const timestamp = Date.parse(d);
  return isNaN(timestamp) ? false : true;
}
