// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import "@maersk-global/mds-components-core/mc-icon";

@Component({
  selector: 'champs-a3-vessel-detail',
  templateUrl: './a3-vessel-detail.component.html',
  styleUrls: ['./a3-vessel-detail.component.scss']
})
export class A3VesselDetailComponent implements OnInit {
  @Input() public detail: any;
  @Output() public toggleDetail: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {

  }

}
