/** adapter for date picker */
import { NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { isValidDate } from 'src/app/utils/valid-date';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDatePickerAdapter extends NativeDateAdapter {
  /** @docsNotRequired */
  public format(date: Date, displayFormat: Object): string {
    if (!displayFormat['day']) {
      return super.format(super.isDateInstance(date) ? date : new Date(date), displayFormat);
    }
    return isValidDate(date) ? (new DatePipe('en-US').transform(date, 'dd-MMM-yy') as any) : null;
  }

  /** @docsNotRequired */
  public deserialize(value: any): Date | null {
    return isValidDate(value) ? (new DatePipe('en-US').transform(value, 'dd-MMM-yy') as any) : null;
  }

  /** @docsNotRequired */
  public isDateInstance(obj: any) {
    return super.isDateInstance(obj) || isValidDate(obj);
  }

  /** @docsNotRequired */
  public isValid(date: Date | any): boolean {
    return isValidDate(date);
  }

  /** @docsNotRequired */
  public sameDate(first: Date | any | null, second: Date | any | null) {
    return super.sameDate(first ? new Date(first) : null, second ? new Date(second) : null);
  }

  /** @docsNotRequired */
  public getDate(date: Date | any): number {
    return super.getDate(super.isDateInstance(date) ? date : new Date(date));
  }

  /** @docsNotRequired */
  public getMonth(date: Date | any): number {
    return super.getMonth(super.isDateInstance(date) ? date : new Date(date));
  }

  /** @docsNotRequired */
  public getYear(date: Date | any): number {
    return super.getYear(super.isDateInstance(date) ? date : new Date(date));
  }

  /** @docsNotRequired */
  public getYearName(date: Date | any): string {
    return super.getYearName(super.isDateInstance(date) ? date : new Date(date));
  }

  /** @docsNotRequired */
  public toIso8601(date: any): string {
    return super.toIso8601(super.isDateInstance(date) ? date : new Date(date));
  }
}
