// Angular
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'champs-powerbi-report-list',
  templateUrl: './powerbi-report-list.component.html',
  styleUrls: ['./powerbi-report-list.component.scss']
})
export class PowerbiReportListComponent implements OnInit {
  public reportType: string;
  public reports: any[];
  public showReport: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private msal: MsalService, private http: HttpClient, private spinner: NgxSpinnerService) {

    this.route.url.subscribe(params => {
      this.reportType = params[1].path;
    });
  }

  public ngOnInit(): void {
    this.spinner.show();
    this.getReports();
  }

  public getReports() {
    const groupID = this.reportType === 'application-reports' ? 'adeff199-f790-49bf-93e4-9c10dc1fc033' : '3ef637e0-5674-49d9-938c-97877913a031';
    this.msal.instance.acquireTokenSilent({ scopes: ['https://analysis.windows.net/powerbi/api/.default'] }).then((result) => {
      this.http.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupID}/reports`, {
        headers: {
          'Authorization': 'Bearer ' + result.accessToken
        }
      }).subscribe((data: any) => {
        this.reports = data.value.filter((report: any) => report.name !== 'Report Usage Metrics Report');
        this.sortReports();
        this.showReport = true;
        this.spinner.hide();
      });
    });
  }

  public navigateToReport(report: any) {
    const reportName = report.name.replace(/ /g, '-').toLowerCase();
    const param = {
      id: report.id,
      url: report.embedUrl,
    };
    const url = this.router.createUrlTree([`reports/${reportName}`], { queryParams: param });
    window.open(url.toString(), '_blank');
  }

  private sortReports() {
    this.reports.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });
  }

}
