/** function to scroll towards the first mat-error found */
export function scrollToError(matErrorRefs) {
  setTimeout(() => {
    if (matErrorRefs) {
      matErrorRefs.first.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, 350);
}
