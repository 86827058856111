//

import { Action } from '@ngrx/store';

export const PUT_DROPDOWN_CONTENT = 'PUT_DROPDOWN_CONTENT';

export class PutDropdownContent implements Action {
  public readonly type = PUT_DROPDOWN_CONTENT;
  constructor(
    public payload: any,
    public url: any
  ) {}
}

export type DropdownContentActions = PutDropdownContent;
