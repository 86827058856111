//

import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'ngx-datatable[ngxtableFixedHeader]'
})
export class NgxtableFixedHeaderDirective {

  private setFixed: boolean = false;

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('window:scroll', [])
  public onWindowScroll() {
    const compOffsetTop = this.elementRef.nativeElement.getBoundingClientRect().top;

    let setFixed = false;
    if (compOffsetTop < 40 && compOffsetTop !== 0) {
      setFixed = true;
    } else {
      setFixed = false;
    }

    if (this.setFixed !== setFixed) {
      this.setFixed = setFixed;
      this.setElementFixed(this.setFixed);
    }
  }

  @HostListener('window:resize', [])
  public onWindowResize() {
    const compOffsetTop = this.elementRef.nativeElement.getBoundingClientRect().top;

    let setFixed = false;
    if (compOffsetTop < 40 && compOffsetTop !== 0) {
      setFixed = true;
    } else {
      setFixed = false;
    }
    this.setFixed = setFixed;
    this.setElementFixed(this.setFixed);
  }

  private setElementFixed(fixed) {
    const tableHeader = this.elementRef.nativeElement.querySelector('datatable-header');
    const tableBody = this.elementRef.nativeElement.querySelector('datatable-body');
    const tableHeaderInner = this.elementRef.nativeElement.querySelector('datatable-header');
    if (fixed) {
      this.elementRef.nativeElement.classList.add('fixed-dtheader');
      tableHeader.style.width = this.elementRef.nativeElement.clientWidth + 'px';
      tableHeaderInner.style.width = this.elementRef.nativeElement.clientWidth + 'px';
      tableBody.style['padding-top'] = tableHeader.offsetHeight + 'px';
    } else {
      this.elementRef.nativeElement.classList.remove('fixed-dtheader');
      tableHeader.style.width = '100%';
      tableBody.style['padding-top'] = '0';
    }

  }

}
