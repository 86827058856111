//

import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

/** @docsNotRequired */
export interface Alert {
  type: AlertType;
  message: string;
  fullMessage: string;
  detailButton: string;
  header: string;
}

/** @docsNotRequired */
export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private keepAfterRouteChange = false;
  private subject = new ReplaySubject<Array<Alert>>(1);
  public alertVesselId = new BehaviorSubject<string>('');

  private alerts: Array<Alert> = [];

  public alertMessage: Alert;
  public sharedVesselType = new Subject<any>();

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  public getAlert() {
    return this.subject;
  }

  public success(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, keepAfterRouteChange);
  }

  public error(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, keepAfterRouteChange);
  }

  public info(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, keepAfterRouteChange);
  }

  public warn(message: string, keepAfterRouteChange = false, fullMessage?: string, detailButton?: string, header?: string) {
    if (!detailButton && header) {
      this.alert(AlertType.Warning, message, keepAfterRouteChange, '', '', header);
    } else if (!detailButton) {
      this.alert(AlertType.Warning, message, keepAfterRouteChange);
    } else if (detailButton === 'Show') {
      this.alert(AlertType.Warning, message, keepAfterRouteChange, fullMessage, detailButton, header);
    }
  }

  public alert(type: AlertType, message: string, keepAfterRouteChange = false, fullMessage?: string, detailButton?: string, header?: string) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.alerts.push({ type: type, message: message, fullMessage: fullMessage, detailButton: detailButton, header: header });
    this.subject.next(this.alerts);
    // this.alertMessage = { type: type, message: message };
  }

  public clear() {
    // tslint:disable-next-line:no-console
    this.alerts = [];
    this.subject.next(this.alerts);
  }
}