// create-update service
import { Component, Input, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'champs-view-tco',
  templateUrl: './view-tco.component.html',
  styleUrls: ['./view-tco.component.scss'],
})
export class ViewTcoComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  @Input() public serviceFormGrp: UntypedFormGroup;
  @Input() public serviceObj: any;
  @Input() public totalTeuCap: any;
  @Input() public index: any;
  @Input() public showAllBunker: boolean = false;
  @Input() public roundtripDays: any;
  @Input() public schId: any;
  @Input() public serviceRepo: any;
  @Input() public proformaHeaderId: any;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @ViewChild('addTCO', { static: false }) public addTCOForm: any;
  @ViewChild('updateTCO', { static: false }) public updateTCOForm: any;
  @ViewChild('uploadTCO', { static: false }) public uploadTCOForm: any;
  public bunkerForm: UntypedFormGroup;
  public selectedService: any;
  public update: boolean = false;
  public bunkerEditMode: boolean = false;
  public tcobunkeredit: boolean = false;
  public isTCOUpdateAuth: boolean = false;
  public uploadedProfoId: any;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private scheduleService: ServiceRepositoryService,
    private auth: AuthService
  ) {
    this.createBunkerForm();
  }

  public ngOnInit() {
    this.isTCOUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
  }
  public editBunkerPrice() {
    this.bunkerEditMode = true;
  }

  public editBunkerPriceShow() {
    this.tcobunkeredit = true;
    this.createBunkerForm();
  }

  public createBunkerForm() {
    // Resolving intermittent issue of porforma header id coming null
    if (this.router.url.split('/')[4] && this.router.url.split('/')[4].length > 0) {
      this.scheduleService
        .getScheduleDating(this.router.url.split('/')[4])

        .subscribe((res: any) => {
          if (res) {
            this.proformaHeaderId = res.proformaHeaderId;
          }
        });
    }
    this.bunkerForm = this.formBuilder.group({
      bpPKID: this.serviceObj && this.serviceObj.scheduleBunkerPrice ? this.serviceObj.scheduleBunkerPrice.bpPKID : 0,
      bpProformaHeaderId:
        this.serviceObj && this.serviceObj.scheduleBunkerPrice
          ? this.serviceObj.scheduleBunkerPrice.bpProformaHeaderId
          : this.proformaHeaderId,
      bpBunkerType1Price:
        this.serviceObj && this.serviceObj.scheduleBunkerPrice
          ? this.serviceObj.scheduleBunkerPrice.bpBunkerType1Price
          : null,
      bpBunkerType2Price:
        this.serviceObj && this.serviceObj.scheduleBunkerPrice
          ? this.serviceObj.scheduleBunkerPrice.bpBunkerType2Price
          : null,
      bpBunkerType3Price:
        this.serviceObj && this.serviceObj.scheduleBunkerPrice
          ? this.serviceObj.scheduleBunkerPrice.bpBunkerType3Price
          : null,
    });
  }
  public saveBunker(e) {
    if (e === 'save') {
      const formData = {
        frequency: this.serviceObj.frequency,
        scheduleBunkerPrice: this.bunkerForm.value,
        scheduleTCOs: this.serviceObj.scheduleTCOs,
      };
      this.tcobunkeredit = !this.tcobunkeredit;
      this.close.emit({
        value: formData,
      });
    }
    if (e === 'cancel') {
      this.tcobunkeredit = !this.tcobunkeredit;
      this.createBunkerForm();
    }
  }
  public closeCreateUpdateForm(event) {
    this.dialogRef.close();
    this.dialogRef.close();
    this.close.emit({
      value: event.value,
    });
  }
  public editTCO(service, index) {
    this.index = index;
    this.selectedService = service;
    this.update = true;
    this.dialogRef = this.dialog.open(this.updateTCOForm, {
      width: '700px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public createTCOform() {
    this.update = false;
    this.dialogRef = this.dialog.open(this.addTCOForm, {
      width: '1000px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public uploadTCOform() {
    this.dialogRef = this.dialog.open(this.uploadTCOForm, {
      width: '700px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public closeUploadTco(e: any) {
    if (e.type === 'close') {
      this.dialogRef.close();
    }
    if (e.type === 'save') {
      this.selectedService = e.response;
      this.uploadedProfoId = e.uploadedProfoId;
      this.update = true;
      this.dialogRef.close();
      this.dialogRef = this.dialog.open(this.updateTCOForm, {
        width: '700px',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
}
