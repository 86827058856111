// customised adapter
import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { isValidDate } from '../../utils/valid-date';
import { DatePipe } from '@angular/common';
import { CustomMomentUtcAdapter } from './custom-moment-utc-adapter';

@Injectable()
export class TempCustomMomentUtcAdapter extends CustomMomentUtcAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  // tslint:disable-next-line:completed-docs
  public format(date: Moment): string {
    return isValidDate(date) ? (new DatePipe('en-US').transform(date.toDate(), 'dd-MMM-yyyy') as any) : null;
  }
}
