import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { RumInit } from '@maersk-global/telemetry-web-sdk';

if (environment.production) {
  enableProdMode();
}

// Initialize the SDK
const faro = RumInit({
  app: {
    name: environment.mopName, // Replace 'YourAppName' with your actual app name
    version: '1.0.0', // Replace '1.0.0' with your actual app version
    environment: environment.mopEnvironment, // Set environment based on Angular environment
  },
  apiKey: environment.mopApiKey, // Replace 'YourAPIKey' with your actual API key
  debug: true,
  traceLog: true,
  useSendBeacon: true, // Add useSendBeacon property
  isolate: true,
  ignoreUrls:['http://localhost*/', '.*pensieve.*'],
  spanLimitInTrace: 10,
  sso_user: true, //  default set to true
  transportBufferSize: 30,
  rateLimitBackoffMs: 5000,
  batching:{
    // enable/disable batch sending of events 
    enabled: true, // default
    // The interval in which to send event batches   
    sendTimeout:250, // default
    // The size of the signal buffer before the batch is sent (overrides interval) 
    itemLimit: 50 // default
  },
  instrumentations: {
    documentload: true,
    xhr: true,
    fetch: true,
    webvitals: true,
    console: {
      disabledLevels: [] // This will enable all console levels
    },
    errors: true,
    interactions: {
      eventNames: ['click', 'mouseup', 'mousedown', 'dblclick']
    }
  }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  // tslint:disable-next-line:no-console
  .catch(err => console.log(err));
