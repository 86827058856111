//
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import {
  MY_FORMATS,
  time,
  customMoment,
  convertToLocalIsoString,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DatePipe } from '@angular/common';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
const moment = customMoment;

@Component({
  selector: 'champs-vessels-deployment',
  templateUrl: './vessel-deployment.component.html',
  styleUrls: ['./vessel-deployment.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VesselDeploymentComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public vesselDto: any;
  @Output() public show: EventEmitter<any> = new EventEmitter();
  @Input() public position: any;
  @Input() public deploylst: any;
  @Input() public maxPosition: any;
  @Input() public proformaId: any;
  @Input() public serviceid: any;
  @Input() public serviceRepo: boolean;
  @Output() public delete: EventEmitter<any> = new EventEmitter();
  @Input() public serviceCode: any;
  @Input() public serviceName: any;
  @Input() public scenarioId: any;
  public vesselDeploymentFrmGrp: UntypedFormGroup;
  public extendDummyFormGroup: UntypedFormGroup;
  public showMoreDetails: boolean = false;
  public extendDummy: boolean = false;
  public vesselRequirementObj: any;
  public extendDummyFromDate;
  public selectedTime: any;
  public cityId: any;
  public cityName: any;
  public time = time;
  public endPortId: any;
  public endPortName: any;
  public endPortTime: any;
  @Input() public proformaHeaderId: any;
  public positionsLst: any = [];
  public isVslDeploymentDeleteAuth: boolean = false;
  public isVslDeploymentUpdateAuth: boolean = false;
  @ViewChild('replaceVessel', { static: false }) public replaceVessel: any;
  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private serviceRepoService: ServiceRepositoryService,
    private scenarioService: ScenariosService,
    private auth: AuthService
  ) {}

  public ngOnInit() {
    this.isVslDeploymentUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.isVslDeploymentDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'delete');
    this.setFormGrp();
  }

  public setExtendValues() {
    this.setPortValues(this.position);
    this.createPositionArray();
  }
  public getVesselRequirementObj() {
    const serv = this.serviceRepo
      ? this.serviceRepoService.getVesselRequirementsExtendToDummy(this.vesselDto.vesselId, this.proformaId)
      : this.scenarioService.getVesselRequirementsExtendToDummy(
          this.vesselDto.vesselId,
          this.proformaId,
          this.scenarioId
        );

    serv.subscribe((resp: any) => {
      this.vesselRequirementObj = resp;
      const schLst = this.getScheduleIds();
      const postData1 = this.createObj();
      const startDateTime = this.getDateTimeInStringFormat(
        this.extendDummyFormGroup.value.crPhaseInStartDate,
        this.extendDummyFormGroup.value.frFromTime
      );
      this.serviceRepoService
        .getLastDeploymentOnServiceByPosition(this.serviceid, this.position, startDateTime)
        .subscribe((res1: any) => {
          postData1.crVesselDepID = res1 === -1 ? null : res1;
          this.serviceRepoService.postCreateUpdateDummy(postData1).subscribe((res: any) => {
            if (res) {
              const charterRequestId = res;
              const postData = {
                charterRequestId: charterRequestId.charter_id,
                lstSchedules: schLst,
              };
              this.serviceRepoService.postUpdateCharterRequestOnSchedule(postData).subscribe((res2: any) => {
                if (res2) {
                  this.close.emit({
                    type: 'closeExtends',
                    position: this.extendDummyFormGroup.value.position,
                  });
                }
              });
            }
          });
        });
    });
  }
  public getDateTimeInStringFormat(_date, _time) {
    return convertToLocalIsoString(
      new Date(moment(_date).format('YYYY-MM-DD[T]HH:mm:ss').slice(0, 11) + _time + ':00')
    );
  }
  public createPositionArray() {
    let i = 0;
    for (let j = 0; j < this.maxPosition; j++) {
      i++;
      this.positionsLst[j] = i;
    }
  }
  public setPortValues(position) {
    let timeSet = false;
    for (const portsLSt of this.deploylst.lstPortsListDto) {
      for (const posLst of portsLSt.lstScheduleListDto) {
        const arrTime = posLst.lstPositionDto
          ? posLst.lstPositionDto.length === 2
            ? posLst.lstPositionDto[0].scheduleType === -1
              ? posLst.lstPositionDto[0]
              : posLst.lstPositionDto[1].scheduleType === -1
              ? posLst.lstPositionDto[1]
              : null
            : posLst.lstPositionDto[0].scheduleType === -1
            ? posLst.lstPositionDto[0]
            : null
          : null;
        if (
          posLst.position === position &&
          arrTime &&
          new Date(new DatePipe('en-US').transform(this.vesselDto.deploymentEndDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() <
            new Date(new DatePipe('en-US').transform(arrTime.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime()
        ) {
          this.cityId = portsLSt.cityId;
          this.cityName = portsLSt.cityName;
          this.extendDummyFromDate = arrTime.scheduleDate;
          this.selectedTime = arrTime.scheduleDate.slice(11, 17);
          timeSet = true;
          break;
        }
      }
      if (timeSet) {
        break;
      }
    }
    this.setExtendFormGroup();
  }
  public replaceVesselForm() {
    this.dialogRef = this.dialog.open(this.replaceVessel, {
      width: '1200px',
      // height: '450px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public cancelCreateForm() {
    this.close.emit({
      type: 'close',
    });
  }
  public redeliverVessel() {
    this.show.emit({
      showVesselRedeliverPopUp: true,
      vesselId: this.vesselDto.vesselId,
      position: this.position,
      fixtureId: this.vesselDto.fixtureId,
    });
  }
  public deleteDeployment() {
    this.delete.emit({
      deleteDeploymentPopUp: true,
      vesselId: this.vesselDto.vesselDeploymentId,
    });
  }
  public vesselLink() {
    if (this.vesselDto.vesselType === 4) {
      window.open('/vessels/dummy/overview/' + this.vesselDto.vesselId, '_blank', 'noreferrer');
    } else {
      window.open('/vessels/' + this.vesselDto.vesselId + '/', '_blank', 'noreferrer');
    }
  }
  public fixtureLink() {
    window.open('/fixtures/' + this.vesselDto.fixtureId + '/', '_blank', 'noreferrer');
  }
  public nextActionLink(serviceId, proformaId) {
    window.open(
      '/services/' + serviceId + '/schedule/' + proformaId + '/true/deployments/true',
      '_blank',
      'noreferrer'
    );
  }
  public createDeployment() {
    const postData = {
      vesselDeploymentId: this.vesselDto.vesselDeploymentId,

      deploymentComments: this.vesselDeploymentFrmGrp.value.deploymentComments,
    };
    const servpostUpdateDeploymentCommentsAsync = this.serviceRepo
      ? this.serviceRepoService.postUpdateDeploymentCommentsAsync(postData)
      : this.scenarioService.postUpdateDeploymentCommentsAsync(postData);
    servpostUpdateDeploymentCommentsAsync.subscribe((res) => {
      // this.serviceRepoService.postUpdateDeploymentCommentsAsync(postData).subscribe((res) => {
      if (res) {
        this.close.emit({
          type: 'closeComment',
        });
      }
    });
  }
  public extendToDummy() {
    this.getVesselRequirementObj();
  }

  public getScheduleIds() {
    let schLst = [];
    const fromDate = new Date(
      moment(this.extendDummyFormGroup.value.crPhaseInStartDate).format('YYYY-MM-DD[T]HH:mm:ss').slice(0, 11) +
        this.extendDummyFormGroup.value.frFromTime +
        ':00'
    );
    /* new Date(new DatePipe('en-US').transform(this.extendDummyFormGroup.value.crPhaseInStartDate, 'yyyy-MM-ddTHH:mm:ss')) */
    const toDate = new Date(
      moment(this.extendDummyFormGroup.value.crPhaseOutStartDate).format('YYYY-MM-DD[T]HH:mm:ss').slice(0, 11) +
        this.extendDummyFormGroup.value.frToTime +
        ':00'
    );
    /* new Date(new DatePipe('en-US').transform(this.extendDummyFormGroup.value.crPhaseOutStartDate, 'yyyy-MM-dd')) */
    let index = 1;
    //  && schToDate.vesselDto === null && schToDate.charterRequestDto === null
    //   && schFromDate.vesselDto === null && schFromDate.charterRequestDto === null
    for (const ports of this.deploylst.lstPortsListDto) {
      for (const lst of ports.lstScheduleListDto) {
        const schFromDate = lst.lstPositionDto
          ? lst.lstPositionDto.length === 2
            ? lst.lstPositionDto[0].scheduleType === 0
              ? lst.lstPositionDto[0]
              : lst.lstPositionDto[1].scheduleType === 0
              ? lst.lstPositionDto[1]
              : null
            : lst.lstPositionDto[0].scheduleType === 0
            ? lst.lstPositionDto[0]
            : null
          : null;
        const schToDate = lst.lstPositionDto
          ? lst.lstPositionDto.length === 2
            ? lst.lstPositionDto[0].scheduleType === -1
              ? lst.lstPositionDto[0]
              : lst.lstPositionDto[1].scheduleType === -1
              ? lst.lstPositionDto[1]
              : null
            : lst.lstPositionDto[0].scheduleType === -1
            ? lst.lstPositionDto[0]
            : null
          : null;
        if (
          lst.position === this.extendDummyFormGroup.value.position && schFromDate
            ? new Date(schFromDate.scheduleDate).getTime() >= new Date(fromDate).getTime() &&
              new Date(schFromDate.scheduleDate).getTime() <= new Date(toDate).getTime()
            : false
        ) {
          schLst.push(schFromDate.scheduleId);
          // this.endPortTime = schFromDate.scheduleDate;
          /*User entered todate passed except the schedule date getting from Schlist */
          this.endPortTime = convertToLocalIsoString(toDate.toISOString());
        }
        if (
          lst.position === this.extendDummyFormGroup.value.position && schToDate
            ? new Date(schToDate.scheduleDate).getTime() >= new Date(fromDate).getTime() &&
              new Date(schToDate.scheduleDate).getTime() <= new Date(toDate).getTime()
            : false
        ) {
          // && (index !== this.deploylst.lstPortsListDto.length)
          schLst.push(schToDate.scheduleId);
          // this.endPortTime = schToDate.scheduleDate;
          /*User entered todate passed except the schedule date getting from Schlist */
          this.endPortTime = convertToLocalIsoString(toDate.toISOString());
        }
        if (index === this.deploylst.lstPortsListDto.length) {
          this.endPortId = ports.cityId;
          this.endPortName = ports.cityName;
        }
      }
      index++;
    }
    schLst = Array.from(new Set(schLst));
    return schLst;
  }
  public createObj() {
    const postObj = {
      crPKID: 0,

      crPhaseInPortID: this.cityId,

      crPhaseInPortName: this.cityName,

      crStatusID: 1,

      // crPhaseInStartDate: this.extendDummyFormGroup.value.crPhaseInStartDate,
      crPhaseInStartDate: this.getDateTimeInStringFormat(
        this.extendDummyFormGroup.value.crPhaseInStartDate,
        this.extendDummyFormGroup.value.frFromTime
      ),

      crPhaseOutPortID: this.endPortId,

      crPhaseOutPortName: this.endPortName,

      crPhaseOutStartDate: this.endPortTime,

      crClassID: null,

      crTradeID: 7,
      nomTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null,
      tonCenCap: this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null,
      crTEU: this.vesselRequirementObj ? this.vesselRequirementObj.totalTeuCap : null,

      crMTCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.totalMTCap : null,

      crPlugs: this.vesselRequirementObj ? this.vesselRequirementObj.plugs : null,

      crMinSpeed: this.vesselRequirementObj ? this.vesselRequirementObj.minSpeed : null,

      crMaxSpeed: this.vesselRequirementObj ? this.vesselRequirementObj.maxSpeed : null,

      crDraft: this.vesselRequirementObj ? this.vesselRequirementObj.draftLimitations : null,

      crAirDraft: this.vesselRequirementObj ? this.vesselRequirementObj.airDraft : null,

      crIsGear: this.vesselRequirementObj ? this.vesselRequirementObj.gears : null,

      crIsIceClass: this.vesselRequirementObj ? this.vesselRequirementObj.iceClass : null,

      crComments: '',

      crVesselDepID: null,

      crReason: '',

      crForecastTEU: null,

      crForecastTEURate: null,

      crHFOPrice: null,

      crLSHFOPrice: null,

      crMDOPrice: null,

      crLSMDOPrice: null,

      crMLTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlTeuCap : null,

      crMLMTCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlmtCap : null,

      crProformaHeaderId: this.proformaHeaderId,

      crVesselTeuId: this.vesselRequirementObj ? this.vesselRequirementObj.vesselTeuId : '',

      crNomTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null,

      crTonCenCap: this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null,

      crECAZone: this.vesselRequirementObj ? this.vesselRequirementObj.ecaZone : null,

      crIsECA: this.vesselRequirementObj ? this.vesselRequirementObj.isECAZone : null,
      crExtendsVesselId: this.vesselDto.vesselId,
      crPosition: this.position,
      crServiceId: this.serviceid,
      crServiceCode: this.serviceCode,
      crServiceName: this.serviceName,
    };
    return postObj;
  }
  private setFormGrp() {
    this.vesselDeploymentFrmGrp = this.formBuilder.group({
      deploymentStartDate: this.vesselDto ? this.vesselDto.deploymentStartDate : null,
      deploymentEndDate: this.vesselDto ? this.vesselDto.deploymentEndDate : null,
      deploymentComments: this.vesselDto ? this.vesselDto.deploymentComments : null,
    });
  }

  private setExtendFormGroup() {
    this.extendDummyFormGroup = this.formBuilder.group({
      crPhaseInStartDate: this.extendDummyFromDate ? this.formBuilder.control(moment(this.extendDummyFromDate)) : null,
      crPhaseOutStartDate: null,
      position: this.position,
      frFromTime: this.selectedTime ? this.selectedTime.slice(0, 5) : null,
      frToTime: '00:00',
    });
  }
}
