//
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CharterDataService {
  private dataSource = new BehaviorSubject<any>('');
  public data = this.dataSource.asObservable();
  constructor() { }
  public updateData(data: any) {
    this.dataSource.next(data);
  }

  public setData(data) {
    this.data = data.charterId;
  }

  public getData() {
    const tempData = this.data;
    this.clearData();
    return tempData;
  }

  public clearData() {
    this.data = null;
  }

}
