//

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, delay } from 'rxjs/operators';
import { HttpSpinnerToggleService } from 'src/app/core/services/http-spinner-toggle/http-spinner-toggle.service';
import { Router } from '@angular/router';

const GET_URLS: Array<string | RegExp> = [
  '/api/VesselManagement/Vessels',
  /\/api\/VesselManagement\/Vessel\/[0-9].*/,
  '/api/VesselManagement/GetVesselDetailForCreate',
  // '/api/VesselManagement/RKSTVesselSynchronization/SyncVesselDetails',
  // /\/api\/VesselManagement\/RKSTVesselSynchronization\/SyncVesselDetails\/[0-9].*/,

  '/api/ServiceRepositoryManagement/ServiceRepository',
  /\/api\/ServiceRepositoryManagement\/GetVesselFinderVesselRequirements\/[0-9].*/,
  /\/api\/ServiceRepositoryManagement\/VesselFinderVesselsList\/[0-9].*/,
  /\/api\/ServiceRepositoryManagement\/GetServicePlanningViewByServiceId\/[0-9].*/,
  '/api/FixtureManagement/Fixtures',
  /\/api\/FixtureManagement\/GetFixtureOverview\/[0-9].*/,
  /\/api\/FixtureManagement\/GetFixtureBunker\/[0-9].*/,

  '/api/VesselOwnerManagement/VesselOwners',
  /\/api\/VesselOwnerManagement\/VesselOwner\/[0-9].*/,

  '/api/MarketRateManagement/MarketRates',

  '/api/ServiceRepositoryManagement/ServiceList',
  /\/api\/ServiceManagement\/ServiceList\/[0-9].*/,

  '/api/DictionaryManagement/Dictionaries',
  /\/api\/DictionaryManagement\/Dictionary\/[A-Za-z].*/,

  '/api/AverageBunker/MonthlyAvgBunkerPriceList',
  '/api/ServiceManagement/Service',
  '/api/ServiceRepositoryManagement/GetSchedulePlanningViewByDates',
  '/api/ServiceRepositoryManagement/GetLastDeploymentOnServiceByPosition',
  // '/api/ServiceRepositoryManagement/UpdateCommentsForDeploymentPortDate',
  '/api/VesselManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForSchedulePlannerAsync',
  '/api/ScenarioManagement/VesselDeployement/VesselDeploymentCreateOrUpdateForSchedulePlannerAsync',
  /\/api\/ServiceRepositoryManagement\/GetPostfixtureRedeliverNoticesAsync\/[0-9].*/,
];

const ROUTES_PATHS: Array<string | RegExp> = [/\/vessels\/[0-9].*/, /\/fixtures\/[0-9].*/];

const ROUTES_PATHS_TO_SKIP_LOADER: Array<string | RegExp> = [];

const GET_URLS_TO_SKIP_LOADER: Array<string | RegExp> = [
  '/api/ServiceManagement/CharterRequest/CandidateVessels',
  /\/api\/ServiceManagement\/CharterRequest\/CandidateVessels\/[0-9].*/,

  '/api/DictionaryManagement/Dictionary/UNNumber',
  '/api/HireStatementManagement/HireStatementInfo?hsId',
  '/api/FixtureManagement/GetFixtureOverview/',
  /\/api\/FixtureManagement\/GetFixtureOverview\/[0-9].*/,
  '/api/FixtureManagement/GetFixtureExpense/',
  /\/api\/FixtureManagement\/GetFixtureExpense\/[0-9].*/,
  '/api/FixtureManagement/GetFixtureVesselRestrictionsList/',
  /\/api\/FixtureManagement\/GetFixtureVesselRestrictionsList\/[0-9].*/,

  '/api/FixtureManagement/GetFixtureMiscNotices/',
  /\/api\/FixtureManagement\/GetFixtureMiscNotices\/[0-9].*/,

  '/api/FixtureManagement/GetFixtureCargoRestrictionsList/',
  /\/api\/FixtureManagement\/GetFixtureCargoRestrictionsList\/[0-9].*/,
  '/api/VesselManagement/Vessels/GetVesselListForFixture',
];
const POST_URLS_TO_SKIP_LOADER: Array<string | RegExp> = [
  '/api/User/AddOrUpdateUserLoginDetails',
  '/api/ServiceManagement/CharterRequest/VesselCandidate/Update',
  '/api/DictionaryManagement/ArmedGuard/UploadImage',
  '/api/FixtureManagement/GetFixtureTradePorts',
  'api/FixtureManagement/FixturePort/CreateOrUpdate',
  '/api/HireStatementManagement/SAPCreatePOnSO',
  '/api/HireStatementManagement/HireStatusBlock/VendorBalance?fixtureId',
  // '/api/VesselManagement/RKSTVesselSynchronization/SyncVesselDetails',
  // '/api/VesselManagement/VesselDeployement/VesselDeploymentCreateOrUpdate',
  '/api/FixtureManagement/FixtureTradePort/Remove/',
  '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForNewScheduleDatesChanged',
  '/api/ScenarioManagement/GetSchedulePlannerViewForNewScheduleDatesChanged',
  '/api/FixtureManagement/FixtureVesselRestrictions/CreateOrUpdate/',
  '/api/FixtureManagement/FixtureCargoRestrictions/CreateOrUpdate',
  '/api/FixtureManagement/FixturePort/CreateOrUpdate',
  '/api/FixtureManagement/FixturePort/Remove/',
  '/api/FixtureManagement/FixtureBlackout/CreateOrUpdate',
  '/api/FixtureManagement/GetFixturePortRanges',
  '/api/ServiceRepositoryManagement/UploadTCO',
  '/api/ServiceRepositoryManagement/GetSchedulePlannerViewForNewScheduleCutOff',
  '/api/ScenarioManagement/UpdateCommentsForDeploymentPortDate',
  '/api/ServiceRepositoryManagement/UpdateCommentsForDeploymentPortDate',
  '/api/ScenarioManagement/UpdateScheduleVisibility/',
  // '/api/ServiceRepositoryManagement/UpdateScheduleVisibility/',
  // '/api/ServiceRepositoryManagement/GetSchedulePlanningViewForScheduleDating',
  '/api/ServiceManagement/RemoveCandidateFromCompare',
  '/api/ServiceRepositoryManagement/MarkServiceAsFavouriteOrUnfavourite',
  '/api/ServiceRepositoryManagement/UpdateProformaPortType',
  '/api/ServiceRepositoryManagement/UpdateScheduleComments',
];

export const CHECK_IN_URLS = (URLS: Array<string | RegExp>, url) => {
  let foundIn = false;
  for (const str of URLS) {
    if (!foundIn) {
      if (str instanceof RegExp) {
        foundIn = str.test(url);
      } else if (url.indexOf(str) > -1) {
        foundIn = true;
      }
    }
  }

  return foundIn;
};
export const CHECK_IN_SKIP_URLS = (URLS: Array<string | RegExp>, url) => {
  let foundIn = false;
  for (const str of URLS) {
    if (!foundIn) {
      if (str instanceof RegExp) {
        foundIn = str.test(url);
      } else if (url.indexOf(str) > -1) {
        foundIn = true;
      }
    }
  }
  return foundIn;
};

const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

@Injectable()
export class HttpSpinnerInterceptor implements HttpInterceptor {
  constructor(
    /** @docsNotRequired */
    private httpSpinnerToggleService: HttpSpinnerToggleService,
    /** @docsNotRequired */
    private router: Router
  ) {}

  /**
   * override intercept function of HttpInterceptor interface
   * @param {HttpRequest} req ongoing request
   * @param {HttpHandler} next action on response Handler
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method.toUpperCase() === 'POST' && !CHECK_IN_SKIP_URLS(POST_URLS_TO_SKIP_LOADER, req.url)) {
      this.httpSpinnerToggleService.addUrl.next(req.url);
    } else if (
      CHECK_IN_URLS(GET_URLS, req.url) &&
      !CHECK_IN_URLS(ROUTES_PATHS, this.router.url) &&
      !CHECK_IN_SKIP_URLS(GET_URLS_TO_SKIP_LOADER, req.url) &&
      !CHECK_IN_SKIP_URLS(ROUTES_PATHS_TO_SKIP_LOADER, this.router.url) &&
      req.method.toUpperCase() === 'GET'
    ) {
      this.httpSpinnerToggleService.addUrl.next(req.url);
    }

    return next.handle(req).pipe(
      // delay(500),
      catchError((err: HttpEvent<any>) => {
        if (err instanceof HttpErrorResponse) {
          const url = err.url ? err.url : err.error['target']['__zone_symbol__xhrURL'];
          this.httpSpinnerToggleService.removeUrl.next(url);
          if (url) {
            this.httpSpinnerToggleService.removeUrl.next(url);
          }
        }
        return throwError(err);
      }),
      tap(
        (event: HttpResponse<any>) => {
          if (event && event.url) {
            this.httpSpinnerToggleService.removeUrl.next(event.url);
          }
        },
        () => {},
        () => {
          setTimeout(() => {
            let event;
            if (isIEOrEdge) {
              event = document.createEvent('UIEvents');
              event.initUIEvent('resize', true, false, window, 0);
            } else {
              event = new Event('resize');
            }
            window.dispatchEvent(event);
          }, 0);
        }
      )
    );
  }
}
