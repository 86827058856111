//

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DownloadFileService } from '../../../core/services/download-file/download-file.service';

@Injectable()
export class DictionaryService {
  constructor(private http: HttpClient, private download: DownloadFileService) {}

  public getDictionaries(dictType?: string, sortOnSave: boolean = false) {
    if (dictType.toLowerCase() === 'string') {
      return this.http.get(
        environment.baseUrl + '/api/ServiceRepositoryManagement/ServiceDictionaryList',
        sortOnSave
          ? {
              params: {
                sortOnSave: 'true',
              },
            }
          : {}
      );
    }
    return this.http.get(
      environment.baseUrl + '/api/DictionaryManagement/Dictionary/' + dictType,
      sortOnSave
        ? {
            params: {
              sortOnSave: 'true',
            },
          }
        : {}
    );
  }

  public getDictionaryList() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/Dictionaries');
  }

  public getMainEngines() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/EngineManufacturers');
  }

  public genPostObject(postObj, constObj): any {
    return Object.keys(constObj).reduce((res, val, i) => {
      return Object.assign(res, {
        [val]: postObj[Object.keys(postObj)[i]],
      });
    }, {});
  }

  public postCreateDictionary(dictType: string, postObj) {
    return this.http.post(environment.baseUrl + '/api/DictionaryManagement/' + dictType + '/CreateOrUpdate', postObj);
  }

  public postUpdateDictionary(dictType: string, postObj) {
    return this.http.post(environment.baseUrl + '/api/DictionaryManagement/' + dictType + '/Update', postObj);
  }

  public getLinesforString() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/Lines');
  }

  public getBrandsSAPpg() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/ChartererProps');
  }

  public getUnits4SAPMaterials() {
    return this.http.get(environment.baseUrl + '/api/UnitManagement/Units');
  }

  public getDeployLimits() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/DeploymentLimitations');
  }

  public getBunkerTypes(): Observable<any[]> {
    return this.http.get<any[]>(environment.baseUrl + '/api/DictionaryManagement/BunkerTypes');
  }
  public getCargoRestrictionGroup() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/CargoRestrictionGroups');
  }

  public getResponsiblePersonPostFixture() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/PFResponsiblePerson');
  }

  public postResponsiblePersonPostFixture() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/PFResponsiblePerson/Update');
  }

  public getUNNumbers() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/UNNumbers');
  }

  /** vessel designs dictionary */
  public getVesselDesigns() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/VesselDesignMapping');
  }

  public postVesselDesigns(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/DictionaryManagement/VesselDesignMapping/CreateOrUpdate',
      postObj
    );
  }

  public getVesselSpecs() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/VesselSpecs');
  }

  public getChampsDesigns() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/ChampsDesign');
  }

  public getVesselTEU() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/VesselTEU');
  }

  public postChampsDesign(postObj) {
    return this.http.post(environment.baseUrl + '/api/DictionaryManagement/ChampsDesign/CreateOrUpdate', postObj);
  }

  public getWarRiskTransitMatrix() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/Dictionary/WarRiskTransitMatrix');
  }

  public postWarRiskTransitMatrix(postObj) {
    return this.http.post(environment.baseUrl + '/api/DictionaryManagement/WarRiskTransitMatrix/Update', postObj);
  }

  public armedGuardUploadImage(imageFile) {
    return this.http.post(environment.baseUrl + '/api/DictionaryManagement/ArmedGuard/UploadImage', imageFile);
  }

  public armedGuardDownloadImage(imageName) {
    const url = environment.baseUrl + '/api/DictionaryManagement/ArmedGuard/DownloadImage/' + imageName;
    this.download.downloadFile(url);
  }

  public postCreateArmedGuardDictionary(postobj) {
    const url = environment.baseUrl + '/api/DictionaryManagement/ArmedGuard/CreateOrUpdate';

    return this.http.post(url, postobj);
  }
  /** strings dictionary */
  public getStrings() {
    return this.http.get(environment.baseUrl + '/api/ServiceRepositoryManagement/ServiceDictionaryList');
  }

  /** customer-vendor dictionary */
  public getCustVend() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/SAPCustomerVendorCodes');
  }

  /** deployment contact matrix dictionary */
  public getEmailContacts() {
    return this.http.get(
    //   environment.baseUrl + '/api/DictionaryManagement/StakeholderEmail/GetStakeholderEmailcontacts'
      environment.baseUrl + '/api/DictionaryManagement/DeploymentContactMatrix/GetEmails'
    );
  }

  public getDropdownList(){
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/DeploymentContactMatrix/GetDropdownList');
  }
  /** */
  public getServiceOverviewDropDown() {
    return this.http.get(environment.baseUrl + '/api/ServiceRepositoryManagement/ServiceOverviewHeaderDropDownList');
  }
  /** */
  public getServiceTradeDropDown() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/TradeList');
  }
  public postCreateUpdateString(postObj) {
    const url = environment.baseUrl + '/api/ServiceRepositoryManagement/CreateOrUpdateService';

    return this.http.post(url, postObj);
  }
  public postCreateUpdateCustVend(postObj) {
    const url = environment.baseUrl + '/api/DictionaryManagement/SAPCustomerVendorCodes/CreateOrUpdate';

    return this.http.post(url, postObj);
  }

  public postCreateUpdateEmailContacts(postObj) {
    // const url = environment.baseUrl + '/api/DictionaryManagement/StakeholderEmail/CreateOrUpdateStakeholderEmail';
    const url = environment.baseUrl + '/api/DictionaryManagement/DeploymentContactMatrix/CreateOrUpdate'
    return this.http.post(url, postObj);
  }

  public getTradeList() {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/TradeList');
  }
}
