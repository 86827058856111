// ports services
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class PortService {
  private portsSubject: ReplaySubject<any> = new ReplaySubject();

  private ports: any = null;

  public sendCount = new Subject<any>();
  constructor(
    private http: HttpClient,
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get list of ports
   */
  public getPorts(): Observable<any> {
    const url = environment.baseUrl + '/api/PortManagement/Ports';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
   * get ports by country
   * @param {number} couID country id
   */
  public getPortsByCountry(couID: number): any {
    if (couID) {
      return this.http.get(environment.baseUrl + '/api/PortManagement/PortsByCountry/' + couID);
    }

    return this.getPorts();
  }
}
