//

export const environment = {
  production: Boolean('false'),
  baseUrl: 'https://champs.dev.maersk.com',
  uiUrl: 'https://champs.dev.maersk.com',
  postLogoutUrl: 'https://champs.dev.maersk.com',
  appInsights: {
    instrumentationKey: '1b18c793-1601-493d-86be-ba817c9bf3e6'
  },
  schedulePlannerUrl: 'https://comst.apmoller.net/ddnd/schedulePlanner',
  tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
  clientId: '7012c174-890d-4790-bd31-a20703c3e15b',
  MSALScope: 'https://maersk.onmicrosoft.com/5d77b961-43ec-4729-a316-a13006202317/user_impersonation',
  byPassAuthentication: 'false',
  mopName: 'champs-dev',
  mopEnvironment: 'dev',
  mopApiKey: 'g+sxswVSVfdNnYASPR+al/W7WXP3dggdywaLcjEpXzVX4mp3lQDrU+HEkfp6tg=='
};
