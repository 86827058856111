/** custom date picker for dd-mmm-yy (to be used with ngModel forms) */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';

const moment = _moment;

@Component({
  selector: 'champs-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class CustomDatePickerComponent implements OnInit {
  @Input() public date;
  @Input() public min;
  @Input() public max;
  @Input() public showClearButton: boolean = false;
  @Input() public required: boolean = false;
  @Output() public newDate = new EventEmitter<any>();
  @Output() public isEmpty = new EventEmitter<boolean>();
  @ViewChild('datePickerInput', { static: false }) public datePickerInput;
  public errors = {
    required: false,
    pattern: false,
  };
  constructor() {}

  public ngOnInit() {}

  public onDateChange(date) {
    this.newDate.emit(date);
    this.datePatternValidate(date);
  }

  public datePatternValidate(value) {
    if (this.required && this.datePickerInput.invalid) {
      this.errors.required = true;
    } else {
      this.errors.required = false;
    }
  }
}
