//

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'champs-standalone-report-app',
  templateUrl: './standalone-report-app.component.html',
  styleUrls: ['./standalone-report-app.component.scss'],
})
export class StandaloneReportAppComponent implements OnInit {
  public cards: { title: string; description: string; routeName: string; routeURL: string }[];
  public currentRole: any;
  public isReportingRole: any;
  public mstrLink: any;
  public showError: boolean = false;
  public reports: any[];
  public showReport: boolean = false;
  public showPublicReport: boolean = false;
  public showApplicationReport: boolean = false;
  public isNoRole: any;
  constructor(private router: Router, private auth: AuthService, private msal: MsalService, private http: HttpClient) { }

  public ngOnInit() {
    this.checkReportAccess();
    // Need to correct here
    this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
    this.currentRole = !this.auth.roles ? 'NoRole' : this.auth.userProfile.roles;
    this.isNoRole = !this.auth.roles ? true : false;
    const prod = [
      // prod
      'https://wapazewpmlit002champsweb.crb.apmoller.net/reports',

      'https://champs.apmoller.net/reports',
      // prod
      'https://wapazeapmlit002champsweb.crb.apmoller.net/reports',

      'https://champsea.apmoller.net/reports',
    ];
    const url = window.location.href;

    if (prod.includes(url)) {
      this.mstrLink =
        'https://microstrategy.biinsights.apmoller.net:8443/MicroStrategy/servlet/mstrWeb?Server=APMLMICROITL01&Project=CHAMPS&Port=0&evt=2001&src=mstrWeb.shared.fbb.fb.2001&folderID=7C3C44464E180914C79B70AA115D4B60';
    } else {
      this.mstrLink =
        'https://microstrategyuat.biinsights.apmoller.net:8443/MicroStrategy/servlet/mstrWeb?Server=ARMLMICROITL01&Project=CHAMPS&Port=0&evt=2001&src=mstrWeb.shared.fbb.fb.2001&folderID=7C3C44464E180914C79B70AA115D4B60';
    }
    const hadAcess = this.auth.checkPreviousAccess();
    hadAcess.subscribe((data) => {
      if (data) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    });
  }

  public navigateTo(URL: string) {
    this.router.navigate([URL]);
  }

  public checkReportAccess() {
    this.msal.instance.acquireTokenSilent({ scopes: ['https://analysis.windows.net/powerbi/api/.default'] }).then((result) => {
      const applicationReportURL$ = this.http.get('https://api.powerbi.com/v1.0/myorg/groups/adeff199-f790-49bf-93e4-9c10dc1fc033/reports', {
        headers: { 'Authorization': 'Bearer ' + result.accessToken }
      }).pipe(catchError(error => {
        return of(false);
      }));

      const publicReportURL$ = this.http.get('https://api.powerbi.com/v1.0/myorg/groups/3ef637e0-5674-49d9-938c-97877913a031/reports', {
        headers: { 'Authorization': 'Bearer ' + result.accessToken }
      }).pipe(catchError(error => {
        return of(false);
      }));

      forkJoin([applicationReportURL$, publicReportURL$]).subscribe(([res1, res2]) => {
        this.showApplicationReport = !!res1;
        this.showPublicReport = !!res2;
        this.showReport = true;
      });
    });
  }

  public navigateToReport(report: any) {
    const reportName = report.name.replace(/ /g, '-').toLowerCase();
    const param = {
      id: report.id,
      url: report.embedUrl,
    };
    const url = this.router.createUrlTree([`reports/${reportName}`], { queryParams: param });
    window.open(url.toString(), '_blank');
  }

  public openWindow(url) {
    window.open(url, '_blank');
    window.focus();
  }
}
