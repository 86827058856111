// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIHelperService {
  private sidebarState = new BehaviorSubject<string>('open');
  public currentSidebarState = this.sidebarState.asObservable();

  constructor() {}

  public changeSidebarState(state: string) {
    this.sidebarState.next(state);
  }
}
