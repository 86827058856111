// vessels related service

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, switchMap, take, filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs'; // Passing data from overview to redesign comp
import * as fromVesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';
import * as VesselQuestionnaireActions from 'src/app/state/vessels/actions/vessel-questionnaire.actions';
import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { environment } from 'src/environments/environment';
import { deepmerge } from 'src/app/utils/deep-merge';
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Injectable()
export class VesselsService {
  private dataSource = new BehaviorSubject<any>('');
  private emailStatusUpdateSource = new Subject<void>();
  public emailStatusUpdate$ = this.emailStatusUpdateSource.asObservable();
  public data = this.dataSource.asObservable();
  public saveButtonState = new BehaviorSubject<boolean>(false);
  public a3Data = new Subject();
  constructor(private http: HttpClient, private alertService: AlertService, private store: Store<any>) {}

  /**
   * get Vessel List
   * @param {any} params filter params for pagination
   */

  public getVessels(params: any) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/Vessels', params);
  }

  /**
   * get vessel detail for overview
   * @param {number} id vessel PKId
   */
  public getSingleVesselData(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/Vessel/' + id);
  }

  /**
   * update vessel color for overview
   * @param {any} data  vessel detail data of type
   */
  public updateVesselColor(data: any) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/VesselDetail/UpdateVesselColor', data);
  }

  /**
   * get vessel's fixtures
   * @param {number} id vessel PKId
   */
  public getVesselFixtures(id: number) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetByVessel/' + id);
  }

  /**
   * get vessel's deplyments
   * @param {number} id vessel PKId
   */
  public getVesselDeployments(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselDeployment/' + id);
  }

  /**
   * get vessel details
   * @param {number} id vessel PKId
   */
  public getVMD(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselDetail/' + id);
  }

  /**
   * update vessel
   * @param {String} detailType vessel detail type
   * @param {any} data  vessel detail data of type
   */
  public postVMD(detailType: string, data: any) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/VesselDetail/' + detailType, data);
  }

  /**
   *  get dropdowns for vessels
   */
  public getVesselDetailDropdowns(): any {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselDetailDropdowns');
  }

  /**
   * post questionnaire excel
   * @param {FormData} quesXLS Excel load
   */
  public postUploadCompare(quesXLS: FormData): Observable<any> {
    return this.http
      .post(environment.baseUrl + '/api/VesselManagement/ImportQuestionnaire', quesXLS, {
        reportProgress: true,
      })
      .pipe(
        filter((x) => x !== undefined),
        tap((res) => {
          if (res.currentVessel.vmMONumber) {
            this.alertService.success('Questionnaire uploaded', true);
          }
          if (!res.currentVessel.vmMONumber) {
            this.alertService.error(
              'Questionnaire uploaded without IMO number. Please upload again with IMO number',
              true
            );
          }

          let vessel = {
            ...res.currentVessel,
            ...{
              vslPKID: res.previousVessel ? res.previousVessel.vslPKID : 0,
            },
          };

          vessel = deepmerge(fromVesselCreate.emptyCreateVessel.form, vessel);

          vessel.shopTrial[0].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[0].vstpkID : null;
          vessel.shopTrial[1].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[1].vstpkID : null;
          vessel.shopTrial[2].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[2].vstpkID : null;
          vessel.shopTrial[3].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[3].vstpkID : null;
          vessel.shopTrial[4].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[4].vstpkID : null;
          vessel.shopTrial[5].vstpkID =
            res.previousVessel && res.previousVessel.shopTrial.length ? res.previousVessel.shopTrial[5].vstpkID : null;

          vessel.turboChargerCutOut[0].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[0].vccopkID
              : null;
          vessel.turboChargerCutOut[1].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[1].vccopkID
              : null;
          vessel.turboChargerCutOut[2].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[2].vccopkID
              : null;
          vessel.turboChargerCutOut[3].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[3].vccopkID
              : null;
          vessel.turboChargerCutOut[4].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[4].vccopkID
              : null;
          vessel.turboChargerCutOut[5].vccopkID =
            res.previousVessel && res.previousVessel.turboChargerCutOut.length
              ? res.previousVessel.turboChargerCutOut[5].vccopkID
              : null;

          vessel.auxEngine[0].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[0].vaePKID : null;
          vessel.auxEngine[1].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[1].vaePKID : null;
          vessel.auxEngine[2].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[2].vaePKID : null;
          vessel.auxEngine[3].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[3].vaePKID : null;
          vessel.auxEngine[4].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[4].vaePKID : null;
          vessel.auxEngine[5].vaePKID =
            res.previousVessel && res.previousVessel.auxEngine.length ? res.previousVessel.auxEngine[5].vaePKID : null;

          vessel.enginePowerConsumption[0].vepPKID =
            res.previousVessel && res.previousVessel.enginePowerConsumption.length
              ? res.previousVessel.enginePowerConsumption[0].vepPKID
              : null;
          vessel.enginePowerConsumption[1].vepPKID =
            res.previousVessel && res.previousVessel.enginePowerConsumption.length
              ? res.previousVessel.enginePowerConsumption[1].vepPKID
              : null;

          vessel.beaufort2[0].vbPKID =
            res.previousVessel && res.previousVessel.beaufort2.length ? res.previousVessel.beaufort2[0].vbPKID : null;
          vessel.beaufort2[1].vbPKID =
            res.previousVessel && res.previousVessel.beaufort2.length ? res.previousVessel.beaufort2[1].vbPKID : null;

          vessel.beaufort4[0].vbPKID =
            res.previousVessel && res.previousVessel.beaufort4.length ? res.previousVessel.beaufort4[0].vbPKID : null;
          vessel.beaufort4[1].vbPKID =
            res.previousVessel && res.previousVessel.beaufort4.length ? res.previousVessel.beaufort4[1].vbPKID : null;

          this.store.dispatch(new VesselCreateActions.PutCreateVessel(res.previousVessel));
          this.store.dispatch(new VesselQuestionnaireActions.PutVesselQuestionnaires(vessel));

          if (res.previousVessel && res.previousVessel.vmVesselName && res.previousVessel.vmMONumber) {
            const msg =
              'Vessel identified: IMO ' +
              res.previousVessel.vmMONumber +
              ' – ' +
              res.previousVessel.vmVesselName +
              ' of ' +
              res.previousVessel.vslCountryforReg;
            this.alertService.success(msg, true);
          }
        })
      );
  }

  /**
   * get vessel detail using IMO Number or Hull Number for create
   * @param {any} data IMO Number or Hull Number object
   */
  public getVesselDetailForCreate(data: any): Observable<any> {
    return this.http
      .get(environment.baseUrl + '/api/VesselManagement/GetVesselDetailForCreate', {
        params: data,
      })
      .pipe(
        tap((vesselDetails) => {
          if (vesselDetails && vesselDetails.vmVesselName && vesselDetails.vmMONumber) {
            const msg =
              'Vessel identified: IMO ' +
              vesselDetails.vmMONumber +
              ' – ' +
              vesselDetails.vmVesselName +
              ' of ' +
              vesselDetails.vslCountryforReg;
            this.alertService.success(msg, true);
          }
        })
      );
  }

  /**
   * post create vessel
   */
  public createVessel(): Observable<any> {
    return this.store.pipe(
      select(fromVesselCreate.getVesselFormGrp),
      take(1),
      switchMap((val) => {
        const postObj = {
          ...val.generalInformation,
          ...val.vesselSpecification,
          ...val.machineryPerticulars,
          ...val.speedAndConsumption,
          ...val.communication,
          ...val.cargoCapLayout,
          ...val.plansDocCert,
          ...val.additionalInfo,
        };
        if (postObj.vesselCode[0].vcType === 'VSA') {
          return this.http.post(environment.baseUrl + '/api/VesselManagement/Vessel/CreateOrUpdateVSA', postObj);
        } else {
          return this.http.post(environment.baseUrl + '/api/VesselManagement/Vessel/CreateOrUpdate', postObj);
        }
      })
    );
  }

  public getVesselData() {
    const obj = this.store.pipe(
      select(fromVesselCreate.getVesselFormGrp),
      take(1),
      switchMap((val) => {
        const postObj = {
          ...val.generalInformation,
          ...val.vesselSpecification,
          ...val.machineryPerticulars,
          ...val.speedAndConsumption,
          ...val.communication,
          ...val.cargoCapLayout,
          ...val.plansDocCert,
          ...val.additionalInfo,
        };
        return val;
      })
    );
  }

  /**
   * get vessel details
   * @param {number} id vessel PKId
   */
  public getVesselEvaluationData(imo: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselFleetRisk/' + imo);
  }
  public getVesselBMTInspectionCrane(imo: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/CraneBMTInspection/' + imo);
  }

  public getVesselBMTInspectionVessel(imo: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselBMTInspection/' + imo);
  }
  public getVesselOffhireScore(vesselid: number) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/GetDeploymentDurationByDays/' + vesselid);
  }
  public getVesselScore(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselEvaluation/GetVesselScore/' + id);
  }

  public getVesselScoreOutstandings(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselEvaluation/GetVesselEvaluationOutstandings/' + id);
  }

  public getFRPVesselScore(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselEvaluation/GetFRPVesselScore/' + id);
  }

  public getDonutChartDetails(id: number) {
    return this.http.get(environment.baseUrl + '/api/VesselEvaluation/GetDonutChartDetails/' + id);
  }
  /** Service for Fixture Options */
  public getFixtureOptions(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureOption/' + fixtureId);
  }
  /** service for 'vessel deployemnt create' dropdowns */
  public getVesselDeploymentDropdowns(vesselId?, serviceId?) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselDeployment/Dropdowns/' + vesselId);
  }

  public getVesselDeploymentDropdownsByServiceId(vesselId?, serviceId?) {
    return this.http.get(
      environment.baseUrl + '/api/VesselManagement/VesselDeployment/Dropdowns/' + vesselId + '/' + serviceId
    );
  }

  /** service LIST for 'vessel deployemnt create' dropdowns */
  public getVesselDeploymentDropdownsServicesList(vesselId?) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/VesselDeployment/ServicesList');
  }

  /** service for 'vessel deployemnt create' saveee */
  public saveVesselDeploymentNew(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/VesselDeployement/CreateOrUpdate', postObj);
  }

  /** service for 'vessel deployemnt create' save */
  public saveVesselDeploymentNewVessel(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/VesselDeployement/OverlapDeployment', postObj);
  }

  // delete deployment
  public deleteVesselDeployment(postData) {
    return this.http.post(
      environment.baseUrl + '/api/VesselManagement/VesselDeployement/DeleteVesselDeploymentOnSchedulePlannerAsync',
      postData
    );
  }
  /** service to fetch ports and dates based on service */
  public getPortsandDatesbyService(serviceId, startDate) {
    return this.http.get(
      environment.baseUrl + '/api/VesselManagement/GetPortsAndDatesByService/' + serviceId + '/' + startDate
    );
  }
  public getPortsByServiceByPosition(serviceId, startDate, endDate, position) {
    return this.http.get(
      environment.baseUrl +
        '/api/VesselManagement/GetPortsByService/' +
        serviceId +
        '/' +
        startDate +
        '/' +
        endDate +
        '/' +
        position
    );
  }
  public getPortsByService(serviceId, startDate, endDate) {
    return this.http.get(
      environment.baseUrl + '/api/VesselManagement/GetPortsByService/' + serviceId + '/' + startDate + '/' + endDate
    );
  }
  public getVesselMapPosition(imoNo, vesselCode) {
    return this.http.get(
      environment.baseUrl + '/api/VesselManagement/GetBODVesselPosition/' + imoNo + '/' + vesselCode
    );
  }
  /** API to check duplicate vessel code */
  public checkDuplicateVesselCode(codeList) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/CheckDuplicateVesselCode/' + codeList);
  }
  /** */
  public getOwnerOffhireScore(ownerId) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/GetFixtureOffhireBlockByCommOwnerId/' + ownerId);
  }
  /* to get deployment details  */
  public getDeploymentDetails(deploymentId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/StringPosition/' + deploymentId);
  }
  // Dummy Details
  public getDummyDetails(vslId) {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/Vessel/Dummy/VesselDetails/' + vslId);
  }
  public postDummyDetails(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/Vessel/Dummy/CreateOrUpdate', postObj);
  }
  /* Delete dummy */
  public deleteDummyDetails(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/Vessel/Dummy/Delete', postObj);
  }

  /* Check Duplicate dummy */
  public checkDuplicateDummy(postObj) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/Vessel/Dummy/CheckDuplicateDummy', postObj);
  }

  // service call to get bunker info for creating service
  public getBunkerForCharter() {
    return this.http.get(environment.baseUrl + '/api/AverageBunker/BunkerPriceForCharterRequest');
  }

  // Passing data from overview to redesign comp
  public setDataCombineArr(data) {
    this.dataSource.next(data);
  }

  public getDataCombineArr() {
    const tempData = this.data;
    this.clearData();
    return tempData;
  }

  public clearData() {
    this.data = null;
  }

  public getNegotiatingRequestStage(charterId) {
    return this.http.get(environment.baseUrl + '/api/ServiceManagement/CharterRequest/RequestStage/' + charterId);
  }

  public getApprovalData(id) {
    return this.http.get(
      environment.baseUrl + '/api/ServiceManagement/CharterRequest/ManagementApproval/Summary?charterRequestId=' + id
    );
  }

  /** get opportunity list for report page */
  public getOpportunityListReportPage() {
    return this.http.get(environment.baseUrl + '/api/Dashboard/Opprtunity/GetOpportunityReportDetailsListAsync');
  }
  /** get opportunity list for report page */
  public getA3ServiceViewData(obj, emit: boolean) {
    if (!emit) {
      return this.http.post(environment.baseUrl + '/api/A3View/A3ViewTableData', obj).toPromise();
    }
    this.http.post(environment.baseUrl + '/api/A3View/A3ViewTableData', obj).subscribe((res) => this.a3Data.next(res));
  }

  public getA3Filters() {
    return this.http.get(environment.baseUrl + '/api/A3View/A3ViewFilters');
  }

  public getStakeholderEmailContacts(): Observable<any> {
    return this.http.get(
      environment.baseUrl + '/api/DictionaryManagement/StakeholderEmail/GetStakeholderEmailcontacts'
    );
  }

  public getEmailTemplate(depPKID: number, eventType: string) {
    return this.http.get(
      environment.baseUrl + '/api/VesselManagement/VesselDeployment/GetDeploymentCommTemplate/' + depPKID + '/' + eventType
    );
  }

  public getEmailStatus(data) {
    return this.http.post(environment.baseUrl + '/api/VesselManagement/VesselDeployment/UpdateDepCommStatus', data);
  }

  public emitEmailStatusUpdate() {
    this.emailStatusUpdateSource.next();
  }

  public getA3SavedFilters(data) {
    return this.http.post(environment.baseUrl + '/api/A3View/CreateOrUpdateA3Filters', data);
  }

  //service view
  public getWeeklyCapacityViewData(data) {
    return this.http.post(environment.baseUrl + '/api/Dashboard/ServiceView/GetServieViewDetailsListAsync',data)
  }

  public getFiltersWeeklyCapacityView(userId) {
    return this.http.get(environment.baseUrl + '/api/Dashboard/ServiceView/GetServiceViewFilterListAsync?userid='+userId)
  }
}
