//

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HttpSpinnerToggleService {
  public spinnerUrls: any = {};

  public showSpinner: Subject<boolean> = new Subject();

  public addUrl: Subject<string> = new Subject();
  public removeUrl: Subject<string> = new Subject();

  constructor() {
    // REFACTOR - move subscribes to onInit
    this.addUrl.subscribe(url => {
      if (url) {
        this.spinnerUrls[url] = true;
        this.toggleSpinner();
      }
    });

    this.removeUrl.subscribe(url => {
      if (url && this.spinnerUrls[url.split('?')[0]]) {
        delete this.spinnerUrls[url.split('?')[0]];
      }
      this.toggleSpinner();
    });
  }

  /**
   * function to toggle spinner
   */
  private toggleSpinner() {
    if (Object.keys(this.spinnerUrls).length <= 0) {
      this.showSpinner.next(false);
    } else {
      this.showSpinner.next(true);
    }
  }
}
