//

import {
  Component,
  ViewContainerRef,
  Inject,
  ViewChild,
  Optional,
  NgZone,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerContent, MatDatepicker, MAT_DATEPICKER_SCROLL_STRATEGY, MatDateSelectionModel } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'champs-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class.mat-calender-inline]': 'showInline',
    '[class.mat-calender-touch]': 'touchUi',
  },
  styleUrls: ['./custom-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatepickerComponent<D> extends MatDatepicker<D> {
  @ViewChild('datePicker', { static: false, read: ViewContainerRef })
  public datePickerViewContainerRef: ViewContainerRef;

  @Input('inline')
  set showInline(value: boolean) {
    if (value) {
      this.createDatePicker();
    }
    this._inline = value;
  }
  get showInline() {
    return this._inline;
  }
  // tslint:disable-next-line:member-ordering
  private _inline: boolean = false;

  constructor(
    _dialog: MatDialog,
    _overlay: Overlay,
    _ngZone: NgZone,
    _viewContainerRef: ViewContainerRef,
    @Inject(MAT_DATEPICKER_SCROLL_STRATEGY) _scrollStrategy,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Optional() _dir: Directionality,
    @Optional() _model: MatDateSelectionModel<D, D>,
    @Optional() @Inject(DOCUMENT) _document: any,
    private viewContainerRef: ViewContainerRef,
    @Optional() private dateAdapter: DateAdapter<D>
  ) {
    super(_overlay, _ngZone, _viewContainerRef, _scrollStrategy, _dateAdapter, _dir, _document);
  }

  public open() {
    if (this._inline) {
      return;
    }

    super.open();
  }

  public close() {
    if (this._inline) {
      return;
    }

    super.close();
  }

  /** Selects the given date */
  public _select(date: D | any): void {
  }

  private createDatePicker() {
    const component = this.viewContainerRef.createComponent(MatDatepickerContent);
    // const component = factory.create(this.datePickerViewContainerRef.parentInjector);
    const instance = component.instance;
    instance.datepicker = this;
    this.datePickerViewContainerRef.insert(component.hostView);
  }
}
