//

import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  combineReducers
} from '@ngrx/store';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from 'src/environments/environment';

import { reducer as vesselQuestionnaire } from './vessels/reducers/vessel-questionnaire.reducer';
import { reducer as vesselCreate } from './vessels/reducers/vessel-create.reducer';
import { reducer as vesselList } from './vessels/reducers/vessel-list.reducer';

import { reducer as spinner } from './spinner/reducers/spinner.reducer';

import { reducer as dropdowns } from './dropdowns/reducers/dropdown-content.reducer';

// tslint:disable-next-line:no-empty-interface
export interface State {

}

export const reducers: ActionReducerMap<State> = {
  dropdowns,
  spinner,
  vessel: combineReducers({
    create: vesselCreate,
    list: vesselList,
    questionnaire: vesselQuestionnaire
  })
};

// export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
//   return function(state: State, action: any): State {
//     // tslint:disable-next-line:no-console
//     console.log('state', state);
//     // tslint:disable-next-line:no-console
//     console.log('action', action);

//     return reducer(state, action);
//   };
// }

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [storeFreeze]
  : [];
