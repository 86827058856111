//

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { share, take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { DownloadFileService } from '../../../../core/services/download-file/download-file.service';

@Injectable()
export class FixturesService {
  public data: EventEmitter<any> = new EventEmitter();
  public currentData = this.data;
  public fixtureDetails;

  public deletedFixtureText: string;

  public refreshMoreActions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public fixtureDataObservable = new BehaviorSubject([]);
  public fixtureCurrentDetails = this.fixtureDataObservable.asObservable();

  public fixData: any;
  public portInfo: any;
  constructor(private http: HttpClient, private download: DownloadFileService) { }

  public getUpdatedFixtureData() {
    return this.fixtureCurrentDetails;
  }

  public updateFixtureDataSourceObservable(data?) {
    this.fixtureDataObservable.next(data);
  }

  public refreshFixtureDataSourceObservable(id?) {
    this.getFixtureOverview(id)
      .pipe(take(1))
      .subscribe((details: any) => {
        this.fixtureDataObservable.next(details);
      });
  }

  /** Services for fixtures list */
  public getFixtures() {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/Fixtures');
  }

  /** Service to get fixture overview */
  public getFixtureOverview(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureOverview/' + fixtureId);
  }
  public getEmailTemplate(fixID, frnPKID, fixTypeID, operatorID, templateID) {
    return this.http.get(
      environment.baseUrl +
      '/api/FixtureManagement/Fixture/GetNoticeDetailsForTemplate?fixpkID=' +
      fixID +
      '&noticeID=' +
      frnPKID +
      '&noticeTypeID=' +
      fixTypeID +
      '&operatorID=' +
      operatorID +
      '&templateID=' +
      templateID
    );
  }

  /** Service to post fixture overview */
  public postFixtureOverview(blockName, data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOverview/' + blockName, data);
  }

  /** Services for individual blocks in fixture overview */

  public declarePurchaseOptions(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixturePurchaseOption/Declare/', postData);
  }

  public clearDeclare(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixturePurchaseOption/ClearDeclare/', postData);
  }

  public deletePurchaseOptions(optionId) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/PurchaseOption/Remove/', optionId);
  }

  /** Services for bunker card */
  public getBunkersOverview(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureBunker/' + fixtureId);
  }

  public getBunkerTypes() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/BunkerTypes/');
  }
  // Bunker Grade API call for Bunker Tab
  public getBunkerGrades() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/BunkerGrades');
  }

  public postBunkerCard(bunkerDataPostObject) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureBunker/CreateOrUpdate',
      bunkerDataPostObject
    );
  }

  public deleteBunkerType(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureBunker/Remove/', id);
  }
  public getOffhireDetails(id) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/Offhires/' + id);
  }
  /** Services for bunker clause card */
  public postBunkerClauseCard(bunkerClauseDataPostObject) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureBunkerClause/CreateOrUpdate',
      bunkerClauseDataPostObject
    );
  }

  public deleteBunkerAlert(id) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureBunkerAlert/Remove/' + id);
  }

  public closeBunkerAlert(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureBunkerAlert/Close/', id);
  }

  /** Services for fixture deliver notices */
  public getFixtureDeliverNotices(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureDeliverNotices/' + fixtureId);
  }

  public postFixtureCreateOrUpdate() {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureOverview/CreateOrUpdatePurchaseOption/');
  }

  public postFixtureDeliverRecieve(data: any) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureDeliverNotice/Receive', data);
  }

  public postFixtureDeliverNotice(data: any) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureDeliverNotice/CreateOrUpdate', data);
  }

  public deleteFixtureDeliverNotice(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureDeliverNotice/Remove/', id);
  }

  public createOffhire(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/Offhires/CreateOrUpdate', data);
  }

  public createExtensionFixture(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureExtension/Create', data);
  }

  public deleteOffhire(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/Offhire/Remove/', id);
  }

  /**
   * Service for fixture redelivery notices
   */
  public getFixtureRedeliverNotices(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureRedeliverNotices/' + fixtureId);
  }

  public removeRedeliveryNotice(noticeId) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRedeliverNotice/Remove/', noticeId);
  }

  /**
   * Service for post redelivery notice
   */
  public postFixtureRedeliverNotices(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRedeliverNotice/CreateOrUpdate/', data);
  }

  /**
   * Service for get redelivery notice Action
   */
  public getFixtureRedeliverNoticeAction(fixID, frnPKID, fixTypeID, operatorID, templateID) {
    return this.http.get(
      environment.baseUrl +
      '/api/FixtureManagement/FixtureRedeliverNoticeAction/' +
      fixID +
      '?noticeID=' +
      frnPKID +
      '&noticeTypeID=' +
      fixTypeID +
      '&operatorID=' +
      operatorID +
      '&templateID=' +
      templateID
    );
  }
  /** */
  public postFixtureRedeliverRecieve(data: any) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRedeliverNotice/Receive', data);
  }
  /**
   * Service for get delivery notice Action
   */
  public getFixtureDeliverNoticeAction(fixID, frnPKID, fixTypeID, operatorID, templateID) {
    return this.http.get(
      environment.baseUrl +
      '/api/FixtureManagement/FixtureDeliverNoticeAction/' +
      fixID +
      '?noticeID=' +
      frnPKID +
      '&noticeTypeID=' +
      fixTypeID +
      '&operatorID=' +
      operatorID +
      '&templateID=' +
      templateID
    );
  }

  /**
   * Service for send redelivery notice
   */
  public sendFixtureRedeliveryNotice(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRedeliverNoticeSend', data);
  }

  /**
   * Service for send delivery notice
   */
  public sendFixtureDeliveryNotice(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureDeliverNoticeSend', data);
  }

  /** Services for fixture misc notices */
  public getFixtureMiscNotices(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureMiscNotices/' + fixtureId);
  }

  public deleteFixtureMiscNotices(noticeId) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureMiscNotice/Remove/', noticeId);
  }

  public declareFixtureMiscNotices(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureMiscNotice/Declare/', postObj);
  }

  public postFixtureMiscNotices(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureMiscNotice/CreateOrUpdate/', postObj);
  }

  public getFixtureMiscNoticeDateOption(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureMiscNoticeDateOption/' + fixtureId);
  }
  /** Services for fixture delivery info */
  public getDeliveryPlaces() {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/DeliveryPlaces');
  }
  public getDeliveryPorts() {
    return this.http.get(environment.baseUrl + '/api/PortManagement/Ports');
  }
  public postFixtureDeliveryInfo(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureOverview/CreateOrUpdateDelivery/',
      postObj
    );
  }

  public deleteBlackout(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureBlackout/Remove/', id);
  }

  public postBlackout(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureBlackout/CreateOrUpdate', postObj);
  }

  public postFixtureRedeliveryInfo(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOverview/UpdateRedelivery', postObj);
  }

  public deletePort(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixturePort/Remove/', postData);
  }

  public postFixturePorts(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixturePort/CreateOrUpdate', postObj);
  }

  public getFixturePortRanges(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/GetFixturePortRanges', postObj);
  }

  /** resolving merge conflicts */
  public postFixtureContractPayment(data) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureOverview/CreateOrUpdateContractAndPayment',
      data
    );
  }

  /*** Service to get charter list for fixture general info(Edit)
   */
  public getChartere() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/ChartererProps');
  }

  /*** Service to get vessel list for fixture general info(Edit)
   */
  public getVessels() {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/Vessels/GetVesselListForFixture');
  }

  /*** Service to save fixture general info
   */
  public saveFixtureGeneralInfo(data) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureOverview/UpdateFixtureGeneralInformation',
      data
    );
  }

  /** Service to get fixture expenses */
  public getFixtureExpenses(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureExpense/' + fixtureId);
  }

  /** Service to post fixture expense */
  public postFixtureExpense(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureExpense/CreateOrUpdate', data);
  }

  public removeFixtureExpense(expenseId) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureExpense/Remove/', expenseId);
  }

  /** Service to post fixture expense */
  public postFixtureLumpsum(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureLumpsum/CreateOrUpdate', data);
  }

  /** Service to post fixture expense */
  public postSplitFixtureLumpsum(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureLumpsum/Split', data);
  }

  public removeFixtureLumpsum(lumpsumId) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureLumpsum/Remove/', lumpsumId);
  }

  /** Service to post fixture expense */
  public splitFixtureLumpsum(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureLumpsum/Split', data);
  }

  public getFixtureRate(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureRate/' + fixtureId);
  }

  public postFixtureRate(data) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRate/CreateOrUpdate', data);
  }
  /** Service for Broker & Commission */
  public getBrokerCommission() {
    return this.http.get(environment.baseUrl + '/api/VesselManagement/GetVesselBrokerList').pipe(share());
  }
  /** Service for Fixture Options */
  public getFixtureOptions(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureOption/' + fixtureId);
  }
  /** Service for using an Option */
  public useFixtureOptions(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOption/Use/', postData);
  }
  /** Service for Not using an Option */
  public notUseFixtureOptions(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOption/NotUse/', postData);
  }

  /** Service for FixtureTypes */
  public getFixtureTypes(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureTypes');
  }
  /** Service for save date */
  public postCreateFixture(fixData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/Fixture/CreateOrUpdate', fixData);
  }

  /** service to create Rates */
  public createOptionRate(optData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOption/CreateOrUpdate', optData);
  }

  public removeRate(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOption/Rate/Remove/', id);
  }

  public removeRateFromFixture(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRate/Remove/', id);
  }

  public removeOption(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureOption/Remove/', id);
  }
  /** Service for get Fixtur restriction */
  public getRestrictionList(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureVesselRestrictionsList/' + fixtureId);
  }

  public getHireStatusOverview(fixtureId) {
    return this.http.get(
      environment.baseUrl + '/api/HireStatementManagement/GetHireStatusBlock?fixtureId=' + fixtureId
    );
  }
  public getHireStatementOverviewList(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/HireStatementManagement/HireStatementOverview/' + fixtureId);
  }
  public postHireStatusCard(postObj) {
    return this.http.post(environment.baseUrl + '/api/HireStatementManagement/UpdateHireStatusBlock', postObj);
  }

  public getAddHireStatement(fixtureId) {
    return this.http.post(environment.baseUrl + '/api/HireStatementManagement/AddHireStatement/', fixtureId);
  }
  public getHireStatementOverviewPOList(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/HireStatementManagement/HireStatementInfo?hsId=' + fixtureId);
  }
  public getHireStatementOverviewDetailsList(hireId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/HireStatementView/' + hireId);
  }

  // Post hire statement overview card
  public UpdateHSComments(postObj) {
    return this.http.post(environment.baseUrl + '/api/HireStatementManagement/UpdateHSComments', postObj);
  }

  // Resend Hire Statement
  public resendHireStatement(hsId) {
    return this.http.post(environment.baseUrl + '/api/HireStatementManagement/SAPCreatePOnSO?hsId=' + hsId, null);
  }
  // SO Cancellation Hire Statement
  public soCancellation(hireInvoice) {
    return this.http.put(
      environment.baseUrl + '/api/HireStatementManagement/SAPSOCancelation?hireInvocie=' + hireInvoice,
      null
    );
  }

  public downloadFinalHireStatement(type: string, fixtureId) {
    const url = environment.baseUrl + '/api/HireStatementManagement/FinalHirestatement/' + type + '/' + fixtureId;
    this.download.downloadFile(url);
  }

  public downloadSelectedHireStatement(postObj?) {
    if (postObj) {
      return this.http.post(environment.baseUrl + '/api/HireStatementManagement/DownloadExcelForSelectedHire', postObj, { observe: 'response', responseType: 'blob' as 'json' });

    } else {
      return null;
    }
  }
  public sendResponseTodownloadExcelForSelectedHire(res?, data?) {
    this.download.downloadExcelForSelectedHire(res, data);
  }

  public sendResponseTodownloadPDFForSelectedHire(res?, data?) {
    this.download.downloadPdfForSelectedHire(res, data);
  }

  public getRecapCommentDetails(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureRecapCommentAdditionalView/' + fixtureId);
  }

  public postFixtureRestrictionCreateUpdate(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureVesselRestrictions/CreateOrUpdate/',
      postObj
    );
  }
  public postRecapCard(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureRecap/Update', postObj);
  }
  public postCommentCard(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureComment/CreateOrUpdate', postObj);
  }
  // REFACTOR - using same API endpoint
  public postAdditionalObject(data) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureRecapCommentAdditional/CreateOrUpdateAdditionals',
      data
    );
  }
  public postAdditionalCard(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureRecapCommentAdditional/CreateOrUpdateAdditionals',
      postObj
    );
  }

  public deleteFixtureVesselRestriction(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureVesselRestrictions/Remove/', id);
  }
  /** Service for cargo restriction */
  public getCargoRestrictionList(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureCargoRestrictionsList/' + fixtureId);
  }
  public postCargoRestrictionCreateUpdate(psotObj) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureCargoRestrictions/CreateOrUpdate',
      psotObj
    );
  }

  public deleteCargoRestriction(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureCargoRestrictions/Remove/', id);
  }
  /** Service for trade restriction */
  public getTradeRestrictionList(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureTradeRestrictionsList/' + fixtureId);
  }
  public postTradeRestrictionCreateUpdate(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/FixtureTradeRestrictions/CreateOrUpdate',
      postObj
    );
  }
  public getFixtureTradePorts(postObj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/GetFixtureTradePorts', postObj);
  }

  public deleteFixtureTradePort(postData) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureTradePort/Remove/', postData);
  }

  public deleteTradeRestriction(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureTradeRestrictions/Remove/', id);
  }
  public getRecap(id) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/FixtureRecapCommentAdditionalView/' + id);
  }

  /** more actions start */
  public getDeliveryWorksplitDetails(fixID, operatorID, templateID) {
    return this.http.get(
      environment.baseUrl +
      '/api/FixtureManagement/Fixture/GetDeliveryWorksplitDetails?fixID=' +
      fixID +
      '&operatorID=' +
      operatorID +
      '&templateID=' +
      templateID
    );
  }

  public deleteFixture(id) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/Fixture/Delete/', id);
  }

  public isFixtureDeleted(value, contractNo) {
    if (value) {
      this.deletedFixtureText = 'Fixture ' + contractNo + ' successfully deleted.';
    } else {
      this.deletedFixtureText = '';
    }
  }

  public sendDeliveryWorksplitDetails(obj) {
    return this.http.post(environment.baseUrl + '/api/FixtureManagement/FixtureDeliveryWorkSplitSend', obj);
  }
  public reverseBunkerDeduction(postObj) {
    return this.http.post(environment.baseUrl + '/api/HireStatementManagement/DeductBunker/ReverseOrCancel', postObj);
  }
  public getBunkerDeductionDetails(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/DeductBunker/Details/' + fixtureId);
  }
  public refreshVendorBalance(postObj) {
    return this.http.post(
      environment.baseUrl + '/api/HireStatementManagement/HireStatusBlock/VendorBalance?fixtureId=' + postObj,
      null
    );
  }
  /** customer-vendor dictionary */
  public getCustVend() {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/SAPCustomerVendorCodes');
  }
  public setFixtureData(fixData) {
    this.fixData = fixData;
  }

  public getFixtureData() {
    return this.fixData;
  }
  /** Service to get fixture footer details */
  public getFixtureFooter(fixtureId) {
    return this.http.get(environment.baseUrl + '/api/FixtureManagement/GetFixtureFooterDetails/' + fixtureId);
  }
  /** service for updating extimate redelivery date OR port */
  public updateEstRedelivryDateOrPort(postData) {
    return this.http.post(
      environment.baseUrl + '/api/FixtureManagement/UpdateEstRedeliveryDateOrRedeliveryPortIdAsync',
      postData
    );
  }
  public setRedeliveryPortData(portInfo) {
    this.portInfo = portInfo;
  }
  public getRedeliveryDateData() {
    return this.portInfo;
  }
}
