//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdinalNumberPipe } from './ordinal-number/ordinal-number.pipe';
import { DropdownSelectedFilterPipe } from './dropdown-selected-filter/dropdown-selected-filter.pipe';
import { ReversePipe } from './reverse/reverse.pipe';
import { TypeToDisplayPipe } from './type-to-display/type-to-display.pipe';
import { ExpenseTypeToDisplayPipe } from './alphabetically-numbering/alphabetically-numbering';
import { RomanNumberingPipe } from './roman-numbering/roman-numbering.pipe';
import { ChampsDateFormatPipe } from './global-date-formating/global-date-formating';
import { PermissionPipe } from './permission/permission.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    OrdinalNumberPipe,
    DropdownSelectedFilterPipe,
    ReversePipe,
    TypeToDisplayPipe,
    ExpenseTypeToDisplayPipe,
    RomanNumberingPipe,
    ChampsDateFormatPipe,
    PermissionPipe
  ],
  exports: [
    OrdinalNumberPipe,
    DropdownSelectedFilterPipe,
    ReversePipe,
    TypeToDisplayPipe,
    ExpenseTypeToDisplayPipe,
    RomanNumberingPipe,
    ChampsDateFormatPipe,
    PermissionPipe
  ]
})
export class PipeModule { }
