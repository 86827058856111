//
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { DataServiceService } from '../../../service-repository/services/data-service.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DataScenarioService } from '../../../scenarios/services/data-scenario.service';

@Component({
  selector: 'champs-schedule-overview-planner-details',
  templateUrl: './schedule-overview-planner-details.component.html',
  styleUrls: ['./schedule-overview-planner-details.component.scss']
})
export class ScheduleOverviewPlannerDetailsComponent implements OnInit {
  @Input() public serviceRepo: any;
  public serviceDetails: any = null;
  public serviceDetailFrmGrp: UntypedFormGroup;
  public schId: any;
  public id: any;
  public schedulePlannerDetail: any;
  public scnId: any;
  public schedulePlannerLink: any;
  constructor(
    private serviceRepoService: ServiceRepositoryService,
    private dataService: DataServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private scenarioService: ScenariosService,
    private dataScService: DataScenarioService
  ) {
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.id = this.route.snapshot.paramMap.get('id');
    this.scnId = this.route.parent.snapshot.paramMap.get('scnId');
  }

  public ngOnInit() {
    this.getServicePlannerDetail();
  }

  public setSchedulePlannerLink() {
    const test = [
      // local
      'http://localhost:4200',
      // dev1
      'https://wapazewdmlit001champsweb.crb.apmoller.net',
      'https://champsdev1.apmoller.net',
      // dev2
      'https://wapazewdmlit002champsweb.crb.apmoller.net',
      'https://champsdev1.apmoller.net',
      // test
      'https://wapazewtmlit001champsweb.crb.apmoller.net',
      'https://champsst.apmoller.net',
      // sit
      'https://wapazewtmlit002champsweb.crb.apmoller.net',
      'https://champssit.apmoller.net'
    ];
    const pp = [
      // edu
      'https://wapazewumlit001champsweb.crb.apmoller.net',
      'https://champsedu.apmoller.net',
      // pp
      'https://wapazewrmlit001champsweb.crb.apmoller.net',
      'https://champspp.apmoller.net',
      // pp
      'https://wapazearmlit001champsweb.crb.apmoller.net',
      'https://champsppea.apmoller.net'
    ];
    const prod = [
      // prod
      'https://wapazewpmlit002champsweb.crb.apmoller.net',
      'https://champs.apmoller.net',
      // prod
      'https://wapazeapmlit002champsweb.crb.apmoller.net/',
      'https://champsea.apmoller.net'
    ];
    const testUrl = 'https://comst.apmoller.net/ddnd/schedulePlanner';
    const ppUrl = 'https://comsd.apmoller.net/ddnd/schedulePlannerProformaDesigner';
    const prodUrl = 'https://coms.apmoller.net/ddnd/schedulePlannerProformaDesigner';
    const url = document.location.origin;

    if (test.includes(url)) {
      this.schedulePlannerLink = testUrl + '/' + this.schedulePlannerDetail.proformaID;
    }
    if (pp.includes(url)) {
      this.schedulePlannerLink = ppUrl + '/' + this.schedulePlannerDetail.proformaID;
    }
    if (prod.includes(url)) {
      this.schedulePlannerLink = prodUrl + '/' + this.schedulePlannerDetail.proformaID;
    }
  }

  private getServicePlannerDetail() {
    const serv = this.serviceRepo
      ? this.serviceRepoService.getScheduleDetail(this.schId)
      : this.scenarioService.getScheduleDetail(this.schId, this.scnId);

    serv.subscribe((res: any) => {
      this.schedulePlannerDetail = res;
      if (!this.serviceRepo) {
        this.dataScService.updateSchIDSc(this.schedulePlannerDetail);
      }
      this.setSchedulePlannerLink();
    });
  }
}
