//

import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  AfterViewInit,
  Input,
  ElementRef,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatListItem } from '@angular/material/list';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { FixturesService } from '../../modules/fixtures/services/fixtures/fixtures.service';
import { take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { isValidDate } from 'src/app/utils/valid-date';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { ColorConfigService } from '../../core/services/color-config/color-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { addSemicolonToEnd, removeSemicolonFromEnd } from '../../utils/text-utils';
import * as _moment from 'moment';
import { customMoment } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
const moment = customMoment;
@Component({
  selector: 'champs-vessel-fixture',
  templateUrl: './vessel-fixture.component.html',
  styleUrls: ['./vessel-fixture.component.scss'],
})
export class VesselFixtureComponent implements OnInit, AfterViewInit, OnChanges {
  private _noticeAlert: any;
  private _sendNoticeDialogRef: MatDialogRef<any>;
  private _changeDialogRef: MatDialogRef<any>;
  private dialogRef: MatDialogRef<any>;

  @ViewChildren('listitem') public listItems: QueryList<MatListItem>;
  @ViewChild('sendNoticeView', { static: false }) public sendNoticeView;
  @ViewChild('redeliveryDateDialog', { static: false }) public redeliveryDateDialog;
  @ViewChild('redeliveryPortDialog', { static: false }) public redeliveryPortDialog;
  @ViewChild('email', { static: false }) public brokerEmail;
  @ViewChild('noticeText', { static: false }) public noticeContent: ElementRef;
  @ViewChild('redeliverySelector', { static: false }) public redeliverySelector;
  @ViewChild('recieveForm', { static: false }) public recieveForm: any;

  @Input() public fixture?: any = {};
  @Input() public typePrevious?: boolean = false;
  @Input() public typeUpcoming?: boolean = false;
  @Output() public noticeSend = new EventEmitter<boolean>();
  public viewRate: boolean = false;
  public fixtureOptions: any;
  public redeliveryDate: any;
  public redeliveryPort: any;
  public gsisScheduleData: any = false;

  public redeliverNotices = [];
  public showMoreVisible: boolean = false;
  public showMore: boolean = false;

  public popupFixDelivDate: boolean;
  public popupFixEstimatedDate: boolean;
  public noticeAlertIndex: any;
  public noticeAlert: any = {};

  public recieveFrmGrp: UntypedFormGroup;
  public recieveFormObject: any;
  public recieveFormData: any;
  public deliverNoticeId: any;
  public dayNumber: any;
  public isVsFixUpdateAuth: boolean;
  public red = '#d62d23';
  public orange = '#ff9b1e';
  public green = '#2dba5e';
  public black = '#000000';
  public transparentColor = '#dadada';
  public dueDateColorConfig: any;
  @ViewChild('useConfirmOption', { static: false }) public useConfirmOption: any;
  public selectedOptionId: any;
  public useOptionLength: string;
  public vesselId: any;
  public showIncorrectRateErr: boolean;
  public incorrectRatePeriodErr: any;

  public multipleEmailsPattern = '([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}\\s{0,1};\\s*)+';
  public showDateError: boolean;

  public showEarliestDateError: boolean;

  public portList: any;
  public sendNoticeError: any;
  public showLetterLoadingText: boolean = false;
  public commentSendNotice: any;
  public deliveryPortsList: any;
  public deliveryPortNames: any;
  public fixEstRedelivryDate: any;
  public newRedeliveryPort: any;
  public ignoreSaveBtnVisible: boolean = false;
  public blackoutErrors: boolean = false;
  public showError: boolean = false;
  public latestInclOffError: boolean = false;
  public valueChanged: boolean = true;
  public rangesPopup: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private vesselService: VesselsService,
    private dialog: MatDialog,
    private fixtureService: FixturesService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private colorConfigService: ColorConfigService
  ) {
    this.vesselId = this.route.parent.snapshot.paramMap.get('id');
  }

  public ngOnInit() {
    this.isVsFixUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.colorConfigService.getColorConfigForNoticesDueIn().subscribe((dueColorConfig) => {
      this.dueDateColorConfig = dueColorConfig;
    });
    this.noticeAlert = this._noticeAlert = {
      fixEstRedelivDate: this.fixture['fixEstRedelivDate'],
      fixRedeliveryPort: this.fixture['fixPortOfRedelivery'],
      fixRedeliveryPlace: this.fixture['fixRedeliveryPlace'],
      broker: this.fixture['chartereBrokerName'],
      fixEstimatedDate: this.fixture['fixEstimatedDate'],
      fixDeliveryPort: this.fixture['fixDelivRange'],
      fixDeliveryPlace: this.fixture['fixDeliveryPlace'],
      range: this.fixture['range'],
    };
    this.fixtureService.getDeliveryPorts().subscribe((response: any) => {
      this.deliveryPortsList = response;
      this.deliveryPortNames = this.deliveryPortsList.map((item) => item.prtPortName);
    });
    this.portList = this.setPortList(this.fixture['range']);
  }

  public setPortList(list) {
    return list.reduce((res, val) => {
      if (val.portsList && val.portsList.length) {
        const mapped = val.portsList.reduce((portRes, portVal) => {
          return portRes.concat([
            {
              ...portVal,
              ...{
                prtRegion: val.prtRegion,
              },
            },
          ]);
        }, []);
        return res.concat(mapped);
      }
      return res;
    }, []);
  }

  public ngAfterViewInit() {
    if (this.listItems.length > 2) {
      this.showMoreVisible = true;
      this.toggleMore(false);
    } else {
      this.showMoreVisible = false;
    }
  }

  public toggleMore = (click: boolean) => {
    if (click) {
      this.showMore = !this.showMore;
    }
    this.listItems.map((item, key) => {
      if (key > 1 && !this.showMore) {
        item._hostElement.classList.add('hide');
      } else {
        item._hostElement.classList.remove('hide');
      }
      return true;
    });
  }

  public toggleRangesPopup(show: boolean) {
    this.rangesPopup = show;
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges() {
    this.getFixtureOption();
  }
  public getFixtureOption() {
    this.vesselService.getFixtureOptions(this.fixture.fixPKID).subscribe((response) => {
      const options = response['fixtureOptions'];
      this.fixtureOptions = [];
      let index = 0;
      options.map((option: any) => {
        if (index === 0) {
          const noticeDate = new Date(option.optNoticeDate).setHours(0, 0, 0, 0);
          const currentDate = new Date(Date.now()).setHours(0, 0, 0, 0);
          if (!option.optUsed && noticeDate >= currentDate) {
            option.use = 'Use...';
          } else {
            if (option.optUsed) {
              option.use = 'Used';
            } else {
              option.use = '';
            }
          }
        } else {
          if (option.optUsed) {
            option.use = 'Used';
          } else {
            if (
              this.fixtureOptions.slice(0, index).some((i) => i.use === 'Use...') ||
              new Date(this.fixtureOptions[index - 1].optNoticeDate).setHours(0, 0, 0, 0) <
                new Date(Date.now()).setHours(0, 0, 0, 0)
            ) {
              option.use = '';
            } else {
              if (new Date(option.optNoticeDate).setHours(0, 0, 0, 0) < new Date(Date.now()).setHours(0, 0, 0, 0)) {
                option.use = '';
              } else {
                option.use = 'Use...';
              }
            }
          }
        }
        this.fixtureOptions.push(option);
        index++;
      });
    });
  }
  // send notice popup
  public openSendNotice(n: number, notice: any) {
    this.showLetterLoadingText = true;
    this.showDateError = false;
    this.noticeAlert = {};
    this.showEarliestDateError = false;
    this.noticeAlertIndex = n;
    this.sendNoticeError = '';
    let templateResponse: any;

    this.fixtureService
      .getFixtureRedeliverNoticeAction(this.fixture.fixPKID, notice.frnPKID, 2, notice.operatorID, notice.templateID)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.showLetterLoadingText = false;
          templateResponse = res;
          this.noticeAlert = {
            ...this._noticeAlert,
            fixEarliestRedelivDate: templateResponse.fixEarliestRedelivDate,
            fixEstRedelivDate: templateResponse.fixEstRedelivDate,
            latestInclOffhire: templateResponse.latestInclOffhire,
            blackOuts: [...templateResponse.blackOuts],
            noticeType: notice.frnType,
            frnDays: notice.frnDays,
            gsisSchedules: [...templateResponse.gsisSchedules],
            frnPKID: notice.frnPKID,
            broker: templateResponse.broker,
            isScheduleMatched: templateResponse.isScheduleMatched,
            noticeSubject: templateResponse.mailContent['fnSubject'],
            brokerEmail: addSemicolonToEnd(templateResponse.mailContent['fnTo']),
            noticeContent: templateResponse.mailContent['fnContent'],
          };
        },
        (err) => {
          this.showLetterLoadingText = false;
          this.noticeAlert = { ...this._noticeAlert };
          this.sendNoticeError = err.error.error;
        }
      );
    this._sendNoticeDialogRef = this.dialog.open(this.sendNoticeView, {
      width: '400px',
      panelClass: 'send-notice-container',
      disableClose: true,
    });
  }
  /**
   * Radio button selection change
   * @param {MatRadioChange} e Radio button selection change event
   */
  public gsisScheduleChanged(e: MatRadioChange) {
    if (e && e.value) {
      this.gsisScheduleData = e.value;
    }
  }

  /**
   * send notice
   */
  public sendNotice() {
    if (!this.brokerEmail.valid) {
      return;
    }
    const postObject = {
      frnPKID: this.noticeAlert.frnPKID,
      fixPKID: this.fixture.fixPKID,
      brokerEmail: removeSemicolonFromEnd(this.brokerEmail.value),
      noticeText: this.noticeAlert.noticeContent,
      noticeSubject: this.noticeAlert.noticeSubject,
      fixEstRedelivDate: moment(new Date(this.noticeAlert.fixEstRedelivDate).getTime()),
      fixRedeliveryPort: this.noticeAlert.fixRedeliveryPort,
      fixRedeliveryPlace: this.noticeAlert.fixRedeliveryPlace,
    };

    this.fixtureService
      .sendFixtureRedeliveryNotice(postObject)
      .pipe(take(1))
      .subscribe((res) => {
        this._sendNoticeDialogRef.close();
        this.noticeSend.emit(true);
      });
  }

  /**
   * open port and redelivery date change popup
   */
  public openSelectPort() {
    this.redeliveryDate = null;
    this.redeliveryPort = null;
    this.gsisScheduleData = null;
    this._changeDialogRef = this.dialog.open(this.redeliverySelector, {
      width: '325px',
      panelClass: 'portselect-container',
      disableClose: true,
      height: '550px',
      autoFocus: false,
    });
  }
  /**
   * change port and est redelivery date on Use click
   */
  public changePort() {
    this.showDateError = false;
    this.showEarliestDateError = false;
    this.noticeAlert.fixEstRedelivDate =
      (this.gsisScheduleData ? this.gsisScheduleData.arrDate : null) ||
      (isValidDate(this.redeliveryDate) ? new Date(this.redeliveryDate) : null) ||
      this.noticeAlert.fixEstRedelivDate;

    this.noticeAlert.fixRedeliveryPort =
      (this.gsisScheduleData ? this.gsisScheduleData.cityName : null) ||
      this.redeliveryPort ||
      this.noticeAlert.fixRedeliveryPort;
    this.commentSendNotice = this.noticeAlert.noticeContent;
    const fixEstRedelivDate1 = new Date(
      new DatePipe('en-US').transform(this.noticeAlert.fixEstRedelivDate, 'dd-MMM-yy')
    );
    const latestInclOffhire1 = new Date(
      new DatePipe('en-US').transform(this.noticeAlert.latestInclOffhire, 'dd-MMM-yy')
    );
    const fixEarliestRedelivDate1 = new Date(
      new DatePipe('en-US').transform(this.noticeAlert.fixEarliestRedelivDate, 'dd-MMM-yy')
    );
    if (fixEstRedelivDate1 > latestInclOffhire1) {
      this.showDateError = true;
    }
    if (fixEstRedelivDate1 < fixEarliestRedelivDate1) {
      this.showEarliestDateError = true;
    }
    this.noticeAlert.blackOuts.forEach((blackout) => {
      const fixEstRedelivDate = new Date(
        new DatePipe('en-US').transform(this.noticeAlert.fixEstRedelivDate, 'dd-MMM-yy')
      );
      const blackOutStartDate = new Date(new DatePipe('en-US').transform(blackout.fxbStartDate, 'dd-MMM-yy'));
      const blackOutEndDate = new Date(new DatePipe('en-US').transform(blackout.fxbEndDate, 'dd-MMM-yy'));
      if (fixEstRedelivDate >= blackOutStartDate && fixEstRedelivDate <= blackOutEndDate) {
        this.showDateError = true;
      }
    });
    this._changeDialogRef.close();
  }

  public showRecieveForm(day: any) {
    this.deliverNoticeId = day.frnPKID;
    this.dayNumber = day.frnDays;
    this.createRecieveForm();
    this.dialogRef = this.dialog.open(this.recieveForm, {
      width: '300px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }

  public closeRecieveForm() {
    this.dialogRef.close();
  }

  public saveRecieveForm() {
    this.recieveFormData = this.recieveFrmGrp.value;
    this.recieveFormData = {
      ...this.recieveFormData,
      frnPKID: this.deliverNoticeId,
    };
    this.fixtureService.postFixtureRedeliverRecieve(this.recieveFormData).subscribe((res) => {
      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(() => {
        this.noticeSend.emit(true);
      });
    });
  }
  public openUseOption(option: any) {
    this.selectedOptionId = option.optPKID;
    if (option.optLengthInDays) {
      this.useOptionLength =
        option.optLengthInDays === 1 ? option.optLengthInDays + ' ' + 'day' : option.optLengthInDays + ' ' + 'days';
    } else if (option.optLengthInMonths) {
      this.useOptionLength =
        option.optLengthInMonths === 1
          ? option.optLengthInMonths + ' ' + 'month'
          : option.optLengthInMonths + ' ' + 'months';
    } else if (option.optLengthInYears) {
      this.useOptionLength =
        option.optLengthInYears === 1
          ? option.optLengthInYears + ' ' + 'year'
          : option.optLengthInYears + ' ' + 'years';
    }
    const dialogRef = this.dialog.open(this.useConfirmOption);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const postData = {
            genericRequestID1: this.selectedOptionId,
            genericRequestID2: 0,
            genericString1: '',
            genericString2: '',
            genericFlag: true,
          };
          this.fixtureService.useFixtureOptions(postData).subscribe(
            (response) => {
              this.router
                .navigateByUrl('/vessels', { skipLocationChange: true })
                .then(() => this.router.navigate(['/vessels/' + this.vesselId]));
            },
            (errorResponse) => {
              if (errorResponse instanceof HttpErrorResponse) {
                const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
                if (error) {
                  this.showIncorrectRateErr = true;
                  this.incorrectRatePeriodErr = error;
                }
              }
            }
          );
        }
      });
  }
  public dueInColorSetterr(score) {
    if (this.dueDateColorConfig) {
      if (score >= this.dueDateColorConfig.lowValueScore && score <= this.dueDateColorConfig.mediumValueScore) {
        return this[this.dueDateColorConfig.lowValueColorScore];
      } else if (score > this.dueDateColorConfig.mediumValueScore && score <= this.dueDateColorConfig.highValueScore) {
        return this[this.dueDateColorConfig.mediumValueColorScore];
      } else if (score > this.dueDateColorConfig.highValueScore) {
        return this[this.dueDateColorConfig.highValueColorScore];
      } else {
        return this[this.dueDateColorConfig.lowValueColorScore];
      }
    }
  }
  public changePortPopup() {
    this.newRedeliveryPort = this.fixture.fixRedeliveryPortID;
    this.ignoreSaveBtnVisible = false;
    this.valueChanged = true;
    this.dialogRef = this.dialog.open(this.redeliveryPortDialog, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public onRedelvryPortChange(event) {
    this.valueChanged = false;
    let matchedCount = 0;
    this.portList = this.setPortList(this.fixture['range']);
    this.portList.map((item) => {
      if (+item.prtPKID === +this.newRedeliveryPort) {
        matchedCount++;
        return;
      }
    });
    if (matchedCount === 0) {
      this.ignoreSaveBtnVisible = true;
    } else if (matchedCount > 0) {
      this.ignoreSaveBtnVisible = false;
    }
  }
  public newEstRedlivPortSave() {
    const postData = {
      genericRequestID1: this.fixture.fixPKID,
      genericRequestID2: this.newRedeliveryPort,
      genericString1: null,
      genericString2: '',
      genericFlag: true,
    };
    this.fixtureService.updateEstRedelivryDateOrPort(postData).subscribe((response) => {
      this.dialogRef.close();
      this.router
        .navigateByUrl('/vessels', { skipLocationChange: true })
        .then(() => this.router.navigate(['/vessels/' + this.vesselId]));
    });
  }
  public clearfixEstRedelivryDate() {
    this.fixEstRedelivryDate = null;
  }
  public convertDateTimeToDate(dateTime) {
    return dateTime ? new Date(dateTime).setHours(0, 0, 0, 0) : null;
  }
  public changeDatePopup() {
    this.showError = false;
    this.fixEstRedelivryDate = this.fixture.fixEstRedelivDate;
    this.dialogRef = this.dialog.open(this.redeliveryDateDialog, {
      width: '280px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public blackoutValidate(date?) {
    this.blackoutErrors = false;
    const selectedDate = this.convertDateTimeToDate(new Date(date));
    this.fixture.blackouts.forEach((item) => {
      const blackoutStartDate = new Date(item.fxbStartDate).getTime();
      const blackoutEndDate = new Date(item.fxbEndDate).getTime();
      if (date) {
        if (blackoutStartDate <= selectedDate && blackoutEndDate >= selectedDate) {
          this.blackoutErrors = true;
        }
      }
    });
    return this.blackoutErrors;
  }
  public latestInclOffHireValidate(date?) {
    this.latestInclOffError = false;
    const selectedDate = this.convertDateTimeToDate(new Date(date));
    const fixLatestInclOffhire = new Date(this.fixture.fixLatestInclOffhire).getTime();
    if (selectedDate > fixLatestInclOffhire) {
      this.latestInclOffError = true;
    }
    return this.latestInclOffError;
  }
  public newEstRedlivDateSave() {
    const blackoutErrors = this.blackoutValidate(this.fixEstRedelivryDate);
    const latestInclOffError = this.latestInclOffHireValidate(this.fixEstRedelivryDate);
    if (this.blackoutErrors || this.latestInclOffError) {
      this.showError = true;
      return;
    }
    const postData = {
      genericRequestID1: this.fixture.fixPKID,
      genericRequestID2: null,
      genericString1: new Date(this.fixEstRedelivryDate).toISOString(),
      genericString2: '',
      genericFlag: true,
    };
    this.fixtureService.updateEstRedelivryDateOrPort(postData).subscribe((response) => {
      this.dialogRef.close();
      this.router
        .navigateByUrl('/vessels', { skipLocationChange: true })
        .then(() => this.router.navigate(['/vessels/' + this.vesselId]));
    });
    if (
      this.fixture.vslExtendedDummyId != null &&
      this.fixture.vslOperatorCodeID === this.fixture.vslExtendedDummyOperatorCodeId
    ) {
      this.navigateToLinkedDummy();
    }
  }

  public navigateToLinkedDummy() {
    // linked Dummy Set Item
    localStorage.setItem('this.fixEstRedelivDate', this.fixEstRedelivryDate);
    localStorage.setItem('isFromFixture', 'true');

    let idOfDummyOnExtension;
    idOfDummyOnExtension = this.fixture.vslExtendedDummyId;
    window.open('../../vessels/dummy/overview/' + idOfDummyOnExtension);
  }

  private createRecieveForm() {
    const now = new Date();
    this.recieveFrmGrp = this.fb.group(
      (this.recieveFormObject = {
        frnPKID: null,
        frnSentDate: isValidDate(now) ? new DatePipe('en-US').transform(now, 'dd-MMM-yy') : null, // date,
        receviedComment: null,
      })
    );
  }
}
