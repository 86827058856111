// vessel-deploymentnnnnn
import { Component, QueryList, ViewChild, ViewChildren, AfterViewInit, Input, OnInit } from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MY_FORMATS,
  dateTimeCompile,
  time,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { VesselsService } from '../../core/services/vessels/vessels.service';
import { PortService } from '../../core/services/port/port.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isValidDate } from 'src/app/utils/valid-date';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
const moment = customMoment;

@Component({
  selector: 'champs-vessel-deployment',
  templateUrl: './vessel-deployments.component.html',
  styleUrls: ['./vessel-deployments.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VesselDeploymentsComponent implements OnInit, AfterViewInit {
  private dialogRef: MatDialogRef<any>;

  @ViewChildren('listitem') public listItems: QueryList<MatListItem>;

  @Input() public deployment?: any = {};
  @Input() public typePrevious?: boolean = false;
  @Input() public typeUpcoming?: boolean = false;
  @Input() public typeCurrent?: boolean = false;
  @Input() public vesselDeploymentDropdownsOnInit?: any;
  @ViewChild('editPrevious', { static: false }) public editPrevious: any;
  @ViewChild('editBlackout', { static: false }) public editBlackout: any;
  @ViewChild('confirmationDialog', { static: false }) public deleteDeploymentDialogue: any;
  @ViewChild('warningDialog', { static: false }) public warningMessagetDialogue: any;

  public showMoreVisible: boolean = false;
  public showMore: boolean = false;

  public deploymentDropdown?: any = {};
  public depEndDateShowAlert: boolean;
  public depEndPortShowAlert: boolean;
  public popupDepEndDate: boolean;
  public popupDepStartDate: boolean;
  public vesselDeploymentFrmGrp: UntypedFormGroup;

  public vesselDeploymentServices: any = [];
  public vesselDeploymentSpclServices: any = [];
  public vesselDepSpclServiceTypes: any = [];
  public vesselDepUnemployedTypes: any = [];
  public vesselDepLayupTypes: any = [];
  public vesselDeploymentPorts: any = [];
  public vesselDeploymentReasons: any = [];
  public vesselDeploymentStartPort: any;
  public vesselDeploymentEndPort: any;
  public roundTrip: any;
  public trade: any;
  public hideTrade: boolean = false;
  public hideRoundTrip: boolean = false;
  public showLocationHidePort: boolean = false;
  public showvesselDepSpclServiceTypes: boolean = false;
  public showvesselDepUnemployedTypes: boolean = false;
  public dontShowServiceType: boolean = false;
  public showvesselDepLayupTypes: boolean = false;
  public time = time;
  public showDetailsDiv: boolean = false;
  public showSpecialServicesFlag: boolean = false;
  public showServicePosition: boolean = false;
  public servicePositions: any = [];
  public preServiceDetails: any = [];

  public vslId: any;
  public showError: boolean = false;
  public locationErrorMsg: any;
  public showBeginTimeError: boolean = false;
  public showBeginDateError: boolean = false;
  public showEndTimeError: boolean = false;
  public showEndDateError: boolean = false;
  /** error keys */
  public errorKeys: string[] = [];
  /** error values */
  public errorValues: any[] = [];
  public incorrectDeploymentDetails: any;
  public showIncorrectDetailError: boolean = false;
  public blackoutViolationMsg: any;
  public showDatesInvalid: boolean = false;
  public stopSavingData: boolean = false;
  public previousDeploymentId: any;
  public fixturePortsForRange: any[] = [];
  public showRangeInvalid: boolean = false;
  public showEditPreviousWarning: boolean = false;
  public isVsDepUpdateAuth: boolean = false;
  public isVsDepDeleteAuth: boolean = false;
  public vesselDetail: any;
  public deploymentStartDate: null;
  public deploymentEndDate: any;
  public showScheduleDateError: boolean;
  public scheduleDateErrorMsg: any;
  public endDateNotPresent: boolean = false;
  public teuPercent: any;
  public mlPercent: any;
  public vsaFlag: string;
  public depPositionExists: boolean = true;
  public extraLoaderPosition: any;
  public extraPosEntered: boolean = false;
  public validExtraPos: boolean = false;
  public depPositionValid: boolean = true;
  public isVesslDeplyPositionSelected: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private vesselsService: VesselsService,
    private portsService: PortService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public dialog: MatDialog
  ) {
    this.vslId = this.route.parent.snapshot.paramMap.get('id');
    route.parent.data.subscribe(({ details }) => {
      this.vesselDetail = details;
    });
  }

  public ngOnInit() {
    this.isVsDepUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.isVsDepDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'delete');
  }

  public ngAfterViewInit() {
    if (this.listItems.length > 2) {
      this.showMoreVisible = true;
      this.toggleMore(false);
    } else {
      this.showMoreVisible = false;
    }
    const f = function (x) {
      return x.filter((a) => a !== null);
    };

    const filteredBlackOutArr = this.deployment.blackouts.forEach((eachElement) => {
      f(Object.values(eachElement));
    });
  }

  public toggleMore = (click: boolean) => {
    if (click) {
      this.showMore = !this.showMore;
    }
    this.listItems.map((item, key) => {
      if (key > 1 && !this.showMore) {
        item._hostElement.classList.add('hide');
      } else {
        item._hostElement.classList.remove('hide');
      }
      return true;
    });
  }
  /** */
  public editDeployment(deploymentToEdit, isPrevious) {
    this.showScheduleDateError = false;
    this.deploymentDropdown = this.vesselDeploymentDropdownsOnInit;
    this.vesselDeploymentServices = this.deploymentDropdown.services.map((o) => {
      return {
        strPKID: o.strPKID,
        strCode: o.strCode,
        strName: o.strName,
        strRoundTrip: o.strRoundTrip,
        strTrade: o.strTrade,
        isSpecial: o.isSpecial,
        strNameCode: o.strCode + '  ' + o.strName,
        positions: o.positions,
      };
    });
    this.vesselDeploymentSpclServices = this.vesselDeploymentServices.filter((o) => o.isSpecial === true);
    this.vesselDepSpclServiceTypes = this.deploymentDropdown.specialSerivceTypes;
    this.vesselDepUnemployedTypes = this.deploymentDropdown.unemployedeTypes;
    this.vesselDepLayupTypes = this.deploymentDropdown.layupTypes;
    this.vesselDeploymentPorts = this.deploymentDropdown.ports;
    this.vesselDeploymentReasons = this.deploymentDropdown.deploymentReasons;
    this.previousDeploymentId = this.deploymentDropdown.previousDeploymentId;
    this.fixturePortsForRange = this.deploymentDropdown.fixturePorts;

    this.createNewDeploymentForm();
    if (isPrevious) {
      this.showEditConfirmation();
    }

    this.createNewDeploymentForm(deploymentToEdit);
  }
  public deleteVesselDeployment() {
    const postData = {
      vesselDeploymentId: this.vesselDeploymentFrmGrp.get('depPKID').value,
      lstScheduleIds: null,
    };
    this.vesselsService.deleteVesselDeployment(postData).subscribe((res: any) => {
      if (res) {
        this.router
          .navigateByUrl('/vessels', { skipLocationChange: true })
          .then(() => this.router.navigate(['/vessels/' + this.vslId]));
      }
    });
  }
  public deleteDeployment() {
    this.dialogRef = this.dialog.open(this.deleteDeploymentDialogue, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public closeDialogue() {
    this.dialogRef.close();
  }
  // /** */
  public createNewDeploymentForm(depValues: any = null) {
    this.deploymentStartDate = null;
    if (!this.vesselDeploymentFrmGrp) {
      this.vesselDeploymentFrmGrp = this.fb.group({
        depPKID: null,
        depVesselID: null,
        depStringID: null,
        depPosition: null,
        depStartPortID: null,
        depEndPortID: null,
        depBeginDate: null,
        depBeginTime: time[0],
        depEndDate: null,
        depEndTime: time[0],
        depReasonCodeID: this.fb.control(null, [Validators.required]),
        depComments: null,
        specialSerivceType: null,
        unemployedeType: null,
        layupType: null,
      });
    }
    if (this.vesselDeploymentFrmGrp && depValues) {
      this.deploymentStartDate = depValues.depBeginDate;
      const enddate =
        depValues.depEndDate !== null ? moment(depValues.depEndDate).add(1, 'M').format('YYYY-MM-DD') : null;
      this.deploymentEndDate = enddate;
      this.preServiceDetails = this.vesselDeploymentServices.filter((o) => o.strPKID === depValues.depStringID);

      this.vesselDeploymentFrmGrp = this.fb.group({
        depPKID: depValues.depPKID,
        depVesselID: this.vslId,
        depStringID: depValues.depStringID,
        depPosition: this.fb.control(
          depValues.extraPosition === true
            ? depValues.depPosition + 'X'
            : depValues.extraPosition === false
              ? depValues.depPosition
              : 0
        ),
        depStartPortID: depValues.isSpecial ? depValues.depSpecialServiceStartPortID : depValues.depStartPortID,
        depEndPortID: depValues.isSpecial ? depValues.depSpecialServiceEndPortID : depValues.depEndPortID,
        depBeginDate: isValidDate(depValues.depBeginDate) ? moment(depValues.depBeginDate) : null,
        depBeginTime: isValidDate(depValues.depBeginDate)
          ? new DatePipe('en-US').transform(depValues.depBeginDate, 'HH:mm')
          : time[0],
        depEndDate: isValidDate(depValues.depEndDate) ? moment(depValues.depEndDate) : null,
        depEndTime: isValidDate(depValues.depEndDate)
          ? new DatePipe('en-US').transform(depValues.depEndDate, 'HH:mm')
          : time[0],
        depReasonCodeID: depValues.depReasonCodeID,
        depComments: depValues.depComments,
        specialSerivceType: depValues.specialSerivceType ? depValues.specialSerivceType : null,
        unemployedeType: depValues.unemployedeType ? depValues.unemployedeType : null,
        layupType: depValues.layupType ? depValues.layupType : null,
      });
      if (this.preServiceDetails) {
        this.populateServiceDetails(this.preServiceDetails[0]);
      }
    }
  }

  public validateRegExPosPattern() {
    return /^(?:(^([1-9][\d]*){1,2})+([xX])$)/g.test(this.vesselDeploymentFrmGrp.controls.depPosition.value);
  }

  public validateDepPosition() {
    this.depPositionExists = true;
    this.extraPosEntered = false;
    this.showError = false;
    const servicePositionList = [];
    this.servicePositions.map((item) => {
      servicePositionList.push(item.position);
    });
    const patternMatched = this.validateRegExPosPattern();
    if (patternMatched === true) {
      this.extraPosEntered = true;
      this.extraLoaderPosition = this.vesselDeploymentFrmGrp.controls.depPosition.value;
      // For extra slice x from the input and check min max value validation
      this.extraLoaderPosition = this.extraLoaderPosition.toLowerCase();
      const xPos = this.extraLoaderPosition.indexOf('x');
      xPos === 1
        ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
        : xPos === 2
          ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
          : (this.extraLoaderPosition = null);

      if (+this.extraLoaderPosition >= 0 && +this.extraLoaderPosition <= 99) {
        this.depPositionValid = true;
        this.validatePosExists(servicePositionList, this.extraLoaderPosition, true);
      } else {
        this.depPositionValid = false;
      }
    } else if (patternMatched === false) {
      if (
        +this.vesselDeploymentFrmGrp.controls.depPosition.value >= 0 &&
        +this.vesselDeploymentFrmGrp.controls.depPosition.value <= 99
      ) {
        this.depPositionValid = true;
        this.validatePosExists(servicePositionList, this.vesselDeploymentFrmGrp.controls.depPosition.value, false);
      } else {
        this.depPositionValid = false;
      }
    }
  }
  public validatePosExists(list, positionEntered, extra?) {
    this.validExtraPos = false;
    if (list.includes(+positionEntered) && extra === false) {
      this.depPositionExists = true;
    } else if (extra === false && (+positionEntered === 0 || +positionEntered === 31 || +positionEntered === 99)) {
      this.depPositionExists = true;
    } else if (list.includes(+positionEntered) && extra === true) {
      const specialPos = ['0', '31', '99'];
      if (specialPos.indexOf('' + positionEntered) === -1) {
        this.validExtraPos = true;
      } else {
        this.depPositionExists = false;
        this.showError = true;
      }
    } else {
      this.depPositionExists = false;
      this.showError = true;
    }
  }
  /** */
  public populateServiceDetails(e) {
    this.showSpecialServicesFlag = false;
    this.showServicePosition = false;
    this.showLocationHidePort = false;
    this.showvesselDepSpclServiceTypes = false;
    this.showvesselDepUnemployedTypes = false;
    this.showvesselDepLayupTypes = false;
    this.dontShowServiceType = false;
    this.hideTrade = false;
    this.hideRoundTrip = false;
    this.showScheduleDateError = false;
    if (e.isSpecial) {
      this.hideTrade = true;
      this.hideRoundTrip = true;
      if (
        e.strName.toLowerCase() === 'docking offservice' ||
        e.strName.toLowerCase() === 'unemployed' ||
        e.strName.toLowerCase() === 'off-service' ||
        e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888'
      ) {
        this.showLocationHidePort = true;
      }
      if (e.strName.toLowerCase() === 'docking offservice') {
        const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'docking');
        if (reasonObj) {
          this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
        }
      }
      if (e.strName.toLowerCase().includes('relet')) {
        const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'relet');
        if (reasonObj) {
          this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
        }
      }
      if (e.strName.toLowerCase() === 'empties positioning') {
        const reasonObj = this.vesselDeploymentReasons.filter(
          (o) => o.drReason.toLowerCase() === 'empties positioning'
        );
        if (reasonObj) {
          this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
        }
      }
      if (e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888') {
        const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'layup');
        if (reasonObj) {
          this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
        }
      }
      if (
        e.strName.toLowerCase() === 'internal relet' ||
        e.strName.toLowerCase() === 'external relet' ||
        e.strName.toLowerCase() === 'empties positioning' ||
        e.strName.toLowerCase() === 'fleet positioning' ||
        e.strName.toLowerCase() === 'docking offservice'
      ) {
        this.showvesselDepSpclServiceTypes = true;
      }
      if (e.strName.toLowerCase() === 'unemployed') {
        this.showvesselDepUnemployedTypes = true;
      }
      if (e.strName.toLowerCase() === 'off-service') {
        this.dontShowServiceType = true;
      }
      if (e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888') {
        this.showvesselDepLayupTypes = true;
      }
      this.vesselDeploymentStartPort = [];
      this.vesselDeploymentEndPort = [];
      this.portsService.getPorts().subscribe((ports) => {
        this.vesselDeploymentStartPort = ports;
        this.vesselDeploymentEndPort = ports;
        this.vesselDeploymentStartPort = this.vesselDeploymentStartPort.map((o) => {
          return {
            schedulePortId: o.prtPKID,
            schedulePortName: o.prtPortName,
            scheduleDate: null,
            scheduleTime: null,
            scheduleType: null,
            position: null,
            portId: o.prtPKID,
          };
        });
        this.vesselDeploymentEndPort = this.vesselDeploymentEndPort.map((o) => {
          return {
            schedulePortId: o.prtPKID,
            schedulePortName: o.prtPortName,
            scheduleDate: null,
            scheduleTime: null,
            scheduleType: null,
            position: null,
            portId: o.prtPKID,
          };
        });
      });
    } else {
      this.roundTrip = e.strRoundTrip;
      this.trade = e.strTrade;
      this.showServicePosition = true;
      this.servicePositions = e.positions;
      this.vesselDeploymentStartPort = [];
      this.vesselDeploymentEndPort = [];
      this.vesselsService.getPortsByService(e.strPKID, this.deploymentStartDate, this.deploymentEndDate).subscribe(
        (response: any) => {
          const patternMatched = this.validateRegExPosPattern();
          let positionSelected;

          if (patternMatched === true) {
            const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
            const xPos = extraPosValue.indexOf('x');
            xPos === 1
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
              : xPos === 2
                ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
                : (this.extraLoaderPosition = null);
            positionSelected = this.extraLoaderPosition;
          } else {
            positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
          }

          if (positionSelected && positionSelected > 0) {
            this.isVesslDeplyPositionSelected = true;
            this.vesselDeploymentStartPort = response.portDateTime.filter(
              (o) => o.scheduleType === 0 && o.position === positionSelected
            );
            this.vesselDeploymentEndPort = response.portDateTime.filter(
              (o) => o.scheduleType === -1 && o.position === positionSelected
            );
          } else {
            this.isVesslDeplyPositionSelected = false;
            this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
            this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.scheduleType === -1);
          }
          let startPortToPopulate: any;
          const startDateBeforePort = new Date(this.vesselDeploymentFrmGrp.controls.depBeginDate.value);
          startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
          if (startPortToPopulate.length) {
            this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
          }
          let endPortToPopulate: any;
          const endDateBeforePort = new Date(
            new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
          );
          if (endDateBeforePort) {
            endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
                k.scheduleType === 0
            );
            if (endPortToPopulate.length) {
              this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
                endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
              );
            }
          }
        },
        (errorResponse) => {
          this.showScheduleDateError = true;
          const msg = errorResponse.error.error;
          this.scheduleDateErrorMsg = msg;
        }
      );
    }
  }

  /** */
  public showSpecialServices() {
    this.showSpecialServicesFlag = true;
  }
  /** */
  public populateServiceControl(e) {
    this.vesselDeploymentFrmGrp.patchValue({
      depStringID: e.strPKID,
    });
    this.populateServiceDetails(e);
  }
  /** */
  public validatePostObject(deployment, isPrevious, isUpcoming) {
    this.showError = false;
    this.showBeginDateError = false;
    this.showBeginTimeError = false;
    this.showEndDateError = false;
    this.showEndTimeError = false;
    this.showDatesInvalid = false;
    this.showRangeInvalid = false;
    this.endDateNotPresent = false;
    if (!this.vesselDeploymentFrmGrp.valid || !this.depPositionValid) {
      this.showError = true;
      return;
    }
    if (!this.vesselDeploymentFrmGrp.valid || !this.depPositionExists) {
      this.showError = true;
      return;
    }

    if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === ''
      ) {
        this.showBeginTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value === null) {
        this.showBeginDateError = true;
        return;
      }
    }
    if (this.vesselDeploymentFrmGrp.controls.depEndDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === ''
      ) {
        this.showEndTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depEndDate.value === null) {
        this.showEndDateError = true;
        return;
      }
    }
    const endDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    const beginDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depBeginDate.value,
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value
    );
    if (!endDate) {
      this.showError = true;
      this.endDateNotPresent = true;
      return;
    }
    if (endDate < beginDate) {
      this.showError = true;
      this.showDatesInvalid = true;
      return;
    }
    if (isPrevious) {
    }

    if (this.showLocationHidePort) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
        const srviceObj = this.vesselDeploymentSpclServices.filter((o) => o.strPKID === selectedService);
        if (srviceObj) {
          if (srviceObj[0].strName.toLowerCase() === 'docking offservice') {
            this.showError = true;
            this.locationErrorMsg = 'Please indicate docking location & yard';
            return;
          }
        }
      }
    }
    if (this.showServicePosition) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const patternMatched = this.validateRegExPosPattern();
        let selectedPosition;

        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
          selectedPosition = this.extraLoaderPosition;
        } else {
          selectedPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        if (selectedPosition === -1 || selectedPosition === 0) {
          this.showError = true;
          this.locationErrorMsg = 'Please indicate idle/positioning port(s)';
          return;
        }
      }
    }
    if (!(this.vesselDetail.vslVesselType === 'Own')) {
      const depEndPortIdFromList = this.vesselDeploymentEndPort.find(
        (ep) => ep.schedulePortId === this.vesselDeploymentFrmGrp.controls.depEndPortID.value
      );
      if (depEndPortIdFromList) {
        const portIdFound = this.fixturePortsForRange.find((p) => p === depEndPortIdFromList.portId);
        if (!portIdFound) {
          this.showError = true;
          this.showRangeInvalid = true;
          return;
        }
      }
    }

    if (isUpcoming) {
      const endDateDeployment = dateTimeCompile(
        this.vesselDeploymentFrmGrp.controls.depEndDate.value,
        this.vesselDeploymentFrmGrp.controls.depEndTime.value
      );
      if (deployment.blackouts.length) {
        deployment.blackouts.forEach((blackout) => {
          if (endDateDeployment >= blackout.fxbStartDate && endDateDeployment <= blackout.fxbEndDate) {
            this.blackoutViolationMsg =
              'Please note the Blackout period of ' +
              new DatePipe('en-US').transform(blackout.fxbStartDate, 'dd-MMM-yy') +
              '     to     ' +
              new DatePipe('en-US').transform(blackout.fxbEndDate, 'dd-MMM-yy') +
              '. Do you wish to edit or ignore.?';
            this.showBlackoutConfirmation(deployment, isPrevious);
          } else {
            this.saveNewDeployment(deployment, isPrevious);
          }
        });
      } else {
        this.saveNewDeployment(deployment, isPrevious);
      }
    } else {
      this.saveNewDeployment(deployment, isPrevious);
    }
  }

  /** */
  public saveNewDeployment(deployment, isPrevious) {
    if (!this.vesselDeploymentFrmGrp.valid) {
      this.showError = true;
      return;
    }

    if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === ''
      ) {
        this.showBeginTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value === null) {
        this.showBeginDateError = true;
        return;
      }
    }
    if (this.vesselDeploymentFrmGrp.controls.depEndDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === ''
      ) {
        this.showEndTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depEndDate.value === null) {
        this.showEndDateError = true;
        return;
      }
    }

    if (isPrevious) {
    }

    if (this.showLocationHidePort) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
        const srviceObj = this.vesselDeploymentSpclServices.filter((o) => o.strPKID === selectedService);
        if (srviceObj) {
          if (srviceObj[0].strName.toLowerCase() === 'docking offservice') {
            this.showError = true;
            this.locationErrorMsg = 'Please indicate docking location & yard';
            return;
          }
        }
      }
    }
    if (this.showServicePosition) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const patternMatched = this.validateRegExPosPattern();
        let selectedPosition;

        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
          selectedPosition = this.extraLoaderPosition;
        } else {
          selectedPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        if (selectedPosition === -1 || selectedPosition === 0) {
          this.showError = true;
          this.locationErrorMsg = 'Please indicate idle/positioning port(s)';
          return;
        }
      }
    }
    const postObj = this.vesselDeploymentFrmGrp.value;
    const isServiceSpcl = this.vesselDeploymentSpclServices.filter((servc) => postObj.depStringID === servc.strPKID);
    if (isServiceSpcl.length) {
      postObj.depSpecialServiceStartPortID = this.vesselDeploymentFrmGrp.controls.depStartPortID.value;
      postObj.depSpecialServiceEndPortID = this.vesselDeploymentFrmGrp.controls.depEndPortID.value;
      postObj.depStartPortID = null;
      postObj.depEndPortID = null;
    } else {
      postObj.depSpecialServiceStartPortID = null;
      postObj.depSpecialServiceEndPortID = null;
    }
    postObj.depBeginDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depBeginDate.value,
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value
    );
    postObj.depEndDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    postObj.depPKID = postObj.depPKID ? postObj.depPKID : 0;
    // Extra Position Exists then send flag true and depPosition without X.
    if (this.validExtraPos === true) {
      postObj.depPosition = this.extraLoaderPosition;
    }
    postObj.extraPosition = this.validExtraPos === true ? true : false;
    // End of code
    const finalPostObj = postObj;
    delete finalPostObj.depBeginTime;
    delete finalPostObj.depEndTime;
    this.vesselsService.saveVesselDeploymentNewVessel(finalPostObj).subscribe((res) => {
      if (res['isOverlapDeployment'] === true) {
        const extraPos = res['extraPosition'] ? 'X' : '';
        const warnMessage =
          res['currVesselName'] +
          ' is not inserted -' +
          res['deployedVessels'][0]['deployedVesselName'] +
          ' is already deployed from ' +
          new DatePipe('en-US').transform(res['deployedVessels'][0]['deployedVslBeginDate'], 'dd-MMM-yyyy') +
          ' to ' +
          new DatePipe('en-US').transform(res['deployedVessels'][0]['deployedVslEndDate'], 'dd-MMM-yyyy') +
          ' at position ' +
          res['depPosition'] +
          extraPos +
          '.' +
          ' Do you want to insert overlap deployment? ';
        this.incorrectDeploymentDetails = warnMessage;
        this.dialogRef = this.dialog.open(this.warningMessagetDialogue, {
          width: '500px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
        });
        this.dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              this.sendVesselDeoplymentPostObject(deployment, finalPostObj);
            }
          });
      } else {
        this.sendVesselDeoplymentPostObject(deployment, finalPostObj);
      }
    });
  }

  public sendVesselDeoplymentPostObject(deployment, postObjFinal) {
    this.vesselsService.saveVesselDeploymentNew(postObjFinal).subscribe(
      (res) => {
        deployment.editDeployment = false;
        this.showError = false;
        this.showIncorrectDetailError = false;
        this.showBeginDateError = false;
        this.showBeginTimeError = false;
        this.showEndDateError = false;
        this.showEndTimeError = false;
        this.showRangeInvalid = false;
        this.showDatesInvalid = false;
        this.showLocationHidePort = false;
        this.router
          .navigateByUrl('/vessels', { skipLocationChange: true })
          .then(() => this.router.navigate(['/vessels/' + this.vslId]));
      },
      (errorResponse) => {
        this.errorKeys = Object.keys(this.vesselDeploymentFrmGrp).filter((key) =>
          Object.keys(errorResponse.error).includes(key)
        );
        this.errorValues = this.errorKeys.map((errorKey) => errorResponse.error[errorKey]);
        if (errorResponse instanceof HttpErrorResponse) {
          const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
          const data = errorResponse.error && errorResponse.error.data ? errorResponse.error.data : null;
          if (error) {
            this.showIncorrectDetailError = true;
            this.incorrectDeploymentDetails = error;
          }
        }
      }
    );
  }
  /** */
  public cancelEdit(deployment) {
    deployment.editDeployment = false;
    this.showError = false;
    this.endDateNotPresent = false;
    this.showIncorrectDetailError = false;
    this.showBeginDateError = false;
    this.showBeginTimeError = false;
    this.showEndDateError = false;
    this.showEndTimeError = false;
    this.showRangeInvalid = false;
    this.showDatesInvalid = false;
    this.showLocationHidePort = false;
    this.showScheduleDateError = false;
  }
  /** */
  public populateReasonDetails(e) {
    this.showScheduleDateError = false;
    this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(null);
    this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(null);
    const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
    this.vesselsService.getPortsByService(selectedService, this.deploymentStartDate, this.deploymentEndDate).subscribe(
      (response: any) => {
        const patternMatched = this.validateRegExPosPattern();
        let positionSelected;

        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
          positionSelected = this.extraLoaderPosition;
        } else {
          positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        this.vesselDeploymentStartPort = [];
        this.vesselDeploymentEndPort = [];
        if (positionSelected && positionSelected > 0) {
          this.isVesslDeplyPositionSelected = true;
          this.vesselDeploymentStartPort = response.portDateTime.filter(
            (o) => o.scheduleType === 0 && o.position === positionSelected
          );
          this.vesselDeploymentEndPort = response.portDateTime.filter(
            (o) => o.scheduleType === -1 && o.position === positionSelected
          );
          let startPortToPopulate: any;
          const startDateBeforePort = new Date(
            new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depBeginDate.value, 'dd-MMM-yyyy')
          );
          startPortToPopulate = response.portDateTime.filter((k) => k.position === positionSelected);
          if (startPortToPopulate.length) {
            this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
          }
          let endPortToPopulate: any;
          const endDateBeforePort = new Date(
            new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
          );
          if (endDateBeforePort) {
            endPortToPopulate = response.portDateTime.filter(
              (k) =>
                positionSelected === k.position &&
                endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
                k.scheduleType === 0
            );
            if (endPortToPopulate.length) {
              this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
                endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
              );
            }
          }
        } else {
          this.isVesslDeplyPositionSelected = false;
          this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
          this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.scheduleType === -1);
        }
      },
      (errorResponse) => {
        this.showScheduleDateError = true;
        const msg = errorResponse.error.error;
        this.scheduleDateErrorMsg = msg;
      }
    );
    if (e.position === -1) {
      const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'idle');
      this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
    }
    if (e.position === 0) {
      const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'positioning');
      this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
    }
  }
  public ignoreAndSave(deployment, isPrevious, isUpcoming) {
    this.showRangeInvalid = false;
    if (isUpcoming) {
      const endDateDeployment = dateTimeCompile(
        this.vesselDeploymentFrmGrp.controls.depEndDate.value,
        this.vesselDeploymentFrmGrp.controls.depEndTime.value
      );
      if (deployment.blackouts.length) {
        deployment.blackouts.forEach((blackout) => {
          if (endDateDeployment >= blackout.fxbStartDate && endDateDeployment <= blackout.fxbEndDate) {
            this.blackoutViolationMsg =
              'Please note the Blackout period of ' +
              new DatePipe('en-US').transform(blackout.fxbStartDate, 'dd-MMM-yy') +
              '     to     ' +
              new DatePipe('en-US').transform(blackout.fxbEndDate, 'dd-MMM-yy') +
              '. Do you wish to edit or ignore.?';
            this.showBlackoutConfirmation(deployment, isPrevious);
          } else {
            this.saveNewDeployment(deployment, isPrevious);
          }
        });
      } else {
        this.saveNewDeployment(deployment, isPrevious);
      }
    } else {
      this.saveNewDeployment(deployment, isPrevious);
    }
  }
  /** */
  public editPort() {
    this.showRangeInvalid = false;
  }
  /** */
  public startDateChng() {
    const strDate = moment(this.vesselDeploymentFrmGrp.controls.depBeginDate.value).format('YYYY-MM-DD');
    const strTime = this.vesselDeploymentFrmGrp.controls.depBeginTime.value;
    const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
    const selectedDateTime = strDate + 'T' + strTime + ':00';
    this.vesselsService.getPortsByService(selectedService, selectedDateTime, null).subscribe(
      (response: any) => {
        // Logic for Extra position containing x or X
        const patternMatched = this.validateRegExPosPattern();
        let positionSelected;
        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
          positionSelected = this.extraLoaderPosition;
        } else {
          positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        this.vesselDeploymentStartPort = [];
        if (positionSelected && positionSelected > 0) {
          this.isVesslDeplyPositionSelected = true;
          this.vesselDeploymentStartPort = response.portDateTime.filter(
            (o) => o.scheduleType === 0 && o.position === positionSelected
          );
        } else {
          this.isVesslDeplyPositionSelected = false;
          this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
        }
        if (positionSelected > 0) {
          let startPortToPopulate: any;
          const startDateBeforePort = new Date(this.vesselDeploymentFrmGrp.controls.depBeginDate.value);
          startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
          if (startPortToPopulate.length) {
            this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
          }
        }
      },
      (errorResponse) => {
        this.showScheduleDateError = true;
        const msg = errorResponse.error.error;
        this.scheduleDateErrorMsg = msg;
      }
    );
  }
  public endDateChng() {
    const endDate = moment(this.vesselDeploymentFrmGrp.controls.depEndDate.value).add(1, 'M').format('YYYY-MM-DD');
    const beginDate = moment(this.vesselDeploymentFrmGrp.controls.depBeginDate.value).format('YYYY-MM-DD');
    const endTime = this.vesselDeploymentFrmGrp.controls.depEndTime.value;
    const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
    const selectedEndDateTime = endDate + 'T' + endTime + ':00';
    this.showScheduleDateError = false;
    this.vesselsService.getPortsByService(selectedService, beginDate, selectedEndDateTime).subscribe(
      (response: any) => {
        // Logic for Extra position containing x or X
        const patternMatched = this.validateRegExPosPattern();
        let positionSelected;
        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
              ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
              : (this.extraLoaderPosition = null);
          positionSelected = this.extraLoaderPosition;
        } else {
          positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        this.vesselDeploymentEndPort = [];
        if (positionSelected && positionSelected > 0) {
          this.isVesslDeplyPositionSelected = true;
          this.vesselDeploymentEndPort = response.portDateTime.filter(
            (o) => o.scheduleType === -1 && o.position === positionSelected
          );
          if (this.vesselDeploymentEndPort.length === 0) {
            this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue('');
            this.showScheduleDateError = true;
            const msg = 'Schedules do not exist for the selected end date ';
            this.scheduleDateErrorMsg = msg;
          }
        } else {
          this.isVesslDeplyPositionSelected = false;
          this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.scheduleType === -1);
        }
        if (positionSelected > 0) {
          let endPortToPopulate: any;
          const endDateBeforePort = new Date(
            new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
          );
          endPortToPopulate = response.portDateTime.filter(
            (k) =>
              positionSelected === k.position &&
              endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
              k.scheduleType === 0
          );
          if (endPortToPopulate.length) {
            this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
              endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
            );
          }
        }
      },
      (errorResponse) => {
        this.showScheduleDateError = true;
        const msg = errorResponse.error.error;
        this.scheduleDateErrorMsg = msg;
      }
    );
  }
  public populateStartDate(e) { }
  /** */
  public populateEndDate(e) { }
  private showEditConfirmation() {
    const dialogRef = this.dialog.open(this.editPrevious);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          return;
        }
      });
  }
  private showBlackoutConfirmation(deployment, typePrevious) {
    this.dialogRef = this.dialog.open(this.editBlackout, {
      width: '500px',
      panelClass: 'fixture-overlay-container',
      disableClose: true,
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.saveNewDeployment(deployment, typePrevious);
        }
      });
  }

  private isVsDepDeleteAuthCheck() {
    return this.auth.isRolePresent(this.auth.roles, 'Deployment') || this.auth.isRolePresent(this.auth.roles, 'L3');
  }
  private displayAllocation() {
    const value = this.deployment.maerskAllocationOnServiceDto;
    if (value != null) {
      if (value.mlmtCap === 0 || value.mlTeuCap === 0 || value.totalMTCap === 0 || value.totalTeuCap === 0) {
        return false;
      } else {
        this.teuPercent = Number((value.mlTeuCap / value.totalTeuCap) * 100).toFixed(0);
        this.mlPercent = Number((value.mlmtCap / value.totalMTCap) * 100).toFixed(0);
        return true;
      }
    } else { return false; }
  }
}
