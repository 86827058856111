//

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, filter } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class MsalLoginRedirectService {

  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  public
}
