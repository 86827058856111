//

import { ModuleWithProviders, NgModule, Optional, SkipSelf, InjectionToken } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';

import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';

import { State as IAppState } from 'src/app/state/app.reducer';
import { reducers, metaReducers } from './app.reducer';

import { AppEffects } from './app.effects';
import { VesselsEffects } from './vessels/effects/vessels.effects';

/* Needed to resolve reducers with AOT (see: https://github.com/ngrx/store/issues/444) */
export const reducerToken: InjectionToken<ActionReducerMap<IAppState>> = new InjectionToken<
  ActionReducerMap<IAppState>
>('Registered Reducers');

export const reducerProvider = { provide: reducerToken, useFactory: getRootReducer };

export function getRootReducer(): ActionReducerMap<IAppState> {
  return reducers;
}

@NgModule({
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    StoreModule.forRoot(reducerToken, { metaReducers }),
    EffectsModule.forRoot([AppEffects, VesselsEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 50,
        })
      : [],
  ],
  declarations: [],
  providers: [reducerProvider],
})
export class StateModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StateModule
  ) {
    if (parentModule) {
      throw new Error('StateModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<StateModule> {
    return {
      ngModule: StateModule,
      providers: [],
    };
  }
}
