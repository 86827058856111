//
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataScenarioService } from '../../../scenarios/services/data-scenario.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';

@Component({
  selector: 'champs-scenario-overview-header',
  templateUrl: './scenario-overview-header.component.html',
  styleUrls: ['./scenario-overview-header.component.scss']
})
export class ScenarioOverviewHeaderComponent implements OnInit {
  public scenarioOverviewData: any;
  public scenarioDetailFrmGrp: UntypedFormGroup = new UntypedFormGroup({
    scName: new UntypedFormControl()
  });
  public editMode: boolean = false;
  public isScenarioUpdateAuth: boolean = true;
  public showError: boolean;
  public scnId: any;
  public id: any;
  public schId: any;
  constructor(private formBuilder: UntypedFormBuilder, private dataScenario: DataScenarioService, private auth: AuthService, private scenarioService: ScenariosService, private route: ActivatedRoute, private router: Router, ) {
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.id = this.route.snapshot.paramMap.get('id');
    this.scnId = this.route.snapshot.paramMap.get('scnId');
  }

  public ngOnInit() {
    this.isScenarioUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Scenarios', 'update');
    this.dataScenario.scenarioOverviewData.subscribe((res: any) => {
      if (res) {
        this.scenarioOverviewData = res;
        this.createScenarioFormGroup(this.scenarioOverviewData);
      } else {
        this.scenarioService.getScenarioOverviewHeader(Number(this.scnId)).subscribe((res) => {
          this.scenarioOverviewData = res;
          this.createScenarioFormGroup(this.scenarioOverviewData);
        });
      }
    });
  }
  public toggleEditMode(edit) {
    this.editMode = edit;
    if (!edit) {
      this.showError = false;
    }
    if (edit) {
      this.createScenarioFormGroup(this.scenarioOverviewData);
    }
  }
  public saveService() {
    const postData = {
      'scScenarioID': this.scenarioOverviewData.scScenarioID,
      'scName': this.scenarioDetailFrmGrp.get('scName').value,
      'scType': this.scenarioOverviewData.scType,
      'scParentScenarioId': this.scenarioOverviewData.scParentScenarioId,
      'scIsDeleted': this.scenarioOverviewData.scIsDeleted,
      'createdDate': this.scenarioOverviewData.createdDate,
      'createdBy': this.scenarioOverviewData.createdBy,
      'subScenarioDto': this.scenarioOverviewData.subScenarioDto
    };
    this.scenarioService.postCreateUpdate(postData).subscribe((res: any) => {
      if (res === 'Name already exists') {
        this.showError = true;
        this.editMode = true;
      } else {
        this.showError = false;
        this.editMode = false;
        this.scenarioOverviewData.scName = postData.scName;
      }
    });
  }

  private createScenarioFormGroup(scenarioDetails) {
    if (scenarioDetails) {
      this.scenarioDetailFrmGrp = this.formBuilder.group({
        scName: scenarioDetails.scName
      });
    }
  }
}
