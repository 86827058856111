// cargo restriction service
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class CargoRestrictionGroupService {

  constructor(private http: HttpClient, private dropdownContent: DropdownContentService) { }

  /**
   * get list of UN Numbers
   */
  public getUNNumbers(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/Dictionary/unnumber?sortOnSave=false');
  }

}
