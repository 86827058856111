//

/**
 * check if object is mergeable
 * @param val value
 */
function isMergeableObject(val) {
  const nonNullObject = val && typeof val === 'object';

  return nonNullObject
    && Object.prototype.toString.call(val) !== '[object RegExp]'
    && Object.prototype.toString.call(val) !== '[object Date]';
}

/**
 * check if target is empty
 * @param val value
 * @returns
 */
function emptyTarget(val): any {
  return Array.isArray(val) ? [] : {};
}

/**
 * clone object if necessary
 * @param value value
 * @param optionsArgument optionsArgument
 * @returns
 */
function cloneIfNecessary(value, optionsArgument): any {
  const clone = optionsArgument && optionsArgument.clone === true;
  return (clone && isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value;
}

/**
 * defaultArrayMerge function
 * @param target target
 * @param source source
 * @param optionsArgument optionsArgument
 * @returns
 */
function defaultArrayMerge(target, source, optionsArgument): any {
  const destination = target.slice();
  source.forEach(function (e, i) {
    if (typeof destination[i] === 'undefined') {
      destination[i] = cloneIfNecessary(e, optionsArgument);
    } else if (isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, optionsArgument);
    } else if (target.indexOf(e) === -1) {
      destination.push(cloneIfNecessary(e, optionsArgument));
    }
  });
  return destination;
}

/**
 * mergeObject function
 * @param target target
 * @param source source
 * @param optionsArgument optionsArgument
 */
function mergeObject(target, source, optionsArgument): any {
  const destination = {};
  if (isMergeableObject(target)) {
    Object.keys(target).forEach(function (key) {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }
  Object.keys(source).forEach(function (key) {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = deepmerge(target[key], source[key], optionsArgument);
    }
  });
  return destination;
}

/**
 * deepmerge export function
 * @param target target
 * @param source source
 * @param optArgs optArgs
 */
export function deepmerge(target, source, optArgs = null) {
  const array = Array.isArray(source);
  const options = optArgs || { arrayMerge: defaultArrayMerge };
  const arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (array) {
    return Array.isArray(target) ? arrayMerge(target, source, optArgs) : cloneIfNecessary(source, optArgs);
  } else {
    return mergeObject(target, source, optArgs);
  }
}

deepmerge.prototype.all = function deepmergeAll(array, optionsArgument) {
  if (!Array.isArray(array) || array.length < 2) {
    throw new Error('first argument should be an array with at least two elements');
  }

  // we are sure there are at least 2 values, so it is safe to have no initial value
  return array.reduce(function (prev, next) {
    return deepmerge(prev, next, optionsArgument);
  });
};
