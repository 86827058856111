/* */
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '@cham-services/alert/alert.service';
import { Location, DatePipe } from '@angular/common';
import { DeploymentService } from 'src/app/modules/deployment-dashboard/services/deployment.service';
import { Subject, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  MY_FORMATS,
  extractDateOnly,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ExcelService } from '@cham-services/download-excel/excel.service';
import { FeatureToggleService } from 'src/app/core/services/feature-toggle/feature-toggle.service';

@Component({
  selector: 'champs-dry-docking-planning-dashboard',
  templateUrl: './dry-dock-planning-dashboard.html',
  styleUrls: ['./dry-dock-planning-dashboard.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class DryDockPlanningDashboardComponent implements OnInit, OnDestroy {
  private showMoreIndex: number = 0;
  private params: any = null;
  private filteredArray: any[] = [];
  private firstCalled: boolean = false;
  private _onDestroy = new Subject<void>();
  private noticesBuffer = [];
  private bufferSize = 50;
  private dryDockList: any[] = [];
  public showMinMaxError: boolean = false;
  public rows: any[] = [];
  public mobile: boolean = false;
  public showElementsCount: number = 10;
  public reorderable: boolean = true;
  public removable: boolean = true;
  public filtersList: FilterList = null;
  public loadingCharterRequests: boolean = false;
  public nextDryDockChip: string;
  public teuRangeChipString: string;
  public services: any[] = [];
  public segments: any[] = [];
  public operators: any[] = [];
  public endRange: any[] = [];
  public vesselNames: any[] = [];
  public dryDockingType: any = [];
  /** Label of the search placeholder */
  public placeholderLabel = 'Search';
  public isNext18MonthsSet: boolean = false;
  public isNext12MonthsSet: boolean = false;
  public isNext6MonthsSet: boolean = false;
  public isNext3MonthsSet: boolean = false;
  public isPastDaysSet: boolean = false;
  public isNext1MonthsSet: boolean = false;
  public dockFrmGrp: UntypedFormGroup;
  public newArr = [];

  /** Label to be shown when no entries are found. Set to null if no message should be shown. */
  public noEntriesFoundLabel = 'Nothing found';
  /** list of elements filtered by search keyword for multi-selection */
  public filteredcategoryMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredrangeMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredendrangeMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  public paramList: ParamsList = {
    sortBy: '',
    sortDir: '',
    more: 0,
    vesselTypes: [],
    segments: [],
    planningStatus: [],
    operators: [],
    endRange: [],
  };
  public filtersCtrls = {
    dryDockDateCtrl: {
      fromMultiCtrl: new UntypedFormControl(),
      toMultiCtrl: new UntypedFormControl(),
    },
    vesselTypeCtrl: new UntypedFormControl(),
    segmentCtrl: new UntypedFormControl(),
    planningStatusCtrl: new UntypedFormControl(),
    operatorCtrl: new UntypedFormControl(),
    dockingLocationCtrl: new UntypedFormControl(),
  };

  @ViewChild(DatatableComponent, { static: false }) public table: DatatableComponent;

  constructor(
    private depDashboardService: DeploymentService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private alertService: AlertService,
    private excelService: ExcelService,
    private ft: FeatureToggleService
  ) {
    if (window.screen.width < 600) {
      this.mobile = true;
    }

    if (window.screen.width < 600) {
      this.mobile = true;
    }
    this.activatedRoute.queryParams
      .pipe(filter((x) => x !== undefined))
      .pipe(take(1))
      .subscribe((params) => {
        this.params = params;
        Object.keys(params).forEach((key) => {
          if (key === 'sortBy' || key === 'sortDir') {
            this.paramList[key] = params[key];
          }
          if (key === 'statuses') {
            this.paramList[key] = params[key].split(',').map((val) => parseInt(val, 10));
          }
          if (key === 'more') {
            this.showMoreIndex = this.showMoreIndex + parseInt(params[key] || '0', 10);
            this.paramList[key] = this.showMoreIndex;
            this.showElementsCount = 10 * (this.showMoreIndex + 1);
          } else {
            this.paramList[key] = params[key].split(',');
          }
        });
      });
    const ftEnabled = this.ft.isFtEnabled('DRY_DOCK_PLANNING_DASHBOARD');
  }
  /** */
  public ngOnInit() {
    this.newArr = [];
    this.getDryDockPlanningReport();
  }

  public getDryDockPlanningReport() {
    this.depDashboardService.getDryDockPlanningReport().subscribe((vessels: any) => {
      this.dryDockList = vessels.deploymentDryDockingDetails;
      this.dryDockList = [...this.dryDockList].sort(
        (a, b) => new Date(a.nextDryDockDate).getTime() - new Date(b.nextDryDockDate).getTime()
      );
      this.dryDockList = this.dryDockList.sort((a, b) => a.vesselname - b.vesselname);
      this.getFilters(vessels);
      this.services = vessels.services;
      this.operators = vessels.operators;
      this.segments = vessels.segments;
      this.setFiltersFromParam(this.paramList);
      this.allFiltered();
    });
  }

  public getToolTip(arrayOfWords) {
    let toolTip = '';
    for (let i = 0; i < arrayOfWords.length; i++) {
      toolTip = toolTip + ' ' + arrayOfWords[i];
    }
    return toolTip;
  }
  /*
   * @param filterControl
   */
  public filterDropdownIconUrlSetter(filterControl): string {
    if (filterControl.value && filterControl.value.length > 0) {
      return 'assets/icons/tick.svg';
    } else {
      return 'assets/icons/arrow-dropdown.svg';
    }
  }

  /** */
  public allFiltered() {
    /* chaining the filters */
    this.filteredArray = this.setNextDryDateByFilter(this.dryDockList, 'dryDockDateCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'vesselTypeCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'segmentCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'planningStatusCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'operatorCtrl');
    this.filteredArray = this.setFilter(this.filteredArray, 'dockingLocationCtrl');
    this.rows = [...this.filteredArray];
    this.setRequestFilter();
  }
  /**
   *
   * @param ctrl
   */
  public valueChanges(ctrl) {
    this.alertService.clear();
    this.allFiltered();
  }

  /**
   *
   * @param fltr
   * @param ctrl
   */
  public remove(fltr: any, ctrl: string = null): void {
    this.alertService.clear();
    /* remove all filters chip */
    if (fltr === 'all') {
      this.filtersCtrls.vesselTypeCtrl.setValue([]);
      this.filtersCtrls.segmentCtrl.setValue([]);
      this.filtersCtrls.planningStatusCtrl.setValue([]);
      this.filtersCtrls.operatorCtrl.setValue([]);
      this.filtersCtrls.dockingLocationCtrl.setValue([]);
      this.filteredArray = [];
      this.allFiltered();
      return;
    }
    if (fltr === 'dryDockDateBy') {
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(null);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(null);
      this.allFiltered();
      return;
    }

    const removeFilterIndex = this.filtersCtrls[ctrl].value ? this.filtersCtrls[ctrl].value.indexOf(fltr) : -1;

    if (removeFilterIndex >= 0) {
      this.filtersCtrls[ctrl].value.splice(removeFilterIndex, 1);
      this.filtersCtrls[ctrl].setValue(this.filtersCtrls[ctrl].value);
    }
    this.allFiltered();
  }
  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  /** */
  public fetchMore(e) {
    const len = this.noticesBuffer.length;
    const more = this.services.slice(len, this.bufferSize + len);
    this.noticesBuffer = this.noticesBuffer.concat(more);
  }
  /** */
  /** */
  public showMore() {
    this.showElementsCount = this.showElementsCount + 10;
    this.showMoreIndex = ++this.showMoreIndex;
    this.paramList.more = this.showMoreIndex;
    this.setRequestFilter();
  }

  public dryDockTypeChange(e, row) {
    this.newArr.push({
      val: e.value,
      id: row.vslPkid,
    });

    this.dryDockList.forEach((item, index) => {
      item['typeOfDock'] = Object.assign([]);
    });

    this.dryDockList.forEach((p, j) => {
      this.newArr.forEach((m, k) => {
        if (m.id === p.vslPkid) {
          p['typeOfDock'] = m.val;
        }
      });
    });
  }

  public navigatetoVslOverview(row) {
    window.open('/vessels/' + row);
  }

  public downloadDryDockingReport() {
    const modified_rows = this.rows.map((e) => {
      return {
        Segment: e.segment,
        Vessel: e.vesselName,
        'Vessel Type': e.vesseltype,
        'IMO Number': e.imoNumber,
        'Nominal TEU': e.teuNominal,
        'Vessel Flag': e.country,
        'Commercial Owner': e.commOwner,
        'Owner Brokers': e.ownerBroker,
        'Last Drydock Date': extractDateOnly(e.lastDryDockDate),
        'Next Drydock Date': extractDateOnly(e.nextDryDockDate),
        'Current Service': e.currentService,
        'Out of fleet date': extractDateOnly(e.outFleetDate),
        'Estimated Redelivery Date': extractDateOnly(e.estimatedRedelivDate),
        'Latest Redelivery Date incl. offhire': extractDateOnly(e.fixtureLatestDateIncludingOffhire),
        'Phaseout date': extractDateOnly(e.phaseoutDate),
        'Phaseout service': e.phaseoutService,
        'Phaseout port': e.phaseoutPort,
        'Docking location': e.dockingLocation,
        'Docking region': e.endRange,
        'Docking start date': extractDateOnly(e.dockingStartDate),
        'Docking end date': extractDateOnly(e.dockingEndDate),
        'Days in dock': e.daysInDock,
        'Phasein date': extractDateOnly(e.phaseinDate),
        'Phasein service': e.phaseinService,
        'Phasein port': e.phaseinPort,
        'Type of Dock': e.typeOfDock,
        'Planning Status': e.planningStatus,
      };
    });

    // set headers of excel columns
    const header = [
      'Segment',
      'Vessel',
      'Vessel Type',
      'IMO Number',
      'Nominal TEU',
      'Vessel Flag',
      'Commercial Owner',
      'Owner Brokers',
      'Last Drydock Date',
      'Next Drydock Date',
      'Current Service',
      'Out of fleet date',
      'Estimated Redelivery Date',
      'Latest Redelivery Date incl. offhire',
      'Phaseout date',
      'Phaseout service',
      'Phaseout port',
      'Docking location',
      'Docking region',
      'Docking start date',
      'Docking end date',
      'Days in dock',
      'Phasein date',
      'Phasein service',
      'Phasein port',
      'Type of Dock',
      'Planning Status',
    ];

    const width = [
      18,
      20,
      20,
      10,
      15,
      20,
      15,
      20,
      10,
      13,
      23,
      10,
      30,
      10,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
      20,
    ];

    this.excelService.generateExcel('Dry_Dock_Planning_Report', modified_rows, header, width);
  }

  private setRequestFilter() {
    this.paramList = Object.assign(this.paramList, {
      vesselTypes: this.filtersCtrls.vesselTypeCtrl.value,
      segments: this.filtersCtrls.segmentCtrl.value,
      planningStatus: this.filtersCtrls.planningStatusCtrl.value,
      operators: this.filtersCtrls.operatorCtrl.value,
      endRange: this.filtersCtrls.dockingLocationCtrl.value,
    });

    const params = Object.keys(this.paramList)
      .filter((key) => {
        if (this.paramList[key] && (this.paramList[key].length > 0 || typeof this.paramList[key] === 'number')) {
          if (key === 'more') {
            return this.paramList[key] > 0;
          }
          return true;
        }
        return false;
      })
      .reduce((res, key) => Object.assign(res, { [key]: this.paramList[key] }), {});

    this.params = Object.keys(params)
      .map((key) => key + '=' + encodeURI(params[key]))
      .join('&');
    const searchParams = this.params ? '?' + this.params : '';
    if (searchParams !== location.search) {
      this.location.replaceState(location.pathname + searchParams);
    }
  }

  /**
   *
   * @param vesselToBeFiltered
   * @param ctrl
   */
  private setFilter(vesselToBeFiltered, ctrl) {
    let key = '';
    switch (ctrl) {
      case 'segmentCtrl':
        key = 'segment';
        break;
      case 'vesselTypeCtrl':
        key = 'vesseltype';
        break;
      case 'planningStatusCtrl':
        key = 'planningStatus';
        break;
      case 'operatorCtrl':
        key = 'operatorCode';
        break;
      case 'dockingLocationCtrl':
        key = 'endRange';
        break;
      default:
        key = '';
        break;
    }

    if (this.filtersCtrls[ctrl].value && this.filtersCtrls[ctrl].value.length > 0) {
      return vesselToBeFiltered.filter((dummy) => this.filtersCtrls[ctrl].value.indexOf(dummy[key]) > -1);
    }
    return vesselToBeFiltered;
  }

  public setNextDryDockDate(str) {
    this.isNext18MonthsSet = false;
    this.isNext12MonthsSet = false;
    this.isNext6MonthsSet = false;
    this.isNext3MonthsSet = false;
    this.isNext1MonthsSet = false;
    this.isPastDaysSet = false;
    if (str === 'Past') {
      const todaysDate = new Date();
      const l7Date = null;
      const yesterdaysDate = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      this.isPastDaysSet = true;
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(l7Date);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(yesterdaysDate);
      this.allFiltered();
    }
    if (str === 'N1') {
      const todaysDate = new Date();
      this.isNext1MonthsSet = true;
      const n30Date = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      const todaysDate1 = new Date();
      const yesterdaysDate = new DatePipe('en-US').transform(
        todaysDate1.setDate(todaysDate1.getDate() + 30),
        'dd-MMM-yyyy'
      );
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(n30Date);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(yesterdaysDate);
      this.allFiltered();
    }
    if (str === 'N3') {
      const todaysDate = new Date();
      const tomorrowsDate = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      const todaysDate1 = new Date();
      const n60Date = new DatePipe('en-US').transform(todaysDate1.setDate(todaysDate1.getDate() + 90), 'dd-MMM-yyyy');
      this.isNext3MonthsSet = true;
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(tomorrowsDate);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(n60Date);
      this.allFiltered();
    }
    if (str === 'N6') {
      const todaysDate = new Date();
      const tomorrowsDate = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      const todaysDate1 = new Date();
      const n90Date = new DatePipe('en-US').transform(todaysDate1.setDate(todaysDate1.getDate() + 180), 'dd-MMM-yyyy');
      this.isNext6MonthsSet = true;
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(tomorrowsDate);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(n90Date);
      this.allFiltered();
    }
    if (str === 'N12') {
      const todaysDate = new Date();
      const tomorrowsDate = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      const todaysDate1 = new Date();
      const n120Date = new DatePipe('en-US').transform(todaysDate1.setDate(todaysDate1.getDate() + 360), 'dd-MMM-yyyy');
      this.isNext12MonthsSet = true;
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(tomorrowsDate);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(n120Date);
      this.allFiltered();
    }
    if (str === 'N18') {
      const todaysDate = new Date();
      const tomorrowsDate = new DatePipe('en-US').transform(todaysDate.setDate(todaysDate.getDate()), 'dd-MMM-yyyy');
      const todaysDate1 = new Date();
      const n120Date = new DatePipe('en-US').transform(todaysDate1.setDate(todaysDate1.getDate() + 540), 'dd-MMM-yyyy');
      this.isNext18MonthsSet = true;
      this.filtersCtrls.dryDockDateCtrl.fromMultiCtrl.setValue(tomorrowsDate);
      this.filtersCtrls.dryDockDateCtrl.toMultiCtrl.setValue(n120Date);
      this.allFiltered();
    }
  }
  private setNextDryDateByFilter(vesselToBeFiltered, ctrl) {
    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);
    const fromDateValue =
      this.filtersCtrls[ctrl].fromMultiCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].fromMultiCtrl.value, 'dd-MMM-yyyy'))
        : null;

    const toDateValue =
      this.filtersCtrls[ctrl].toMultiCtrl.value !== null
        ? new Date(new DatePipe('en-US').transform(this.filtersCtrls[ctrl].toMultiCtrl.value, 'dd-MMM-yyyy'))
        : null;

    if (fromDateValue !== null && toDateValue !== null) {
      this.nextDryDockChip =
        new DatePipe('en-US').transform(fromDateValue, 'dd-MMM-yyyy') +
        ' - ' +
        new DatePipe('en-US').transform(toDateValue, 'dd-MMM-yyyy');
      if (this.isNext18MonthsSet) {
        this.nextDryDockChip = '18 months';
      }
      if (this.isNext12MonthsSet) {
        this.nextDryDockChip = '12 months';
      }
      if (this.isNext6MonthsSet) {
        this.nextDryDockChip = '6 months';
      }
      if (this.isNext3MonthsSet) {
        this.nextDryDockChip = '3 months';
      }
      if (this.isNext1MonthsSet) {
        this.nextDryDockChip = '1 month';
      }
      if (this.isPastDaysSet) {
        this.nextDryDockChip = 'Past';
      }

      return vesselToBeFiltered.filter(
        (vessels) =>
          new Date(new DatePipe('en-US').transform(vessels.nextDryDockDate, 'dd-MMM-yyyy')) >= fromDateValue &&
          new Date(new DatePipe('en-US').transform(vessels.nextDryDockDate, 'dd-MMM-yyyy')) <= toDateValue
      );
    } else if (fromDateValue === null && toDateValue !== null) {
      this.nextDryDockChip = '<= ' + new DatePipe('en-US').transform(toDateValue, 'dd-MMM-yyyy');
      return vesselToBeFiltered.filter(
        (vessels) =>
          new Date(new DatePipe('en-US').transform(vessels.nextDryDockDate, 'dd-MMM-yyyy')) <= toDateValue
      );
    } else if (toDateValue === null && fromDateValue !== null) {
      this.nextDryDockChip = '>= ' + new DatePipe('en-US').transform(fromDateValue, 'dd-MMM-yyyy');
      return vesselToBeFiltered.filter(
        (vessels) =>
          new Date(new DatePipe('en-US').transform(vessels.nextDryDockDate, 'dd-MMM-yyyy')) >= fromDateValue
      );
    } else {
      this.nextDryDockChip = '';
      return vesselToBeFiltered;
    }
  }
  /**
   *
   * @param vessels
   */
  private getFilters(vessels) {
    if (!this.filtersList) {
      this.filtersList = {
        vesselType: vessels.vesselTypes,
        segments: vessels.segments,
        planningStatus: vessels.planningStatus,
        operators: vessels.operators,
        endRange: vessels.endRange,
      };
    }
  }
  /**
   *
   * @param paramList
   */
  private setFiltersFromParam(paramList: ParamsList) {
    if (!this.firstCalled) {
      this.filtersCtrls.vesselTypeCtrl.setValue(paramList.vesselTypes || null);
      this.filtersCtrls.segmentCtrl.setValue(paramList.segments || null);
      this.filtersCtrls.dockingLocationCtrl.setValue(paramList.endRange || null);
      this.filtersCtrls.operatorCtrl.setValue(paramList.operators);
      this.filtersCtrls.planningStatusCtrl.setValue(paramList.planningStatus);
    }
    this.firstCalled = true;
  }
}

interface ParamsList {
  nextDryDockDate?: Array<string>;
  vesselTypes: Array<string>;
  segments?: Array<string>;
  planningStatus?: Array<string>;
  sortBy?: string;
  sortDir?: string;
  more?: any;
  operators?: Array<string>;
  endRange?: Array<string>;
}
interface FilterList {
  nextDryDockDate?: Array<string>;
  vesselType?: Array<string>;
  segments?: Array<string>;
  planningStatus?: Array<string>;
  operators?: Array<string>;
  endRange?: Array<string>;
}
