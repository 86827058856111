/** authentication guard service */
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
// import { AdalService } from 'adal-angular4';
import { AuthService } from '../auth/auth.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@cham-environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private msal: MsalService, private auth: AuthService, private route: Router) { }
  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const module = next.data ? next.data['module'] : '';

    /** getting roles */
    // let roles = this.adal.userInfo.profile.roles;

    let roles = this.msal.instance.getAllAccounts()[0]?.idTokenClaims?.roles;
    console.log('User role:', roles);
    if (environment.byPassAuthentication === 'true') {
      console.log('Authentication Bypassed');
    }
    // ['ReadOnly', ]
    // roles = ['ReadOnly'];
    /** checking if user is AUTHENTICATED */
    // if (this.adal.userInfo.authenticated || this.auth.BYPASS_AUTHENTICATION) {

    if (this.msal.instance.getAllAccounts().length > 0 || this.auth.BYPASS_AUTHENTICATION) {
      /** user is AUTHENTICATED */

      localStorage.removeItem('redirectUrl');
      if (!module) {
        /** no module set for current route */
        // console.log('no module set for current route', 'moduleName', module);
        return true;
      }
      /** checking if user is AUTHORISED */
      if (this.auth.isAuthorised(roles, module, 'view')) {
        /** user is AUTHORISED */
        // console.log('this user is authorised', module);
        return true;
      } else {
        /** user is UNAUTHORISED */
        this.route.navigateByUrl('/unauthorised');
      }
    } else {
      /** user is NOT AUTHENTICATED */
      localStorage.setItem('redirectUrl', state.url);
      // this.adal.login();
      // this.msal.loginRedirect();
      return true;
    }
  }
}
