//
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
import { DataServiceService } from '../../../service-repository/services/data-service.service';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DataScenarioService } from '../../../scenarios/services/data-scenario.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'champs-schedule-dating',
  templateUrl: './schedule-dating.component.html',
  styleUrls: ['./schedule-dating.component.scss'],
})
export class ScheduleDatingComponent implements OnInit {
  @ViewChild('addPortDialog', { static: false }) public addPortDialog: any;
  @ViewChild('editPortDialog', { static: false }) public editPortDialog: any;
  @Input() public isDateValidating: any;
  @Input() public scheduleDatingDto: any;
  @Input() public serviceRepo: any;
  @Output() public importEmit: EventEmitter<any> = new EventEmitter();
  public id: any;
  public scheduleDating: any;
  public popupHeaderText: string;
  public bunkerLst: any;
  public schId: any;
  public schedulePortLst: any;
  public form: UntypedFormGroup;
  public editPortCtrl: UntypedFormControl = new UntypedFormControl();
  public dialogRef: MatDialogRef<any>;
  public selectedPorts: any[] = [];
  public navigationSubscription: any;
  public isImportedSuccess: any;
  public isScheduleDatingUpdateAuth: boolean = false;
  public isScheduleDatingDeleteAuth: boolean = false;
  public scnId: any;
  public show;
  public scheduleComment: any;
  public savedComment: any;
  public serviceCode: any;
  public schDetails: any;
  public serviceID: any;
  public serviceCodeVal: any;
  constructor(
    private serviceRepoService: ServiceRepositoryService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private titleService: Title,
    private router: Router,
    private formBulider: UntypedFormBuilder,
    private dataService: DataServiceService,
    private dataScScenario: DataScenarioService,
    private auth: AuthService,
    private scenarioService: ScenariosService
  ) {
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.id = this.route.parent.snapshot.paramMap.get('id');
    this.scnId = this.route.parent.snapshot.paramMap.get('scnId');
    this.route.parent.snapshot.paramMap.get('serviceRepo') === 'false'
      ? (this.serviceRepo = false)
      : (this.serviceRepo = true);
  }

  public ngOnInit() {
    this.show = false;
    this.isScheduleDatingUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.isScheduleDatingDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'delete');
    if (this.scheduleDatingDto) {
      this.scheduleDating = this.scheduleDatingDto;
    } else {
      this.getScheduleDating();
    }
    const serv = this.serviceRepo
      ? this.dataService.isImportedSuccess.subscribe((data) => {
          this.isImportedSuccess = data;
        })
      : this.dataScScenario.isImportedSuccess.subscribe((data) => {
          this.isImportedSuccess = data;
        });
  }
  public showPortLstView(calledAction) {
    this.popupHeaderText = calledAction;
    if (calledAction === 'Phase in port' && this.form) {
      this.form.patchValue({
        portCtrl: this.scheduleDating.defaultPhaseInPortId,
      });
    } else if (this.form) {
      this.form.patchValue({
        portCtrl: this.scheduleDating.defaultPhaseOutPortId,
      });
    }
    this.dialogRef = this.dialog.open(this.addPortDialog, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
      autoFocus: false,
    });
  }
  public importSch() {
    this.importEmit.emit({
      scheduleDatingDto: this.scheduleDatingDto,
    });
  }
  public showEditPortLstView() {
    if (this.bunkerLst) {
      this.editPortCtrl.setValue(this.bunkerLst.portId);
    }
    this.dialogRef = this.dialog.open(this.editPortDialog, {
      width: '300px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
      autoFocus: false,
    });
  }
  public onPortChange(event) {
    if (Array.isArray(event)) {
      this.selectedPorts = event.map((item) => item.portId);
    }
  }
  public editPort() {
    const postBunkerLst = [];
    this.form.get('ports').value.map((selected, i) => {
      this.scheduleDating.lstSchedulePorts[i].isBunkerPort = selected;
      postBunkerLst.push(this.scheduleDating.lstSchedulePorts[i]);
    });
    this.dialogRef.close();
    const serv = this.serviceRepo
      ? this.serviceRepoService.deleteBunkerPortsService(postBunkerLst)
      : this.scenarioService.deleteBunkerPortsService(postBunkerLst);
    serv.subscribe((res) => {
      this.reload();
    });
  }
  public addPort() {
    if (this.popupHeaderText === 'Phase in port') {
      this.scheduleDating.defaultPhaseInPortId = this.form.get('portCtrl').value;
      this.dialogRef.close();
      const serv = this.serviceRepo
        ? this.serviceRepoService.postInOutPortsService(this.scheduleDating)
        : this.scenarioService.postInOutPortsService(this.scheduleDating);
      serv.subscribe((res) => {
        this.getScheduleDating();
      });
    } else if (this.popupHeaderText === 'Phase out port') {
      this.scheduleDating.defaultPhaseOutPortId = this.form.get('portCtrl').value;
      this.dialogRef.close();
      const serv = this.serviceRepo
        ? this.serviceRepoService.postInOutPortsService(this.scheduleDating)
        : this.scenarioService.postInOutPortsService(this.scheduleDating);
      serv.subscribe((res) => {
        this.getScheduleDating();
      });
    }
  }
  public reload() {
    this.serviceRepo
      ? this.router
          .navigateByUrl('/services/' + this.id, { skipLocationChange: true })
          .then(() =>
            this.router.navigate(['/services/' + this.id + '/schedule/' + this.schId + '/' + this.serviceRepo])
          )
      : this.router
          .navigateByUrl('/services/' + this.id, { skipLocationChange: true })
          .then(() =>
            this.router.navigate([
              '/scenarios/' + this.id + '/schedule/' + this.schId + '/' + this.scnId + '/' + this.serviceRepo,
            ])
          );
  }
  public deleteBunkerPort(bunker) {
    bunker.isBunkerPort = false;
    const serv = this.serviceRepo
      ? this.serviceRepoService.deleteBunkerPortsService([bunker])
      : this.scenarioService.deleteBunkerPortsService([bunker]);
    serv.subscribe((res) => {
      this.reload();
    });
  }
  public resetBunkerPort() {
    this.setFormGroup();
  }
  public cancel() {
    if (this.isDateValidating) {
      this.serviceRepo
        ? this.dataService.updatedCreatedDateSelection('')
        : this.dataScScenario.updatedCreatedDateSelection('');
      this.dataScScenario.updateStatusSwitchSchOverview(false);
      this.serviceRepo
        ? this.router.navigateByUrl(
            '/services/' + this.id + '/schedule/' + this.scheduleDatingDto.proformaId + '/' + this.serviceRepo,
            { replaceUrl: true }
          )
        : this.router.navigateByUrl(
            '/scenarios/' +
              this.id +
              '/schedule/' +
              this.scheduleDatingDto.proformaId +
              '/' +
              this.scnId +
              '/' +
              this.serviceRepo,
            { replaceUrl: true }
          );
    }
  }
  public cancelComment() {
    this.show = false;
    this.scheduleComment = this.savedComment;
  }
  public saveComment() {
    this.show = false;
    const postData = {
      genericRequestID1: this.schId,
      genericRequestID2: 0,
      genericString1: this.scheduleComment,
      genericString2: 'string',
      genericFlag: true,
    };
    this.serviceRepoService.updateScheduleComment(postData).subscribe((res: any) => {
      if (!res) {
        this.cancelComment();
      } else {
        this.savedComment = this.scheduleComment;
      }
    });
  }
  private getScheduleDating() {
    const serv = this.serviceRepo
      ? this.serviceRepoService.getScheduleDating(this.schId)
      : this.scenarioService.getScheduleDating(this.schId, this.scnId);
    serv.subscribe((res: any) => {
      this.scheduleDating = res;
      this.savedComment = res.scheduleComments;
      this.scheduleComment = res.scheduleComments;
      this.bunkerLst = this.scheduleDating.lstBunkerPorts;
      this.schedulePortLst = this.scheduleDating.lstSchedulePorts;
      this.setFormGroup();
      const title = this.titleService.getTitle();
      if (title.includes('null')) {
        this.titleService.setTitle(
          'CHAMPS' +
            ' ' +
            '(S)' +
            ' ' +
            localStorage.getItem('serviceCodeFromHeader') +
            '- ' +
            'ID' +
            localStorage.getItem('serviceProformaIDFromHeader')
        );
      } else {
        if(title.includes('undefined')){
          this.getServiceDetails();
        }
        else if (title.includes('(') && title.lastIndexOf('(') === 7) {
          return;
        } else {
          this.titleService.setTitle(
            this.titleService.getTitle() + (' ' + '(S)' + ' ' + res.serviceCode + '- ' + 'ID' + this.id)
          );
        }
      }
    });
  }
  public getServiceDetails(){
    this.serviceRepoService.getScheduleDetail(this.schId).subscribe((res: any) => {
      this.schDetails = res;
      this.serviceCodeVal = this.schDetails.serviceCode;
      this.serviceID = this.schDetails.proformaID;
      return this.titleService.setTitle(
        'CHAMPS' +
          ' ' +
          '(S)' +
          ' ' +
          this.serviceCodeVal +
          '- ' +
          'ID' +
          this.serviceID
      );
    });
  }
  private setFormGroup() {
    this.form = this.formBulider.group({
      ports: this.buildPortsLst(),
      portCtrl: '',
    });
  }
  private buildPortsLst() {
    const arr = this.schedulePortLst.map((port) => {
      return this.formBulider.control(port.isBunkerPort);
    });
    return this.formBulider.array(arr);
  }
}
