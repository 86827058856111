//

import { Action } from '@ngrx/store';

/** @docsNotRequired */
export enum VesselCreateActionTypes {
  ResetCreateVessel = '[VesselNew] Reset Create Vessel',
  ResetCreateVesselObj = '[VesselNew] Reset Create Vessel Object',
  PutCreateVessel = '[VesselNew] Set New Vessel',
  PutCreateType = '[VesselNew] Set Create Vessel',
  ResetCreateType = '[VesselNew] Reset Create Vessel',
  PutGeneralInfo = '[VesselNew] Put General Info',
  PutVesselSpecification = '[VesselNew] Put Vessel Specs',
  PutMachineryParticulars = '[VesselNew] Put Machinery Perticulars',
  PutSpeedConsumption = '[VesselNew] Put Speed and Consumption',
  PutCommunication = '[VesselNew] Put Communication',
  PutCargoCap = '[VesselNew] Put Cargo Capacity',
  PutPlansDoc = '[VesselNew] Put Plans, Docs & Certs',
  PutAdditionalInfo = '[VesselNew] Put Additional Information'
}

export class ResetCreateVessel implements Action {
  public readonly type = VesselCreateActionTypes.ResetCreateVessel;
}

export class ResetCreateType implements Action {
  public readonly type = VesselCreateActionTypes.ResetCreateType;
}

export class PutCreateType implements Action {
  public readonly type = VesselCreateActionTypes.PutCreateType;
  constructor(
    public payload: any
  ) {}
}

export class ResetCreateVesselObj implements Action {
  public readonly type = VesselCreateActionTypes.ResetCreateVesselObj;
}

export class PutCreateVessel implements Action {
  public readonly type = VesselCreateActionTypes.PutCreateVessel;
  constructor(
    public payload: any
  ) {}
}

export class PutGeneralInfo implements Action {
  public readonly type = VesselCreateActionTypes.PutGeneralInfo;
  constructor(
    public payload: any
  ) {}
}

export class PutVesselSpecification implements Action {
  public readonly type = VesselCreateActionTypes.PutVesselSpecification;
  constructor(
    public payload: any
  ) {}
}

export class PutMachineryParticulars implements Action {
  public readonly type = VesselCreateActionTypes.PutMachineryParticulars;
  constructor(
    public payload: any
  ) {}
}

export class PutSpeedConsumption implements Action {
  public readonly type = VesselCreateActionTypes.PutSpeedConsumption;
  constructor(
    public payload: any
  ) {}
}

export class PutCommunication implements Action {
  public readonly type = VesselCreateActionTypes.PutCommunication;
  constructor(
    public payload: any
  ) {}
}

export class PutCargoCap implements Action {
  public readonly type = VesselCreateActionTypes.PutCargoCap;
  constructor(
    public payload: any
  ) {}
}

export class PutPlansDoc implements Action {
  public readonly type = VesselCreateActionTypes.PutPlansDoc;
  constructor(
    public payload: any
  ) {}
}

export class PutAdditionalInfo implements Action {
  public readonly type = VesselCreateActionTypes.PutAdditionalInfo;
  constructor(
    public payload: any
  ) {}
}

export type VesselCreateActions = PutCreateVessel | ResetCreateVessel | ResetCreateVesselObj | PutGeneralInfo
  | PutVesselSpecification | PutMachineryParticulars | PutSpeedConsumption | PutCommunication | PutCargoCap
  | PutPlansDoc | PutAdditionalInfo | PutCreateType | ResetCreateType;
