/** service for authentication and authorisation functions using adal */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** flag to bypass authentication (also remember to disable the msal interceptor in app module & MsalGuard in routing module) */
  public BYPASS_AUTHENTICATION: boolean = environment.byPassAuthentication === 'true';
  /** this contains roles of current user */
  public roles: any;
  /** this contains the auth token */
  public authToken: any;
  /** this contains the profile of the current user (for name use userProfile.name) */
  public userProfile: any;
  /** Here the authorisation is defined based on roles */
  /** The format is {
   * module : {
   *  role: list of actions
   *        }
   * }
   */
  public authPolicy: any =
    {
      'Vessels': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view'],
        'NoRole': []
      },
      'Fixtures': {
        'ReadOnly': ['view'],
        'Finance': ['view', 'update'],
        'Chartering': ['view', 'update', 'delete'],
        'PostFixture': ['view', 'update', 'delete'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Expenses': {
        'ReadOnly': ['view'],
        'Finance': ['view', 'update', 'delete'],
        'Chartering': ['view', 'update', 'delete'],
        'PostFixture': ['view', 'update', 'delete'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Hire Statements': {
        'ReadOnly': ['view'],
        'Finance': ['view', 'update', 'delete'],
        'Chartering': ['view', 'update', 'delete'],
        'PostFixture': ['view', 'update', 'delete'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Services': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view'],
        'PostFixture': ['view'],
        'Deployment': ['view', 'update', 'delete'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': ['view'],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Scenarios': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': [],
        'PostFixture': [],
        'Deployment': ['view', 'update', 'delete'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Charter Requests': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update', 'delete'],
        'PostFixture': ['view'],
        'Deployment': ['view', 'update', 'delete'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Dictionary': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Owners': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Market Rates': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Charter Candidates': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Average Bunker': {
        'ReadOnly': ['view'],
        'Finance': ['view', 'update', 'delete'],
        'Chartering': ['view'],
        'PostFixture': ['view'],
        'Deployment': ['view'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Reporting': {
        'ReadOnly': ['view'],
        'Finance': ['view', 'update'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': ['view', 'update'],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Administrator': {
        'ReadOnly': [],
        'Finance': ['view'],
        'Chartering': [],
        'PostFixture': [],
        'Deployment': [],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': [],
        'NoRole': []
      },
      'Change Log': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view', 'update', 'delete'],
        'NoRole': []
      },
      'Standalone Report': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view'],
        'PostFixture': ['view'],
        'Deployment': ['view'],
        'L3': ['view'],
        'Reporting': ['view'],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view'],
        'NoRole': ['view', 'update', 'delete']
      },
      'Feature Toggle': {
        'ReadOnly': [],
        'Finance': [],
        'Chartering': [],
        'PostFixture': [],
        'Deployment': [],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': [],
        'NoRole': []
      },
      'A3View': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view'],
        'NoRole': []
      },
      'ServiceView': {
        'ReadOnly': ['view'],
        'Finance': ['view'],
        'Chartering': ['view', 'update'],
        'PostFixture': ['view', 'update'],
        'Deployment': ['view', 'update'],
        'L3': ['view', 'update', 'delete'],
        'Reporting': [],
        'SEA': [],
        'MSK': [],
        'SGL': [],
        'MCC': [],
        'FinanceL3': ['view'],
        'NoRole': []
      }
    };

  public currentUrl: string = document.location.origin;
  public redirectUrl: string;

  constructor(private http: HttpClient) {
  }

  public readAppSettings() {
    return this.http.get(this.currentUrl + '/appSettings.php');
  }

  public autoBlockUser() {
    return this.http.post(environment.baseUrl + '/api/User/AddOrUpdateUserLoginDetails', {});
  }

  public checkPreviousAccess() {
    return this.http.get(environment.baseUrl + '/api/User/GetCurrentUserFlagWhoHasNotSignInFor90Days');
  }

  public isAuthorised(roles?, module?, action?) {
    if (this.BYPASS_AUTHENTICATION) {
      return true;
    } else {
      if (roles) {
        const permittedRoles = roles.filter(role => this.authPolicy[module][role].includes(action));
        if (permittedRoles.length) {
          return true;
        } else {
          return false;
        }
      } else {
        // tslint:disable-next-line:no-console
        console.log('Roles not coming from azure.');
      }
    }
  }

  public checkRolePresent(roles, module?, permission?) {
    if (roles === 'NoRole' && module === 'Standalone report' && permission === 'view') {
      return true;
    } else {
      return false;
    }

  }

  public isRolePresent(roles, role) {
    return roles.includes(role);
  }

  public isReportingRoleOnly(roles, role) {
    if (roles) {
      if (role === 'Reporting' && roles.length === 1 && roles[0] === 'Reporting') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
