// rediliver vessel
import { Component, OnInit, Input, Output, AfterViewChecked, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';

@Component({
  selector: 'champs-redeliver-vessel',
  templateUrl: './redeliver-vessel.component.html',
  styleUrls: ['./redeliver-vessel.component.scss'],
})
export class RedeliverVesselComponent implements OnInit, AfterViewChecked {
  @Input() public vesselId: any;
  @Input() public position: any;
  @Input() public fixtureId: any;
  @Input() public proformaId: any;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @ViewChild('portConfirmation', { static: false }) public portConfirmation: any;
  public vesselDetail: any;
  public fixtureDetail: any;
  public vesselName: any;
  public arrivalDate: any;
  public redeliverVessel: UntypedFormGroup;
  public redeliverVesselForm: UntypedFormGroup;
  public portId: any;
  public vesselDetails: any[] = [];
  public portList: any[] = [];
  public filteredPorts = [];
  public vesselBuffer = [];
  public serviceLst: any;
  public bufferSize: any = 50;
  public loading: boolean = false;
  public portNameSelect: UntypedFormControl = new UntypedFormControl();
  public portDateMismatchError: boolean = false;
  public message: any;
  public blackoutErrorEst: boolean = false;
  public latestInclOffhireErrorEst: boolean = false;
  public containPorts: boolean = false;
  public showError: boolean = false;
  constructor(
    private serviceRepoService: ServiceRepositoryService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.getPortData();
    this.getRedeliverPort();
    this.getFixtureDetail();
  }
  public ngAfterViewChecked() {
    this.redeliverVessel.valueChanges.subscribe((values) => {
      const port = this.vesselDetails.find((x) => x.portID === values.portID);
      if (port) {
        if (!port.matchwithFixture) {
          this.portDateMismatchError = true;
        } else {
          const arrivalDate = new Date(values.date);
          arrivalDate.setHours(new Date(port.arrivalDate).getHours());
          arrivalDate.setMinutes(new Date(port.arrivalDate).getMinutes());
          arrivalDate.setSeconds(new Date(port.arrivalDate).getSeconds());
          if (new Date(port.arrivalDate).getTime() !== arrivalDate.getTime()) {
            this.portDateMismatchError = true;
            return;
          } else {
            this.portDateMismatchError = false;
          }
        }
      } else {
        this.portDateMismatchError = true;
      }
      return;
    });
  }
  public getFixtureDetail() {
    this.serviceRepoService.getFixtureOverview(this.fixtureId).subscribe((res: any) => {
      if (res) {
        this.fixtureDetail = res;
      }
    });
  }
  public blackoutValidate() {
    const estRedelivDate = new Date(this.redeliverVessel.value.date).setHours(0, 0, 0, 0);
    this.blackoutErrorEst = false;
    for (const item of this.fixtureDetail.blackouts) {
      const blackoutStartDate = new Date(item.fxbStartDate).setHours(0, 0, 0, 0);
      const blackoutEndDate = new Date(item.fxbEndDate).setHours(0, 0, 0, 0);
      if (blackoutStartDate <= estRedelivDate && blackoutEndDate >= estRedelivDate) {
        this.blackoutErrorEst = true;
        this.message =
          'Estimated date should not fall within blackout period (' +
          new DatePipe('en-US').transform(blackoutStartDate, 'dd-MMM-yy') +
          ' - ' +
          new DatePipe('en-US').transform(blackoutEndDate, 'dd-MMM-yy') +
          ')';
        break;
      }
    }
    return this.blackoutErrorEst;
  }
  /** validation for estimated and actual date to not be after latest incl offhire */
  public latestInclOffhireValidate() {
    const estRedelivDate = new Date(this.redeliverVessel.value.date).setHours(0, 0, 0, 0);
    let latestInclOffhireRedelivDate: any;
    this.latestInclOffhireErrorEst = false;
    if (this.fixtureDetail.fixLatestInclOffhire) {
      latestInclOffhireRedelivDate = new Date(this.fixtureDetail.fixLatestInclOffhire).getTime();
      if (estRedelivDate > latestInclOffhireRedelivDate) {
        this.latestInclOffhireErrorEst = true;
        this.message =
          'Estimated date should not fall after Latest incl. offhire (' +
          new DatePipe('en-US').transform(this.fixtureDetail.fixLatestInclOffhire, 'dd-MMM-yy') +
          ')';
        return true;
      }
    }
  }
  public getRedeliverPort() {
    this.serviceRepoService
      .getRedeliverPortsAndDateService(this.vesselId, this.position, this.proformaId, this.fixtureId)
      .subscribe((res: any) => {
        if (res) {
          this.vesselDetail = res;
          this.vesselDetails = res.scheduledPortList;
          this.portList = res.fixturePortList[0].portsforFixtureList;
          this.vesselBuffer = this.vesselDetails.slice(0, this.bufferSize);
          this.checkMatchingports();
        }
      });
  }
  public checkMatchingports() {
    this.containPorts = false;
    for (const port of this.vesselDetails) {
      const fixturePort = this.portList.find((x) => x.portID === port.portID);
      if (fixturePort !== undefined && fixturePort !== null) {
        this.containPorts = true;
        break;
      }
    }
  }
  public getPortDetails(port) {
    this.redeliverVessel.patchValue({
      portID: port.portID,
      portNameList: port.portName,
      date: port.arrivalDate,
    });

    const portData = this.portList.find((x) => x.portID === port.portID);
    if (portData === undefined || portData === null) {
      this.portList = [...this.portList, { portID: port.portID, portName: port.portName }];
    }
    this.portId = port.portID;
  }
  public saveRequest() {
    this.showError = false;
    if (this.redeliverVessel.invalid) {
      this.showError = true;
      return;
    }
    if (this.latestInclOffhireValidate()) {
      this.dialog.open(this.portConfirmation);
      return;
    }
    if (this.blackoutValidate()) {
      this.dialog.open(this.portConfirmation);
      return;
    }
    const postData = {
      fixtureID: this.fixtureId,
      newPortID: this.redeliverVessel.get('portID').value,
      newRedeliveryDt: this.redeliverVessel.get('date').value,
      updateCheck: false,
    };
    this.serviceRepoService.updateRedeliverPort(postData).subscribe((res: any) => {
      if (res && res.errorMsg !== '') {
        this.message = res.errorMsg;
        const dialogRef = this.dialog.open(this.portConfirmation);
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveRedeliverData();
          }
        });
      } else {
        this.saveRedeliverData();
      }
    });
  }

  public saveRedeliverData() {
    const postData = {
      fixtureID: this.fixtureId,
      newPortID: this.redeliverVessel.get('portID').value,
      newRedeliveryDt: this.redeliverVessel.get('date').value,
      updateCheck: true,
    };
    this.serviceRepoService.updateRedeliverPort(postData).subscribe((res: any) => {
      if (res) {
        this.close.emit({});
      }
    });
  }
  public fetchMore(e) {
    const len = this.vesselBuffer.length;
    const more = this.vesselDetails.slice(len, this.bufferSize + len);
    this.loading = true;
    this.vesselBuffer = this.vesselBuffer.concat(more);
  }
  public checkPortDate() {}
  public selectedVesselPort(portsToBeFiltered) {
    const port = this.vesselDetails.find((x) => x.portID === portsToBeFiltered.portID);
    if (port) {
      if (new Date(port.arrivalDate).getTime() !== new Date(this.redeliverVessel.value.date).getTime()) {
        this.portDateMismatchError = true;
        return;
      } else {
        this.portDateMismatchError = false;
        return;
      }
    } else {
      this.portDateMismatchError = true;
      return;
    }
  }
  private getPortData() {
    this.redeliverVessel = this.formBuilder.group({
      portID: null,
      portNameList: null,
      portArrivalDate: null,
      date: null,
    });
  }
}
