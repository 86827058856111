//
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceRepositoryService } from '../../../../modules/service-repository/services/service-repository.service';
@Component({
  selector: 'champs-overview-current-rotation',
  templateUrl: './overview-current-rotation.component.html',
  styleUrls: ['./overview-current-rotation.component.scss']
})
export class OverviewCurrentRotationComponent implements OnInit, OnChanges {
  public id: any;
  // public currentRotation: any;
  @Input() public currentRotation: any;
  @Input() public headerText: any;
  @Input() public serviceRepo: any;
  public port_status = [];
  public port_status_initial = [];
  public showButton;
  public proformaId: any;
  constructor(
    private route: ActivatedRoute, public serviceRepoService: ServiceRepositoryService
  ) {
    this.id = this.route.parent.snapshot.paramMap.get('id');
    this.proformaId = this.route.snapshot.paramMap.get('schId');
  }

  public ngOnInit() {
    // this.getCurrentRotation();
  }
  public ngOnChanges() {
    if (this.currentRotation != null) {
      this.currentRotation.rotationDto.forEach((element, idx) => {
        this.port_status.push({
          'proformaID': element.proformaPortId,
          'isPhasingPort': element.pfmIsPhasingPort,
          'isBunkerPort': element.pfmIsBunkerPort,
          'isTurnPort': element.pfmIsTurnPort,
          'isBottleNeckPort': element.pfmIsBottleNeckPort,
          'IsTurnBackHaulPort':element.pfmIsTrurnBackHaulPort,
          'IsBottleNeckBackHaulPort':element.pfmIsBottleNeckBackHaulPort
        });
        this.port_status_initial.push({
          'proformaID': element.proformaPortId,
          'isPhasingPort': element.pfmIsPhasingPort,
          'isBunkerPort': element.pfmIsBunkerPort,
          'isTurnPort': element.pfmIsTurnPort,
          'isBottleNeckPort': element.pfmIsBottleNeckPort,
          'IsTurnBackHaulPort':element.pfmIsTrurnBackHaulPort,
          'IsBottleNeckBackHaulPort':element.pfmIsBottleNeckBackHaulPort
        });
      });
    }

  }

  public changePort(idx, portKey) {
    this.port_status.forEach((elm, i) => {
      if (elm.proformaID == this.port_status[idx].proformaID) {
        this.port_status[i][portKey] = !this.port_status[i][portKey];
      }
    });
    for (let i = 0; i < this.port_status.length; i++) {
      if (JSON.stringify(this.port_status[i]) === JSON.stringify(this.port_status_initial[i])) {
        this.showButton = false;
      } else {
        this.showButton = true;
        break;
      }
    }
  }
  public reset() {
    this.showButton = false;
    this.port_status = JSON.parse(JSON.stringify(this.port_status_initial));
  }

  public save() {
    this.showButton = false;
    const postData = {
      'proformaID': this.proformaId,
      'proformaPortTypeFields': this.port_status,
    };
    console.log(this.port_status);
    this.serviceRepoService.postServiceProformaPortType(postData).subscribe((res: any) => {
      if (res) {
        this.port_status_initial = JSON.parse(JSON.stringify(this.port_status));
      } else {
        this.reset();
      }
    });
  }
}
