import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'champs-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent implements OnInit {
  public isNoRole: any;
  constructor(public auth: AuthService) { }

  public ngOnInit() {
    this.isNoRole = !this.auth.roles ? true : false;
  }

}
