//

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Optional,
  Inject,
  ComponentRef,
  NgZone,
  Output,
  EventEmitter,
} from '@angular/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { Subscription, merge, Subject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayRef, OverlayConfig, PositionStrategy, Overlay } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { filter, take } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UP_ARROW, ESCAPE } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';

import { CustomDatetimepickerContentComponent } from '../champs-custom-datetimepicker-content/custom-datetimepicker-content.component';
import { CustomDatetimepickerInput } from '../custom-datetimepicker-input.directive';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'champs-custom-datetimepicker',
  template: ` <ng-template #datetimePicker></ng-template> `,
  // styleUrls: ['./custom-datetimepicker.component.scss']
})
export class CustomDatetimepickerComponent<D> implements OnInit {
  /** Subscription to value changes in the associated input element. */
  private _inputSubscription = Subscription.EMPTY;

  /** A portal containing the calendar for this datepicker. */
  private _calendarPortal: ComponentPortal<CustomDatetimepickerContentComponent<D>>;

  /** Reference to the component instantiated in popup mode. */
  private _popupComponentRef: ComponentRef<CustomDatetimepickerContentComponent<D>> | null;

  /** The element that was focused before the datepicker was opened. */
  private _focusedElementBeforeOpen: HTMLElement | null = null;

  @ViewChild('datetimePicker', { static: false, read: ViewContainerRef })
  public datetimePickerViewContainerRef: ViewContainerRef;

  /** Emits when the datepicker is disabled. */
  public readonly _disabledChange = new Subject<boolean>();

  /** Emits new selected date when selected date changes. */
  public readonly _selectedChanged = new Subject<D>();

  @Input('inline')
  set showInline(value: boolean) {
    if (value) {
      this.createDatetimePicker();
    }
    this._inline = value;
  }
  get showInline() {
    return this._inline;
  }
  // tslint:disable-next-line:member-ordering
  private _inline: boolean = false;

  /** A reference to the overlay when the calendar is opened as a popup. */
  public _popupRef: OverlayRef;

  /** The input element this datepicker is associated with. */
  public _datetimepickerInput: CustomDatetimepickerInput<D>;

  /** Whether the datepicker pop-up should be disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled === undefined && this._datetimepickerInput
      ? this._datetimepickerInput.disabled
      : !!this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this._disabledChange.next(newValue);
    }
  }
  // tslint:disable-next-line:member-ordering
  private _disabled: boolean;

  // tslint:disable-next-line:no-output-rename
  @Output('opened') public openedStream: EventEmitter<void> = new EventEmitter<void>();

  /** Emits when the datepicker has been closed. */
  // tslint:disable-next-line:no-output-rename
  @Output('closed') public closedStream: EventEmitter<void> = new EventEmitter<void>();

  /** Whether the calendar is open. */
  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: boolean) {
    value ? this.open() : this.close();
  }
  // tslint:disable-next-line:member-ordering
  private _opened = false;

  constructor(
    private _factoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DATEPICKER_SCROLL_STRATEGY) private _scrollStrategy,
    @Optional() private _dir: Directionality,
    private _ngZone: NgZone,
    private _overlay: Overlay,
    @Optional() @Inject(DOCUMENT) private _document: any,
    private _viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit() {}

  public open() {
    if (this._inline) {
      return;
    }

    if (this._opened || this.disabled) {
      return;
    }

    if (!this._datetimepickerInput) {
      throw Error('Attempted to open an MatDatepicker with no associated input.');
    }

    if (this._document) {
      this._focusedElementBeforeOpen = this._document.activeElement;
    }

    this._openAsPopup();

    this._opened = true;

    this.openedStream.emit();
  }

  public close() {
    if (this._inline) {
      return;
    }

    if (!this._opened) {
      return;
    }

    if (this._popupRef && this._popupRef.hasAttached()) {
      this._popupRef.detach();
    }

    const completeClose = () => {
      // The `_opened` could've been reset already if
      // we got two events in quick succession.
      if (this._opened) {
        this._opened = false;
        this.closedStream.emit();
        this._focusedElementBeforeOpen = null;
      }
    };

    if (this._focusedElementBeforeOpen && typeof this._focusedElementBeforeOpen.focus === 'function') {
      // Because IE moves focus asynchronously, we can't count on it being restored before we've
      // marked the datepicker as closed. If the event fires out of sequence and the element that
      // we're refocusing opens the datepicker on focus, the user could be stuck with not being
      // able to close the calendar at all. We work around it by making the logic, that marks
      // the datepicker as closed, async as well.
      this._focusedElementBeforeOpen.focus();
      setTimeout(completeClose);
    } else {
      completeClose();
    }
  }

  public dateValueChange(e) {
    const evtDate = new Date(e);
    const dt = new Date((this._datetimepickerInput.value as any) || new Date());
    dt.setDate(evtDate.getDate());
    dt.setFullYear(evtDate.getFullYear());
    dt.setMonth(evtDate.getMonth());
    if (!this._datetimepickerInput.value) {
      dt.setHours(0);
      dt.setMinutes(0);
    }
    this._selectedChanged.next(new DatePipe('en-US').transform(dt, 'dd-MMM-yy HH:mm') as any);
  }

  public hourValueChange(e) {
    const dt = new Date((this._datetimepickerInput.value as any) || new Date());
    dt.setHours(parseInt(e, 10));
    this._selectedChanged.next(new DatePipe('en-US').transform(dt, 'dd-MMM-yy HH:mm') as any);
  }

  public minuteValueChange(e) {
    const dt = new Date((this._datetimepickerInput.value as any) || new Date());
    dt.setMinutes(parseInt(e, 10));
    this._selectedChanged.next(new DatePipe('en-US').transform(dt, 'dd-MMM-yy HH:mm') as any);
  }

  /**
   * Register an input with this datepicker.
   * @param input The datepicker input to register with this datepicker.
   */
  public _registerInput(input: CustomDatetimepickerInput<D>): void {
    if (this._datetimepickerInput) {
      throw Error('A MatDatepicker can only be associated with a single input.');
    }
    this._datetimepickerInput = input;
    this._inputSubscription = this._datetimepickerInput._valueChange.subscribe((value: D | null) =>
      this.valueChanged(value)
    );
  }

  public valueChanged(value) {}

  private createDatetimePicker() {
    const factory = this._factoryResolver.resolveComponentFactory(CustomDatetimepickerContentComponent);
    const component = factory.create(this.datetimePickerViewContainerRef.parentInjector);
    const instance = component.instance;
    instance.datetimePicker = this;
    this.datetimePickerViewContainerRef.insert(component.hostView);
  }

  /** Open the calendar as a popup. */
  private _openAsPopup(): void {
    if (!this._calendarPortal) {
      this._calendarPortal = new ComponentPortal<CustomDatetimepickerContentComponent<D>>(
        CustomDatetimepickerContentComponent,
        this._viewContainerRef
      );
    }

    if (!this._popupRef) {
      this._createPopup();
    }

    if (!this._popupRef.hasAttached()) {
      this._popupComponentRef = this._popupRef.attach(this._calendarPortal);
      this._popupComponentRef.instance.datetimePicker = this;

      // Update the position once the calendar has rendered.
      this._ngZone.onStable
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this._popupRef.updatePosition();
        });
    }
  }

  /** Create the popup. */
  private _createPopup(): void {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this._createPopupPositionStrategy(),
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      direction: this._dir,
      scrollStrategy: this._scrollStrategy(),
      panelClass: 'mat-datepicker-popup',
    });

    this._popupRef = this._overlay.create(overlayConfig);
    this._popupRef.overlayElement.setAttribute('role', 'dialog');
    merge(
      this._popupRef.backdropClick(),
      this._popupRef.detachments(),
      this._popupRef.keydownEvents().pipe(
        filter((event) => {
          // Closing on alt + up is only valid when there's an input associated with the datepicker.
          return event.keyCode === ESCAPE || (this._datetimepickerInput && event.altKey && event.keyCode === UP_ARROW);
        })
      )
    ).subscribe(() => this.close());
  }

  /** Create the popup PositionStrategy. */
  private _createPopupPositionStrategy(): PositionStrategy {
    return this._overlay
      .position()
      .flexibleConnectedTo(this._datetimepickerInput.getConnectedOverlayOrigin())
      .withTransformOriginOn('.mat-datepickertimepicker-content')
      .withFlexibleDimensions(true)
      .withViewportMargin(8)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
  }
}
