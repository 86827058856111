//

import { Action } from '@ngrx/store';

export const LOAD_ITEMS = '[VesselList] Load Items';
export const LOAD_ITEMS_SUCCESS = '[VesselList] Load Items Success';
export const LOAD_ITEMS_FAIL = '[VesselList] Load Items Fail';

export class Load implements Action {
  public readonly type = LOAD_ITEMS;
}

export class LoadSuccess implements Action {
  public readonly type = LOAD_ITEMS_SUCCESS;
  constructor(
    public payload: any
  ) {}
}

export class LoadFail implements Action {
  public readonly type = LOAD_ITEMS_FAIL;
  constructor(
    public payload: any
  ) {}
}

export type VesselListActions = Load | LoadSuccess | LoadFail;
