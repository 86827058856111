export const feedbackId = {
    devSUSFeedBackId: '1234804388416897024',
    sitSUSFeedBackId: '1234804445274882048',
    prodSUSFeedBackId: '1234804493698121728',
    devNPSFeedBackId: 'e4cd86c6-d8e3-49bd-98ba-0e7fe19b38a4',
    devNPSFeedBackDisplayId: '1237744112379097088',
    sitNPSFeedBackId: '03adbcce-2f4b-40a1-bc08-e435217c657b',
    sitNPSFeedBackDisplayId: '1237744179899002880',
    prodNPSFeedBackId: 'bf7e61cd-a627-4aaa-80bd-7b99daaf9eae',
    prodNPSFeedBackDisplayId: '1237744241144229888',
    devNPSQsn: {
        qsn1: 'ac691da0-629a-42c9-b6f1-3f4306155aee',
        qsn2: 'e9590f2f-ce52-4774-be7b-1f114baf806d',
    },
    sitNPSQsn: {
        qsn1: 'fad7ce33-577e-4a2e-b5c2-67f5095a0b9d',
        qsn2: '05658ac1-b301-4712-895a-f8fa03a06f18',
    },
    prodNPSQsn: {
        qsn1: '10c342ac-7d71-433d-a55a-deae3f99cf7b',
        qsn2: 'abeae589-9db3-439b-bba6-e34a01645fc2',
    }
}