//
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'champs-upload-tco',
  templateUrl: './upload-tco.component.html',
  styleUrls: ['./upload-tco.component.scss'],
})
export class UploadTcoComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public proformaId: any;
  @ViewChild('updateTCO', { static: false }) public updateTCOForm: any;
  @Input() public serviceObj: any;
  public uploadTcoFrmGrp: UntypedFormGroup;
  public showError: boolean;
  public uploadClicked: boolean;
  public response: any;
  public showUpload: boolean;
  public showErrorMsg: boolean;
  public errorMsg: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceRepo: ServiceRepositoryService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.CreateFrmGrp();
  }
  public closeCreateForm() {
    this.showError = false;
    this.uploadClicked = false;
    this.close.emit({
      type: 'close',
    });
  }
  public fileChanged(e) {
    const formData: FormData = new FormData();
    formData.append('file', e);
    this.serviceRepo.postUploadTcoFromExcel(formData, this.uploadTcoFrmGrp.get('proformaId').value).subscribe(
      (response) => {
        this.showUpload = true;
        this.errorMsg = '';
        this.showErrorMsg = false;
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        this.response = response;
      },
      (errorResponse) => {
        this.showUpload = false;
        if (errorResponse instanceof HttpErrorResponse) {
          const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
          if (error) {
            this.errorMsg = error;
            this.showErrorMsg = true;
          }
        }
      }
    );
  }
  public uploadTco() {
    if (this.uploadTcoFrmGrp.invalid) {
      this.showError = true;
    } else {
      if (this.response) {
        this.close.emit({
          type: 'save',
          response: this.response,
          uploadedProfoId: this.uploadTcoFrmGrp.get('proformaId').value,
        });
      }
    }
  }
  private CreateFrmGrp() {
    this.uploadTcoFrmGrp = this.formBuilder.group({
      proformaId: null,
    });
  }
}
