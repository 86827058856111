//

import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';

import { AlertNotificationComponent } from './components/alert-notification/alert-notification.component';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule
  ],
  declarations: [
    AlertNotificationComponent,
  ],
  exports: [
    AlertNotificationComponent,
  ],
  providers: []
})
export class AlertNotificationModule { }
