// Angular
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, HostListener, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

// Rxjs
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

// Services
import { VesselsService } from '../../core/services/vessels/vessels.service';
import { VesselDropdownsService } from '../../core/services/vessel-dropdowns/vessel-dropdowns.service';
import { ServiceRepositoryService } from '../../modules/service-repository/services/service-repository.service';
import { AlertService } from '../../core/services/alert/alert.service';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { SessionTimeoutService } from 'src/app/core/services/common/session-timeout.service';

// Components
import { VesselRedesignDeploymentsComponent } from '../../components/vessel-redesign-deployments/vessel-redesign-deployments.component';
import { VesselRequirementsComponent } from '../../modules/common-planner/component/vessel-requirements/vessel-requirements.component';

// Constants
import { VesselPresetColors } from 'src/app/utils/vessel-color-pallet-preset-data';
import { VesselDeploymentsLinesComponent } from '@cham-components/vessel-deployments-lines/vessel-deployments-lines.component';

@Component({
  selector: 'champs-vesel-new-dummy',
  templateUrl: './vesel-new-dummy.component.html',
  styleUrls: ['./vesel-new-dummy.component.scss'],
})
export class VeselNewDummyComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('warningDialog', { static: false }) public warningDialog: any;
  @ViewChild('convertToRequestDialog', { static: false }) public convertToRequestDialog: any;
  public dialogRef: MatDialogRef<any>;
  public showFinalConfirmation: boolean = false;
  public deleteFinalMsg: string;
  public deleteFirstMsg: string;
  public dummyNameWatermark: any = 'Service code-segment-position-start port';
  public editVesselRequirements: boolean = true;
  public fromCreateDummyPage: boolean = true;
  public vslId: any;
  public dummyDetails: any;
  public vesselReq: any;
  public operators$: Observable<any>;
  public segmentList: any;
  public segment: any;
  public vesselReqFrmGrp: UntypedFormGroup;
  public editMode: boolean;
  public convertToRequest: boolean = false;
  public vslOperatorCodeId: any;
  public setOperator: boolean = false;
  public updateLinkedDummy: boolean = false;
  public convertToRequestChecked: boolean = false;
  public teuDiffError: boolean = false;
  public selectedColor: string = '#B2B2B2';
  public segmentInName: string;
  public serviceDetailsForName: string;
  public positionDetailForName: string;
  public startPortForName: string;
  public routeData: any;
  public dummyControlChanges: boolean;
  public isSaveCancelBtnEnabled: boolean;
  public isVsDepUpdateAuth: boolean = false;
  public loading: boolean;
  public isSpecialPosition: boolean = false;
  public dummyOverlapDetails: any;
  public isCancelShowPreviousClick: boolean = false;

  // @ViewChild(VesselRedesignDeploymentsComponent, { static: false }) public childRedesignComponent;
  @ViewChild(VesselDeploymentsLinesComponent, { static: false }) public childRedesignComponent;
  @ViewChild(VesselRequirementsComponent, { static: false }) public childVslReqComponent: VesselRequirementsComponent;
  @ViewChild('opselect', { static: false }) public opselect;

  public setServiceforProforma: any;
  public countOfMethodVisit = 0;
  public isProfoBoxChecked: boolean = true;
  public showInvalidDataError: boolean;
  public invalidControlsForPage = [];
  public vbID: any;
  public vesselName: any;
  public vesselImo: any;
  public showWarningOverlappingDummy: boolean = false;
  public overlappingExistDummy: boolean = false;
  public overlappingMsg: any;
  public showPortRangeError: boolean = false;
  public portRangeErrorMsg: any;
  public incorrectDeploymentDetails: any;
  public showIncorrectDetailErrorDummy: boolean = false;
  public trade_restrictions: any;
  public warnings: string[] = [];
  public overlappingErrorMsg: any = [];

  public vsldeploymentControlIndex: any;
  public requirementFrmOriginalValue: any;
  public colorDisabled: boolean = false;

  public serviceFormGrp: UntypedFormGroup;
  public serviceObj: any;
  public openConvertToDummyBlock: boolean = false;
  public requestBlockDetails: any;
  public requestRequirementDetails: any;
  public operatorFormGrp: UntypedFormGroup;
  public dontShowDeleteButton: string = 'false';
  public showSegmentNotActive: boolean = false;
  public isDummyAuth: boolean = true;
  public fieldError: boolean = false;
  public vslIsProformaFromService: boolean = true;
  public showProfCheckBbox: boolean = true;
  public lstOfProformasForFirstService: any;
  public prhPKIDForReq: any;
  public finalProformaId: any;
  public proformaIdWhenNotchecked: any;
  public vesselPresetColors: Array<string> = VesselPresetColors;
  public checkDuplicateDummyFlag: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private titleService: Title,
    private vslService: VesselsService,
    private vesselDropdowns: VesselDropdownsService,
    private serviceRepoService: ServiceRepositoryService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private auth: AuthService,
    private router: Router,
    private sessionTimeoutService: SessionTimeoutService
  ) {
    this.vslId = this.route.snapshot.paramMap.get('id');
    this.routeData = this.route.snapshot.data;
  }

  @HostListener('window:mousemove') public refreshUserState() {
    clearTimeout(this.sessionTimeoutService.userActivity);
    if (!this.isSaveCancelBtnEnabled) {
      this.sessionTimeoutService.setTimeout();
    }
  }

  public ngOnInit() {
    this.loading = true;

    if (this.auth.BYPASS_AUTHENTICATION) {
      this.isDummyAuth = false;
    } else {
      this.isDummyAuth =
        this.auth.userProfile.roles.includes('L3') || this.auth.userProfile.roles.includes('Deployment');
    }

    this.isVsDepUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.isCancelShowPreviousClick = true;

    this.getDummyRequirements();
    this.getSegmentList();
    this.vesselRequirementsFormGroup(null);
    this.toggleEditMode(true);
    this.dummyControlChanges = false;

    this.vslService.saveButtonState.subscribe((res) => {
      this.showWarningOverlappingDummy = this.showWarningOverlappingDummy ? res : false;
    });
  }

  public ngAfterViewChecked() {
    this.childVslReqComponent.vesselReqFrmGrp.valueChanges.subscribe((value) => {
      const emitObj = {
        isInvalid: this.childVslReqComponent.vesselReqFrmGrp.invalid,
        isModified: true,
        isPristine: this.childVslReqComponent.vesselReqFrmGrp.pristine,
        isDirty: this.childVslReqComponent.vesselReqFrmGrp.dirty,
      };
      this.recievedControlValueChangeEvents(emitObj);
    });
    /* checking if deployment details are entered */
    // this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
  }

  public getDummyRequirements() {
    this.isSaveCancelBtnEnabled = true;
    if (this.vslId) {
      if (this.routeData.title === 'Extend Dummy') {
        this.colorDisabled = true;
        this.vesselImo = localStorage.getItem('this.vesselDetail.vslIMONumber');
        this.vesselName = localStorage.getItem('this.vesselDetail.vslVesselName');
        this.vslOperatorCodeId = parseInt(localStorage.getItem('this.vslOperatorCodeID'), 10);
        this.dontShowDeleteButton = localStorage.getItem('dontShowDelete');
        localStorage.removeItem('dontShowDelete');
        this.showProfCheckBbox = false;
        this.vslIsProformaFromService = false;
      }
      this.vslService
        .getDummyDetails(this.vslId)
        .pipe(
          take(1),
          distinctUntilChanged(),

          takeUntil(this.unsubscribe$)
        )
        .subscribe(
          (response: any) => {
            this.dummyDetails = response.dummyVesselDetails;
            this.vesselReq = response.dummyVesselDetails;
            if (this.routeData.title === 'Extend Dummy') {
              this.vbID = parseInt(localStorage.getItem('this.vslOperatorCodeID'), 10);
              this.vslOperatorCodeId = parseInt(localStorage.getItem('this.vslOperatorCodeID'), 10);
              this.vesselImo = localStorage.getItem('this.vesselDetail.vslIMONumber');
            } else {
              this.vbID = this.vesselReq.vslOperatorCodeId;
              this.vslOperatorCodeId = this.vesselReq.vslOperatorCodeId;
              this.vesselImo = this.dummyDetails.vmMONumber;
            }
            const dummyNameOnLoad: any = this.dummyDetails.vmVesselName.split('-');
            this.serviceDetailsForName = dummyNameOnLoad[0];
            this.segmentInName = dummyNameOnLoad[1];
            this.positionDetailForName = dummyNameOnLoad[2];
            this.startPortForName = dummyNameOnLoad[3];
            // to check/unchek the proform checkbox (disable for extend dummy)
            this.vslIsProformaFromService =
              this.dummyDetails.vslIsProformaVesselRequirement &&
              !this.dummyDetails.extendedDummyFixtureID &&
              this.routeData.title !== 'Extend Dummy'
                ? true
                : false;
            this.isProfoBoxChecked = this.vslIsProformaFromService;
            // to disbale enable the proforma checkbox
            this.showProfCheckBbox =
              this.routeData.fromVesselDetail ||
              this.dummyDetails.extendedDummyFixtureID ||
              this.routeData.title === 'Extend Dummy'
                ? false
                : true;
            if (this.dummyDetails.dummyIsRequest) {
              this.invokeConvertToRequestCheckWhenRequestView(true);
            }
            // this.selectedColor = this.dummyDetails.vslVesselColor ? '#b2b2b2' : '#edf0f2';
            // To display the color of vessel on page load
            if (this.routeData.title === 'Extend Dummy') {
              this.selectedColor = '#b2b2b2';
            } else {
              this.selectedColor = this.dummyDetails.vslVesselColor ? this.dummyDetails.vslVesselColor : '#b2b2b2';
            }
            this.titleService.setTitle(
              this.titleService.getTitle() + (' ' + '(D)' + ' ' + this.dummyDetails.vmVesselName)
            );
          },
          (error) => {
            // Vessel Not found or if any error Navigate to Vessels page
            this.router.navigate(['/vessels']);
          }
        );
    } else {
      this.loading = false;
    }

    this.getOperatorsList();

    this.loading = false;
  }

  public getOperatorsList() {
    this.operators$ = this.vesselDropdowns.getVesselOperators();

    if (this.vslId) {
      if (this.routeData.title === 'Extend Dummy') {
        this.vbID = this.vslOperatorCodeId;
      } else {
        this.vbID = this.dummyDetails ? this.dummyDetails.vslOperatorCodeId : null;
      }
    }
    this.operatorFormGrp = this.formBuilder.group({
      vslOperatorCodeIdFC: [{ value: this.vbID || 0, disabled: this.noOperationPermitted }],
    });
  }

  public ngAfterViewInit() {
    if (this.vslId && this.dummyDetails) {
      this.selectOperator({ vbID: this.dummyDetails.vslOperatorCodeId });
    }
  }

  public getSegmentList() {
    this.serviceRepoService.getSegmentList().subscribe((segList: Response) => {
      this.segmentList = segList;
    });
  }
  public calculatePercentage(unitVal, totalVal) {
    return Math.round((unitVal / totalVal) * 100);
  }
  public setTeuPercentage() {
    const unitVal = this.vesselReqFrmGrp.get('mlTeuCap').value;
    const totalVal = +this.vesselReqFrmGrp.get('totalTeuCap').value;
    this.vesselReqFrmGrp.patchValue({
      mlTeuPer: totalVal && unitVal ? Math.round((unitVal / totalVal) * 100) : '',
    });
    this.isTeuValid(totalVal, unitVal);
  }
  public isTeuValid(val1, val2) {
    this.teuDiffError = val1 < val2;
  }
  public toggleEditMode(edit) {
    this.editMode = edit;
    if (!edit) {
      // this.noCharterError = false;
    }
    if (edit) {
      this.vesselRequirementsFormGroup(this.vesselReq);
    }
  }

  public convertToRequestCheck(e) {
    this.convertToRequest = e.target.checked;
    this.convertToRequestChecked = e.target.checked;
    if (this.convertToRequestChecked) {
      this.createFormGroup();
    }
  }

  public invokeConvertToRequestCheckWhenRequestView(isReq) {
    this.convertToRequestChecked = isReq;
    this.createFormGroup();
  }
  public selectOperator(e) {
    this.vslOperatorCodeId = e.vbID;
    this.setOperator = false;
    // this.isSaveCancelBtnEnabled = false;
    /* checking if deployment details are entered */
    this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
  }

  public saveDummyDetails() {
    // Delete first deployment dummy
    this.showIncorrectDetailErrorDummy = false;

    if (this.childRedesignComponent.vesselDeploymentLines.value.length) {
      if (!this.convertToRequestChecked) {
        this.clearMessages();
        this.showInvalidDataError = false;
        this.invalidControlsForPage = [];
        // debugger;
        if (!this.vslOperatorCodeId) {
          this.setOperator = true;
          return;
        }

        this.childRedesignComponent.saveAllDeploymentLines();
        this.childVslReqComponent.updateVesselReq();
        this.vesselReqFrmGrp = this.childVslReqComponent.vesselReqFrmGrp;
        if (this.childVslReqComponent.vesselReqFrmGrp.invalid) {
          return;
        }

        let invalidControls: any = [];
        const isSingleControlCheck = this.ValidateFirstDeplymentLine();
        invalidControls = this.getRedesignInvalidControls();
        if (!isSingleControlCheck && invalidControls && invalidControls.length > 0) {
          this.showInvalidDataError = true;
          this.showWarningOverlappingDummy = false;

          this.invalidControlsForPage = invalidControls;
          return;
        }
      }

      this.dummyNameWatermark =
        this.serviceDetailsForName +
        '-' +
        this.segmentInName +
        '-' +
        this.positionDetailForName +
        '-' +
        this.startPortForName;

      const postObj = this.convertToRequestChecked ? this.prepareRequestPayloadObj() : this.prepareDummyPayloadObj();

      const isSegmentActive = this.findSegmentPresentInList(postObj);

      if (postObj) {
        let deleteCount = 0;
        let updateCount = 0;
        let existingDepCount = 0;
        let newDepCount = 0;
        if (this.childRedesignComponent && this.childRedesignComponent.finalPayloadFromRedesign) {
          this.childRedesignComponent.finalPayloadFromRedesign.lstVesselDeplymentDto.forEach((element) => {
            if (element.depPKID > 0) {
              existingDepCount++;
              if (element.depIsDeleted) {
                deleteCount++;
              }
              if (element.depIsUpdated) {
                updateCount++;
              }
              // bug - 343064
              if (this.convertToRequestChecked && !this.isSaveCancelBtnEnabled) {
                element.depIsUpdated = true;
              }
            }
            if (element.depPKID === 0) {
              newDepCount++;
            }
            // Check is Past date
            const today = new Date();
            const depEndDate = new Date(element.depEndDate);
            if (depEndDate.getTime() < today.getTime()) {
              element[`isPastdepLine`] = true;
            } else {
              element[`isPastdepLine`] = false;
            }
          });
        }
        let checkOverlap = true;
        if (newDepCount === 0 && existingDepCount === deleteCount) {
          checkOverlap = false;
        }
        if (updateCount === 0 && deleteCount > 0) {
          if (this.childRedesignComponent.saveAfterDeleteStatus) {
            checkOverlap = true;
          } else {
            checkOverlap = false;
          }
        }
        // Send Only those object which are active dep lines no need to validate past dep lines
        const payloadObject_activeLine = { ...this.childRedesignComponent.finalPayloadFromRedesign };
        payloadObject_activeLine.lstVesselDeplymentDto = this.childRedesignComponent.finalPayloadFromRedesign.lstVesselDeplymentDto.filter(
          (item) => {
            return item.isPastdepLine === false;
          }
        );
        // if (!this.showWarningOverlappingDummy  && checkOverlap && !this.convertToRequestChecked) {
        if (!this.showWarningOverlappingDummy && !this.showIncorrectDetailErrorDummy && checkOverlap) {
          this.incorrectDeploymentDetails = '';
          this.vslService.saveVesselDeploymentNewVessel(payloadObject_activeLine).subscribe(
            (res) => {
              if (res && res[0] && res[0].isOverlapDeployment) {
                this.overlappingExistDummy = true;
                this.showWarningOverlappingDummy = true;
                this.showIncorrectDetailErrorDummy = false;
                const extraPos = res[0].extraPosition ? true : false;

                const warnMessage = {
                  vessel_status_id: null,
                  vslPKID: res[0].deployedVessels[0].deployedVesselID,
                  vessel_name: res[0].deployedVessels[0].deployedVesselName,
                  position: res[0].depPosition,
                  overlapping_start_date: new DatePipe('en-US').transform(
                    res[0].deployedVessels[0].deployedVslBeginDate,
                    'dd-MMM-yyyy'
                  ),
                  overlapping_end_date: new DatePipe('en-US').transform(
                    res[0].deployedVessels[0].deployedVslEndDate,
                    'dd-MMM-yyyy'
                  ),
                  extra_position: extraPos,
                };
                const warnMessageArray = [];
                warnMessageArray.push(warnMessage);

                this.overlappingMsg = warnMessageArray;
              } else {
                this.checkDuplicateDummyAndCreateUpdate(postObj);
              }
            }, // Warning overlapping implementation
            (errorResponse) => {
              if (errorResponse.error && errorResponse.error.code === 'VesselDeploymentOverlapWarning') {
                this.dummyOverlapDetails = errorResponse;
                if (errorResponse.error.data.overlap_warnings.length || errorResponse.error.data.warnings.length) {
                  this.showWarningOverlappingDummy = true;
                }
              } else {
                this.showWarningOverlappingDummy = false;
                this.showIncorrectDetailErrorDummy = true;
                const msg =
                  errorResponse && errorResponse.error ? errorResponse.error.data : 'Error while saving dummy vessel.';
                this.overlappingErrorMsg = msg;
              }
            }
          );
        } else {
          this.checkDuplicateDummyAndCreateUpdate(postObj);
        }
      }
    }
  }

  public checkDuplicateDummyAndCreateUpdate(postObj) {
    // To check for duplicate dummy name for first deployment line
    this.showWarningOverlappingDummy = false;
    this.showIncorrectDetailErrorDummy = false;
    if (
      postObj.deployments &&
      postObj.deployments.lstVesselDeplymentDto[0] &&
      postObj.deployments.lstVesselDeplymentDto[0].depIsUpdated &&
      postObj.deployments.lstVesselDeplymentDto[0].depPKID === 0
    ) {
      if (this.dummyNameWatermark !== '') {
        const postData = {
          genericRequestID1: postObj.deployments.lstVesselDeplymentDto[0].depPKID,
          genericRequestID2: 0,
          genericString1: this.dummyNameWatermark,
          genericString2: '',
          genericFlag: true,
          genericDateTime1: this.childRedesignComponent.vesselDeploymentLines.value[0].depBeginDate,
        };

        this.vslService.checkDuplicateDummy(postData).subscribe(
          (res) => {
            if (res) {
              this.checkDuplicateDummyFlag = false;
            }
          },
          (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse) {
              const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
              if (error) {
                this.alertService.error(error);
                this.checkDuplicateDummyFlag = true;
              }
            }
          },
          () => {
            // if no error
            this.postDummyDetailslocal(postObj);
          }
        );
      }
    } else {
      // if no first deployment line
      this.postDummyDetailslocal(postObj);
    }
  }

  public redirectToVslOverview(vessel_status_id, vslPKID) {
    if (vessel_status_id === 3 || vessel_status_id === 2) {
      // Request OR Dummy
      window.open('/vessels/dummy/overview/' + vslPKID, '_blank');
    } else if (vessel_status_id === null || vessel_status_id === '') {
      // vessel
      window.open('/vessels/' + vslPKID, '_blank');
    }
  }

  public postDummyDetailslocal(postObj) {
    const payloadObject_activeLine = { ...postObj };
    payloadObject_activeLine.deployments.lstVesselDeplymentDto = payloadObject_activeLine.deployments.lstVesselDeplymentDto.filter(
      (item) => {
        return item.depIsUpdated === true || item.depIsDeleted === true;
      }
    );

    this.vslService.postDummyDetails(payloadObject_activeLine).subscribe(
      (res: any) => {
        this.dontShowDeleteButton = 'false';
        this.countOfMethodVisit = 0;

        if (!this.vslId) {
          this.afterSaveRedirectToDummyOverviewPage(res.vessel_id);
          this.alertService.success('Created successfully', true);
          // this.router.navigate(["/vessels/dummy/overview/" + res.vessel_id]);
        } else {
          // this.alertService.success('Updated successfully', true);
          // this.router.navigate(["/vessels/dummy/overview/" + res.vessel_id]);
          this.afterSaveRedirectToDummyOverviewPage(res.vessel_id);
          this.alertService.success('Updated successfully', true);
        }
      },
      (errorResponse) => {
        if (errorResponse instanceof HttpErrorResponse) {
          const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
          const errorName =
            errorResponse.error && errorResponse.error.vmVesselName ? errorResponse.error.vmVesselName[0] : null;
          if (error) {
            this.alertService.error(error);
          }
          if (errorName) {
            this.alertService.error('Invalid dummy name.');
          }
        }
      }
    );
  }
  public findSegmentPresentInList(postObj) {
    const index = this.segmentList.findIndex((e) => e.vesselTeuID === postObj.vslVesselTeu);
    return index > -1 ? true : false;
  }
  public setDummyNameBasedOnDeploymentControlChanges() {
    return `{{serviceDetailsForName ? serviceDetailsForName : 'Service code'}}-{{segmentInName ? segmentInName : 'segment'}}-{{positionDetailForName ? positionDetailForName : 'position'}}-{{startPortForName ? startPortForName : 'start port'}}`;
  }

  public prepareDummyPayloadObj() {
    if (this.vslId) {
      // this.dummyNameWatermark = (this.routeData.fromVesselDetail || (this.dummyDetails && this.dummyDetails.extendedDummyFixtureID)) ? this.vesselName : this.dummyNameWatermark;

      this.dummyNameWatermark = this.routeData.fromVesselDetail
        ? this.vesselName
        : this.dummyDetails && this.dummyDetails.extendedDummyFixtureID
        ? this.dummyDetails.vmVesselName
        : this.dummyNameWatermark;
    } else {
      this.dummyNameWatermark =
        this.serviceDetailsForName +
        '-' +
        this.segmentInName.replace(/[^\d]/g, '') +
        '-' +
        this.positionDetailForName +
        '-' +
        this.startPortForName;
    }

    const fixPKID = localStorage.getItem('fixPkidVal');
    localStorage.removeItem('fixPKID');
    const postObj = {
      vslPKID: this.routeData.title !== 'Extend Dummy' && this.vslId ? Number(this.vslId) : 0,
      vmVesselName: this.routeData.fromVesselDetail ? this.vesselName : this.dummyNameWatermark,
      vmMONumber: this.routeData.title !== 'Extend Dummy' ? this.vesselImo : this.dummyDetails.vmMONumber,

      vslOperatorCodeId: this.vslOperatorCodeId,
      vslVesselTeu: this.vesselReqFrmGrp.get('vesselTeuId').value
        ? this.vesselReqFrmGrp.get('vesselTeuId').value
        : this.findSegmentId(this.vesselReqFrmGrp.get('segment').value),
      vslTeuNominal: this.vesselReqFrmGrp.get('nomTeuCapacity').value,
      vslTonCenCap: this.vesselReqFrmGrp.get('tonCenCap').value,
      vslGT: this.vesselReqFrmGrp.get('grossTonnage').value,
      vslDWT: this.vesselReqFrmGrp.get('deadWeight').value,
      vslMaxSpeed: this.vesselReqFrmGrp.get('maxSpeed').value,
      vslMinSpeed: this.vesselReqFrmGrp.get('minSpeed').value,
      vslTeuReefer: 0,
      vslPlugsInHold: Number(this.vesselReqFrmGrp.get('plugs').value),
      vslVesselColor: this.routeData.title !== 'Extend Dummy' ? this.selectedColor : '#b2b2b2',
      vslCharterRequestID: null,
      dummyIsRequest: this.convertToRequestChecked,
      deployments: { ...this.childRedesignComponent.finalPayloadFromRedesign },
      requestDetails: null,

      fixPKID: this.routeData.fromVesselDetail ? fixPKID : null,
      vslIsProformaVesselRequirement: this.vslIsProformaFromService,
    };
    postObj.deployments.vesselID = postObj.deployments.vesselID ? postObj.deployments.vesselID : 0;
    return postObj;
  }
  public navigateToCharterRequest() {
    // window.open('/charter-request/CandidateVessels/' + this.dummyDetails.vslCharterRequestID);
    this.vslService.getNegotiatingRequestStage(this.dummyDetails.vslCharterRequestID).subscribe((response: any) => {
      if (
        response.charterRequestStage === 3.1 ||
        response.charterRequestStage === 3 ||
        response.charterRequestStage === 2.0
      ) {
        window.open('/charter-request/CandidateVessels/' + this.dummyDetails.vslCharterRequestID);
      }
      if (response.charterRequestStage === 3.2) {
        this.vslService
          .getApprovalData(this.dummyDetails.vslCharterRequestID)
          .pipe(
            map((mapCandResponse: any) => {
              return mapCandResponse.charterRequestCandidateList.candidates.filter(
                (cand) => cand.crvcIsFinalCandidate === true
              );
            })
          )
          .subscribe((selectedVslIdForNegotitation: any) => {
            if (selectedVslIdForNegotitation.length > 0) {
              const vcPKID = selectedVslIdForNegotitation.map((item) => item.vcPKID);
              window.open(
                '/charter-request/CandidateVessels/' + this.dummyDetails.vslCharterRequestID + '/Approval/' + vcPKID
              );
            } else {
              window.open('/charter-request/');
            }
          });
      }
      if (response.charterRequestStage === 0) {
        window.open('/charter-request/');
      }
    });
  }

  public prepareRequestPayloadObj() {
    if (this.vslId) {
      this.dummyNameWatermark = this.routeData.fromVesselDetail
        ? this.vesselName
        : this.dummyDetails && this.dummyDetails.extendedDummyFixtureID
        ? this.dummyDetails.vmVesselName
        : this.dummyNameWatermark;
    } else {
      this.dummyNameWatermark =
        this.serviceDetailsForName +
        '-' +
        this.segmentInName.replace(/[^\d]/g, '') +
        '-' +
        this.positionDetailForName +
        '-' +
        this.startPortForName;
    }
    // Get Data for fixpkid from overview
    const fixPKID = localStorage.getItem('fixPkidVal');
    localStorage.removeItem('fixPKID');
    const postObj = {
      vslPKID: this.routeData.title !== 'Extend Dummy' && this.vslId ? Number(this.vslId) : 0,
      vslOperatorCodeId: this.vslOperatorCodeId,
      vslVesselTeu: this.vesselReqFrmGrp.get('vesselTeuId').value,
      vslTeuNominal: this.vesselReqFrmGrp.get('nomTeuCapacity').value,
      vslTonCenCap: this.vesselReqFrmGrp.get('tonCenCap').value,
      vslGT: this.vesselReqFrmGrp.get('grossTonnage').value,
      vslDWT: this.vesselReqFrmGrp.get('deadWeight').value,
      vslMaxSpeed: this.vesselReqFrmGrp.get('maxSpeed').value,
      vslMinSpeed: this.vesselReqFrmGrp.get('minSpeed').value,
      vslPlugsInHold: Number(this.vesselReqFrmGrp.get('plugs').value),
      vslTeuReefer: 0,
      vmVesselName: this.routeData.fromVesselDetail ? this.vesselName : this.dummyNameWatermark,
      vslVesselColor: this.selectedColor,
      vslCharterRequestID: this.vesselReq ? this.vesselReq.vslCharterRequestID : null,
      dummyIsRequest: this.convertToRequestChecked,
      deployments: { ...this.childRedesignComponent.finalPayloadFromRedesign },
      requestDetails: this.setRequestDetails(),
      fixPKID:
        this.dummyDetails && this.dummyDetails.extendedDummyFixtureID
          ? this.dummyDetails.extendedDummyFixtureID
          : this.routeData.fromVesselDetail
          ? fixPKID
          : null,
      vslIsProformaVesselRequirement: this.vslIsProformaFromService,
    };
    postObj.deployments.vesselID = postObj.deployments.vesselID ? postObj.deployments.vesselID : 0;
    return postObj;
  }
  public setRequestDetails() {
    this.getProformaDetails();
    const requestObj = {
      crPKID: this.vesselReq && this.vesselReq.vslCharterRequestID ? this.vesselReq.vslCharterRequestID : 0,
      crPhaseInPortID: !this.getFirstDeploymentLineDetail().controls['isSpecialPosition'].value
        ? this.getFirstDeploymentLineDetail().controls['depStartPortID'].value
        : null,
      crPhaseInPortName: null,
      crStatusID: this.requestBlockDetails.crStatusID,
      crPhaseInStartDate:
        new DatePipe('en-US').transform(
          this.getFirstDeploymentLineDetail().controls['depBeginDate'].value,
          'yyyy-MM-dd'
        ) +
          'T' +
          '00:00:00.000Z' || null,
      crPhaseOutPortID: !this.getLastDeploymentLineDetails().controls['isSpecialPosition'].value
        ? this.getLastDeploymentLineDetails().controls['depEndPortID'].value
        : null,
      crPhaseOutPortName: null,
      crPhaseOutStartDate:
        new DatePipe('en-US').transform(
          this.getLastDeploymentLineDetails().controls['depEndDate'].value,
          'yyyy-MM-dd'
        ) +
          'T' +
          '00:00:00.000Z' || null,
      crTradeID: null,
      crTEU: this.requestRequirementDetails.totalTeuCap || null,
      crMTCapacity: this.requestRequirementDetails.mlmtCap,
      crPlugs: Number(this.requestRequirementDetails.plugs),
      crMinSpeed: this.requestRequirementDetails.minSpeed,
      crMaxSpeed: this.requestRequirementDetails.maxSpeed,
      crDraft: this.requestRequirementDetails.maxDraft,
      crAirDraft: this.requestRequirementDetails.airDraft,
      crIsGear: this.requestRequirementDetails.gears,
      crIsIceClass: true,
      crComments: this.requestRequirementDetails.additionalRemark,
      crVesselDepID: this.getFirstDeploymentLineDetail().controls['depPKID'].value || null,
      crReason: this.requestBlockDetails.crReason,
      crForecastTEU: Number(this.requestBlockDetails.crForecastTEU),
      crForecastTEURate: this.requestBlockDetails.crForecastTEURate,
      crMLTeuCapacity: this.requestRequirementDetails.mlTeuCap,
      crMLMTCapacity: this.requestRequirementDetails.totalMTCap,
      crProformaHeaderId: this.prhPKIDForReq,
      crVesselTeuId: this.requestRequirementDetails.vesselTeuId,
      crNomTeuCapacity: this.requestRequirementDetails.nomTeuCapacity,
      crTonCenCap: this.requestRequirementDetails.tonCenCap,
      crECAZone: null,
      crIsECA: true,
      crExtendsVesselId: null,
      crPosition: this.getPosition(),
      crServiceId: this.getFirstDeploymentLineDetail().controls['depStringID'].value,
      crServiceCode: this.dummyDetails ? this.dummyDetails.vmVesselName.split('-')[0] : this.serviceDetailsForName,
      crServiceName: null,
      updatedBy: null,
      updatedDate: null,
      proformaId: this.finalProformaId,
      previousVesselCode: null,
      previousVesselName: null,
      crPhaseInPortIDForUnknownSvc: this.getFirstDeploymentLineDetail().controls['isSpecialPosition'].value
        ? this.getFirstDeploymentLineDetail().controls['depSpecialServiceStartPortID'].value
        : null,
      crPhaseOutPortIDForUnknownSvc: this.getLastDeploymentLineDetails().controls['isSpecialPosition'].value
        ? this.getLastDeploymentLineDetails().controls['depSpecialServiceEndPortID'].value
        : null,
      crPhaseInPortNameForUnknownSvc: null,
      crPhaseOutPortNameForUnknownSvc: null,
      portFromProforma: true,
      crIsExtraPosition: this.getFirstDeploymentLineDetail().controls['extraPosition'].value || false,
      charterRequestName: this.routeData.fromVesselDetail ? this.vesselName : this.dummyNameWatermark,
      crGT: this.requestRequirementDetails.grossTonnage,
      crLOA: this.requestRequirementDetails.maxLOA,
      crBeam: this.requestRequirementDetails.maxBeam,
      crIsRCMInstalled: this.requestRequirementDetails.rcmInstalled,
      crIsAMPCompliant: this.requestRequirementDetails.ampCompliant,
      crIsPanamaFitted: this.requestRequirementDetails.panamaFitted || 3,
      crIsITFCompliant: this.requestRequirementDetails.itfCompliant,
      crIsScrubber: this.requestRequirementDetails.scrubber,
      crCapacityAdditionalRemark: this.requestRequirementDetails.capacityAdditionalRemark,
      crBunkerType1Price: Number(this.requestBlockDetails.crBunkerType1Price),
      crBunkerType2Price: Number(this.requestBlockDetails.crBunkerType2Price),
      crBunkerType3Price: Number(this.requestBlockDetails.crBunkerType3Price),
      isSpecialPosition: this.getFirstDeploymentLineDetail().controls['isSpecialPosition'].value || false,
    };
    return requestObj;
  }
  public getProformaDetails() {
    const isSpecialPosition = this.getFirstDeploymentLineDetail().controls['isSpecialPosition'].value;
    const serviceIdFromFirstLine = this.getFirstDeploymentLineDetail().controls['depStringID'].value;
    const beginDateFromFirstLine = this.getFirstDeploymentLineDetail().controls['depBeginDate'].value;
    const endDateFromFirstLine =this.getFirstDeploymentLineDetail().controls['depEndDate'].value;
    const startPortForFirstLine =  isSpecialPosition ?this.getFirstDeploymentLineDetail().controls['depSpecialServiceStartPortID'].value   :  this.getFirstDeploymentLineDetail().controls['depStartPortID'].value;
    const serviceListFromRedesign = this.childRedesignComponent.vesselDeploymentServices;
    const srviceObjForFirstLine = serviceListFromRedesign.filter((s) => s.strPKID === +serviceIdFromFirstLine);
    const beginDate = new DatePipe('en-US').transform(beginDateFromFirstLine, 'dd-MMM-yyyy');
    const endDate = new DatePipe('en-US').transform(endDateFromFirstLine, 'dd-MMM-yyyy');
    // this.serviceRepoService.getScheduleLst(this.id).subscribe((response: any) => {
    const scheduleList = srviceObjForFirstLine.length ? srviceObjForFirstLine[0].lstProformas : null;
    if (scheduleList && scheduleList.length === 1) {
      this.finalProformaId = scheduleList[0].proformaID;
      this.prhPKIDForReq = scheduleList[0].proformaPKID;
    } else if (scheduleList && scheduleList.length > 1) {
      const filteredSchedule = scheduleList.filter(
        (x) => new Date(beginDate) >= new Date(x.validFrom) && new Date(endDate) <= new Date(x.validTo)
      );
      if (filteredSchedule && filteredSchedule.length > 1) {
        let filtersedScheduleOnStatus = filteredSchedule.filter((x) => x.proformaStatus === 'Current');
        filtersedScheduleOnStatus =
          filtersedScheduleOnStatus.length === 0
            ? filteredSchedule.filter((x) => x.proformaStatus === 'Upcoming')
            : filtersedScheduleOnStatus;
        filtersedScheduleOnStatus =
          filtersedScheduleOnStatus.length === 0
            ? filteredSchedule.filter((x) => x.proformaStatus === 'New')
            : filtersedScheduleOnStatus;

        if (filtersedScheduleOnStatus.length > 1) {
          let startPortFromRedesign = [];
          startPortFromRedesign =isSpecialPosition ? this.childRedesignComponent.allPorts :  this.childRedesignComponent.allPortsByService.portDateTime;
          startPortFromRedesign = startPortFromRedesign.filter((x) => x.schedulePortId === startPortForFirstLine);
          const proformaIDForFirstLine = startPortFromRedesign[0] ? startPortFromRedesign[0].scheduleProformaPKId : 0;

          filtersedScheduleOnStatus = filtersedScheduleOnStatus.reduce((a, b) => {
            return new Date(a.validFrom) > new Date(b.validFrom) ? a : b;
          });
          if(proformaIDForFirstLine)
          filtersedScheduleOnStatus =  filteredSchedule.filter((x) => x.proformaPKID === proformaIDForFirstLine)[0]
          console.log(filtersedScheduleOnStatus);

          //const proformaIDForFirstLine = startPortFromRedesign[0] ? startPortFromRedesign[0].scheduleProformaPKId : 0;
          this.finalProformaId = filtersedScheduleOnStatus &&  filtersedScheduleOnStatus.proformaID ? filtersedScheduleOnStatus.proformaID : null;
          // this.prhPKIDForReq = filtersedScheduleOnStatus ? filtersedScheduleOnStatus.proformaPKID : null;
          this.prhPKIDForReq = proformaIDForFirstLine ? proformaIDForFirstLine : null;
        } else {
          this.finalProformaId = filtersedScheduleOnStatus[0].proformaID;
          this.prhPKIDForReq = filtersedScheduleOnStatus[0].proformaPKID;
        }
      } else if (filteredSchedule && filteredSchedule.length === 1) {
        this.finalProformaId = filteredSchedule[0].proformaID;
        this.prhPKIDForReq = filteredSchedule[0].proformaPKID;
      } else if (filteredSchedule && filteredSchedule.length === 0) {
        const filteredScheduleCurrent = scheduleList.filter((x) => x.proformaStatus === 'Current');
        if (filteredScheduleCurrent && filteredScheduleCurrent.length === 1) {
          this.finalProformaId = filteredScheduleCurrent[0].proformaID;
          this.prhPKIDForReq = filteredScheduleCurrent[0].proformaPKID;
        } else {
          this.finalProformaId = null;
          this.prhPKIDForReq = null;
          return;
        }
      }
    } else if (!scheduleList || scheduleList.length === 0) {
      this.finalProformaId = null;
      this.prhPKIDForReq = null;
      return;
    }
  }

  public getFirstDeploymentLineDetail() {
    const firstDepLine = this.childRedesignComponent.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.controls[0];
    if (
      this.childRedesignComponent.vesselPreviousDeployments &&
      this.childRedesignComponent.vesselDeploymentLines.pristine &&
      !firstDepLine.controls['depEndDate'].value
    ) {
      const firstPrevDepLine = this.childRedesignComponent.vesselPreviousDeployments[0];
      const isSpecial =
        firstPrevDepLine.isSpecialPosition ||
        firstPrevDepLine.isSpecial ||
        (firstPrevDepLine.depSpecialServiceStartPortID && !firstPrevDepLine.depStartPortID)
          ? true
          : false;
      return this.formBuilder.group({ isSpecialPosition: isSpecial, ...firstPrevDepLine });
    }

    return firstDepLine;
  }
  public getLastDeploymentLineDetails() {
    const curDepLines = this.childRedesignComponent.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.controls;
    if (
      this.childRedesignComponent.vesselPreviousDeployments &&
      this.childRedesignComponent.vesselDeploymentLines.pristine &&
      !curDepLines[0].controls['depEndDate'].value
    ) {
      const lastPrevDepLine = this.childRedesignComponent.vesselPreviousDeployments[
        this.childRedesignComponent.vesselPreviousDeployments.length - 1
      ];
      const isSpecial =
        lastPrevDepLine.isSpecialPosition ||
        lastPrevDepLine.isSpecial ||
        (lastPrevDepLine.depSpecialServiceStartPortID && !lastPrevDepLine.depStartPortID)
          ? true
          : false;
      return this.formBuilder.group({ isSpecialPosition: isSpecial, ...lastPrevDepLine });
    }
    if (curDepLines.length > 1) {
      const lastIndex = curDepLines.length - 2;
      return curDepLines[lastIndex];
    } else if (curDepLines.length === 1) {
      return curDepLines[0];
    }
  }
  public getPosition() {
    let positionTemp = this.getFirstDeploymentLineDetail().controls['depPosition'].value.toString();
    if (positionTemp.indexOf('X') > -1 || positionTemp.indexOf('x') > -1) {
      positionTemp = positionTemp.replace(/\D/g, '');
    }
    return Number(positionTemp);
  }
  public getRedesignInvalidControls() {
    const formgrp = [];

    this.childRedesignComponent.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.controls.forEach((element) => {
      formgrp.push(element);
    });
    let invalid: any = [];
    formgrp.forEach((fg, fgindex) => {
      const controls = fg.controls;
      if (fgindex < formgrp.length - 1) {
        // tslint:disable-next-line:forin
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name === 'depPosition' ? 'Position' : name === 'depReasonCodeID' ? 'ReasonCode' : 'data');
          }
          if (name === 'depStringID' && !controls[name].value) {
            invalid.push('Service');
          }
          if (controls.isSpecialPosition.value !== true) {
            if (name === 'depStartPortID' && !controls[name].value) {
              invalid.push('depStartPortID');
            }
            if (name === 'depEndPortID' && !controls[name].value) {
              invalid.push('depEndPortID');
            }
          } else if (controls.isSpecialPosition.value === true) {
            if (name === 'depSpecialServiceStartPortID' && !controls[name].value) {
              invalid.push('depSpecialServiceStartPortID');
            }
            if (name === 'depSpecialServiceEndPortID' && !controls[name].value) {
              invalid.push('depSpecialServiceEndPortID');
            }
          }
        }
      } else {
        if (controls.isSpecialPosition.value !== true) {
          if (
            this.getFirstDeploymentLineDetail().controls['depBeginDate'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depStringID'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depPosition'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depReasonCodeID'].value == null
          ) {
            invalid.push('depBeginDate');
            invalid.push('depPosition');
            invalid.push('depReason');
          }
        } else if (controls.isSpecialPosition.value === true) {
          if (
            this.getFirstDeploymentLineDetail().controls['depBeginDate'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depStringID'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depPosition'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depSpecialServiceStartPortID'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depSpecialServiceEndPortID'].value == null ||
            this.getFirstDeploymentLineDetail().controls['depReasonCodeID'].value == null
          ) {
            invalid.push('depSpecialServiceStartPortID');
            invalid.push('depSpecialServiceEndPortID');
            invalid.push('depReason');
          }
        }
      }
    });
    invalid = invalid.filter((v, i) => invalid.indexOf(v) === i);
    return invalid;
  }
  public ValidateFirstDeplymentLine() {
    const currentObject = this.prepareDummyPayloadObj();

    const formgrp = [];
    this.childRedesignComponent.vesselDeploymentFrmGrp.controls.vesselDeploymentLines.controls.forEach((element) => {
      formgrp.push(element);
    });
    let valid = false;
    if (formgrp.length === 1 && currentObject.deployments.lstVesselDeplymentDto.length > 0) {
      const controls = formgrp[0].controls;
      if (controls.isSpecialPosition.value !== true) {
        if (
          this.getFirstDeploymentLineDetail().controls['depStringID'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depPosition'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depReasonCodeID'].value == null
        ) {
          valid = true;
        }
      } else if (controls.isSpecialPosition.value === true) {
        if (
          this.getFirstDeploymentLineDetail().controls['depStringID'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depPosition'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depSpecialServiceStartPortID'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depSpecialServiceEndPortID'].value == null &&
          this.getFirstDeploymentLineDetail().controls['depReasonCodeID'].value == null
        ) {
          valid = true;
        }
      }
    }
    return valid;
  }
  public serviceDetailsFromRedesign(e) {
    if (e) {
      this.serviceDetailsForName = e.serviceCode ? e.serviceCode : 'Service code';
      if (this.vslId && !e.serviceCode && this.dummyDetails) {
        this.serviceDetailsForName = this.dummyDetails.vmVesselName.split('-')[0];
      }
      this.lstOfProformasForFirstService = e.lstProformas;
      const k = {
        target: {
          checked: true,
        },
      };
      if (this.countOfMethodVisit === 0) {
        this.requirementFrmOriginalValue = this.childVslReqComponent.vesselReqFrmGrp.value;
      } else {
        this.dummyControlChanges = true;
      }
      if (this.isProfoBoxChecked && !this.vslId && this.routeData.title !== 'Extend Dummy') {
        this.countOfMethodVisit++;
        this.useProformaFromFirstDep(k);
      } else if (this.vslId && this.isProfoBoxChecked && this.routeData.title !== 'Extend Dummy') {
        // if ((this.countOfMethodVisit === 0 && !this.dummyDetails.vslVesselTeu) || this.countOfMethodVisit > 0) {
        this.useProformaFromFirstDep(k);
        // }

        this.countOfMethodVisit++;
      } else if (this.vslId && !this.isProfoBoxChecked && this.childVslReqComponent.vesselReqFrmGrp.pristine) {
        this.vesselRequirementsFormGroup(this.dummyDetails);
        this.countOfMethodVisit++;
      }
    } else if (this.childVslReqComponent.vesselReqFrmGrp.pristine) {
      this.vesselRequirementsFormGroup(this.dummyDetails);
      this.countOfMethodVisit++;
    }
  }
  public positionDetailsFromRedesign(e) {
    if (e.event !== '') {
      this.positionDetailForName = e.target && e.target.value ? e.target.value : 'position';
    } else {
      this.positionDetailForName = e.depPosition;
    }
    this.dummyControlChanges = true;
  }
  public startPortDetailsFromRedesign(e) {
    this.startPortForName = e && e.schedulePortName ? e.schedulePortName : 'start port';
    this.dummyControlChanges = true;
  }

  public setSegmentInDummyName(segmentOptions) {
    const isSegment =
      segmentOptions?.vesselTeuId && segmentOptions?.segment
        ? segmentOptions.segment.replace(/[^\d]/g, '') || 'segment'
        : '';
    this.segmentInName = segmentOptions?.vesselTeuName
      ? segmentOptions?.vesselTeuName.replace(/[^\d]/g, '')
      : isSegment;
    // this.prhPKIDForReq = segmentOptions.prhPKID;

    this.dummyControlChanges = true;
    const emitObj = {
      isInvalid: this.childVslReqComponent.vesselReqFrmGrp.invalid,
      isModified: true,
      isPristine: this.childVslReqComponent.vesselReqFrmGrp.pristine,
      isDirty: this.childVslReqComponent.vesselReqFrmGrp.dirty,
    };
    this.recievedControlValueChangeEvents(emitObj);
    if (this.routeData.title === 'Extend Dummy') {
      /* checking if deployment details are entered */
      this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
    }
  }

  public getUpdatedNameForDummy(e) {
    this.startPortForName = e.startPort;
    this.serviceDetailsForName = e.stringObj;
    this.positionDetailForName = e.position;
  }

  public useProformaFromFirstDep(e) {
    let serviceInFirstLine: any;
    if (!this.lstOfProformasForFirstService) {
      const serviceListFromRedesign = this.childRedesignComponent.vesselDeploymentServices;

      serviceInFirstLine = serviceListFromRedesign.filter(
        (s) => s.strPKID === +this.childRedesignComponent.vesselDeploymentLines.value[0].depStringID
      );
    }

    this.isProfoBoxChecked = e.target.checked;
    this.vslIsProformaFromService = this.isProfoBoxChecked;
    this.isSaveCancelBtnEnabled = false;

    if (e.target.checked) {
      const serviceId = this.childRedesignComponent.vesselDeploymentLines.value[0].depStringID;
      const beginDate = this.childRedesignComponent.vesselDeploymentLines.value[0].depBeginDate;
      const endDate = this.childRedesignComponent.vesselDeploymentLines.value[0].depEndDate;
      this.setServiceforProforma = {
        serviceId: serviceId,
        lstProformas: this.lstOfProformasForFirstService
          ? this.lstOfProformasForFirstService
          : serviceInFirstLine[0]
          ? serviceInFirstLine[0].lstProformas
          : null,
        beginDate: beginDate,
        endDate: endDate,
      };
    }
  }
  public removeServicePortFromName() {
    this.positionDetailForName = null;
    this.serviceDetailsForName = null;
    this.startPortForName = null;
  }
  public cancelChanges() {
    this.countOfMethodVisit = 0;
    this.dummyControlChanges = false;
    this.showWarningOverlappingDummy = false;
    this.showIncorrectDetailErrorDummy = false;

    if (!this.vslId) {
      this.childRedesignComponent.vesselDeploymentFrmGrp.reset();
      this.router
        .navigateByUrl('/vessels', { skipLocationChange: true })
        .then(() => this.router.navigate(['/vessels/createdummy']));
    } else {
      if (this.routeData.title !== 'Extend Dummy') {
        this.dummyNameWatermark = this.dummyDetails.vmVesselName;
        this.vslOperatorCodeId = this.dummyDetails.vslOperatorCodeId;
        this.convertToRequest = this.dummyDetails.dummyIsRequest;
      }
      if (this.routeData.title === 'Extend Dummy') {
        this.dontShowDeleteButton = 'true';
      }
      this.invokeConvertToRequestCheckWhenRequestView(this.convertToRequest);
      if (this.routeData.title !== 'Extend Dummy') {
        this.childVslReqComponent.vesselReqFrmGrp.reset(this.requirementFrmOriginalValue);
      } else {
        this.childVslReqComponent.getExtendDummyRequirement();
      }
      this.childRedesignComponent.cancelEdit();
      this.isSaveCancelBtnEnabled = true;
    }
    this.isCancelShowPreviousClick = true;
  }

  public openDeleteDummyPopup() {
    this.showFinalConfirmation = false;
    this.deleteFirstMsg = 'Are you sure you want to delete the dummy vessel "' + this.dummyDetails.vmVesselName + '" ?';
    this.deleteFinalMsg =
      'Dummy vessel "' +
      this.dummyDetails.vmVesselName +
      '" will be permanently deleted.Do you want to cancel permanent delete operation?';
    this.dialogRef = this.dialog.open(this.warningDialog, {
      width: '350px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public deleteDummy(confirm) {
    if (confirm === 'First') {
      this.showFinalConfirmation = true;
    } else if (confirm === 'Final') {
      this.showFinalConfirmation = false;
      const postData = {
        genericRequestID1: this.vslId,
        genericRequestID2: 0,
        genericString1: '',
        genericString2: '',
        genericFlag: true,
      };
      this.vslService.deleteDummyDetails(postData).subscribe((response) => {
        if (response) {
          this.clearMessages();
          this.alertService.success('Dummy is successfully deleted.', true);
          this.router.navigate(['/vessels']);
        }
      });
    }
  }
  public requirementFormChanged() {
    this.showWarningOverlappingDummy = false;
    this.isSaveCancelBtnEnabled = false;
  }

  public findSegment(segmentId) {
    const obj = this.segmentList ? this.segmentList.filter((e) => e.vesselTeuID === segmentId) : null;
    const name = obj.length ? obj[0].vesselTeuName : null;
    return name;
  }
  public findSegmentId(teuName) {
    const obj = this.segmentList ? this.segmentList.filter((e) => e.vesselTeuName === teuName) : null;
    const id = obj.length ? obj[0].vesselTeuID : null;
    return id;
  }

  public empty() {
    this.vslOperatorCodeId = null;
  }
  public enabledSaveButtonWhenConvertToCheckRequest(val?) {
    this.isCancelShowPreviousClick = false;
    // Commented as isSaveCancelBtnEnabled is no longer used
    // const res = this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
    // if (val.target.checked) {
    //   if (res && this.operatorFormGrp.value.vslOperatorCodeIdFC !== null) {
    //     this.isSaveCancelBtnEnabled = true;
    //   } else {
    //     if (this.operatorFormGrp.value.vslOperatorCodeIdFC == null && !this.isSaveCancelBtnEnabled) {
    //       this.isSaveCancelBtnEnabled = true;
    //     }
    //   }
    // } else {
    //   this.isSaveCancelBtnEnabled = true;
    // }
  }

  public recievedControlValueChangeEvents(emitEvnts?) {
    if (!emitEvnts.isDirty && !emitEvnts.isModified) {
      return;
    }
    this.vsldeploymentControlIndex = emitEvnts.controlIndex;
    this.clearMessages();
    this.isCancelShowPreviousClick = false;
    if (
      this.vsldeploymentControlIndex === undefined &&
      this.childRedesignComponent.vesselDeploymentLines.value.length === 1
    ) {
      if (this.routeData.title === 'Extend Dummy') {
        this.isSaveCancelBtnEnabled = false;
      } else {
        this.isSaveCancelBtnEnabled = true;
      }
      /* checking if deployment details are entered */
      this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
    } else if (emitEvnts.isPristine && this.vsldeploymentControlIndex < 2) {
      this.isSaveCancelBtnEnabled = this.convertToRequestChecked ? false : emitEvnts.isPristine;
    } else {
      /* checking if deployment details are entered */
      this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
      /* Not enabling save until operator is selected */
      if (this.operatorFormGrp.value.vslOperatorCodeIdFC == null && !this.isSaveCancelBtnEnabled) {
        this.isSaveCancelBtnEnabled = true;
      }
    }
    if (!emitEvnts.isInvalid && emitEvnts.isModified) {
      this.showIncorrectDetailErrorDummy = false;
    }
    if (emitEvnts.isDirty && emitEvnts.isdeplineEmpty) {
      this.isSaveCancelBtnEnabled = true;
    } else if (
      emitEvnts.isdeplineEmpty === false &&
      emitEvnts.isSpecialPosition !== undefined &&
      emitEvnts.isSpecialPosition
    ) {
      // for SpecialService
      this.isSpecialPosition = true;
      // this.isSaveCancelBtnEnabled = false;
      this.isSaveEnabledForSpecialPos(this.isSpecialPosition);
    }
    // Delete first deployment line dummy
    if (emitEvnts.isModified && emitEvnts.isDummyDeleted) {
      this.openDeleteDummyPopup();
    }
  }

  // to enable save button only after all 3 blocks are filled
  public isSaveEnabledForSpecialPos(val) {
    if (val) {
      const res = this.chkVesselReqFrmGrpEmpty(this.childVslReqComponent.vesselReqFrmGrp.value);
      if (res && this.operatorFormGrp.value.vslOperatorCodeIdFC !== null) {
        this.isSaveCancelBtnEnabled = true;
      } else {
        if (this.operatorFormGrp.value.vslOperatorCodeIdFC == null && !this.isSaveCancelBtnEnabled) {
          this.isSaveCancelBtnEnabled = true;
        }
      }
    }
  }

  /* Checking if all the required fields are filled in vessel requirements form and Redesign control */
  public chkVesselReqFrmGrpEmpty(val) {
    let invalidControls: any = [];
    invalidControls = this.getRedesignInvalidControls();
    this.isCancelShowPreviousClick = invalidControls && invalidControls.length > 0 ? true : false;
    // debugger;
    // if null or empty string
    if (
      val.segment !== null &&
      val.plugs !== null &&
      val.maxSpeed !== null &&
      val.nomTeuCapacity !== null &&
      !this.isCancelShowPreviousClick
    ) {
      this.isCancelShowPreviousClick = false;
    } else {
      this.isCancelShowPreviousClick = true;
    }
    if (
      val.segment !== '' &&
      val.plugs !== '' &&
      val.maxSpeed !== '' &&
      val.nomTeuCapacity !== '' &&
      !this.isCancelShowPreviousClick
    ) {
      this.isCancelShowPreviousClick = false;
    } else {
      this.isCancelShowPreviousClick = true;
    }
    return this.isCancelShowPreviousClick;
  }

  get isDeploymentUserAuthorized() {
    return this.auth.roles
      ? this.auth.isRolePresent(this.auth.roles, 'Deployment') || this.auth.isRolePresent(this.auth.roles, 'L3')
      : false;
  }

  get noOperationPermitted() {
    // return this.vslId ? !(this.isDeploymentUserAuthorized && this.vslId) : false;
    return this.auth.BYPASS_AUTHENTICATION
      ? false
      : this.vslId
      ? !(this.isDeploymentUserAuthorized && this.vslId)
      : false;
  }

  public afterSaveRedirectToDummyOverviewPage(id) {
    this.router
      .navigateByUrl('/vessels', { skipLocationChange: true })

      .then(() => this.router.navigate(['/vessels/dummy/overview/' + id]));
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public vessleColorChange() {
    if (this.selectedColor !== this.dummyDetails.vslVesselColor) {
      const dummyVessel = {
        vslID: this.dummyDetails.vslPKID,
        vslColor: this.selectedColor,
      };
      this.vslService.updateVesselColor(dummyVessel).subscribe();
    }
  }

  public saveDummyRequestDetails() {
    this.clearMessages();

    this.showInvalidDataError = false;
    this.invalidControlsForPage = [];
    // debugger;
    if (!this.vslOperatorCodeId) {
      this.setOperator = true;
      return;
    }

    if (this.childRedesignComponent.vesselDeploymentLines.value.length) {
      this.childRedesignComponent.saveAllDeploymentLines();
      this.childVslReqComponent.updateVesselReq();
      this.vesselReqFrmGrp = this.childVslReqComponent.vesselReqFrmGrp;
      if (this.childVslReqComponent.vesselReqFrmGrp.invalid) {
        return;
      }

      let invalidControls: any = [];
      invalidControls = this.getRedesignInvalidControls();
      if (
        (!this.childRedesignComponent.vesselPreviousDeployments ||
          !this.childRedesignComponent.vesselDeploymentLines.pristine) &&
        invalidControls &&
        invalidControls.length > 0
      ) {
        this.showInvalidDataError = true;
        this.showWarningOverlappingDummy = false;
        this.invalidControlsForPage = invalidControls;
        return;
      }
      this.vslService.getBunkerForCharter().subscribe((res: any) => {
        this.setBunkerValues(res.monthlyAvgBunkerPrice);
      });
      if (!this.dummyDetails || (this.dummyDetails && !this.dummyDetails.vslCharterRequestID)) {
        this.openConvertToDummyBlock = true;
        this.dialogRef = this.dialog.open(this.convertToRequestDialog, {
          width: '743px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
        });
      } else {
        this.saveRequestBlock();
      }
    }
  }

  public saveRequestBlock() {
    if (this.serviceFormGrp.invalid && !this.dummyDetails.vslCharterRequestID) {
      this.fieldError = true;
      return;
    }

    this.requestBlockDetails = this.serviceFormGrp.value;
    this.requestRequirementDetails = this.childVslReqComponent.vesselReqFrmGrp.value;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.saveDummyDetails();
  }

  public checkDatesChanged(e) {
    if (e) {
      this.showWarningOverlappingDummy = false;
      this.showIncorrectDetailErrorDummy = false;
    }
  }

  private vesselRequirementsFormGroup(vesselReq: any = null) {
    if (!vesselReq) {
      this.vesselReqFrmGrp = this.formBuilder.group({
        proformaID: null,
        totalTeuCap: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        totalMTCap: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        mlTeuCap: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        mlTeuPer: null,
        mlMTPer: null,
        mlmtCap: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        plugs: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(0),
          Validators.pattern(new RegExp('^[0-9]+$')),
        ]),
        minSpeed: null,
        maxSpeed: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(0),
          Validators.max(99),
          Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
        ]),
        airDraft: this.formBuilder.control(null, [Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$'))]),
        gears: null,
        lastUpdatedby: null,
        totalTeuCapOld: null,
        totalMTCapOld: null,
        mlTeuCapOld: null,
        mlmtCapOld: null,
        plugsOld: null,
        minSpeedOld: null,
        maxSpeedOld: null,
        nomTeuCapacity: null,
        tonCenCap: null,
        segment: null,
        vesselTeuId: null,
        additionalRemark: null,
        grossTonnage: null,
        deadWeight: null,
        maxLOA: null,
        maxBeam: null,
        maxDraft: null,
        rcmInstalled: null,
        ampCompliant: null,
        itfCompliant: null,
        capacityAdditionalRemark: null,
        panamaFitted: 3,
        scrubber: null,
      });
    } else {
      if (!vesselReq.dummyIsRequest) {
        this.childVslReqComponent.vesselReqFrmGrp = this.formBuilder.group({
          totalTeuCap: null,
          totalMTCap: null,
          mlTeuCap: null,
          mlTeuPer: null,
          mlMTPer: null,
          mlmtCap: null,
          plugs: this.formBuilder.control(vesselReq.vslPlugsInHold, [
            Validators.required,
            Validators.min(0),
            Validators.pattern(new RegExp('^[0-9]+$')),
          ]),
          minSpeed: this.formBuilder.control(vesselReq.vslMinSpeed),
          maxSpeed: this.formBuilder.control(vesselReq.vslMaxSpeed, [
            Validators.required,
            Validators.min(0),
            Validators.max(99),
            Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
          ]),
          airDraft: null,
          gears: null,
          lastUpdatedby: null,
          totalTeuCapOld: null,
          totalMTCapOld: null,
          mlTeuCapOld: null,
          mlmtCapOld: null,
          plugsOld: null,
          minSpeedOld: null,
          maxSpeedOld: null,
          nomTeuCapacity: this.formBuilder.control(vesselReq.vslTeuNominal, [
            Validators.required,
            Validators.min(0),
            Validators.pattern(new RegExp('^[0-9]+$')),
          ]),
          tonCenCap: this.formBuilder.control(vesselReq.vslTonCenCap, [
            Validators.min(0),
            Validators.pattern(new RegExp('^[0-9]+$')),
          ]),
          grossTonnage: this.formBuilder.control(vesselReq.vslGT, [
            Validators.pattern(new RegExp('^[0-9]+(.[0-9]{1,2})?$')),
          ]),
          deadWeight: this.formBuilder.control(vesselReq.vslDWT, [Validators.pattern(new RegExp('^[0-9]+$'))]),
          maxLOA: null,
          maxBeam: null,
          maxDraft: null,
          rcmInstalled: null,
          ampCompliant: null,
          panamaFitted: 3,
          itfCompliant: null,
          scrubber: null,
          capacityAdditionalRemark: null,
          segment: this.formBuilder.control(this.findSegment(vesselReq.vslVesselTeu), [Validators.required]),
          vesselTeuId: this.formBuilder.control(vesselReq.vslVesselTeu),
          additionalRemark: null,
        });
      } else if (vesselReq.dummyIsRequest) {
        this.childVslReqComponent.vesselReqFrmGrp = this.formBuilder.group({
          totalTeuCap: vesselReq.requestDetails.crTEU || null,
          totalMTCap: vesselReq.requestDetails.crMTCapacity || null,
          mlTeuCap: vesselReq.requestDetails.crMLTeuCapacity || null,
          mlTeuPer: null,
          mlMTPer: null,
          mlmtCap: vesselReq.requestDetails.crMLMTCapacity || null,
          plugs: vesselReq.requestDetails.crPlugs || null,
          minSpeed: vesselReq.requestDetails.crMinSpeed || null,
          maxSpeed: vesselReq.requestDetails.crMaxSpeed || null,
          airDraft: vesselReq.requestDetails.crAirDraft || null,
          gears: vesselReq.requestDetails.crIsGear,
          lastUpdatedby: null,
          totalTeuCapOld: null,
          totalMTCapOld: null,
          mlTeuCapOld: null,
          mlmtCapOld: null,
          plugsOld: null,
          minSpeedOld: null,
          maxSpeedOld: null,
          nomTeuCapacity: vesselReq.requestDetails.crNomTeuCapacity || null,
          tonCenCap: vesselReq.requestDetails.crTonCenCap || null,
          grossTonnage: vesselReq.requestDetails.crGT || null,
          deadWeight: vesselReq.vslDWT || null,
          maxLOA: vesselReq.requestDetails.crLOA || null,
          maxBeam: vesselReq.requestDetails.crBeam || null,
          maxDraft: vesselReq.requestDetails.crDraft || null,
          rcmInstalled: vesselReq.requestDetails.crIsRCMInstalled,
          ampCompliant: vesselReq.requestDetails.crIsAMPCompliant,
          panamaFitted: vesselReq.requestDetails.crIsPanamaFitted || 3,
          itfCompliant: vesselReq.requestDetails.crIsITFCompliant,
          scrubber: vesselReq.requestDetails.crIsScrubber,
          capacityAdditionalRemark: vesselReq.requestDetails.crCapacityAdditionalRemark || null,
          segment: this.findSegment(vesselReq.vslVesselTeu),
          vesselTeuId: vesselReq.requestDetails.crVesselTeuId,
          additionalRemark: vesselReq.requestDetails.crComments,
        });
      }
    }
    if (this.countOfMethodVisit === 0 && this.childVslReqComponent && this.childVslReqComponent.vesselReqFrmGrp) {
      this.requirementFrmOriginalValue = this.childVslReqComponent.vesselReqFrmGrp.value;
    }
  }

  private setBunkerValues(mapPrice) {
    mapPrice.forEach((rate) => {
      if (rate.bunkerType === 'HFO') {
        this.serviceFormGrp.patchValue({ crBunkerType1Price: rate.mapbunkerPrice ? rate.mapbunkerPrice : null });
      }
      if (rate.bunkerType === 'LSHFO') {
        this.serviceFormGrp.patchValue({ crBunkerType2Price: rate.mapbunkerPrice ? rate.mapbunkerPrice : null });
      }
      if (rate.bunkerType === 'ULSFO') {
        this.serviceFormGrp.patchValue({ crBunkerType3Price: rate.mapbunkerPrice ? rate.mapbunkerPrice : null });
      }
    });
  }
  private createFormGroup() {
    this.serviceFormGrp = this.formBuilder.group(
      (this.serviceObj = {
        crPKID: 0,
        crReason: null,
        crForecastTEU: null,
        crForecastTEURate: 14,
        crBunkerType1Price: null,
        crBunkerType2Price: null,
        crBunkerType3Price: null,
        crDeploymentUser: 0,
        crStatusID: 2,
      })
    );
  }

  private clearMessages() {
    this.alertService.clear();
    this.overlappingErrorMsg = [];
    this.trade_restrictions = [];
    this.overlappingMsg = [];
    this.warnings = [];
  }
}
