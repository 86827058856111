// communication

import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { distinctUntilChanged, debounceTime, map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import * as VesselCreate from 'src/app/state/vessels/reducers/vessel-create.reducer';

import { Store, select } from '@ngrx/store';
import { reduceArray2Obj } from 'src/app/utils/reduce-array2Obj';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { deepmerge } from '../../utils/deep-merge';
import { emptyCreateVessel } from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { Observable } from 'rxjs';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';

@Component({
  selector: 'champs-vessel-comm',
  templateUrl: './vessel-comm.component.html',
  styleUrls: ['./vessel-comm.component.scss'],
})
export class VesselCommComponent implements OnInit, OnChanges {
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  private _showFltrValue;

  private vslCommFormData: any;
  private vslPostnFormData: any;
  private regOwnerFormData: any;
  private manOwnerFormData: any;
  private comOwnerFormData: any;
  private techManagerFormData: any;
  private ownerBrokerFormData: any;
  private crewManagerFormData: any;
  // private phone_pattern = /^[0-9\+\-\ ]$/;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  @Output() public update: EventEmitter<any> = new EventEmitter();
  @Output() public changeStep?: EventEmitter<any> = new EventEmitter();

  @Input() public values: any;

  public originalValues: any = null;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  public showRequiredOnly: boolean = false;

  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vslCommViewMode: boolean = true;
  public vslPostnViewMode: boolean = true;
  public regOwnerViewMode: boolean = true;
  public manOwnerViewMode: boolean = true;
  public comOwnerViewMode: boolean = true;
  public techManagerViewMode: boolean = true;
  public ownerBrokerViewMode: boolean = true;
  public crewManagerViewMode: boolean = true;

  public vesselNewPage: boolean = false;
  public isCreateTypeHull: boolean;
  public vesselQsImport: boolean = false;
  public vesselType: boolean = true;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
      this.vslCommViewMode = false;
      this.vslPostnViewMode = false;
      this.regOwnerViewMode = false;
      this.manOwnerViewMode = false;
      this.comOwnerViewMode = false;
      this.techManagerViewMode = false;
      this.ownerBrokerViewMode = false;
      this.crewManagerViewMode = false;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
      this.vslCommViewMode = false;
      this.vslPostnViewMode = false;
      this.regOwnerViewMode = false;
      this.manOwnerViewMode = false;
      this.comOwnerViewMode = false;
      this.techManagerViewMode = false;
      this.ownerBrokerViewMode = false;
      this.crewManagerViewMode = false;
    }
  }

  public vslCommFrmGrp: UntypedFormGroup;
  public vslPostnFrmGrp: UntypedFormGroup;
  public regOwnerFrmGrp: UntypedFormGroup;
  public manOwnerFrmGrp: UntypedFormGroup;
  public comOwnerFrmGrp: UntypedFormGroup;
  public techManagerFrmGrp: UntypedFormGroup;
  public ownerBrokerFrmGrp: UntypedFormGroup;
  public crewManagerFrmGrp: UntypedFormGroup;

  public owners: any = [];
  public commOwners: any = [];
  public brokerOwners: any = [];
  public stakeholderEmails: any = [];
  public manOwnerObj: any;
  public comOwnerObj: any;
  public techManagerObj: any;

  public showFormErrors: boolean = false;
  public isVsCommUpdateAuth: boolean;
  public emailContacts: any;
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public vesselService: VesselsService,
    private vesselDropdowns: VesselDropdownsService,
    private store: Store<any>,
    private storeVessel: Store<VesselCreate.State>,
    public dialog: MatDialog,
    private auth: AuthService,
    private alertService: AlertService
  ) {}

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsCommUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.storeVessel.pipe(select(VesselCreate.getVesselCreateType), take(1)).subscribe((type) => {
      this.isCreateTypeHull = type && type === 'hull-number' ? true : false;
    });

    this.alertService.sharedVesselType.subscribe((res) => {
      if (res) {
        this.vesselType = res === 'VSA' ? false : true;
      }
    });

    this.vesselService.getStakeholderEmailContacts().subscribe((res) => {
      this.emailContacts = res;
    });

    this.values = deepmerge(
      {
        positionMonitoring: emptyCreateVessel.form.positionMonitoring,
      },
      this.values
    );

    this.createVesselCommForm();
    this.vslPostnFrmGrp = this.formBuilder.group(this.createVslPosMontrForm(this.values.positionMonitoring));
    this.createRegOwnerForm();
    this.createMgOwnerForm();
    this.createComsOwnerForm();
    this.createTechMgrForm();
    this.createOwnerBrokerForm();
    this.createCrewMgrForm();
    this.subscribeFormChange();

    this.vesselDropdowns
      .getVesselOwners()
      .pipe(distinctUntilChanged())
      .subscribe((owners) => {
        this.owners = owners ? owners : [];
        this.brokerOwners = owners.filter((o) => o.isBroker === true);
        if (this.owners.length > 0) {
          if (!this.manOwnerObj) {
            const ownPKID = this.values.vslOwnerID
              ? this.values.vslOwnerID
              : this.values.vslMgOwner
              ? this.values.vslMgOwner.ownPKID
              : null;
            const owner = this.owners.filter((ownr) => ownr.ownPKID === ownPKID);
            this.manOwnerObj = owner[0];
          }

          if (!this.techManagerObj) {
            const ownPKID = this.values.vslTechMngID
              ? this.values.vslTechMngID
              : this.values.vslTechMgmt
              ? this.values.vslTechMgmt.ownPKID
              : null;
            const owner = this.owners.filter((ownr) => ownr.ownPKID === ownPKID);
            this.techManagerObj = owner[0];
          }
        }
      });

    this.vesselDropdowns
      .getVesselCommOwners()
      .pipe(distinctUntilChanged())
      .subscribe((owners) => {
        this.commOwners = owners ? owners : [];
        if (!this.comOwnerObj) {
          const ownPKID = this.values.vslCommOwnerID
            ? this.values.vslCommOwnerID
            : this.values.vslComOwner
            ? this.values.vslComOwner.ownPKID
            : null;
          const owner = owners.filter((ownr) => ownr.ownPKID === ownPKID);
          this.comOwnerObj = owner[0];
        }
      });
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (changes.values) {
      this.values = changes.values.currentValue;
    }
  }
  public subscribeFormChange() {
    this.vslCommFrmGrp.valueChanges.pipe(debounceTime(1000)).subscribe((values) => {
      this.vslCommFormData = values;
    });
    this.vslPostnFrmGrp.valueChanges.pipe(debounceTime(1000)).subscribe((values) => {
      this.vslPostnFormData = Object.keys(values).reduce(
        (res, val) => {
          res[0][val] = values[val][0];
          res[1][val] = values[val][1];
          res[2][val] = values[val][2];
          return res;
        },
        [{}, {}, {}]
      );
    });
    this.regOwnerFrmGrp.valueChanges.pipe(debounceTime(1000)).subscribe((values) => {
      this.regOwnerFormData = values;
    });
    this.manOwnerFrmGrp.valueChanges.pipe(debounceTime(350)).subscribe((values) => {
      const owner = this.owners.filter((ownr) => ownr.ownPKID === values['ownPKID']);
      this.manOwnerObj = owner[0];
      this.manOwnerFormData = {
        vslOwnerID: values['ownPKID'],
      };
    });
    this.comOwnerFrmGrp.valueChanges.pipe(debounceTime(350)).subscribe((values) => {
      const owner = this.owners.filter((ownr) => ownr.ownPKID === values['ownPKID']);
      this.comOwnerObj = owner[0];
      this.comOwnerFormData = {
        vslCommOwnerID: values['ownPKID'],
      };
    });
    this.techManagerFrmGrp.valueChanges.pipe(debounceTime(350)).subscribe((values) => {
      const owner = this.owners.filter((ownr) => ownr.ownPKID === values['ownPKID']);
      this.techManagerObj = owner[0];
      this.techManagerFormData = {
        vslTechMngID: values['ownPKID'],
      };
    });
    this.ownerBrokerFrmGrp.valueChanges.pipe(debounceTime(1000)).subscribe((values) => {
      this.ownerBrokerFormData = {
        vslOwnerBrokerID: values['ownPKID'],
      };
    });
    this.crewManagerFrmGrp.valueChanges.pipe(debounceTime(1000)).subscribe((values) => {
      this.crewManagerFormData = {
        vslCrewMngID: values['ownPKID'],
      };
    });
  }

  public getvslEmailContactsDrpdwns(val) {
    this.vslCommFrmGrp.get('vslShEmailIds').setValue(val.vslStakeholderEmailcontacts.shEmailIds);
  }

  public vslCommCancel() {
    this.vslCommViewMode = true;
    this.createVesselCommForm();
  }
  public vslCommSave() {
    if (!this.vslCommFrmGrp.valid) {
      this.showFormErrors = true;
      let requiredError = false;
      Object.keys(this.vslCommFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.vslCommFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    this.vslCommFrmGrp.value.vslSatMTlph =
      this.vslCommFrmGrp.value.vslSatMTlph === '' ? null : this.vslCommFrmGrp.value.vslSatMTlph;
    this.vslCommFrmGrp.value.vslSatMFax =
      this.vslCommFrmGrp.value.vslSatMFax === '' ? null : this.vslCommFrmGrp.value.vslSatMFax;
    this.vslCommFrmGrp.value.vslCelluarMobile =
      this.vslCommFrmGrp.value.vslCelluarMobile === '' ? null : this.vslCommFrmGrp.value.vslCelluarMobile;

    this.showFormErrors = false;

    this.update.emit({
      type: 'UpdateComm',
      value: this.vslCommFrmGrp.value,
    });

    this.vslCommViewMode = true;
  }

  public regOwnerCancel() {
    this.regOwnerViewMode = true;
    this.createRegOwnerForm();
  }
  public regOwnerSave() {
    if (this.regOwnerFrmGrp.invalid) {
      this.showFormErrors = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.regOwnerViewMode = true;
    if (this.regOwnerFrmGrp.dirty && this.regOwnerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateRegOwn',
        value: this.regOwnerFormData,
      });
    }
  }

  public manOwnerCancel() {
    this.manOwnerViewMode = true;
    this.createMgOwnerForm();
  }
  public manOwnerSave() {
    this.manOwnerViewMode = true;
    if (this.manOwnerFrmGrp.dirty && this.manOwnerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateMngOwn',
        value: this.manOwnerFormData,
      });
    }
  }

  public comOwnerCancel() {
    this.comOwnerViewMode = true;
    this.createComsOwnerForm();
  }

  public comOwnerSave() {
    if (this.comOwnerFrmGrp.invalid) {
      this.showFormErrors = true;
      let requiredError = false;
      Object.keys(this.vslCommFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.vslCommFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    this.comOwnerViewMode = true;
    if (this.comOwnerFrmGrp.dirty && this.comOwnerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateComOwn',
        value: this.comOwnerFormData,
      });
    }
  }

  public techManagerCancel() {
    this.techManagerViewMode = true;
    this.createTechMgrForm();
  }
  public techManagerSave() {
    this.techManagerViewMode = true;
    const techMang = this.values.vslTechMngID
      ? this.values.vslTechMngID
      : this.values.vslTechMgmt
      ? this.values.vslTechMgmt.ownPKID
      : null;
    if (this.techManagerFrmGrp.dirty && this.techManagerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateTechOwn',
        value: this.techManagerFormData,
      });
    }
  }

  public crewManagerCancel() {
    this.crewManagerViewMode = true;
    this.createCrewMgrForm();
  }
  public crewManagerSave() {
    this.crewManagerViewMode = true;
    const crewMang = this.values.vslCrewMngID
      ? this.values.vslCrewMngID
      : this.values.vslCrewMgmt
      ? this.values.vslCrewMgmt.ownPKID
      : null;
    if (this.crewManagerFrmGrp.dirty && this.crewManagerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateCrewOwn',
        value: this.crewManagerFormData,
      });
    }
  }

  public ownerBrokerCancel() {
    this.ownerBrokerViewMode = true;
    this.createOwnerBrokerForm();
  }
  public ownerBrokerSave() {
    this.ownerBrokerViewMode = true;
    const broker = this.values.vslOwnerBrokerID
      ? this.values.vslOwnerBrokerID
      : this.values.vslOwnBroker
      ? this.values.vslOwnBroker.ownPKID
      : null;
    if (this.ownerBrokerFrmGrp.dirty && this.ownerBrokerFrmGrp.valid) {
      this.update.emit({
        type: 'UpdateOwnBroker',
        value: this.ownerBrokerFormData,
      });
    }
  }

  public vslPostnCancel() {
    this.vslPostnViewMode = true;
  }
  public vslPostnSave() {
    if (this.vslPostnFrmGrp.invalid) {
      this.showFormErrors = true;
      scrollToError(this.matErrorRefs);
      return;
    }

    this.vslPostnViewMode = true;
    if (this.vslPostnFrmGrp.dirty && this.vslPostnFrmGrp.valid) {
      this.vslPostnFormData.forEach((item) => {
        if (item.vpmSatCTlx === '') {
          item.vpmSatCTlx = null;
        }
      });
      this.update.emit({
        type: 'UpdatePosMon',
        value: {
          positionMonitoring: this.vslPostnFormData,
        },
      });
    }
  }

  public nextStep() {
    if (!this.vslCommFrmGrp.valid && this.vesselType) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.vslCommFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.vslCommFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }

    if ((this.comOwnerFrmGrp.invalid && this.vesselType) || this.regOwnerFrmGrp.invalid) {
      this.showFormErrors = true;
      let requiredError = false;
      Object.keys(this.comOwnerFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.comOwnerFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (!requiredError) {
        Object.keys(this.regOwnerFrmGrp.controls).forEach((key) => {
          const controlErrors: ValidationErrors = this.regOwnerFrmGrp.get(key).errors;
          if (controlErrors != null) {
            for (const error of Object.keys(controlErrors)) {
              if (error === 'required') {
                requiredError = true;
                break;
              }
            }
          }
        });
      }
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }

    let valueObj = this.createPostObj();

    valueObj = {
      ...valueObj,
      ...{
        vslMgOwner: null,
        vslComOwner: null,
        vslTechMgmt: null,
        vslCrewMgmt: null,
        vslOwnBroker: null,
      },
    };

    this.store.dispatch(new VesselCreateActions.PutCommunication(valueObj));
    this.changeStep.emit();
  }

  private createPostObj() {
    return {
      ...this.vslCommFrmGrp.value,
      ...{
        positionMonitoring: reduceArray2Obj(this.vslPostnFrmGrp.value),
      },
      ...this.regOwnerFrmGrp.value,
      vslOwnerID: this.manOwnerFrmGrp.get('ownPKID').value > 0 ? this.manOwnerFrmGrp.get('ownPKID').value : null,
      vslCommOwnerID: this.comOwnerFrmGrp.get('ownPKID').value > 0 ? this.comOwnerFrmGrp.get('ownPKID').value : null,
      ...{
        vslTechMngID:
          this.techManagerFrmGrp.get('ownPKID').value > 0 ? this.techManagerFrmGrp.get('ownPKID').value : null,
        vslTechPersonInCharge: this.techManagerFrmGrp.get('vslTechPersonInCharge').value,
      },
      vslOwnerBrokerID:
        this.ownerBrokerFrmGrp.get('ownPKID').value > 0 ? this.ownerBrokerFrmGrp.get('ownPKID').value : null,
      vslCrewMngID:
        this.crewManagerFrmGrp.get('ownPKID').value > 0 ? this.crewManagerFrmGrp.get('ownPKID').value : null,
    };
  }
  private createVesselCommForm() {
    this.vslCommFrmGrp = this.formBuilder.group({
      vslCallSign: this.formBuilder.control(this.values.vslCallSign, [Validators.maxLength(7)]),
      vslEmail: [this.values.vslEmail ? this.values.vslEmail : null, [Validators.pattern(this.emailPattern)]],
      vslSatMTlph: this.formBuilder.control(this.values.vslSatMTlph !== '' ? this.values.vslSatMTlph : null, [
        Validators.pattern(new RegExp('^[0-9+-]*$')),
        Validators.maxLength(15),
      ]),
      vslSatMFax: this.formBuilder.control(this.values.vslSatMFax ? this.values.vslSatMFax : null, [
        Validators.pattern(new RegExp('^[0-9+-]*$')),
      ]),
      vslCelluarMobile: this.formBuilder.control(this.values.vslCelluarMobile ? this.values.vslCelluarMobile : null, [
        Validators.pattern(new RegExp('^[0-9+-]*$')),
        Validators.maxLength(15),
      ]),
      vslShEmailIds: this.formBuilder.control(this.values.vslShEmailIds),
    });
  }

  private createRegOwnerForm() {
    this.regOwnerFrmGrp = this.formBuilder.group({
      vslHeadOwnerID: this.values.vslHeadOwnerID ? this.values.vslHeadOwnerID : null,
      vslRegOwnAddress: this.values.vslRegOwnAddress ? this.values.vslRegOwnAddress : null,
      vslRegOwnPhone: [
        this.values.vslRegOwnPhone ? this.values.vslRegOwnPhone : null,
        [Validators.pattern(new RegExp('^[0-9+-]*$')), Validators.maxLength(15)],
      ],
      vslRegOwnEmail: [
        this.values.vslRegOwnEmail ? this.values.vslRegOwnEmail : null,
        [Validators.pattern(this.emailPattern)],
      ],
    });
  }

  private createMgOwnerForm() {
    this.manOwnerFrmGrp = this.formBuilder.group({
      ownPKID: this.values.vslOwnerID
        ? this.values.vslOwnerID
        : this.values.vslMgOwner
        ? this.values.vslMgOwner.ownPKID
        : null,
    });
  }

  private createComsOwnerForm() {
    this.comOwnerFrmGrp = this.formBuilder.group({
      ownPKID: this.values.vslComOwner && this.values.vslComOwner.ownPKID > 0 ? this.values.vslComOwner.ownPKID : null,
    });
  }

  private createTechMgrForm() {
    this.techManagerFrmGrp = this.formBuilder.group({
      ownPKID: this.values.vslTechMngID
        ? this.values.vslTechMngID
        : this.values.vslTechMgmt
        ? this.values.vslTechMgmt.ownPKID
        : null,
      vslTechPersonInCharge: this.values.vslTechPersonInCharge,
    });
  }

  private createOwnerBrokerForm() {
    this.ownerBrokerFrmGrp = this.formBuilder.group({
      ownPKID: this.values.vslOwnerBrokerID
        ? this.values.vslOwnerBrokerID
        : this.values.vslOwnBroker
        ? this.values.vslOwnBroker.ownPKID
        : null,
    });
  }

  private createCrewMgrForm() {
    this.crewManagerFrmGrp = this.formBuilder.group({
      ownPKID: this.values.vslCrewMngID
        ? this.values.vslCrewMngID
        : this.values.vslCrewMgmt
        ? this.values.vslCrewMgmt.ownPKID
        : null,
    });
  }

  private createVslPosMontrForm(items): any {
    return {
      vpmPKID: this.formBuilder.array(this.createColArr(items, 'vpmPKID')),
      vpmSatCDetail: this.formBuilder.array(this.createColArr(items, 'vpmSatCDetail')),
      vpmBrand: this.formBuilder.array(this.createColArr(items, 'vpmBrand')),
      vpmModel: this.formBuilder.array(this.createColArr(items, 'vpmModel')),
      vpmISN: this.formBuilder.array(this.createColArr(items, 'vpmISN')),
      vpmSatCTlx: this.formBuilder.array(this.createColArr(items, 'vpmSatCTlx')),
      vpmOrderNo: this.formBuilder.array(this.createColArr(items, 'vpmOrderNo')),
    };
  }

  private createColArr(items, key) {
    const grpArr = [];
    items.forEach((item) => {
      if (item[key] !== '') {
        grpArr.push(this.formBuilder.control(item[key]));
      } else {
        grpArr.push(this.formBuilder.control(null));
      }
    });
    return grpArr;
  }
}
