//

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, filter } from 'rxjs/operators';
import { DropdownContentService } from 'src/app/core/services/dropdown-content/dropdown-content.service';

@Injectable()
export class PaintsService {

  constructor(
    private http: HttpClient,
    private dropdownContent: DropdownContentService
  ) { }

  /**
   * get paints makers
   */
  public getPaintsMaker(): Observable<any> {
    const url = environment.baseUrl + '/api/DictionaryManagement/PaintMakers';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
   * get engine makers
   */
  public getEngineMaker(): Observable<any> {
    const url = environment.baseUrl + '/api/DictionaryManagement/EngineManufacturers';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
   * get paints by maker
   * @param {number} pmID paint maker id
   */
  public getPaintsTypeByMaker(pmID: number): any {
    return this.http.get(environment.baseUrl + '/api/DictionaryManagement/PaintTypeByMaker/' + pmID);
  }
}
