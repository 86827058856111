// schedule overview service
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';

@Component({
  selector: 'champs-schedule-overview-service',
  templateUrl: './schedule-overview-service.component.html',
  styleUrls: ['./schedule-overview-service.component.scss']
})
export class ScheduleOverviewServiceComponent implements OnInit {
  @Input() public serviceRepo: any;
  public serviceDetails: any = null;
  public serviceDetailFrmGrp: UntypedFormGroup;
  public id: any;
  constructor(
    private serviceReopService: ServiceRepositoryService,
    private route: ActivatedRoute
  ) {
    this.id = this.route.parent.snapshot.paramMap.get('id');
  }

  public ngOnInit() {
    this.getServiceDetail();
  }
  private getServiceDetail() {

    this.serviceReopService.getServiceOverview(this.id).subscribe((details: any) => {
      this.serviceDetails = details;
    });

  }
}
