//

import { LOAD_ITEMS, LOAD_ITEMS_SUCCESS, LOAD_ITEMS_FAIL, VesselListActions } from '../actions/vessel-list.actions';

/** @docsNotRequired */
export interface State {
  entities?: Array<any>;
  loaded: boolean;
}

export const initialState: State = {
  entities: null,
  loaded: false,
};

/**
 * Reducer for Vessel List actions
 * @param {State} state - The current state
 * @param {VesselQuestionnaireActions} action - The header content action
 */
export function reducer(state = initialState, action: VesselListActions): State {
  switch (action.type) {

    case LOAD_ITEMS: {
      return {
        ...state
      };
    }

    case LOAD_ITEMS_SUCCESS: {
      return {
        loaded: true,
        entities: action.payload
      };
    }

    case LOAD_ITEMS_FAIL: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
