//
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MY_FORMATS,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
const moment = customMoment;
@Component({
  selector: 'champs-visible-date-range',
  templateUrl: './visible-date-range.component.html',
  styleUrls: ['./visible-date-range.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VisibleDateRangeComponent implements OnInit {
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Output() public set: EventEmitter<any> = new EventEmitter();
  @Input() public fromDate: any;
  @Input() public toDate: any;
  public vissibleFrmGrp: UntypedFormGroup;
  public currentYear: any = new Date().getFullYear();
  public startQutPrevLst: any[] = [];
  public startQutLst: any[] = [];
  public endQutLst: any[] = [];
  public startQutNextLst: any[] = [];
  public endQutPrevLst: any[] = [];
  public endQutNextLst: any[] = [];
  public todayDate: Date = new Date();
  public id: any;
  public prevYear: any[] = [this.currentYear - 2, this.currentYear - 1];
  public nextYear: any[] = [this.currentYear, this.currentYear + 1];
  public invalidStartDate: boolean = false;
  public invalidEndDate: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute) {
    this.id = this.route.parent.snapshot.paramMap.get('id');
    for (let i = 0; i < 2; i++) {
      const temp = <IQuartar>{};
      temp.year = this.prevYear[i];
      temp.quarter = [
        {
          ali: 'Q1',
          date: temp.year + '-04-01' + 'T00:00:00',
        },
        {
          ali: 'Q2',
          date: temp.year + '-07-01' + 'T00:00:00',
        },
        {
          ali: 'Q3',
          date: temp.year + '-10-01' + 'T00:00:00',
        },
        {
          ali: 'Q4',
          date: temp.year + 1 + '-01-01' + 'T00:00:00',
        },
      ];
      this.startQutPrevLst.push(temp);
      const temp1 = <IQuartar>{};
      temp1.year = this.prevYear[i];
      temp1.quarter = [
        {
          ali: 'Q1',
          date: temp.year + '-06-30' + 'T00:00:00',
        },
        {
          ali: 'Q2',
          date: temp.year + '-09-30' + 'T00:00:00',
        },
        {
          ali: 'Q3',
          date: temp.year + '-12-31' + 'T00:00:00',
        },
        {
          ali: 'Q4',
          date: temp.year + 1 + '-03-31' + 'T00:00:00',
        },
      ];
      this.endQutPrevLst.push(temp1);
    }
    for (let i = 0; i < 2; i++) {
      const temp = <IQuartar>{};
      temp.year = this.nextYear[i];
      temp.quarter = [
        {
          ali: 'Q1',
          date: temp.year + '-04-01' + 'T00:00:00',
        },
        {
          ali: 'Q2',
          date: temp.year + '-07-01' + 'T00:00:00',
        },
        {
          ali: 'Q3',
          date: temp.year + '-10-01' + 'T00:00:00',
        },
        {
          ali: 'Q4',
          date: temp.year + 1 + '-01-01' + 'T00:00:00',
        },
      ];
      this.startQutNextLst.push(temp);
      const temp1 = <IQuartar>{};
      temp1.year = this.nextYear[i];
      temp1.quarter = [
        {
          ali: 'Q1',
          date: temp.year + '-06-30' + 'T00:00:00',
        },
        {
          ali: 'Q2',
          date: temp.year + '-09-30' + 'T00:00:00',
        },
        {
          ali: 'Q3',
          date: temp.year + '-12-31' + 'T00:00:00',
        },
        {
          ali: 'Q4',
          date: temp.year + 1 + '-03-31' + 'T00:00:00',
        },
      ];
      this.endQutNextLst.push(temp1);
    }
  }

  public ngOnInit() {
    this.createFormGroup();
  }
  public cancelCreateForm() {
    this.close.emit({
      type: 'close',
    });
  }
  public onStartDate(event) {
    this.vissibleFrmGrp.patchValue({
      customStartDate: event,
    });
    this.validStartDate();
  }
  public onEndDate(event) {
    this.vissibleFrmGrp.patchValue({
      customEndDate: event,
    });
    this.validEndDate();
  }
  public setDate() {
    if (this.vissibleFrmGrp.valid && !this.invalidEndDate && !this.invalidStartDate) {
      const dateObj = {
        from: new DatePipe('en-US').transform(this.vissibleFrmGrp.get('customStartDate').value, 'dd-MMM-yyyy'),
        end: new DatePipe('en-US').transform(this.vissibleFrmGrp.get('customEndDate').value, 'dd-MMM-yyyy'),
      };

      this.set.emit(dateObj);
    }
  }
  public validStartDate() {
    if (
      new Date(this.vissibleFrmGrp.get('customStartDate').value) >=
      new Date(this.vissibleFrmGrp.get('customEndDate').value)
    ) {
      this.invalidStartDate = true;
      this.invalidEndDate = false;
    } else {
      this.invalidStartDate = false;
      this.invalidEndDate = false;
    }
  }
  public validEndDate() {
    if (
      new Date(this.vissibleFrmGrp.get('customEndDate').value) <=
      new Date(this.vissibleFrmGrp.get('customStartDate').value)
    ) {
      this.invalidStartDate = false;
      this.invalidEndDate = true;
    } else {
      this.invalidEndDate = false;
      this.invalidStartDate = false;
    }
  }
  private createFormGroup() {
    this.vissibleFrmGrp = this.formBuilder.group({
      customStartDate: this.fromDate ? this.fromDate : null,
      customEndDate: this.toDate ? this.toDate : null,
    });
  }
}
interface IQuartar {
  year: any;
  quarter: Array<{ ali: any; date: any }>;
}
