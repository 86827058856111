// replace vessel
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewChecked,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Location, DatePipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
@Component({
  selector: 'champs-replace-vessel',
  templateUrl: './replace-vessel.component.html',
  styleUrls: ['./replace-vessel.component.scss'],
})
export class ReplaceVesselComponent implements OnInit, AfterViewChecked {
  private vesselsBuffer = [];
  private params: any = null;
  private filteredArray: any[] = [];
  private firstCalled: boolean = false;
  private bufferSize = 50;
  @ViewChildren('listitem') public listItems: QueryList<MatOption>;
  @ViewChildren('cutlist', { read: ElementRef }) public cutlist: QueryList<ElementRef>;
  public enableSave: boolean = false;
  public viewheaderContent: boolean = false;
  public teuRangeChipString: string;
  public plugsRangeChipString: string;
  public speedRangeChipString: string;
  public loaRangeChipString: string;
  public tonnesRangeChipsString: string;
  public removable: boolean = true;
  public statusFilter: boolean = true;
  public filtersList: FilterList = null;
  public teuList: any[] = [];
  public showCutOption: boolean = false;
  public postObj: any;
  @Input() public serviceid: any;
  @Input() public charter: any;
  @Input() public startDate: any;
  @Input() public endDate: any;
  @Input() public position: any;
  @Input() public proformaId: any;
  @Input() public serviceRepo: any;
  @Input() public scenarioId: any;
  public totPercentage: any;
  public margin: boolean = false;
  public rows: any[] = [];
  public maxDays: any;
  @Input() public requirmentHeader: any;
  @Input() public vessels: any;
  public rowsVessels: any = [1, 2, 3];
  @Input() public vesselList: any;
  public showMinMaxErrorTeu: boolean = false;
  public showMinMaxErrorTonnes: boolean = false;
  public showMinMaxErrorPlugs: boolean = false;
  public showMinMaxErrorSpeed: boolean = false;
  public showMinMaxErrorLoa: boolean = false;
  public vesselNames: any[] = [];
  public scheduleIds: any[] = [];
  @Output() public close: EventEmitter<any> = new EventEmitter();
  public filtersCtrls = {
    vesselMultiCtrl: new UntypedFormControl(),
    statusMultiCtrl: new UntypedFormControl(),
    operatorMultiCtrl: new UntypedFormControl(),
    teuMultiCtrl: {
      fromTeuMultiCtrl: new UntypedFormControl(),
      toTeuMultiCtrl: new UntypedFormControl(),
      teuListMultiCtrl: new UntypedFormControl(),
    },
    plugsMultiCtrl: {
      fromPlugsMultiCtrl: new UntypedFormControl(),
      toPlugsMultiCtrl: new UntypedFormControl(),
    },
    tonnesMultiCtrl: {
      fromTonnesMultiCtrl: new UntypedFormControl(),
      toTonnesMultiCtrl: new UntypedFormControl(),
    },
    speedMultiCtrl: {
      fromSpeedMultiCtrl: new UntypedFormControl(),
      toSpeedMultiCtrl: new UntypedFormControl(),
    },
    loaMultiCtrl: {
      fromLoaMultiCtrl: new UntypedFormControl(),
      toLoaMultiCtrl: new UntypedFormControl(),
    },
  };
  public portValue = new Array<any>();
  public showportValue = new UntypedFormControl();
  public paramList: ParamsList = {
    vessels: [],
    operators: [],
    status: [],
    teuList: [],
    fromteu: '',
    toteu: '',
    fromplugs: '',
    toplugs: '',
    fromtonnes: '',
    totonnes: '',
    fromspeed: '',
    tospeed: '',
    fromloa: '',
    toloa: '',
  };
  public disableUse: boolean = false;
  constructor(
    private scheduleService: ServiceRepositoryService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.route.queryParams
      .pipe(filter((x) => x !== undefined))
      .pipe(take(1))
      .subscribe((params) => {
        this.params = params;
        Object.keys(params).forEach((key) => {
          if (key === 'fromteu' || key === 'toteu') {
            this.paramList[key] = params[key];
          }
          if (key === 'fromplugs' || key === 'toplugs') {
            this.paramList[key] = params[key];
          }
          if (key === 'fromtonnes' || key === 'totonnes') {
            this.paramList[key] = params[key];
          }
          if (key === 'fromspeed' || key === 'tospeed') {
            this.paramList[key] = params[key];
          }
          if (key === 'fromloa' || key === 'toloa') {
            this.paramList[key] = params[key];
          }
          if (key === 'status') {
            this.paramList[key] = params[key].split(',').map((val) => parseInt(val, 10));
          } else {
            this.paramList[key] = params[key].split(',');
          }
        });
      });
  }

  public getValueForCutOffDropdown(value) {
    return (
      new DatePipe('en-US').transform(
        this.calculateArrDep(value.lstArrivalDepartureDto[0], value.lstArrivalDepartureDto[1], 0),
        'dd-MMM-yy'
      ) +
      ' ' +
      value.portName
    );
  }

  public amendPortValue(vesselIndex, serviceIndex, portIndex) {
    this.enableSave = true;
    this.listItems.map((item) => {
      item._getHostElement().classList.remove('background-col');
      item._getHostElement().classList.add('background-col-white');
    });
    this.cutlist.map((item) => {
      item.nativeElement.classList.add('hide');
      item.nativeElement.classList.remove('un-hide');
      return true;
    });
    const length = this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto.length;

    this.postObj = {
      serviceIndex: serviceIndex,
      vesselIndex: vesselIndex,
      proformaHeaderId: this.portValue[vesselIndex].proformaHeaderId,
      cutOffPortDetails: this.portValue[vesselIndex],
      cutOffServiceId: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].serviceId,
      cutOffPosition: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].servicePosition,
      cutOffDeploymentId: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].vesselDeploymentId,
      charterId: this.charter.charterRequestId,
      lstPortDetailsListDto: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto,
      tradeId: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].tradeId,
      vesselDeploymentDto: {
        depPKID: 0,
        depBeginDate: this.startDate,
        depEndDate: this.endDate,
        depStartPortID: this.charter.phaseInPortID,
        depStartPortName: this.charter.phaseInPortName,
        depEndPortID: this.charter.phaseOutPortID,
        depEndPortName: this.charter.phaseOutPortName,
        cutPortStartId: this.portValue[vesselIndex].deploymentPortId,
        cutPortStartName: this.portValue[vesselIndex].deploymentPortName,
        cutPortEndID: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto[length - 1]
          .deploymentPortId,
        cutPortEndName: this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto[length - 1]
          .deploymentPortName,
        cutPortBeginDate: this.calculateArrDep(
          this.portValue[vesselIndex].lstArrivalDepartureDto[0],
          this.portValue[vesselIndex].lstArrivalDepartureDto[1],
          -1
        ),
        cutPortEndDate: this.calculateArrDep(
          this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto[length - 1]
            .lstArrivalDepartureDto[0],
          this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto[length - 1]
            .lstArrivalDepartureDto[1],
          0
        ),
        depReasonCodeID: null,
        depVesselID: this.rows[vesselIndex].vesselID,
        depComments: null,
        depStringID: this.serviceid,
        depPosition: this.position,
        specialSerivceType: null,
        unemployedeType: null,
        layupType: null,
      },
    };
    const ports = this.rows[vesselIndex].lstserviceDetailsDto[serviceIndex].lstPortDetailsListDto;
    this.scheduleIds = [];
    for (let i = portIndex; i < ports.length; i++) {
      if (i === ports.length - 1) {
        this.scheduleIds.push(
          ports[i].lstArrivalDepartureDto[0].movementType === 0
            ? ports[i].lstArrivalDepartureDto[0].scheduleId
            : ports[i].lstArrivalDepartureDto[1].scheduleId
        );
      } else if (+portIndex === i) {
        this.scheduleIds.push(
          ports[i].lstArrivalDepartureDto[0].movementType === -1
            ? ports[i].lstArrivalDepartureDto[0].scheduleId
            : ports[i].lstArrivalDepartureDto[1].scheduleId
        );
      } else {
        this.scheduleIds.push(ports[i].lstArrivalDepartureDto[0].scheduleId);
        this.scheduleIds.push(ports[i].lstArrivalDepartureDto[1].scheduleId);
      }
    }
  }
  public addClass(port, index) {
    this.listItems.map((item, key) => {
      if (key > index) {
        item._getHostElement().classList.remove('background-col-white');
        item._getHostElement().classList.add('background-col');
      }
      return true;
    });
    const i = index + 1;

    this.cutlist.map((item, cutKey) => {
      if ((cutKey = i)) {
        const el = this.cutlist.toArray()[cutKey].nativeElement;
        el.classList.remove('hide');
        el.classList.add('un-hide');
      }
      return true;
    });
  }
  public removeClass() {
    this.listItems.map((item) => {
      item._getHostElement().classList.remove('background-col');
      item._getHostElement().classList.add('background-col-white');
    });
    this.cutlist.map((item) => {
      item.nativeElement.classList.add('hide');
      item.nativeElement.classList.remove('un-hide');
      return true;
    });
  }
  public ngAfterViewChecked() {
    if (this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value && this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value) {
      this.showMinMaxErrorTeu = false;
      if (this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value > this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value) {
        this.showMinMaxErrorTeu = true;
        return;
      } else {
        this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.teuMultiCtrl);
        this.allFiltered();
      });
    }
    if (
      this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.value &&
      this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.value
    ) {
      this.showMinMaxErrorPlugs = false;
      if (
        this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.value >
        this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.value
      ) {
        this.showMinMaxErrorPlugs = true;
        return;
      } else {
        this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.plugsMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.plugsMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.plugsMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.plugsMultiCtrl);
        this.allFiltered();
      });
    }

    if (
      this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.value &&
      this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.value
    ) {
      this.showMinMaxErrorTonnes = false;
      if (
        this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.value >
        this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.value
      ) {
        this.showMinMaxErrorTonnes = true;
        return;
      } else {
        this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.tonnesMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.tonnesMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.tonnesMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.tonnesMultiCtrl);
        this.allFiltered();
      });
    }

    if (
      this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.value &&
      this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.value
    ) {
      this.showMinMaxErrorSpeed = false;
      if (
        this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.value >
        this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.value
      ) {
        this.showMinMaxErrorSpeed = true;
        return;
      } else {
        this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.speedMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.speedMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.speedMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.speedMultiCtrl);
        this.allFiltered();
      });
    }

    if (this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.value && this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.value) {
      this.showMinMaxErrorLoa = false;
      if (
        parseInt(this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.value) >
        parseInt(this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.value)
      ) {
        this.showMinMaxErrorLoa = true;
      } else {
        this.showMinMaxErrorLoa = false;
        this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.loaMultiCtrl);
          this.allFiltered();
        });
        this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.valueChanges.subscribe(() => {
          this.filterDropdownIconUrlSetter(this.filtersCtrls.loaMultiCtrl);
          this.allFiltered();
        });
      }
    } else {
      this.showMinMaxErrorLoa = false;
      this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.loaMultiCtrl);
        this.allFiltered();
      });
      this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.valueChanges.subscribe(() => {
        this.filterDropdownIconUrlSetter(this.filtersCtrls.loaMultiCtrl);
        this.allFiltered();
      });
    }
  }

  public allFiltered() {
    /* chaining the filters */
    this.filteredArray = this.setFilterred(this.vesselList, 'vesselMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'operatorMultiCtrl');
    this.filteredArray = this.setFilterred(this.filteredArray, 'statusMultiCtrl');
    this.filteredArray = this.teuFilter(this.filteredArray, 'teuMultiCtrl');
    this.filteredArray = this.plugsFilter(this.filteredArray, 'plugsMultiCtrl');
    this.filteredArray = this.maxSpeedFilter(this.filteredArray, 'speedMultiCtrl');
    this.filteredArray = this.tonnesFilter(this.filteredArray, 'tonnesMultiCtrl');
    this.filteredArray = this.loaFilter(this.filteredArray, 'loaMultiCtrl');
    this.rows = [...this.filteredArray];
    this.setRequestFilter();
    this.portValue = Array(this.rows.length).fill('');
  }
  public ngOnInit() {
    this.getData();
    this.maxDays = this.calculateDaysFromDates(this.startDate, this.endDate);

    this.scheduleService.disableUse.subscribe((val) => {
      this.disableUse = val;
    });
  }
  public getData() {
    this.getFilters(this.vessels);
    this.rows = this.vessels.vesselList;
    this.vesselNames = this.vessels.vesselList;
    this.vesselsBuffer = this.vesselNames.slice(0, this.bufferSize);
    this.filtersCtrls.vesselMultiCtrl.valueChanges.subscribe(() => {
      if (this.firstCalled) {
        this.allFiltered();
      }
    });
    this.setFiltersFromParam(this.paramList);
    if (this.statusFilter) {
      this.filtersCtrls.statusMultiCtrl.setValue([-2]);
      this.statusFilter = false;
    }
    this.allFiltered();
  }
  public closeForm() {
    this.close.emit({
      type: 'close',
    });
  }
  public calculateArrDep(portObj1, portObj2, type) {
    if (portObj1 && type === portObj1.movementType) {
      return portObj1.portArrDepDate;
    }
    if (portObj2 && type === portObj2.movementType) {
      return portObj2.portArrDepDate;
    }
  }

  public getmargin(service, k) {
    if (k === 0 && new Date(service.serviceStartDate) < new Date(this.startDate)) {
      return 0;
    } else if (k === 0) {
      return '16px';
    }
  }
  public getBorderColor(service) {
    if (+service.servicePosition === -1) {
      return 'rgba(242, 242, 242, 1)';
    } else if (+service.servicePosition === 0) {
      return '#888888';
    } else if (service.serviceName === 'Docking') {
      return 'repeating-linear-gradient(140deg, #eaeaea 16px,  #dadada 12px, #eaeaea 0,#dadada  24px)';
    } else {
      return '#3486b5';
    }
  }
  public getWidth(service, index, k) {
    const days = this.calculateDaysFromDates(service.serviceStartDate, service.serviceEndDate);
    let percentage = null;
    if (
      new Date(service.serviceStartDate) > new Date(this.startDate) &&
      new Date(service.serviceEndDate) > new Date(this.endDate)
    ) {
      const aheadDaysStart = this.calculateDaysFromDates(service.serviceStartDate, this.startDate);
      const aheadDaysEnd = this.calculateDaysFromDates(this.endDate, service.serviceEndDate);
      percentage = ((days - (aheadDaysStart + aheadDaysEnd)) / +this.maxDays) * 100;
      percentage = Math.floor(percentage);
    } else if (new Date(service.serviceStartDate) < new Date(this.startDate)) {
      const aheadDays = this.calculateDaysFromDates(service.serviceStartDate, this.startDate);
      percentage = ((days - aheadDays) / this.maxDays) * 100;
      percentage = Math.floor(percentage);
    } else if (new Date(service.serviceEndDate) > new Date(this.endDate)) {
      const aheadDays = this.calculateDaysFromDates(this.endDate, service.serviceEndDate);
      percentage = ((days - aheadDays) / +this.maxDays) * 100;
      percentage = Math.floor(percentage);
    } else {
      percentage = (days / this.maxDays) * 100;
      percentage = Math.floor(percentage);
    }
    this.totPercentage = this.totPercentage + percentage;
    if (k === 0 && new Date(service.serviceStartDate) < new Date(this.startDate)) {
      this.margin = true;
    }
    if (this.rows[index].lstserviceDetailsDto.length === k + 1) {
      if (this.margin) {
        if (new Date(service.serviceEndDate) > new Date(this.endDate)) {
          percentage = percentage - (this.totPercentage - 95);
          this.totPercentage = 0;
        } else if (new Date(service.serviceEndDate).getTime() === new Date(this.endDate).getTime()) {
          percentage = percentage - (this.totPercentage - 92);
        }
      } else {
        if (new Date(service.serviceEndDate) > new Date(this.endDate)) {
          percentage = percentage - (this.totPercentage - 100);
          this.totPercentage = 0;
        } else if (new Date(service.serviceEndDate).getTime() === new Date(this.endDate).getTime()) {
          percentage = percentage - (this.totPercentage - 97);
        }
      }
      this.totPercentage = 0;
      this.margin = true;
    }
    return percentage + '%';
  }

  public valueChanges(ctrl) {
    this.filterDropdownIconUrlSetter(this.filtersCtrls[ctrl]);
    if (this.firstCalled) {
      this.allFiltered();
    }
  }
  public filterDropdownIconUrlSetter(filterControl): string {
    if (!(filterControl instanceof UntypedFormControl)) {
      if (
        (filterControl.fromTeuMultiCtrl && filterControl.fromTeuMultiCtrl.value) ||
        (filterControl.toTeuMultiCtrl && filterControl.toTeuMultiCtrl.value) ||
        (filterControl.teuListMultiCtrl &&
          filterControl.teuListMultiCtrl.value &&
          filterControl.teuListMultiCtrl.value.length)
      ) {
        return 'assets/icons/tick.svg';
      } else if (
        (filterControl.fromTonnesMultiCtrl && filterControl.fromTonnesMultiCtrl.value) ||
        (filterControl.toTonnesMultiCtrl && filterControl.toTonnesMultiCtrl.value)
      ) {
        return 'assets/icons/tick.svg';
      } else if (
        (filterControl.fromPlugsMultiCtrl && filterControl.fromPlugsMultiCtrl.value) ||
        (filterControl.toPlugsMultiCtrl && filterControl.toPlugsMultiCtrl.value)
      ) {
        return 'assets/icons/tick.svg';
      } else if (
        (filterControl.fromSpeedMultiCtrl && filterControl.fromSpeedMultiCtrl.value) ||
        (filterControl.toSpeedMultiCtrl && filterControl.toSpeedMultiCtrl.value)
      ) {
        return 'assets/icons/tick.svg';
      } else if (
        (filterControl.fromLoaMultiCtrl && filterControl.fromLoaMultiCtrl.value) ||
        (filterControl.toLoaMultiCtrl && filterControl.toLoaMultiCtrl.value)
      ) {
        return 'assets/icons/tick.svg';
      } else {
        return 'assets/icons/arrow-dropdown.svg';
      }
    }
    if (filterControl.value && filterControl.value.length > 0) {
      return 'assets/icons/tick.svg';
    } else {
      return 'assets/icons/arrow-dropdown.svg';
    }
  }

  public useVessel(vesselIndex) {
    this.scheduleService.disableUse.next(true);
    if (this.postObj && vesselIndex === this.postObj.vesselIndex) {
      const deleteArray = [];
      const directDummy = [];
      for (let i = this.postObj.serviceIndex + 1; i < this.rows[vesselIndex].lstserviceDetailsDto.length; i++) {
        deleteArray.push(this.rows[vesselIndex].lstserviceDetailsDto[i]);
        if (
          !(
            this.rows[vesselIndex].lstserviceDetailsDto[i].servicePosition === 0 ||
            this.rows[vesselIndex].lstserviceDetailsDto[i].servicePosition === -1 ||
            this.rows[vesselIndex].lstserviceDetailsDto[i].serviceName === 'Docking'
          )
        ) {
          directDummy.push(this.rows[vesselIndex].lstserviceDetailsDto[i]);
        }
      }
      this.serviceRepo
        ? this.close.emit({
            type: 'use',
            value: this.postObj,
            delete: deleteArray,
            scheduleIds: this.scheduleIds,
            directDummy: directDummy,
            charter: this.charter,
            proformaHeaderId: this.postObj.proformaHeaderId,
          })
        : this.close.emit({
            type: 'use',
            value: { cutoffAndConvertToDummyDto: this.postObj, scenarioId: this.scenarioId },
            delete: deleteArray,
            scheduleIds: this.scheduleIds,
            directDummy: directDummy,
            charter: this.charter,
            proformaHeaderId: this.postObj.proformaHeaderId,
          });
    } else if (this.rows[vesselIndex].lstserviceDetailsDto.length === 0) {
      this.close.emit({
        type: 'usedirect',
        createVesselObj: this.createVesselObj(vesselIndex),
      });
    } else {
      const deleteArray = [];
      const directDummy = [];
      for (let i = this.postObj.serviceIndex + 1; i < this.rows[vesselIndex].lstserviceDetailsDto.length; i++) {
        deleteArray.push(this.rows[vesselIndex].lstserviceDetailsDto[i]);
        if (
          !(
            this.rows[vesselIndex].lstserviceDetailsDto[i].servicePosition === 0 ||
            this.rows[vesselIndex].lstserviceDetailsDto[i].servicePosition === -1 ||
            this.rows[vesselIndex].lstserviceDetailsDto[i].serviceName === 'Docking'
          )
        ) {
          directDummy.push(this.rows[vesselIndex].lstserviceDetailsDto[i]);
        }
      }
      this.serviceRepo
        ? this.close.emit({
            type: 'useIdle',
            delete: deleteArray,
            proformaId: this.proformaId,
            directDummy: directDummy,
            charter: this.charter,
            proformaHeaderId: this.charter.charterProformaHeaderId,
          })
        : this.close.emit({
            type: 'useIdle',
            delete: deleteArray,
            scenarioId: this.scenarioId,
            proformaId: this.proformaId,
            directDummy: directDummy,
            charter: this.charter,
            proformaHeaderId: this.charter.charterProformaHeaderId,
          });
    }
    this.portValue.fill('');
  }

  public remove(fltr: any, ctrl: string = null): void {
    /* remove all filters chip */
    if (fltr === 'all') {
      this.filtersCtrls.vesselMultiCtrl.setValue([]);
      this.filtersCtrls.operatorMultiCtrl.setValue([]);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.teuListMultiCtrl.setValue(null);
      this.filtersCtrls.statusMultiCtrl.setValue([]);
      this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.setValue(null);
      this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.setValue(null);
      this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.setValue(null);
      this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.setValue(null);
      this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.setValue(null);
      this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.setValue(null);
      this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.setValue(null);
      this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.setValue(null);
      this.filteredArray = [];
      this.allFiltered();
      return;
    }

    if (fltr === 'teu') {
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(null);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(null);
      this.allFiltered();
      return;
    }
    if (fltr === 'plugs') {
      this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.setValue(null);
      this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }
    if (fltr === 'tonnes') {
      this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.setValue(null);
      this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }
    if (fltr === 'speed') {
      this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.setValue(null);
      this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }
    if (fltr === 'loa') {
      this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.setValue(null);
      this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.setValue(null);

      this.allFiltered();
      return;
    }
    if (ctrl === 'teuMultiCtrl') {
      const removeIndex = this.filtersCtrls[ctrl].teuListMultiCtrl.value
        ? this.filtersCtrls[ctrl].teuListMultiCtrl.value.indexOf(fltr)
        : -1;

      if (removeIndex >= 0) {
        this.filtersCtrls[ctrl].teuListMultiCtrl.value.splice(removeIndex, 1);
        this.filtersCtrls[ctrl].teuListMultiCtrl.setValue(this.filtersCtrls[ctrl].teuListMultiCtrl.value);
      }
      this.allFiltered();
      return;
    }

    const removeFilterIndex = this.filtersCtrls[ctrl].value ? this.filtersCtrls[ctrl].value.indexOf(fltr) : -1;
    if (removeFilterIndex >= 0) {
      this.filtersCtrls[ctrl].value.splice(removeFilterIndex, 1);
      this.filtersCtrls[ctrl].setValue(this.filtersCtrls[ctrl].value);
    }
    this.allFiltered();
  }

  public portToShow(port) {
    const date = this.calculateArrDep(port.lstArrivalDepartureDto[0], port.lstArrivalDepartureDto[1], 0);
    if (Date.parse(date) > Date.parse(this.endDate)) {
      return false;
    }
    return true;
  }

  public getTransitTime(ports) {
    const length = ports.length;
    if (ports.length !== 0) {
      return ports[length - 1].transitTime;
    } else {
      return null;
    }
  }
  private createVesselObj(vesselIndex) {
    if (this.serviceRepo) {
      const postObj = {
        charterId: this.charter.charterRequestId,
        vesselDeploymentCreateOrUpdateDto: {
          depPKID: 0,
          depStartPortID: this.charter.phaseInPortID,
          depStartPortName: this.charter.phaseInPortName,
          depEndPortID: this.charter.phaseOutPortID,
          depEndPortName: this.charter.phaseOutPortName,
          depReasonCodeID: null,
          depVesselID: this.rows[vesselIndex].vesselID,
          depBeginDate: this.charter.fromDate,
          depEndDate: this.charter.toDate,
          depComments: null,
          depStringID: this.serviceid,
          depPosition: this.position,
          specialSerivceType: null,
          unemployedeType: null,
          layupType: null,
        },
      };
      return postObj;
    } else {
      const postObj = {
        scenarioId: this.scenarioId,
        deploymentDto: {
          charterId: this.charter.charterRequestId,
          vesselDeploymentCreateOrUpdateDto: {
            depPKID: 0,
            depStartPortID: this.charter.phaseInPortID,
            depStartPortName: this.charter.phaseInPortName,
            depEndPortID: this.charter.phaseOutPortID,
            depEndPortName: this.charter.phaseOutPortName,
            depReasonCodeID: null,
            depVesselID: this.rows[vesselIndex].vesselID,
            depBeginDate: this.charter.fromDate,
            depEndDate: this.charter.toDate,
            depComments: null,
            depStringID: this.serviceid,
            depPosition: this.position,
            specialSerivceType: null,
            unemployedeType: null,
            layupType: null,
          },
        },
      };
      return postObj;
    }
  }
  private calculateDaysFromDates(startDate, endDate) {
    if (startDate && endDate) {
      const delta = Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) / 1000;
      // calculate (and subtract) whole days
      return Math.round(delta / 86400);
    }
  }

  private setFilterred(vesselToBeFiltered, ctrl) {
    let key = '';
    let key1 = '';
    switch (ctrl) {
      case 'vesselMultiCtrl':
        key = 'vesselName';
        key1 = 'vesselCode';
        break;
      case 'operatorMultiCtrl':
        key = 'vesselOperator';
        break;
      case 'statusMultiCtrl':
        key = 'vesselDeployStatus';
        break;
      default:
        key = '';
        break;
    }
    if (this.filtersCtrls[ctrl].value && this.filtersCtrls[ctrl].value.length > 0) {
      if (key !== '' && key1 !== '') {
        return vesselToBeFiltered.filter(
          (vessel) =>
            this.filtersCtrls[ctrl].value.indexOf(vessel[key]) > -1 ||
            this.filtersCtrls[ctrl].value.indexOf(vessel[key1]) > -1
        );
      } else {
        if (key === 'vesselDeployStatus' && this.filtersCtrls[ctrl].value.indexOf(1) > -1) {
          return vesselToBeFiltered;
        } else {
          return vesselToBeFiltered.filter((vessel) => this.filtersCtrls[ctrl].value.indexOf(vessel[key]) > -1);
        }
      }
    }
    return vesselToBeFiltered;
  }

  /** */
  private setRequestFilter() {
    this.paramList = Object.assign(this.paramList, {
      vessels: this.filtersCtrls.vesselMultiCtrl.value,
      operators: this.filtersCtrls.operatorMultiCtrl.value,
      status: this.filtersCtrls.statusMultiCtrl.value,
      teuList: this.filtersCtrls.teuMultiCtrl.teuListMultiCtrl.value,
      fromteu: this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.value,
      toteu: this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.value,
      fromplugs: this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.value,
      toplugs: this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.value,
      fromtonnes: this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.value,
      totonnes: this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.value,
      fromspeed: this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.value,
      tospeed: this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.value,
      fromloa: this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.value,
      toloa: this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.value,
    });
  }
  private teuFilter(vesselsToBeFiltered, ctrl) {
    if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value && this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString =
        this.filtersCtrls[ctrl].fromTeuMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          +vessel.vesselTeuNominal >= +this.filtersCtrls[ctrl].fromTeuMultiCtrl.value &&
          +vessel.vesselTeuNominal <= +this.filtersCtrls[ctrl].toTeuMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toTeuMultiCtrl.value) {
      this.teuRangeChipString = '<= ' + this.filtersCtrls[ctrl].toTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => +vessel.vesselTeuNominal <= +this.filtersCtrls[ctrl].toTeuMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromTeuMultiCtrl.value) {
      this.teuRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromTeuMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => +vessel.vesselTeuNominal >= +this.filtersCtrls[ctrl].fromTeuMultiCtrl.value
      );
    } else if (
      this.filtersCtrls[ctrl].teuListMultiCtrl.value &&
      this.filtersCtrls[ctrl].teuListMultiCtrl.value.length > 0
    ) {
      const filteredVessels = [];
      this.filtersCtrls[ctrl].teuListMultiCtrl.value.forEach((element) => {
        const teuValue = element.split(' - ');
        const obj = {
          from: teuValue[0],
          to: teuValue[1],
        };
        vesselsToBeFiltered
          .filter((vessel) => vessel.vesselTeuNominal >= +obj.from && vessel.vesselTeuNominal <= +obj.to)
          .forEach((el) => {
            filteredVessels.push(el);
          });
      });
      return filteredVessels;
    } else {
      this.teuRangeChipString = null;
      return vesselsToBeFiltered;
    }
  }
  private tonnesFilter(vesselsToBeFiltered, ctrl) {
    if (this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value && this.filtersCtrls[ctrl].toTonnesMultiCtrl.value) {
      this.tonnesRangeChipsString =
        this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toTonnesMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          +vessel.vesselMTcapacity >= +this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value &&
          +vessel.vesselMTcapacity <= +this.filtersCtrls[ctrl].toTonnesMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toTonnesMultiCtrl.value) {
      this.tonnesRangeChipsString = '<= ' + this.filtersCtrls[ctrl].toTonnesMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => +vessel.vesselMTcapacity <= +this.filtersCtrls[ctrl].toTonnesMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value) {
      this.tonnesRangeChipsString = '>= ' + this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => +vessel.vesselMTcapacity >= +this.filtersCtrls[ctrl].fromTonnesMultiCtrl.value
      );
    } else {
      this.tonnesRangeChipsString = null;
      return vesselsToBeFiltered;
    }
  }
  private plugsFilter(vesselsToBeFiltered, ctrl) {
    if (this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value && this.filtersCtrls[ctrl].toPlugsMultiCtrl.value) {
      this.plugsRangeChipString =
        this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toPlugsMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          vessel.vesselPlugs >= this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value &&
          vessel.vesselPlugs <= this.filtersCtrls[ctrl].toPlugsMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toPlugsMultiCtrl.value) {
      this.plugsRangeChipString = '<= ' + this.filtersCtrls[ctrl].toPlugsMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vesselPlugs <= this.filtersCtrls[ctrl].toPlugsMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value) {
      this.plugsRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vesselPlugs >= this.filtersCtrls[ctrl].fromPlugsMultiCtrl.value
      );
    } else {
      this.plugsRangeChipString = null;
      return vesselsToBeFiltered;
    }
  }
  private maxSpeedFilter(vesselsToBeFiltered, ctrl) {

    if (this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value && this.filtersCtrls[ctrl].toSpeedMultiCtrl.value) {
      this.speedRangeChipString =
        this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toSpeedMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          vessel.vesselMaxSpeed >= this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value &&
          vessel.vesselMaxSpeed <= this.filtersCtrls[ctrl].toSpeedMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toSpeedMultiCtrl.value) {
      this.speedRangeChipString = '<= ' + this.filtersCtrls[ctrl].toSpeedMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vesselMaxSpeed <= this.filtersCtrls[ctrl].toSpeedMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value) {
      this.speedRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) => vessel.vesselMaxSpeed >= this.filtersCtrls[ctrl].fromSpeedMultiCtrl.value
      );
    } else {
      this.speedRangeChipString = null;
      return vesselsToBeFiltered;
    }
  }
  private loaFilter(vesselsToBeFiltered, ctrl) {

    if (this.filtersCtrls[ctrl].fromLoaMultiCtrl.value && this.filtersCtrls[ctrl].toLoaMultiCtrl.value) {
      this.loaRangeChipString =
        this.filtersCtrls[ctrl].fromLoaMultiCtrl.value + ' - ' + this.filtersCtrls[ctrl].toLoaMultiCtrl.value;
      return vesselsToBeFiltered.filter(
        (vessel) =>
          vessel.vesselLOA >= this.filtersCtrls[ctrl].fromLoaMultiCtrl.value &&
          vessel.vesselLOA <= this.filtersCtrls[ctrl].toLoaMultiCtrl.value
      );
    } else if (this.filtersCtrls[ctrl].toLoaMultiCtrl.value) {
      this.loaRangeChipString = '<= ' + this.filtersCtrls[ctrl].toLoaMultiCtrl.value;
      return vesselsToBeFiltered.filter((vessel) => vessel.vesselLOA <= this.filtersCtrls[ctrl].toLoaMultiCtrl.value);
    } else if (this.filtersCtrls[ctrl].fromLoaMultiCtrl.value) {
      this.loaRangeChipString = '>= ' + this.filtersCtrls[ctrl].fromLoaMultiCtrl.value;
      return vesselsToBeFiltered.filter((vessel) => vessel.vesselLOA >= this.filtersCtrls[ctrl].fromLoaMultiCtrl.value);
    } else {
      this.loaRangeChipString = null;
      return vesselsToBeFiltered;
    }
  }

  private setFiltersFromParam(paramList: ParamsList) {
    if (!this.firstCalled) {
      const statusList = paramList.status.map((i) => +i);
      this.filtersCtrls.vesselMultiCtrl.setValue(paramList.vessels || null);
      this.filtersCtrls.operatorMultiCtrl.setValue(paramList.operators || null);
      this.filtersCtrls.statusMultiCtrl.setValue(statusList || [-1]);
      this.filtersCtrls.teuMultiCtrl.teuListMultiCtrl.setValue(paramList.teuList);
      this.filtersCtrls.teuMultiCtrl.fromTeuMultiCtrl.setValue(paramList.fromteu);
      this.filtersCtrls.teuMultiCtrl.toTeuMultiCtrl.setValue(paramList.toteu);
      this.filtersCtrls.plugsMultiCtrl.fromPlugsMultiCtrl.setValue(paramList.fromplugs);
      this.filtersCtrls.plugsMultiCtrl.toPlugsMultiCtrl.setValue(paramList.toplugs);
      this.filtersCtrls.speedMultiCtrl.fromSpeedMultiCtrl.setValue(paramList.fromspeed);
      this.filtersCtrls.speedMultiCtrl.toSpeedMultiCtrl.setValue(paramList.tospeed);
      this.filtersCtrls.tonnesMultiCtrl.fromTonnesMultiCtrl.setValue(paramList.fromtonnes);
      this.filtersCtrls.loaMultiCtrl.fromLoaMultiCtrl.setValue(paramList.fromloa);
      this.filtersCtrls.loaMultiCtrl.toLoaMultiCtrl.setValue(paramList.toloa);
      this.filtersCtrls.tonnesMultiCtrl.toTonnesMultiCtrl.setValue(paramList.totonnes);
    }
    this.firstCalled = true;
  }

  private getFilters(vessels) {
    if (!this.filtersList) {
      this.filtersList = {
        vessels: vessels.vesselCombo.sort(),
        operators: vessels.operators.sort(),
        teu: vessels.teu.map((item) => item.vesselTeuFrom + '  -  ' + item.vesselTeuTo),
      };
    }
  }
}
interface ParamsList {
  vessels?: Array<string>;
  operators?: Array<string>;
  fromteu?: string;
  toteu?: string;
  teuList?: Array<any>;
  fromplugs?: string;
  toplugs?: string;
  fromtonnes?: string;
  totonnes?: string;
  fromspeed?: string;
  tospeed?: string;
  fromloa?: string;
  toloa?: string;
  status?: Array<string>;
}

interface FilterList {
  vessels: Array<string>;
  operators: Array<string>;
  teu?: Array<string>;
  tonnes?: Array<string>;
  speed?: Array<string>;
  loa?: Array<string>;
  plugs?: Array<string>;
  status?: Array<string>;
}

interface Teu {
  fromteu?: string;
  toteu?: string;
}
