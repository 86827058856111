// Angular
import { Component, OnInit } from '@angular/core';

// Services
import { ImageModalService } from '@cham-services/image-modal/image-modal.service';

@Component({
  selector: 'champs-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  public hideModal = true;
  public modalImage: string;

  constructor(private modalService: ImageModalService) { }

  public ngOnInit() {
    this.modalService.getImage().subscribe((img) => {
      this.hideModal = img ? false : true;
      this.modalImage = img;
    });
  }

  public closeModal() {
    this.hideModal = true;
  }
}
