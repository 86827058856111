//
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// import { AdalService } from 'adal-angular4';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'champs-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  public redirectUrl: any;
  constructor(
    private router: Router,
    // private adal: AdalService,
    private msal: MsalService,
    private _zone: NgZone, 
  ) { }

  public ngOnInit() {
    this.msal.instance.handleRedirectPromise();
    this.redirectUrl = localStorage.getItem('redirectUrl');

    setTimeout(() => {
      this._zone.run(
        () => {
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          } else {
            this.router.navigate(['/']);
          }
        }
      );
    }, 2000);

  }

}
