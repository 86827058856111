// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import "@maersk-global/mds-components-core/mc-icon";
@Component({
  selector: 'champs-service-view-detail',
  templateUrl: './service-view-detail.component.html',
  styleUrls: ['./service-view-detail.component.scss']
})
export class ServiceViewDetailComponent implements OnInit {
  @Input() public detail: any;
  @Input() public serviceViewData:any;
  @Output() public toggleDetail: EventEmitter<any> = new EventEmitter();
public avgCII :any;
public avgFleetRisk:any;
public blankRowsCount:any;
public perFixed13wk:any;
public perML13wk:any;
public perScrubber:any;
public serviceName:any;
public tradeRoute:any;
public portCode: any

  constructor() { }

  public ngOnInit(): void {
    this.fetchServiceDetails();
  }

  public fetchServiceDetails() {
    const serviceCode = this.detail.serviceCode;
    var result = Object.entries(this.serviceViewData.avgCII13wk);
    for(let i=0;i<result.length;i++){
      if(result[i][0]==serviceCode){
       this.avgCII = result[i][1];
      }
    }

    var result1 = Object.entries(this.serviceViewData.avgFleetRisk);
    for(let i=0;i<result1.length;i++){
      if(result1[i][0]==serviceCode){
       this.avgFleetRisk = result1[i][1];
      }
    }


    var result2 = Object.entries(this.serviceViewData.blankRowsCount);
    for(let i=0;i<result2.length;i++){
      if(result2[i][0]==serviceCode){
       this.blankRowsCount = result2[i][1];
      }
    }

    var result3 = Object.entries(this.serviceViewData.perFixed13wk);
    for(let i=0;i<result3.length;i++){
      if(result3[i][0]==serviceCode){
       this.perFixed13wk = result3[i][1];
      }
    }

    var result4 = Object.entries(this.serviceViewData.perML13wk);
    for(let i=0;i<result4.length;i++){
      if(result4[i][0]==serviceCode){
       this.perML13wk = result4[i][1];
      }
    }

    var result5 = Object.entries(this.serviceViewData.perScrubber);
    for(let i=0;i<result5.length;i++){
      if(result5[i][0]==serviceCode){
       this.perScrubber = result5[i][1];
      }
    }

    var result6 = Object.entries(this.serviceViewData.serviceName);
    for(let i=0;i<result6.length;i++){
      if(result6[i][0]==serviceCode){
       this.serviceName = result6[i][1];
      }
    }

    var result7 = Object.entries(this.serviceViewData.tradeRoute);
    for(let i=0;i<result7.length;i++){
      if(result7[i][0]==serviceCode){
       this.tradeRoute = result7[i][1];
      }
    }

    var result8 = Object.entries(this.serviceViewData.portCodes);
    for(let i=0;i<result8.length;i++){
      if(result8[i][0]==serviceCode){
       this.portCode = result8[i][1];
      }
    }
  }
}
