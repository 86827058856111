//

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VesselCreateActions, VesselCreateActionTypes } from '../actions/vessel-create.actions';
import { deepmerge } from 'src/app/utils/deep-merge';

/** @docsNotRequired */
export interface State {
  form: IVesselCreate;
  formObj: {
    generalInformation: any;
    vesselSpecification: any;
    machineryPerticulars: any;
    speedAndConsumption: any;
    communication: any;
    cargoCapLayout: any;
    plansDocCert: any;
    additionalInfo: any;
  };
  createType?: string;
}

export const emptyCreateVessel: State = {
  form: {
    vesselCode: null,
    vslPKID: 0,
    vslVesselMasterID: null,
    vslVesselCategory: null,
    vmVesselName: null,
    vmMONumber: null,
    vslOfficialNo: null,
    vslFlag: 0,
    vslHomePort: 0,
    vslCountryReg: 0,
    vslMaidenName: null,
    vslExName: null,
    vslSisterName: null,
    vslBuildCountry: 0,
    vslBuildYardID: 0,
    vslhullno: null,
    vslBuildYear: null,
    vslClassID: 0,
    vslIceClassID: 0,
    vslPIClub: null,
    vslHMValue: null,
    vslTotalLoss: null,
    vslOSHAFitted: null,
    lmdPanama: null,
    vslSludgeTime: null,
    vslSludgeCost: null,
    lmdITF: null,

    vslLOA: null,
    vslLBP: null,
    vslBeam: null,
    vslDesignDraft: null,
    vslTropDraught: null,
    vslDraft: null,
    vslDWT: null,
    vsgTropDeadWeight: null,
    vsgDesignDeadWeight: null,
    vsgLightShipWeight: null,
    vsgBlockCoefficient: null,
    vsgDeplacement: null,
    vslMaxAirDraft: null,
    vsgFreshWaterAllow: null,

    vslGT: null,
    vslNT: null,
    vslSuezT: null,
    vslSuezTNet: null,
    vslPanamaGT: null,
    vslPanamaNT: null,

    vslFBArea: null,
    vslVSArea: null,
    vslLastDryDockDate: null,
    vslNextDryDockDate: null,
    vslLastHullCleanDate: null,
    vslLastPropPolishDate: null,
    vslLastWaterInspDate: null,
    VerticalSurfacePrepDropDown1: null,
    VerticalSurfacePrepDropDown2: null,
    FlatSurfacePrepDropDown1: null,
    FlatSurfacePrepDropDown2: null,
    vslAntiFouling: null,
    vslPaintMaker: null,
    pmProducerName: null,
    vslPaintType: null,
    ptProductName: null,

    vslBallastWaterCap: null,
    vslFreshWaterCap: null,
    vslHFOCapacity: null,
    vslMGOCapacity: null,
    vslUltraLowSulphurTankCap: null,
    vslHFOReceivingCap: null,
    vslMGOReceivingCap: null,
    vslULSFOReceivingCap: null,
    vslMaxFillLimit: null,

    vslEngineFuel: null,
    vslAuxFuel: null,
    vslisUniFiel: null,
    vslfsyID: null,
    fsyYear: null,
    vslSuitforULSFuel: null,
    vslFullySeparateFSFlag: null,
    vslULSFuel: null,
    vslDistillateFuel: null,
    feuType: null,

    vslMainEngCount: null,
    vslMainEngineMaker: null,
    vslMainEngineType: null,
    vslMainEngStroke: null,
    vslMainEngOutput: null,
    vslMainEngRPM: null,
    vslMainEngCSR: null,
    vslMCR: null,
    vslMaxSpeed: null,
    vslMinSpeed: null,
    vslMainEngExMCR: null,

    shopTrial: [
      {
        vstpkID: null,
        vstOrderNo: 1,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '25% MCR',
      },
      {
        vstpkID: null,
        vstOrderNo: 2,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '50% MCR',
      },
      {
        vstpkID: null,
        vstOrderNo: 3,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '75% MCR',
      },
      {
        vstpkID: null,
        vstOrderNo: 4,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '85% MCR',
      },
      {
        vstpkID: null,
        vstOrderNo: 5,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '90% MCR',
      },
      {
        vstpkID: null,
        vstOrderNo: 6,
        vstPower: null,
        vstRPM: null,
        vstPMax: null,
        vstPComp: null,
        vstSFOC: null,
        vstTCRPM: null,
        vstAirFilter: null,
        vstAirCooler: null,
        vstEPATC: null,
        vstSATD: null,
        vstSAP: null,
        vstBIT: null,
        vstExhT: null,
        vstBP: null,
        vstTIP: null,
        vstTIT: null,
        vstTOT: null,
        vstLCV: null,
        vstLoadDetails: '100% MCR',
      },
    ],

    vslTCsuction: null,
    vslTorMeterInstalled: null,
    vslAvgPowerAbility: null,
    vslTorMeterBwMotorPropeller: null,
    vslTorMeterBwMotorMainEng: null,
    vslAuxBlowerRating: null,
    vslAuxBlowerCount: null,
    vslAuxBlowerMCR: null,
    vslMLP: null,
    vslMEPMax: null,
    vslPmaxLimit: null,
    vslESP: null,
    vslNominalMaxRPM: null,
    vslTurboChargerType: null,
    vslTurboChargerCount: null,
    vslTCMaxRPM: null,

    vslTCCOMode: null,
    turboChargerCutOut: [
      {
        vccopkID: null,
        vccoOrderNo: 1,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '25% MCR',
      },
      {
        vccopkID: null,
        vccoOrderNo: 2,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '50% MCR',
      },
      {
        vccopkID: null,
        vccoOrderNo: 3,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '75% MCR',
      },
      {
        vccopkID: null,
        vccoOrderNo: 4,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '85% MCR',
      },
      {
        vccopkID: null,
        vccoOrderNo: 5,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '90% MCR',
      },
      {
        vccopkID: null,
        vccoOrderNo: 6,
        vccoengineRPM: null,
        vccoPMax: null,
        vccoPComp: null,
        vccoSFOC: null,
        vccoTCRPM: null,
        vccoAirFilter: null,
        vccoAirCooler: null,
        vccoEPATC: null,
        vccoSATD: null,
        vccoSAP: null,
        vccoBIT: null,
        vccoExhT: null,
        vccoBP: null,
        vccoLCV: null,
        vccoLoadDetails: '100% MCR',
      },
    ],

    vslPropellerType: null,
    vslPropellerDiameter: null,
    vslPropellerPitch: null,

    vslAuxEstLoadAtSea: null,
    vslAuxEstLoadAtPort: null,
    vslAuxEstLoadDurManouvering: null,

    auxEngine: [
      {
        vaePKID: null,
        vaeOrderNo: 1,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 1,
      },
      {
        vaePKID: null,
        vaeOrderNo: 2,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 2,
      },
      {
        vaePKID: null,
        vaeOrderNo: 3,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 3,
      },
      {
        vaePKID: null,
        vaeOrderNo: 4,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 4,
      },
      {
        vaePKID: null,
        vaeOrderNo: 5,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 5,
      },
      {
        vaePKID: null,
        vaeOrderNo: 6,
        vaeAuxEngineMaker: null,
        vaeAuxEngineType: null,
        vaeSerialNo: null,
        vaeTurboChargerNo: null,
        vaeRPM: null,
        vaeAuxMaxGenPower: null,
        vaeMinOperLoad: null,
        vaeVoltage: null,
        vaeFrequency: null,
        vaePowerFactor: null,
        vaeKWHCounterInstalled: null,
        vaeEngineNo: 6,
      },
    ],

    vslReeferKWHInstalled: null,

    vslTurboGenInstalled: null,
    vslTurboGenMake: null,
    vslTurboGenType: null,
    vslTurboGenMaxPower: null,
    vslTurboGenMCR: null,
    vslTurboGenKWHCounterInstalled: null,

    vslShaftGenInstalled: null,
    vslShaftGenMake: null,
    vslShaftGenType: null,
    vslShaftGenMaxPower: null,
    vslShaftGenMCR: null,
    vslShaftGenKWHCounterInstalled: null,

    vslShaftMotorInstalled: null,
    vslShaftMotorMake: null,
    vslShaftMotorType: null,
    vslShaftMotorRating: null,

    vslBowThrusterInstalled: null,
    vslBowThruster: null,
    vslSternThrusterInstalled: null,
    vslSternThruster: null,

    vslStabiliserInstalled: null,

    enginePowerConsumption: [
      {
        vepPKID: null,
        vepMCR90: null,
        vepMCR80: null,
        vepMCR70: null,
        vepMCR60: null,
        vepMCR50: null,
        vepMCR40: null,
        vepMCR30: null,
        vepMCR20: null,
        vepMCR10: null,
        vepOrderNo: 1,
        vepMCRLoadDetails: 'Power(KW)',
      },
      {
        vepPKID: null,
        vepMCR90: null,
        vepMCR80: null,
        vepMCR70: null,
        vepMCR60: null,
        vepMCR50: null,
        vepMCR40: null,
        vepMCR30: null,
        vepMCR20: null,
        vepMCR10: null,
        vepOrderNo: 2,
        vepMCRLoadDetails: 'ME Consumption(mt/24h)',
      },
    ],

    beaufort2: [
      {
        vbPKID: null,
        vbOrderNo: 1,
        vbB2Speed10: null,
        vbB2Speed11: null,
        vbB2Speed12: null,
        vbB2Speed13: null,
        vbB2Speed14: null,
        vbB2Speed15: null,
        vbB2Speed16: null,
        vbB2Speed17: null,
        vbB2Speed18: null,
        vbB2Speed19: null,
        vbB2Speed20: null,
        vbB2Speed21: null,
        vbB2Speed22: null,
        vbB2Speed23: null,
        vbB2Speed24: null,
        vbSpeedType: 'Scantling Draft (ME Cons/24H)',
      },
      {
        vbPKID: null,
        vbOrderNo: 2,
        vbB2Speed10: null,
        vbB2Speed11: null,
        vbB2Speed12: null,
        vbB2Speed13: null,
        vbB2Speed14: null,
        vbB2Speed15: null,
        vbB2Speed16: null,
        vbB2Speed17: null,
        vbB2Speed18: null,
        vbB2Speed19: null,
        vbB2Speed20: null,
        vbB2Speed21: null,
        vbB2Speed22: null,
        vbB2Speed23: null,
        vbB2Speed24: null,
        vbSpeedType: 'Design Draft (ME Cons/24H)',
      },
    ],

    beaufort4: [
      {
        vbPKID: null,
        vbOrderNo: 1,
        vbB4Speed10: null,
        vbB4Speed11: null,
        vbB4Speed12: null,
        vbB4Speed13: null,
        vbB4Speed14: null,
        vbB4Speed15: null,
        vbB4Speed16: null,
        vbB4Speed17: null,
        vbB4Speed18: null,
        vbB4Speed19: null,
        vbB4Speed20: null,
        vbB4Speed21: null,
        vbB4Speed22: null,
        vbB4Speed23: null,
        vbB4Speed24: null,
        vbSpeedType: 'Scantling Draft (ME Cons/24H)',
      },
      {
        vbPKID: null,
        vbOrderNo: 2,
        vbB4Speed10: null,
        vbB4Speed11: null,
        vbB4Speed12: null,
        vbB4Speed13: null,
        vbB4Speed14: null,
        vbB4Speed15: null,
        vbB4Speed16: null,
        vbB4Speed17: null,
        vbB4Speed18: null,
        vbB4Speed19: null,
        vbB4Speed20: null,
        vbB4Speed21: null,
        vbB4Speed22: null,
        vbB4Speed23: null,
        vbB4Speed24: null,
        vbSpeedType: 'Design Draft (ME Cons/24H)',
      },
    ],

    vslLCVUsedForConsump: null,

    vslAuxConsumpAtSea: null,
    vslAuxLCVUsed: null,

    vslAuxConsumpAtPort: null,
    vslAuxPortLCVUsed: null,

    vslBolierConsumpAtSea: null,
    vslBoilerSeaLCVUsed: null,
    vslBolierConsumpAtPort: null,
    vslBoilerPortLCVUsed: null,

    vslCallSign: null,
    vslEmail: null,
    vslSatMTlph: null,
    vslSatMFax: null,
    vslCelluarMobile: null,

    positionMonitoring: [
      {
        vpmPKID: null,
        vpmSatCDetail: 'SAT C1',
        vpmBrand: null,
        vpmModel: null,
        vpmISN: null,
        vpmOrderNo: 1,
        vpmSatCTlx: null,
      },
      {
        vpmPKID: null,
        vpmSatCDetail: 'SAT C2',
        vpmBrand: null,
        vpmModel: null,
        vpmISN: null,
        vpmOrderNo: 2,
        vpmSatCTlx: null,
      },
      {
        vpmPKID: null,
        vpmSatCDetail: 'SSAS',
        vpmBrand: null,
        vpmModel: null,
        vpmISN: null,
        vpmOrderNo: 3,
        vpmSatCTlx: null,
      },
    ],

    vslTechPersonInCharge: null,
    vslHeadOwnerID: null,
    vslRegOwnAddress: null,
    vslRegOwnPhone: null,
    vslRegOwnEmail: null,

    vslOwnerID: null,
    vslCommOwnerID: null,
    vslTechMngID: null,
    vslOwnerBrokerID: null,
    vslCrewMngID: null,

    vslTeuNominal: null,
    vslTeuActual: null,
    vslDeckRussianStowage: null,
    vslSLP: null,

    vslCargoCraneInstalled: null,
    vslNoOfCranes: null,
    vslOutReach: null,
    vslCapacityCrane: null,

    vslFullyFittedLashing: null,
    vslAutomaticTwistLock: null,

    vslPlugsUnderDeck: null,
    vslPlugsInHold: null,
    vslPlugTypeInHold: null,
    vslPlugTypeOnDeck: null,
    vslEarthPin: null,
    vslVolt: null,
    vslReeferSocketCompliant: null,
    vslReeferSocketProtected: null,
    vslReeferRepairable: null,
    vslReeferRepairableComment: null,
    vslReeferPlugsIAtSea: null,
    vslReeferPlugsDuringPort: null,
    vslReeferPlugsDuringManoeuvring: null,
    vslVentPerReefer: null,
    vslVentPerCargo: null,
    vslCapacity40Reefer: null,
    vslUnderDesk40Reefer: null,
    vslCapacity44Reefer: null,

    vcSeaTrial: null,
    vcShopTrialME: null,
    vcShopTrialAE: null,
    vcDisplacementTable: null,
    vcPowerSpeedTable: null,
    vcShopTrialTCCOOperation: null,
    vcModelTest: null,
    vcLastDryDockingPaintReport: null,
    vcLastUnderwaterInspectionReport: null,
    vcLastPropellerPolishingReport: null,
    vcLastHullCleaningReport: null,
    vcShopTrailForTCCO: null,
  },
  formObj: {
    generalInformation: null,
    vesselSpecification: null,
    machineryPerticulars: null,
    speedAndConsumption: null,
    communication: null,
    cargoCapLayout: null,
    plansDocCert: null,
    additionalInfo: null,
  },
  createType: null,
};

/**
 * Reducer for Vessel Create actions
 * @param {State} state - The current state
 * @param {VesselQuestionnaireActions} action - The header content action
 */
export function reducer(state = emptyCreateVessel, action: VesselCreateActions): State {
  switch (action.type) {
    case VesselCreateActionTypes.ResetCreateVessel: {
      return {
        ...state,
        ...{
          form: emptyCreateVessel.form,
        },
      };
    }
    case VesselCreateActionTypes.ResetCreateVesselObj: {
      return {
        ...state,
        ...{
          formObj: emptyCreateVessel.formObj,
        },
      };
    }
    case VesselCreateActionTypes.PutCreateVessel: {
      return {
        ...state,
        ...{
          form: action.payload ? deepmerge(state.form, action.payload) : null,
        },
      };
    }
    case VesselCreateActionTypes.ResetCreateVessel: {
      return {
        ...state,
        ...{
          formObj: emptyCreateVessel.formObj,
        },
      };
    }
    case VesselCreateActionTypes.PutCreateType: {
      return {
        ...state,
        ...{
          createType: action.payload,
        },
      };
    }
    case VesselCreateActionTypes.ResetCreateType: {
      return {
        ...state,
        ...{
          createType: emptyCreateVessel.createType,
        },
      };
    }
    case VesselCreateActionTypes.PutGeneralInfo: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            generalInformation: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutVesselSpecification: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            vesselSpecification: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutMachineryParticulars: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            machineryPerticulars: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutSpeedConsumption: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            speedAndConsumption: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutCommunication: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            communication: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutCargoCap: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            cargoCapLayout: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutPlansDoc: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            plansDocCert: action.payload,
          },
        },
      };
    }
    case VesselCreateActionTypes.PutAdditionalInfo: {
      return {
        ...state,
        ...{
          formObj: {
            ...state.formObj,
            additionalInfo: action.payload,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}

const getVesselState = createFeatureSelector<any>('vessel');

export const getVesselFormData = createSelector(getVesselState, (state) => state.create.form);

export const getVesselFormGrp = createSelector(getVesselState, (state) => state.create.formObj);

export const getIMONumber = createSelector(getVesselFormGrp, (state) => state.generalInformation.vmMONumber);

export const getVesselCreateType = createSelector(getVesselState, (state) => state.create.createType);

// Vessel General Information
export interface IVesselInformationCreate {
  vesselCode?: Array<{
    vslMLCode: string;
    vslOperatorCode: string;
    vbID: number;
  }>;
  vslPKID?: number;
  vslVesselMasterID?: number;
  vslVesselCategory?: string;
  vmVesselName?: string;
  vmMONumber?: string;
  vslOfficialNo?: string;
  vslFlag?: number;
  vslHomePort?: number;
  vslCountryReg?: number;
  vslMaidenName?: string;
  vslExName?: string;
  vslSisterName?: string;
  vslBuildCountry?: number;
  vslBuildYardID?: number;
  vslhullno?: string;
  vslBuildYear?: Date;
  vslClassID?: number;
  vslIceClassID?: number;
  vslPIClub?: string;
  vslHMValue?: string;
  vslTotalLoss?: string;
  vslOSHAFitted?: boolean;
  lmdPanama?: string;
  vslSludgeTime?: boolean;
  vslSludgeCost?: boolean;
  lmdITF?: string;
}

// Vessel Specifications
export interface IVesselSpecificationsCreate {
  vslLOA?: number;
  vslLBP?: number;
  vslBeam?: number;
  vslDesignDraft?: number;
  vslTropDraught?: number;
  vslDraft?: number;
  vslDWT?: number;
  vsgTropDeadWeight?: number;
  vsgDesignDeadWeight?: number;
  vsgLightShipWeight?: number;
  vsgBlockCoefficient?: string;
  vsgDeplacement?: number;
  vslMaxAirDraft?: number;
  vsgFreshWaterAllow?: number;

  vslGT?: number;
  vslNT?: number;
  vslSuezT?: number;
  vslSuezTNet?: number;
  vslPanamaGT?: number;
  vslPanamaNT?: number;

  vslFBArea?: number;
  vslVSArea?: number;
  vslLastDryDockDate?: Date;
  vslNextDryDockDate?: Date;
  vslLastHullCleanDate?: Date;
  vslLastPropPolishDate?: Date;
  vslLastWaterInspDate?: Date;
  VerticalSurfacePrepDropDown1?: string;
  VerticalSurfacePrepDropDown2?: string;
  FlatSurfacePrepDropDown1?: string;
  FlatSurfacePrepDropDown2?: string;
  vslAntiFouling?: boolean;
  vslPaintMaker?: number;
  pmProducerName?: string;
  vslPaintType?: number;
  ptProductName?: string;

  vslBallastWaterCap?: number;
  vslFreshWaterCap?: number;
  vslHFOCapacity?: number;
  vslMGOCapacity?: number;
  vslUltraLowSulphurTankCap?: number;
  vslHFOReceivingCap?: number;
  vslMGOReceivingCap?: number;
  vslULSFOReceivingCap?: number;
  vslMaxFillLimit?: number;

  vslEngineFuel?: number;
  vslAuxFuel?: number;
  vslisUniFiel?: boolean;
  vslfsyID?: number;
  fsyYear?: string;
  vslSuitforULSFuel?: boolean;
  vslFullySeparateFSFlag?: boolean;
  vslULSFuel?: number;
  vslDistillateFuel?: number;
  feuType?: string;
}

// Machinery Particulars
export interface IVesselMachineryParticularsCreate {
  vslMainEngCount?: number;
  vslMainEngineMaker?: string;
  vslMainEngineType?: string;
  vslMainEngStroke?: number;
  vslMainEngOutput?: number;
  vslMainEngRPM?: number;
  vslMainEngCSR?: number;
  vslMCR?: number;
  vslMaxSpeed?: number;
  vslMinSpeed?: number;
  vslMainEngExMCR?: number;

  shopTrial?: Array<{
    vstpkID?: number;
    vstPower?: number;
    vstRPM?: number;
    vstPMax?: number;
    vstPComp?: number;
    vstSFOC?: number;
    vstTCRPM?: number;
    vstAirFilter?: number;
    vstAirCooler?: number;
    vstEPATC?: number;
    vstSATD?: number;
    vstSAP?: number;
    vstBIT?: number;
    vstExhT?: number;
    vstBP?: number;
    vstTIP?: number;
    vstTIT?: number;
    vstTOT?: number;
    vstLCV?: number;
    vstOrderNo?: number;
    vstLoadDetails?: string;
  }>;

  vslTCsuction?: boolean;
  vslTorMeterInstalled?: boolean;
  vslAvgPowerAbility?: boolean;
  vslTorMeterBwMotorPropeller?: boolean;
  vslTorMeterBwMotorMainEng?: boolean;
  vslAuxBlowerRating?: number;
  vslAuxBlowerCount?: number;
  vslAuxBlowerMCR?: number;
  vslMLP?: number;
  vslMEPMax?: number;
  vslPmaxLimit?: number;
  vslESP?: number;
  vslNominalMaxRPM?: number;
  vslTurboChargerType?: string;
  vslTurboChargerCount?: number;
  vslTCMaxRPM?: number;

  vslTCCOMode?: boolean;

  turboChargerCutOut?: Array<{
    vccopkID?: number;
    vccoengineRPM?: number;
    vccoPMax?: number;
    vccoPComp?: number;
    vccoSFOC?: number;
    vccoTCRPM?: number;
    vccoAirFilter?: number;
    vccoAirCooler?: number;
    vccoEPATC?: number;
    vccoSATD?: number;
    vccoSAP?: number;
    vccoBIT?: number;
    vccoExhT?: number;
    vccoBP?: number;
    vccoLCV?: number;
    vccoOrderNo?: number;
    vccoLoadDetails?: string;
  }>;

  vslPropellerType?: string;
  vslPropellerDiameter?: string;
  vslPropellerPitch?: number;

  auxEngine?: Array<{
    vaePKID?: number;
    vaeAuxEngineMaker?: string;
    vaeAuxEngineType?: string;
    vaeSerialNo?: number;
    vaeTurboChargerNo?: number;
    vaeRPM?: number;
    vaeAuxMaxGenPower?: number;
    vaeMinOperLoad?: number;
    vaeVoltage?: number;
    vaeOrderNo?: number;
    vaeFrequency?: number;
    vaePowerFactor?: number;
    vaeKWHCounterInstalled?: string;
    vaeEngineNo?: number;
  }>;
  vslAuxEstLoadAtSea?: number;
  vslAuxEstLoadAtPort?: number;
  vslAuxEstLoadDurManouvering?: number;

  vslReeferKWHInstalled?: boolean;

  vslTurboGenInstalled?: boolean;
  vslTurboGenMake?: string;
  vslTurboGenType?: string;
  vslTurboGenMaxPower?: number;
  vslTurboGenMCR?: number;
  vslTurboGenKWHCounterInstalled?: boolean;

  vslShaftGenInstalled?: boolean;
  vslShaftGenMake?: string;
  vslShaftGenType?: string;
  vslShaftGenMaxPower?: number;
  vslShaftGenMCR?: number;
  vslShaftGenKWHCounterInstalled?: boolean;

  vslShaftMotorInstalled?: boolean;
  vslShaftMotorMake?: string;
  vslShaftMotorType?: string;
  vslShaftMotorRating?: number;

  vslBowThrusterInstalled?: boolean;
  vslBowThruster?: number;
  vslSternThrusterInstalled?: boolean;
  vslSternThruster?: number;

  vslStabiliserInstalled?: boolean;
}

// Speed and Consumption
export interface IVesselSpeedConsumptionCreate {
  enginePowerConsumption?: Array<{
    vepPKID?: number;
    vepMCR90?: number;
    vepMCR80?: number;
    vepMCR70?: number;
    vepMCR60?: number;
    vepMCR50?: number;
    vepMCR40?: number;
    vepMCR30?: number;
    vepMCR20?: number;
    vepMCR10?: number;
    vepOrderNo?: number;
    vepMCRLoadDetails?: string;
  }>;

  beaufort2?: Array<{
    vbPKID?: number;
    vbOrderNo?: number;
    vbB2Speed10?: number;
    vbB2Speed11?: number;
    vbB2Speed12?: number;
    vbB2Speed13?: number;
    vbB2Speed14?: number;
    vbB2Speed15?: number;
    vbB2Speed16?: number;
    vbB2Speed17?: number;
    vbB2Speed18?: number;
    vbB2Speed19?: number;
    vbB2Speed20?: number;
    vbB2Speed21?: number;
    vbB2Speed22?: number;
    vbB2Speed23?: number;
    vbB2Speed24?: number;
    vbSpeedType?: string;
  }>;

  beaufort4?: Array<{
    vbPKID?: number;
    vbOrderNo?: number;
    vbB4Speed10?: number;
    vbB4Speed11?: number;
    vbB4Speed12?: number;
    vbB4Speed13?: number;
    vbB4Speed14?: number;
    vbB4Speed15?: number;
    vbB4Speed16?: number;
    vbB4Speed17?: number;
    vbB4Speed18?: number;
    vbB4Speed19?: number;
    vbB4Speed20?: number;
    vbB4Speed21?: number;
    vbB4Speed22?: number;
    vbB4Speed23?: number;
    vbB4Speed24?: number;
    vbSpeedType?: string;
  }>;

  vslLCVUsedForConsump?: string;

  vslAuxConsumpAtSea?: number;
  vslAuxLCVUsed?: number;

  vslAuxConsumpAtPort?: number;
  vslAuxPortLCVUsed?: number;

  vslBolierConsumpAtSea?: number;
  vslBoilerSeaLCVUsed?: number;
  vslBolierConsumpAtPort?: number;
  vslBoilerPortLCVUsed?: number;
}

// Communication
export interface IVesselCommunicationCreate {
  vslCallSign?: string;
  vslEmail?: string;
  vslSatMTlph?: string;
  vslSatMFax?: string;
  vslCelluarMobile?: string;

  positionMonitoring?: Array<{
    vpmPKID: number;
    vpmSatCDetail: string;
    vpmBrand: string;
    vpmModel: string;
    vpmISN: string;
    vpmOrderNo?: number;
    vpmSatCTlx: string;
  }>;

  vslTechPersonInCharge?: string;
  vslHeadOwnerID?: string;
  vslRegOwnAddress?: string;
  vslRegOwnPhone?: string;
  vslRegOwnEmail?: string;

  vslOwnerID?: number;
  vslCommOwnerID?: number;
  vslTechMngID?: number;
  vslOwnerBrokerID?: number;
  vslCrewMngID?: number;
}

// Cargo Cap and Layout
export interface IVesselCargoCapCreate {
  vslTeuNominal?: number;
  vslTonCenCap?: number;
  vslTeuActual?: number;
  vslDeckRussianStowage?: boolean;
  vslSLP?: string;

  vslCargoCraneInstalled?: boolean;
  vslNoOfCranes?: number;
  vslOutReach?: string;
  vslCapacityCrane?: number;

  vslFullyFittedLashing?: boolean;
  vslAutomaticTwistLock?: boolean;

  vslPlugsUnderDeck?: number;
  vslPlugsInHold?: number;
  vslPlugTypeInHold?: string;
  vslPlugTypeOnDeck?: string;
  vslEarthPin?: string;
  vslVolt?: string;
  vslReeferSocketCompliant?: boolean;
  vslReeferSocketProtected?: boolean;
  vslReeferRepairable?: boolean;
  vslReeferRepairableComment?: string;
  vslReeferPlugsIAtSea?: number;
  vslReeferPlugsDuringPort?: number;
  vslReeferPlugsDuringManoeuvring?: number;
  vslVentPerReefer?: string;
  vslVentPerCargo?: string;
  vslCapacity40Reefer?: number;
  vslUnderDesk40Reefer?: number;
  vslCapacity44Reefer?: number;
}

/** @docsNotRequired */
export interface IVesselDocsCertsCreate {
  // Plans Docs and Certs
  vcSeaTrial?: boolean;
  vcShopTrialME?: boolean;
  vcShopTrialAE?: boolean;
  vcDisplacementTable?: boolean;
  vcPowerSpeedTable?: boolean;
  vcShopTrialTCCOOperation?: boolean;
  vcModelTest?: boolean;
  vcLastDryDockingPaintReport?: boolean;
  vcLastUnderwaterInspectionReport?: boolean;
  vcLastPropellerPolishingReport?: boolean;
  vcLastHullCleaningReport?: boolean;
  vcShopTrailForTCCO?: boolean;
}

export interface IVesselAdditionalInfoCreate {
  // additional Information
  vslVesselTeu?: number;
  vslDesignID?: number;
  vslScrubberID?: number;
  vslNoOfPigtail?: number;
  vslisRCM?: boolean;
  vslMaxSpeedScantling?: number;
  vslWarRiskInsuranceID?: number;
  vslArmedGuardID?: number;
  vslAMPCompliantID?: number;
  vslTeuNominal?: number;
  vslVesselPlanningGroupMappingID?: number;
  vslVesselCiiMappingID?: number;
}

/** @docsNotRequired */
export interface IVesselCreate
  extends IVesselSpecificationsCreate,
  IVesselMachineryParticularsCreate,
  IVesselSpeedConsumptionCreate,
  IVesselCommunicationCreate,
  IVesselCargoCapCreate,
  IVesselAdditionalInfoCreate,
  IVesselDocsCertsCreate,
  IVesselInformationCreate { }
