// Angular
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Rxjs
import { Subject, BehaviorSubject } from 'rxjs';

// Services
import { MsalService } from '@azure/msal-angular';

// Components
import { ReloadMessageModalComponent } from 'src/app/components/reload-message-modal/reload-message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private timeoutId;
  public userActivity;
  public userInactive: Subject<boolean> = new Subject();
  public sessionState: Subject<boolean> = new BehaviorSubject(true);

  constructor(public msal: MsalService, public dialog: MatDialog) { }

  public setTimeout() {
    this.userActivity = setTimeout(() => {
      if (this.msal.loginRedirect) {
        const dialogRef = this.dialog.open(ReloadMessageModalComponent, { disableClose: true });
        dialogRef.componentInstance.cancelSessionTimeOut = this.cancelSessionTimeOut;
        dialogRef.componentInstance.reloadSession = this.logout;
        this.timeoutId = setTimeout(() => {
          this.userInactive.next(undefined);
        }, 1800000);
      }
    }, 1800000);
  }

  public cancelSessionTimeOut() {
    clearTimeout(this.timeoutId);
  }

  public logout() {
    location.reload();
  }
}
