//

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'champs-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() public maxSize?: number = 5;
  @Input() public maxFiles?: number = 1; // NOTE: Will add feature if required in future
  @Input() public fileExt?: any = ['xls', 'xlsx'];

  @Input() public title: string;

  @Output() public fileChanged: EventEmitter<any> = new EventEmitter();

  public dragAreaClass: string = '';
  public error: string = null;
  public message: string = null;

  /**
   * onDragOver event
   * @param e
   */
  public onDragOver(e) {
    e.preventDefault();
    this.dragAreaClass = 'droparea';
  }

  /**
   * onDragEnter event
   * @param e
   */
  public onDragEnter(e) {
    e.preventDefault();
    this.dragAreaClass = 'droparea';
  }

  /**
   * onDragEnd event
   * @param e
   */
  public onDragEnd(e) {
    e.preventDefault();
    this.dragAreaClass = 'dragarea';
  }

  /**
   * onDragLeave event
   * @param e
   */
  public onDragLeave(e) {
    e.preventDefault();
    this.dragAreaClass = 'dragarea';
  }

  /**
   * onDrop event
   * @param e
   */
  public onDrop(e) {
    e.preventDefault();
    event.stopPropagation();
    this.dragAreaClass = 'dragarea';
    const files = e.dataTransfer.files;
    this.saveFile(files[0]);
  }

  /**
   * file input change event
   * @param event
   */
  public onFileChange(event) {
    const files = event.target.files;
    this.saveFile(files[0]);
  }

  private saveFile(file) {
    this.error = null;
    this.isValidFileExtension(file);

    if (file && !this.error) {
      this.fileChanged.emit(file);
      this.message = file.name + ' uploaded';
    }
  }

  private isValidFileExtension(file) {
    const type = (typeof this.fileExt).toString();
    const extensions = (type !== 'string' ? this.fileExt : this.fileExt.split(','))
      .map(function (x) { return x.toLocaleUpperCase().trim(); });

    const ext = file.name.toUpperCase().split('.').pop() || file.name;

    const exists = extensions.includes(ext);

    if (!exists) {
      this.message = null;
      this.error = 'Invalid file type ' + ext + ': in ' + file.name;
      return;
    }

    this.isValidFileSize(file);

    return;

  }

  private isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize) {
      this.error = 'Error (File Size): ' + file.name + ': exceed file size limit of ' + this.maxSize + 'MB ( ' + size + 'MB )';
    }

    return;
  }

}
