// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Services
import { VesselsService } from '@cham-services/vessels/vessels.service';

// Rxjs
import { distinctUntilChanged, take } from 'rxjs/operators';

@Component({
  selector: 'champs-floating-deployment-block',
  templateUrl: './floating-deployment-block.component.html',
  styleUrls: ['./floating-deployment-block.component.scss']
})
export class FloatingDeploymentBlockComponent implements OnInit {
  @Input() public vesselDto: any;
  @Output() public reload: EventEmitter<any> = new EventEmitter();
  public blockNum: any = [];
  public countIdCheck = true;
  public isDataLoaded: boolean = false;
  constructor(
    private vesselsService: VesselsService
  ) { }

  public ngOnInit(): void {
    this.LoadVesselDeploymentLines();
  }

  public LoadVesselDeploymentLines() {
    this.isDataLoaded = false;
    this.vesselsService
      .getVesselDeployments(this.vesselDto.vesselId)
      .pipe(take(1), distinctUntilChanged())
      .subscribe(async (vesselDeployments: any) => {
        if (vesselDeployments) {
          if (vesselDeployments.vesselCodeCount > 1) {
            // Filter to get correct deployment if vessel has duplicate vessel code
            this.blockNum = vesselDeployments.allDeployments.find(vessel => 
              vessel.deployments.currentDeployment.some(deployment => deployment.depPKID === this.vesselDto.vesselDeploymentId) ||
              vessel.deployments.upcomingDeployment.some(deployment => deployment.depPKID === this.vesselDto.vesselDeploymentId) ||
              vessel.deployments.previousDeployment.some(deployment => deployment.depPKID === this.vesselDto.vesselDeploymentId)
            );
          } else {
            this.blockNum = vesselDeployments[`allDeployments`][0];
          }
        }
        this.isDataLoaded = true;
      });
  }

  public reloadPage() {
    this.reload.emit();
  }
}
