import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NpsFeedbackService } from '../../core/services/nps-feedback/nps-feedback.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'champs-nps-integration',
  templateUrl: './nps-integration.component.html',
  styleUrls: ['./nps-integration.component.scss']
})
export class NpsIntegrationComponent {
  @Output() feedbackSubmitted = new EventEmitter<any>();
  public clickedIndex: number | null = null;
  public showWarning = false;
  public feedback: string = '';
  private localStorageKey = 'feedbackSubmissionDate';
  private notificationKey = 'notificationDate';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<NpsIntegrationComponent>,
    private npsFeedbackService: NpsFeedbackService,
    private snackBar: MatSnackBar
  ) {}

  onCancel(): void { 
    this.dialogRef.close(); 
  } 

  onRatingSelected(rating: number) {
    this.showWarning = false;
    if (this.clickedIndex === rating) {
      this.clickedIndex = null;
    } else {
      this.clickedIndex = rating;
    }
  }

  onSave() {
    if(this.clickedIndex === null) {
      return this.showWarning = true;
    }
    const { username, userEmail, feedbackDisplayId, feedbackId, QuestionIds } = this.data;
    const submissionDate = new Date().toISOString();
    // 2024-05-09T10:06:45.263Z - Sample date for testing

    this.npsFeedbackService.postFeedback(this.clickedIndex, this.feedback, username, userEmail, feedbackDisplayId, feedbackId, QuestionIds)
      .subscribe(
        response => {
          this.onCancel();
          localStorage.setItem(this.localStorageKey, submissionDate);
          localStorage.setItem(this.notificationKey, new Date().toISOString());
          this.openSnackBar('Feedback submitted successfully', 'Close');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        error => {
          this.onCancel();
          localStorage.setItem(this.notificationKey, new Date().toISOString());
          this.openSnackBar('Error submitting feedback', 'Close');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      );
  }
  public openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom'; 
    config.horizontalPosition = 'end'; 
    config.duration = 3000;
    this.snackBar.open(message, action, config);
  }
}
