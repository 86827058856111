//
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DatePipe } from '@angular/common';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
@Component({
  selector: 'champs-extend-to-dummy',
  templateUrl: './extend-to-dummy.component.html',
  styleUrls: ['./extend-to-dummy.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ExtendToDummyComponent implements OnInit {
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public position: any;
  @Input() public proformaId: any;
  @Input() public vesselId: any;
  @Input() public vesselType: any;
  @Input() public extendDummyFromDate: any;
  @Input() public vslFixtureID: any;
  @Input() public redeliveryDate: any;
  public extendDummyToDate: any;
  @Input() public proformaPortName: any;
  @Input() public proformaHeaderId: any;
  @Input() public proformaPortId: any;
  @Input() public rotationNumber: any;
  @Input() public posPortLst: any;
  @Input() public cityId: any;
  @Input() public cityName: any;
  @Input() public serviceid: any;
  @Input() public serviceCode: any;
  @Input() public serviceName: any;
  @Input() public serviceRepo: any;
  @Input() public scenarioId: any;
  public endPortId: any;
  public endPortName: any;
  public createDummyFrmGrp: UntypedFormGroup;
  public vesselRequirementObj: any;
  public noticeRes: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceRepoService: ServiceRepositoryService,
    private scenarioService: ScenariosService
  ) {}
  public ngOnInit(): void {
    if (this.vesselType === 0) {
      // Own vessel
      this.assignValuesForExtendOwnVessel();
    } else {
      // charter vessel
      this.assignValuesForExtendCharterVessel();
    }
  }

  public assignValuesForExtendOwnVessel() {
    const endDateFromSchObj = this.getEndDateFromSch(this.rotationNumber, this.posPortLst);
    this.extendDummyToDate = endDateFromSchObj.endDate;
    this.endPortId = endDateFromSchObj.cityId;
    this.endPortName = endDateFromSchObj.cityName;
  }

  public assignValuesForExtendCharterVessel() {
    const obj = this.posPortLst.filter((i) => i.lstPositionDto[0].scheduleDate <= this.redeliveryDate).slice(-1)[0];
    this.extendDummyToDate = obj.lstPositionDto[0].scheduleDate;
    this.endPortId = obj.cityId;
    this.endPortName = obj.cityName;
  }

  public getEndDateFromSch(rotationNumber, posPortLst) {
    const listWithGivenRotationNumber = posPortLst.filter((i) => i.rotationNumber === rotationNumber);
    const lastCityInRotation = listWithGivenRotationNumber[listWithGivenRotationNumber.length - 1];

    return {
      cityId: lastCityInRotation.cityId,
      cityName: lastCityInRotation.cityName,
      endDate: lastCityInRotation.lstPositionDto.find((i) => i.scheduleType === 0).scheduleDate,
    };
  }

  public extendDummy(isDummy) {
    if (isDummy) {
      const serv = this.serviceRepo
        ? this.serviceRepoService.getVesselRequirementsExtendToDummy(this.vesselId, this.proformaId)
        : this.scenarioService.getVesselRequirementsExtendToDummy(this.vesselId, this.proformaId, this.scenarioId);
      serv.subscribe((res: any) => {
        this.vesselRequirementObj = res;
        this.createCharterFormGroup();
        this.extendToDummy();
      });
    } else {
      if (this.vesselType !== 0) {
        this.serviceRepoService.getPostfixtureRedeliverNoticesAsync(this.vslFixtureID).subscribe((resp: any) => {
          this.noticeRes = resp;
          const noticeWithOrderOne = this.noticeRes.find((i) => i.noticeOrder === 1);
          if (noticeWithOrderOne && noticeWithOrderOne.noticeStatusValue === 1) {
            this.close.emit({
              type: 'popupError',
              error: 'First redelivery notice has been tendered - contact Chartering.',
            });
          } else if (noticeWithOrderOne && noticeWithOrderOne.noticeStatusValue === 2) {
            this.close.emit({
              type: 'popupError',
              error: 'First redelivery notice will be overdue - contact Chartering.',
            });
          } else {
            this.createVesselDepCheck();
          }
        });
      } else {
        this.createVesselDepCheck();
      }
    }
  }

  public createVesselDepCheck() {
    const postObj = this.createVesselDeploymentObj();
    const serv = this.serviceRepo
      ? this.serviceRepoService.createVesselDeployment(postObj)
      : this.scenarioService.createVesselDeployment(postObj);
    serv.subscribe(
      (res: any) => {
        if (res) {
          this.close.emit({
            type: 'vessel',
          });
        }
      },
      (error) => {
        this.close.emit({
          type: 'popupError',
          error: error.error.error,
        });
      }
    );
  }

  public closePopup() {
    this.close.emit({
      type: 'closepopup',
    });
  }

  public createVesselDeploymentObj() {
    const postObj = {
      lstScheduleId: this.getScheduleIds(),
      vesselDeploymentCreateOrUpdateDto: {
        depPKID: 0,
        depVesselID: this.vesselId,
        depStringID: this.serviceid,
        depPosition: this.position,
        depStartPortID: this.cityId,
        depEndPortID: this.endPortId,
        depBeginDate: this.extendDummyFromDate,
        depEndDate: this.extendDummyToDate,
        depReasonCodeID: null,
        depComments: null,
        specialSerivceType: null,
        unemployedeType: null,
        layupType: null,
        previousDeploymentId: null,
      },
    };
    return postObj;
  }

  public getScheduleIds() {
    let schLst = [];
    const fromDate = new Date(new DatePipe('en-US').transform(this.extendDummyFromDate, 'yyyy-MM-ddTHH:mm:ss'));
    // for (const lst of this.posPortLst) {
    //   const schFromDate = lst.lstPositionDto ? lst.lstPositionDto.length === 2 ? (lst.lstPositionDto[0].scheduleType === 0 ? lst.lstPositionDto[0] : (lst.lstPositionDto[1].scheduleType === 0 ? lst.lstPositionDto[1] : null)) : (lst.lstPositionDto[0].scheduleType === 0 ? lst.lstPositionDto[0] : null) : null;
    //   const schToDate = lst.lstPositionDto ? lst.lstPositionDto.length === 2 ? (lst.lstPositionDto[0].scheduleType === -1 ? lst.lstPositionDto[0] : (lst.lstPositionDto[1].scheduleType === -1 ? lst.lstPositionDto[1] : null)) : (lst.lstPositionDto[0].scheduleType === -1 ? lst.lstPositionDto[0] : null) : null;
    //   if ((schFromDate) ? (new Date(new DatePipe('en-US').transform(schFromDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() >= new Date (fromDate).getTime()
    //      && (schFromDate.vesselDto !== null || schFromDate.charterRequestDto !== null)) : true) {
    //       break;
    //   }
    //   this.extendDummyToDate = schFromDate.scheduleDate;
    //   if ((schToDate) ? (new Date(new DatePipe('en-US').transform(schToDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() >= new Date (fromDate).getTime()
    //      && (schToDate.vesselDto !== null || schToDate.charterRequestDto !== null)) : true) {
    //     break;
    //   }
    // }&& schFromDate.vesselDto === null && schFromDate.charterRequestDto === null
    // && schToDate.vesselDto === null && schToDate.charterRequestDto === null
    // this.createDummyFrmGrp.patchValue ({'crPhaseOutStartDate': this.extendDummyToDate});
    for (const lst of this.posPortLst) {
      const schFromDate = lst.lstPositionDto
        ? lst.lstPositionDto.length === 2
          ? lst.lstPositionDto[0].scheduleType === 0
            ? lst.lstPositionDto[0]
            : lst.lstPositionDto[1].scheduleType === 0
            ? lst.lstPositionDto[1]
            : null
          : lst.lstPositionDto[0].scheduleType === 0
          ? lst.lstPositionDto[0]
          : null
        : null;
      const schToDate = lst.lstPositionDto
        ? lst.lstPositionDto.length === 2
          ? lst.lstPositionDto[0].scheduleType === -1
            ? lst.lstPositionDto[0]
            : lst.lstPositionDto[1].scheduleType === -1
            ? lst.lstPositionDto[1]
            : null
          : lst.lstPositionDto[0].scheduleType === -1
          ? lst.lstPositionDto[0]
          : null
        : null;
      if (
        schFromDate
          ? new Date(new DatePipe('en-US').transform(schFromDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() >=
              new Date(fromDate).getTime() &&
            new Date(new DatePipe('en-US').transform(schFromDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() <=
              new Date(this.extendDummyToDate).getTime()
          : false
      ) {
        schLst.push(schFromDate.scheduleId);
      }
      if (
        schToDate
          ? new Date(new DatePipe('en-US').transform(schToDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() >=
              new Date(fromDate).getTime() &&
            new Date(new DatePipe('en-US').transform(schToDate.scheduleDate, 'yyyy-MM-ddTHH:mm:ss')).getTime() <=
              new Date(this.extendDummyToDate).getTime()
          : false
      ) {
        schLst.push(schToDate.scheduleId);
      }
    }
    schLst = Array.from(new Set(schLst));
    return schLst;
  }
  public extendToDummy() {
    const schLst = this.getScheduleIds();
    const serv1 = this.serviceRepo
      ? this.serviceRepoService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value
        )
      : this.scenarioService.getLastDeploymentOnServiceByPosition(
          this.serviceid,
          this.position,
          this.createDummyFrmGrp.get('crPhaseInStartDate').value,
          this.scenarioId
        );
    serv1.subscribe((res1: any) => {
      this.createDummyFrmGrp.patchValue({
        crVesselDepID: res1 === -1 ? null : res1,
      });
      const servpostCreateUpdateDummy = this.serviceRepo
        ? this.serviceRepoService.postCreateUpdateDummy(this.createDummyFrmGrp.value)
        : this.scenarioService.postCreateUpdateDummy(this.createDummyFrmGrp.value);
      servpostCreateUpdateDummy.subscribe((res: any) => {
        if (res) {
          const charterRequestId = res;
          const postData = {
            charterRequestId: charterRequestId.charter_id,
            lstSchedules: schLst,
          };
          const servpostUpdateCharterRequestOnSchedule = this.serviceRepo
            ? this.serviceRepoService.postUpdateCharterRequestOnSchedule(postData)
            : this.scenarioService.postUpdateCharterRequestOnSchedule(postData);
          servpostUpdateCharterRequestOnSchedule.subscribe((res2: any) => {
            if (res2) {
              this.close.emit({
                type: 'dummy',
              });
            }
          });
        }
      });
    });
  }
  public createCharterFormGroup() {
    this.createDummyFrmGrp = this.formBuilder.group({
      crPKID: 0,

      crPhaseInPortID: this.cityId,

      crPhaseInPortName: this.cityName,

      crStatusID: 1,

      crPhaseInStartDate: this.extendDummyFromDate,

      crPhaseOutPortID: this.endPortId,

      crPhaseOutPortName: this.endPortName,

      crPhaseOutStartDate: this.extendDummyToDate,

      crClassID: null,

      crTradeID: 7,
      nomTeuCapacity: this.formBuilder.control(
        this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null,
        [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]
      ),
      tonCenCap: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null, [
        Validators.min(0),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      crTEU: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.totalTeuCap : null, [
        Validators.min(0),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),

      crMTCapacity: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.totalMTCap : null, [
        Validators.min(0),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),

      crPlugs: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.plugs : null, [
        Validators.min(0),
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),

      crMinSpeed: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.minSpeed : null, [
        Validators.min(0),
        Validators.max(99),
        Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
      ]),

      crMaxSpeed: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.maxSpeed : null, [
        Validators.min(0),
        Validators.max(99),
        Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
      ]),

      crDraft: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.draftLimitations : null, [
        Validators.min(0),
        Validators.max(99),
        Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,1})?$')),
      ]),

      crAirDraft: this.formBuilder.control(this.vesselRequirementObj ? this.vesselRequirementObj.airDraft : null, [
        Validators.min(0),
        Validators.max(999),
        Validators.pattern(new RegExp('^[0-9]+(.[0-9]{0,2})?$')),
      ]),

      crIsGear: this.vesselRequirementObj ? this.vesselRequirementObj.gears : null,

      crIsIceClass: this.vesselRequirementObj ? this.vesselRequirementObj.iceClass : null,

      crComments: '',

      crVesselDepID: null,

      crReason: '',

      crForecastTEU: null,

      crForecastTEURate: null,

      crHFOPrice: null,

      crLSHFOPrice: null,

      crMDOPrice: null,

      crLSMDOPrice: null,

      crMLTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlTeuCap : null,

      crMLMTCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.mlmtCap : null,

      crProformaHeaderId: this.proformaHeaderId,

      crVesselTeuId: this.vesselRequirementObj ? this.vesselRequirementObj.vesselTeuId : '',

      crNomTeuCapacity: this.vesselRequirementObj ? this.vesselRequirementObj.nomTeuCapacity : null,

      crTonCenCap: this.vesselRequirementObj ? this.vesselRequirementObj.tonCenCap : null,

      crECAZone: this.vesselRequirementObj ? this.vesselRequirementObj.ecaZone : null,

      crIsECA: this.vesselRequirementObj ? this.vesselRequirementObj.isECAZone : null,
      crExtendsVesselId: this.vesselId,
    });
  }
}
