// pipe to create ordinal number

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nOrdinal'
})
export class OrdinalNumberPipe implements PipeTransform {

  /**
   * transform 1 2 3 4 to 1st 2nd 3rd 4th
   * @param {any} value pipe value
   * @param {any} args argument for pipe
   */
  public transform(value: any, args?: any): any {
    const s = ['th', 'st', 'nd', 'rd'];
    if (typeof value !== 'number') {
      return value;
    } else {
      const v = value % 100;
      return value + (s[(v - 20) % 10] || s [v] || s[0]);
    }
  }

}
