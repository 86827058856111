// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep .replace-vessel .div-cut-popup {
  border: 1px solid #ff9b1e;
  opacity: 1;
  left: 0;
  width: 100%;
  background: #ff9b1e;
  color: #ffffff;
  height: auto;
}
:host::ng-deep .replace-vessel .padding-block {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
:host::ng-deep .replace-vessel .placeholder-black span {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
}
:host::ng-deep .replace-vessel .placeholder-black span.mat-select-placeholder {
  color: #282828 !important;
  -webkit-text-fill-color: #282828;
}
:host::ng-deep .replace-vessel .mat-mdc-card {
  padding: 12px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
}
:host::ng-deep .replace-vessel .mat-mdc-card .mat-card-header-text {
  margin: 0 !important;
  display: none !important;
}
:host::ng-deep .replace-vessel .save-button {
  background: #64b2d4;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 5px;
  min-width: 160px !important;
}
:host::ng-deep .replace-vessel .disabled {
  cursor: wait;
  background: #888888;
}
:host::ng-deep .replace-vessel .ng-select.filter {
  width: 200px;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  zoom: 1;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container {
  min-height: 24px;
  background-color: #ffffff;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container::after {
  content: none !important;
  display: none;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
}
:host::ng-deep .replace-vessel .ng-select.filter .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}
:host::ng-deep .replace-vessel .content-mar {
  margin: 0 !important;
}
:host::ng-deep .replace-vessel .padding-left {
  padding-left: 10px !important;
}
:host::ng-deep .replace-vessel .padding-up {
  padding-top: 10px !important;
}
:host::ng-deep .replace-vessel .padding-down {
  padding-bottom: 10px !important;
}
:host::ng-deep .replace-vessel .requirement-value .heading {
  width: 36%;
  padding: 8px 0 !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .requirement-value .value {
  width: 64%;
  padding: 0 !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .requirement-value .value .date {
  width: 36%;
  padding: 5px !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .requirement-value .value .date-value {
  width: 64%;
  padding: 0 !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .rule {
  padding: 4px;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field {
  display: inline-block;
  position: relative;
  font-size: 12px;
  text-align: left;
  width: 100%;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
  margin-right: 5px;
  margin-bottom: 5px;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-flex {
  border: 1px solid #ededed;
  margin: 0 -50px 0 0;
  padding: 0 5px;
  background: #ffffff;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-infix {
  border: 0;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-hide-placeholder {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-panel {
  width: 156px;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-content .teu-inputs {
  width: 50px;
  margin: 10px;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-content img {
  float: right;
  margin-right: 25px;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-hide-placeholder .mat-select-placeholder {
  color: #282828 !important;
}
:host::ng-deep .replace-vessel .vessel-filters .mat-mdc-form-field-suffix img {
  margin-left: -17px;
  width: 16px;
  height: 16px;
  margin-bottom: -3.5px;
  background: #ffffff;
}
:host::ng-deep .replace-vessel .vessel-filters .vesselcombo {
  padding-left: 10px;
}
:host::ng-deep .replace-vessel .vessel-filters .status {
  padding-left: 10px;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vessel-filters .operator {
  padding-left: 10px;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vessel-heading {
  width: 36%;
  padding: 8px 0;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vessel-value {
  width: 64%;
  display: inline-block;
  text-align: right;
}
:host::ng-deep .replace-vessel .vessel-details {
  width: 40%;
  padding-top: 27px;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip {
  background-color: #64b2d4;
  color: #ffffff;
  height: 23px;
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip#removeAllFilters {
  border: 1px solid #64b2d4;
  background-color: #ffffff;
  color: #282828;
  padding: 3px 3px 4px 8px;
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip-list-wrapper {
  padding: 0;
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip img {
  margin-right: 0 !important;
  fill: #ffffff;
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) {
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
}
:host::ng-deep .replace-vessel .vessels-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) .mat-chip-remove {
  opacity: 1 !important;
}
:host::ng-deep .replace-vessel .heading,
:host::ng-deep .replace-vessel .close {
  padding-top: 6px;
  padding-bottom: 6px;
}
:host::ng-deep .replace-vessel .gap {
  width: 3%;
  display: inline-block;
}
:host::ng-deep .replace-vessel .gap img {
  width: 20px;
  height: 20px;
  top: 0;
  position: relative;
}
:host::ng-deep .replace-vessel .requirement,
:host::ng-deep .replace-vessel .teu,
:host::ng-deep .replace-vessel .mt,
:host::ng-deep .replace-vessel .plugs,
:host::ng-deep .replace-vessel .speed, :host::ng-deep .replace-vessel .loa {
  width: 10%;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .values .teu-head,
:host::ng-deep .replace-vessel .values .plugs-head,
:host::ng-deep .replace-vessel .values .speed-head,
:host::ng-deep .replace-vessel .values .start-date,
:host::ng-deep .replace-vessel .values .mt-head {
  width: 9.5% !important;
  padding-top: 0 !important;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .values .name {
  width: 25% !important;
  padding-top: 0 !important;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .values .code,
:host::ng-deep .replace-vessel .values .oper {
  width: 5%;
  padding-top: 0 !important;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .values .green {
  color: #195a3c !important;
}
:host::ng-deep .replace-vessel .space-head {
  width: 0.8%;
  display: inline-block;
}
:host::ng-deep .replace-vessel .teu-head,
:host::ng-deep .replace-vessel .plugs-head,
:host::ng-deep .replace-vessel .speed-head,
:host::ng-deep .replace-vessel .mt-head {
  width: 9%;
  padding-top: 20px;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .start-date {
  width: 11%;
  padding-top: 20px;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .name {
  width: 24.3%;
  padding-top: 20px;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan {
  width: 32%;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan .dash {
  height: 10px;
  z-index: 1000;
  padding: 5px;
  margin-top: 0;
  opacity: 1;
  color: #282828;
  position: absolute;
}
:host::ng-deep .replace-vessel .deploy-plan .left-pos {
  right: 32%;
}
:host::ng-deep .replace-vessel .deploy-plan .right-pos {
  right: 3%;
}
:host::ng-deep .replace-vessel .deploy-plan .top-1,
:host::ng-deep .replace-vessel .deploy-plan .bottom-1 {
  top: -8px;
}
:host::ng-deep .replace-vessel .deploy-plan .top-2,
:host::ng-deep .replace-vessel .deploy-plan .bottom-2 {
  top: 6px;
}
:host::ng-deep .replace-vessel .deploy-plan .top-3,
:host::ng-deep .replace-vessel .deploy-plan .bottom-3 {
  top: 20px;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup {
  padding: 5px;
  border: 1px solid #ffd21e;
  margin-top: 0;
  opacity: 2;
  top: 42px;
  width: 290px;
  background-color: #fff6d2;
  color: #282828;
  height: auto;
  position: absolute;
  z-index: 2000;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup .content-name {
  width: 100%;
  color: #64b2d4;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup .content-dates {
  width: 100%;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup::before {
  content: "";
  background: #fff6d2;
  position: absolute;
  top: -10px;
  border-right: 1px solid #ffd21e;
  border-top: 1px solid #ffd21e;
  transform: rotate(-45deg);
  width: 16px;
  height: 16px;
  margin-left: 7px;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup-last {
  right: 1%;
  padding: 5px;
  border: 1px solid #ffd21e;
  margin-top: 0;
  opacity: 2;
  top: 42px;
  width: 290px;
  background-color: #fff6d2;
  color: #282828;
  height: auto;
  position: absolute;
  z-index: 2000;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup-last .content-name {
  width: 100%;
  color: #64b2d4;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup-last .content-dates {
  width: 100%;
  display: inline-block;
}
:host::ng-deep .replace-vessel .deploy-plan .div-port-popup-last::before {
  content: "";
  background: #fff6d2;
  position: absolute;
  top: -10px;
  border-right: 1px solid #ffd21e;
  border-top: 1px solid #ffd21e;
  transform: rotate(-45deg);
  width: 16px;
  height: 16px;
  margin-left: 236px;
}
:host::ng-deep .replace-vessel .deploy-plan .div-popup {
  padding: 5px;
  border: 1px solid #ffd21e;
  margin-top: 0;
  opacity: 1;
  top: 26px;
  left: 0;
  width: 200px;
  background-color: #fff6d2;
  color: #282828;
  height: auto;
  position: absolute;
  z-index: 1000;
}
:host::ng-deep .replace-vessel .code,
:host::ng-deep .replace-vessel .oper {
  width: 5%;
  padding-top: 20px;
  text-align: left;
  display: inline-block;
}
:host::ng-deep .replace-vessel .duration {
  width: 13%;
  padding-top: 20px;
  display: inline-block;
}
:host::ng-deep .replace-vessel .end-date {
  width: 8%;
  padding-top: 20px;
  text-align: right !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .cut-off-heading {
  width: 15%;
  padding: 8px 0 !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .cut-off-value {
  width: 85%;
  padding: 0 !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .cut-off-value .name {
  width: 65% !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .cut-off-value .operation {
  width: 35% !important;
  display: inline-block;
}
:host::ng-deep .replace-vessel .vertical-rule {
  z-index: 1;
}
:host::ng-deep .replace-vessel .mat-mdc-form-field {
  padding-left: 10px;
}
:host::ng-deep .replace-vessel .mat-mdc-form-field-flex {
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #888888;
  border-radius: 2px;
}
:host::ng-deep .replace-vessel .mat-mdc-form-field-infix {
  width: 134px;
  border: 0;
}
:host::ng-deep .replace-vessel .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .replace-vessel .mat-mdc-form-field-should-float {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
:host::ng-deep .replace-vessel .mat-mdc-form-field-should-float .mat-form-field-label {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
