// Angular
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  QueryList,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatDialogRef, MatDialog, DialogPosition } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Event } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Libraries
import * as _moment from 'moment';

// Rxjs
import { Subject } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';

// Components
import {
  MY_FORMATS,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';

// Srvices
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { DataServiceService } from '../../../service-repository/services/data-service.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DataScenarioService } from '../../../scenarios/services/data-scenario.service';
import { AuthService } from 'src/app/core/services/authentication/auth/auth.service';
import { UIHelperService } from 'src/app/core/services/ui-helper/ui-helper.service';
import { SessionTimeoutService } from 'src/app/core/services/common/session-timeout.service';
import { AlertService } from '@cham-services/alert/alert.service';

const moment = customMoment;
export interface DialogData {
  schDetails: any;
}
@Component({
  selector: 'champs-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class DeploymentComponent implements OnInit, AfterViewChecked, OnDestroy {
  private _onDestroy = new Subject<void>();
  private unsubscribe$ = new Subject<void>();
  public vesselNameDisplayedDetails = {};

  public cutIndexInDto: any;
  public shortenType: any;
  public deploymentsOnBelowIndexPort: any[];
  public deploymentsOnIndexPort: any[];
  public selectedValue: any;
  public cutType: any;
  public portInfoForCut: any;
  public mainListPlanner: any;
  public cutPosition: any;
  public overlapDetailList: any[] = [];
  public nonoverlapDetailList: any[] = [];
  public cutSpot: any;
  public showOverlapVesselListBox: boolean;
  public cutLocation: any;
  public cutIndex: any;
  public showVesselSwitchRadioBtns: boolean;
  public isCut: boolean;
  public prolongErrorMsg: any;
  public showProlongErr: boolean;
  public isProlongElseShorten: boolean;
  public newScheduleDate: any;
  public originalValidTo: any;
  public schId: any;
  public dialogRef: MatDialogRef<any>;
  @ViewChild('visibleDate', { static: false }) public addNewPeriod: any;
  @ViewChild('submitRequest', { static: false }) public submitRequest: any;
  @ViewChild('redeliverVessel', { static: false }) public redeliverVessel: any;
  @ViewChildren('portInfoLst', { read: ElementRef })
  public portInfoLst: QueryList<ElementRef>;
  @ViewChild('confirmationDialog', { static: false }) public deleteRequest: any;
  @ViewChild('confirmationPopUpDialog', { static: false }) public confirmationPopUpDialog: any;
  @ViewChild('extraLoaderPopup', { static: false }) public extraLoaderPopup: any;
  @ViewChild('warningDialog', { static: false }) public warningDialog: any;
  @ViewChild('confirmationProlongSchedule', { static: false })
  public confirmationProlongSchedule: any;
  @ViewChild('makeOverlapVesselCutSelection', { static: false })
  public makeOverlapVesselCutSelection: any;
  @ViewChild('confirmationCutSchedule', { static: false }) public confirmationCutSchedule: any;
  @ViewChild('warningOnChangeSchedule', { static: false }) public warningOnChangeSchedule: any;
  @ViewChild('plannerTable', { static: false }) public plannerTable: any;

  @Output() public delete: EventEmitter<any> = new EventEmitter();
  public showVisibleForm: boolean = false;
  public showVesselDeplyment: boolean = false;
  public redeliverVesselPopUp: boolean = false;
  public scheduleEndDate: any;
  public color: any = 'red';
  public servicePlanningLst: any;
  public popupPosition: any;
  public omitPortpopupPosition: any;
  public vesselPopupPosition: any;
  public extraLoaderPosition: any;
  public showIcon: boolean = false;
  public versionPorts: any;
  public omitFromToDate: any;
  public createDummyFromDate: any;
  public posPortLst: any = [];
  public posPortLstExtend: any = [];
  public atChanged: any;
  public vesselDto: any;
  public overlapvesselDto: any;
  public vesselDeploymentInfo: any;
  public showVslPopUp: any;
  public createdDateData: any;
  public isSwitchSchOverview: any;
  public latestVersion: any;
  public isHover = false;
  public serviceRepo: any;
  public openPlanner: boolean = false;
  public setVesselDtoForQIcon: boolean = false;
  public id: any;
  public vesselMap: any = [];
  public vesselPrevMap: any = [];
  public dummyMap: any = [];
  public dummyPrevMap: any = [];
  public positionsLst: any = [];
  public extraPositionsLst: any = [];
  public isSticky: boolean = false;
  public createRequestPopUp: boolean = false;
  public charterRequestObject: any;
  public deleteRequestPopUp: boolean = false;
  public showRevertToDummy: boolean = false;
  public charterId: any;
  public selectedPos: any;
  public selectedRow: any = [];
  public selectedRowIndex: any;
  public isCutoff: any = false;
  public deployLst: any;
  public schIdData: any;
  public schIdScData: any;
  public subHeaderFrmGrp: UntypedFormGroup;
  public dates: UntypedFormArray;
  public subHeaderDraftFrmGrp: UntypedFormGroup;
  public maxValidTo: any;
  public minValidFrom: any;
  public isDateValidating: boolean = false;
  public deleteDummyPopUP: boolean = false;
  public deleteDeployment: boolean = false;
  public vesselId: any;
  public position: any;
  public fixtureId: any;
  public proformaId: any;
  public startHereIndex: any;
  public xPosition: any;
  public yPosition: any;
  public extendImagePositionTop: any;
  public extendImagePositionLeft: any;
  public dragEnabled: boolean = false;
  public extenToDummyPosition: any;
  public popupLastIndexX: any;
  public popupLastIndexY: any;
  public popupLastIndexZ: any;
  public showExtendDummyPopup: boolean = false;
  public extendDummyStartDate: any;
  public extendDummyVesselId: any;
  public startPort: any;
  public startDate: any;
  public capturedError: any;
  public cutOffPos: any;
  public scenarioId: any;
  public serviceDetails: any;
  public scheduleDatingDto = {
    startingPosition: 0,
    scheduleId: 0,
    proformaHeaderId: '',
    proformaId: '',
    scheduleStartPortId: '',
    scheduleStartPortName: '',
    scheduleStartDate: '',
    scheduleEndPortId: '',
    scheduleEndPortName: '',
    scheduleEndDate: '',
    defaultPhaseInPortId: '',
    defaultPhaseInPortName: '',
    defaultPhaseOutPortId: '',
    defaultPhaseOutPortName: '',
    portDetailsId: 0,
    vesselCount: 0,
    frequency: 7,
    lstBunkerPorts: null,
  };
  public currentScheduleDatingDto = {
    startingPosition: 0,
    scheduleId: 0,
    proformaHeaderId: '',
    proformaId: '',
    scheduleStartPortId: '',
    scheduleStartPortName: '',
    scheduleStartDate: '',
    scheduleEndPortId: '',
    scheduleEndPortName: '',
    scheduleEndDate: '',
    defaultPhaseInPortId: '',
    defaultPhaseInPortName: '',
    defaultPhaseOutPortId: '',
    defaultPhaseOutPortName: '',
    portDetailsId: 0,
    vesselCount: 0,
    frequency: 7,
    lstBunkerPorts: null,
  };
  public currentProformaIdData: any;
  public startHereRowIndex: any;
  public deleteSchedulePopup: boolean = false;
  public errorPopUp: boolean = false;
  public deleteSch: any;
  public deleteDeploymentId: any;
  public hasDatedScheduleFalse: boolean;
  public selectedVersion: any;
  public omitPortPopupi: any;
  public omitPortPopupj: any;
  public omitPortPopupk: any;
  public selctedDummyi: any;
  public selctedDummyj: any;
  public selctedDummyk: any;
  public selctedDeploymenti: any;
  public selctedDeploymentj: any;
  public selctedDeploymentk: any;
  public topPos: number = 0;
  public leftPos: number = 0;
  public isDeploymentUpdateAuth: boolean;
  public isDeploymentDeleteAuth: boolean;
  public extendDummyVesselType: any;
  public extendDummyVesselRedlDate: any;
  public showExeptionError: boolean;
  public schDatingData: any;
  public selectedRequestPos: any;
  public startedHereI: any;
  public startedHereJ: any;
  public startedHereK: any;
  public activeLink: string;
  public deleteRequestName: any;
  public vslFixtureID: any;
  public enableUse: any;
  public extraLoaderPopupShow: boolean;
  public isExtraloaderHidden: boolean;
  public extraLoaderPopupPosition: { left: string; top: string };
  public proformaIndex: number;
  public openCreateDummyPopup: boolean = false;
  public depDateExtraLoader: any;
  public showDetails: any;
  public showDetailFlag: any;
  public show: any;
  public toggle_off: any;
  public comment: any;
  public savedComment: any;
  public commentList: any;
  public savedCommentList: any;
  public showButtonList: any;
  public viewToDate = new UntypedFormControl(new Date());
  public portInfoStartHere: any;
  public returenedScheduleDate: any;
  public selectedPosition: any;
  public isSetStartHere: any;
  public portInfoStartHereNewSch: any;
  public returenedScheduleDateNewSch: any;
  public hideImportLimitMsg: boolean = false;
  public loading: boolean = false;
  public schDateAfterDateChangeNewSch: any;
  public expandedViewPositionArray: any = [];
  public selectedIsExtraloaderPos: any;
  public showSecondConfirmationDetails: boolean = false;
  public multiControls = {
    portsMultiCtrl: new UntypedFormControl(),
  };
  public selectedPorts: any;
  public bufferSize: any = 25;
  public portsBuffer = [];
  public portsForFilter = [];
  public hiddenPortInfo: any = [];
  public itemChecked: any;
  public itemUnchecked: any;
  public allportCheck: boolean = false;
  public isFirstTimeLoad: boolean = false;
  public deletePrfmId: any;
  public showFinalConfirmation: boolean = false;
  public deleteFinalMsg: string;
  public deleteFirstMsg: string;
  public noMappingSchMsg: any;
  public noMappingSch: boolean = false;
  public deleteFirstMsgExtend: string;
  public overlappingDeployment: boolean = false;
  public overlappingDeploymentArry: any = [];
  public isReportingRole: any;
  public nextPortInfo: any;
  public displayedNames = [];
  public isLastRowOfVessel = [];
  public vesselDepIDs = [];
  public isOverlapedVesselSame = false;
  public vesselNameListBelowPosition: string[] = [];
  public sidebarState: string = 'open';
  public isplannerLoading: boolean = false;
  public popupWidth: string;
  public popupHeight: string;
  public links: Array<any> = [];
  public sid: any;
  public isServiceUpdateAuth: boolean = false;
  public schhId: any;
  public scnId: any;
  public editMode: boolean = false;
  public activeLinkUrl: string;
  // public id: any;
  public schDetails: any;
  public approved: UntypedFormControl;
  public serviceOverviewData: any;
  public isImportedSuccess: any;
  public removable: boolean = true;
  public disableOverview: any;

  public filteredArray: any[] = [];
  public filteredArraySegments: any[] = [];

  public filtersList: any[] = [];
  public favList: any[] = [];
  public segList: any[] = [];
  public serList: any[] = [];
  public allSegList: any[] = [];
  public userName: any;
  public serviceIdLink: number;
  public segmentListOrig: any[];
  public serviceListOrig: any[];
  public vslSelectServiceFrmGrp: UntypedFormGroup;

  public multiControlsservice = {
    favouritesMultiCtrl: new UntypedFormControl(),
    segmentMultiCtrl: new UntypedFormControl(),
    serviceMultiCtrl: new UntypedFormControl(),
  };
  public routerLinkText: any;
  public isCreatedDated: any;
  public isfavouritesAdded: boolean;

  constructor(
    public dialog: MatDialog,
    public serviceRepoServie: ServiceRepositoryService,
    public route: ActivatedRoute,
    public dataService: DataServiceService,
    public formBuilder: UntypedFormBuilder,
    private scenarioService: ScenariosService,
    private dataScService: DataScenarioService,
    public router: Router,
    public auth: AuthService,
    private cdr: ChangeDetectorRef,
    private uiHelperService: UIHelperService,
    private titleService: Title,
    private sessionTimeoutService: SessionTimeoutService,
    private alertService: AlertService,
    private serviceRepoService: ServiceRepositoryService
  ) {
    this.vslSelectServiceFrmGrp = this.formBuilder.group({
      favouritesMultiCtrl: ['', Validators.required],
      segmentMultiCtrl: ['', Validators.required],
      serviceMultiCtrl: ['', Validators.required],
    });
    this.showDetails = [];
    this.showButtonList = [];
    this.showButtonList[0] = false;
    this.show = false;
    this.toggle_off = true;
    this.showDetailFlag = false;
    this.comment = null;
    this.savedComment = null;
    this.commentList = [];
    this.savedCommentList = [];
    this.commentList[0] = null;
    this.savedCommentList[0] = null;
    this.activeLink = router.url;
    this.activeLink.indexOf('schedule') === -1 && this.activeLink.indexOf('services') === -1
          ? (this.openPlanner = true)
          : (this.openPlanner = false);
    // router.events
    //   .pipe(
    //     filter((event: Event) => event instanceof NavigationEnd),
    //     takeUntil(this._onDestroy)
    //   )
    //   .subscribe((event: NavigationEnd) => {
    //     this.activeLink = event.url;
    //     this.activeLink.indexOf('schedule') === -1 && this.activeLink.indexOf('services') === -1
    //       ? (this.openPlanner = true)
    //       : (this.openPlanner = false);
    //   });
    //added
    this.schhId = this.route.snapshot.paramMap.get('schId');
    this.sid = this.route.snapshot.paramMap.get('id');
    router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        takeUntil(this._onDestroy)
      )
      .subscribe((event: NavigationEnd) => {
        this.activeLinkUrl = event.url;

        this.id = this.route.snapshot.paramMap.get('id');
        this.links = [
          {
            route: '/services/' + this.id + '/schedule/' + this.schhId + '/true',
            text: 'Overview',
          },

          {
            route: '/services/' + this.id + '/schedule/' + this.schhId + '/true' + '/deployments/true',
            text: 'Deployments',
          },
        ];
      });
    //added
  }
  @HostListener('window:scroll', ['$event'])
  public checkScroll() {
    // code for setting vessel name under position
    const columnLength = this.positionsLst.length;
    const selectedRow = Math.ceil((window.pageYOffset - 300) / 55);
    this.vesselNameListBelowPosition = [];
    if (selectedRow > 0) {
      for (let i = 0; i <= columnLength - 1; i++) {
        if (document.querySelector(`.row${selectedRow} .column${i} .vessel-name-div`)) {
          this.vesselNameListBelowPosition.push(
            document
              .querySelector(`.row${selectedRow} .column${i} .vessel-name-div`)
              .getAttribute('title')
              .replace('Phase-in: ', ' ')
          );
        } else {
          if (!document.querySelector(`.row${selectedRow} .column${i} .position-padding`)) {
            this.vesselNameListBelowPosition.push('');
          }
        }
      }
    }
    // this.topPos = window.pageYOffset;
    this.leftPos = window.pageXOffset;
    this.isSticky = window.pageYOffset >= 150;
  }

  @HostListener('window:mousemove') public refreshUserState() {
    clearTimeout(this.sessionTimeoutService.userActivity);
    if (this.showVesselSwitchRadioBtns) {
      this.sessionTimeoutService.setTimeout();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    const width = window.innerWidth * 0.45;
    const height = window.innerHeight * 0.45;
    this.popupWidth = (width > 650 ? width : 700) + 'px';
    this.popupHeight = (height > 300 ? height : 300) + 'px';
  }
  public ngOnInit() {
    this.setVesselDtoForQIcon = false;
    this.isDeploymentUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.isDeploymentDeleteAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'delete');
    this.setSubheaderDraftFrmGroup();
    this.setSubheaderFrmGroup();
    this.uiHelperService.currentSidebarState.subscribe((state) => {
      this.sidebarState = state;
    });
    !this.openPlanner
      ? (this.id = this.route.parent.snapshot.paramMap.get('id'))
      : (this.id = this.route.snapshot.paramMap.get('id'));
    !this.openPlanner
      ? (this.scenarioId = this.route.parent.snapshot.paramMap.get('scnId'))
      : (this.scenarioId = this.route.snapshot.paramMap.get('scnId'));
    !this.openPlanner
      ? (this.serviceRepo = this.route.parent.snapshot.paramMap.get('serviceRepo') === 'false' ? false : true)
      : (this.serviceRepo = this.route.snapshot.paramMap.get('serviceRepo') === 'false' ? false : true);
    if (this.openPlanner) {
      this.getServiceDetails();
    }
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.serviceRepo
      ? this.dataService.createDatedData.subscribe((res: any) => {
          this.createdDateData = res;
        })
      : this.dataScService.createDatedData.subscribe((res: any) => {
          this.createdDateData = res;
        });
    this.serviceRepo
      ? this.dataService.switchSchOverview.subscribe((res) => {
          this.isSwitchSchOverview = res;
        })
      : this.dataScService.switchSchOverview.subscribe((res) => {
          this.isSwitchSchOverview = res;
        });
    const dataScServi = this.serviceRepo ? this.dataService.schIDData : this.dataScService.schIDScData;
    dataScServi.subscribe((data) => {
      this.schIdScData = data;
    });
    this.dataService.isSetStartHere.subscribe((data) => {
      this.isSetStartHere = data;
    });
    if (this.isSetStartHere === true) {
      this.onSelectStartIndex(0, 0);
    }
    if (!this.createdDateData) {
      this.loading = true;
      this.getServicePlanningLst();
    } else {
      let i = 0;
      for (let j = 0; j < this.createdDateData.maxPosition; j++) {
        i++;
        this.positionsLst[j] = this.createdDateData!.listPortsDto[0]!.listPositionsDto[j]!.position;
      }
      const dataServ = this.serviceRepo ? this.dataService.schIDData : this.dataScService.schIDData;
      dataServ.subscribe((data) => {
        this.schIdData = data;
        /*  if (new Date(this.schDetails.validityTo).valueOf() - new Date(this.schDetails.validityFrom).valueOf() > 730) {
           this.isLimitTwoyear = true;
         } */
        // Logic Change Here to show ETA and ETD for valid From and valid to of Porfornma Header
        this.subHeaderDraftFrmGrp.patchValue({
          // toDate: this.setArrivalDate(this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[0], this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[1]).scheduleDate
          toDate: this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[
            this.positionsLst.length - 1
          ].listArrivalDepartureDto[1].scheduleDate,
        });
        this.subHeaderDraftFrmGrp.patchValue({
          // fromDate: this.setDeptDate(this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0], this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[1]).scheduleDate
          fromDate: this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate,
        });
      });
      this.serviceRepo
        ? this.dataService.hasDatedScheduleFalse.subscribe((data: any) => {
            this.hasDatedScheduleFalse = data;
          })
        : this.dataScService.hasDatedScheduleFalse.subscribe((data: any) => {
            this.hasDatedScheduleFalse = data;
          });
      this.portInfoStartHereNewSch = this.createdDateData.listPortsDto[0];
      if (this.portInfoStartHereNewSch) {
        // Logic Change Here to show ETA for valid From of Porfornma Header
        //  this.returenedScheduleDateNewSch = this.setDeptDate(((this.portInfoStartHereNewSch.listPositionsDto)[0].listArrivalDepartureDto)[0],((this.portInfoStartHereNewSch.listPositionsDto)[0].listArrivalDepartureDto)[1]).scheduleDate;
        this.returenedScheduleDateNewSch = this.portInfoStartHereNewSch.listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate;
        this.changeFromDate(this.returenedScheduleDateNewSch, this.portInfoStartHereNewSch, 'draft', 0, 0, '', '');
      }
    }

    this.multiControls.portsMultiCtrl.valueChanges.subscribe((portFilterVal: any) => {
      if (this.isFirstTimeLoad) {
        this.filterDeploymentsByPort();
        this.isFirstTimeLoad = false;
      }
    });

    if (this.auth.BYPASS_AUTHENTICATION) {
      this.isReportingRole = false;
    } else {
      this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
    }
    if (this.servicePlanningLst) {
      this.setVesselNameDisplayedDetailsMap(this.servicePlanningLst.maxPosition);
    }

    //added
    this.isServiceUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    // this.isReportingRole = this.auth.isRolePresent(this.auth.roles, 'Reporting');
    this.getSchDetail();
    this.dataService.data.subscribe((data) => {
      this.serviceOverviewData = data;
    });
    this.dataService.schIDData.subscribe((data) => {
      this.schIdData = data;
    });
    this.dataService.isImportedSuccess.subscribe((data) => {
      this.isImportedSuccess = data;
    });
    // this.setPreSetFiltersForFav();

    if (this.auth.BYPASS_AUTHENTICATION) {
      this.isReportingRole = false;
    } else {
      this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
    }
  }

  public authenticationCheck() {
    if (this.isDeploymentUpdateAuth === false) {
      return {
        disabled: true,
      };
    }
  }
  public setCurentHeader() {
    const currentHeader = {
      proformaHeaderId: this.servicePlanningLst.lstProformaListDto[0].proformaHeaderId,
      scheduleVersion: this.servicePlanningLst.lstProformaListDto[0].gsisVersion,
      serviceId: this.servicePlanningLst.serviceId,
      serviceCode: this.servicePlanningLst.serviceCode,
      serviceName: this.servicePlanningLst.serviceName,
      proformaID: this.servicePlanningLst.lstProformaListDto[0].proformaId,
      validityFrom: this.servicePlanningLst.lstProformaListDto[0].validFrom,
      validityTo: this.servicePlanningLst.lstProformaListDto[0].validTo,
      rotationDays: this.servicePlanningLst.lstProformaListDto[0].roundTripDays,
      vesselCount: this.servicePlanningLst.lstProformaListDto[0].vesselCount,
      isVisible: this.servicePlanningLst.lstProformaListDto[0].isVisible,
      createdBy: this.servicePlanningLst.lstProformaListDto[0].createdBy,
      createdDate: this.servicePlanningLst.lstProformaListDto[0].createdDate,
      status: this.servicePlanningLst.lstProformaListDto[0].status,
      isDeploymentUser: this.servicePlanningLst.lstProformaListDto[0].isDeploymentUser,
      scheduleStatus: this.servicePlanningLst.lstProformaListDto[0].scheduleStatus,
      hasDatedSchedule: this.servicePlanningLst.lstProformaListDto[0].hasDatedSchedule,
      isDeleted: this.servicePlanningLst.lstProformaListDto[0].isDeleted,
    };
    this.serviceRepo
      ? this.dataService.updateSchCurrentData(currentHeader)
      : this.dataScService.updateSchCurrentData(currentHeader);
  }

  public onSelectStartIndex(selectedIndex: any, rowIndex: any) {
    this.startHereIndex = selectedIndex;
    this.startHereRowIndex = rowIndex;
  }

  public showVisibleDateForm() {
    this.showVisibleForm = !this.showVisibleForm;
  }
  public showVesselDeploymentForm() {
    this.showVesselDeplyment = !this.showVesselDeplyment;
  }
  public showDummyForm() {
    // this.showDummy = !this.showDummy;
  }
  public setVesselDto(vesselDto: any) {
    this.vesselDto = vesselDto;
  }

  public openVesselPopupInfo($event, portInfo, i, j, k) {
    if ($event.target.tagName === 'DIV' || $event.target.tagName === 'svg') {
      if (portInfo && portInfo.lstScheduleListDto[j]) {
        const cellInfo = portInfo.lstScheduleListDto[j];
        this.setDeptDate(cellInfo.lstPositionDto[0], cellInfo.lstPositionDto[1]).vesselDto &&
        !(this.cutIndex === i && this.cutLocation === 'departure' && cellInfo.lstPositionDto[1].vesselDto)
          ? (cellInfo.showVesselDeployment = true)
          : (cellInfo.showVesselDeployment = false);

        if (cellInfo.showVesselDeployment) {
          this.setVesselDto(
            this.setDeptDate(cellInfo.lstPositionDto[0], cellInfo.lstPositionDto[1]).overlapVesselDto == null
              ? this.setDeptDate(cellInfo.lstPositionDto[0], cellInfo.lstPositionDto[1]).vesselDto
              : this.validateVessel(portInfo, j)
          );
        } else {
          if (!this.setVesselDtoForQIcon) {
            this.setVesselDto(
              this.setDeptDate(cellInfo.lstPositionDto[0], cellInfo.lstPositionDto[1]).overlapVesselDto == null
                ? this.setDeptDate(cellInfo.lstPositionDto[0], cellInfo.lstPositionDto[1]).vesselDto
                : this.validateVessel(portInfo, j)
            );
          }
        }
        if (cellInfo.showVesselDeployment) {
          this.setVslPopupPosition($event, i, j, k);
        } else {
          if (this.selctedDeploymenti > 0 || this.selctedDeploymenti === 0) {
            this.closeVesselForm(
              { type: 'close' },
              this.selctedDeploymentk,
              this.selctedDeploymenti,
              this.selctedDeploymentj
            );
          }
        }
      }
    }
  }

  public checkLastRowOfVessel(arrivalVessel?: any, deptVessel?: any, departureVesselDto?: any) {
    if (arrivalVessel && deptVessel) {
      if (arrivalVessel.vesselId !== deptVessel.vesselId) {
        if (departureVesselDto && arrivalVessel.vesselId === departureVesselDto.vesselId) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else if (arrivalVessel && !deptVessel) {
      if (departureVesselDto && arrivalVessel.vesselId === departureVesselDto.vesselId) {
        return false;
      } else {
        return true;
      }
    }
  }
  public setImgAtETD(departureVessel?: any, lstPortsListDto?: any, i?: number, j?: number) {
    let nextArrivalId = 0;
    let currentDepartureId = 0;
    if (departureVessel && i < lstPortsListDto.length) {
      if (departureVessel.vesselDto) {
        currentDepartureId = departureVessel.vesselDto.vesselDeploymentId;
        if (
          lstPortsListDto[i].lstScheduleListDto[j] &&
          lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto
        ) {
          nextArrivalId = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselDeploymentId;
        }
      }
      if (currentDepartureId && currentDepartureId !== undefined && nextArrivalId !== undefined) {
        if (currentDepartureId !== nextArrivalId) {
          return true;
        } else {
          return false;
        }
      }
    } else if (i === lstPortsListDto.length) {
      if (
        lstPortsListDto[i - 1].lstScheduleListDto[j] &&
        lstPortsListDto[i - 1].lstScheduleListDto[j].lstPositionDto[1].vesselDto &&
        lstPortsListDto[i - 1].lstScheduleListDto[j].lstPositionDto[1].vesselDto.showEndDateIcon
      ) {
        return true;
      }
    }
  }

  public setImgAtOverlapVesselETD(departureVessel?: any, lstPortsListDto?: any, i?: number, j?: number) {
    let nextArrivalId = 0;
    let currentDepartureId = 0;
    if (departureVessel && i < lstPortsListDto.length) {
      if (departureVessel.overlapVesselDto) {
        currentDepartureId = departureVessel.overlapVesselDto.vesselId;
        if (
          lstPortsListDto[i].lstScheduleListDto[j] &&
          lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto
        ) {
          nextArrivalId = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto.vesselId;
        } else {
          if (
            lstPortsListDto[i].lstScheduleListDto[j] &&
            lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto
          ) {
            nextArrivalId = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselId;
          }
        }
      }
      if (currentDepartureId && currentDepartureId !== undefined && nextArrivalId !== undefined) {
        if (currentDepartureId !== nextArrivalId) {
          return true;
        } else {
          return false;
        }
      }
    } else if (i === lstPortsListDto.length) {
      if (
        lstPortsListDto[i - 1].lstScheduleListDto[j] &&
        lstPortsListDto[i - 1].lstScheduleListDto[j].lstPositionDto[1].vesselDto &&
        lstPortsListDto[i - 1].lstScheduleListDto[j].lstPositionDto[1].vesselDto.showEndDateIcon
      ) {
        return true;
      }
    }
  }
  public showRunningDepLine(deptVessel?: any, lstPortsListDto?: any, i?: any, j?: any) {
    // same deployment across two proformas of same service
    if (deptVessel && i === lstPortsListDto.length) {
      if (deptVessel.runningDeploymentLine || deptVessel.cutVesselParent) {
        return true;
      } else {
        return false;
      }
    }
    if (deptVessel && i < lstPortsListDto.length) {
      if (lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto) {
        const nextArrival = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto;
        const nextDeparture = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[1].vesselDto;
        if (nextArrival) {
          return false;
        } else if (deptVessel.runningDeploymentLine) {
          if (deptVessel.cutVesselParent) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }

  /* Showing blue cut icon at arrival*/

  public showRunningDepLineForArrival(deptVessel?: any, lstPortsListDto?: any, i?: any, j?: any) {
    // same deployment across two proformas of same service
    if (deptVessel && i === lstPortsListDto.length) {
      if (deptVessel.runningDeploymentLine || deptVessel.cutVesselParent) {
        return true;
      } else {
        return false;
      }
    }

    if (deptVessel && i < lstPortsListDto.length) {
      if (lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto) {
        const nextArrival = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[0].vesselDto;
        const nextDeparture = lstPortsListDto[i].lstScheduleListDto[j].lstPositionDto[1].vesselDto;
        if (nextArrival) {
          /* below condition when nextarrival is present but not nextdeparture and when cut is performed at arrival*/
          if (
            deptVessel.vesselId === nextArrival.vesselId &&
            nextDeparture === null &&
            deptVessel.runningDeploymentLine
          ) {
            // if (deptVessel.vesselId !== nextArrival.vesselId  && deptVessel.runningDeploymentLine) {
            // return true;
            if (deptVessel.cutVesselParent) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else if (deptVessel.runningDeploymentLine) {
          /*cutVesselParent flag to identify the parent proforma from where cut was performed along with runningDeploymentLine flag*/
          if (deptVessel.cutVesselParent) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }

  public setVersionPort(k, i, portDate?: any) {
    this.versionPorts = this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i];
    this.omitFromToDate = {
      fromDate: portDate
        ? portDate.lstPositionDto[0].scheduleType === 0
          ? portDate.lstPositionDto[0]
          : portDate.lstPositionDto[1].scheduleType === 0
          ? portDate.lstPositionDto[1]
          : null
        : null,
      toDate: portDate
        ? portDate.lstPositionDto[0].scheduleType === -1
          ? portDate.lstPositionDto[0]
          : portDate.lstPositionDto[1].scheduleType === -1
          ? portDate.lstPositionDto[1]
          : null
        : null,
    };
  }
  public closeAllPopup(portDate: any) {
    /* if (portDate) {
      portDate.showDummy = false;
      portDate.showOmit.status = false;
      portDate.createDummy = false;
      portDate.showVesselDeployment = false;
    } */
  }
  public setCreateDummyFromDate(fromDate, portLst?: any, pos?: any) {
    this.posPortLst = [];
    this.createDummyFromDate = fromDate;
    for (const portsLSt of portLst.lstPortsListDto) {
      for (const posLst of portsLSt.lstScheduleListDto) {
        if (posLst.position === pos) {
          this.posPortLst.push({
            cityId: portsLSt.cityId,
            cityName: portsLSt.cityName,
            lstPositionDto: posLst.lstPositionDto,
            isExtraloaderPosition: posLst.isExtraloaderPosition,
          });
        }
      }
    }
  }

  public closeCreateForm(e) {
    if (e.type === 'close') {
      this.showVisibleForm = false;
    }
  }
  public closeVesselDeployment(e) {
    if (e.type === 'close') {
      this.showVesselDeplyment = false;
    }
  }
  public endDeployment(e) {
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postEndDeployment(e.postData)
      : this.scenarioService.postEndDeployment(e.postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.getServicePlanningLst();
      }
    });
  }
  public addCommenton(e) {
    // commenting code as it might be taken up in next sprint.
    // let  result = '';
    // let searchTerm =' ';
    // let indexOfFirst = e.postData.comments.indexOf(searchTerm , 57);
    // while (e.postData.comments.length > 0 ) {
    //   result += e.postData.comments.substring(0,indexOfFirst) + '\n';
    //   e.postData.comments = e.postData.comments.substring(indexOfFirst);
    //   if(e.postData.comments.length > 57){
    //     indexOfFirst = e.postData.comments.indexOf(searchTerm , 57);
    //   }
    //   else{
    //     result += e.postData.comments;
    //     break;
    //   }
    // }
    // e.postData.comments = result;
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postAddCommentService(e.postData)
      : this.scenarioService.postAddCommentService(e.postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.omitPortPopupi = null;
        this.omitPortPopupj = null;
        this.omitPortPopupk = null;
        this.getServicePlanningLst();
      }
    });
  }
  public setDate(e) {
    this.showVisibleForm = false;
    this.expandedViewPositionArray = [];
    const serv = this.serviceRepo
      ? this.serviceRepoServie.getAdujstingVisibleDate(this.activeLink.split('/')[4], e.from, e.end)
      : this.scenarioService.getAdujstingVisibleDate(this.activeLink.split('/')[4], e.from, e.end, this.scenarioId);
    serv.subscribe((res: any) => {
      this.setPositionVisibility(res);
      this.showVisibleForm = false;
    });
  }
  public setOmit(e) {
    window.scroll(0, 0);
    this.loading = true;
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postOmitPortUpdateService(e.postData)
      : this.scenarioService.postOmitPortUpdateService(e.postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.omitPortPopupi = null;
        this.omitPortPopupj = null;
        this.omitPortPopupk = null;
        this.loading = false;
        this.getServicePlanningLst();
      }
    });
  }
  public closeExtraloaderForm(e) {
    this.extraLoaderPopupShow = false;
    switch (e.type) {
      case 'addExtraLoader': {
        this.omitPortPopupi = this.omitPortPopupj = this.omitPortPopupk = this.showExtraLoaderPopup = null;
        this.getServicePlanningLst(false, e.data.position);
        return;
      }
      case 'showExtraLoader': {
        this.omitPortPopupi = this.omitPortPopupj = this.omitPortPopupk = this.showExtraLoaderPopup = null;
        this.setPositionVisibility(this.servicePlanningLst, e.data.position);
        return;
      }
    }
  }
  public closeDummyForm(e, k?: any, i?: any, j?: any) {
    if (e.type === 'close') {
      //  this.showDummy = false;
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showDummy = false;
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].createDummy = false;
      this.openCreateDummyPopup = false;
    }
    if (e.type === 'save') {
      //  this.showDummy = false;
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showDummy = false;
      this.openCreateDummyPopup = false;
      this.getServicePlanningLst();
    } else if (e.type === 'popupErrorVessel') {
      this.capturedError = e.error;
      this.dialogRef = this.dialog.open(this.confirmationPopUpDialog, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
        autoFocus: false,
      });
    }
  }
  public closeVesselForm(e, k?: any, i?: any, j?: any) {
    if (e.type === 'close') {
      this.alertService.alertVesselId.next(null);
      this.alertService.clear();
      //  this.showDummy = false;
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[
        j
      ].showVesselDeployment = false;

      (<HTMLElement>document.getElementsByClassName('vessel-dep-popup')[0]).style.display = 'none';
      this.selctedDeploymenti = null;
      this.selctedDeploymentj = null;
      this.selctedDeploymentk = null;
    }
    if (e.type === 'closeExtends') {
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[
        j
      ].showVesselDeployment = false;
      this.getServicePlanningLst();
    }
    if (e.type === 'closeComment') {
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[
        j
      ].showVesselDeployment = false;
      this.getServicePlanningLst();
    }
  }
  public closeCreateOmit(e, k?: any, i?: any, j?: any) {
    if (e.type === 'close') {
      //  this.showDummy = false;
      this.omitPortPopupi = this.omitPortPopupj = this.omitPortPopupk = this.showExtraLoaderPopup = null;
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showOmit.status = false;
    }
  }
  public showOmit(flag: boolean) {
    return flag;
  }
  public setMaxDate(portData, type) {
    if (type === 'notDraft' && this.positionsLst) {
      return this.setDeptDate(
        portData.lstScheduleListDto[this.positionsLst.length - 1].lstPositionDto[0],
        portData.lstScheduleListDto[this.positionsLst.length - 1].lstPositionDto[1]
      ).scheduleDate;
    } else if (this.positionsLst) {
      return this.setDeptDate(
        portData.listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[0],
        portData.listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[1]
      ).scheduleDate;
    }
  }
  public setMinDate(portData, type) {
    if (type === 'notDraft' && this.positionsLst) {
      // lOGIC FOr ETD
      // return this.setArrivalDate(portData.lstScheduleListDto[0].lstPositionDto[0], portData.lstScheduleListDto[0].lstPositionDto[1]).scheduleDate;
      return portData.lstScheduleListDto[0].lstPositionDto[1].scheduleDate;
    } else if (this.positionsLst) {
      // return this.setArrivalDate(portData.listPositionsDto[0].listArrivalDepartureDto[0], portData.listPositionsDto[0].listArrivalDepartureDto[1]).scheduleDate;
      return portData.listPositionsDto[0].listArrivalDepartureDto[1].scheduleDate;
    }
  }
  public setVesselMap(n: number) {
    for (let i = 0; i < n; i++) {
      this.vesselMap[i] = { vessedlId: -1, vesselDeploymentId: -1 };
      this.vesselPrevMap[i] = { vessedlId: -1, vesselDeploymentId: -1 };
      this.vesselNameDisplayedDetails[i] = [];
      this.dummyMap[i] = -1;
      this.dummyPrevMap[i] = -1;
    }
  }

  public setVesselNameDisplayedDetailsMap(n: number) {
    for (let i = 0; i < n; i++) {
      this.vesselNameDisplayedDetails[i] = [];
    }
  }
  public getColor(vesselColor?: string) {
    const colorInHSL = this.convertHexCodeToHSL(vesselColor, '', '');
    return colorInHSL;
  }

  public getTextColor(dto) {
    let isDummyRequest = false;
    if (dto) {
      if (dto.isOverlapDeployment) {
        isDummyRequest =
          dto.overlapVesselDto && dto.overlapVesselDto.vesselType === 4 && dto.overlapVesselDto.vesselCharterRequestId
            ? true
            : false;
      } else {
        isDummyRequest =
          dto.vesselDto && dto.vesselDto.vesselType === 4 && dto.vesselDto.vesselCharterRequestId ? true : false;
      }
    }
    return isDummyRequest;
  }

  public getBGColor(vesselColor?: string) {
    const bgColorInHSL = this.convertHexCodeToHSL(vesselColor, 0, 0);
    return bgColorInHSL;
  }
  /*added convertHexCodeToHSL function for conversion of hexCodes to hsl */
  public convertHexCodeToHSL(vesselColor, sat, lig) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(vesselColor);
    if (result) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);

      (r /= 255), (g /= 255), (b /= 255);
      const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      let h,
        s,
        l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }

      s = s * 100;
      s = Math.round(s);
      l = l * 100;
      l = Math.round(l);
      h = Math.round(360 * h);
      if (sat === 0 && lig === 0) {
        return 'hsla(' + h + ',' + sat + '%,' + lig + '%,' + '0.5)';
      } else {
        return 'hsla(' + h + ',' + s + '%,' + l + '%,' + '0.3)';
      }
    }
  }

  public showImg(vesselDto) {
    if (vesselDto) {
      if (vesselDto.nextDeploymentOnThisService) {
        // below condition added for not displaying down arrow on parent cut vessel
        if (!vesselDto.cutVesselParent) {
          return 'assets/icons/arrow-down-planning.svg';
        } else {
          return null;
        }
        // return 'assets/icons/arrow-down-planning.svg';
      } else if (vesselDto.redeliverAfterThisDeployment) {
        return 'assets/icons/arrow-right-planning.svg';
      } else if (vesselDto.nextDeploymentOnThisServiceDummy) {
        return 'assets/icons/arrow-right-planning.svg';
      } else {
        if (!vesselDto.cutVesselParent && !vesselDto.lstNextActionListDto) {
          return 'assets/icons/question-circle-planning.svg';
        } else if (!vesselDto.cutVesselParent && vesselDto.lstNextActionListDto) {
          return 'assets/icons/arrow-right-planning.svg';
        } else {
          return null;
        }
        // return 'assets/icons/question-circle-planning.svg';
      }
    }
  }
  public addLeft(rowPos, colPos, versionPos) {
    if (this.isCutoff) {
      return false;
    }
    if (this.selectedRow.includes(rowPos) && this.selectedPos === colPos && this.selectedVersion === versionPos) {
      return true;
    } else {
      return false;
    }
  }
  public addTop(rowPos, colPos, versionPos, maxPosition) {
    if (this.isCutoff) {
      return false;
    }
    if (
      this.selectedRow.includes(rowPos) &&
      colPos >= this.selectedPos &&
      colPos < maxPosition &&
      this.selectedRowIndex === rowPos &&
      this.selectedVersion === versionPos
    ) {
      return true;
    } else {
      return false;
    }
  }
  public getCutOff(portInfo, i, j, versionPos, selectedPortID, pos) {
    this.selectedRow = [];
    this.selectedPos = j;
    this.selectedRowIndex = i;
    this.selectedRow[0] = i;
    this.cutOffPos = pos;
    this.selectedVersion = versionPos;
    for (let k = i + 1; k < portInfo.length; k++) {
      if (portInfo[k].proformaPortId !== selectedPortID) {
        this.selectedRow.push(k);
      } else {
        break;
      }
    }
  }
  public setCurrentSchDating(schList, maxPosition, pos?: any) {
    const servicePlanningViewPortsDtos = [];
    this.currentScheduleDatingDto.proformaHeaderId = schList.proformaHeaderId;
    this.currentScheduleDatingDto.proformaId = schList.proformaId;
    pos
      ? (this.currentScheduleDatingDto.startingPosition = pos)
      : (this.currentScheduleDatingDto.startingPosition = this.cutOffPos);
    // this.currentScheduleDatingDto.scheduleStartPortId = schList.lstPortsListDto[0].proformaPortId;
    // this.currentScheduleDatingDto.scheduleStartPortName = schList.lstPortsListDto[0].proformaPortName;
    // this.currentScheduleDatingDto.scheduleStartDate = this.setArrivalDate(schList.lstPortsListDto[0].lstScheduleListDto[0].lstPositionDto[0], schList.lstPortsListDto[0].lstScheduleListDto[0].lstPositionDto[1]).scheduleDate;

    this.currentScheduleDatingDto.scheduleEndPortId = schList.lstPortsListDto[this.selectedRowIndex].proformaPortId;
    this.currentScheduleDatingDto.scheduleEndPortName = schList.lstPortsListDto[this.selectedRowIndex].proformaPortName;
    for (let i = 0; i < schList.lstPortsListDto.length; i++) {
      servicePlanningViewPortsDtos.push(schList.lstPortsListDto[i]);
    }
    this.currentProformaIdData = {
      scheduleDatingDto: '',
      servicePlanningViewPortsDtos: servicePlanningViewPortsDtos,
    };
  }
  public setCutHere(schDetails, ports, portInfo, cuttOffDate, schList, maxPosition, pos?: any) {
    if (schList) {
      this.setCurrentSchDating(schDetails, maxPosition, pos);
    }
    const portIdLst = [];
    this.selectedVersion = '';
    for (let i = 0; i <= this.selectedRowIndex; i++) {
      portIdLst.push(ports[i].proformaPortId);
    }
    this.serviceRepo
      ? this.dataService.schIDData.subscribe((data) => {
          this.schIdData = data;
        })
      : this.dataScService.schIDData.subscribe((data) => {
          this.schIdData = data;
        });
    const postData = {
      serviceId: this.servicePlanningLst.serviceId,
      proformaId: schDetails.proformaId,
      lstProformaPortIds: portIdLst,
      fromDate: this.servicePlanningLst.viewFromDate,
      cutOffDate: cuttOffDate,
    };
    const postDataSc = {
      scenarioId: this.scenarioId,
      scheduleDatingCutHereDto: {
        serviceId: this.servicePlanningLst.serviceId,
        proformaId: schDetails.proformaId,
        lstProformaPortIds: portIdLst,
        fromDate: this.servicePlanningLst.viewFromDate,
        cutOffDate: cuttOffDate,
      },
    };
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postSchedulePlannerScheduleCutHere(postData)
      : this.scenarioService.postSchedulePlannerScheduleCutHere(postDataSc);
    serv.subscribe((res: any) => {
      if (res) {
        this.positionsLst = [];
        let i = 0;
        for (let j = this.cutOffPos; j <= res.maxPosition; j++) {
          this.positionsLst[i] = j;
          i++;
        }
        for (let j = 1; j < this.cutOffPos; j++) {
          this.positionsLst[i] = j;
          i++;
        }
        this.servicePlanningLst = res;
        const length = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto.length;
        this.scheduleEndDate = this.setDeptDate(
          this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto[length - 1].lstScheduleListDto[maxPosition - 1]
            .lstPositionDto[0],
          this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto[length - 1].lstScheduleListDto[maxPosition - 1]
            .lstPositionDto[1]
        ).scheduleDate;
        this.draftCutOff(schDetails, portInfo, cuttOffDate);
        this.isCutoff = true;
        this.onSelectStartIndex(0, 0);
      }
    });
  }
  public undoCut() {
    this.serviceRepo
      ? this.dataService.updatedCreatedDateSelection('')
      : this.dataScService.updatedCreatedDateSelection('');
    this.getServicePlanningLst();
    this.isCutoff = false;
    // this.isUseDatingDisable = true;
    // this.isLimitTwoyear = false;
  }
  public draftCutOff(schDetails, portInfo, cuttOffDate) {
    const postData = {
      serviceOverviewSchedulesDto: {
        prhPKId: schDetails.proformaHeaderId,
        prhProformaID: schDetails.proformaId,
        prhStatus: 'Draft',
        prhGSISVersion: schDetails.gsisVersion,
        prhValidityFrom: schDetails.validFrom,
        prhValidityTo: schDetails.validTo,
        prhRotationDays: schDetails.roundTripDays,
        pdVesselCount: schDetails.vesselCount,
        pdStatus: schDetails.versionStatus,
        prhIsVisible: schDetails.isVisible,
        prhIsIgnored: false,
        prhIsDeleted: false,
      },
      cutOffDate: cuttOffDate,
      cutOffStartProformaPortId: portInfo.proformaPortId,
      serviceOverviewSchedulesDtoNew: {
        prhPKId: this.schIdData.prhPKId,
        prhProformaID: this.schIdData.prhProformaID,
        prhStatus: this.schIdData.prhStatus,
        prhGSISVersion: this.schIdData.prhGSISVersion,
        prhValidityFrom: cuttOffDate,
        prhValidityTo: this.schIdData.prhValidityTo,
        prhRotationDays: this.schIdData.prhRotationDays,
        pdVesselCount: this.schIdData.pdVesselCount,
        pdStatus: this.schIdData.pdStatus,
        prhIsVisible: this.schIdData.prhIsVisible,
        prhIsIgnored: this.schIdData.prhIsIgnored,
        prhIsDeleted: this.schIdData.prhIsDeleted,
      },
    };

    const postDataSc = {
      serviceOverviewSchedulesDto: {
        prhPKId: schDetails.proformaHeaderId,
        prhProformaID: schDetails.proformaId,
        prhStatus: 'Draft',
        prhGSISVersion: schDetails.gsisVersion,
        prhValidityFrom: schDetails.validFrom,
        prhValidityTo: schDetails.validTo,
        prhRotationDays: schDetails.roundTripDays,
        pdVesselCount: schDetails.vesselCount,
        pdStatus: schDetails.versionStatus,
        prhIsVisible: schDetails.isVisible,
        prhIsIgnored: false,
        prhIsDeleted: false,
      },
      cutOffDate: cuttOffDate,
      cutOffStartProformaPortId: portInfo.proformaPortId,
      serviceOverviewSchedulesDtoNew: {
        prhPKId: this.schIdScData.proformaHeaderId,
        prhProformaID: this.schIdScData.proformaID,
        prhStatus: this.schIdScData.scheduleStatus,
        prhGSISVersion: this.schIdScData.scheduleVersion,
        prhValidityFrom: cuttOffDate,
        prhValidityTo: this.schIdScData.validityTo,
        prhRotationDays: this.schIdScData.rotationDays,
        pdVesselCount: this.schIdScData.vesselCount,
        pdStatus: this.schIdScData.status,
        prhIsVisible: this.schIdScData.isVisible,
        prhIsIgnored: this.schIdScData.scheduleStatus === 'Ignored' ? true : false,
        prhIsDeleted: this.schIdScData.isDeleted,
      },
    };

    this.deployLst = postData.serviceOverviewSchedulesDto;
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postSchedulePlannerForNewScheduleCutOff(postData)
      : this.scenarioService.postSchedulePlannerForNewScheduleCutOff(postDataSc);
    serv.subscribe((res: any) => {
      if (res) {
        this.portInfoStartHere = res.listPortsDto[0];
        if (this.portInfoStartHere) {
          // Logic changed for ETA and ETD OF PORFORMA header
          // this.returenedScheduleDate=this.setArrivalDate(((this.portInfoStartHere.listPositionsDto)[0].listArrivalDepartureDto)[0],((this.portInfoStartHere.listPositionsDto)[0].listArrivalDepartureDto)[1]).scheduleDate;
          this.returenedScheduleDate = this.portInfoStartHere.listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate;
          this.selectedPosition = this.portInfoStartHere.listPositionsDto[0].position;
          this.changeFromDate(
            this.returenedScheduleDate,
            this.portInfoStartHere,
            'draft',
            0,
            0,
            '',
            this.selectedPosition
          );
        }

        // if (new Date(this.schIdData.prhValidityTo).valueOf() - new Date(this.schIdData.prhValidityFrom).valueOf() > 730) {
        //   this.isLimitTwoyear = true;
        // }
        // Logic changed for ETA and ETD OF PORFORMA header
        this.subHeaderDraftFrmGrp.patchValue({
          // fromDate: this.setDeptDate(res.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0], res.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[1]).scheduleDate
          fromDate: res.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate,
        });
        this.subHeaderDraftFrmGrp.patchValue({
          // toDate: this.setArrivalDate(res.listPortsDto[res.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[0], res.listPortsDto[res.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[1]).scheduleDate
          toDate:
            res.listPortsDto[res.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1]
              .listArrivalDepartureDto[1].scheduleDate,
        });
        this.serviceRepo
          ? this.dataService.updatedCreatedDateSelection(res)
          : this.dataScService.updatedCreatedDateSelection(res);
      }
    });
  }
  public schDeletePopup() {
    this.dialogRef = this.dialog.open(this.warningDialog, {
      width: '420px',
      height: 'auto',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }
  public openDeleteSchPopup() {
    this.showFinalConfirmation = false;
    this.overlappingDeployment = false;
    this.noMappingSch = false;
    this.deletePrfmId = this.activeLink.split('/')[4];
    const postData = {
      genericRequestID1: this.deletePrfmId,
      genericRequestID2: 0,
      genericString1: '',
      genericString2: '',
      genericFlag: false,
    };
    this.serviceRepoServie.DeleteProformaHeader(postData).subscribe(
      (response) => {
        if (response === true) {
          this.deleteFirstMsg = 'Are you sure you want to delete the proforma ' + this.deletePrfmId + '?';
          this.deleteFinalMsg =
            'Proforma ' +
            this.deletePrfmId +
            'will be permanently deleted.Do you want to cancel the permanent delete operation?';
          this.schDeletePopup();
        }
      },
      (error) => {
        if (error.error.code === 'ServiceMappingScheduleNotFound') {
          this.noMappingSch = true;
          this.noMappingSchMsg = error.error.error;
          this.schDeletePopup();
        } else if (error.error.code === 'ServiceMappingScheduleOverlappingDeployment') {
          this.overlappingDeploymentArry = error.error.data;
          this.overlappingDeployment = true;
          this.deleteFirstMsgExtend = 'Are you sure you want to delete the proforma ' + this.deletePrfmId + '?';
          this.deleteFinalMsg =
            'Proforma ' +
            this.deletePrfmId +
            'will be permanently deleted.Do you want to cancel the permanent delete operation?';
          this.schDeletePopup();
        }
      }
    );
  }
  public deletePrfSchedule(confirm, ovrlap?: any) {
    if (confirm === 'First') {
      this.showFinalConfirmation = true;
      if (ovrlap === true) {
        this.overlappingDeployment = false;
      }
    } else if (confirm === 'Final') {
      this.showFinalConfirmation = false;
      const postData = {
        genericRequestID1: this.deletePrfmId,
        genericRequestID2: 0,
        genericString1: '',
        genericString2: '',
        genericFlag: true,
      };
      this.serviceRepoServie.DeleteProformaHeader(postData).subscribe((response) => {
        if (response) {
          this.dataService.setData1(true);
          this.router.navigate(['/services']);
        }
      });
    }
  }

  public redirectToOverview(row) {
    if (row.vessel_status_id === 3 || row.vessel_status_id === 2) {
      // Request OR Dummy
      window.open('/vessels/dummy/overview/' + row.vslPKID, '_blank', 'noreferrer');
    } else if (row.vessel_status_id === null || row.vessel_status_id === '') {
      // vessel
      window.open('/vessels/' + row.vslPKID, '_blank', 'noreferrer');
    }
  }
  public deletePlanVesselDeployment() {
    const postData = {
      vesselDeploymentId: this.deleteDeploymentId,
      lstScheduleIds: null,
    };
    const serv = this.serviceRepo
      ? this.serviceRepoServie.deleteVesselDeployment(postData)
      : this.scenarioService.deleteVesselDeployment(postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.dialogRef.close();
        this.getServicePlanningLst();
      }
    });
  }
  public deleteRotation() {
    const postData = {
      prhPKId: this.deleteSch.proformaHeaderId,

      prhProformaID: this.deleteSch.proformaId,

      prhStatus: 'Draft',

      prhGSISVersion: this.deleteSch.gsisVersion,

      prhValidityFrom: this.deleteSch.validFrom,

      prhValidityTo: this.deleteSch.validTo,

      prhRotationDays: this.deleteSch.roundTripDays,

      pdVesselCount: this.deleteSch.vesselCount,

      pdStatus: this.deleteSch.versionStatus,

      prhIsVisible: this.deleteSch.isVisible,

      prhIsIgnored: false,
      prhIsDeleted: true,
    };

    const serv = this.serviceRepo
      ? this.serviceRepoServie.postUpdateSchedule(postData)
      : this.scenarioService.postUpdateSchedule(postData);
    serv.subscribe((res) => {
      if (res) {
        this.getServicePlanningLst();
        this.dataService.setData1(res);
        this.router.navigate(['/services']);
      }
      /* this.router.navigateByUrl('/services/' + this.id + '/deployments' + this.scheduleDatingDto.proformaId, { replaceUrl: true }); */
    });
  }
  public getBorderColor(imo?: string) {
    if (imo) {
      imo = imo.substr(1);
      imo = imo.split('').reverse().join('');
      const hue = 360 * (+imo.substr(0, 3) / 1000);

      const sat = 20 + (+imo.substr(3, 3) / 1000) * 80;
      const lig = 40;
      return 'hsla(' + hue + ',' + sat + '%,' + lig + '%,' + '1)';
    }
  }
  public checkVesselMap(i, vesselDtoDept, status: string) {
    if (!vesselDtoDept) {
      this.vesselMap[i] = { vessedlId: -1, vesselDeploymentId: -1 };
      this.vesselPrevMap[i] = { vessedlId: -1, vesselDeploymentId: -1 };
      return false;
    } else {
      if (
        this.vesselMap[i] &&
        (this.vesselMap[i].vesselId !== vesselDtoDept.vesselId ||
          this.vesselMap[i].vesselDeploymentId !== vesselDtoDept.vesselDeploymentId)
      ) {
        this.vesselMap[i] = { vesselId: vesselDtoDept.vesselId, vesselDeploymentId: vesselDtoDept.vesselDeploymentId };

        if (
          this.vesselNameDisplayedDetails[i] &&
          (vesselDtoDept.runningDeploymentLine ||
            !this.vesselNameDisplayedDetails[i].find((item) => {
              return (
                item.vesselId === vesselDtoDept.vesselId && item.vesselDeploymentId === vesselDtoDept.vesselDeploymentId
              );
            }))
        ) {
          this.vesselNameDisplayedDetails[i].push({
            vesselId: vesselDtoDept.vesselId,
            vesselDeploymentId: vesselDtoDept.vesselDeploymentId,
          });
          this.atChanged = status;

          return true;
        } else {
          this.atChanged = '';
        }
      } else if (
        this.vesselMap[i] &&
        ((this.vesselMap[i].vesselId === vesselDtoDept.vesselId &&
          this.vesselPrevMap[i].vesselId !== this.vesselMap[i].vesselId) ||
          (this.vesselMap[i].vesselDeploymentId !== vesselDtoDept.vesselDeploymentId &&
            this.vesselPrevMap[i].vesselDeploymentId !== this.vesselMap[i].vesselDeploymentId))
      ) {
        this.vesselPrevMap[i] = {
          vesselId: this.vesselMap[i].vesselId,
          vesselDeploymentId: this.vesselMap[i].vesselDeploymentId,
        };
        return false;
      }
    }
  }

  public removePaddingTop(i, duplicateVesselName?: string) {
    if (
      this.vesselMap[i] &&
      this.vesselPrevMap[i] &&
      (this.vesselMap[i].vesselId !== this.vesselPrevMap[i].vesselId ||
        this.vesselMap[i].vesselDeploymentId !== this.vesselPrevMap[i].vesselDeploymentId) &&
      this.atChanged === 'dept'
    ) {
      this.vesselPrevMap[i] = {
        vesselId: this.vesselMap[i].vesselId,
        vesselDeploymentId: this.vesselMap[i].vesselDeploymentId,
      };
      return true;
    }
    if (this.dummyMap[i] !== this.dummyPrevMap[i] && this.atChanged === 'dept') {
      this.dummyPrevMap[i] = this.dummyMap[i];
      return true;
    }
    if (duplicateVesselName === 'duplicateVesselName') {
      return true;
    }
    return false;
  }
  public checkDummyMap(i, dummyId, status?: any) {
    if (this.dummyMap[i] !== dummyId) {
      this.dummyMap[i] = dummyId;
      this.atChanged = status;
      return true;
    } else if (this.dummyMap[i] === dummyId && this.dummyPrevMap[i] !== this.dummyMap[i]) {
      this.dummyPrevMap[i] = this.dummyMap[i];
    }
    return false;
  }
  public onChangeVisibility(proformaId, approved) {
    const postData = {
      genericRequestID1: proformaId,
      genericRequestID2: 0,
      genericString1: '',
      genericString2: '',
      genericFlag: !approved,
    };
    const postDataSc = {
      genericRequestID1: proformaId,
      genericRequestID2: this.scenarioId,
      genericString1: '',
      genericString2: '',
      genericFlag: !approved,
    };
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postUpdateScheduleVisibility(postData)
      : this.scenarioService.postUpdateScheduleVisibility(postDataSc);
    serv.subscribe((res) => {
      // this.getServicePlanningLst();
      this.loading = true;
      this.router
        .navigateByUrl('/services', { skipLocationChange: true })
        .then(() =>
          this.router.navigate([
            '/services/' +
              this.id +
              '/schedule/' +
              this.servicePlanningLst.lstProformaListDto[0].proformaId +
              '/true' +
              '/deployments/true',
          ])
        );
    });
  }
  public positionLength(n?: number) {
    let i = 0;
    for (let j = 0; j < n; j++) {
      i++;
      this.positionsLst[j] = i;
    }
    return this.positionsLst;
  }
  public setArrivalDate(date1?: any, date2?: any): any {
    const obj = date1
      ? date1.scheduleType === 0
        ? date1
        : date2
        ? date2.scheduleType === 0
          ? date2
          : null
        : null
      : null;
    /* obj = date2 ? (date2.scheduleType === -1 ? date2 : null ) : null; */
    return obj;
  }
  public setDeptDate(date1, date2): any {
    const obj = date1
      ? date1.scheduleType === -1
        ? date1
        : date2
        ? date2.scheduleType === -1
          ? date2
          : null
        : null
      : null;
    // this.checkVesselStartingOnSameDate()
    // obj = date2 ? (date2.scheduleType === 0 ? date2 : null ) : null;
    return obj;
  }
  public dummyPopUp(event: any, i, j, k) {
    const target = event.target || event.srcElement || event.currentTarget;
    this.popupPosition = target.offsetLeft + target.offsetWidth + 'px';
    this.selctedDummyi = i;
    this.selctedDummyj = j;
    this.selctedDummyk = k;
    this.selctedDeploymenti = null;
    this.selctedDeploymentj = null;
    this.selctedDeploymentk = null;
    return this.popupPosition;
  }

  public setVslPopupPosition(event: any, i: number, j: number, k: number, triggerPopup?: string) {
    if (
      this.selctedDeploymenti != null &&
      this.selctedDeploymenti >= 0 &&
      this.selctedDeploymentj != null &&
      this.selctedDeploymentj >= 0 &&
      this.selctedDeploymentk != null &&
      this.selctedDeploymentk >= 0
    ) {
      const e = { type: 'close' };
      this.closeVesselForm(e, this.selctedDeploymentk, this.selctedDeploymenti, this.selctedDeploymentj);
    }
    if (
      event.target.tagName === 'svg' ||
      (event.target.tagName === 'SPAN' && event.target.classList.contains('vessel-name-ellipsis-span')) ||
      event.target.tagName === 'path' ||
      (event.target.tagName === 'DIV' && (event.target.title || event.target.classList.contains('boat-icon-overlap')))
    ) {
      this.setVesselDtoForQIcon = false;
      const target = event.target || event.srcElement || event.currentTarget;
      this.vesselPopupPosition = target.offsetLeft + target.offsetWidth + 'px';
      this.selctedDeploymenti = i;
      this.selctedDeploymentj = j;
      this.selctedDeploymentk = k;
      this.selctedDummyi = null;
      this.selctedDummyj = null;
      this.selctedDummyk = null;
      this.omitPortPopupi = null;
      this.omitPortPopupj = null;
      this.omitPortPopupk = null;
      // this.showVslPopUp = true;
      return this.vesselPopupPosition;
    }
  }
  public omitPortPopUp(event: any) {
    const target = event.target || event.srcElement || event.currentTarget;
    this.omitPortpopupPosition = target.offsetLeft + target.offsetWidth + 'px';
    this.selctedDummyi = null;
    this.selctedDummyj = null;
    this.selctedDummyk = null;
    if (
      this.selctedDeploymenti != null &&
      this.selctedDeploymenti >= 0 &&
      this.selctedDeploymentj != null &&
      this.selctedDeploymentj >= 0 &&
      this.selctedDeploymentk != null &&
      this.selctedDeploymentk >= 0
    ) {
      const e = { type: 'close' };
      this.closeVesselForm(e, this.selctedDeploymentk, this.selctedDeploymenti, this.selctedDeploymentj);
    }
    this.selctedDeploymenti = null;
    this.selctedDeploymentj = null;
    this.selctedDeploymentk = null;
    return this.omitPortpopupPosition;
  }
  public selectedOmitPortPopup(i, j, k) {
    if (
      this.selctedDeploymenti != null &&
      this.selctedDeploymenti >= 0 &&
      this.selctedDeploymentj != null &&
      this.selctedDeploymentj >= 0 &&
      this.selctedDeploymentk != null &&
      this.selctedDeploymentk >= 0
    ) {
      const e = { type: 'close' };
      this.closeVesselForm(e, this.selctedDeploymentk, this.selctedDeploymenti, this.selctedDeploymentj);
    }
    this.omitPortPopupi = i;
    this.omitPortPopupj = j;
    this.omitPortPopupk = k;
  }
  public extendToDummyPopup(event: any, dragEnabled, k?: any, i?: any, j?: any) {
    if (dragEnabled && !this.showExtendDummyPopup) {
      this.popupLastIndexY = j;
      this.popupLastIndexX = i;
      this.popupLastIndexZ = k;
      this.showExtendDummyPopup = true;
      const target = event.target || event.srcElement || event.currentTarget;
      this.extenToDummyPosition = target.offsetLeft + target.offsetWidth + 'px';
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[
        j
      ].showExtendDummyPopup = true;
      return this.extenToDummyPosition;
    }
  }
  public setPortShow(k?: any, i?: any, j?: any, portDate?: any, event?) {
    if (
      this.dragEnabled &&
      this.setDeptDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).vesselDto === null &&
      this.setDeptDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).charterRequestDto === null
    ) {
      if (this.popupLastIndexX !== i || (this.popupLastIndexY !== j && this.popupLastIndexZ !== k)) {
        this.showExtendDummyPopup = false;
      }
      this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showImage = true;
      const target = event.target || event.srcElement || event.currentTarget;
      this.popupLastIndexX = i;
      this.popupLastIndexY = j;
      this.popupLastIndexZ = k;
      this.extendImagePositionLeft = event.layerX + 'px';
      this.extendImagePositionTop = event.layerY + 'px';
    } else if (
      this.dragEnabled &&
      (this.setDeptDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).vesselDto !== null ||
        this.setDeptDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).charterRequestDto !== null)
    ) {
      this.popupLastIndexX = null;
      this.popupLastIndexY = null;
      this.popupLastIndexZ = null;
    }
  }

  public setStartDateForExtendDummy(fromDate, portLst?: any, pos?: any) {
    this.extendDummyStartDate = fromDate;
    this.posPortLstExtend = [];
    portLst.lstPortsListDto.map((portsLSt: any) => {
      portsLSt.lstScheduleListDto.map((posLst: any) => {
        if (posLst.position === pos && posLst.lstPositionDto && posLst.lstPositionDto.length !== 0) {
          this.posPortLstExtend.push({
            cityId: portsLSt.cityId,
            cityName: portsLSt.cityName,
            lstPositionDto: posLst.lstPositionDto,
            position: posLst.position,
            rotationNumber: portsLSt.rotationNumber,
          });
        }
      });
    });
  }
  public closeExtendDummyForm(e) {
    //  this.showDummy = false;
    // this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showDummy = false;
    // this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].createDummy = false;
    if (e.type === 'dummy') {
      this.showExtendDummyPopup = false;
      this.dragEnabled = false;
      this.getServicePlanningLst(true);
    }
    if (e.type === 'vessel') {
      this.showExtendDummyPopup = false;
      this.dragEnabled = false;
      this.getServicePlanningLst(false);
    } else if (e.type === 'closepopup') {
      this.showExtendDummyPopup = false;
      this.popupLastIndexX = null;
      this.popupLastIndexY = null;
      this.popupLastIndexZ = null;
      this.dragEnabled = false;
    } else if (e.type === 'popupError') {
      this.showExtendDummyPopup = false;
      this.popupLastIndexX = null;
      this.popupLastIndexY = null;
      this.popupLastIndexZ = null;
      this.dragEnabled = false;
      this.capturedError = e.error;
      this.dialogRef = this.dialog.open(this.confirmationPopUpDialog, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
        autoFocus: false,
      });
    }
  }
  public showImgWithCursor(i, j, vesselDto) {
    this.setVesselDtoForQIcon = true;
    /*  const vesselDto = this.setArrivalDate(portDate.lstPositionDto[0], portDate[1]).vesselDto; */
    this.vesselDto = vesselDto;
    if (
      !(
        vesselDto.nextDeploymentOnThisService ||
        vesselDto.redeliverAfterThisDeployment ||
        vesselDto.nextDeploymentOnThisServiceDummy
      )
    ) {
      this.dragEnabled = true;
      this.xPosition = i;
      this.yPosition = j;
      /*  this.extendDummyVesselId = this.setArrivalDate(portDate.lstPositionDto[0], portDate[1]).vesselDto.vesselId; */
      this.extendDummyVesselId = vesselDto.vesselId;
      this.extendDummyVesselType = vesselDto.vesselType;
      this.extendDummyVesselRedlDate = vesselDto.redeliverDateInclOffhire;
      this.vslFixtureID = vesselDto.fixtureId;
      /* this.color = this.getColor(this.setArrivalDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).vesselDto.imoNumber); */
      this.color = this.getColor(vesselDto.vesselColor);
    }
  }
  public showImgWithCursorOverlap(i, j, overlapVesselDto) {
    this.setVesselDto(overlapVesselDto);

    this.setVesselDtoForQIcon = true;
    /*  const vesselDto = this.setArrivalDate(portDate.lstPositionDto[0], portDate[1]).vesselDto; */
    this.overlapvesselDto = overlapVesselDto;
    if (
      !(
        overlapVesselDto.nextDeploymentOnThisService ||
        overlapVesselDto.redeliverAfterThisDeployment ||
        overlapVesselDto.nextDeploymentOnThisServiceDummy
      )
    ) {
      this.dragEnabled = true;
      this.xPosition = i;
      this.yPosition = j;
      /*  this.extendDummyVesselId = this.setArrivalDate(portDate.lstPositionDto[0], portDate[1]).vesselDto.vesselId; */
      this.extendDummyVesselId = overlapVesselDto.vesselId;
      this.extendDummyVesselType = overlapVesselDto.vesselType;
      this.extendDummyVesselRedlDate = overlapVesselDto.redeliverDateInclOffhire;
      this.vslFixtureID = overlapVesselDto.fixtureId;
      /* this.color = this.getColor(this.setArrivalDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).vesselDto.imoNumber); */
      this.color = this.getColor(overlapVesselDto.vesselColor);
    }
    return overlapVesselDto;
  }
  public showFileIcon(event, k?: any, i?: any, j?: any) {
    this.servicePlanningLst.lstProformaListDto[k].lstPortsListDto[i].lstScheduleListDto[j].showOmit.showNotice =
      event.showIcon;
  }
  public showRequestPopUp(event, requestPosition, isExtraloaderPosition) {
    this.createRequestPopUp = event.showCreateRequestPopUp;
    this.charterRequestObject = event.charterRequest;
    this.selectedRequestPos = requestPosition;
    this.selectedIsExtraloaderPos = isExtraloaderPosition;

    if (this.createRequestPopUp === true) {
      this.dialogRef = this.dialog.open(this.submitRequest, {
        width: '900px',
        height: '500px',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public redeliverVesselComponent(event, proformaId) {
    this.redeliverVesselPopUp = event.showVesselRedeliverPopUp;
    this.vesselId = event.vesselId;
    this.position = event.position;
    this.fixtureId = event.fixtureId;
    this.proformaId = proformaId;
    if (this.redeliverVesselPopUp === true) {
      this.dialogRef = this.dialog.open(this.redeliverVessel, {
        width: '850px',
        height: '330px',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public closeDialogue(event) {
    this.deleteSchedulePopup = false;
    this.deleteRequestPopUp = false;
    this.showRevertToDummy = false;
    this.deleteDummyPopUP = false;
    this.deleteDeployment = false;
    this.dialogRef.close();
    if (event.link !== undefined && event.link !== null) {
      window.open(event.link, '_blank', 'noreferrer');
    }
    // window.open(url,'_blank');
  }
  public deleteRequestDialogue(event) {
    this.deleteRequestPopUp = event.showDeletePopUp;
    this.charterId = event.charterId;

    if (this.deleteRequestPopUp === true) {
      this.dialogRef = this.dialog.open(this.deleteRequest, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public deleteVesselDeployment(event) {
    this.deleteDeployment = event.deleteDeploymentPopUp;
    this.deleteDeploymentId = event.vesselId;
    if (this.deleteDeployment === true) {
      this.dialogRef = this.dialog.open(this.deleteRequest, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public deleteDummyDialogue(event) {
    this.deleteDummyPopUP = event.showDeleteDummyPopUp;
    this.charterId = event.charterId;
    this.deleteRequestName = event.charterId.charterRequestStatusId;

    if (this.deleteDummyPopUP === true) {
      this.dialogRef = this.dialog.open(this.deleteRequest, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public revertToDummy(event) {
    this.showRevertToDummy = event.showRevertToDummy;
    this.charterRequestObject = event.charterRequest;
    if (this.showRevertToDummy === true) {
      this.dialogRef = this.dialog.open(this.deleteRequest, {
        width: '300px',
        height: 'auto',
        panelClass: 'dialog-container',
        disableClose: true,
      });
    }
  }
  public revertRequestToDummy() {
    const postData = {
      crPKID: this.charterRequestObject.charterRequestId,
      crPhaseInPortID: this.charterRequestObject.phaseInPortID,
      crPhaseInPortName: this.charterRequestObject.phaseInPortName,
      crStatusID: 1,
      crPhaseInStartDate: this.charterRequestObject.fromDate,
      crPhaseOutPortID: this.charterRequestObject.phaseOutPortID,
      crPhaseOutPortName: this.charterRequestObject.phaseOutPortName,
      crPhaseOutStartDate: this.charterRequestObject.toDate,
      crClassID: this.charterRequestObject.classId,
      crTradeID: this.charterRequestObject.tradeID,
      crTEU: this.charterRequestObject.teu,
      crMTCapacity: this.charterRequestObject.mtCapacity,
      crPlugs: this.charterRequestObject.plugs,
      crMinSpeed: this.charterRequestObject.minSpeed,
      crMaxSpeed: this.charterRequestObject.maxSpeed,
      crDraft: this.charterRequestObject.draftLimitations,
      crAirDraft: this.charterRequestObject.airDraft,
      crIsGear: this.charterRequestObject.gear,
      crIsIceClass: this.charterRequestObject.iceClass,
      crComments: this.charterRequestObject.requestComments,
      crVesselDepID: this.charterRequestObject.vesselDepID,
      crReason: this.charterRequestObject.reason,
      crForecastTEU: this.charterRequestObject.forecastTEU,
      crForecastTEURate: this.charterRequestObject.forecastTEURate,
      crHFOPrice: this.charterRequestObject.hfoPrice,
      crLSHFOPrice: this.charterRequestObject.lshfoPrice,
      crMDOPrice: this.charterRequestObject.mdoPrice,
      crLSMDOPrice: this.charterRequestObject.lsmdoPrice,
      crMLTeuCapacity: this.charterRequestObject.mlTeuCapacity,
      crMLMTCapacity: this.charterRequestObject.mlmtCapacity,
      crProformaHeaderId: this.charterRequestObject.charterProformaHeaderId,
      crVesselTeuId: this.charterRequestObject.vesselTeuId,
      crNomTeuCapacity: this.charterRequestObject.nomTeuCapacity,
      crTonCenCap: this.charterRequestObject.tonCenCap,
      crECAZone: this.charterRequestObject.ecaZone,
      crIsECA: this.charterRequestObject.isECAZone,
      crExtendsVesselId: null,
      crPosition: this.position,
      crServiceId: this.servicePlanningLst.serviceid,
      crServiceCode: this.servicePlanningLst.serviceCode,
      crServiceName: this.servicePlanningLst.serviceName,
    };

    const serv = this.serviceRepo
      ? this.serviceRepoServie.postCreateUpdateDummy(postData)
      : this.scenarioService.postCreateUpdateDummy(postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.dialogRef.close();
        this.getServicePlanningLst();
      }
    });
  }
  public setGroupPosition(lstPositionListDto: any) {
    const groupData = lstPositionListDto.reduce((obj, item) => {
      obj[item.position] = obj[item.position] || [];
      obj[item.position].push(item);
      return obj;
    }, {});
    const groups = Object.keys(groupData).map(function (key) {
      return { position: key, date: groupData[key] };
    });
    return groups;
  }
  public deleteRequestCharter() {
    const charterRequestId = this.charterId.charterRequestId;
    const postData = {
      genericRequestID1: charterRequestId,
      genericRequestID2: 0,
      genericString1: '',
      genericString2: '',
      genericFlag: false,
    };
    const serv = this.serviceRepo
      ? this.serviceRepoServie.deleteCharterRequest(postData)
      : this.scenarioService.deleteCharterRequest(postData);
    serv.subscribe((res: any) => {
      if (res) {
        this.dialogRef.close();
        this.getServicePlanningLst();
      }
    });
  }
  public saveSchedule() {
    this.loading = true;
    this.isDateValidating = true;
    this.serviceRepo ? this.dataService.updateIsUseDating(true) : this.dataScService.updateIsUseDating(true);
    const toDate = new Date(this.subHeaderDraftFrmGrp.value.toDate);
    toDate.setHours(0, 0, 0, 0);
    const serv = this.serviceRepo
      ? this.serviceRepoServie.getScheduleDating(this.schIdData.prhProformaID)
      : this.scenarioService.getScheduleDating(this.schIdData.proformaID, this.scenarioId);
    serv.subscribe((res: any) => {
      if (res) {
        this.scheduleDatingDto.frequency = res.frequency;
        this.scheduleDatingDto.portDetailsId = res.portDetailsId;
        this.scheduleDatingDto.lstBunkerPorts = res.lstSchedulePorts;
      }
    });
    // console.log('schIdData-deployment ', JSON.stringify(this.schIdData));
    // this.scheduleDatingDto.
    this.scheduleDatingDto.proformaHeaderId = this.serviceRepo
      ? this.schIdData.prhPKId
      : this.schIdScData.proformaHeaderId;
    this.scheduleDatingDto.proformaId = this.serviceRepo ? this.schIdData.prhProformaID : this.schIdData.proformaID;
    this.scheduleDatingDto.vesselCount = this.schIdData.pdVesselCount ? this.schIdData.pdVesselCount : 0;
    for (const portInfo of this.createdDateData.listPortsDto) {
      for (const portDate of portInfo.listPositionsDto) {
        // Logic for ETD
        // const toDate1 = new Date(this.setArrivalDate(portDate.listArrivalDepartureDto[0], portDate.listArrivalDepartureDto[0]).scheduleDate);
        const toDate1 = new Date(portDate.listArrivalDepartureDto[1].scheduleDate);
        toDate1.setHours(0, 0, 0, 0);
        if (
          new DatePipe('en-US').transform(toDate, 'dd-MMM-yy') === new DatePipe('en-US').transform(toDate1, 'dd-MMM-yy')
        ) {
          this.scheduleDatingDto.scheduleEndPortId = portInfo.proformaPortId;
          this.scheduleDatingDto.scheduleEndPortName = portInfo.proformaPortName;
          // Logic for Schedule End Date
          // this.scheduleDatingDto.scheduleEndDate = this.setArrivalDate(portDate.listArrivalDepartureDto[1], portDate.listArrivalDepartureDto[1]).scheduleDate;
          this.scheduleDatingDto.scheduleEndDate = portDate.listArrivalDepartureDto[1].scheduleDate;
          break;
        }
      }
    }
    this.importSch(this.scheduleDatingDto);
  }
  public changeFromDate(fromDate, portInfo, type, i, j, k, pos?: any) {
    if (type === 'draft') {
      this.subHeaderDraftFrmGrp.patchValue({
        // Logic changed for ETA
        // fromDate: fromDate
        fromDate: portInfo.listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate,
      });
      // this.isUseDatingDisable = false;
      this.startedHereI = i;
      this.startedHereJ = j;
      this.startedHereK = k;
    }
    this.scheduleDatingDto.scheduleStartPortId = portInfo.proformaPortId;
    this.scheduleDatingDto.scheduleStartPortName = portInfo.proformaPortName;
    // this.scheduleDatingDto.scheduleStartDate = fromDate;
    // Logic schedule startDate should start from ETA now
    this.scheduleDatingDto.scheduleStartDate = portInfo.listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate;
    pos ? (this.scheduleDatingDto.startingPosition = pos) : (this.scheduleDatingDto.startingPosition = j + 1);
  }
  public importSch(event) {
    const servicePlanningViewDto = [];
    for (const createData of this.createdDateData.listPortsDto) {
      const lstPositionDto = [];
      for (const lst of createData.listPositionsDto) {
        const temp1 = {
          position: lst.position,
          isOmitted: false,
          lstPositionDto: [
            {
              scheduleId: 0,
              scheduleDate: lst.listArrivalDepartureDto[0].scheduleDate,
              scheduleType: lst.listArrivalDepartureDto[0].scheduleType,
              deploymentPortComments: null,
              vesselDto: null,
              charterRequestDto: null,
            },
            {
              scheduleId: 0,
              scheduleDate: lst.listArrivalDepartureDto[1].scheduleDate,
              scheduleType: lst.listArrivalDepartureDto[1].scheduleType,
              deploymentPortComments: null,
              vesselDto: null,
              charterRequestDto: null,
            },
          ],
        };
        lstPositionDto.push(temp1);
      }
      const temp = {
        proformaPortId: createData.proformaPortId,
        cityId: createData.cityId,
        cityName: createData.cityName,
        proformaPortName: createData.proformaPortName,
        arrivalTime: createData.arrivalTime,
        departureTime: createData.departureTime,
        rotationNumber: createData.rotationNumber,
        lstScheduleListDto: lstPositionDto,
      };
      servicePlanningViewDto.push(temp);
    }
    const postData = {
      scheduleDatingDto: event,
      servicePlanningViewPortsDtos: servicePlanningViewDto,
    };
    const serv = this.serviceRepo
      ? this.serviceRepoServie.postCreateUpdateScheduleDating(postData)
      : this.scenarioService.postCreateUpdateScheduleDating(postData);
    serv.subscribe((res) => {
      if (res) {
        this.isDateValidating = false;
        this.serviceRepo
          ? this.dataService.updateImportedSucces('true')
          : this.dataScService.updateImportedSucces('true');
        this.dataScService.updateIsUseDating(false);
        this.serviceRepo
          ? this.router.navigateByUrl('/services/' + this.id + '/schedule/' + event.proformaId + '/true', {
              replaceUrl: true,
            })
          : this.router.navigateByUrl(
              '/scenarios/' + this.id + '/schedule/' + event.proformaId + '/' + this.scenarioId + '/false',
              { replaceUrl: true }
            );
        this.loading = false;
      }
    });
    // }
  }
  public createDate(fromDate?: any, toDate?: any): UntypedFormGroup {
    const control = this.formBuilder.group({
      fromDate: fromDate,
      toDate: toDate,
    });
    return control;
  }
  public addDate(fromDate?: any, toDate?: any): void {
    /* console.log('j ', this.j, 'fromDate ', fromDate, 'toDate ', toDate); */
    this.dates = this.subHeaderFrmGrp.get('dates') as UntypedFormArray;
    this.dates.push(this.createDate(fromDate, toDate));
  }

  public dateChanged() {
    const dataServ = this.serviceRepo ? this.dataService.schDtoData : this.dataScService.schDtoData;
    dataServ.subscribe((res: any) => {
      const fromDate = new DatePipe('en-US').transform(this.subHeaderDraftFrmGrp.get('fromDate').value, 'dd-MMM-yy');
      const toDate = new DatePipe('en-US').transform(this.subHeaderDraftFrmGrp.get('toDate').value, 'dd-MMM-yy');
      const serv = this.serviceRepo
        ? this.serviceRepoServie.postSchedulePlannerViewForNewScheduleDatesChanged(res, fromDate, toDate)
        : this.scenarioService.postSchedulePlannerViewForNewScheduleDatesChanged(res, fromDate, toDate);
      serv.subscribe((res1: any) => {
        this.createdDateData = res1;
        this.subHeaderDraftFrmGrp.patchValue({
          //  toDate: this.setArrivalDate(this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[0], this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[this.positionsLst.length - 1].listArrivalDepartureDto[1]).scheduleDate
          toDate: this.createdDateData.listPortsDto[this.createdDateData.listPortsDto.length - 1].listPositionsDto[
            this.positionsLst.length - 1
          ].listArrivalDepartureDto[1].scheduleDate,
        });
        this.subHeaderDraftFrmGrp.patchValue({
          //  fromDate: this.setDeptDate(this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0], this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[1]).scheduleDate
          fromDate: this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate,
        });
        this.onSelectStartIndex(0, 0);
        // this.schDateAfterDateChangeNewSch = this.setDeptDate(((this.createdDateData.listPortsDto[0].listPositionsDto)[0].listArrivalDepartureDto)[0],((this.createdDateData.listPortsDto[0].listPositionsDto)[0].listArrivalDepartureDto)[1]).scheduleDate;
        this.schDateAfterDateChangeNewSch = this.createdDateData.listPortsDto[0].listPositionsDto[0].listArrivalDepartureDto[0].scheduleDate;
        this.changeFromDate(
          this.schDateAfterDateChangeNewSch,
          this.createdDateData.listPortsDto[0],
          'draft',
          0,
          0,
          '',
          ''
        );
      });
    });
  }
  public setTooltipDataForConsecutivePorts(currentVisiblePort, currentIndex) {
    const scheduleList = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto;
    let firstInvisiblePortInReverseSeq, lastInvisiblePortInReverseSeq;
    const tooltipDataInSeq = [];
    firstInvisiblePortInReverseSeq = currentIndex - 1;
    for (let i = firstInvisiblePortInReverseSeq - 1; i > 0; i--) {
      if (scheduleList[i].isPortVisible) {
        lastInvisiblePortInReverseSeq = i + 1;
        break;
      } else {
        continue;
      }
    }
    for (let j = lastInvisiblePortInReverseSeq; j <= firstInvisiblePortInReverseSeq; j++) {
      tooltipDataInSeq.push(
        scheduleList[j].siteCode + ' (' + scheduleList[j].departureDirection + ') ' + scheduleList[j].proformaPortName
      );
    }
    const tooltipDataToDisplay = tooltipDataInSeq.join('\n');
    return tooltipDataToDisplay;
  }

  public getHiddenPortInfo() {
    return this.hiddenPortInfo;
  }

  public trackByFnFilterForPorts(item: any) {
    return item.pfmPKID;
  }

  public uncheckAllPorts(item?) {
    this.clearAllPortsDropDownOptions();
    this.filterServicePlanningLstDto(item);
  }

  public selectAllPorts() {
    this.selectAllPortsDropDownOptions();
    this.showAllServicePlanningLstDto(true);
  }

  public setTooltipDataForMultipleHiddenPortBypfmPKID(id) {
    this.portsForFilter
      .filter((port) => port.pfmPKID === id)
      .forEach((item) => {
        if (this.hiddenPortInfo.indexOf(item.portName) === -1) {
          this.hiddenPortInfo.push(item.portName);
        }
      });
    return this.hiddenPortInfo;
  }

  public setTooltipDataForHiddenPortBypfmPKID(id) {
    return this.portsForFilter.filter((port) => port.pfmPKID === id).map((item) => item.portName);
  }

  public checkLastPortOfRotationFromPortsOfFilter() {
    const fromlistOfPorts = this.portsForFilter;
    const [, first, second, ...portsFilter] = fromlistOfPorts;
    return portsFilter[portsFilter.length - 1];
  }

  public isLastPortOfRotation(proformaPortId?) {
    const lastPort = this.checkLastPortOfRotationFromPortsOfFilter();
    const rotationLastPortpfmPKID = lastPort.pfmPKID;
    const isContionMet = Array.isArray(proformaPortId)
      ? proformaPortId.includes(rotationLastPortpfmPKID)
      : proformaPortId === rotationLastPortpfmPKID;
    return isContionMet ? true : false;
  }

  public updateScheduleListDtoForOffRoundTripFlag(reset?) {
    const lstscheduls = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto;
    if (Array.isArray(lstscheduls)) {
      let nextElement, currentElement;
      for (let i = 0; i < lstscheduls.length - 1; i++) {
        currentElement = lstscheduls[i];

        if (currentElement.isLastPortOfRotation && currentElement.isCollapsible) {
          nextElement = lstscheduls[i + 1];

          nextElement.rotationFlag = reset;
        } else {
          nextElement = lstscheduls[i + 1];
        }
      }
    }
  }

  public hidePort() {
    const lstscheduls = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto;
    if (Array.isArray(lstscheduls)) {
      let nextDpLine, currentDpLine;
      for (let i = 0; i < lstscheduls.length - 1; i++) {
        currentDpLine = lstscheduls[i];

        if (!currentDpLine.isPortVisible && !currentDpLine.isDeploymentExist) {
          nextDpLine = lstscheduls[i + 1];
          let ttipData;
          let ttipData1 = this.setTooltipDataForMultipleHiddenPortBypfmPKID(currentDpLine.proformaPortId);
          if (!nextDpLine.isPortVisible) {
            const ttipData2 = this.setTooltipDataForMultipleHiddenPortBypfmPKID(currentDpLine.proformaPortId);
            ttipData1 = [...ttipData1, ...ttipData2];
          }
          ttipData = [...ttipData1];
          currentDpLine.filteredPortName = ttipData;
        } else {
          nextDpLine = lstscheduls[i + 1];
          if (!nextDpLine.isPortVisible && Array.isArray(nextDpLine.filteredPortName)) {
            nextDpLine.filteredPortName = 'filteredPortName';
          }
        }
      }
    }
  }

  public filterServicePlanningLstDto(selectedPortPfmPKID?) {
    const lstscheduls = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto;
    lstscheduls.map((lstPortsListDtoItem, index) => {
      lstPortsListDtoItem.isLastPortOfRotation = false;

      if (Array.isArray(selectedPortPfmPKID)) {
        if (selectedPortPfmPKID.indexOf(lstPortsListDtoItem.proformaPortId) !== -1) {
          const isDeploymentExist = lstPortsListDtoItem.isCollapsible;
          if (isDeploymentExist) {
            lstPortsListDtoItem.isPortVisible = false;

            lstPortsListDtoItem.isLastPortOfRotation = this.isLastPortOfRotation(lstPortsListDtoItem.proformaPortId);
          } else {
            lstPortsListDtoItem.isPortVisible = true;
          }
        }
      }
    });
    if (Array.isArray(selectedPortPfmPKID)) {
      this.hidePort();
    }

    if (this.isLastPortOfRotation(selectedPortPfmPKID)) {
      this.updateScheduleListDtoForOffRoundTripFlag(false);
    }
  }

  public showLstPortsListDtoItem(selectedPortPfmPKID?) {
    const portsListArray = this.getPortForFilterBypfmPKID();
    const islastPortOfRotaionChecked = this.checkifSelectedPortInPortsForFilterArray(
      portsListArray,
      selectedPortPfmPKID
    );
    let updateOnce = false;
    const checkedPortValue = islastPortOfRotaionChecked.values().next().value;

    if (![selectedPortPfmPKID].includes(checkedPortValue)) {
      updateOnce = true;
    } else {
      updateOnce = false;
    }
    const lstscheduls = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto;
    lstscheduls.map((lstPortsListDtoItem) => {
      if (Array.isArray(selectedPortPfmPKID)) {
        if (selectedPortPfmPKID.indexOf(lstPortsListDtoItem.proformaPortId) === -1) {
          lstPortsListDtoItem.isPortVisible = true;
        }
      }
    });
    if (
      this.isLastPortOfRotation(islastPortOfRotaionChecked) &&
      updateOnce &&
      (islastPortOfRotaionChecked.length === 1 || checkedPortValue === undefined)
    ) {
      this.updateScheduleListDtoForOffRoundTripFlag(true);
    }
  }

  public removeAllPortIndexWhenOtherItemUnchecked(array, elem) {
    let index = array.indexOf(elem);
    while (index > -1) {
      array.splice(index, 1);
      index = array.indexOf(elem);
    }
  }

  public addPortListItem(item?) {
    this.itemChecked = true;
    const portsListArray = this.getPortForFilterBypfmPKID();
    if (this.itemChecked) {
      const totalPortsForFilter = portsListArray.length;
      const lastItem = totalPortsForFilter - 2;
      if (this.multiControls.portsMultiCtrl.value.length >= lastItem) {
        this.allportCheck = true;
        this.selectAllPorts();
        if (this.isLastPortOfRotation(item.pfmPKID)) {
          this.updateScheduleListDtoForOffRoundTripFlag(true);
        }
      }
    }
  }

  public removedPortListItem(item?) {
    this.itemChecked = false;
    if (!item.selected && this.isLastPortOfRotation(item.value.pfmPKID)) {
      this.updateScheduleListDtoForOffRoundTripFlag(false);
    }
    if (item.index === 0) {
      this.filterDeploymentsByPort(true);
    } else {
      this.filterDeploymentsByPort();
    }
  }

  public filterDeploymentsByPort(removeAllPorts?) {
    const portsListArray = this.getPortForFilterBypfmPKID();
    const control = this.multiControls.portsMultiCtrl;
    const selectedPortListArray = removeAllPorts ? [0] : control.value;
    if (control.value && control.value[0] !== 0) {
      const selectedPortArr = this.checkifSelectedPortInPortsForFilterArray(portsListArray, selectedPortListArray);
      if (Array.isArray(selectedPortArr) && selectedPortArr.length) {
        const allcheck = control.value.includes(0);
        if (
          selectedPortArr &&
          selectedPortArr.values().next().value === 0 &&
          selectedPortArr.length === 1 &&
          !allcheck &&
          this.allportCheck
        ) {
          this.uncheckAllPorts(control.value);
        }

        if (
          selectedPortArr &&
          selectedPortArr.values().next().value === 0 &&
          selectedPortArr.length === 1 &&
          !this.allportCheck
        ) {
          this.uncheckAllPorts(control.value);
        } else {
          if (allcheck) {
            this.selectAllPorts();
          } else {
            if (this.itemChecked) {
              this.showLstPortsListDtoItem(selectedPortArr);
            } else {
              this.filterServicePlanningLstDto(selectedPortArr);
            }
          }
        }
      } else {
      }
    } else {
      if (control.value && control.value.length === 1) {
        this.selectAllPorts();
      } else {
        const selectedPortArr = this.checkifSelectedPortInPortsForFilterArray(portsListArray, selectedPortListArray);
        if (Array.isArray(selectedPortArr) && selectedPortArr.length) {
          this.removeAllPortIndexWhenOtherItemUnchecked(control.value, selectedPortArr);
          const isAllPortchecked = control.value.includes(0);
          control.value.shift();
          if (isAllPortchecked) {
            this.updatePortForFilterControl(control.value);
          }
          this.filterServicePlanningLstDto(selectedPortArr);
        } else {
          this.selectAllPorts();
        }
      }
    }
  }

  public checkifSelectedPortInPortsForFilterArray(portsListArray?, selectedPortListArray?) {
    return portsListArray.filter((port) => !selectedPortListArray.includes(port)).map((item) => item);
  }

  public selected(portsListArray?, selectedPortListArray?) {
    return portsListArray.filter((port) => selectedPortListArray.includes(port)).map((item) => item);
  }

  public showAllServicePlanningLstDto(isPortVisible?) {
    this.hiddenPortInfo = [];
    this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto.map(
      (lstPortsListDtoItem) => (lstPortsListDtoItem.isPortVisible = isPortVisible)
    );
  }

  public hideAllServicePlanningLstDto() {
    this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto.map(
      (lstPortsListDtoItem) => (lstPortsListDtoItem.isPortVisible = false)
    );
  }

  public getPortsForFilter(lstFilterSchedulePortsDto?) {
    const allPortsObj = {
      pfmIsBottleNeckPort: false,
      pfmIsBunkerPort: false,
      pfmIsPhasingPort: false,
      pfmIsTurnPort: false,
      pfmPKID: 0,
      portName: 'All ports',
    };

    this.portsForFilter = lstFilterSchedulePortsDto;
    this.portsForFilter.unshift(allPortsObj);
    this.selectedPorts = this.getPortForFilterBypfmPKID();
    if (this.selectAllPorts && !this.isFirstTimeLoad) {
      this.isFirstTimeLoad = true;
      this.updatePortForFilterControl(this.selectedPorts);
    }
  }

  public getPortForFilterBypfmPKID() {
    return this.portsForFilter.map((item) => item.pfmPKID);
  }

  public selectAllPortsDropDownOptions() {
    const selectedPorts = this.getPortForFilterBypfmPKID();
    this.updatePortForFilterControl(selectedPorts);
    if (this.isLastPortOfRotation(selectedPorts)) {
      this.updateScheduleListDtoForOffRoundTripFlag(true);
    }
  }

  public clearAllPortsDropDownOptions() {
    this.updatePortForFilterControl([]);
  }

  public updatePortForFilterControl(data) {
    this.multiControls.portsMultiCtrl.setValue(data, {
      onlySelf: true,
      emitEvent: false,
    });
  }

  public getServicePlanningLst(isDummy?: boolean, position?: number) {
    // proforma id has been passed for first one as signature of api has been changed now
    const serv = this.serviceRepo
      ? this.serviceRepoServie.getServicePlanningLst(this.activeLink.split('/')[4])
      : this.serviceRepoServie.getServicePlanningLst(this.schIdScData.prhProformaID);
    serv.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(
      async (res: any) => {
        this.isplannerLoading = false;
        this.showExeptionError = false;
        await this.setPositionVisibility(res, position);

        if (this.servicePlanningLst.lstProformaListDto[0].lstFilterSchedulePortsDto) {
          this.getPortsForFilter(this.servicePlanningLst.lstProformaListDto[0].lstFilterSchedulePortsDto);
        }
        /* get ScheduleDating detail */
        if (res) {
          this.loading = false;
          this.latestVersion = +res.lstProformaListDto[res.lstProformaListDto.length - 1].gsisVersion + 1;

          this.positionsLst = new Array<number>(res.maxPosition);
          let i = 0;
          if (
            this.servicePlanningLst &&
            this.servicePlanningLst.lstProformaListDto &&
            this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto
          ) {
            for (let j = 0; j < res.maxPosition; j++) {
              i++;
              this.positionsLst[
                j
              ] = this.servicePlanningLst.lstProformaListDto[0].lstPortsListDto[0].lstScheduleListDto[j].position;
            }
          }
          this.setCurentHeader();
          if (this.servicePlanningLst) {
            this.servicePlanningLst.lstProformaListDto.forEach((element, idx) => {
              this.showDetails.push(false);
              this.showButtonList[idx] = false;
              this.savedCommentList[idx] = element.scheduleComments;
              this.commentList[idx] = element.scheduleComments;
            });
            this.viewToDate = new UntypedFormControl(new Date(this.servicePlanningLst.viewToDate));
            // this.comment = this.servicePlanningLst.lstProformaListDto[0].scheduleComments;
            // this.savedComment = this.servicePlanningLst.lstProformaListDto[0].scheduleComments;
            for (const deployLst of this.servicePlanningLst.lstProformaListDto) {
              this.addDate(deployLst.validFrom, deployLst.validTo);
            }
          }
          /* Set FormArray */
          this.servicePlanningLst.lstProformaListDto.map((lst: any) => {
            lst.lstPortsListDto.map((portLSt: any) => {
              portLSt.isPortVisible = true;
              portLSt.lstScheduleListDto.map((posLst: any) => {
                posLst.showDummy = false;
                posLst.createDummy = false;
                posLst.showOmit = { status: false, showNotice: false };
                portLSt.showVesselDeployment = false;
                portLSt.showImage = false;
                portLSt.showExtendDummyPopup = false;
              });
            });
          });
          if (isDummy) {
            this.servicePlanningLst.lstProformaListDto[this.popupLastIndexZ].lstPortsListDto[
              this.popupLastIndexX
            ].lstScheduleListDto[this.popupLastIndexY].showDummy = true;
            this.popupPosition = this.extenToDummyPosition;
          } else if (!isDummy && this.servicePlanningLst.lstProformaListDto[this.popupLastIndexZ] != null) {
            const portDate = this.servicePlanningLst.lstProformaListDto[this.popupLastIndexZ].lstPortsListDto[
              this.popupLastIndexX
            ].lstScheduleListDto[this.popupLastIndexY];
            portDate.showVesselDeployment = true;
            this.vesselDto = this.setDeptDate(portDate.lstPositionDto[0], portDate.lstPositionDto[1]).vesselDto;
            this.popupPosition = this.extenToDummyPosition;
          }
          this.popupLastIndexX = null;
          this.popupLastIndexY = null;
          this.popupLastIndexZ = null;

          let title = this.titleService.getTitle();
          if (title.includes('(') && title.lastIndexOf('(') === 7) {
            return;
          } else {
            this.titleService.setTitle(
              this.titleService.getTitle() +
                (' ' +
                  '(S)' +
                  ' ' +
                  res.serviceCode +
                  '- ' +
                  'ID' +
                  this.servicePlanningLst.lstProformaListDto[0].proformaId)
            );
          }
          localStorage.setItem('serviceCode', res.serviceCode);
          localStorage.setItem('serviceProformaID', this.servicePlanningLst.lstProformaListDto[0].proformaId);
        } else {
          this.servicePlanningLst = null;
        }
      },
      (errorResponse) => {
        this.showExeptionError = true;
        this.loading = false;
      }
    );
  }

  public setSubheaderDraftFrmGroup() {
    this.subHeaderDraftFrmGrp = this.formBuilder.group({
      fromDate: '',
      toDate: '',
    });
  }
  public setSubheaderFrmGroup() {
    this.subHeaderFrmGrp = this.formBuilder.group({
      dates: this.formBuilder.array([]),
    });
  }

  // public scroll(dir) {
  //   if (dir === 'left') {
  //     document.getElementsByClassName('mat-sidenav-content')[0].scrollBy(-500, 0);
  //   } else if (dir === 'right') {
  //     document.getElementsByClassName('mat-sidenav-content')[0].scrollBy(500, 0);
  //   }
  // }
  public getServiceDetails() {
    this.serviceRepoServie
      .getServiceOverview(this.id)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
        if (resp) {
          this.dataScService.updateServiceOverViewHeader(resp);
          this.serviceDetails = resp;
        }
      });
  }

  public enableUseButton() {
    this.serviceRepoServie.disableUse.next(false);
  }

  public showExtraLoaderForOmitPort(scheduleLstDto, index: number, proformaStatus: string) {
    const prevPosition = scheduleLstDto[index - 1] && scheduleLstDto[index - 1].position;
    const currPosition = scheduleLstDto[index] && scheduleLstDto[index].position;
    const nextPosition = scheduleLstDto[index + 1] && scheduleLstDto[index + 1].position;

    const isExtraloaderHidden =
      scheduleLstDto[index + 1] &&
      scheduleLstDto[index + 1].isExtraloaderPosition &&
      !scheduleLstDto[index + 1].isVisible;

    if (([prevPosition, nextPosition].includes(currPosition) && !isExtraloaderHidden) || proformaStatus !== 'Current') {
      return false;
    }

    return true;
  }

  public showExtraLoaderPopup(scheduleLstDto, index: number, proformaIndex, event, deptDateObj) {
    this.proformaIndex = proformaIndex;
    const prevPosition = scheduleLstDto[index - 1] && scheduleLstDto[index - 1].position;
    const currPosition = scheduleLstDto[index] && scheduleLstDto[index].position;
    const nextPosition = scheduleLstDto[index + 1] && scheduleLstDto[index + 1].position;

    this.isExtraloaderHidden =
      scheduleLstDto[index + 1] &&
      scheduleLstDto[index + 1].isExtraloaderPosition &&
      !scheduleLstDto[index + 1].isVisible;

    if (
      // when extraloader exists and is not hidden - dont show extraloader popup
      ([prevPosition, nextPosition].includes(currPosition) && !this.isExtraloaderHidden) ||
      // when no deployment is there - dont show extraloder popup
      (deptDateObj && !deptDateObj.charterRequestDto && !deptDateObj.vesselDto)
    ) {
      this.extraLoaderPopupShow = false;
      return;
    }
    if (event) {
      const target = event.target || event.srcElement || event.currentTarget;
      this.extraLoaderPopupPosition = {
        left: target.offsetLeft + target.offsetWidth + 'px',
        top: target.offsetTop - 5 + 'px',
      };
    }
    this.extraLoaderPosition = currPosition;
    this.extraLoaderPopupShow = true;
    this.depDateExtraLoader = deptDateObj.scheduleId;
  }

  // sets class for showing cursor pointer or not
  public getCursor(lstScheduleListDto, index, proforma) {
    const date1 = lstScheduleListDto[index] && lstScheduleListDto[index].lstPositionDto[0];
    const date2 = lstScheduleListDto[index] && lstScheduleListDto[index].lstPositionDto[1];
    const date = this.setDeptDate(date1, date2);

    const prevPosition = lstScheduleListDto[index - 1] && lstScheduleListDto[index - 1].position;
    const currPosition = lstScheduleListDto[index] && lstScheduleListDto[index].position;
    const nextPosition = lstScheduleListDto[index + 1] && lstScheduleListDto[index + 1].position;

    const isExtraloaderHidden =
      lstScheduleListDto[index + 1] &&
      lstScheduleListDto[index + 1].isExtraloaderPosition &&
      !lstScheduleListDto[index + 1].isVisible;

    if (date && date.charterRequestDto && [prevPosition, nextPosition].includes(currPosition) && !isExtraloaderHidden) {
      return '';
    }

    return 'cursor-pointer';
  }

  // set the value of isVisible for each cell in servicePlanningLst (based on deployment is present or not)
  public setPositionVisibility(prevPlanningLst: any, position?: number) {
    if (prevPlanningLst) {
      this.servicePlanningLst = {
        ...prevPlanningLst,
        lstProformaListDto: prevPlanningLst.lstProformaListDto.map((proforma) => {
          return {
            ...proforma,
            lstPortsListDto: proforma.lstPortsListDto.map((port) => {
              port.isPortVisible = true;
              return {
                ...port,
                lstScheduleListDto: port.lstScheduleListDto.map((schedule) => {
                  let isVisible = true;
                  if (schedule.isExtraloaderPosition) {
                    isVisible =
                      schedule.position === position ||
                      schedule.lstPositionDto.some((pos) => pos.charterRequestDto || pos.vesselDto);
                  }
                  return { ...schedule, isVisible };
                }),
              };
            }),
          };
        }),
      };

      // TO close extra loader popup
      // this.omitPortPopupi = this.omitPortPopupj = this.omitPortPopupk = this.showExtraLoaderPopup = null;
    }
  }

  public getScheduleHavePosVesselDto(schedule) {
    return (schedule.lstPositionDto.isScheduleHavePosVesselDto = schedule.lstPositionDto.every(
      (pos) =>
        pos.vesselDto != null && pos.vesselDto.vesselDeploymentId != null && pos.vesselDto.deploymentEndDate != null
    ));
  }

  // checks if cell (both header and table) should be finally visible or not
  public isPositionVisible(lstProformaListDto: any[], position: number, isExtra: boolean): boolean {
    const canSeeExtraPos = this.expandedViewPositionArray.indexOf(position);
    // for new schedule
    if (!lstProformaListDto) {
      return true;
    }
    // for already made schedule
    return lstProformaListDto.some((proforma) => {
      return proforma.lstPortsListDto.some((port) => {
        return port.lstScheduleListDto.find((schedule) => {
          return (
            schedule.position === position &&
            schedule.isExtraloaderPosition === isExtra &&
            ((schedule.isVisible && canSeeExtraPos > -1) || !schedule.isExtraloaderPosition)
          );
        });
      });
    });
  }
  public showCreateDummyPopup(event) {
    this.openCreateDummyPopup = event.showCreateDummy;
  }
  public cancelComment(id) {
    this.showButtonList[id] = false;
    this.commentList[id] = this.savedCommentList[id];
    if (id === 0) {
      this.show = false;
      this.comment = this.savedComment;
    }
  }
  public saveComment(prfId, cmt, id) {
    if (id === 0) {
      this.show = false;
    }
    this.showButtonList[id] = false;
    const postData = {
      genericRequestID1: prfId, // this.servicePlanningLst.lstProformaListDto[0].proformaId,
      // 'proformaPortTypeFields': this.port_status,
      genericRequestID2: 0,
      genericString1: cmt, // this.comment,
      genericString2: 'string',
      genericFlag: true,
    };
    this.serviceRepoServie.updateScheduleComment(postData).subscribe((res: any) => {
      if (!res) {
        this.cancelComment(id);
      } else {
        this.savedCommentList[id] = cmt;
        if (id === 0) {
          this.savedComment = cmt;
        }
      }
    });
  }

  //
  public expandedExtraPositions(positionClicked, isExpanded) {
    if (isExpanded) {
      this.expandedViewPositionArray.push(positionClicked);
    } else {
      const index = this.expandedViewPositionArray.indexOf(positionClicked);
      if (index > -1) {
        this.expandedViewPositionArray.splice(index, 1);
      }
    }
  }
  public isPosExpanded(pos) {
    if (!this.expandedViewPositionArray.length) {
      return false;
    }
    const index = this.expandedViewPositionArray.indexOf(pos);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  public isExpandVisible(proforma, position, isExtra: boolean) {
    if (!proforma) {
      return true;
    }
    // for already made schedule
    return proforma.some((proformaRes) => {
      return proformaRes.lstPortsListDto.some((port) => {
        return port.lstScheduleListDto.find((schedule) => {
          return (
            schedule.position === position &&
            schedule.isExtraloaderPosition === isExtra &&
            schedule.lstPositionDto.length &&
            (schedule.lstPositionDto.length > 1
              ? schedule.lstPositionDto[0].charterRequestDto ||
                schedule.lstPositionDto[0].vesselDto ||
                schedule.lstPositionDto[1].charterRequestDto ||
                schedule.lstPositionDto[1].vesselDto
              : schedule.lstPositionDto[0].charterRequestDto || schedule.lstPositionDto[0].vesselDto)
          );
        });
      });
    });
  }
  // prolong schedule
  public prolongSchedule(e, schDetails) {
    this.originalValidTo = schDetails.validTo;
    this.isProlongElseShorten =
      new Date(e.value.toISOString().split('.')[0]).getTime() > new Date(schDetails.validTo).getTime() ? true : false;
    const postData = {
      prhPKId: schDetails.proformaHeaderId,
      prhProformaID: schDetails.proformaId,
      prhStatus: 'Draft',
      prhGSISVersion: schDetails.gsisVersion,
      prhValidityFrom: schDetails.validFrom,
      prhValidityTo: e.value.toISOString().split('.')[0],
      prhRotationDays: schDetails.roundTripDays,
      pdVesselCount: schDetails.vesselCount,
      pdStatus: schDetails.versionStatus,
      prhIsVisible: schDetails.isVisible,
      prhIsIgnored: false,
      prhIsDeleted: false,
    };
    this.serviceRepoServie.getNewScheduleEndDateOnProlong(postData).subscribe(
      (response: any) => {
        this.newScheduleDate = response;
        this.dialogRef = this.dialog.open(this.confirmationProlongSchedule, {
          width: '350px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
          autoFocus: false,
        });
        this.dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              const serv = this.serviceRepoServie.prolongSchedule(postData);
              serv.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe((res: any) => {
                if (res) {
                  // this.getServicePlanningLst();
                  this.router
                    .navigateByUrl('/services', { skipLocationChange: true })
                    .then(() =>
                      this.router.navigate([
                        '/services/' +
                          this.id +
                          '/schedule/' +
                          this.servicePlanningLst.lstProformaListDto[0].proformaId +
                          '/true' +
                          '/deployments/true',
                      ])
                    );
                } else {
                  this.subHeaderFrmGrp.controls.dates['controls'][0].controls.toDate.setValue(
                    this.servicePlanningLst.viewToDate
                  );
                }
              });
            }
          });
      },
      (errorResponse) => {
        if (errorResponse instanceof HttpErrorResponse) {
          const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
          if (error) {
            this.showProlongErr = true;
            this.prolongErrorMsg = error;
            this.dialogRef = this.dialog.open(this.warningOnChangeSchedule, {
              width: '350px',
              height: 'auto',
              panelClass: 'dialog-container',
              disableClose: true,
              autoFocus: false,
            });
          }
        }
      }
    );
    //
  }
  public cancelProlong() {
    if (this.originalValidTo) {
      this.subHeaderFrmGrp.controls.dates['controls'][0].controls.toDate.setValue(this.originalValidTo);
    }
  }
  public cancelErrorProlong() {
    this.showProlongErr = false;
    this.prolongErrorMsg = null;
    if (this.originalValidTo) {
      this.subHeaderFrmGrp.controls.dates['controls'][0].controls.toDate.setValue(this.originalValidTo);
    }
  }
  public autoShortenSchedule(schDetails) {
    if (this.servicePlanningLst.enableCutSchedule) {
      this.isProlongElseShorten = false;
      this.serviceRepoServie.autoShortenSchedule(schDetails.proformaId, false).subscribe(
        (response: any) => {
          this.newScheduleDate = response;
          this.dialogRef = this.dialog.open(this.confirmationProlongSchedule, {
            width: '350px',
            height: 'auto',
            panelClass: 'dialog-container',
            disableClose: true,
            autoFocus: false,
          });
          this.dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((result) => {
              if (result) {
                const serv = this.serviceRepoServie.autoShortenSchedule(schDetails.proformaId, true);
                serv.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe((res: any) => {
                  if (res) {
                    // this.getServicePlanningLst();
                    this.router
                      .navigateByUrl('/services', {
                        skipLocationChange: true,
                      })
                      .then(() =>
                        this.router.navigate([
                          '/services/' +
                            this.id +
                            '/schedule/' +
                            this.servicePlanningLst.lstProformaListDto[0].proformaId +
                            '/true' +
                            '/deployments/true',
                        ])
                      );
                  }
                });
              }
            });
        },
        (errorResponse) => {
          if (errorResponse instanceof HttpErrorResponse) {
            const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
            if (error) {
              this.showProlongErr = true;
              this.prolongErrorMsg = error;
              this.dialogRef = this.dialog.open(this.warningOnChangeSchedule, {
                width: '350px',
                height: 'auto',
                panelClass: 'dialog-container',
                disableClose: true,
                autoFocus: false,
              });
            }
          }
        }
      );
    }
  }

  public cutVesselSwitch(e) {
    // this.isCut = !this.isCut;
    if (e.checked) {
      this.sessionTimeoutService.setTimeout();
      this.sessionTimeoutService.userInactive.subscribe((value) => {
        console.log('what is the val', value);
        // this.sessionTimeoutService.logout();
      });
    }
    if (e.cancel) {
      this.showVesselSwitchRadioBtns = false;
      setTimeout(() => {
        this.showVesselSwitchRadioBtns = true;
      }, 4);
      //
    } else {
      this.showVesselSwitchRadioBtns = e.checked;
    }
    if (!e.checked) {
      this.cutIndex = null;
      this.cutLocation = null;
    }
    // to cancel selected part on click on cut vessel toggle button
    this.overlapDetailList = [];
    this.nonoverlapDetailList = [];
    this.deploymentsOnIndexPort = [];
    this.deploymentsOnBelowIndexPort = [];
  }
  public cutVesselFromHere(portInfo, origin, index) {
    this.cutIndex = index;
    this.cutLocation = origin;
    this.selectedValue = 'cutVessel';
    this.overlapDetailList = [];
    this.nonoverlapDetailList = [];
    this.deploymentsOnIndexPort = [];
    this.deploymentsOnBelowIndexPort = [];
    this.cutType = false;
  }
  public whereToCut(mainlist, portInfo, i, j, cutType, e) {
    // removing duplicates vessel names
    this.overlapDetailList = [];

    const portsLength = mainlist.lstPortsListDto.length;
    this.deploymentsOnIndexPort = [];
    this.deploymentsOnBelowIndexPort = [];
    this.shortenType = 'vessel';
    const dialogPosition: DialogPosition = {
      top: e.y + 'px',
      left: e.x + 'px',
    };
    this.showOverlapVesselListBox = false;
    this.cutSpot = j;
    this.cutIndexInDto = i;
    const cutPosition = portInfo.lstScheduleListDto[j].position;
    this.mainListPlanner = mainlist;
    this.nextPortInfo = mainlist.lstPortsListDto[i + 1];
    this.portInfoForCut = portInfo;
    this.cutType = cutType;
    // this.overlapDetailList = [];
    if (cutType === 'arrivalVslDto') {
      if (
        portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto &&
        portInfo.lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto
      ) {
        this.showOverlapVesselListBox = true;
        portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutSpot = j;
        portInfo.lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto.cutSpot = j;
        portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutPosition = cutPosition;
        portInfo.lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto.cutPosition = cutPosition;
        this.overlapDetailList.push(portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto);
        this.overlapDetailList.push(portInfo.lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto);

        // removing duplicates
        if (this.overlapDetailList.length > 2) {
          this.overlapDetailList = this.overlapDetailList.filter(
            (item, index) => this.overlapDetailList.indexOf(item) !== index
          );
        }
        this.dialogRef = this.dialog.open(this.makeOverlapVesselCutSelection, {
          width: '250px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
          autoFocus: false,
          position: dialogPosition,
        });
        e.preventDefault();
        e.stopPropagation();
      } else {
        // x = i + 1
        for (let x = i; x < portsLength; x++) {
          if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto) {
            this.showOverlapVesselListBox = false;
            portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutSpot = j;
            portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutPosition = cutPosition;
            portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.checked = true;
            this.nonoverlapDetailList.push(
              mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].vesselDto
            );
            this.nonoverlapDetailList.push(
              mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].overlapVesselDto
            );
            e.preventDefault();
            e.stopPropagation();
          } else {
            this.showOverlapVesselListBox = false;
            // if (portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto) {
            if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].vesselDto) {
              portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutSpot = j;
              portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.cutPosition = cutPosition;
              portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto.checked = true;
              // this.nonoverlapDetailList.push(
              //   portInfo.lstScheduleListDto[j].lstPositionDto[0].vesselDto
              // );

              // visually disappear all deployments below cut scissor icon
              this.nonoverlapDetailList.push(
                mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].vesselDto
              );

              /* visually disappear the deployment of other vessels just below but when the current vessel already has right headed arrow or end of deployment (?) icon */
              // if (mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[1].vesselDto !== null) {
              //   if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto.vesselId !== mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselId) {
              //     this.nonoverlapDetailList.push(
              //       mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[0].vesselDto
              //     );
              //   }
              // }

              if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto !== null) {
                if (
                  mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto.vesselId !==
                  mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselId
                ) {
                  this.nonoverlapDetailList.push(
                    mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto
                  );
                }
              }

              e.preventDefault();
              e.stopPropagation();
            }
          }
        }
      }
    }
    if (cutType === 'departureVslDto') {
      if (
        portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto &&
        portInfo.lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto
      ) {
        portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutSpot = j;
        portInfo.lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto.cutSpot = j;
        portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutPosition = cutPosition;
        portInfo.lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto.cutPosition = cutPosition;
        this.showOverlapVesselListBox = true;
        this.overlapDetailList.push(portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto);
        this.overlapDetailList.push(portInfo.lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto);
        if (this.overlapDetailList.length > 2) {
          this.overlapDetailList = this.overlapDetailList.filter(
            (item, index) => this.overlapDetailList.indexOf(item) !== index
          );
        }
        this.dialogRef = this.dialog.open(this.makeOverlapVesselCutSelection, {
          width: '250px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
          autoFocus: false,
          position: dialogPosition,
        });
        e.preventDefault();
        e.stopPropagation();
      } else {
        for (let x = i; x < portsLength; x++) {
          if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto) {
            this.showOverlapVesselListBox = false;
            portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutSpot = j;
            portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutPosition = cutPosition;
            portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.checked = true;
            this.nonoverlapDetailList.push(
              mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto
            );
            this.nonoverlapDetailList.push(
              mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].overlapVesselDto
            );
            e.preventDefault();
            e.stopPropagation();
          } else {
            this.showOverlapVesselListBox = false;
            if (mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto) {
              portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutSpot = j;
              portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.cutPosition = cutPosition;
              portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto.checked = true;
              // this.nonoverlapDetailList.push(
              //   portInfo.lstScheduleListDto[j].lstPositionDto[1].vesselDto
              // );

              // visually disappear all deployments below cut scissor icon
              this.nonoverlapDetailList.push(
                mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto
              );

              /* visually disappear the deployment of other vessels just below but when the current vessel already has right headed arrow or end of deployment (?) icon */
              if (mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[0].vesselDto !== null) {
                if (
                  mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto.vesselId !==
                  mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselId
                ) {
                  this.nonoverlapDetailList.push(
                    mainlist.lstPortsListDto[x + 2].lstScheduleListDto[j].lstPositionDto[0].vesselDto
                  );
                }
              }

              if (mainlist.lstPortsListDto[x + 1].lstScheduleListDto[j].lstPositionDto[0].vesselDto !== null) {
                if (
                  mainlist.lstPortsListDto[x].lstScheduleListDto[j].lstPositionDto[1].vesselDto.vesselId !==
                  mainlist.lstPortsListDto[x + 1].lstScheduleListDto[j].lstPositionDto[0].vesselDto.vesselId
                ) {
                  this.nonoverlapDetailList.push(
                    mainlist.lstPortsListDto[x + 1].lstScheduleListDto[j].lstPositionDto[0].vesselDto
                  );
                }
              }

              e.preventDefault();
              e.stopPropagation();
            }
          }
        }
      }
    }
  }
  public cutFromPort(deployLst, lstPortsListDto, index, portType) {
    this.overlapDetailList = [];
    this.nonoverlapDetailList = [];
    this.shortenType = 'port';
    this.cutIndexInDto = index;
    this.mainListPlanner = deployLst;
    this.deploymentsOnIndexPort = [];
    this.deploymentsOnBelowIndexPort = [];
    this.cutType = portType;
    if (portType === 'arrivalPort') {
      for (let x = index; x < lstPortsListDto.length; x++) {
        lstPortsListDto[x].lstScheduleListDto.forEach((positionDet) => {
          const objVslDto = positionDet.lstPositionDto[0].vesselDto;
          const objVslOverlapDto = positionDet.lstPositionDto[0].overlapVesselDto;
          if (objVslDto) {
            objVslDto.scheduleDate = positionDet.lstPositionDto[0].scheduleDate;
            objVslDto.position = positionDet.position;
            objVslDto.isExtraloaderPosition = positionDet.isExtraloaderPosition;
            objVslDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objVslDto);
          }
          if (objVslOverlapDto) {
            objVslOverlapDto.scheduleDate = positionDet.lstPositionDto[0].scheduleDate;
            objVslOverlapDto.position = positionDet.position;
            objVslOverlapDto.isExtraloaderPosition = positionDet.isExtraloaderPosition;
            objVslOverlapDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objVslOverlapDto);
          }
        });
        /* added */
        lstPortsListDto[x].lstScheduleListDto.forEach((positionD) => {
          // const arrDto = positionD.lstPositionDto[0].vesselDto.vesselDeploymentId;
          const objDepVslDto = positionD.lstPositionDto[1].vesselDto;
          // if (objDepVslDto && arrDto !== objDepVslDto.vesselDeploymentId) {
          if (objDepVslDto) {
            objDepVslDto.scheduleDate = positionD.lstPositionDto[1].scheduleDate;
            objDepVslDto.position = positionD.position;
            objDepVslDto.isExtraloaderPosition = positionD.isExtraloaderPosition;
            objDepVslDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objDepVslDto);
          }
        });
        /* end */
      }
    }

    if (portType === 'departurePort') {
      for (let x = index; x < lstPortsListDto.length; x++) {
        lstPortsListDto[x].lstScheduleListDto.forEach((positionDet) => {
          const objVslDto = positionDet.lstPositionDto[1].vesselDto;
          const objVslOverlapDto = positionDet.lstPositionDto[1].overlapVesselDto;
          if (objVslDto) {
            objVslDto.scheduleDate = positionDet.lstPositionDto[1].scheduleDate;
            objVslDto.position = positionDet.position;
            objVslDto.isExtraloaderPosition = positionDet.isExtraloaderPosition;
            objVslDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objVslDto);
          }
          if (objVslOverlapDto) {
            objVslOverlapDto.scheduleDate = positionDet.lstPositionDto[1].scheduleDate;
            objVslOverlapDto.position = positionDet.position;
            objVslOverlapDto.isExtraloaderPosition = positionDet.isExtraloaderPosition;
            objVslOverlapDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objVslOverlapDto);
          }
        });
        /* added */
        lstPortsListDto[x + 1].lstScheduleListDto.forEach((positionD) => {
          const objArrVslDto = positionD.lstPositionDto[0].vesselDto;
          if (objArrVslDto) {
            objArrVslDto.scheduleDate = positionD.lstPositionDto[0].scheduleDate;
            objArrVslDto.position = positionD.position;
            objArrVslDto.isExtraloaderPosition = positionD.isExtraloaderPosition;
            objArrVslDto.isCompleteCut = false;
            this.deploymentsOnIndexPort.push(objArrVslDto);
          }
        });
        /* end */
      }
    }
    lstPortsListDto.forEach((port, portindex) => {
      if (portindex > index) {
        port.lstScheduleListDto.forEach((pos, posindex) => {
          const objVslArrDto = pos.lstPositionDto[0].vesselDto;
          const objVslArrOverlapDto = pos.lstPositionDto[0].overlapVesselDto;
          const objVslDepDto = pos.lstPositionDto[1].vesselDto;
          const objVslDepOverlapDto = pos.lstPositionDto[1].overlapVesselDto;
          if (objVslArrDto) {
            objVslArrDto.scheduleDate = pos.lstPositionDto[0].scheduleDate;
            objVslArrDto.position = pos.position;
            objVslArrDto.isExtraloaderPosition = pos.isExtraloaderPosition;
            objVslArrDto.isCompleteCut = true;
            this.deploymentsOnBelowIndexPort.push(objVslArrDto);
          }
          if (objVslArrOverlapDto) {
            objVslArrOverlapDto.scheduleDate = pos.lstPositionDto[0].scheduleDate;
            objVslArrOverlapDto.position = pos.position;
            objVslArrOverlapDto.isExtraloaderPosition = pos.isExtraloaderPosition;
            objVslArrOverlapDto.isCompleteCut = true;
            this.deploymentsOnBelowIndexPort.push(objVslArrOverlapDto);
          }
          if (objVslDepDto) {
            objVslDepDto.scheduleDate = pos.lstPositionDto[1].scheduleDate;
            objVslDepDto.position = pos.position;
            objVslDepDto.isExtraloaderPosition = pos.isExtraloaderPosition;
            objVslDepDto.isCompleteCut = true;
            this.deploymentsOnBelowIndexPort.push(objVslDepDto);
          }
          if (objVslDepOverlapDto) {
            objVslDepOverlapDto.scheduleDate = pos.lstPositionDto[1].scheduleDate;
            objVslDepOverlapDto.position = pos.position;
            objVslDepOverlapDto.isExtraloaderPosition = pos.isExtraloaderPosition;
            objVslDepOverlapDto.isCompleteCut = true;
            this.deploymentsOnBelowIndexPort.push(objVslDepOverlapDto);
          }
        });
      }
    });
  }
  public checkDeplIsNotCut(dto, i, depOrArr) {
    //  To hide the current arrival when cut across arrival is made
    if (this.cutType === 'arrivalPort' || this.cutType === 'arrivalVslDto') {
      i = i + 0.5;
    }
    let vesselIsNotCut = true;
    const isDepOrArrCut =
      this.cutType === 'departurePort' || this.cutType === 'departureVslDto'
        ? 'arr'
        : this.cutType === 'arrivalPort' || this.cutType === 'arrivalVslDto'
        ? 'dep'
        : '';
    if (this.shortenType === 'vessel') {
      const tempoverlapDetailList = [
        ...this.overlapDetailList.filter((x) => x.checked === true),
        ...this.nonoverlapDetailList,
      ];
      vesselIsNotCut =
        tempoverlapDetailList.findIndex((element) => element.vesselDeploymentId === dto.vesselDeploymentId) > -1 &&
        (i > this.cutIndexInDto || (i === this.cutIndexInDto && depOrArr !== isDepOrArrCut && depOrArr === 'dep'))
          ? false
          : true;
    }
    if (this.shortenType === 'port') {
      vesselIsNotCut =
        this.deploymentsOnIndexPort.findIndex((element) => element.vesselDeploymentId === dto.vesselDeploymentId) >
          -1 &&
        (i > this.cutIndexInDto || (i === this.cutIndexInDto && depOrArr !== isDepOrArrCut && depOrArr === 'dep'))
          ? false
          : true;
    }
    return vesselIsNotCut;
  }
  public changeSelection(event, index) {
    this.overlapDetailList[index].checked = event.checked;
  }
  public cancelSelection() {
    // fix for duplicate ports on cut schedule popup
    this.overlapDetailList = [];
  }
  public cancelShortenSchedule() {
    this.overlapDetailList = [];
    this.nonoverlapDetailList = [];
    this.cutIndex = null;
    this.cutLocation = null;
    this.selectedValue = null;
    this.deploymentsOnBelowIndexPort = [];
    this.deploymentsOnIndexPort = [];
    this.cutIndexInDto = null;
    this.cutType = null;
    this.shortenType = null;
    const obj = {
      checked: false,
      cancel: true,
    };
    this.cutVesselSwitch(obj);
  }
  public saveShortenedSchedule() {
    let postObj = {};
    const cutDeploymentsDtoObj = [];
    if (this.shortenType === 'vessel') {
      let arrayOfDepIds = [];
      let dto;
      let cutSpot;

      // To remove duplicates from array by all properties
      this.nonoverlapDetailList = this.nonoverlapDetailList.filter(
        (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      );

      // this.nonoverlapDetailList = this.nonoverlapDetailList.length > 2 ? this.nonoverlapDetailList.splice(2) : this.nonoverlapDetailList;

      // Removing duplicate vesselDeploymentId
      this.nonoverlapDetailList = this.nonoverlapDetailList.filter(
        (v, i, a) => a.findIndex((t) => t.vesselDeploymentId === v.vesselDeploymentId) === i
      );

      this.overlapDetailList = [...this.overlapDetailList, ...this.nonoverlapDetailList];
      this.overlapDetailList.forEach((vsl) => {
        if (vsl.checked) {
          cutSpot = vsl.cutSpot;
          dto =
            this.cutType === 'arrivalVslDto'
              ? this.portInfoForCut.lstScheduleListDto[cutSpot].lstPositionDto[0]
              : this.portInfoForCut.lstScheduleListDto[cutSpot].lstPositionDto[1];
          const obj = {
            vesselDeploymentId: vsl.vesselDeploymentId,
            position: this.portInfoForCut.lstScheduleListDto[cutSpot].position,
            isExtraPosition: this.portInfoForCut.lstScheduleListDto[cutSpot].isExtraloaderPosition,
            cutDate: dto.scheduleDate,
            isCompleteDeploymentCut: false,
          };
          cutDeploymentsDtoObj.push(obj);

          this.mainListPlanner.lstPortsListDto.forEach((element, idx) => {
            if (idx > this.cutIndex) {
              const dtoArr1DepId =
                element.lstScheduleListDto[cutSpot].lstPositionDto[0].vesselDto &&
                new Date(
                  element.lstScheduleListDto[cutSpot].lstPositionDto[0].vesselDto.deploymentStartDate
                ).getTime() > new Date(dto.scheduleDate).getTime()
                  ? element.lstScheduleListDto[cutSpot].lstPositionDto[0].vesselDto.vesselDeploymentId
                  : null;

              const dtoArr2DepId =
                element.lstScheduleListDto[cutSpot].lstPositionDto[0].overlapVesselDto &&
                new Date(
                  element.lstScheduleListDto[cutSpot].lstPositionDto[0].overlapVesselDto.deploymentStartDate
                ).getTime() > new Date(dto.scheduleDate).getTime()
                  ? element.lstScheduleListDto[cutSpot].lstPositionDto[0].overlapVesselDto.vesselDeploymentId
                  : null;

              const dtoDepr1DepId =
                element.lstScheduleListDto[cutSpot].lstPositionDto[1].vesselDto &&
                new Date(
                  element.lstScheduleListDto[cutSpot].lstPositionDto[1].vesselDto.deploymentStartDate
                ).getTime() > new Date(dto.scheduleDate).getTime()
                  ? element.lstScheduleListDto[cutSpot].lstPositionDto[1].vesselDto.vesselDeploymentId
                  : null;

              const dtoDepr2DepId =
                element.lstScheduleListDto[cutSpot].lstPositionDto[1].overlapVesselDto &&
                new Date(
                  element.lstScheduleListDto[cutSpot].lstPositionDto[1].overlapVesselDto.deploymentStartDate
                ).getTime() > new Date(dto.scheduleDate).getTime()
                  ? element.lstScheduleListDto[cutSpot].lstPositionDto[1].overlapVesselDto.vesselDeploymentId
                  : null;

              arrayOfDepIds = [...arrayOfDepIds, dtoArr1DepId, dtoArr2DepId, dtoDepr1DepId, dtoDepr2DepId];
            }
          });
        }
        arrayOfDepIds = Array.from(new Set(arrayOfDepIds));
        arrayOfDepIds.forEach((depId) => {
          if (depId && depId !== vsl.vesselDeploymentId) {
            const obj = {
              vesselDeploymentId: depId,
              position: this.portInfoForCut.lstScheduleListDto[cutSpot].position,
              isExtraPosition: this.portInfoForCut.lstScheduleListDto[cutSpot].isExtraloaderPosition,
              cutDate: null,
              isCompleteDeploymentCut: true,
            };
            cutDeploymentsDtoObj.push(obj);
          }
        });
      });
    }
    if (this.shortenType === 'port') {
      let combinedDeplObj = [];
      combinedDeplObj = [...this.deploymentsOnIndexPort, ...this.deploymentsOnBelowIndexPort];
      const combinedDeplObjUnique = Array.from(new Set(combinedDeplObj.map((a) => a.vesselDeploymentId))).map(
        (vesselDeploymentId) => {
          return combinedDeplObj.find((a) => a.vesselDeploymentId === vesselDeploymentId);
        }
      );
      combinedDeplObjUnique.forEach((element) => {
        const obj = {
          vesselDeploymentId: element.vesselDeploymentId,
          position: element.position,
          isExtraPosition: element.isExtraloaderPosition,
          cutDate: element.scheduleDate,
          isCompleteDeploymentCut: element.isCompleteCut,
        };
        cutDeploymentsDtoObj.push(obj);
      });
    }
    if (cutDeploymentsDtoObj !== undefined && cutDeploymentsDtoObj.length > 0) {
      postObj = {
        proformaId: this.mainListPlanner.proformaId,
        confirmCut: false,
        cutDeploymentsDto: cutDeploymentsDtoObj,
      };

      this.serviceRepoServie.cutDeploymentAndSchedule(postObj).subscribe(
        (response: any) => {
          this.newScheduleDate = response;
          this.dialogRef = this.dialog.open(this.confirmationCutSchedule, {
            width: '350px',
            height: 'auto',
            panelClass: 'dialog-container',
            disableClose: true,
            autoFocus: false,
          });
          this.dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((result) => {
              if (result) {
                postObj = {
                  proformaId: this.mainListPlanner.proformaId,
                  confirmCut: true,
                  cutDeploymentsDto: cutDeploymentsDtoObj,
                };
                this.serviceRepoServie.cutDeploymentAndSchedule(postObj).subscribe((res: any) => {
                  if (res) {
                    this.getServicePlanningLst();
                    this.router
                      .navigateByUrl('/services', {
                        skipLocationChange: true,
                      })
                      .then(() =>
                        this.router.navigate([
                          '/services/' +
                            this.id +
                            '/schedule/' +
                            this.servicePlanningLst.lstProformaListDto[0].proformaId +
                            '/true' +
                            '/deployments/true',
                        ])
                      );
                  }
                });
              }
            });
        },
        (errorResponse) => {
          if (errorResponse instanceof HttpErrorResponse) {
            const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
            if (error) {
              this.showProlongErr = true;
              this.prolongErrorMsg = error;
              this.dialogRef = this.dialog.open(this.warningOnChangeSchedule, {
                width: '350px',
                height: 'auto',
                panelClass: 'dialog-container',
                disableClose: true,
                autoFocus: false,
              });
            }
          }
        }
      );
    }
  }
  public fetchMore(e) {
    const len = this.portsBuffer.length;
    const more = this.servicePlanningLst.lstProformaListDto[0].lstFilterSchedulePortsDto.slice(
      len,
      this.bufferSize + len
    );
    //  this.loading = true;
    this.portsBuffer = this.portsBuffer.concat(more);
  }

  public ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  public setTooltipDataOnHoverForVslDpt(dToObj?) {
    if (!dToObj) {
      return;
    }

    const vslCode = dToObj.vslVesselType === 'Dummy' ? (dToObj.vesselCode = 'DUM') : dToObj.vesselCode;
    return `(${dToObj.operatorCode}) ${vslCode !== '' ? vslCode : ''} ${dToObj.vesselName}`;
  }

  public setTooltipDataOnHoverForOverlapVslDpt(dToObj?, overlapDtoObj?) {
    if (!dToObj) {
      return;
    }
    const vslCode = dToObj.vslVesselType === 'Dummy' ? (dToObj.vesselCode = 'DUM') : dToObj.vesselCode;
    const overlapVslCode =
      overlapDtoObj.vslVesselType === 'Dummy' ? (overlapDtoObj.vesselCode = 'DUM') : overlapDtoObj.vesselCode;

    if (dToObj && overlapDtoObj) {
      const setVesselDto = this.checkVesselStartingOnSameDate(dToObj, overlapDtoObj);
      overlapDtoObj = setVesselDto.vesselDto;
      dToObj = setVesselDto.overlapVesselDto;
    }

    const str1 = `Phase-in: (${overlapDtoObj.operatorCode}) ${overlapVslCode} ${overlapDtoObj.vesselName}`;
    const str2 = `Phase-out: (${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}`;
    // const str1 = `Phase-in: (${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}`;
    // const str2 = `Phase-out: (${overlapDtoObj.operatorCode}) ${overlapVslCode} ${overlapDtoObj.vesselName}`;
    const tooltipData = [];
    tooltipData.push(str1);
    tooltipData.push(str2);
    const tooltipDatatoDisplay = tooltipData.join('\n');
    if (this.isOverlapedVesselSame) {
      return `(${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}`;
    }
    return tooltipDatatoDisplay;
    // return `Phase-in: (${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}    Phase-out: (${overlapDtoObj.operatorCode}) ${overlapVslCode} ${overlapDtoObj.vesselName}`
  }

  public checkVesselStartingOnSameDate(vesselDto, overlapVesselDto) {
    if (overlapVesselDto && vesselDto && overlapVesselDto.deploymentStartDate && vesselDto.deploymentStartDate) {
      if (
        new Date(overlapVesselDto.deploymentStartDate).setHours(0, 0, 0, 0) ===
        new Date(vesselDto.deploymentStartDate).setHours(0, 0, 0, 0)
      ) {
        return { vesselDto: vesselDto, overlapVesselDto: overlapVesselDto };
      } else {
        return { vesselDto: overlapVesselDto, overlapVesselDto: vesselDto };
      }
    }
  }

  public checkVesselEndingOnSameDate(vesselDto, overlapVesselDto) {
    if (overlapVesselDto && vesselDto && overlapVesselDto.deploymentStartDate && vesselDto.deploymentStartDate) {
      if (
        new Date(overlapVesselDto.deploymentStartDate).setHours(0, 0, 0, 0) >
        new Date(vesselDto.deploymentStartDate).setHours(0, 0, 0, 0)
      ) {
        return { vesselDto: vesselDto, overlapVesselDto: overlapVesselDto };
      } else {
        return { vesselDto: overlapVesselDto, overlapVesselDto: vesselDto };
      }
    }
  }

  public checkSameStartDate(vesselDto, overlapVesselDto) {
    if (overlapVesselDto && vesselDto && overlapVesselDto.deploymentStartDate && vesselDto.deploymentStartDate) {
      return new Date(overlapVesselDto.deploymentStartDate).setHours(0, 0, 0, 0) ===
        new Date(vesselDto.deploymentStartDate).setHours(0, 0, 0, 0)
        ? true
        : false;
    }
  }

  public checkSameEndDate(vesselDto, overlapVesselDto) {
    if (overlapVesselDto && vesselDto && overlapVesselDto.deploymentEndDate && vesselDto.deploymentEndDate) {
      return new Date(overlapVesselDto.deploymentEndDate).setHours(0, 0, 0, 0) ===
        new Date(vesselDto.deploymentEndDate).setHours(0, 0, 0, 0)
        ? true
        : false;
    }
  }

  public getVesselEndingLast(portInfo) {
    const isSameStartDate = this.checkSameStartDate(portInfo.vesselDto, portInfo.overlapVesselDto);
    const isSameEndDate = this.checkSameEndDate(portInfo.vesselDto, portInfo.overlapVesselDto);
    let vesselDetails = {
      vesselDto: portInfo.vesselDto,
      overlapVesselDto: portInfo.overlapVesselDto,
    };
    if (isSameStartDate) {
      vesselDetails = this.setVesselEndingLastAsPhaseIn(portInfo.vesselDto, portInfo.overlapVesselDto);
    }
    if (isSameEndDate) {
      vesselDetails = this.setVesselEndingLastAsPhaseIn(portInfo.vesselDto, portInfo.overlapVesselDto);
    }
    if (!isSameEndDate && !isSameStartDate) {
      vesselDetails = {
        vesselDto: portInfo.overlapVesselDto,
        overlapVesselDto: portInfo.vesselDto,
      };
    }
    this.setVesselDto(vesselDetails.overlapVesselDto);
    this.setVesselDtoForQIcon = true;
  }

  public setVesselEndingLastAsPhaseIn(vesselDto, overlapVesselDto) {
    this.setVesselDto(overlapVesselDto);
    this.setVesselDtoForQIcon = true;
    if (overlapVesselDto && vesselDto && overlapVesselDto.deploymentEndDate && vesselDto.deploymentEndDate) {
      if (
        new Date(overlapVesselDto.deploymentEndDate).setHours(0, 0, 0, 0) <
        new Date(vesselDto.deploymentEndDate).setHours(0, 0, 0, 0)
      ) {
        return { vesselDto: vesselDto, overlapVesselDto: overlapVesselDto };
      } else {
        return { vesselDto: overlapVesselDto, overlapVesselDto: vesselDto };
      }
    }
  }

  public validateVessel(portInfo, j) {
    let departurePortInfo;
    if (portInfo && portInfo.lstScheduleListDto[j]) {
      departurePortInfo = this.setDeptDate(
        portInfo.lstScheduleListDto[j].lstPositionDto[0],
        portInfo.lstScheduleListDto[j].lstPositionDto[1]
      );
    }
    if (this.checkVesselStartingOnSameDate(departurePortInfo.vesselDto, departurePortInfo.overlapVesselDto)) {
      return this.checkVesselStartingOnSameDate(departurePortInfo.vesselDto, departurePortInfo.overlapVesselDto)
        .vesselDto;
    } else {
      return departurePortInfo.vesselDto;
    }
  }

  public validateArrivalVessel(portInfo, j) {
    let arrivalPortInfo;
    if (portInfo && portInfo.lstScheduleListDto[j]) {
      arrivalPortInfo = this.setArrivalDate(
        portInfo.lstScheduleListDto[j].lstPositionDto[0],
        portInfo.lstScheduleListDto[j].lstPositionDto[1]
      );
    }
    if (this.checkVesselStartingOnSameDate(arrivalPortInfo.vesselDto, arrivalPortInfo.overlapVesselDto)) {
      return this.checkVesselStartingOnSameDate(arrivalPortInfo.vesselDto, arrivalPortInfo.overlapVesselDto).vesselDto;
    } else {
      return arrivalPortInfo.vesselDto;
    }
  }

  public setNameDataForOverlapVslDpt(dToObj?, overlapDtoObj?) {
    if (!dToObj) {
      return;
    }
    // let vslCode = dToObj.vslVesselType === 'Dummy' ?  dToObj.vesselCode='DUM' : dToObj.vesselCode;
    if (dToObj && overlapDtoObj) {
      overlapDtoObj = this.checkVesselStartingOnSameDate(dToObj, overlapDtoObj).vesselDto;
    }
    const overlapVslCode =
      overlapDtoObj.vslVesselType === 'Dummy' ? (overlapDtoObj.vesselCode = 'DUM') : overlapDtoObj.vesselCode;

    const str1 = `(${overlapDtoObj.operatorCode}) ${overlapVslCode} ${overlapDtoObj.vesselName}`;
    // let str2 = `Phase-out: (${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}`;
    const tooltipData = [];
    tooltipData.push(str1);
    // tooltipData.push(str2);
    // const tooltipDatatoDisplay = tooltipData.join('\n');
    const tooltipDatatoDisplay = tooltipData;
    return tooltipDatatoDisplay;
    // return `Phase-in: (${dToObj.operatorCode}) ${vslCode} ${dToObj.vesselName}    Phase-out: (${overlapDtoObj.operatorCode}) ${overlapVslCode} ${overlapDtoObj.vesselName}`
  }

  public isVesselDeployed(arvDate?, depDate?) {
    const isVslDept =
      this.setDeptDate(arvDate, depDate).overlapVesselDto == null
        ? this.setDeptDate(arvDate, depDate).vesselDto
        : this.setDeptDate(arvDate, depDate).overlapVesselDto;
    return `${isVslDept.vesselName}`;
  }

  // method to find if same vessel is overlapped
  public isSameVessel(postionDtoArrival, positionDtoDeparture) {
    let isSameVesselOverlaped = false;
    let oDto;
    let vDto;
    if (postionDtoArrival && postionDtoArrival.overlapVesselDto && postionDtoArrival.vesselDto) {
      oDto = postionDtoArrival.overlapVesselDto;
      vDto = postionDtoArrival.vesselDto;
    } else if (positionDtoDeparture && positionDtoDeparture.overlapVesselDto && positionDtoDeparture.vesselDto) {
      oDto = positionDtoDeparture.overlapVesselDto;
      vDto = positionDtoDeparture.vesselDto;
    }
    isSameVesselOverlaped = this.isSameVesselOverlaped(oDto, vDto);
    this.isOverlapedVesselSame = isSameVesselOverlaped;
    return isSameVesselOverlaped;
  }

  public isSameVesselOverlaped(oDto, vDto) {
    return oDto.vesselId === vDto.vesselId &&
      new Date(vDto.deploymentEndDate).setHours(0, 0, 0, 0) === new Date(oDto.deploymentStartDate).setHours(0, 0, 0, 0)
      ? true
      : false;
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public reload() {
    // window.location.reload();
    this.isplannerLoading = true;
    this.getServicePlanningLst();
  }

  public vesselLink() {
    if (this.vesselDto.vesselType === 4) {
      window.open('/vessels/dummy/overview/' + this.vesselDto.vesselId, '_blank', 'noreferrer');
    } else {
      window.open('/vessels/' + this.vesselDto.vesselId, '_blank', 'noreferrer');
    }
  }

  public fixtureLink() {
    window.open('/fixtures/' + this.vesselDto.fixtureId, '_blank', 'noreferrer');
  }

  //added
  public setPreSetFiltersForFav() {
    this.multiControlsservice.favouritesMultiCtrl.setValue(['ALL']);
    // this.setAllFavouritesVal();
  }
  public setAllFavouritesVal() {
    this.multiControlsservice.favouritesMultiCtrl.setValue(this.favList, {
      onlySelf: true,
      emitEvent: false,
    });
  }
  // public removeFavourites(item?) {
  //   if (item.label == 'ALL') {
  //     this.multiControlsservice.favouritesMultiCtrl.setValue([]);
  //   }
  // }
  public addFavourites(item?) {
    if (item != null) {
      this.isfavouritesAdded = true;
      const control = this.multiControlsservice.favouritesMultiCtrl;
      const isAllFavchecked = Array.isArray(control.value) ? control.value.includes('ALL') : false;
      if (isAllFavchecked) {
        control.value.shift();
        this.updateFavListFilterControl([item]);
      } else {
        if (item === 'ALL') {
          this.updateFavListFilterControl([]);
          this.updateFavListFilterControl([item]);
        }
      }
    }
    //added
    // if (item == 'ALL') {
    //   this.multiControlsservice.favouritesMultiCtrl.setValue(this.favList, {
    //     onlySelf: true,
    //     emitEvent: false,
    //   });
    // }
  }

  public updateFavListFilterControl(data) {
    this.multiControlsservice.favouritesMultiCtrl.setValue(data, { onlySelf: true, emitEvent: true });
  }

  public allFiltered() {
    this.filteredArray = [];
    this.filteredArray = this.favouritesFilter(this.filtersList, this.multiControlsservice.favouritesMultiCtrl.value);
    this.filteredArray = this.segmentsFilter(this.filteredArray, this.multiControlsservice.segmentMultiCtrl.value);
  }
  public favouritesFilter(serviceToBeFiltered, userNameValArr) {
    if (this.multiControlsservice.favouritesMultiCtrl.value.length > 0) {
      const filteredLstSegments = [];
      this.segList = [];
      this.serList = [];
      if (userNameValArr.find((i) => i === 'ALL') !== 'ALL') {
        this.multiControlsservice.segmentMultiCtrl.setValue([]);
        userNameValArr.forEach((userNameVal) => {
          const selectedUser = serviceToBeFiltered.find((item) => {
            return item.userName === userNameVal;
          });
          if (selectedUser) {
            filteredLstSegments.push(...selectedUser.lstSegments);
          }
          this.segList = Array.from(
            new Set(filteredLstSegments.filter((e) => e.segment !== null).map((e) => e.segment))
          );
        });
      } else {
        this.segList = this.segmentListOrig;
        this.multiControlsservice.segmentMultiCtrl.setValue([]);
        this.serList = this.serviceListOrig;
      }
      return filteredLstSegments;
    } else {
      this.segList = [];
      this.multiControlsservice.segmentMultiCtrl.setValue([]);
      this.serList = [];
    }
    return serviceToBeFiltered;
  }
  public segmentsFilter(serviceToBeFiltered, selectedSegValueArr) {
    if (this.multiControlsservice.favouritesMultiCtrl.value.length > 0 && serviceToBeFiltered !== undefined) {
      if (
        this.multiControlsservice.segmentMultiCtrl.value != null &&
        this.multiControlsservice.segmentMultiCtrl.value.length > 0
      ) {
        const filteredLstServices = [];
        this.serList = [];
        if (this.multiControlsservice.favouritesMultiCtrl.value.find((i) => i === 'ALL') !== 'ALL') {
          selectedSegValueArr.forEach((selectedSegVal) => {
            const selectedSegment = serviceToBeFiltered.find((item) => {
              return item.segment === selectedSegVal;
            });
            if (selectedSegment) {
              filteredLstServices.push(...selectedSegment.lstServices);
            }
            this.serList = filteredLstServices;
          });
          return serviceToBeFiltered;
        } else {
          selectedSegValueArr.forEach((selectedSegVal) => {
            const selectedSegment = this.allSegList.find((item) => {
              return item.segment === selectedSegVal;
            });
            if (selectedSegment) {
              filteredLstServices.push(...selectedSegment.lstServices);
            }
            this.serList = filteredLstServices;
          });
          return serviceToBeFiltered;
        }
      } else if (this.multiControlsservice.favouritesMultiCtrl.value.find((i) => i === 'ALL') === 'ALL') {
        this.serList = this.serviceListOrig;
      } else {
        const temp = serviceToBeFiltered.map((item) => item.lstServices);
        const allSelectedServices = temp.reduce((arr, e) => {
          return arr.concat(e);
        }, []);

        this.serList = this.sortFilteredServiceListDataByCodeAndValidTo(allSelectedServices);
      }
    } else {
      this.segList = [];
      this.serList = [];
    }
    return serviceToBeFiltered;
  }

  public sortFilteredServiceListDataByCodeAndValidTo(serviceList?) {
    if (!serviceList) {
      return;
    }
    return serviceList
      .sort((a, b) => {
        return a.serviceCode < b.serviceCode ? -1 : a.serviceCode > b.serviceCode ? 1 : a.vaildTo < b.vaildTo ? -1 : 1;
      })
      .reduce((x, y) => (x.findIndex((e) => e.serviceId === y.serviceId) < 0 ? [...x, y] : x), []);
  }

  public remove(filter: any, ctrl: string = null): void {
    console.log(this.multiControlsservice.favouritesMultiCtrl.value);
    /* remove all filters chip */
    if (
      filter === 'all' ||
      (this.multiControlsservice.favouritesMultiCtrl.value.length === 1 && ctrl === 'favouritesMultiCtrl')
    ) {
      this.multiControlsservice.favouritesMultiCtrl.setValue([]);
      this.multiControlsservice.segmentMultiCtrl.setValue([]);
    }
    if (this.multiControlsservice[ctrl] !== undefined) {
      const removeFilterIndex = this.multiControlsservice[ctrl].value
        ? this.multiControlsservice[ctrl].value.indexOf(filter)
        : -1;
      if (removeFilterIndex >= 0) {
        this.multiControlsservice[ctrl].value.splice(removeFilterIndex, 1);
        this.multiControlsservice[ctrl].setValue(this.multiControlsservice[ctrl].value);
      }
    }
  }
  private getSchDetail() {
    this.dataService.switchSchOverview.subscribe((data) => {
      this.isCreatedDated = data;
    });

    // this.serviceRepoService.getScheduleDetail(this.schhId).subscribe((res: any) => {
    //   this.schDetails = res;
    //   this.serviceRepoService.scheduleDetail = res;
    //   this.dataService.updateScheduleHeaderOverviewData(this.schDetails);
    //   this.approved = new FormControl(res.isVisible);
    // });
    // To fetch data for Schedule Planner API
    this.serviceRepoService.getFiltersForPlanner().subscribe((res: any) => {
      this.filtersList = res.lstUsersInfo;
      this.favList = res.lstUsers;
      this.favList.unshift('ALL');
      // All Segments to Show By Default
      this.segList = res.lstAllSegments.map((e) => e.segment);
      this.allSegList = res.lstAllSegments;
      if (this.isReportingRole) {
        this.serList = res.lstServices.filter((item) => item.proformHeaderID !== null && item.isApproved === true);
      } else {
        this.serList = res.lstServices.filter((item) => item.proformHeaderID !== null); // Retriving only those services whose proforma id is not null
      }
      this.segmentListOrig = res.lstAllSegments.map((e) => e.segment);
      this.serviceListOrig = res.lstServices.filter((item) => item.proformHeaderID !== null);
      // this.setPreSetFiltersForFav();
      /* Listening to changes in the filter planner dropdown */
      this.multiControlsservice.favouritesMultiCtrl.valueChanges.subscribe(() => {
        this.allFiltered();
      });
      this.multiControlsservice.segmentMultiCtrl.valueChanges.subscribe(() => {
        this.segmentsFilter(this.filteredArray, this.multiControlsservice.segmentMultiCtrl.value);
      });
      this.multiControlsservice.serviceMultiCtrl.valueChanges.subscribe(() => {});
    });
  }
}
