import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeToDisplay'
})
export class TypeToDisplayPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    switch (value) {

      // Main Lists
      case 'timeCharterHireList':
        return 'Time Charter Hire';
      case 'lumpsumsList':
        return 'Lumpsums';
        case 'charterersExpense':
        return 'Charterer\'s expense';
        case 'ownersExpense':
        return 'Owner\'s expense';
      case 'offhireList':
        return 'Offhire';
      case 'otherCommissionList':
        return 'Other';
      case 'bunkerDtl':
        return 'Bunker';

      // Bunkers Lists section
      case 'estimatedBunkersOnDelivery':
        return 'Estimated on Delivery';
      case 'refundOfEstimatedBunkersOnDelivery':
        return 'Refund of Estimated on Delivery';
      case 'actualBunkersOnDelivery':
        return 'Actual on Delivery';
      case 'estimatedBunkersOnRedelivery':
        return 'Estimated on Redelivery';
      case 'refundofEstimatedBunkersOnRedelivery':
        return 'Refund of Estimated on Redelivery';
      case 'actualBunkersOnRedelivery':
        return 'Actual on Redelivery';

    }
  }

}
