import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { filter, takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { PaintsService } from 'src/app/core/services/paints/paints.service';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { isValidDate } from '../../utils/valid-date';
import { scrollToError } from '../../utils/scroll-utils';

const moment = _moment;
@Component({
  selector: 'champs-vessel-new-vessel-specifications',
  templateUrl: './vessel-new-vessel-specifications.component.html',
  styleUrls: ['./vessel-new-vessel-specifications.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VesselNewVesselSpecificationsComponent implements OnInit, OnDestroy {
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  private _showFltrValue;

  @Output() public changeStep: EventEmitter<any> = new EventEmitter();

  @Input() public values: any;
  public dryDockDateError: boolean;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  public originalValues: any = null;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  public dimensionFrmGrp: UntypedFormGroup;
  public tonnageFrmGrp: UntypedFormGroup;
  public hullInfoFrmGrp: UntypedFormGroup;
  public tankCapFrmGrp: UntypedFormGroup;
  public fuelSpecFrmGrp: UntypedFormGroup;

  public SrvcPrepVrtclSide: any[];
  public SrvcPrepVrtclSideValue: any[];
  public ServcPrepFlatBottm: any[]; // edit
  public ServcPrepFlatBottmValue: any[];
  public paintTypes: ReplaySubject<any> = new ReplaySubject(1); // edit

  public fuelISOYears$: Observable<any>;
  public mainFuelTypes$: Observable<any>;
  public auxFuelTypes$: Observable<any>;
  public distillateFuelTypes$: Observable<any>;
  public halfFuelTypes$: Observable<any>;
  public ulsFuelTypes$: Observable<any>;
  public surfacePrep1$: Observable<any>;
  public surfacePrep2$: Observable<any>;
  public paintMaker$: Observable<any>;

  public minDate = new Date(1900, 0, 1);
  public maxDate = new Date(2100, 11, 31);

  public showFormErrors: boolean = false;
  public showPaintError: boolean = false;
  public showGTLessError: boolean = false;
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<any>,
    private vesselDropdowns: VesselDropdownsService,
    private paintsService: PaintsService,
    public dialog: MatDialog
  ) {
    this.mainFuelTypes$ = this.vesselDropdowns.getMainFuelTypes();
    this.auxFuelTypes$ = this.vesselDropdowns.getAuxFuelTypes();
    this.distillateFuelTypes$ = this.vesselDropdowns.getDistillateFuelTypes();
    this.halfFuelTypes$ = this.vesselDropdowns.getHalfFuelTypes();
    this.ulsFuelTypes$ = this.vesselDropdowns.getULSFuelTypes();
    this.fuelISOYears$ = this.vesselDropdowns.getFuelISOYears();
    this.surfacePrep1$ = this.vesselDropdowns.getSurfacePrepDropDown1();
    this.surfacePrep2$ = this.vesselDropdowns.getSurfacePrepDropDown2();
    this.paintMaker$ = this.paintsService.getPaintsMaker();
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.createDimForm();
    this.createTonnageForm();
    this.createHullInfoForm();
    this.createTankCapForm();
    this.createFuelSpecForm();
    this.paintMakerChanged();
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /** */
  public nextStep(): void {
    if (
      this.hullInfoFrmGrp.get('vslAntiFouling').value &&
      !this.hullInfoFrmGrp.get('vslPaintMaker').value &&
      !this.hullInfoFrmGrp.get('vslPaintType').value
    ) {
      this.showPaintError = true;
      scrollToError(this.matErrorRefs);
      return;
    } else if (
      this.hullInfoFrmGrp.get('vslLastDryDockDate').value &&
      this.hullInfoFrmGrp.get('vslNextDryDockDate').value &&
      this.hullInfoFrmGrp.get('vslLastDryDockDate').value > this.hullInfoFrmGrp.get('vslNextDryDockDate').value
    ) {
      this.dryDockDateError = true;
      scrollToError(this.matErrorRefs);
      return;
    } else if (
      this.tonnageFrmGrp.get('vslGT').value &&
      this.tonnageFrmGrp.get('vslNT').value &&
      Number(this.tonnageFrmGrp.get('vslGT').value) < Number(this.tonnageFrmGrp.get('vslNT').value)
    ) {
      this.showFormErrors = true;
      this.showGTLessError = true;
      scrollToError(this.matErrorRefs);
      return;
    } else if (
      !this.dimensionFrmGrp.valid ||
      !this.tonnageFrmGrp.valid ||
      !this.tankCapFrmGrp.valid ||
      !this.fuelSpecFrmGrp.valid
    ) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
        // return;
      }
      let requiredError = false;
      Object.keys(this.dimensionFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.dimensionFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (!requiredError) {
        Object.keys(this.tonnageFrmGrp.controls).forEach((key) => {
          const controlErrors: ValidationErrors = this.tonnageFrmGrp.get(key).errors;
          if (controlErrors != null) {
            for (const error of Object.keys(controlErrors)) {
              if (error === 'required') {
                requiredError = true;
                break;
              }
            }
          }
        });
      }
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    } else {
      this.showPaintError = false;
      this.showGTLessError = false;
      const valueObj = this.createPostObj();
      this.store.dispatch(new VesselCreateActions.PutVesselSpecification(valueObj));
      this.changeStep.emit();
    }
  }

  public paintMakerChanged(e: any = null) {
    const val = e ? e.value : this.values.vslPaintMaker;
    if (val && val > 0) {
      this.paintsService
        .getPaintsTypeByMaker(val)
        .pipe(
          filter((x) => x !== undefined),
          distinctUntilChanged()
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.paintTypes.next(res);
          if (this.hullInfoFrmGrp.get('vslPaintType').value) {
            const match = res.filter((ptMaker) => ptMaker.ptPKID === this.hullInfoFrmGrp.get('vslPaintType').value);
            if (match.length === 0) {
              this.hullInfoFrmGrp.get('vslPaintType').setValue(null);
            }
          }
        });

      return;
    }

    this.hullInfoFrmGrp.get('vslPaintType').setValue(null);
  }
  public updateVslisUniFielChecked(option, event) {
    if (!event.checked) {
      this.fuelSpecFrmGrp.get('vslAuxFuel').setValue(null);
    }
  }

  private createPostObj() {
    return {
      ...this.dimensionFrmGrp.value,
      ...this.tonnageFrmGrp.value,
      ...{
        ...this.hullInfoFrmGrp.value,
        ...{
          vslLastDryDockDate:
            JSON.stringify(this.hullInfoFrmGrp.get('vslLastDryDockDate').value) !== 'null'
              ? new DatePipe('en-US').transform(this.hullInfoFrmGrp.get('vslLastDryDockDate').value, 'dd-MMM-yy')
              : null,
          vslNextDryDockDate:
            JSON.stringify(this.hullInfoFrmGrp.get('vslNextDryDockDate').value) !== 'null'
              ? new DatePipe('en-US').transform(this.hullInfoFrmGrp.get('vslNextDryDockDate').value, 'dd-MMM-yy')
              : null,
          vslLastHullCleanDate:
            JSON.stringify(this.hullInfoFrmGrp.get('vslLastHullCleanDate').value) !== 'null'
              ? new DatePipe('en-US').transform(this.hullInfoFrmGrp.get('vslLastHullCleanDate').value, 'dd-MMM-yy')
              : null,
          vslLastPropPolishDate:
            JSON.stringify(this.hullInfoFrmGrp.get('vslLastPropPolishDate').value) !== 'null'
              ? new DatePipe('en-US').transform(this.hullInfoFrmGrp.get('vslLastPropPolishDate').value, 'dd-MMM-yy')
              : null,
          vslLastWaterInspDate:
            JSON.stringify(this.hullInfoFrmGrp.get('vslLastWaterInspDate').value) !== 'null'
              ? new DatePipe('en-US').transform(this.hullInfoFrmGrp.get('vslLastWaterInspDate').value, 'dd-MMM-yy')
              : null,
        },
      },
      ...this.tankCapFrmGrp.value,
      ...this.fuelSpecFrmGrp.value,
    };
  }

  /** */
  private createDimForm() {
    this.dimensionFrmGrp = this.formBuilder.group({
      vslLOA: this.values.vslLOA,
      vslLBP: this.values.vslLBP,
      vslBeam: this.formBuilder.control(this.values.vslBeam, [Validators.max(99)]),
      vslDesignDraft: this.values.vslDesignDraft,
      vslTropDraught: this.values.vslTropDraught,
      vslDraft: this.values.vslDraft,
      vslDWT: this.formBuilder.control(this.values.vslDWT, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      vsgTropDeadWeight: this.values.vsgTropDeadWeight,
      vsgDesignDeadWeight: this.values.vsgDesignDeadWeight,
      vsgLightShipWeight: this.values.vsgLightShipWeight,
      vsgBlockCoefficient: this.values.vsgBlockCoefficient,
      vsgDeplacement: this.values.vsgDeplacement,
      vslMaxAirDraft: this.values.vslMaxAirDraft,
      vsgFreshWaterAllow: this.values.vsgFreshWaterAllow,
    });
  }

  private createTonnageForm() {
    this.tonnageFrmGrp = this.formBuilder.group({
      vslGT: this.formBuilder.control(this.values.vslGT, [Validators.required]),
      vslNT: this.formBuilder.control(this.values.vslNT, [Validators.required]),
      vslSuezT: this.values.vslSuezT,
      vslSuezTNet: this.values.vslSuezTNet,
      vslPanamaGT: this.values.vslPanamaGT,
      vslPanamaNT: this.values.vslPanamaNT,
    });
  }

  private createHullInfoForm() {
    this.hullInfoFrmGrp = this.formBuilder.group({
      vslFBArea: this.values.vslFBArea,
      vslVSArea: this.values.vslVSArea,
      vslLastDryDockDate: isValidDate(this.values.vslLastDryDockDate)
        ? this.formBuilder.control(moment(this.values.vslLastDryDockDate))
        : null,
      vslNextDryDockDate: this.values.vslNextDryDockDate
        ? this.formBuilder.control(moment(this.values.vslNextDryDockDate))
        : null,
      vslLastHullCleanDate: this.values.vslLastHullCleanDate
        ? this.formBuilder.control(moment(this.values.vslLastHullCleanDate))
        : null,
      vslLastPropPolishDate: this.values.vslLastPropPolishDate
        ? this.formBuilder.control(moment(this.values.vslLastPropPolishDate))
        : null,
      vslLastWaterInspDate: this.values.vslLastWaterInspDate
        ? this.formBuilder.control(moment(this.values.vslLastWaterInspDate))
        : null,
      verticalSurfacePrepDropDown1: this.values.verticalSurfacePrepDropDown1, // edit
      verticalSurfacePrepDropDown2: this.values.verticalSurfacePrepDropDown2,
      flatSurfacePrepDropDown1: this.values.flatSurfacePrepDropDown1, // edit
      flatSurfacePrepDropDown2: this.values.flatSurfacePrepDropDown2,
      vslAntiFouling: this.values.vslAntiFouling,
      vslPaintMaker: this.formBuilder.control(
        this.values.vslPaintMaker > 0 && this.values.vslAntiFouling ? this.values.vslPaintMaker : null
      ), // edit
      vslPaintType: this.formBuilder.control(
        this.values.vslPaintType > 0 && this.values.vslAntiFouling ? this.values.vslPaintType : null
      ), // edit
    });
  }

  private createTankCapForm() {
    this.tankCapFrmGrp = this.formBuilder.group({
      vslBallastWaterCap: this.formBuilder.control(
        this.values.vslBallastWaterCap ? this.values.vslBallastWaterCap : null,
        [Validators.min(0)]
      ),

      vslFreshWaterCap: this.formBuilder.control(this.values.vslFreshWaterCap ? this.values.vslFreshWaterCap : null, [
        Validators.min(0),
      ]),

      vslHFOCapacity: this.formBuilder.control(this.values.vslHFOCapacity ? this.values.vslHFOCapacity : null, [
        Validators.min(0),
      ]),

      vslMGOCapacity: this.formBuilder.control(this.values.vslMGOCapacity ? this.values.vslMGOCapacity : null, [
        Validators.min(0),
      ]),

      vslUltraLowSulphurTankCap: this.formBuilder.control(
        this.values.vslUltraLowSulphurTankCap ? this.values.vslUltraLowSulphurTankCap : null,
        [Validators.min(0)]
      ),

      vslHFOReceivingCap: this.formBuilder.control(
        this.values.vslHFOReceivingCap ? this.values.vslHFOReceivingCap : null,
        [Validators.min(0)]
      ),

      vslMGOReceivingCap: this.formBuilder.control(
        this.values.vslMGOReceivingCap ? this.values.vslMGOReceivingCap : null,
        [Validators.min(0)]
      ),

      vslULSFOReceivingCap: this.formBuilder.control(
        this.values.vslULSFOReceivingCap ? this.values.vslULSFOReceivingCap : null,
        [Validators.min(0)]
      ),

      vslMaxFillLimit: this.formBuilder.control(this.values.vslMaxFillLimit ? this.values.vslMaxFillLimit : null, [
        Validators.min(0),
      ]),
    });
  }

  private createFuelSpecForm() {
    this.fuelSpecFrmGrp = this.formBuilder.group({
      vslEngineFuel: this.formBuilder.control(
        this.values.vslEngineFuel > 0 ? this.values.vslEngineFuel : null,
        Validators.min(1)
      ),
      vslisUniFiel: this.values.vslisUniFiel,
      vslAuxFuel: this.formBuilder.control(
        this.values.vslAuxFuel > 0 && this.values.vslAuxFuel ? this.values.vslAuxFuel : null,
        Validators.min(1)
      ),
      vslfsyID: this.formBuilder.control(
        this.values.vslfsyID > 0 && this.values.vslfsyID ? this.values.vslfsyID : null,
        Validators.min(1)
      ), // int
      vslSuitforULSFuel: this.values.vslSuitforULSFuel,
      vslFullySeparateFSFlag: this.values.vslFullySeparateFSFlag,
      vslULSFuel: this.formBuilder.control(
        this.values.vslULSFuel > 0 && this.values.vslULSFuel ? this.values.vslULSFuel : null,
        Validators.min(1)
      ),
      vslDistillateFuel: this.formBuilder.control(
        this.values.vslDistillateFuel > 0 && this.values.vslDistillateFuel ? this.values.vslDistillateFuel : null,
        Validators.min(1)
      ),
      vslHalffo: this.formBuilder.control(
        this.values.vslHalffo > 0 && this.values.vslHalffo ? this.values.vslHalffo : null,
        Validators.min(1)
      ),
    });
  }
}
