// Machinery PArticulars component for View VMD and Update VMD
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewChecked,
  OnChanges,
  SimpleChange,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DictionaryService } from '../../modules/dictionary/services/dictionary.service';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';
import { PaintsService } from 'src/app/core/services/paints/paints.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'champs-machinery-particulars',
  providers: [DictionaryService],
  templateUrl: './machinery-particulars.component.html',
  styleUrls: ['./machinery-particulars.component.scss'],
})
export class MachineryParticularsComponent implements OnInit, AfterViewChecked, OnChanges {
  // private variables

  private mainEngineFormObject: any;
  private auxEngineFormObject: any;
  private thrusterFormObject: any;
  private mainEngineFormData: any;
  private auxEngineFormData: any;
  private thrusterFormData: any;
  public thrusterFieldError: boolean;
  public auxEngFieldError: boolean;
  public mainEngFieldError: boolean;
  public isVsMpUpdateAuth: boolean;
  public engineMaker$: Observable<any>;

  // public variables
  @Input() public values: any;

  @Output() public update: EventEmitter<any> = new EventEmitter();

  public mainEngineViewMode: boolean = true;
  public mainEngineFrmGrp: UntypedFormGroup;
  public mainEngines: any;

  public auxEngineViewMode: boolean = true;
  public auxEngineFrmGrp: UntypedFormGroup;

  public thrusterViewMode: boolean = true;
  public thrusterFrmGrp: UntypedFormGroup;

  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;

  constructor(
    // private ownersService: OwnersService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    private dictionaryService: DictionaryService,
    private auth: AuthService,
    private paintsService: PaintsService
  ) {
    this.engineMaker$ = this.paintsService.getEngineMaker();
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsMpUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.dictionaryService.getMainEngines().subscribe((mainEng) => {
      this.mainEngines = mainEng;
    });
    this.createMainEngineForm();
    this.auxEngineFrmGrp = this.formBuilder.group(this.createAuxEngine(this.values.auxEngine));
    this.createThrustrForm();
  }

  /**
   * on change life-cycle hook
   */
  public ngOnChanges(changes: { values: SimpleChange }) {
    if (changes.values) {
      this.values = changes.values.currentValue;
      this.auxEngineFrmGrp = this.formBuilder.group(this.createAuxEngine(this.values.auxEngine));
    }
  }

  /**
   * after view checked life-cycle hook
   */
  public ngAfterViewChecked() {
    // thi
    this.mainEngineFrmGrp.valueChanges.subscribe((values) => {
      this.mainEngineFormData = values;
    });

    this.auxEngineFrmGrp.valueChanges.subscribe((values) => {
      this.auxEngineFormData = Object.keys(values).reduce(
        (res, val) => {
          res[0][val] = values[val][0];
          res[1][val] = values[val][1];
          res[2][val] = values[val][2];
          res[3][val] = values[val][3];
          res[4][val] = values[val][4];
          res[5][val] = values[val][5];
          return res;
        },
        [{}, {}, {}, {}, {}, {}]
      );
    });

    this.thrusterFrmGrp.valueChanges.subscribe((values) => {
      this.thrusterFormData = values;
    });
  }

  public mainEngineCancel() {
    this.mainEngineViewMode = !this.mainEngineViewMode;
    this.createMainEngineForm();
  }
  public mainEngineSave() {
    if (this.mainEngineFrmGrp.invalid) {
      this.mainEngFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.mainEngineViewMode = true;
    if (this.mainEngineFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateMainEngine',
        value: this.mainEngineFormData,
      });
    }
  }

  public auxEngineCancel() {
    this.auxEngineViewMode = true;
    this.auxEngineFrmGrp = this.formBuilder.group(this.createAuxEngine(this.values.auxEngine));
  }
  public auxEngineSave() {
    if (this.auxEngineFrmGrp.invalid) {
      this.auxEngFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.auxEngineViewMode = true;
    if (this.auxEngineFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateAuxEngine',
        value: {
          auxEngine: this.auxEngineFormData,
        },
      });
    }
  }
  public thrusterCancel() {
    this.thrusterViewMode = true;
    this.createThrustrForm();
  }

  public thrusterSave() {
    if (
      this.thrusterFrmGrp.invalid &&
      ((this.thrusterFrmGrp.get('vslBowThruster').errors && this.thrusterFrmGrp.get('vslBowThrusterInstalled').value) ||
        (this.thrusterFrmGrp.get('vslSternThruster').errors &&
          this.thrusterFrmGrp.get('vslSternThrusterInstalled').value))
    ) {
      this.thrusterFieldError = true;
      scrollToError(this.matErrorRefs);
      return;
    }
    this.thrusterViewMode = true;
    if (!this.thrusterFrmGrp.get('vslBowThrusterInstalled').value) {
      this.thrusterFrmGrp.patchValue({
        vslBowThruster: '',
      });
    }
    if (!this.thrusterFrmGrp.get('vslSternThrusterInstalled').value) {
      this.thrusterFrmGrp.patchValue({
        vslSternThruster: '',
      });
    }
    if (this.thrusterFrmGrp.dirty) {
      this.update.emit({
        type: 'UpdateThruster',
        value: this.thrusterFormData,
      });
    }
  }
  public updateBowThrusterChecked(option, event) {
    if (!event.checked) {
      this.thrusterFrmGrp.get('vslBowThruster').setValue(null);
    }
  }
  public updateSternThrusterChecked(event) {
    if (!event.checked) {
      this.thrusterFrmGrp.get('vslSternThruster').setValue(null);
    }
  }
  private createThrustrForm() {
    this.thrusterFormObject = {
      vslBowThrusterInstalled: this.values.vslBowThrusterInstalled ? this.values.vslBowThrusterInstalled : false,
      vslBowThruster: this.formBuilder.control(this.values.vslBowThruster ? this.values.vslBowThruster : '', [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
      vslSternThrusterInstalled: this.values.vslSternThrusterInstalled ? this.values.vslSternThrusterInstalled : false,
      vslSternThruster: this.formBuilder.control(this.values.vslSternThruster ? this.values.vslSternThruster : '', [
        Validators.pattern(new RegExp('^[0-9]+$')),
      ]),
    };
    this.thrusterFrmGrp = this.formBuilder.group(this.thrusterFormObject);
  }

  private createAuxEngine(items) {
    return {
      vaePKID: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaePKID : ''),
        this.formBuilder.control(items[1] ? items[1].vaePKID : ''),
        this.formBuilder.control(items[2] ? items[2].vaePKID : ''),
        this.formBuilder.control(items[3] ? items[3].vaePKID : ''),
        this.formBuilder.control(items[4] ? items[4].vaePKID : ''),
        this.formBuilder.control(items[5] ? items[5].vaePKID : ''),
      ]),
      vaeAuxEngineMaker: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeAuxEngineMaker : ''),
        this.formBuilder.control(items[1] ? items[1].vaeAuxEngineMaker : ''),
        this.formBuilder.control(items[2] ? items[2].vaeAuxEngineMaker : ''),
        this.formBuilder.control(items[3] ? items[3].vaeAuxEngineMaker : ''),
        this.formBuilder.control(items[4] ? items[4].vaeAuxEngineMaker : ''),
        this.formBuilder.control(items[5] ? items[5].vaeAuxEngineMaker : ''),
      ]),
      vaeAuxEngineType: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeAuxEngineType : ''),
        this.formBuilder.control(items[1] ? items[1].vaeAuxEngineType : ''),
        this.formBuilder.control(items[2] ? items[2].vaeAuxEngineType : ''),
        this.formBuilder.control(items[3] ? items[3].vaeAuxEngineType : ''),
        this.formBuilder.control(items[4] ? items[4].vaeAuxEngineType : ''),
        this.formBuilder.control(items[5] ? items[5].vaeAuxEngineType : ''),
      ]),
      vaeAuxMaxGenPower: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeAuxMaxGenPower : ''),
        this.formBuilder.control(items[1] ? items[1].vaeAuxMaxGenPower : ''),
        this.formBuilder.control(items[2] ? items[2].vaeAuxMaxGenPower : ''),
        this.formBuilder.control(items[3] ? items[3].vaeAuxMaxGenPower : ''),
        this.formBuilder.control(items[4] ? items[4].vaeAuxMaxGenPower : ''),
        this.formBuilder.control(items[5] ? items[5].vaeAuxMaxGenPower : ''),
      ]),
      vaeSerialNo: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeSerialNo : ''),
        this.formBuilder.control(items[1] ? items[1].vaeSerialNo : ''),
        this.formBuilder.control(items[2] ? items[2].vaeSerialNo : ''),
        this.formBuilder.control(items[3] ? items[3].vaeSerialNo : ''),
        this.formBuilder.control(items[4] ? items[4].vaeSerialNo : ''),
        this.formBuilder.control(items[5] ? items[5].vaeSerialNo : ''),
      ]),
      vaeTurboChargerNo: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeTurboChargerNo : ''),
        this.formBuilder.control(items[1] ? items[1].vaeTurboChargerNo : ''),
        this.formBuilder.control(items[2] ? items[2].vaeTurboChargerNo : ''),
        this.formBuilder.control(items[3] ? items[3].vaeTurboChargerNo : ''),
        this.formBuilder.control(items[4] ? items[4].vaeTurboChargerNo : ''),
        this.formBuilder.control(items[5] ? items[5].vaeTurboChargerNo : ''),
      ]),
      vaeRPM: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeRPM : ''),
        this.formBuilder.control(items[1] ? items[1].vaeRPM : ''),
        this.formBuilder.control(items[2] ? items[2].vaeRPM : ''),
        this.formBuilder.control(items[3] ? items[3].vaeRPM : ''),
        this.formBuilder.control(items[4] ? items[4].vaeRPM : ''),
        this.formBuilder.control(items[5] ? items[5].vaeRPM : ''),
      ]),
      vaeMinOperLoad: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeMinOperLoad : ''),
        this.formBuilder.control(items[1] ? items[1].vaeMinOperLoad : ''),
        this.formBuilder.control(items[2] ? items[2].vaeMinOperLoad : ''),
        this.formBuilder.control(items[3] ? items[3].vaeMinOperLoad : ''),
        this.formBuilder.control(items[4] ? items[4].vaeMinOperLoad : ''),
        this.formBuilder.control(items[5] ? items[5].vaeMinOperLoad : ''),
      ]),
      vaeVoltage: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeVoltage : ''),
        this.formBuilder.control(items[1] ? items[1].vaeVoltage : ''),
        this.formBuilder.control(items[2] ? items[2].vaeVoltage : ''),
        this.formBuilder.control(items[3] ? items[3].vaeVoltage : ''),
        this.formBuilder.control(items[4] ? items[4].vaeVoltage : ''),
        this.formBuilder.control(items[5] ? items[5].vaeVoltage : ''),
      ]),
      vaeFrequency: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeFrequency : ''),
        this.formBuilder.control(items[1] ? items[1].vaeFrequency : ''),
        this.formBuilder.control(items[2] ? items[2].vaeFrequency : ''),
        this.formBuilder.control(items[3] ? items[3].vaeFrequency : ''),
        this.formBuilder.control(items[4] ? items[4].vaeFrequency : ''),
        this.formBuilder.control(items[5] ? items[5].vaeFrequency : ''),
      ]),
      vaePowerFactor: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaePowerFactor : ''),
        this.formBuilder.control(items[1] ? items[1].vaePowerFactor : ''),
        this.formBuilder.control(items[2] ? items[2].vaePowerFactor : ''),
        this.formBuilder.control(items[3] ? items[3].vaePowerFactor : ''),
        this.formBuilder.control(items[4] ? items[4].vaePowerFactor : ''),
        this.formBuilder.control(items[5] ? items[5].vaePowerFactor : ''),
      ]),
      vaeKWHCounterInstalled: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeKWHCounterInstalled : false),
        this.formBuilder.control(items[1] ? items[1].vaeKWHCounterInstalled : false),
        this.formBuilder.control(items[2] ? items[2].vaeKWHCounterInstalled : false),
        this.formBuilder.control(items[3] ? items[3].vaeKWHCounterInstalled : false),
        this.formBuilder.control(items[4] ? items[4].vaeKWHCounterInstalled : false),
        this.formBuilder.control(items[5] ? items[5].vaeKWHCounterInstalled : false),
      ]),
      vaeEngineNo: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeEngineNo : 1),
        this.formBuilder.control(items[1] ? items[1].vaeEngineNo : 2),
        this.formBuilder.control(items[2] ? items[2].vaeEngineNo : 3),
        this.formBuilder.control(items[3] ? items[3].vaeEngineNo : 4),
        this.formBuilder.control(items[4] ? items[4].vaeEngineNo : 5),
        this.formBuilder.control(items[5] ? items[5].vaeEngineNo : 6),
      ]),
      vaeOrderNo: this.formBuilder.array([
        this.formBuilder.control(items[0] ? items[0].vaeOrderNo : 0),
        this.formBuilder.control(items[1] ? items[1].vaeOrderNo : 0),
        this.formBuilder.control(items[2] ? items[2].vaeOrderNo : 0),
        this.formBuilder.control(items[3] ? items[3].vaeOrderNo : 0),
        this.formBuilder.control(items[4] ? items[4].vaeOrderNo : 0),
        this.formBuilder.control(items[5] ? items[5].vaeOrderNo : 0),
      ]),
    };
  }

  private createAuxEngineFrmArr(items, type) {
    const grpArr: Array<UntypedFormControl> = [];
    items.forEach((item) => {
      const frmItem = this.formBuilder.control(item[type]);
      grpArr.push(frmItem);
    });
    return grpArr;
  }

  private createMainEngineForm() {
    this.mainEngineFormObject = {
      vslMainEngineMaker: this.values.vslMainEngineMaker,
      vslMainEngineMakerID: this.values.vslMainEngineMakerID,
      vslMainEngineType: this.values.vslMainEngineType,
      vslMCR: this.values.vslMCR,
      vslMaxSpeed: this.values.vslMaxSpeed,
      vslMinSpeed: this.values.vslMinSpeed,
    };
    this.mainEngineFrmGrp = this.formBuilder.group(this.mainEngineFormObject);
  }
}
