// Angular
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../core/services/authentication/auth/auth.service';
import { UntypedFormControl } from '@angular/forms';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'champs-service-view-filters',
  templateUrl: './service-view-filters.component.html',
  styleUrls: ['./service-view-filters.component.scss']
})
export class ServiceViewFiltersComponent implements OnInit {
  @Output() public toggleSelectedFilters: EventEmitter<any> = new EventEmitter();

  @Output() public toggleFilter: EventEmitter<any> = new EventEmitter();

  public showFilters: boolean = true;
  public isFiltersLoaded: boolean = false;
  public showStartYearError:boolean = false;
  public showStartWeekError:boolean = false;
  public showEndYearError:boolean = false;
  public showEndWeekError:boolean = false;
  public filterData: any;
  public filterData$: any;
  public selectedFilter: any;
  public selectedFilter1: any;
  public selectedFilter2: any;
  public selectedFilter3: any;
  public selectedFilter4: any;
  public selectedFilter5: any;
  public selectedFilter6: any;
  public selectedFilter7: any;
  public selectedFilter8: any;
  public startdate:any;
  public enddate:any;
  public userId: any;
  public selectedStartDate:any;
  public selectedEndDate:any;
  public selectedStartWeek: any;
  public selectedStartYear: any;
  public selectedEndWeek: any;
  public selectedEndYear: any;
  public selectedTradeFilters:any;
  public selectedRouteFilters:any;
  public selectedServiceFilters:any;
  public selectedAMPFilters:any;
  public selectedCranesFilters:any;
  public selectedSegmentFilters:any;
  public selectedPanamaFilters:any;
   public selectedPortTypeFilters:any;
   public serviceViewFrmGrp: UntypedFormGroup;

  public filtersCtrls = {
    tradeCtrl: new UntypedFormControl(),
    routeCtrl: new UntypedFormControl(),
    serviceCtrl: new UntypedFormControl(),
    ampCtrl:new UntypedFormControl(),
    cranesCtrl:new UntypedFormControl(),
    segmentCtrl: new UntypedFormControl(),
    panamaCtrl: new UntypedFormControl(),
    portTypeCtrl:new UntypedFormControl(),
  }
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private vslService: VesselsService,
  ) { }

  public ngOnInit(): void {
    //updated to return UID for CDA Accounts
    this.userId = this.auth.userProfile.UID ? this.auth.userProfile.UID : 'CDA001';
    this.filterData$ = this.vslService.getFiltersWeeklyCapacityView(this.userId).subscribe(

      (res) => {
        this.filterData = res;
        this.isFiltersLoaded = true;
        // Preset filters
        this.selectedStartDate = this.filterData.startDate;
        this.selectedEndDate = this.filterData.endDate;
        this.selectedStartWeek = this.filterData.startWeekDays;
        this.selectedStartYear = this.filterData.startWeekYear;

        this.selectedEndWeek = this.filterData.endWeekDays;
        this.selectedEndYear = this.filterData.endWeekYear;

        this.selectedFilter = this.setSelectedFilters(this.filterData?.trade);

        this.selectedFilter1 = this.setSelectedFilters(this.filterData?.route);

        this.selectedFilter2 = this.setSelectedFilters(this.filterData?.service);

        this.selectedFilter3 = this.setSelectedFilters(this.filterData?.amp);

        this.selectedFilter4 = this.setSelectedFilters(this.filterData?.cranes);

        this.selectedFilter5 = this.setSelectedFilters(this.filterData?.segment);

        this.selectedFilter6 = this.setSelectedFilters(this.filterData?.panama);

        this.selectedFilter8 = this.setSelectPortType(this.filterData?.portType);

      }
    );
  }

  public showFilter() {
    this.toggleFilter.emit({show: true});
  }

  public applyorSaveFilters(type,e) {
    const obj = {
      "trade":this.selectedFilter? this.formFilterObj(type, this.selectedFilter):[],
      "route": this.selectedFilter1 ? this.formFilterObj(type , this.selectedFilter1) : [],
      "service": this.selectedFilter2? this.formFilterObj(type,this.selectedFilter2):[],
      "amp": this.selectedFilter3? this.formFilterObj(type,this.selectedFilter3):[],
      "cranes":this.selectedFilter4? this.formFilterObj(type,this.selectedFilter4):[],
      "segment": this.selectedFilter5? this.formFilterObj(type,this.selectedFilter5):[],
      "panama":this.selectedFilter6? this.formFilterObj(type,this.selectedFilter6):[],
      "scrubber": this.selectedFilter7? this.formFilterObj(type,this.selectedFilter7):[],
      "portType":this.selectedFilter8?
      [
        {
          "key": 0,
          "value": this.getPortVal(this.selectedFilter8),
          "kvIsChecked":  type == "apply"? false : true
        }
      ]
      : [
        {
          "key": 0,
          "value": "Phase in Port",
          "kvIsChecked": true
        }
      ],
      "userId": this.userId,
      "startdate": this.selectedStartDate ? this.selectedStartDate : null,
      "endDate": this.selectedEndDate ? this.selectedEndDate : null,
      "startWeekDays":this.selectedStartWeek ? Number(this.selectedStartWeek) : null,
      "startWeekYear":this.selectedStartYear ? Number(this.selectedStartYear) : null,
      "endWeekDays":this.selectedEndWeek ? Number(this.selectedEndWeek) : null,
      "endWeekYear":this.selectedEndYear ? Number(this.selectedEndYear) : null,
      "isSaveButtonClicked": type=="apply" ? false : true,
      "distColumns": []
    }
    this.toggleSelectedFilters.emit(obj);
  }

  public getPortVal(portVal) {

    return portVal.toString();

  }

  public formFilterObj(type,arr) {

    let obj = {
      "key": 0,
      "value": "string",
      "kvIsChecked": type == "apply"?false:true
    }
         obj = arr.map(x => ({
          "key": 0,
          "value":x,
          "kvIsChecked": type == "apply"?false:true
        }));
    return obj;
  }


  public setSelectedFilters(val) {
    const arr1: any = [];

    val?.forEach((n: any) => {
      if (n.kvIsChecked === true) {
        arr1.push(n.value);
      }
    });
    return arr1;

  }

  public setSelectPortType(val) {
    const arr1: any = [];

    val?.forEach((n: any) => {
      if (n.kvIsChecked === true) {
        arr1.push(n.value);
      }
    });
    // Setting Phase in Port as default selected port
    if(arr1.length==0){
      arr1.push("Phase in Port")
    }
    return arr1[0];

  }

  public clearStartDate(type) {
    if(type==="Year") {
      this.selectedStartYear = null;
      this.showStartYearError = false;
    } else {
      this.selectedStartWeek = null;
      this.showStartWeekError = false;
    }
  }

  public clearEndDate(type) {
    if(type==="Year") {
      this.selectedEndYear = null;
      this.showEndYearError = false;
    } else {
      this.selectedEndWeek = null;
      this.showEndWeekError = false;
    }
  }

  public cancel() {
    this.selectedFilter = [];
    this.selectedFilter1 = [];
    this.selectedFilter2 = [];
    this.selectedFilter3 = [];
    this.selectedFilter4 = [];
    this.selectedFilter5 = [];
    this.selectedFilter6 = [];
    this.selectedFilter7  = [];
    this.selectedFilter8  = [];
  }

  public checkEnteredFilterVal(type,subtype,val){
    if (val) {
    if (type==="year") {
      if (subtype=="start") {
        if(val.toString().length !== 4) {
          this.showStartYearError = true;
        } else {
          this.showStartYearError = false;
        }
      } else {
        if(val.toString().length !== 4) {
          this.showEndYearError = true;
        } else {
          this.showEndYearError = false;
        }
      }
    } else {
      if(subtype=="start"){
      if(val.toString().length !== 2 && val.toString().length !== 1) {
        this.showStartWeekError = true;
      } else {
        this.showStartWeekError = false;
      }
    } else {
      if(val.toString().length !== 2 && val.toString().length !== 1) {
        this.showEndWeekError = true;
      } else {
        this.showEndWeekError = false;
      }
    }
    }
  } else {
    this.showStartYearError = false;
    this.showEndYearError = false;
    this.showStartWeekError = false;
    this.showEndWeekError = false;
  }
  }
}
