// Component for splitting number into 2 spans - before and after decimal
import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'champs-number-splitter',
  templateUrl: './number-splitter.component.html',
  styleUrls: ['./number-splitter.component.scss']
})
export class NumberSplitterComponent implements OnChanges {

  @Input() public decimalNumber: number;
  public decimalNumber1: string;
  public splittedNumber: string[] = [];
  constructor() { }

  public ngOnChanges() {
    this.splittedNumber = this.decimalNumber != null ? this.decimalNumber.toFixed(2).toString().split('.') : null;
  }

}
