// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rxjs
import { startWith } from 'rxjs/operators';

// Interfaces
import { FeatureToggle } from '../../../pages/feature-toggle/featureToggle.interface';

// Others
import { environment } from '../../../../environments/environment';

const CACHE_KEY = 'cachedFeatureToggles';
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  public featureToggles;
  constructor(private http: HttpClient) { }

  /**
   * get feature toggle list
   */
  public getFeatureToggleList() {
    this.featureToggles = this.http.get(environment.baseUrl + '/api/FeatureToggles/FeatureToggles');
    this.featureToggles.subscribe((data: FeatureToggle[]) => {
      if (data) {
        localStorage[CACHE_KEY] = JSON.stringify(data);
      }
    });
    this.featureToggles = this.featureToggles.pipe(
      startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.featureToggles;
  }

  /**
   * update feature toggle
   * @param data contains toggle value
   */
  public updateFeatureToggle(data) {
    return this.http.post(environment.baseUrl + '/api/FeatureToggles/FeatureToggles/CreateOrUpdate', data);
  }

  public isFtEnabled(feature: string): boolean {
    let isFtEnabled = false;
    const featureToggles = JSON.parse(localStorage['cachedFeatureToggles'] || '[]');
    featureToggles.forEach((item) => {
      if (feature === item.ftName) {
        isFtEnabled = item.ftValue;
      }
    });
    return isFtEnabled;
  }
}
