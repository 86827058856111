/** */
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, forkJoin } from 'rxjs';

import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';

@Component({
  selector: 'champs-vessel-detail-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
})
export class VesselDetailEvaluationComponent implements AfterViewChecked, OnInit {
  public bmtCrane: any;
  public bmtVessel: any;
  public isNotFullView$: Observable<boolean>;
  public frData?: any = {};
  public vesselEvaluationDetails: any;
  public showMoreFleet: boolean = false;
  public firewallArr: any = [];
  public showMoreFirewallVisible: boolean = false;
  public showMoreFirewall: boolean = false;
  public firewallCount = 0;
  public imo: boolean = false;
  public imoNumber: number;
  public frpData: any = {};

  public defectColor: any;
  public daysColor: any;

  /** for color configuration for defects */
  public red = '#d62d23';
  public orange = '#ff9b1e';
  public green = '#2dba5e';
  public transparentColor = '#dadada';
  public colorConfigListForDefects: any[] = [];
  public keyValues: any[] = [];
  public valuesInStringForm: any[] = [];
  public values: any[] = [];
  public namesValuesInStringForm: any[] = [];
  public namesValues: any[] = [];
  public names: any[] = [];
  public lowValue: any;
  public mediumValue: any;
  public highValue: any;
  public lowValueColor: any;
  public mediumValueColor: any;
  public highValueColor: any;

  /** color config for Days */
  public colorConfigListForDays: any[] = [];
  public keyValuesDays: any[] = [];
  public valuesInStringFormDays: any[] = [];
  public valuesDays: any[] = [];
  public namesValuesInStringFormDays: any[] = [];
  public namesValuesDays: any[] = [];
  public namesDays: any[] = [];
  public lowValueDays: any;
  public mediumValueDays: any;
  public highValueDays: any;
  public lowValueColorDays: any;
  public mediumValueColorDays: any;
  public highValueColorDays: any;
  public offhire: any[];

  @ViewChildren('firewallItem', { read: ElementRef }) public firewallItems: QueryList<ElementRef>;

  constructor(
    private vesselsService: VesselsService,
    route: ActivatedRoute,
    private colorConfigService: ColorConfigService
  ) {
    /** getting color configurations */
    const service1 = this.colorConfigService.getColorConfigByGroupName('Evaluation - Average Defects');
    const service2 = this.colorConfigService.getColorConfigByGroupName('Evaluation - Detained Days');
    forkJoin(service1, service2).subscribe((data: any[]) => {
      this.colorConfigListForDefects = data[0];
      this.colorConfigListForDays = data[1];
      this.keyValues = this.colorConfigListForDefects[0].keyValues;
      this.keyValuesDays = this.colorConfigListForDays[0].keyValues;

      route.parent.data.subscribe(({ details }) => {
        this.vesselsService.getFRPVesselScore(details.vslPKID).subscribe((score) => {
          if (score) {
            this.frpData = score;
          }
        });

        if (details.vslIMONumber) {
          this.imo = true;
          this.imoNumber = details.vslIMONumber;
          this.vesselsService
            .getVesselEvaluationData(details.vslIMONumber)
            .subscribe((vesselFleetRiskData: Response) => {
              this.frData = vesselFleetRiskData;
              this.getColorConfigForDefects();
              this.getColorConfigForDays();
            });
          this.vesselsService.getVesselBMTInspectionCrane(details.vslIMONumber).subscribe((bmtInspectionCrane: any) => {
            this.bmtCrane = bmtInspectionCrane;
          });

          this.vesselsService
            .getVesselBMTInspectionVessel(details.vslIMONumber)
            .subscribe((bmtInspectionVessel: any) => {
              this.bmtVessel = bmtInspectionVessel;
            });

          this.vesselsService.getVesselOffhireScore(details.vslPKID).subscribe((offhire: any) => {
            this.offhire = offhire;
          });
        }
      });
    });
  }

  public ngOnInit() {}

  public ngAfterViewChecked() {
    this.firewallCount = this.firewallItems.length;
    if (this.firewallItems.length > 6) {
      this.showMoreFirewallVisible = true;
      this.toggleMoreFirewall(false);
    } else {
      this.showMoreFirewallVisible = false;
    }
  }

  public getColorConfigForDefects() {
    this.namesValuesInStringForm = this.keyValues.map((i) => {
      if (i.kvValue === 'default') {
        return {
          kvName: i.kvName,
          kvValue: '0',
        };
      } else {
        return {
          kvName: i.kvName,
          kvValue: i.kvValue,
        };
      }
    });

    this.namesValues = this.namesValuesInStringForm.map((i) => {
      return {
        kvName: i.kvName,
        kvValue: +i.kvValue,
      };
    });

    this.namesValues.sort((a, b) => a.kvValue - b.kvValue);
    this.namesValues.forEach((i) => {
      this.names.push(i.kvName);
      this.values.push(i.kvValue);
    });

    this.highValue = this.values[2];
    this.mediumValue = this.values[1];
    this.lowValue = this.values[0];

    this.highValueColor = this.names[2].substring(this.names[2].indexOf('.') + 1).toLowerCase();
    this.mediumValueColor = this.names[1].substring(this.names[1].indexOf('.') + 1).toLowerCase();
    this.lowValueColor = this.names[0].substring(this.names[0].indexOf('.') + 1).toLowerCase();

    if (!this.frData?.frAvgDefect) {
      this.defectColor = this[this.lowValueColor];
    }
    if (this.frData?.frAvgDefect >= this.lowValue && this.frData?.frAvgDefect <= this.mediumValue) {
      this.defectColor = this[this.lowValueColor];
    } else if (this.frData?.frAvgDefect > this.mediumValue && this.frData?.frAvgDefect <= this.highValue) {
      this.defectColor = this[this.mediumValueColor];
    } else if (this.frData?.frAvgDefect > this.highValue) {
      this.defectColor = this[this.highValueColor];
    } else {
      this.defectColor = this.transparentColor; // transparent
    }
  }

  public getColorConfigForDays() {
    this.namesValuesInStringFormDays = this.keyValuesDays.map((i) => {
      if (i.kvValue === 'default') {
        return {
          kvName: i.kvName,
          kvValue: '0',
        };
      } else {
        return {
          kvName: i.kvName,
          kvValue: i.kvValue,
        };
      }
    });

    this.namesValuesDays = this.namesValuesInStringFormDays.map((i) => {
      return {
        kvName: i.kvName,
        kvValue: +i.kvValue,
      };
    });

    this.namesValuesDays.sort((a, b) => a.kvValue - b.kvValue);
    this.namesValuesDays.forEach((i) => {
      this.namesDays.push(i.kvName);
      this.valuesDays.push(i.kvValue);
    });

    this.highValueDays = this.valuesDays[2];
    this.mediumValueDays = this.valuesDays[1];
    this.lowValueDays = this.valuesDays[0];

    this.highValueColorDays = this.namesDays[2].substring(this.namesDays[2].indexOf('.') + 1).toLowerCase();
    this.mediumValueColorDays = this.namesDays[1].substring(this.namesDays[1].indexOf('.') + 1).toLowerCase();
    this.lowValueColorDays = this.namesDays[0].substring(this.namesDays[0].indexOf('.') + 1).toLowerCase();

    if (!this.frData?.frDetainedDays.value) {
      this.daysColor = this[this.lowValueColorDays];
    }

    if (
      this.frData?.frDetainedDays.value >= this.lowValueDays &&
      this.frData?.frDetainedDays.value <= this.mediumValueDays
    ) {
      this.daysColor = this[this.lowValueColorDays];
    } else if (
      this.frData?.frDetainedDays.value > this.mediumValueDays &&
      this.frData?.frDetainedDays.value <= this.highValueDays
    ) {
      this.daysColor = this[this.mediumValueColorDays];
    } else if (this.frData?.frDetainedDays.value > this.highValueDays) {
      this.daysColor = this[this.highValueColorDays];
    } else {
      this.daysColor = this.transparentColor; // transparent
    }
  }

  public toggleMoreFirewall = (click: boolean) => {
    if (click) {
      this.showMoreFirewall = !this.showMoreFirewall;
    }

    this.firewallItems.map((item, key) => {
      if (key > 5 && !this.showMoreFirewall) {
        item.nativeElement.classList.add('hide');
      } else {
        item.nativeElement.classList.remove('hide');
      }
      return true;
    });
  }

  public openURL(url: string) {
    window.open(url);
  }
  public showMoreFleetDataSwitch() {
    this.showMoreFleet = !this.showMoreFleet;
  }
  public navigate(row) {
    window.open('/fixtures/' + row.fixPKID + '/offhire/');
  }
  public navigateToFleetRisk() {
    window.open('https://fleetrisk.maersk.com/Ship/Index/{this.imoNumber}');
  }
}
