/** */
import { Component, OnInit } from '@angular/core';
import { AppInsightBaseComponent } from 'src/app/components/app-insights/app-insight-base/app-insight-base.component';
import { AuthService } from 'src/app/core/services/authentication/auth/auth.service';

@Component({
  selector: 'dashboard-reports',
  templateUrl: './dashboard-reports.html',
  styleUrls: ['./dashboard-reports.scss']
})
export class DashboardReports extends AppInsightBaseComponent implements OnInit {
  constructor(private auth: AuthService) {
    super();
  }

  public ngOnInit() {
  }

}
