//

import { Directive, forwardRef, OnDestroy, Input, Optional, ElementRef, EventEmitter, Output } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

import { DOWN_ARROW } from '@angular/cdk/keycodes';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { CustomDatetimepickerComponent } from './champs-custom-datetimepicker/custom-datetimepicker.component';
import { isValidDate } from '../../utils/valid-date';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

class MatDatetimepickerInputEvent<D> {
  /** The new value for the target datepicker input. */
  public value: D | null;

  constructor(
    /** Reference to the datepicker input component that emitted the event. */
    public target: CustomDatetimepickerInput<D>,
    /** Reference to the native input element associated with the datepicker input. */
    public targetElement: HTMLElement
  ) {
    this.value = this.target.value;
  }
}

export const CHAMPS_DATETIMEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomDatetimepickerInput),
  multi: true,
};

export const CHAMPS_DATETIMEPICKER_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CustomDatetimepickerInput),
  multi: true,
};

@Directive({
  selector: 'input[champsDatetimepicker]',
  providers: [
    CHAMPS_DATETIMEPICKER_VALUE_ACCESSOR,
    CHAMPS_DATETIMEPICKER_VALIDATORS,
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CustomDatetimepickerInput },
  ],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(change)': '_onChange()',
    '(blur)': '_onBlur()',
    '(keydown)': '_onKeydown($event)',
  },
  exportAs: 'champsDatetimepickerInput',
})
// tslint:disable-next-line:directive-class-suffix
export class CustomDatetimepickerInput<D> implements ControlValueAccessor, OnDestroy, Validator {
  // tslint:disable:member-ordering

  /** The minimum valid date. */
  @Input()
  get min(): D | null {
    return this._min;
  }
  set min(value: D | null) {
    this._min = this._getValidDateOrNull(this._deserializeDateTime(value));
    this._validatorOnChange();
  }
  private _min: D | null;

  /** The maximum valid date. */
  @Input()
  get max(): D | null {
    return this._max;
  }
  set max(value: D | null) {
    this._max = this._getValidDateOrNull(this._deserializeDateTime(value));
    this._validatorOnChange();
  }
  private _max: D | null;

  /** The form control validator for whether the input parses. */
  private _parseValidator: ValidatorFn = (): ValidationErrors | null => {
    return this._lastValueValid ? null : { matDatepickerParse: { text: this._elementRef.nativeElement.value } };
  }

  /** The form control validator for the min date. */
  private _minValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const controlValue = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value));
    return !this.min || !controlValue || this._dateAdapter.compareDate(this.min, controlValue) <= 0
      ? null
      : { matDatepickerMin: { min: this.min, actual: controlValue } };
  }

  /** The form control validator for the max date. */
  private _maxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const controlValue = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value));
    return !this.max || !controlValue || this._dateAdapter.compareDate(this.max, controlValue) >= 0
      ? null
      : { matDatepickerMax: { max: this.max, actual: controlValue } };
  }

  private _validatorOnChange = () => {};

  /** The combined form control validator for this input. */
  private _validator: ValidatorFn | null = Validators.compose([
    this._parseValidator,
    this._minValidator,
    this._maxValidator,
  ]);

  /** The value of the input. */
  @Input()
  get value(): D | null {
    return this._value;
  }
  set value(value: D | null) {
    value = this._deserializeDateTime(value);
    this._lastValueValid = !value || this._dateAdapter.isValid(value);
    value = this._getValidDateOrNull(value);
    const oldDate = this.value;
    this._value = value;
    this._formatValue(value);
    if (!this._dateAdapter.sameDate(oldDate, value)) {
      this._valueChange.emit(value);
    }
  }
  private _value: D | null;

  /** Whether the last value set on the input was valid. */
  private _lastValueValid = false;

  /** Emits when the value changes (either due to user input or programmatic change). */
  public _valueChange = new EventEmitter<D | null>();

  @Input()
  set champsDatetimepicker(value: CustomDatetimepickerComponent<D>) {
    if (!value) {
      return;
    }
    this._datetimepicker = value;
    this._datetimepicker._registerInput(this);

    this._datepickerSubscription.unsubscribe();

    this._datepickerSubscription = this._datetimepicker._selectedChanged.subscribe((selected: D) => {
      this.value = selected;
      this._cvaOnChange(selected);
      this._onTouched();
      this.dateInput.emit(new MatDatetimepickerInputEvent(this, this._elementRef.nativeElement));
      this.dateChange.emit(new MatDatetimepickerInputEvent(this, this._elementRef.nativeElement));
    });
  }
  public _datetimepicker: CustomDatetimepickerComponent<D>;

  /** Emits when the disabled state has changed */
  public _disabledChange = new EventEmitter<boolean>();

  /** Whether the datepicker-input is disabled. */
  @Input()
  get disabled(): boolean {
    return !!this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    const element = this._elementRef.nativeElement;

    if (this._disabled !== newValue) {
      this._disabled = newValue;
      this._disabledChange.emit(newValue);
    }

    // We need to null check the `blur` method, because it's undefined during SSR.
    if (newValue && element.blur) {
      // Normally, native input elements automatically blur if they turn disabled. This behavior
      // is problematic, because it would mean that it triggers another change detection cycle,
      // which then causes a changed after checked error if the input element was focused before.
      element.blur();
    }
  }
  // tslint:disable-next-line:member-ordering
  private _disabled: boolean;

  private _datepickerSubscription = Subscription.EMPTY;

  /** Emits when a `change` event is fired on this `<input>`. */
  @Output() public readonly dateChange: EventEmitter<MatDatetimepickerInputEvent<D>> = new EventEmitter<
    MatDatetimepickerInputEvent<D>
  >();

  /** Emits when an `input` event is fired on this `<input>`. */
  @Output() public readonly dateInput: EventEmitter<MatDatetimepickerInputEvent<D>> = new EventEmitter<
    MatDatetimepickerInputEvent<D>
  >();

  constructor(
    @Optional() public _dateAdapter: DateAdapter<D>,
    private _elementRef: ElementRef<HTMLInputElement>,
    @Optional() private _formField: MatFormField
  ) {}

  public ngOnDestroy() {
    this._valueChange.complete();
  }

  // Implemented as part of ControlValueAccessor.
  public writeValue(value: D): void {
    this.value = value;
  }

  /** @docs-private */
  public registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  public _onTouched = () => {};

  public _onKeydown(event: KeyboardEvent) {
    if (this._datetimepicker && event.altKey && event.keyCode === DOWN_ARROW) {
      this._datetimepicker.open();
      event.preventDefault();
    }
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnChange(fn: (value: any) => void): void {
    this._cvaOnChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  /** Handles blur events on the input. */
  public _onBlur() {
    // Reformat the input only if we have a valid value.
    if (this.value) {
      this._formatValue(this.value);
    }

    this._onTouched();
  }

  public _onChange() {
    this.dateChange.emit(new MatDatetimepickerInputEvent(this, this._elementRef.nativeElement));
  }

  /**
   * Gets the element that the datepicker popup should be connected to.
   * @return The element to connect the popup to.
   */
  public getConnectedOverlayOrigin(): ElementRef {
    return this._formField ? this._formField.getConnectedOverlayOrigin() : this._elementRef;
  }

  /** @docs-private */
  public validate(c: AbstractControl): ValidationErrors | null {
    return this._validator ? this._validator(c) : null;
  }

  /** Formats a value and sets it on the input element. */
  private _formatValue(value: D | null) {
    this._elementRef.nativeElement.value = value ? this._formatDateTime(value) : '';
  }

  private _cvaOnChange: (value: any) => void = () => {};

  /**
   * @param obj The object to check.
   * @returns The given object if it is both a date instance and valid, otherwise null.
   */
  private _getValidDateOrNull(obj: any): D | null {
    return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null;
  }

  private _formatDateTime(value) {
    return isValidDate(value) ? (new DatePipe('en-US').transform(new Date(value), 'dd-MMM-yy HH:mm') as any) : null;
  }

  /** @docsNotRequired */
  private _deserializeDateTime(value: any): D | null {
    return isValidDate(value) ? (new DatePipe('en-US').transform(new Date(value), 'dd-MMM-yy HH:mm') as any) : null;
  }
}
