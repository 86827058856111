//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { CanDeactivateGuard } from './services/can-deactivate-guard/can-deactivate.guard';
import { CanDeactivateDialogComponent } from './components/can-deactivate-dialog/can-deactivate-dialog.component';
import { CanDeactivateDialogService } from './services/can-deactivate-dialog/can-deactivate-dialog.service';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatDialogModule],
    declarations: [CanDeactivateDialogComponent],
    providers: [CanDeactivateGuard, CanDeactivateDialogService]
})
export class CanDeactivateModule {}
