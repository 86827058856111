// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep .service-detail-icon {
  display: inline-flex;
  margin-left: 20px;
  cursor: pointer;
}
:host::ng-deep .service-specific-info {
  display: inline-flex;
  margin-left: 20px;
  cursor: pointer;
  color: #3486b5;
}
:host::ng-deep .filter-section .ng-dropdown-header {
  padding: 3px !important;
}
:host::ng-deep .filter-section .service-detail-icon {
  display: inline-flex;
  margin-right: 0;
  float: right;
}
:host::ng-deep .filters .ng-select.filter {
  width: 200px;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  zoom: 1;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container {
  min-height: 24px;
  background-color: #ffffff;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container::after {
  content: none !important;
  display: none;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .filters .ng-select.filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .filters .ng-select.filter .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
}
:host::ng-deep .filters .ng-select.filter .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}
:host::ng-deep .filters .mat-mdc-form-field {
  display: inline-block;
  position: relative;
  font-size: 12px;
  text-align: left;
  width: 86%;
  padding-top: 10px;
}
:host::ng-deep .filters .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
  margin-right: 5px;
  margin-bottom: 5px;
}
:host::ng-deep .filters .mat-mdc-form-field-flex {
  border: 1px solid #ededed;
  margin: 0 -50px 0 0;
  padding: 0 5px;
  background: #ffffff;
  height: 26px !important;
}
:host::ng-deep .filters .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .filters .mat-mdc-form-field-infix {
  border: 0;
}
:host::ng-deep .filters .mat-mdc-form-field-hide-placeholder {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
}
:host::ng-deep .filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-panel {
  width: 156px;
}
:host::ng-deep .filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-content .teu-inputs {
  width: 50px;
  margin: 10px;
}
:host::ng-deep .filters .mat-mdc-form-field-hide-placeholder .mat-mdc-select-content img {
  float: right;
  margin-right: 25px;
}
:host::ng-deep .filters .mat-mdc-form-field-hide-placeholder .mat-select-placeholder {
  color: #282828 !important;
}
:host::ng-deep .filters .mat-mdc-form-field-suffix img {
  margin-left: -17px;
  width: 16px;
  height: 16px;
  margin-bottom: -3.5px;
  background: #ffffff;
}
:host::ng-deep .ngx-datatable .datatable-header {
  background-color: #64b2d4 !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: -6px;
}
:host::ng-deep .ngx-datatable .datatable-header .datatable-header-cell {
  padding-left: 4px;
  border-right: 1px solid white !important;
}
:host::ng-deep .ngx-datatable .datatable-header .datatable-header-cell-template-wrap {
  height: 200px !important;
}
:host::ng-deep .ngx-datatable .datatable-header .column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:host::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager .active {
  background-color: #64b2d4;
}
:host::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager .active a {
  color: white;
}
:host::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0 5px;
}
:host::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager li a,
:host::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager li i {
  font-size: 15px;
}
:host::ng-deep .ngx-datatable .datatable-footer .page-count {
  display: none;
}
:host::ng-deep .ngx-datatable .datatable .datatable-body-column {
  border-right: 2px solid #d3d3d3;
}
:host::ng-deep .ngx-datatable .datatable .datatable-row-group.datatable-row-left:hover {
  background-color: #8affd9 !important;
  backface-visibility: hidden;
  cursor: pointer;
}
:host::ng-deep .ngx-datatable .datatable-column {
  border: 1px solid darkgray;
}
:host::ng-deep .ngx-datatable .datatable-body {
  margin-top: 5px;
}
:host::ng-deep .ngx-datatable .datatable-body-cell {
  padding-left: 4px;
  padding-right: 6px;
  height: 30px !important;
  margin-top: -7px;
  margin-bottom: -1px;
  border-right: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;
}
:host::ng-deep .ngx-datatable .datatable-body-cell.sort-active {
  color: #000000 !important;
}
:host::ng-deep .ngx-datatable .datatable-body-cell-label .text {
  display: inline-block;
  width: 80%;
}
:host::ng-deep .ngx-datatable .datatable-body-cell-label .icon {
  display: inline-block;
  width: 20%;
  text-align: center;
}
:host::ng-deep .ng-dropdown-panel.ng-select-bottom {
  top: 30px;
  left: 0;
  box-shadow: none;
}
:host::ng-deep.a3table {
  height: 850px !important;
}
:host::ng-deep .service-view-section {
  font-size: 12px !important;
  display: flex;
  height: 100%;
  gap: 20px;
}
:host::ng-deep .service-view-section .export-a3 {
  display: flex;
  justify-content: flex-end;
}
:host::ng-deep .service-view-section .row-border {
  border-bottom: 3px solid black;
}
:host::ng-deep .service-view-section .row-pos-border {
  border-bottom: 1px solid #4a2511;
}
:host::ng-deep .service-view-section .filter-nav {
  width: 17%;
  background-color: white;
  transition: width 200ms;
  margin-top: 10px;
  height: 100%;
}
:host::ng-deep .service-view-section .filter-nav .expand-icon {
  transition: display 200ms;
  display: block;
  position: relative;
  width: 20px;
  float: right;
  height: 29px;
  font-size: 20px;
  left: 12px;
  color: white;
  cursor: pointer;
  top: 32px;
  text-align: center;
  border-radius: 10px;
  background-color: #64b2d4;
}
:host::ng-deep .service-view-section .filter-nav + .a3 {
  width: 83%;
}
:host::ng-deep .service-view-section .filter-nav-collapse {
  width: 1%;
  background-color: #ededed;
  transition: width 200ms;
  margin-top: 10px;
  height: 100%;
}
:host::ng-deep .service-view-section .filter-nav-collapse .expand-icon {
  transition: display 200ms;
  display: block;
  position: relative;
  width: 20px;
  float: right;
  height: 29px;
  font-size: 20px;
  left: 12px;
  color: white;
  cursor: pointer;
  top: 32px;
  text-align: center;
  border-radius: 10px;
  background-color: #64b2d4;
}
:host::ng-deep .service-view-section .filter-nav-collapse .filters {
  display: none;
}
:host::ng-deep .service-view-section .filter-nav-collapse:hover {
  width: 12%;
  background-color: white;
  transition: all 200ms;
}
:host::ng-deep .service-view-section .filter-nav-collapse:hover .filters {
  display: block;
}
:host::ng-deep .service-view-section .filter-nav-collapse:hover + .a3 {
  width: 97%;
}
:host::ng-deep .service-view-section .a3 {
  margin-top: 15px;
  width: 97%;
  margin-left: 20px;
}
:host::ng-deep .service-view-section .a3 .vessel-detail {
  margin-bottom: 10px;
}
:host::ng-deep .service-view-section .a3 .blanking-color {
  background-color: #fec5e5;
}
:host::ng-deep .service-view-section .a3 .blanking-dummy-vsl-color {
  color: #f94449 !important;
  font-style: normal;
  font-weight: 620;
}
:host::ng-deep .service-view-section .sub-heading {
  font-size: 14px;
  padding: 10px;
}
:host::ng-deep .service-view-section .sub-section {
  background-color: #DCEBFF;
  padding: 5px;
}
:host::ng-deep .a3-column-filter .ng-select-container {
  display: none;
}
:host::ng-deep .a3-column-filter .ng-dropdown-panel {
  border: 0;
  box-shadow: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
