/** */
import { Component, OnInit, Injector } from '@angular/core';
import { ChampsMonitoringService } from 'src/app/core/services/application-insights/champs-monitoring.service';

@Component({
  selector: 'champs-app-insight-base',
  templateUrl: './app-insight-base.component.html',
  styleUrls: ['./app-insight-base.component.scss']
})
export class AppInsightBaseComponent implements OnInit {
  public champsMonitoringService: ChampsMonitoringService;
  constructor() {
    const injector = Injector.create([{provide: ChampsMonitoringService, deps: []}
      
    ]);
    this.champsMonitoringService = injector.get(ChampsMonitoringService);
    this.logNavigation();
  }

  public ngOnInit() {
  }

  public logNavigation() {
    this.champsMonitoringService.logPageView();
  }
}
