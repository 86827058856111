// this component is for the vessel score ui part, consisting of ship and bar charts and color logic
import { Component, OnInit, OnChanges, Input } from '@angular/core';
// import { Chart } from 'angular-highcharts';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';

@Component({
  selector: 'champs-evaluation-vessel-score',
  templateUrl: './evaluation-vessel-score.component.html',
  styleUrls: ['./evaluation-vessel-score.component.scss'],
})
export class EvaluationVesselScoreComponent implements OnInit, OnChanges {
  @Input() public imoNumber: number;
  @Input() public vslFRPScore: any;
  public id: any;
  public barchart: any;

  public vesselScore: any;
  public vesselOutstandingsUnit: any;
  public vesselOutstandingsValue: any;
  // public showSapAlert: boolean;

  /** colors */
  public grey = '#edf0f2';
  public tomato = '#d62d23';
  public mango = '#ff9b1e';
  /** red */
  public darkRed = '#C74E46';
  public normalRed = '#DE574F';
  public lightRed = '#E27069';
  public lighterRed = '#E78984';
  /** orange */
  public darkOrange = '#E59534';
  public normalOrange = '#FFA73B ';
  public lightOrange = '#FFB458';
  public lighterOrange = '#FFC176';
  /** green */
  public darkGreen = '#2dba5e';
  public normalGreen = '#55c77d';
  public lightGreen = '#5ad585';
  public lighterGreen = '#a3dcaf';

  /** for color configuration */
  public colorConfigList: any[] = [];
  public keyValues: any[] = [];
  public valuesInStringForm: any[] = [];
  public values: any[] = [];
  public namesValuesInStringForm: any[] = [];
  public namesValues: any[] = [];
  public names: any[] = [];
  public lowValue: any;
  public mediumValue: any;
  public highValue: any;
  public lowValueScore: any;
  public mediumValueScore: any;
  public highValueScore: any;
  public lowValueColor: any;
  public mediumValueColor: any;
  public highValueColor: any;

  constructor(
    private route: ActivatedRoute,
    private vesselsService: VesselsService,
    private colorConfigService: ColorConfigService
  ) {
    this.id = this.route.parent ? this.route.parent.snapshot.paramMap.get('id') : 0;
    this.vesselScore = 0.0;
  }

  public ngOnChanges() {}

  public ngOnInit() {
    /** integrated mode (comment this for hardcoded mode) */
    const service1 = this.vesselsService.getVesselScore(this.id);
    const service2 = this.vesselsService.getVesselScoreOutstandings(this.id);
    forkJoin(service1, service2).subscribe((data) => {
      this.vesselScore = data;
      this.vesselOutstandingsValue = data[1];
      this.vesselOutstandingsUnit = 'USD';

      this.colorConfigService.getColorConfigByGroupName('Scores - Vessel').subscribe((colorConfigList: any) => {
        this.colorConfigList = colorConfigList;
        this.keyValues = this.colorConfigList[0].keyValues;
      });
    });
  }

  /** */
  public navigateToFixOverview(fixId) {
    window.open('/fixtures/' + fixId);
  }
}
