//
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ScenariosService } from 'src/app/modules/scenarios/services/scenarios.service';
import { ServiceRepositoryService } from 'src/app/modules/service-repository/services/service-repository.service';

@Component({
  selector: 'champs-extra-loader',
  templateUrl: './extra-loader.component.html',
  styleUrls: ['./extra-loader.component.scss']
})
export class ExtraLoaderComponent {

  @Input() public position: any;
  @Input() public datedScheduleId: any;
  @Input() public serviceRepo: any;
  @Input() public isExtraloaderHidden: boolean;
  @Input() public extraLoaderPopupPosition: { left: string, top: string };
  @Output() public close: EventEmitter<any> = new EventEmitter();

  constructor(
    private serviceRepoService: ServiceRepositoryService,
    private scenarioService: ScenariosService
  ) { }

  /** extraloader functionality */
  public addExtraloader() {
    // if extraloader is present but hidden, show extra position in UI
    if (this.isExtraloaderHidden) {
      this.close.emit({
        type: 'showExtraLoader',
        data: {
          position: this.position
        }
      });
      return;
    }

    // if extraloader is not present, hit api
    const requestService = this.serviceRepo ? this.serviceRepoService : this.scenarioService;
    requestService.addExtraloader(this.datedScheduleId, this.position).subscribe(() => {
      this.close.emit({
        type: 'addExtraLoader',
        data: {
          position: this.position
        }
      });
    });
  }

  public closeExtraloaderPopup() {
    this.close.emit({
      type: 'closeExtraloaderPopup'
    });
  }
}
