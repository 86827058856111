//
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { AuthService } from '../../../../core/services/authentication/auth/auth.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'champs-vessel-requirements',
  templateUrl: './vessel-requirements.component.html',
  styleUrls: ['./vessel-requirements.component.scss']
})
export class VesselRequirementsComponent implements OnInit, OnChanges {

  @Input() public serviceRepo: any;
  @Input() public editVesselRequirements: boolean;
  @Input() public fromCreateDummyPage: boolean;
  @Input() public convertToRequestChecked: boolean;
  @Input() public fromVesselDetailPage: boolean;
  @Output() public getSegmentChangeValforDummy: EventEmitter<any> = new EventEmitter();
  @Output() public useProformaFromFirstDep: EventEmitter<any> = new EventEmitter();
  @Output() public noScheduleOnThisService: EventEmitter<any> = new EventEmitter();
  @Output() public emitVslReqFormValueChangesEvents: EventEmitter<any> = new EventEmitter();
  @Input() public setServiceforProforma: any;
  @Input() public vslIsProformaFromService: boolean;
  @Input() public showProfCheckBbox: boolean;
  @Input() public isUserNotAuthToEdit: boolean;
  @Input() public currentRotation: any;

  public showError: boolean;
  public id: any;
  public schId: any;
  public vesselReq: any;
  public vesselGear: [];
  public gearDropdownVal: any;
  public beamDropdownVal: any;
  public loaDropdownVal: any;
  public keelDropdownVal: any;
  public vesselMaxLOA: [];
  public vesselMaxBeam: [];
  public vesselPrfHeightKeel: [];
  public selectedGear: [];
  public segmentvalue: any;
  public maxLOAValue: any;
  public segmentId: any;
  public editMode: boolean = false;
  public vesselReqFrmGrp: UntypedFormGroup;
  public teuDiffError: boolean = false;
  public mtDiffError: boolean = false;
  public isVslReqUpdateAuth: boolean = false;
  public scnId: any;
  public segmentList: any;
  public segment: any;
  public vesselName: any;
  public segmentName: any;
  public nomTeuCapName: any;
  public grossTonnageName: any;
  public deadWeightName: any;
  public maxSpeedName: any;
  public plugsName: any;
  public nomTeu: any;
  public nomTeuName: any;
  public prhPKIDforDummy: any;
  public dropwnGear: any;
  public dropwnLOA: any;
  public dropwnBeam: any;
  public dropwnKeel: any;
  public gearSelected = [];
  public port_status = [];
  public port_status_initial = [];
  public listPortDetailMaxLOADto = new UntypedFormControl();
  public listPortDetailMaxBeamDto = new UntypedFormControl();
  public listPortDetailPreferredHeightFromKeelDto = new UntypedFormControl();
  public listPortDetailGearDto = new UntypedFormControl();
  public ampCompliantVal = ['Active Dual', 'Active Single', 'No'];

  constructor(private serviceRepoService: ServiceRepositoryService,
    private router: Router,
    private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private auth: AuthService, private scenarioService: ScenariosService) {
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.id = this.route.parent.snapshot.paramMap.get('id');
    this.scnId = this.route.parent.snapshot.paramMap.get('scnId');
  }

  public ngOnInit() {
    this.isVslReqUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Services', 'update');
    this.getVesselRequirements();
    this.getSegmentList();
    this.toggleEditMode(this.editVesselRequirements);
    this.getExtendDummyRequirement();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.currentRotation != null) {
      this.currentRotation.rotationDto.forEach((element, idx) => {
        this.port_status.push({
          'portName': element.pfmPortName,
          'siteCode': element.terminalCode,
          'portId': element.proformaPortId,
          'portSiteCode': element.pfmPortName + '-' + ' ' + element.terminalCode,
        });
      });
      this.port_status = this.port_status.filter(
        (v, i, a) => a.findIndex((t) => t.portName === v.portName) === i
      )

      if (this.fromCreateDummyPage && this.setServiceforProforma && !this.fromVesselDetailPage) {
        this.id = this.setServiceforProforma.serviceId;
        const beginDate = new DatePipe('en-US').transform(this.setServiceforProforma.beginDate, 'dd-MMM-yyyy');
        const endDate = new DatePipe('en-US').transform(this.setServiceforProforma.endDate, 'dd-MMM-yyyy');
        const scheduleList = this.setServiceforProforma.lstProformas;
        if (scheduleList && scheduleList.length === 1) {
          this.schId = scheduleList[0].proformaID;
          this.prhPKIDforDummy = scheduleList[0].proformaPKID;
          this.getVesselRequirements();

        } else if (scheduleList && scheduleList.length > 1) {
          const filteredSchedule = scheduleList.filter(x =>
            (new Date(beginDate) >= new Date(x.validFrom)) &&
            (new Date(endDate) <= new Date(x.validTo))
          );
          if (filteredSchedule && filteredSchedule.length > 1) {
            let filtersedScheduleOnStatus = filteredSchedule.filter(x => x.proformaStatus === 'Current');
            filtersedScheduleOnStatus = (filtersedScheduleOnStatus.length === 0) ? filteredSchedule.filter(x => x.proformaStatus === 'Upcoming') : filtersedScheduleOnStatus;
            filtersedScheduleOnStatus = (filtersedScheduleOnStatus.length === 0) ? filteredSchedule.filter(x => x.proformaStatus === 'New') : filtersedScheduleOnStatus;
            if (filtersedScheduleOnStatus.length > 1) {
              filtersedScheduleOnStatus = filtersedScheduleOnStatus.reduce((a, b) => {
                return new Date(a.validFrom) > new Date(b.validFrom) ? a : b;
              });
              this.schId = filtersedScheduleOnStatus ? filtersedScheduleOnStatus.proformaID : null;
              this.prhPKIDforDummy = filtersedScheduleOnStatus ? filtersedScheduleOnStatus.proformaPKID : null;
            } else if (filtersedScheduleOnStatus.length === 1) {
              this.schId = filtersedScheduleOnStatus[0].proformaID;
              this.prhPKIDforDummy = filtersedScheduleOnStatus[0].proformaPKID;
            }

            this.getVesselRequirements();

          } else if (filteredSchedule && filteredSchedule.length === 1) {
            this.schId = filteredSchedule[0].proformaID;
            this.prhPKIDforDummy = filteredSchedule[0].proformaPKID;

            this.getVesselRequirements();

          } else if (filteredSchedule && filteredSchedule.length === 0) {
            const filteredScheduleCurrent = scheduleList.filter(x => x.proformaStatus === 'Current');
            if (filteredScheduleCurrent && filteredScheduleCurrent.length === 1) {
              this.schId = filteredScheduleCurrent[0].proformaID;
              this.prhPKIDforDummy = filteredScheduleCurrent[0].proformaPKID;

              this.getVesselRequirements();

            } else {
              this.noScheduleOnThisService.emit('null');
              return;
            }
          }
        } else if (!scheduleList || scheduleList.length === 0) {
          this.noScheduleOnThisService.emit('null');
          return;
        }

      }
      if (this.vesselReqFrmGrp && this.fromCreateDummyPage) {
        this.vesselReqFrmGrp.valueChanges.subscribe(val => {
          const emitObj = {
            isInvalid: this.vesselReqFrmGrp.invalid,
            isModified: true,
            isPristine: this.vesselReqFrmGrp.pristine,
            isDirty: this.vesselReqFrmGrp.dirty,
          };
          if (this.vslIsProformaFromService && (!this.vesselReqFrmGrp.pristine || this.vesselReqFrmGrp.dirty)) {
            this.vslIsProformaFromService = false;
            const k = {
              target: {
                checked: false
              }
            };
            this.useProformaFromFirstDepFromDummy(k);
          }
          this.emitVslReqFormValueChangesEvents.emit(emitObj);
        });
      }
    }
  }

  public filterDropdownIconUrlSetter(filterControl): string {
    return 'assets/icons/arrow-dropdown.svg';
  }

  public toggleEditMode(edit) {
    this.editMode = edit;
    if (!edit) {
      this.showError = false;
    }
    if (edit) {
      if (!this.fromCreateDummyPage) {
        this.vesselRequirementsFormGroup(this.vesselReq);
        this.setDropdownPortRestrn(this.vesselReq);
      } else {
        this.vesselRequirementsFormGroup(this.vesselReq);
      }
      // this.vesselRequirementsFormGroup(this.vesselReq);
      // this.setDropdownPortRestrn(this.vesselReq);
    }
  }
  public setDropdownPortRestrn(vesselReq) {
    this.vesselMaxBeam = vesselReq.listPortDetailMaxBeamDto.filter(e => e.status).map((e) => {
      return e.pdmbProformaPortId;
    });
    this.vesselMaxLOA = vesselReq.listPortDetailMaxLOADto.filter(e => e.status).map((e) => {
      return e.pdmlProformaPortId;
    });
    this.vesselPrfHeightKeel = vesselReq.listPortDetailPreferredHeightFromKeelDto.filter(e => e.status).map((e) => {
      return e.pdphfkProformaPortId;
    });
    this.vesselGear = vesselReq.listPortDetailGearDto.filter(e => e.status).map((e) => {
      return e.pdgProformaPortId;
    });
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1 === o2
  }
  public valueChanges(ctrl, e) {
    if (ctrl == 'listPortDetailGearDto') {
      const arr = e.value;
      this.dropwnGear = arr;
    }
    if (ctrl == 'listPortDetailMaxLOADto') {
      const arr1 = e.value;
      this.dropwnLOA = arr1;
    }
    if (ctrl == 'listPortDetailMaxBeamDto') {
      const arr2 = e.value;
      this.dropwnBeam = arr2;
    }
    if (ctrl == 'listPortDetailPreferredHeightFromKeelDto') {
      const arr3 = e.value;
      this.dropwnKeel = arr3;
    }
  }
  public getExtendDummyRequirement() {

    if (this.fromVesselDetailPage && this.router.url.includes('extenddummy')) {

      this.segmentName = localStorage.getItem('this.vesselDetail.vesselTeu');
      this.nomTeuCapName = localStorage.getItem('this.vesselDetail.vslTeuNominal');
      this.nomTeuName = localStorage.getItem('this.vesselDetail.vslTonCenCap');
      this.grossTonnageName = localStorage.getItem('this.vesselDetail.vslGT');
      this.deadWeightName = localStorage.getItem('this.vesselDetail.vslDWT');
      this.maxSpeedName = localStorage.getItem('this.vesselDetail.vslMaxSpeed');
      this.plugsName = localStorage.getItem('this.vesselDetail.vslTeuReefer');

      this.vesselReqFrmGrp = this.formBuilder.group({
        totalTeuCap: null,
        totalMTCap: null,
        mlTeuCap: null,
        mlTeuPer: null,
        mlMTPer: null,
        mlmtCap: null,
        plugs: this.formBuilder.control(this.plugsName === 'null' ? null : this.plugsName, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        minSpeed: null,
        maxSpeed: this.formBuilder.control(this.maxSpeedName === 'null' ? null : this.maxSpeedName, [Validators.required, Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        airDraft: null,
        gears: null,
        lastUpdatedby: null,
        listPortDetailGearDto: null,
        listPortDetailMaxBeamDto: null,
        listPortDetailMaxLOADto: null,
        listPortDetailPreferredHeightFromKeelDto: null,
        totalTeuCapOld: null,
        totalMTCapOld: null,
        mlTeuCapOld: null,
        mlmtCapOld: null,
        plugsOld: null,
        minSpeedOld: null,
        maxSpeedOld: null,
        nomTeuCapacity: this.formBuilder.control(this.nomTeuCapName === 'null' ? null : this.nomTeuCapName, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        tonCenCap: this.formBuilder.control(this.nomTeuName === 'null' ? null : this.nomTeuName, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        grossTonnage: this.formBuilder.control(this.grossTonnageName === 'null' ? null : this.grossTonnageName, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{1,2})?$'))]),
        deadWeight: this.formBuilder.control(this.deadWeightName === 'null' ? null : this.deadWeightName, [Validators.pattern(new RegExp('^[0-9]+$'))]),
        maxLOA: null,
        maxBeam: null,
        maxDraft: null,
        rcmInstalled: null,
        ampCompliant: null,
        panamaFitted: null,
        itfCompliant: null,
        scrubber: null,
        capacityAdditionalRemark: null,
        segment: this.formBuilder.control(this.segmentName === 'null' ? null : this.segmentName, [Validators.required]),
        vesselTeuId: null,
        additionalRemark: null
      });
    }

  }
  public getSegmentList() {
    this.serviceRepoService
      .getSegmentList()
      .subscribe((segList: Response) => {
        this.segmentList = segList;
      });
  }
  public calculatePercentage(unitVal, totalVal) {
    return Math.round((unitVal / totalVal) * 100);
  }
  public setTeuPercentage() {
    const unitVal = this.vesselReqFrmGrp.get('mlTeuCap').value;
    const totalVal = +this.vesselReqFrmGrp.get('totalTeuCap').value;
    this.vesselReqFrmGrp.patchValue({
      mlTeuPer: (totalVal && unitVal) ? Math.round(((unitVal / totalVal) * 100)) : ''
    });
    this.isTeuValid(totalVal, unitVal);
  }
  public setMTPercentage() {
    const unitVal = +this.vesselReqFrmGrp.get('mlmtCap').value;
    const totalVal = +this.vesselReqFrmGrp.get('totalMTCap').value;
    this.vesselReqFrmGrp.patchValue({
      mlMTPer: (totalVal && unitVal) ? Math.round(((unitVal / totalVal) * 100)) : ''
    });
    this.isMTVaild(totalVal, unitVal);
  }
  public setMTUnitValue() {
    const mlMTPer = +this.vesselReqFrmGrp.get('mlMTPer').value;
    const totalVal = +this.vesselReqFrmGrp.get('totalMTCap').value;
    this.vesselReqFrmGrp.patchValue({
      mlmtCap: (totalVal && mlMTPer) ? Math.round(((mlMTPer * totalVal) / 100)) : ''
    });
    const unitVal = +this.vesselReqFrmGrp.get('mlmtCap').value;
    this.isMTVaild(totalVal, unitVal);
  }
  public setMLTEUUnitValue() {
    const mlTeuPer = +this.vesselReqFrmGrp.get('mlTeuPer').value;
    const totalVal = +this.vesselReqFrmGrp.get('totalTeuCap').value;
    this.vesselReqFrmGrp.patchValue({
      mlTeuCap: (totalVal && mlTeuPer) ? Math.round(((mlTeuPer * totalVal) / 100)) : ''
    });
    const unitVal = +this.vesselReqFrmGrp.get('mlTeuCap').value;
    this.isTeuValid(totalVal, unitVal);
  }
  public isTeuValid(val1, val2) {
    this.teuDiffError = (val1 < val2);
  }
  public isMTVaild(val1, val2) {
    this.mtDiffError = (val1 < val2);
  }
  public useProformaFromFirstDepFromDummy(e) {
    this.useProformaFromFirstDep.emit(e);
    if (!e.target.checked) {
      this.setServiceforProforma = null;
    }
  }
  public updateVesselReq() {
    const requiredFields = ['plugs', 'maxSpeed', 'nomTeuCapacity', 'segment'];
    requiredFields.forEach(field => {
      if (!(this.vesselReqFrmGrp.get(field).value)) {
        this.vesselReqFrmGrp.get(field).setErrors({ required: true });
      }
    });
    if (!this.vesselReqFrmGrp.valid || this.teuDiffError || this.mtDiffError) {
      this.showError = true;
      return;
    }
    this.segmentvalue = this.vesselReqFrmGrp.get('segment').value;
    let arr1 = [];
    let beamData = [];
    let loaData = [];
    let keelData = [];
    let len = this.vesselGear ? this.vesselGear.length : 0;

    if (len > 0) {
      for (let i = 0; i < len; i++) {
        arr1.push({
          pdgPkid: this.getUniquePortRestnId(this.vesselGear[i], 'pdgProformaPortId', 'gear') ? this.getUniquePortRestnId(this.vesselGear[i], 'pdgProformaPortId', 'gear') : 0,
          pdgProformaPortId: this.vesselGear[i],
          portDetailspdPKId: this.vesselReqFrmGrp.get('portDetailsID').value,
          status: true
        });
      }
    } else if (len == 0) {
      if (this.gearDropdownVal) {
        this.gearDropdownVal.forEach((p, j) => {
          if (len == 0) {
            p['status'] = false;
          }
        });
      }
      arr1 = this.gearDropdownVal;
    }
    arr1 = arr1 ? arr1.filter(
      (v, i, a) => a.findIndex((t) => t.pdgProformaPortId === v.pdgProformaPortId) === i
    ) : [];

    let len1 = this.vesselMaxBeam ? this.vesselMaxBeam.length : 0;
    if (len1 > 0) {
      for (let j = 0; j < len1; j++) {
        beamData.push({
          pdmbPkid: this.getUniquePortRestnId(this.vesselMaxBeam[j], 'pdmbProformaPortId', 'beam') ? this.getUniquePortRestnId(this.vesselMaxBeam[j], 'pdmbProformaPortId', 'beam') : 0,
          pdmbProformaPortId: this.vesselMaxBeam[j],
          portDetailspdPKId: this.vesselReqFrmGrp.get('portDetailsID').value,
          status: true
        });
      }
    } else if (len1 == 0) {
      if (this.beamDropdownVal) {
        this.beamDropdownVal.forEach((p, j) => {
          if (len1 == 0) {
            p['status'] = false;
          }
        });
      }
      beamData = this.beamDropdownVal;
    }
    beamData = beamData ? beamData.filter(
      (v, i, a) => a.findIndex((t) => t.pdmbProformaPortId === v.pdmbProformaPortId) === i
    ) : [];
    let len2 = this.vesselPrfHeightKeel ? this.vesselPrfHeightKeel.length : 0;
    if (len2 > 0) {
      for (let k = 0; k < len2; k++) {
        keelData.push({
          pdphfkPkid: this.getUniquePortRestnId(this.vesselPrfHeightKeel[k], 'pdphfkProformaPortId', 'keel') ? this.getUniquePortRestnId(this.vesselPrfHeightKeel[k], 'pdphfkProformaPortId', 'keel') : 0,
          pdphfkProformaPortId: this.vesselPrfHeightKeel[k],
          portDetailspdPKId: this.vesselReqFrmGrp.get('portDetailsID').value,
          status: true
        });
      }
    } else if (len2 == 0) {
      if (this.keelDropdownVal) {
        this.keelDropdownVal.forEach((p, j) => {
          if (len2 == 0) {
            p['status'] = false;
          }
        });
      }
      keelData = this.keelDropdownVal;
    }
    keelData = keelData ? keelData.filter(
      (v, i, a) => a.findIndex((t) => t.pdphfkProformaPortId === v.pdphfkProformaPortId) === i
    ) : [];

    let len3 = this.vesselMaxLOA ? this.vesselMaxLOA.length : 0;
    if (len3 > 0) {
      for (let l = 0; l < len3; l++) {
        loaData.push({
          pdmlPkid: this.getUniquePortRestnId(this.vesselMaxLOA[l], 'pdmlProformaPortId', 'maxloa') ? this.getUniquePortRestnId(this.vesselMaxLOA[l], 'pdmlProformaPortId', 'maxloa') : 0,
          pdmlProformaPortId: this.vesselMaxLOA[l],
          portDetailspdPKId: this.vesselReqFrmGrp.get('portDetailsID').value,
          status: true
        });
      }
    }
    else if (len3 == 0) {
      if (this.loaDropdownVal) {
        this.loaDropdownVal.forEach((p, j) => {
          if (len3 == 0) {
            p['status'] = false;
          }
        });
      }
      loaData = this.loaDropdownVal;
    }
    loaData = loaData ? loaData.filter(
      (v, i, a) => a.findIndex((t) => t.pdmlProformaPortId === v.pdmlProformaPortId) === i
    ) : [];
    this.vesselReqFrmGrp.patchValue({
      listPortDetailGearDto: arr1,
      listPortDetailMaxBeamDto: beamData,
      listPortDetailMaxLOADto: loaData,
      listPortDetailPreferredHeightFromKeelDto: keelData,
      segment: this.segmentvalue.vesselTeuName || this.segmentvalue || this.vesselReq.segment,
      vesselTeuId: this.segmentvalue.vesselTeuID ? this.segmentvalue.vesselTeuID :
        this.vesselReq && this.vesselReq.vesselTeuId ? this.vesselReq.vesselTeuId :
          this.vesselReqFrmGrp.get('vesselTeuId').value ? this.vesselReqFrmGrp.get('vesselTeuId').value :
            this.findSegmentId(this.vesselReqFrmGrp.get('segment').value),
      additionalRemark: this.vesselReqFrmGrp.get('additionalRemark').value || (this.vesselReq ? this.vesselReq.additionalRemark : null)

    });

    if (!this.fromCreateDummyPage) {
      const obs$ = this.serviceRepo
        ? this.serviceRepoService.postVesselReqUpdateService(this.vesselReqFrmGrp.value)
        : this.scenarioService.postVesselReqUpdateService(this.vesselReqFrmGrp.value);
      obs$.subscribe((res) => {
        this.getVesselRequirements();
        this.editMode = false;
        this.showError = false;
        arr1 = [];
        beamData = [];
        loaData = [];
        keelData = [];
      });
    } else {
      return;
    }
  }
  public setSegmentInDummyName(e) {
    this.getSegmentChangeValforDummy.emit(e);
  }
  public getUniquePortRestnId(e, id, label) {
    let key = '';
    let val = '';
    switch (label) {
      case 'gear':
        key = this.gearDropdownVal;
        val = 'pdgPkid';
        break;
      case 'maxloa':
        key = this.loaDropdownVal;
        val = 'pdmlPkid';
        break;
      case 'beam':
        key = this.beamDropdownVal;
        val = 'pdmbPkid';
        break;
      case 'keel':
        key = this.keelDropdownVal;
        val = 'pdphfkPkid';
        break;
      default:
        break;
    }

    for (const i of key) {
      if (e == i[id]) {
        return i[val];
      }
    }
  }
  public getGearMultiValue(val, type) {
    if (type == 'read') {
      if (val) {
        let gearVal = this.port_status;
        gearVal = gearVal.filter((element, index) =>
          val.find((e) => (e.status && e.pdgProformaPortId) === element.portId)
        );
        let gearArr = [];
        for (const i of gearVal) {
          gearArr.push(i.portName);
        }
        gearArr = gearArr.filter((v, i) => gearArr.indexOf(v) == i);
        return gearArr;
      }
    }
    if (type == 'edit') {
      if (val) {
        val = val.filter((v, i) => val.indexOf(v) == i);
        let gearVal1 = this.port_status;
        gearVal1 = gearVal1.filter((element, index) =>
          val.find((e) => e === element.portId));
        let gearArr1 = [];
        for (let i of gearVal1) {
          gearArr1.push(i.portName);
        }
        gearArr1 = gearArr1.filter((v, i) => gearArr1.indexOf(v) == i);
        return gearArr1;
      }
    }
  }
  public getMaxBeamMultiValue(val, type) {
    if (type == 'read') {
      if (val) {
        let maxBeamVal = this.port_status;
        maxBeamVal = maxBeamVal.filter((element, index) =>
          val.find((e) => (e.status && e.pdmbProformaPortId) === element.portId)
        );
        let beamArr = [];
        for (let i of maxBeamVal) {
          beamArr.push(i.portName);
        }
        beamArr = beamArr.filter((v, i) => beamArr.indexOf(v) == i);
        return beamArr;
      }
    }
    if (type == 'edit') {
      if (val) {
        val = val.filter((v, i) => val.indexOf(v) == i);
        let beamVal1 = this.port_status;
        beamVal1 = beamVal1.filter((element, index) =>
          val.find((e) => e === element.portId));
        let beamArr1 = [];
        for (let i of beamVal1) {
          beamArr1.push(i.portName);
        }
        beamArr1 = beamArr1.filter((v, i) => beamArr1.indexOf(v) == i);
        return beamArr1;
      }
    }
  }
  public getMaxLOAMultiValue(val, type) {
    if (type == 'read') {
      if (val) {
        let maxLOAVal = this.port_status;
        maxLOAVal = maxLOAVal.filter((element, index) =>
          val.find((e) => (e.status && e.pdmlProformaPortId) === element.portId)
        );
        let loaArr = [];
        for (let i of maxLOAVal) {
          loaArr.push(i.portName);
        }
        loaArr = loaArr.filter((v, i) => loaArr.indexOf(v) == i);
        return loaArr;
      }
    }
    if (type == 'edit') {
      if (val) {
        val = val.filter((v, i) => val.indexOf(v) == i);
        let loaVal1 = this.port_status;
        loaVal1 = loaVal1.filter((element, index) =>
          val.find((e) => e === element.portId));
        let loaArr1 = [];
        for (let i of loaVal1) {
          loaArr1.push(i.portName);
        }
        loaArr1 = loaArr1.filter((v, i) => loaArr1.indexOf(v) == i);
        return loaArr1;
      }
    }
  }
  public getPrfHeightKeelMultiValue(val, type) {
    if (type == 'read') {
      if (val) {
        let keelVal = this.port_status;
        keelVal = keelVal.filter((element, index) =>
          val.find((e) => (e.status && e.pdphfkProformaPortId) === element.portId)
        );
        let keelArr = [];
        for (let i of keelVal) {
          keelArr.push(i.portName);
        }
        keelArr = keelArr.filter((v, i) => keelArr.indexOf(v) == i);
        return keelArr;
      }
    }
    if (type == 'edit') {
      if (val) {
        val = val.filter((v, i) => val.indexOf(v) == i);
        let keelVal1 = this.port_status;
        keelVal1 = keelVal1.filter((element, index) =>
          val.find((e) => e === element.portId));
        let keelArr1 = [];
        for (let i of keelVal1) {
          keelArr1.push(i.portName);
        }
        keelArr1 = keelArr1.filter((v, i) => keelArr1.indexOf(v) == i);
        return keelArr1;
      }
    }
  }
  public vesselRequirementsFormGroup(vesselReq: any = null) {
    if (vesselReq) {
      this.vesselReqFrmGrp = this.formBuilder.group({
        portDetailsID: this.vesselReq.portDetailsID,
        proformaID: this.vesselReq.proformaID,
        totalTeuCap: this.formBuilder.control(this.vesselReq.totalTeuCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        totalMTCap: this.formBuilder.control(this.vesselReq.totalMTCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        mlTeuCap: this.formBuilder.control(this.vesselReq.mlTeuCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        mlTeuPer: (this.vesselReq.mlTeuCap && this.vesselReq.totalTeuCap) ? Math.round((this.vesselReq.mlTeuCap / this.vesselReq.totalTeuCap) * 100) : '',
        mlMTPer: (this.vesselReq.mlmtCap && this.vesselReq.totalMTCap) ? Math.round((this.vesselReq.mlmtCap / this.vesselReq.totalMTCap) * 100) : '',
        mlmtCap: this.formBuilder.control(this.vesselReq.mlmtCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        plugs: this.formBuilder.control(this.vesselReq.plugs, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        minSpeed: this.formBuilder.control(this.vesselReq.minSpeed, [Validators.required, Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        maxSpeed: this.formBuilder.control(this.vesselReq.maxSpeed, [Validators.required, Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        airDraft: this.formBuilder.control(this.vesselReq.airDraft, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        gears: this.vesselReq.gears,
        lastUpdatedby: this.vesselReq.lastUpdatedby,
        listPortDetailGearDto: this.setUniqueValue(this.formBuilder.array(this.vesselReq.listPortDetailGearDto.map(val => this.formBuilder.group({
          pdgPkid: [val.pdgPkid],
          pdgProformaPortId: [val.pdgProformaPortId],
          portDetailspdPKId: [val.portDetailspdPKId],
          status: [val.status]
        }))), 'pdgProformaPortId'),

        listPortDetailMaxBeamDto: this.setUniqueValue(this.formBuilder.array(this.vesselReq.listPortDetailMaxBeamDto.map(val => this.formBuilder.group({
          pdmbPkid: [val.pdmbPkid],
          pdmbProformaPortId: [val.pdmbProformaPortId],
          portDetailspdPKId: [val.portDetailspdPKId],
          status: [val.status]
        }))), 'pdmbProformaPortId'),

        listPortDetailMaxLOADto: this.setUniqueValue(this.formBuilder.array(this.vesselReq.listPortDetailMaxLOADto.map(val => this.formBuilder.group({
          pdmlPkid: [val.pdmlPkid],
          pdmlProformaPortId: [val.pdmlProformaPortId],
          portDetailspdPKId: [val.portDetailspdPKId],
          status: [val.status]
        }))), 'pdmlProformaPortId'),

        listPortDetailPreferredHeightFromKeelDto: this.setUniqueValue(this.formBuilder.array(this.vesselReq.listPortDetailPreferredHeightFromKeelDto.map(val => this.formBuilder.group({
          pdphfkPkid: [val.pdphfkPkid],
          pdphfkProformaPortId: [val.pdphfkProformaPortId],
          portDetailspdPKId: [val.portDetailspdPKId],
          status: [val.status]
        }))), 'pdphfkProformaPortId'),

        totalTeuCapOld: this.vesselReq.totalTeuCapOld,
        totalMTCapOld: this.vesselReq.totalMTCapOld,
        mlTeuCapOld: this.vesselReq.mlTeuCapOld,
        mlmtCapOld: this.vesselReq.mlmtCapOld,
        plugsOld: this.vesselReq.plugsOld,
        minSpeedOld: this.vesselReq.minSpeedOld,
        maxSpeedOld: this.vesselReq.maxSpeedOld,
        nomTeuCapacity: this.formBuilder.control(this.vesselReq.nomTeuCapacity, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        tonCenCap: this.formBuilder.control(this.vesselReq.tonCenCap, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        grossTonnage: this.formBuilder.control(this.vesselReq.grossTonnage, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{1,2})?$'))]),
        deadWeight: this.formBuilder.control(this.vesselReq.deadWeight, [Validators.pattern(new RegExp('^[0-9]+$'))]),
        maxLOA: this.formBuilder.control(this.vesselReq.maxLOA, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{1,2})?$'))]),
        maxBeam: this.formBuilder.control(this.vesselReq.maxBeam, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{1,2})?$'))]),
        maxDraft: this.formBuilder.control(this.vesselReq.maxDraft, [Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        rcmInstalled: this.vesselReq.rcmInstalled,
        ampCompliant: this.vesselReq.ampCompliant,
        panamaFitted: this.vesselReq.panamaFitted,
        itfCompliant: this.vesselReq.itfCompliant,
        scrubber: this.vesselReq.scrubber,
        capacityAdditionalRemark: this.vesselReq.capacityAdditionalRemark,
        segment: this.formBuilder.control(this.vesselReq.segment, [Validators.required]),
        vesselTeuId: this.findSegmentId(this.vesselReq.segment),
        additionalRemark: this.vesselReq.additionalRemark
      });
    } else {
      this.vesselReqFrmGrp = this.formBuilder.group({
        portDetailsID: null,
        proformaID: null,
        totalTeuCap: null,
        totalMTCap: null,
        mlTeuCap: null,
        mlTeuPer: null,
        mlMTPer: null,
        mlmtCap: null,
        plugs: this.formBuilder.control(null, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        minSpeed: null,
        maxSpeed: this.formBuilder.control(null, [Validators.required, Validators.min(0), Validators.max(99), Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{0,1})?$'))]),
        airDraft: null,
        gears: null,
        lastUpdatedby: null,
        listPortDetailGearDto: this.formBuilder.array([]),
        listPortDetailMaxBeamDto: this.formBuilder.array([]),
        listPortDetailMaxLOADto: this.formBuilder.array([]),
        listPortDetailPreferredHeightFromKeelDto: this.formBuilder.array([]),
        totalTeuCapOld: null,
        totalMTCapOld: null,
        mlTeuCapOld: null,
        mlmtCapOld: null,
        plugsOld: null,
        minSpeedOld: null,
        maxSpeedOld: null,
        nomTeuCapacity: this.formBuilder.control(null, [Validators.required, Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        tonCenCap: this.formBuilder.control(null, [Validators.min(0), Validators.pattern(new RegExp('^[0-9]+$'))]),
        grossTonnage: this.formBuilder.control(null, [Validators.pattern(new RegExp('^[0-9]+(\.[0-9]{1,2})?$'))]),
        deadWeight: this.formBuilder.control(null, [Validators.pattern(new RegExp('^[0-9]+$'))]),
        maxLOA: null,
        maxBeam: null,
        maxDraft: null,
        rcmInstalled: null,
        ampCompliant: null,
        panamaFitted: null,
        itfCompliant: null,
        scrubber: null,
        capacityAdditionalRemark: null,
        segment: this.formBuilder.control(null, [Validators.required]),
        vesselTeuId: null,
        additionalRemark: null
      });
    }
    if (this.vesselReqFrmGrp && this.fromCreateDummyPage) {
      if (this.isUserNotAuthToEdit) {
        this.vesselReqFrmGrp.disable();
        this.showProfCheckBbox = false;
      }
      this.vesselReqFrmGrp.valueChanges.subscribe(val => {
        const emitObj = {
          isInvalid: this.vesselReqFrmGrp.invalid,
          isModified: true,
          isPristine: this.vesselReqFrmGrp.pristine,
          isDirty: this.vesselReqFrmGrp.dirty,
        };
        if (this.vslIsProformaFromService && (!this.vesselReqFrmGrp.pristine || this.vesselReqFrmGrp.dirty)) {
          this.vslIsProformaFromService = false;
          const k = {
            target: {
              checked: false
            }
          };
          this.useProformaFromFirstDepFromDummy(k);
        }
        this.emitVslReqFormValueChangesEvents.emit(emitObj);
      });
    }
  }
  public setUniqueValue(items, ctrl) {
    items = <UntypedFormArray>items.value.filter(
      (v, i, a) => (a.status === true && a.findIndex((t) => t.ctrl === v.ctrl) === i));
    return items;
  }
  public findSegmentId(teuName) {
    if (this.segmentList !== undefined) {
      const obj = this.segmentList.filter(e => e.vesselTeuName === teuName);
      const id = obj.length ? obj[0].vesselTeuID : null;
      return id;
    }
  }

  public vesselRequirementsFormControlChanges(): void {
    if (this.vesselReqFrmGrp) {
      this.vesselReqFrmGrp.valueChanges.subscribe(val => {
        const emitObj = {
          isInvalid: this.vesselReqFrmGrp.invalid,
          isModified: true,
          isPristine: this.vesselReqFrmGrp.pristine,
          isDirty: this.vesselReqFrmGrp.dirty,
        };
        this.vslIsProformaFromService = false;
        const k = {
          target: {
            checked: false
          }
        };
        this.useProformaFromFirstDepFromDummy(k);
        this.emitVslReqFormValueChangesEvents.emit(emitObj);
      });
    }
  }
  private getVesselRequirements() {
    if (this.fromCreateDummyPage) {
      this.serviceRepo = true;
    }
    if (this.schId) {
      const serv = this.serviceRepo
        ? this.serviceRepoService.getVesselRequirements(this.schId)
        : this.scenarioService.getVesselRequirements(this.schId, this.scnId);
      serv.subscribe((res: any) => {
        this.vesselReq = res;
        this.gearDropdownVal = [];
        this.vesselReq.listPortDetailGearDto.forEach((e) =>
          this.gearDropdownVal.push({
            'pdgPkid': e.pdgPkid,
            'pdgProformaPortId': e.pdgProformaPortId,
            'portDetailspdPKId': e.portDetailspdPKId,
            'status': e.status
          })
        );
        this.beamDropdownVal = [];
        this.vesselReq.listPortDetailMaxBeamDto.forEach((e) =>
          this.beamDropdownVal.push({
            'pdmbPkid': e.pdmbPkid,
            'pdmbProformaPortId': e.pdmbProformaPortId,
            'portDetailspdPKId': e.portDetailspdPKId,
            'status': e.status
          })
        );
        this.loaDropdownVal = [];
        this.vesselReq.listPortDetailMaxLOADto.forEach((e) =>
          this.loaDropdownVal.push({
            'pdmlPkid': e.pdmlPkid,
            'pdmlProformaPortId': e.pdmlProformaPortId,
            'portDetailspdPKId': e.portDetailspdPKId,
            'status': e.status
          })
        );
        this.keelDropdownVal = [];
        this.vesselReq.listPortDetailPreferredHeightFromKeelDto.forEach((e) =>
          this.keelDropdownVal.push({
            'pdphfkPkid': e.pdphfkPkid,
            'pdphfkProformaPortId': e.pdphfkProformaPortId,
            'portDetailspdPKId': e.portDetailspdPKId,
            'status': e.status
          })
        );
        this.vesselReq.listPortDetailMaxBeamDto = this.vesselReq.listPortDetailMaxBeamDto.filter(el => el.status);
        this.vesselReq.listPortDetailMaxBeamDto = this.vesselReq.listPortDetailMaxBeamDto.filter((v, i, a) => a.findIndex((t) => t.pdmbProformaPortId === v.pdmbProformaPortId) === i);

        this.beamDropdownVal = this.vesselReq.listPortDetailMaxBeamDto.filter(el => el.status);
        this.beamDropdownVal = this.beamDropdownVal.filter((v, i, a) => a.findIndex((t) => t.pdmbProformaPortId === v.pdmbProformaPortId) === i);

        this.vesselReq.listPortDetailMaxLOADto = this.vesselReq.listPortDetailMaxLOADto.filter(el => el.status);
        this.vesselReq.listPortDetailMaxLOADto = this.vesselReq.listPortDetailMaxLOADto.filter((v, i, a) => a.findIndex((t) => t.pdmlProformaPortId === v.pdmlProformaPortId) === i);

        this.vesselReq.listPortDetailPreferredHeightFromKeelDto = this.vesselReq.listPortDetailPreferredHeightFromKeelDto.filter(el => el.status);
        this.vesselReq.listPortDetailPreferredHeightFromKeelDto = this.vesselReq.listPortDetailPreferredHeightFromKeelDto.filter((v, i, a) => a.findIndex((t) => t.pdphfkProformaPortId === v.pdphfkProformaPortId) === i);

        this.vesselRequirementsFormGroup(this.vesselReq);
        this.vesselGear = [];
        this.vesselMaxBeam = [];
        this.vesselMaxLOA = [];
        this.vesselPrfHeightKeel = [];
        if (this.fromCreateDummyPage) {
          res.prhPKID = this.prhPKIDforDummy;
          this.getSegmentChangeValforDummy.emit(res);
        }
      });
    }
  }
}
