// Angular
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Event } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Rxjs
import { filter } from 'rxjs/operators';

// Services
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { AuthService } from 'src/app/core/services/authentication/auth/auth.service';
import { SessionTimeoutService } from 'src/app/core/services/common/session-timeout.service';

// Constants
import { VesselPresetColors } from 'src/app/utils/vessel-color-pallet-preset-data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vessel-detail',
  templateUrl: './vessel-detail.component.html',
  styleUrls: ['./vessel-detail.component.scss'],
})
export class VesselDetailComponent {
  public selectedColor: string = '#B2B2B2';

  public scoreColor: string;

  public activePage: string;
  public id: any;
  public vesselDetails: any;
  public vesselBannerName: any;

  public activeLink: string;
  public links: Array<any> = [];
  public userRole: any;
  public hasAccess: any;
  public vesselPresetColors: Array<string> = VesselPresetColors;

  constructor(
    private vesselsService: VesselsService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private titleService: Title,
    private sessionTimeoutService: SessionTimeoutService,
    private router: Router
  ) {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.activeLink = event.url;
        this.activePage = event.url.split('/')[3];
        this.id = this.route.snapshot.paramMap.get('id');

        this.links = [
          {
            route: '/vessels/' + this.id,
            text: 'Overview',
          },
          {
            route: '/vessels/' + this.id + '/details',
            text: 'Details',
          },
          {
            route: '/vessels/' + this.id + '/evaluation',
            text: 'Evaluation',
          },
        ];
      });

    route.data.subscribe(({ details }) => {
      this.vesselDetails = details;

      this.scoreColor = this.vesselDetails.vslVesselScoreColor ? this.vesselDetails.vslVesselScoreColor : null;

      this.vesselBannerName = details.vslVesselName;
      this.selectedColor = this.vesselDetails.vslVesselColor;
    });
    const allowedUsers = ['L3', 'Deployment'];
    // this.titleService.setTitle(
    //   this.titleService.getTitle() +
    //   (':' + 'V' + '-' + ' ' + this.vesselBannerName)
    // );
    this.userRole = this.auth.userProfile.roles
      ? this.auth.userProfile.roles[0]
      : this.auth.BYPASS_AUTHENTICATION
      ? this.auth.BYPASS_AUTHENTICATION
      : 'L3';
    this.hasAccess = allowedUsers.indexOf(this.userRole) === -1 ? true : false;
  }

  @HostListener('window:mousemove') public refreshUserState() {
    clearTimeout(this.sessionTimeoutService.userActivity);
  }

  public tabsLinkClick(link) {
    this.router.navigate([link.route], {
      skipLocationChange: environment.production,
    });
  }

  public getColor() {
    if (this.selectedColor !== this.vesselDetails.vslVesselColor) {
      const postObj = {
        vslID: this.vesselDetails.vslPKID,
        vslColor: this.selectedColor,
      };
      this.vesselsService.updateVesselColor(postObj).subscribe(
        () => {},
        (err) => {}
      );
    } else {
      return;
    }
  }
}
