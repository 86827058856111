// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep {
  /* tab css */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
}
:host::ng-deep * {
  font-size: 12px;
}
:host::ng-deep .header {
  color: #888888 !important;
}
:host::ng-deep .header .address {
  padding: 12px 0;
}
:host::ng-deep .header .address a {
  color: #282828 !important;
}
:host::ng-deep .issue {
  padding: 6px;
  background: #fcfff5;
  width: 48%;
  display: inline-block;
  margin: 8px 0;
  border: 1px solid #57cd6e;
}
:host::ng-deep .sync {
  width: 20%;
  display: inline-block;
}
:host::ng-deep .msg {
  width: 80%;
  display: inline-block;
}
:host::ng-deep .space {
  display: inline-block;
}
:host::ng-deep .err {
  padding: 6px;
  background: #fff6f6;
  width: 48%;
  display: inline-block;
  margin: 8px 0;
  border: 1px solid #de574f;
}
:host::ng-deep .hide {
  display: none;
}
:host::ng-deep .vessel-name {
  font-size: 11px;
  color: #888888;
}
:host::ng-deep a:link,
:host::ng-deep a :visited {
  text-decoration: none;
  color: #3486b5;
}
:host::ng-deep .info-heading {
  font-size: 11px;
  color: #888888;
  margin-left: 16px;
  margin-right: 4px;
}
@media (max-width: 767.98px) {
  :host::ng-deep .info-heading {
    margin: 0;
    width: 50%;
    display: inline-block;
  }
}
:host::ng-deep .info-value {
  font-size: 11px;
  color: #282828;
}
@media (max-width: 767.98px) {
  :host::ng-deep .info-value {
    width: 50%;
    display: inline-block;
  }
}
:host::ng-deep .vesl-name {
  max-width: 400px;
  font-size: 26px;
  color: #282828;
}
:host::ng-deep .input-field {
  padding: 10px;
  text-align: center;
  height: 43px;
  width: 50px;
  margin-left: -6%;
  opacity: 0;
  border: 0;
}
:host::ng-deep .save-button {
  background: #64b2d4;
  color: #ffffff;
  height: 23px;
  width: 60px;
  border: 1px solid #ffffff;
  margin-left: 2px;
  cursor: pointer;
}
:host::ng-deep .cancel-button {
  height: 23px;
  width: 60px;
  border-radius: 0px;
  margin-left: 2px;
  background: #ffffff;
  color: #64b2d4;
  border: 1px solid #64b2d4;
  cursor: pointer;
}
:host::ng-deep .tabs {
  margin-top: 24px;
}
:host::ng-deep .tabs-expand-list {
  box-shadow: none !important;
}
:host::ng-deep .tabs-expand-list .mat-content {
  justify-content: center;
  color: #003e5e;
}
:host::ng-deep .tabs-expand-list .mat-expansion-panel-header {
  position: relative;
}
:host::ng-deep .tabs-expand-list .mat-expansion-panel-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #003e5e;
}
:host::ng-deep .tabs .vessel-details .disabled {
  background: #ebebe4 !important;
  margin: 0 -16px;
  opacity: 0.6;
  pointer-events: none;
}
:host::ng-deep .mat-mdc-card {
  display: block;
  position: relative;
  margin-top: 12px;
  padding: 8px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
:host::ng-deep .mat-mdc-card-header .mat-mdc-card-title {
  font-weight: bold;
  font-size: 12px !important;
}
:host::ng-deep .mat-mdc-card-header-text {
  margin: 0 !important;
  padding: 8px 0;
}
:host::ng-deep .mat-mdc-card-title {
  margin-bottom: 0 !important;
}
:host::ng-deep .mat-mdc-link-bar {
  background-color: #003e5e !important;
}
:host::ng-deep .mat-mdc-tab-header {
  border-bottom: 2px solid #dadada !important;
}
:host::ng-deep .vesseldetailcard {
  max-width: 720px;
  float: left;
  margin-bottom: 16px;
  margin-top: 12px;
}
:host::ng-deep .value {
  display: inline-block;
  width: 50%;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .value a {
  text-decoration: none;
}
:host::ng-deep .value3 {
  color: #888888;
  margin-left: 8px;
  width: 61px;
  display: inline-block;
}
:host::ng-deep .overviewfieldvalue {
  display: inline-block;
  width: 50%;
  color: #282828;
  padding-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .overviewfieldvalue a {
  text-decoration: none;
}
:host::ng-deep .pluginholdheading {
  width: 34%;
  display: inline-block;
  overflow: hidden;
  color: #888888;
}
:host::ng-deep .maxheightkeelheading {
  width: 57%;
  display: inline-block;
  overflow: hidden;
  color: #888888;
}
:host::ng-deep .maxheightkeelvalue {
  display: inline-block;
  width: 15%;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .maxheightkeelvalue a {
  text-decoration: none;
}
:host::ng-deep .pluginholdvalue {
  display: inline-block;
  width: 15%;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .pluginholdvalue a {
  text-decoration: none;
}
:host::ng-deep .fix-border {
  border: 1px solid #888888 !important;
}
:host::ng-deep .fix-presence {
  color: #888888;
  padding-left: 3%;
  padding-bottom: 2%;
  font-weight: bold;
}
:host::ng-deep .fix-status {
  width: 11%;
  padding-left: 3%;
}
:host::ng-deep .fix-status-val {
  width: 11%;
  padding-left: 2%;
}
:host::ng-deep .fix-id {
  width: 14%;
}
:host::ng-deep .fix-idheader {
  width: 14%;
  padding-left: 4px;
}
:host::ng-deep .fix-code {
  width: 13%;
}
:host::ng-deep .fix-rateheader {
  width: 14%;
  padding-left: 5px;
}
:host::ng-deep .fix-rate {
  width: 14%;
}
:host::ng-deep .fix-del {
  width: 13%;
}
:host::ng-deep .fix-redel {
  width: 35%;
}
:host::ng-deep .heading-fix {
  color: #888888;
}
:host::ng-deep .fix-rates-status {
  width: 20%;
  padding-left: 2%;
}
:host::ng-deep .fix-rates {
  width: 20%;
  text-align: start;
}
:host::ng-deep .fix-rates-status-val {
  width: 16%;
}
:host::ng-deep .fix-rates-start {
  width: 21%;
}
:host::ng-deep .fix-rates-end {
  width: 25%;
}
:host::ng-deep .fix-rates-rate {
  width: 19%;
}
:host::ng-deep .heading {
  position: relative;
  width: 50%;
  display: inline-block;
  overflow: hidden;
  color: #888888;
}
:host::ng-deep .heading .show-more-icon,
:host::ng-deep .heading .show-less-icon {
  vertical-align: middle;
}
:host::ng-deep .heading img {
  height: 15px;
  width: 15px;
}
:host::ng-deep .left {
  float: left;
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 0;
}
:host::ng-deep .left.w-100 {
  width: 100%;
}
:host::ng-deep .colmn {
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 0;
}
:host::ng-deep .colmn.show {
  cursor: pointer;
  border: 0;
}
:host::ng-deep .leftdel {
  width: 336px;
  float: left;
  height: 21px;
  padding-top: 10px;
}
:host::ng-deep .value.cur {
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 16px;
}
:host::ng-deep .value.cur.cursor-text {
  cursor: text !important;
}
:host::ng-deep .value.cur .show-more-icon {
  position: absolute;
  vertical-align: middle;
  right: 0;
}
:host::ng-deep .value.cur .show-more-icon img {
  height: 15px;
  width: 24px;
}
:host::ng-deep .value.vessel {
  width: 78%;
}
:host::ng-deep .value.contact {
  width: 76%;
}
:host::ng-deep .show-more-icon img {
  height: 15px;
  width: 24px;
}
:host::ng-deep .owner-show {
  border-left: 4px solid #f0f0f0;
  padding-left: 12px;
  margin: 5px 0;
}
:host::ng-deep .company-address {
  max-width: 90px;
  line-height: 16px;
  padding-top: 10px;
  color: #282828;
}
:host::ng-deep .company-name {
  font-size: 14px;
  font-weight: bold;
  color: #282828;
}
:host::ng-deep span.contact-detail {
  width: 64px;
  display: inline-block;
  color: #888888;
}
:host::ng-deep span.contact-value {
  color: #282828;
  width: 234px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep span.contact-value a {
  text-decoration: none;
}
:host::ng-deep .cont-det {
  padding-top: 15px;
}
:host::ng-deep .icon-img {
  right: 0;
  bottom: -2px;
  position: absolute;
  margin-right: 7px;
}
:host::ng-deep .value2 {
  display: inline-block;
  text-align: right;
}
:host::ng-deep .mat-ink-bar {
  background: #003e5e !important;
}
:host::ng-deep .vesl-info .info-heading:first-child {
  margin-left: 0;
}
:host::ng-deep .mat-tab-label,
:host::ng-deep .mat-mdc-tab-link {
  height: 40px !important;
  min-width: 138px !important;
  color: #003e5e;
  opacity: none !important;
  opacity: unset !important;
}
:host::ng-deep .mat-tab-label-active,
:host::ng-deep .mat-mdc-tab-link-active {
  background: #ffffff;
  border-bottom: 2px solid #003e5e;
  font-weight: bold;
}
:host::ng-deep .mdc-tab--active {
  background: #ffffff;
  font-weight: bold;
}
:host::ng-deep .mat-mdc-tab-header {
  border-bottom: 2px solid #dadada !important;
}
:host::ng-deep .vessel-fixtures {
  font-size: 12px !important;
  margin-top: 16px;
}
:host::ng-deep .vessel-fixtures-title {
  margin-left: 12px;
  color: #282828;
  height: 32px;
}
:host::ng-deep .vessel-fixtures-title .show-more-icon {
  right: auto;
  right: unset;
  vertical-align: middle;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel {
  letter-spacing: 0.4;
  margin-top: 16px;
  box-shadow: none !important;
  box-shadow: unset !important;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel:first-child {
  margin: 0;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header {
  height: 32px !important;
  padding: 0 16px;
  position: relative;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header.mat-expanded {
  border-bottom: 1px solid #f0f0f0;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator {
  bottom: 6px;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator:after {
  transform: rotate(225deg);
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .mat-content {
  display: auto;
  display: unset;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .mat-content .row {
  flex-grow: 1;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .mat-expansion-indicator {
  animation: none !important;
  -moz-animation: none !important;
  -webkit-animation: none !important;
  transform: none !important;
  position: absolute;
  bottom: 8px;
  right: 10px;
  margin-top: -2px;
  width: 15px;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .mat-expansion-indicator:after {
  padding: 2px;
  border-width: 0 1px 1px 0;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture > div[class*=col-] .row > div[class*=col-] {
  height: 32px;
  padding: 8px 0;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture > div[class*=col-] .row > div[class*=col-]:first-child {
  padding-left: 15px;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture-id {
  color: #266d8c;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture span[class*=fixture-] {
  display: inline-block;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture span[class*=fixture-] .service {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 75%;
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-header .fixture span[class*=fixture-] .posn {
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  :host::ng-deep .vessel-fixtures .mat-expansion-panel-header {
    height: auto !important;
    height: unset !important;
  }
}
:host::ng-deep .vessel-fixtures .mat-expansion-panel-body {
  padding: 0 16px 16px;
}
:host::ng-deep .vessel-details .mat {
  padding-left: 16px;
}
:host::ng-deep .vessel-details .mat-expansion-indicator {
  position: absolute;
}
:host::ng-deep .vessel-details .mat-expansion-indicator:after {
  border-width: 2px 2px 0 0;
}
:host::ng-deep .vessel-details .mat-expansion-panel {
  box-shadow: none;
  background: rgba(224, 224, 224, 0.15);
  margin: 4px 0;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header {
  height: 40px !important;
  padding: 0 16px;
  position: relative;
  background: #003e5e;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header:not([aria-disabled=true]) {
  background: #003e5e;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #003e5e;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header-title {
  color: #ffffff;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator:after {
  border-width: 2px 0 0 2px;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header .mat-content {
  padding-left: 16px;
}
:host::ng-deep .vessel-details .mat-expansion-panel-header .mat-expansion-indicator:after {
  color: #ffffff;
}
:host::ng-deep .vessel-details .mat-expansion-panel-body {
  padding: 0 16px 8px;
}
:host::ng-deep .mat-accordion {
  margin-top: 10px;
}
@media (min-width: 989px) {
  :host::ng-deep .vesl-info {
    text-align: right;
    margin-top: auto;
    margin-bottom: 5px;
    float: right;
  }
}
:host::ng-deep .lnk-new-dep {
  float: right;
  padding-left: 10px;
}
:host::ng-deep .pad-left {
  padding-left: 8px;
}
:host::ng-deep .value-deployment {
  display: inline-block;
  width: 170px;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .value-deployment a {
  text-decoration: none;
}
:host::ng-deep .value-deployment2 {
  display: inline-block;
  width: 50px;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host::ng-deep .value-deployment2 a {
  text-decoration: none;
}
:host::ng-deep .text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px !important;
}
:host::ng-deep .auto-height {
  height: auto !important;
}
:host::ng-deep .break-word {
  word-wrap: break-word;
}
:host::ng-deep .text-grey {
  color: #888888;
}
:host::ng-deep champs-vessel-redesign-deployment .invalid-feedback {
  border-bottom: 1px solid #ff0000;
  border-top: 1px solid red;
  margin-top: 13px;
  color: red;
}
:host::ng-deep champs-vessel-redesign-deployment .invalid-feedback.dep-position-top {
  margin-top: -14.5px !important;
}
:host::ng-deep svg {
  fill: currentColor;
  color: red;
}
:host::ng-deep svg:hover {
  color: blue;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
