//

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Component({
  selector: 'champs-vessel-import',
  templateUrl: './vessel-import.component.html',
  styleUrls: ['./vessel-import.component.scss']
})
export class VesselImportComponent implements OnInit {

  public loading: boolean = false;
  public error;

  constructor(
    private vesselsService: VesselsService,
    private router: Router,
    private alertService: AlertService
  ) { }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() { }

  /**
   * file changed in the control
   * @param {any} e
   */
  public fileChanged(e: any) {
    this.alertService.clear();
    const formData: FormData = new FormData();
    formData.append('file', e);
    this.loading = true;
    this.vesselsService.postUploadCompare(formData).subscribe(
      () => {
        this.loading = false;
        this.router.navigate(['/vessels/import/compare'], {
          skipLocationChange: true
        });
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          const error = err.error && err.error['error'] ? err.error['error'] : null;
          if (error) {
            this.alertService.error(error);
          }
        }
      }
    );
  }

}
