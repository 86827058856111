// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep {
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
}
:host::ng-deep .schedule-overview-detail .text-grey {
  color: #888888;
}
:host::ng-deep .schedule-overview-detail .header {
  color: #282828 !important;
}
:host::ng-deep .schedule-overview-detail .header .address {
  padding: 12px 0;
}
:host::ng-deep .schedule-overview-detail .header .address a {
  color: #282828 !important;
}
:host::ng-deep .schedule-overview-detail .import-box {
  border: 1px solid #ff9b1e;
  background-color: #fff6d2;
  margin-left: 0;
  margin-right: 0;
}
:host::ng-deep .schedule-overview-detail .import-box-span {
  width: 33%;
  padding: 12px;
}
:host::ng-deep .schedule-overview-detail .import-box .cancel {
  width: 10%;
  display: inline-block;
  padding: 12px 0;
}
:host::ng-deep .schedule-overview-detail .import-box .link {
  color: #007bff;
  margin-left: 52%;
}
:host::ng-deep .schedule-overview-detail .import-box .import-button {
  background-color: #64b2d4;
  border-radius: 5px;
  width: 18%;
  color: #ffffff;
  height: 30px;
  padding-top: 5px;
  text-align: center;
  position: relative;
  top: 3px;
}
:host::ng-deep .schedule-overview-detail .import-box .ignore-button {
  background-color: #bbbbbb;
  margin-left: 8px;
  border-radius: 5px;
  width: 18%;
  color: #ffffff;
  height: 30px;
  padding-top: 5px;
  text-align: center;
  position: relative;
  top: 3px;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header {
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  margin: 0;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .ser-value {
  display: inline-block;
  padding-top: 5px;
  width: 100%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field {
  display: block;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field .mat-form-field-wrapper {
  padding-bottom: 0.25em;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field .mat-select-arrow {
  color: #282828;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field-flex {
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #888888;
  width: 75%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field-infix {
  border: 0;
  width: 100%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field-should-float {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .mat-mdc-form-field-should-float .mat-form-field-label {
  display: none;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .detail-name {
  color: #888888;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .detail-value {
  color: #282828;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .gsis-col {
  width: 12%;
  padding-left: 1%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .service-col {
  width: 25%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .proforma-id-col {
  width: 21%;
  padding-left: 1%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .vaild-from-col {
  width: 10%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .vaild-to-col {
  width: 10%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .roundtrip-col {
  width: 10%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .vessel-col {
  width: 10%;
}
:host::ng-deep .schedule-overview-detail .sch-overview-header .approved-col {
  width: 10%;
}
:host::ng-deep .schedule-overview-detail span.span-without-icon {
  position: absolute;
  left: 6%;
}
:host::ng-deep .schedule-overview-detail span.span-with-icon {
  position: absolute;
  left: 6%;
}
:host::ng-deep .schedule-overview-detail span.material-icons.text-green.ng-star-inserted {
  position: absolute;
  top: 8px;
  left: 1px;
}
:host::ng-deep .schedule-overview-detail .text-green {
  color: #008000;
  font-size: 1.6em;
  font-weight: bold;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter {
  width: 100%;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  zoom: 1;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container {
  min-height: 24px;
  background-color: #ffffff;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container::after {
  content: none !important;
  display: inline-block;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-dropdown-panel.ng-select-bottom {
  top: -110px !important;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}
:host::ng-deep .schedule-overview-detail .ng-select.filter .ng-dropdown-panel .ng-option {
  padding-left: 8px;
}
:host::ng-deep .schedule-overview-detail-chips .filter-chip {
  background-color: #64b2d4;
  color: #ffffff;
  padding: 3px 3px 4px 8px;
}
:host::ng-deep .schedule-overview-detail-chips .filter-chip-all {
  border: 1px solid #64b2d4;
  background-color: #ffffff;
  color: #282828;
  padding: 3px 3px 4px 8px;
}
:host::ng-deep .schedule-overview-detail-chips .mat-mdc-chip-list-wrapper {
  padding: 0;
}
:host::ng-deep .schedule-overview-detail-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) {
  /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
}
:host::ng-deep .schedule-overview-detail-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) .mat-chip-remove {
  opacity: 1 !important;
}
:host::ng-deep .schedule-overview-detail-chips img {
  margin-right: 0 !important;
  fill: #ffffff;
}
:host::ng-deep .schedule-overview-detail .service-filter-header {
  background-color: #ffffff;
  margin-left: 6px;
  right: -8px;
  top: -24px;
}
:host::ng-deep .ng-select.filter {
  width: 32%;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  zoom: 1;
}
:host::ng-deep .ng-select.filter .ng-select-container {
  min-height: 24px;
  background-color: #ffffff;
}
:host::ng-deep .ng-select.filter .ng-select-container::after {
  content: none !important;
  display: inline-block;
}
:host::ng-deep .ng-select.filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .ng-select.filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .ng-select.filter .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
}
:host::ng-deep .ng-select.filter .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}
:host::ng-deep .ng-select.filter .ng-dropdown-panel .ng-option {
  padding-left: 8px;
  position: relative;
}
:host::ng-deep-chips .filter-chip {
  background-color: #64b2d4;
  color: #ffffff;
  padding: 3px 3px 4px 8px;
}
:host::ng-deep-chips .filter-chip-all {
  border: 1px solid #64b2d4;
  background-color: #ffffff;
  color: #282828;
  padding: 3px 3px 4px 8px;
}
:host::ng-deep-chips .mat-mdc-chip-list-wrapper {
  padding: 0;
}
:host::ng-deep-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) {
  /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
}
:host::ng-deep-chips .mat-mdc-chip:not(.mat-mdc-basic-chip) .mat-chip-remove {
  opacity: 1 !important;
}
:host::ng-deep-chips img {
  margin-right: 0 !important;
  fill: #ffffff;
}
:host::ng-deep .mat-radio-container {
  height: 20px;
  width: 20px;
}
:host::ng-deep .mat-radio-outer-circle {
  height: 15px;
  width: 15px;
}
:host::ng-deep .mat-radio-outer-circle.margin-top-6 {
  margin-top: 6px;
}
:host::ng-deep .mat-radio-outer-circle.margin-bottom-6 {
  margin-bottom: 6px;
}
:host::ng-deep .mat-radio-inner-circle {
  height: 15px;
  width: 15px;
}
:host::ng-deep .mat-radio-inner-circle.margin-top-6 {
  margin-top: 6px;
}
:host::ng-deep .mat-radio-inner-circle.margin-bottom-6 {
  margin-bottom: 6px;
}
:host::ng-deep .view-date {
  text-align: center;
  margin: 10px 120px;
  height: 35px;
  padding: 8px;
  border: 1px solid #888888;
}
:host::ng-deep .view-date-cut {
  text-align: center;
  margin: 10px 360px;
  height: 35px;
  padding: 8px;
  border: 1px solid #888888;
}
:host::ng-deep .text-red {
  color: #B1453F;
}
:host::ng-deep .show-loader {
  background: #ffffff;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 102%;
  z-index: 9999;
  margin-left: -25px;
}
:host::ng-deep .show-loader-img {
  margin: 275px 0;
  float: none;
  height: 30px;
}
:host::ng-deep .show-loader-img {
  margin: 275px 0;
  float: none;
  height: 30px;
}
:host::ng-deep .service-deployment-planning {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .service-deployment-planning .schedule-details .mat-form-field-suffix {
  width: 0px;
}
:host::ng-deep .service-deployment-planning .schedule-details .mat-mdc-form-field {
  width: 63px;
  color: #3486b5;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #3486b5;
}
:host::ng-deep .service-deployment-planning .schedule-details .mat-datepicker-toggle .mat-mdc-icon-button {
  position: relative;
  left: -60px;
  padding-left: 60px;
  border-radius: 0px;
  bottom: -5px;
}
:host::ng-deep .service-deployment-planning .comment-button {
  position: relative;
  bottom: 20px;
}
:host::ng-deep .service-deployment-planning .view-to-date {
  color: #3486b5;
  border-bottom: 1px solid #3486b5;
}
:host::ng-deep .service-deployment-planning .comment-box {
  background-color: white;
  height: 53px;
  width: 180px;
  resize: none;
  border: 0;
  position: relative;
  top: 3px;
}
:host::ng-deep .service-deployment-planning .comment-box:focus {
  outline-width: 0;
}
:host::ng-deep .service-deployment-planning .performa-id {
  color: #3486b5;
  cursor: pointer;
}
:host::ng-deep .service-deployment-planning .approve-title {
  position: relative;
  bottom: 18px;
  left: -185px;
  font-size: 14px;
  color: #333030;
}
:host::ng-deep .service-deployment-planning .approve-button {
  position: relative;
  bottom: 16px;
  left: -175px;
}
:host::ng-deep .service-deployment-planning .schedule-details {
  position: relative;
  bottom: 15px;
  left: -153px;
}
:host::ng-deep .service-deployment-planning .delete-button {
  position: relative;
  bottom: -30px;
  left: -278px;
  color: #3486b5;
}
:host::ng-deep .service-deployment-planning .images {
  text-align: left;
  width: 21%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .images.display-file-icon-inside-cell {
  position: absolute;
}
:host::ng-deep .service-deployment-planning .display-file-icon-inside-cell {
  position: absolute;
}
:host::ng-deep .service-deployment-planning .down-arrow-icon-on-arrival {
  margin-left: 2px;
}
:host::ng-deep .service-deployment-planning .end-date-icon {
  display: inline-flex;
  position: absolute;
  margin-left: 4px;
}
:host::ng-deep .service-deployment-planning .comment-icon + .end-date-icon {
  margin-left: 15px;
}
:host::ng-deep .service-deployment-planning .mat-mdc-form-field.deploypicker.mat-form-field {
  width: 0%;
  border: 0;
}
:host::ng-deep .service-deployment-planning .togglepicker svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  display: none;
}
:host::ng-deep .service-deployment-planning .comment-popup {
  width: 350px;
  height: auto;
  padding: 10px;
  margin-left: 450px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: #ffffff;
  color: #282828;
  position: absolute;
  z-index: 1001;
  margin-top: 11px;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .comment-popup::before {
  content: "";
  background: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: 50px;
  margin-top: -20px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  transform: rotate(45deg);
}
:host::ng-deep .service-deployment-planning .div-drag-popup {
  opacity: 1;
  position: absolute;
  z-index: 1000;
}
:host::ng-deep .service-deployment-planning .version-div {
  position: relative;
  top: 5px;
}
:host::ng-deep .service-deployment-planning .undo {
  position: relative;
  left: 16px;
  color: #FFA73B;
  width: 40%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sch-dating-div {
  width: 66%;
  display: inline-block;
  margin-left: 20%;
}
:host::ng-deep .service-deployment-planning .new-draft {
  position: relative;
  top: 20px;
}
:host::ng-deep .service-deployment-planning .sub-header {
  border: 1px solid #eaeaea;
  padding: 4px 0;
}
:host::ng-deep .service-deployment-planning .sub-header .gap-span {
  width: 125px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sub-header .date-span {
  width: 174px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sub-header .gap-value {
  padding-left: 10px;
}
:host::ng-deep .service-deployment-planning .sub-header .roundtrip-span {
  width: 100px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sub-header .vessel-span {
  width: 65px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sub-header .approved-span {
  width: 115px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .sub-header .delete-btn {
  color: #ffffff;
  background-color: #FF0000;
  text-align: center;
  display: inline-block;
  width: 61%;
  height: 19px;
  border-radius: 3px;
}
:host::ng-deep .service-deployment-planning .sub-header .mat-mdc-form-field {
  display: inline-block;
  width: 58%;
  border: 0.5px solid #888888;
  padding: 0 2px;
  max-height: 24px;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .service-deployment-planning .sub-header .mat-mdc-form-field .mat-form-field-wrapper {
  padding: 0 !important;
  max-height: 24px;
  border: 0;
}
:host::ng-deep .service-deployment-planning .sub-header .mat-mdc-form-field .mat-form-field-wrapper .mat-mdc-form-field-flex {
  max-height: 24px;
  border: 0;
  padding: 0;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .service-deployment-planning .sub-header .mat-mdc-form-field .mat-form-field-wrapper .mat-mdc-form-field-flex .mat-form-field-infix {
  border: 0;
  height: 24px;
}
:host::ng-deep .service-deployment-planning .sub-header .mat-mdc-form-field .mat-form-field-wrapper .mat-mdc-form-field-underline {
  display: none;
}
:host::ng-deep .service-deployment-planning .cut-left {
  display: inline-block;
  width: 15%;
}
:host::ng-deep .service-deployment-planning .cut-right {
  display: inline-block;
  width: 15%;
}
:host::ng-deep .service-deployment-planning .cut-here-span {
  color: #ffffff;
  background-color: #FFA73B;
  display: inline-block;
  width: 100px;
  height: auto;
  padding: 5px;
  position: absolute;
  z-index: 99;
  text-align: center;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .delete-btn-gray {
  color: #ffffff;
  background-color: #bbbbbb;
  text-align: center;
  display: inline-block;
  width: 61%;
  height: 19px;
  border-radius: 3px;
}
:host::ng-deep .service-deployment-planning .use-dating-btn {
  padding-top: 3px;
}
:host::ng-deep .service-deployment-planning .use-dating-btn-left {
  width: 42%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .use-dating-btn-right {
  width: 42%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .use-dating-btn-centre {
  color: #ffffff;
  background-color: #003e5e;
  width: 16%;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-top: 5px;
  height: 26px;
}
:host::ng-deep .service-deployment-planning .use-dating-btn-disabled {
  cursor: not-allowed;
  background: rgb(242, 242, 242);
  color: #282828;
  width: 16%;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-top: 5px;
  height: 26px;
  padding-top: 3px;
}
:host::ng-deep .service-deployment-planning .mat-mdc-tooltip {
  color: #FFA73B;
}
:host::ng-deep .service-deployment-planning .mat-mdc-slide-toggle .mat-checked:not(.mat-disabled) {
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
}
:host::ng-deep .service-deployment-planning .mat-mdc-slide-toggle .mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #2fc608;
}
:host::ng-deep .service-deployment-planning .import-limit {
  padding-top: 5px;
  margin-left: 0;
  margin-right: 0;
  border: 1px solid #ff9b1e;
  background-color: #fff6d2;
}
:host::ng-deep .service-deployment-planning .import-limit .left {
  width: 35%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .import-limit .centre {
  width: 30%;
  padding: 12px 5px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .import-limit .right {
  width: 35%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .start-here1 {
  background-color: #FFA73B;
  padding-top: 24px;
}
:host::ng-deep .service-deployment-planning .start-here1 .text {
  color: #ffffff;
  text-align: center;
  display: inline-block;
  width: 100%;
}
:host::ng-deep .service-deployment-planning .select-start-here-border {
  border: 1px solid #FFA73B;
}
:host::ng-deep .service-deployment-planning .start-here {
  background-color: #FFA73B;
  width: 100px;
  height: auto;
  padding: 5px;
  position: absolute;
  z-index: 99;
  text-align: center;
  overflow-wrap: break-word;
  color: #ffffff;
}
:host::ng-deep .service-deployment-planning .start-here .text {
  display: inline-block;
  width: 100%;
}
:host::ng-deep .service-deployment-planning .ellipsis-span {
  display: inline-block;
  width: 27%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
:host::ng-deep .service-deployment-planning .fixed {
  position: -webkit-sticky;
  top: 0;
}
:host::ng-deep .service-deployment-planning .vessel-popup {
  width: 350px;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: #ffffff;
  color: #282828;
  position: absolute;
  z-index: 1001;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .vessel-popup::before {
  content: "";
  background: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -20px;
  margin-top: 7px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  transform: rotate(-45deg);
}
:host::ng-deep .service-deployment-planning .dummy-popup {
  width: 490px;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: #ffffff;
  color: #282828;
  position: absolute;
  z-index: 1001;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .dummy-popup::before {
  content: "";
  background: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -20px;
  margin-top: 7px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  transform: rotate(-45deg);
}
:host::ng-deep .service-deployment-planning .vessel-dep-popup {
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: #ffffff;
  color: #282828;
  position: absolute;
  z-index: 1000;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .vessel-dep-popup .ng-dropdown-panel {
  top: 20px !important;
}
:host::ng-deep .service-deployment-planning .vessel-dep-popup .float-header {
  display: flex;
  justify-content: space-between;
}
:host::ng-deep .service-deployment-planning .vessel-dep-popup .float-header .close-icon:hover {
  background-color: gainsboro;
  cursor: pointer;
}
:host::ng-deep .service-deployment-planning .vessel-dep-popup::before {
  content: "";
  background: #ffffff;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -20px;
  margin-top: 7px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  transform: rotate(-45deg);
}
:host::ng-deep .service-deployment-planning .extend-dummy-popup {
  width: 250px;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: rgb(242, 242, 242);
  color: #282828;
  position: absolute;
  z-index: 1001;
  overflow-wrap: break-word;
}
:host::ng-deep .service-deployment-planning .extend-dummy-popup::before {
  content: "";
  background: rgb(242, 242, 242);
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -20px;
  margin-top: 7px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  transform: rotate(-45deg);
}
:host::ng-deep .service-deployment-planning .top-border {
  border-left: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
  cursor: pointer;
}
:host::ng-deep .service-deployment-planning-header {
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  margin: 0;
}
:host::ng-deep .service-deployment-planning-header .pen {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
:host::ng-deep .service-deployment-planning .table {
  border: 1px solid #cccccc;
  white-space: nowrap;
  padding-bottom: 10px;
}
:host::ng-deep .service-deployment-planning .table-heading {
  padding: 10px 0;
  color: #888888;
  background-color: #ffffff;
}
:host::ng-deep .service-deployment-planning .table-values {
  padding: 0;
}
:host::ng-deep .service-deployment-planning .vessel-name {
  display: inline-block;
  width: 100%;
  padding-bottom: 0;
}
:host::ng-deep .service-deployment-planning .vessel-name-ellipsis-span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
:host::ng-deep .service-deployment-planning .vessel-name .strips-grey2:first-child {
  height: 35px;
}
:host::ng-deep .service-deployment-planning .material-icons {
  font-size: 1rem;
  position: relative;
  top: 3px;
}
:host::ng-deep .service-deployment-planning .link-position {
  top: -14px;
  left: 70px;
  font-size: 1.4rem !important;
}
:host::ng-deep .service-deployment-planning .port-name {
  width: 50%;
  display: inline-block;
  position: relative;
  margin-left: -6px;
}
:host::ng-deep .service-deployment-planning .port-icons {
  width: 8%;
  float: left;
  height: 55px;
}
:host::ng-deep .service-deployment-planning .port-icons .material-icons {
  font-size: 0.8rem;
}
:host::ng-deep .service-deployment-planning .port-icons .height {
  height: 14px;
}
:host::ng-deep .service-deployment-planning .port-name-span {
  font-size: 10px;
  color: #888888;
  margin-top: 3px;
}
:host::ng-deep .service-deployment-planning .cutvessel-on-port {
  padding-left: 35px;
}
:host::ng-deep .service-deployment-planning .time-mid-span {
  height: 15px;
  display: inline-block;
  width: 100%;
}
:host::ng-deep .service-deployment-planning .port-time {
  width: 40%;
  display: inline-block;
  text-align: left;
  color: #888888;
  white-space: normal;
}
:host::ng-deep .service-deployment-planning .port-time .port-time-start {
  position: absolute;
  top: 0;
}
:host::ng-deep .service-deployment-planning .port-time .port-time-end {
  position: absolute;
  bottom: 0;
}
:host::ng-deep .service-deployment-planning .port-time .end {
  padding-top: 23px;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .subheader-action-icons {
  color: #3486b5;
  cursor: pointer;
}
:host::ng-deep .service-deployment-planning .padding-left24 {
  padding-left: 24px;
}
:host::ng-deep .service-deployment-planning .padding-left40 {
  padding-left: 40px;
}
:host::ng-deep .service-deployment-planning .position-heading {
  width: 80%;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .position-heading .material-icons {
  cursor: pointer;
  font-size: 1.2rem;
  position: relative;
  top: 5px;
}
:host::ng-deep .service-deployment-planning .position-heading .import-form {
  background: #64b2d4;
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 8px;
}
:host::ng-deep .service-deployment-planning .position-heading .comment-cancel {
  margin-left: 12px;
  color: #2996bb;
}
:host::ng-deep .service-deployment-planning .position-heading .border-bottom {
  border-bottom: 1px solid #f0f0f0;
}
:host::ng-deep .service-deployment-planning .position-heading .position-padding {
  width: 136px;
  display: inline-block;
  margin-left: 2px;
  white-space: normal;
}
:host::ng-deep .service-deployment-planning .arrival-span-omit {
  display: inline-block;
  width: 70%;
  color: #888888;
  font-size: 0.68rem !important;
}
:host::ng-deep .service-deployment-planning .img-span-omit {
  display: inline-block;
  width: 40%;
  text-align: right;
}
:host::ng-deep .service-deployment-planning .date-arrival {
  text-align: left;
  display: inline-block;
  width: 100%;
}
:host::ng-deep .service-deployment-planning .date-arrival .arrival-span {
  display: inline-block;
  color: #888888;
}
:host::ng-deep .service-deployment-planning .date-arrival .blue-background {
  background-color: rgba(0, 0, 0, 0.5);
}
:host::ng-deep .service-deployment-planning .date-arrival .img-span {
  display: inline-block;
  text-align: right;
}
:host::ng-deep .service-deployment-planning .date-arrival .img-span-disable-icon {
  fill: gray;
  cursor: not-allowed;
  pointer-events: none;
}
:host::ng-deep .service-deployment-planning .mid-span {
  display: inline-block;
  width: 7%;
  z-index: 9999;
  margin-right: -22%;
  margin-left: -25%;
}
:host::ng-deep .service-deployment-planning .img-omit {
  display: inline-block;
  width: 38%;
  text-align: left;
}
:host::ng-deep .service-deployment-planning .strips-grey {
  opacity: 1 !important;
}
:host::ng-deep .service-deployment-planning .strips-grey2 {
  position: absolute;
  background: url('boat.svg') center right no-repeat;
  width: 17px;
  height: 17px;
  z-index: 9;
  opacity: 0.75;
}
:host::ng-deep .service-deployment-planning .grey-color {
  background-color: hsla(0, 0%, 74%, 0.5);
}
:host::ng-deep .service-deployment-planning .black-color {
  color: #282828;
}
:host::ng-deep .service-deployment-planning .departure-span-omit {
  display: inline-block;
  width: 70%;
  color: #888888;
  font-size: 0.68rem !important;
}
:host::ng-deep .service-deployment-planning .padding-top10 {
  padding-top: 10px !important;
}
:host::ng-deep .service-deployment-planning .padding-left22 {
  padding-left: 22px;
}
:host::ng-deep .service-deployment-planning .extra-position-icon {
  position: absolute;
  margin-top: -15px;
  margin-left: -30px;
}
:host::ng-deep .service-deployment-planning .date-departure {
  text-align: right;
  display: inline-block;
  width: 100%;
  padding-top: 23px;
  color: #888888;
}
:host::ng-deep .service-deployment-planning .date-departure .departure-span {
  display: inline-block;
  z-index: 9 !important;
  width: 77%;
  color: #888888;
  font-size: 0.73rem;
  cursor: default;
}
:host::ng-deep .service-deployment-planning .date-departure .blue-background {
  background-color: rgba(0, 0, 0, 0.5);
}
:host::ng-deep .service-deployment-planning .date-departure .img {
  padding-right: 5px;
}
:host::ng-deep .service-deployment-planning .date-departure .float-right {
  float: right;
}
:host::ng-deep .service-deployment-planning .service-lable {
  width: 25%;
  display: inline-block;
  color: #888888;
}
:host::ng-deep .service-deployment-planning .gray-color {
  color: #888888;
}
:host::ng-deep .service-deployment-planning .service-name {
  width: 73%;
  display: inline-block;
  text-align: right;
}
:host::ng-deep .service-deployment-planning .visible-date-heading {
  width: 210px;
  display: inline-block;
  padding-left: 2px;
  color: #3486b5;
  background: #ffffff;
  z-index: 999;
  margin-top: -2px;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
:host::ng-deep .service-deployment-planning .visible-date-heading .import-form {
  background: #64b2d4;
  padding: 4px 8px;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 8px;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .comment-cancel {
  margin-left: 12px;
  color: #2996bb;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .border-bottom {
  border-bottom: 1px solid #f0f0f0;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .mat-form-field-suffix {
  width: 0px;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .mat-mdc-form-field {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  width: 80px;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .mat-mdc-form-field.to-date {
  color: #3486b5;
  border-bottom: 1px solid #3486b5 !important;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .mat-mdc-form-field.planner-readonly-date-feild {
  border: 0 !important;
  width: 95px !important;
}
:host::ng-deep .service-deployment-planning .visible-date-heading .mat-datepicker-toggle .mat-mdc-icon-button {
  position: relative;
  left: -62px;
  padding-left: 60px;
  border-radius: 0px;
  bottom: -5px;
}
:host::ng-deep .service-deployment-planning .ng-select.filter {
  width: 100%;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
  zoom: 1;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container {
  min-height: 24px;
  background-color: #ffffff;
  opacity: 1;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container::after {
  content: none !important;
  display: inline-block;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
}
:host::ng-deep .service-deployment-planning .ng-select.filter .ng-dropdown-panel .ng-option {
  padding-left: 8px;
}
:host::ng-deep .service-deployment-planning .disabled {
  pointer-events: none;
}
:host::ng-deep .service-deployment-planning .port-label {
  width: 138px;
  display: inline-block;
  position: sticky;
  z-index: 999;
  /*below line has been commented to remove additional left space on side nav collapse*/
  background: #ffffff;
}
:host::ng-deep .service-deployment-planning .grey {
  background: #eaeaea;
}
:host::ng-deep .service-deployment-planning .white {
  background: #ffffff;
}
:host::ng-deep .service-deployment-planning .mat-elevation-z5 {
  position: sticky;
  z-index: 1000;
}
:host::ng-deep .service-deployment-planning .sticky {
  position: sticky;
  top: 40px;
  width: 81%;
  z-index: 999;
}
:host::ng-deep .exeption-error {
  color: #FF0000;
}
:host::ng-deep .request-popup {
  width: 200%;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  color: #282828;
  background-color: #ffffff;
  position: absolute;
  z-index: 99;
  text-align: left;
  overflow-wrap: break-word;
}
:host::ng-deep .scroll-buttons {
  position: fixed !important;
  display: inline-block;
  background: #64b2d4;
  margin: 0 4px;
  padding: 8px 4px;
  width: 75px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  z-index: 99999;
  bottom: 40px;
  right: 40px;
}
:host::ng-deep .scroll-buttons-left {
  position: absolute;
  left: 5px;
}
:host::ng-deep .scroll-buttons-right {
  position: absolute;
  right: 5px;
}
:host::ng-deep .show-round-trip-ends {
  border-top: 3px solid #64b2d4;
}
:host::ng-deep .hidden-port-comment-popup {
  width: 238px;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  right: 0;
  background-color: #fff6d2;
  color: #282828;
  position: absolute;
  z-index: 99;
  text-align: left;
  white-space: pre-line;
  overflow-wrap: break-word;
}
:host::ng-deep .ng-select.ports-filter {
  width: 100%;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #ededed;
  padding: 0 !important;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1px;
  zoom: 1;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container {
  min-height: 33px;
  background-color: #ffffff;
  opacity: 2;
  z-index: 9999;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container::after {
  content: none !important;
  display: inline-block;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container > div {
  padding: 4px 3px;
  margin: 0;
  height: 22px;
  border: 0;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-input {
  bottom: inherit;
  padding: 0 4px;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-input input {
  cursor: text;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-placeholder {
  bottom: 4px;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-value {
  display: none;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-arrow-wrapper {
  display: none;
}
:host::ng-deep .ng-select.ports-filter .ng-select-container .ng-clear-wrapper {
  display: none;
}
:host::ng-deep .ng-select.ports-filter .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
  width: 305px;
}
:host::ng-deep .ng-select.ports-filter .ng-dropdown-panel.ng-select-top {
  width: 305px;
  bottom: 100%;
}
:host::ng-deep .ng-select.ports-filter .ng-dropdown-panel.ng-select .typeahead.opened .ng-placeholder {
  display: block !important;
}
:host::ng-deep .ng-select.ports-filter .ng-dropdown-panel.ng-select .typeahead.opened .ng-value {
  display: block !important;
}
:host::ng-deep .ng-select.ports-filter .ng-dropdown-panel .ng-option {
  padding-left: 8px;
}
:host::ng-deep .port-filter-input-box {
  width: 245px;
  display: inline-block;
  position: sticky;
  z-index: 9999;
  /*commenting to remove left space on side nav collapse*/
  background: #ffffff;
  color: #ededed;
  border-radius: 4px;
  border: 1px solid #888888;
  box-shadow: inset 1px 2px 8px #888888;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.45;
  outline: none;
  transition: 0.18s ease-out;
}
:host::ng-deep .vessel-name > .vessel-name-ellipsis-span {
  font-size: 0.6rem !important;
  text-overflow: clip !important;
  padding-top: 4px;
}
:host::ng-deep .boat-icon-overlap + .vessel-name-div .vessel-name > .vessel-name-ellipsis-span {
  margin-left: 16px !important;
  width: 87% !important;
  font-size: 0.6rem !important;
  text-overflow: clip !important;
  padding-top: 4px;
}
:host::ng-deep .arrival-span {
  font-size: 0.69rem !important;
}
:host::ng-deep .departure-span {
  font-size: 0.69rem !important;
}

.planner-readonly-date-feild {
  border: 0 !important;
  width: 80px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
