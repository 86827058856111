//

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { DropdownContentService } from '../dropdown-content/dropdown-content.service';

@Injectable()
export class VesselDropdownsService {
  public vesselTeuName: any;
  public vesselTeuID: any;

  public url = environment.baseUrl + '/api/VesselManagement/VesselDetailDropdowns';
  public urlCommOwners = environment.baseUrl + '/api/VesselManagement/GetVesselCommercialOwnerList';

  constructor(
    public dropdownContent: DropdownContentService
  ) { }

  /**
   * get the Vessel Operators
   */

  public getVesselOperators(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res ? res['opertors'] : null)
      );
  }

  /**
   * get the ITF Ftd
   */
  public getITFFtd(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['itfFitteds'])
      );
  }

  /**
   * get the Fuel ISO Years
   */
  public getFuelISOYears(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['fuelISOYears'])
      );
  }

  /**
   * get the Panamas Ftd
   */
  public getPanamasFtd(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['panamaFitteds'])
      );
  }

  /**
   * get the Vessel Owners
   */
  public getVesselOwners(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['owners'])
      );
  }

  /**
   * get the Vessel Brokers
   */
  public getVesselBrokers(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['owners']),
        filter(x => {
          return x.filter(o => 0['isBroker']);
        })
      );
  }

  /**
   * get the Fuel Types
   */
  public getMainFuelTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['mainFuelTypes'])
      );
  }

  public getAuxFuelTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['auxFuelTypes'])
      );
  }

  public getDistillateFuelTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['distillateFuelTypes'])
      );
  }

  public getHalfFuelTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['halfFuelTypes'])
      );
  }

  public getULSFuelTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['ulsFuelTypes'])
      );
  }

  /**
   * get the SurfacePrepDropDown1
   */
  public getSurfacePrepDropDown1(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['surfacePrepDropDown1'])
      );
  }

  /**
   * get the SurfacePrepDropDown2
   */
  public getSurfacePrepDropDown2(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['surfacePrepDropDown2'])
      );
  }
  /**
   * get the Vessel Comm Owners
   */
  public getVesselCommOwners(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.urlCommOwners)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }
  /**
   * get the AMP Compliant
   */
  public getAmpCompliants(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['ampCompliants'])
      );
  }

  /**
   * get the Armed Guards
   */
  public getArmedGuards(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['armedGuards'])
      );
  }

  /**
   * get the Vessel Types
   */
  public getVesselTypes(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['vesselTeuTypes'])
      );
  }

  /**
   * get the Vessel Designs
   */
  public getVesselDesigns(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['vesselDesigns'])
      );
  }

  /**
   * get the Scrubbers
   */
  public getScrubbers(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['scrubbers'])
      );
  }

  /**
   * get the War Risk Insurance
   */
  public getWarRiskInsurances(): Observable<any> {
    return this.dropdownContent.retrieveContent(this.url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res['warRiskInsurances'])
      );
  }

  /**
 * get planning group
 */
  public getPlanningGroup(): Observable<any> {
    const url = environment.baseUrl + '/api/VesselManagement/VesselPlanningGroupMapping';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }

  /**
 * get Carbon intensity indicator
 */

  public getCII(): Observable<any> {
    const url = environment.baseUrl + '/api/VesselManagement/VesselCiiMapping';
    return this.dropdownContent.retrieveContent(url)
      .pipe(
        filter(x => x !== undefined),
        map(res => res)
      );
  }
}
