// Angular
import { Component, OnInit } from '@angular/core';

// Services
import { SessionTimeoutService } from 'src/app/core/services/common/session-timeout.service';

@Component({
  selector: 'champs-reload-message-modal',
  templateUrl: './reload-message-modal.component.html',
})
export class ReloadMessageModalComponent implements OnInit {
  public counter = 20;
  public cancelSessionTimeOut;
  public reloadSession;

  constructor() {
  }

  public ngOnInit() {
    const intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
}
