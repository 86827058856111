//

/**
 * Reduce Object of array to Array of object
 * @param items object to homogeneours array
 */
export function reduceArray2Obj(items) {
  return Object.keys(items).reduce((res, val) => {
    return setValue(items, res, val, items[val].length);
  }, []);
}

/**
 * return new reduce value
 * @param items object
 * @param res current reduce value
 * @param key object key
 * @param length array at key length
 */
function setValue(items, res, key, length) {

  for (let i = 0; i < length; i++) {
    if  (res[i]) {
      res[i] = {
        ...res[i],
        [key]: items[key][i]
      };
    } else {
      res[i] = {
        [key]: items[key][i]
      };
    }
  }
  return res;
}
