// side-nav bar
import { Component, Input, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpSpinnerToggleService } from 'src/app/core/services/http-spinner-toggle/http-spinner-toggle.service';
import { AuthService } from '../../core/services/authentication/auth/auth.service';

@Component({
  selector: 'champs-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() public userInfo?: any;

  public roles: Array<any> = [];
  public isReportingRole: any;
  public isNoRole: any;
  public showSpinner$: Observable<boolean>;
  public deploymentDashboardCalled: boolean = false;
  public scenarioCalled: boolean = false;
  public loadingRoute: boolean;
  public isCursorInMenu = false;
  constructor(
    public auth: AuthService,
    httpSpinnerToggleService: HttpSpinnerToggleService,
    router: Router
  ) {
    this.showSpinner$ = httpSpinnerToggleService.showSpinner;

    router.events.subscribe((event) => {
      const url = event['url'];
      if (event['url'] !== undefined && url.indexOf('deployment-dashboard') !== -1) {
        this.deploymentDashboardCalled = true;
      } else if (event['url'] !== undefined) {
        this.deploymentDashboardCalled = false;
      }

      if (event['url'] !== undefined && url.indexOf('scenarios') !== -1) {
        this.scenarioCalled = true;
      } else if (event['url'] !== undefined) {
        this.scenarioCalled = false;
      }
      if (event instanceof RouteConfigLoadStart || event instanceof NavigationStart) {
        if (event['url'] && event['url'].split('/').length > 3) {
          return;
        }
        this.loadingRoute = true;
      } else if (
        event instanceof RouteConfigLoadEnd ||
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loadingRoute = false;
      }
    });
  }

  public ngOnInit(): void {
    if (!this.auth.BYPASS_AUTHENTICATION) {
      this.roles = this.userInfo.roles;
      if (this.auth.BYPASS_AUTHENTICATION) {
        this.isReportingRole = false;
      } else {
        this.isReportingRole = this.auth.isReportingRoleOnly(this.auth.roles, 'Reporting');
      }
    }
    // Checking if user has any role or not
    this.isNoRole = !this.auth.roles ? true : false;
  }

  public isAuthButton(module) {
    return this.auth.isAuthorised(this.roles, module, 'view');
  }

  public handleSubmenu(menuTrigger: MatMenuTrigger, action: string) {
    if (action === 'enter') {
      this.isCursorInMenu = true;
    } else {
      this.isCursorInMenu = false;
      menuTrigger.closeMenu();
    }
  }

  public handleMenuOnHover(menuTrigger: MatMenuTrigger, action: string) {
    if (action === 'open') {
      menuTrigger.openMenu();
    } else {
      setTimeout(() => {
        if (this.isCursorInMenu) {
          return;
        } else {
          menuTrigger.closeMenu();
        }
      }, 80);
    }
  }
}
