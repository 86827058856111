// custom  moment datepicker for date format
import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'D-MMM-YYYY',
  },
  display: {
    dateInput: 'D-MMM-YYYY',
    monthYearLabel: 'MMM-YYYY',
    dateA11yLabel: 'D-MMM-YYYY',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};
export const MAERSK_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM-YYYY',
    dateA11yLabel: 'D-MMM-YYYY',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

export const DATE_COMPARE_FORMAT = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD'
  }
}

export const time = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
];

/*original function*/
// export function dateTimeCompile(inputDate, inputTime) {
//   const date = new Date(inputDate);
//   if (inputDate && inputTime) {
//     const time1 = inputTime.split(':');
//     date.setHours(parseInt(time1[0], 10));
//     date.setMinutes(parseInt(time1[1], 10));
//     return convertToLocalIsoString(date.toISOString());
//   } else {
//     return null;
//   }
// }

export function dateTimeCompile(inputDate, inputTime) {
  const timezoneVal = checkTimezone();
  if (timezoneVal) {
    /*negative timezone*/
    let date = new Date(inputDate);
    const date1 = date.toISOString().split('T')[0];
    if (inputDate && inputTime) {
      const time1 = inputTime.split(':');
      const date2 = date1 + 'T' + inputTime;
      date = new Date(date2);
      return convertToLocalIsoStringNew(date2);
    } else {
      return null;
    }
  } else {
    /*positive/Asian timezone*/
    const date = new Date(inputDate);
    if (inputDate && inputTime) {
      const time1 = inputTime.split(':');
      date.setHours(parseInt(time1[0], 10));
      date.setMinutes(parseInt(time1[1], 10));
      return convertToLocalIsoString(date.toISOString());
    } else {
      return null;
    }
  }
}

export function dateTimeCompileNew(inputDate, inputTime, type) {
  const timezoneVal = checkTimezone();
  if (timezoneVal) {
    if (inputTime > '16:00' && type === 'foBeginDateTime') {
      /*negative timezone*/
      let date = new Date(inputDate);
      const newDate = date.setDate(date.getDate());
      // let newDate = date.setDate(date.getDate() - 1);
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    } else if (inputTime > '16:00' && type == null) {
      let date = new Date(inputDate);
      const newDate = date.setDate(date.getDate() - 1);
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    } else if (type === 'foEndDateTime') {
      let date = new Date(inputDate);
      let newDate = null;
      if (inputTime > '16:00') {
        // newDate = date.setDate(date.getDate() - 1);
        newDate = date.setDate(date.getDate());
      }
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    } else {
      let date = new Date(inputDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const time1 = inputTime.split(':');
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    }
  } else {
    /*positive/Asian timezone*/ const date = new Date(inputDate);
    if (inputDate && inputTime) {
      const time1 = inputTime.split(':');
      date.setHours(parseInt(time1[0], 10));
      date.setMinutes(parseInt(time1[1], 10));
      return convertToLocalIsoString(date.toISOString());
    } else {
      return null;
    }
  }
}

export function dateTimeCompileEndDate(inputDate, inputTime, type) {
  const timezoneVal = checkTimezone();
  if (timezoneVal) {
    if (type === 'foBeginDateTime') {
      let date = new Date(inputDate);
      let newDate = null;
      if (inputTime > '16:00') {
        newDate = date.setDate(date.getDate() - 1);
      } else {
        newDate = date.setDate(date.getDate());
      }
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    } else if (type == null) {
      let date = new Date(inputDate);
      let newDate = null;
      if (inputTime <= '16:00') {
        newDate = date.setDate(date.getDate());
      } else {
        newDate = date.setDate(date.getDate() - 1);
      }
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    } else {
      let date = new Date(inputDate);
      const newDate = date.setDate(date.getDate());
      date = new Date(newDate);
      const date1 = date.toISOString().split('T')[0];
      if (inputDate && inputTime) {
        const date2 = date1 + 'T' + inputTime;
        date = new Date(date2);
        return convertToLocalIsoStringNew(date2);
      } else {
        return null;
      }
    }
  } else {
    /*positive/Asian timezone*/ const date = new Date(inputDate);
    if (inputDate && inputTime) {
      const time1 = inputTime.split(':');
      date.setHours(parseInt(time1[0], 10));
      date.setMinutes(parseInt(time1[1], 10));
      return convertToLocalIsoString(date.toISOString());
    } else {
      return null;
    }
  }
}

export function customMoment(date) {
  if (date) {
    if (typeof date === 'string' && !date.includes('Z')) {
      date = date + 'Z';
    }
    if (typeof date === 'number') {
      date = convertToLocalIsoString(date);
    }
    const day = new Date(date).getUTCDate();
    const month = new Date(date).getUTCMonth();
    const year = new Date(date).getUTCFullYear();
    const result = moment.utc({ year, month, day });
    return result;
  } else {
    return null;
  }
}

/* fix for timezone issue in deployment blocks */
export function customMomentNew(date) {
  if (date) {
    if (typeof date === 'string' && !date.includes('Z')) {
      date = date + 'Z';
    }
    if (typeof date === 'number') {
      date = convertToLocalIsoString(date);
    }
    // const day = new Date(date).getUTCDate();
    const day = new Date(date).getDate();
    const month = new Date(date).getUTCMonth();
    const year = new Date(date).getUTCFullYear();
    // const result = moment.utc({ year, month, day });
    const result = moment({ year, month, day });
    return result;
  } else {
    return null;
  }
}

export function checkValidTime(inputTime) {
  const re = /^\d{1,2}:\d{2}([ap]m)?$/;

  if (inputTime !== '' && String(inputTime).match(re)) {
    return true;
  } else {
    return false;
  }
}

/*original function*/
// export function convertToLocalIsoString(UTCisoString) {
//   const date = new Date(UTCisoString);
//   return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
// }

export function convertToLocalIsoString(UTCisoString, vessleType?) {
  const date = new Date(UTCisoString);

  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
  // console.log('time is' + JSON.stringify(new Date(date.getTime())));
  // if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'America/Los_Angeles') {

  const timezoneVal = checkTimezone();
  if (timezoneVal) {
    return new Date(date.getTime()).toISOString();
  } else {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  }
}

export function convertToLocalIsoStringTime(UTCisoString) {
  const date = new Date(UTCisoString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

/*fix for timezone issue in delivery block of fixture*/
export function convertToLocalIsoStringNew(UTCisoString) {
  const date = new Date(UTCisoString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

export function checkTimezone() {
  const offset = new Date().getTimezoneOffset();
  if (Math.sign(offset) === 1) {
    return true;
  } else {
    return false;
  }
}

export function convertDateTimeToDate(dateTime) {
  return dateTime ? new Date(dateTime).setHours(0, 0, 0, 0) : null;
}

export function dateTimeCompileFixture(inputDate, inputTime) {
  const date = new Date(inputDate);
  if (inputDate && inputTime) {
    const time1 = inputTime.split(':');
    date.setHours(parseInt(time1[0], 10));
    date.setMinutes(parseInt(time1[1], 10));
    return convertToLocalIsoString(date.toISOString());
  } else {
    return null;
  }
}

/*removing hours that is getting added automatically*/
export function setZeroHours(dateTime) {
  if (dateTime) {
    const date = dateTime.split('T');
    let newDate = date[1].split(':');
    newDate.forEach(function (item, i) {
      if (item !== '00') { newDate[i] = '00'; }
    });
    newDate = date[0] + 'T' + newDate[0] + ':' + newDate[1] + ':' + (newDate[2] + '.000Z');
    return newDate;
  } else {
    return null;
  }
}

export function extractDateOnly(dateTime) {
  if (dateTime) {
    const date = dateTime.split('T');
    return date[0];
  } else {
    return null;
  }
}

@Component({
  selector: 'champs-custom-moment-datepicker',
  templateUrl: './custom-moment-datepicker.component.html',
  styleUrls: ['./custom-moment-datepicker.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }],
})
export class CustomMomentDatepickerComponent implements OnInit {
  constructor() { }

  public ngOnInit() { }
}