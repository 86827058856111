//
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRepositoryService } from '../../../service-repository/services/service-repository.service';
import { DataServiceService } from '../../../service-repository/services/data-service.service';
import { ScenariosService } from '../../../scenarios/services/scenarios.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'champs-overview-schedule-detail',
  templateUrl: './overview-schedule-detail.component.html',
  styleUrls: ['./overview-schedule-detail.component.scss'],
})
export class OverviewScheduleDetailComponent implements OnInit, OnDestroy {
  public isPrevious: any;
  public schId: any;
  public performaId: any;
  public scheduleRotation: any;
  public serviceObj: any;
  public roundtripDays: any;
  public teuCapacity: any;
  public proformaHeaderId: any;
  public id: any;
  public serviceRepo: boolean;
  public scnId: any;
  public showCurrentDeplyments: any;
  public currentDeploymentLst: any;
  public showLoading = false;
  public schDetails: any;
  public serviceCode: any;
  public serviceID: any;
  constructor(
    private serviceRepoService: ServiceRepositoryService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataServiceService,
    private scenarioService: ScenariosService,
    private titleService: Title
  ) {
    this.showCurrentDeplyments = false;
    this.schId = this.route.snapshot.paramMap.get('schId');
    this.id = this.route.parent.snapshot.paramMap.get('id');
    this.serviceRepo = this.route.parent.snapshot.paramMap.get('serviceRepo') === 'false' ? false : true;
    this.scnId = this.route.parent.snapshot.paramMap.get('scnId');
  }

  public ngOnInit() {
    this.getScheduleRotation();
    this.getScheduleTCO();
    this.getCurrentDeployment();
    this.getCurrentServiceDetails();
    this.dataService.schIDData.subscribe((data: any) => {
      this.proformaHeaderId = data.prhPKId;
    });
    // const serviceBannerCodeHeader = localStorage.getItem('serviceCodeFromHeader');
    // const serviceBannerIdHeader = localStorage.getItem('serviceProformaIDFromHeader');
    // const serviceBannerCode = localStorage.getItem('serviceCode');
    // const serviceBannerId = localStorage.getItem('serviceProformaID');
    // const bannerName = this.titleService.getTitle();
    // if (this.schId !== serviceBannerId) {
    //   this.titleService.setTitle(this.titleService.getTitle() +
    //     (' ' + '(S)' + ' ' + serviceBannerCode + '- ' + 'ID' + this.schId));
    // } else {
    //   if (bannerName.length == 6) {
    //     this.titleService.setTitle(this.titleService.getTitle() +
    //       (' ' + '(S)' + ' ' + serviceBannerCode + '- ' + 'ID' + serviceBannerId));
    //   }
    // }
    this.titleService.setTitle(
      this.titleService.getTitle() + (' ' + '(S)' + ' ' + this.serviceCode + '- ' + 'ID' + this.serviceID)
    );

  }
  public getCurrentServiceDetails() {
     this.serviceRepoService.getScheduleDetail(this.schId).subscribe((res: any) => {
      this.schDetails = res;
      this.serviceCode = this.schDetails.serviceCode;
      this.serviceID = this.schDetails.proformaID;
    });
  }

  public removeDuplicateBannerName(str) {
    const arr = str.split('');
    const arr2 = [];

    arr.forEach((el, i) => {
      if (!arr2.includes(el)) {
        arr2.push(el);
      }
    });
    return arr2.join('').replace(',', '').replace('', ' ');
  }

  public ngOnDestroy() {
    localStorage.removeItem('serviceCode');
    localStorage.removeItem('serviceProformaID');
  }

  public getCurrentDeployment() {
    this.showLoading = true;
    this.serviceRepoService.getCurrentDeployment(this.id, this.schId).subscribe((res: any) => {
      this.currentDeploymentLst = res;
      this.showLoading = false;
    });
  }
  public updateBunkerValues(event) {
    this.serviceRepoService.saveTCO(event.value).subscribe((res: any) => {
      this.router
        .navigateByUrl('/services/' + this.id, { skipLocationChange: true })
        .then(() => this.router.navigate(['/services/' + this.id + '/schedule/' + this.schId + '/true']));
    });
  }

  public expandPanel(matExpansionPanel, event: Event): void {
    event.stopPropagation(); // Preventing event bubbling

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.close(); // Here's the magic
    }
  }
  private getScheduleRotation() {
    const serv = this.serviceRepo
      ? this.serviceRepoService.getScheduleRotation(this.schId)
      : this.scenarioService.getScheduleRotation(this.schId, this.scnId);
    serv.subscribe((res: any) => {
      this.scheduleRotation = res;
      this.roundtripDays =
        this.scheduleRotation && this.scheduleRotation.roundTripDays ? this.scheduleRotation.roundTripDays : 0;
    });
  }

  private getScheduleTCO() {
    if (this.serviceRepo === true) {
      this.serviceRepoService.getScheduleTCO(this.schId).subscribe((res: any) => {
        this.serviceObj = res;
      });
    }
  }
  private _isExpansionIndicator(target): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return target.classList && target.classList.contains(expansionIndicatorClass);
  }
}
