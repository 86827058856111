import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(private http: HttpClient) { }

  /**
   * this function downloads a file
   * @param url
   */
  public downloadFile(url): void {
    this.http.get(url, {observe: 'response', responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        const dataType = response.body.type;
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        const fileName = (response.headers.get('content-disposition').split(';')[1]).split('=')[1].trim();
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        } else {
          downloadLink.setAttribute('download', 'file');
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    );
  }

  public downloadExcelForSelectedHire(response ? , data?): void {
    const fileName = (response.headers.get('content-disposition').split(';')[1]).split('=')[1].trim();
    const  responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (response.body != null)  {
        const dataType = responseType;
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (fileName != null) {
          downloadLink.setAttribute('download', fileName);
        } else {
          downloadLink.setAttribute('download', 'file');
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
  }

  public downloadPdfForSelectedHire(response ? , data?): void {
    const fileName = (response.headers.get('content-disposition').split(';')[1]).split('=')[1].trim();
    const  responseType = 'application/pdf';
      if (response.body != null)  {
        const dataType = responseType;
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (fileName != null) {
          downloadLink.setAttribute('download', fileName);
        } else {
          downloadLink.setAttribute('download', 'file');
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
  }

}
