import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expenseTypeToDisplay'
})
export class ExpenseTypeToDisplayPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    const baseVale = 97;
      return '(' + String.fromCodePoint(parseInt(value) + baseVale) + ') ';

  }

}
