//

import { Directive, ElementRef, ViewContainerRef, NgZone, Inject, Optional, Input, Provider } from '@angular/core';
import {
  MatTooltip,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  TooltipComponent,
} from '@angular/material/tooltip';
import { Overlay, ScrollDispatcher, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';

@Directive({
  selector: '[champsTooltip]',
  exportAs: 'champsTooltip',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(longpress)': 'show()',
    '(keydown)': '_handleKeydown($event)',
    '(touchend)': '_handleTouchend()',
  },
})
export class CustomTooltipDirective extends MatTooltip {
  // tslint:disable:no-non-null-assertion

  public _overlayRef: OverlayRef | null;
  public _tooltipInstance: TooltipComponent | null;

  /** The message to be displayed in the tooltip */
  @Input('champsTooltip')
  get msg() {
    return this.message;
  }
  set msg(value: string) {
    this.message = value;
  }

  constructor(
    overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    scrollDispatcher: ScrollDispatcher,
    viewContainerRef: ViewContainerRef,
    ngZone: NgZone,
    platform: Platform,
    ariaDescriber: AriaDescriber,
    focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy,
    @Optional() dir: Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) defaultOptions: MatTooltipDefaultOptions,
  ) {
    super(
      overlay,
      elementRef,
      scrollDispatcher,
      viewContainerRef,
      ngZone,
      platform,
      ariaDescriber,
      focusMonitor,
      scrollStrategy,
      dir,
      defaultOptions,
      document
    );
  }

  public show(delay: number = this.showDelay) {
    const el = this.elementRef.nativeElement;
    if (el.offsetWidth < el.scrollWidth) {
      super.show(delay);
    } else {
      super.hide(0);
    }
  }
}
